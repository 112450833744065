import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import { formatCurrency, formatDate2 } from '../../../constants/utility';

const Default = ({ data }) => {
  const columns = [
    {
      dataField: "_",
      text: "No",
      headerClasses: "py-2 px-2 text-center",
      classes: "p-2",
      headerStyle: (colum, colIndex) => {
        return { width: "40px" };
      },
      formatter: (cel, row, rowIndex) => rowIndex + 1,
      footer: ''
    },
    {
      dataField: "nama",
      text: "Nama",
      headerClasses: "py-2 px-2 text-left",
      classes: "p-2 text-left",
      formatter: (val, row) => { return val },
      footer: ''
    },
    {
      dataField: "registrasi",
      text: "Registrasi",
      headerClasses: "py-2 px-2 text-right",
      classes: "p-2 text-right",
      formatter: (val, row) => { return formatCurrency(val) },
      footer: ''
    },
    {
      dataField: "upgrade",
      text: "Upgrade",
      headerClasses: "py-2 px-2 text-right",
      classes: "p-2 text-right",
      formatter: (val, row) => { return formatCurrency(val) },
      footer: ''
    },
    {
      dataField: "ro",
      text: "Repeat Order",
      headerClasses: "py-2 px-2 text-right",
      classes: "p-2 text-right",
      formatter: (val, row) => { return formatCurrency(val) },
      footer: ''
    },
    {
      dataField: "total",
      text: "Omset",
      headerClasses: "py-2 px-2 text-right",
      classes: "p-2 text-right",
      formatter: (val, row) => { return formatCurrency(val) },
      footer: val => <div className="text-right" style={{ fontSize: '14px' }}>{formatCurrency(val.reduce((a, b) => a + b, 0))}</div>,

    },

  ];

  return (

    <div className="bt-table">
      <BootstrapTable
        className="bt-table"
        bootstrap4
        hover
        condensed
        noDataIndication={() => { return "No data"; }}
        bordered={false}
        keyField="nama"
        data={data || []}
        columns={columns} />
    </div>

  )
}

export default Default