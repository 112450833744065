import React from "react";
import { Alert } from "../../components/Alert";

export const MainWrapper = (Component) => {
  return (props) => (
    <>
      <Component {...props} />
      <Alert />
    </>
  );
};
