import * as actionTypes from "../../../constants/actionTypes";
import { FORM_ORDER_KEY } from "../../../constants/keys";
import {
  getLocalStorage,
  storeLocalStorage,
} from "../../../services/localServices";
import { showAlert } from "../alert";
import * as api from "../api";

// local functions
const getTracking = (data) => {
  return {
    type: actionTypes.GET_TRACKING,
    data: data,
  };
};

export const nullTracking = (data) => {
  return {
    type: actionTypes.GET_TRACKING,
    data: null,
  };
};

const getBarang = (data) => {
  return {
    type: actionTypes.GET_BARANG,
    data: data,
  };
};

const getGudang = (data) => {
  return {
    type: actionTypes.GET_GUDANG,
    data: data,
  };
};

export const setPaymenMethod = (data) => {
  return {
    type: actionTypes.GET_PAYMENT_METHOD,
    data: data,
  };
};

export const setProcessSelesai = (data) => {
  return {
    type: actionTypes.SET_PROCESS_SELESAI,
    data: data,
  };
};

const getHistory = (data) => {
  return {
    type: actionTypes.GET_TRANSACTION_HISTORY,
    data: data,
  };
};

const getKode = (data) => {
  return {
    type: actionTypes.GET_TRANSACTION_KODE,
    data: data,
  };
};

const getDownline = (data) => {
  return {
    type: actionTypes.GET_TRANSACTION_DOWNLINE,
    data: data,
  };
};

export const getInvoice = (data) => {
  return {
    type: actionTypes.GET_TRANSACTION_INVOICE,
    data: data,
  };
};

export const setPaymentToken = (payload) => {
  return {
    type: actionTypes.TRANSACTION_PAYMENT_TOKEN,
    data: payload,
  };
};

export const setPaymentAddress = (payload) => {
  return {
    type: actionTypes.TRANSACTION_PAYMENT_ADDRESS,
    data: payload,
  };
};
// export actions

export const setFormResult = (payload) => {
  return {
    type: actionTypes.TRANSACTION_FORM_RESULT,
    data: payload,
  };
};

export const fetchBarang = (query) => async (dispatch) => {
  try {
    const { result } = await api.getBarang(query);
    console.log("xyy result", result);
    dispatch(getBarang(result));
  } catch (err) {
    console.error(err);
  }
};

export const fetchGudang = (query) => async (dispatch) => {
  try {
    const { result } = await api.getGudang(query);
    dispatch(getGudang(result));
  } catch (err) {
    console.error(err);
  }
};

export const fetchGudangStok = (data) => async (dispatch) => {
  try {
    const { result } = await api.postGudangStok(data);
    dispatch(getGudang(result));
  } catch (err) {
    console.error(err);
  }
};

export const fetchPaymentMethod = (query) => async (dispatch) => {
  try {
    const { result } = await api.getPaymentMethod(query);
    dispatch(setPaymenMethod(result));
  } catch (err) {
    console.error(err);
  }
};

export const updateProcessSelesai = (id, data) => async (dispatch) => {
  try {
    const result = await api.putProcessSelesai(id, data);
    console.log("result", result);
    dispatch(showAlert({ message: result?.message, show: true }));
    dispatch(setProcessSelesai(result));
  } catch (err) {
    console.error(err);
  }
};

export const fetchDownline = (query) => async (dispatch) => {
  try {
    const { result } = await api.getDownline(query);
    dispatch(getDownline(result));
  } catch (err) {
    console.error(err);
  }
};

export const fetchTracking = (body) => async (dispatch) => {
  try {
    console.log("xyy", body);
    const { result } = await api.postTrack(body);
    dispatch(getTracking(result));
  } catch (err) {
    console.error(err);
  }
};

export const fetchTransactionHistory = (query) => async (dispatch) => {
  try {
    const { result } = await api.getTransactionHistory(query);
    dispatch(getHistory(result));
  } catch (err) {
    console.error(err);
  }
};

export const fetchTransactionKode = (query) => async (dispatch) => {
  try {
    const { result } = await api.getTransactionKode(query);
    dispatch(getKode(result));
  } catch (err) {
    console.error(err);
  }
};

export const fetchTransactionInvoice = (query) => async (dispatch) => {
  try {
    const { result } = await api.getTransactionInvoice(query);
    dispatch(getInvoice(result));
  } catch (err) {
    console.error(err);
  }
};

export const createPayment = (data) => async (dispatch) => {
  try {
    const { result } = await api.postPayment(data);
    dispatch(setPaymentToken(result));
  } catch (err) {
    console.error(err);
  }
};
export const createPaymentAddress = (data) => async (dispatch) => {
  try {
    const { result } = await api.postPaymentAddress(data);
    dispatch(setPaymentAddress(result));
  } catch (err) {
    console.error(err);
  }
};

export const resetPayment = () => async (dispatch) => {
  try {
    dispatch(setPaymentToken(false));
  } catch (err) {
    console.error(err);
  }
};

export const createTransaction = (data) => async (dispatch) => {
  try {
    const result = await api.postTransaction(data);
    dispatch(showAlert({ message: result?.message, show: true }));
    dispatch(fetchTransactionHistory());
    dispatch(setFormResult(result));
  } catch (err) {
    console.error(err);
  }
};

export const createKodeTransfer = (data) => async (dispatch) => {
  try {
    const result = await api.postKodeTransfer(data);
    dispatch(showAlert({ message: result?.message, show: true }));
    dispatch(setFormResult(result));
  } catch (err) {
    console.error(err);
  }
};

export const updateTransactionKode = (id, data) => async (dispatch) => {
  try {
    const result = await api.putTransactionKode(id, data);
    dispatch(showAlert({ message: result?.message, show: true }));
    dispatch(fetchTransactionKode(result));
    dispatch(fetchTransactionInvoice(result));
    dispatch(fetchTransactionHistory(result));
    dispatch(setFormResult(result));
  } catch (err) {
    console.error(err);
  }
};

export const updateUpgradeProfile = (id, data) => async (dispatch) => {
  try {
    const result = await api.putUpgradeProfile(id, data);
    dispatch(showAlert({ message: result?.message, show: true }));
    dispatch(setFormResult(result));
  } catch (err) {
    console.error(err);
  }
};

const getRetur = (data) => {
  return {
    type: actionTypes.GET_RETUR,
    data: data,
  };
};

export const fetchRetur = (query) => async (dispatch) => {
  try {
    const { result } = await api.getRetur(query);
    dispatch(getRetur(result));
  } catch (err) {
    console.error(err);
  }
};

const getReturAble = (data) => {
  return {
    type: actionTypes.GET_RETUR_ABLE,
    data: data,
  };
};

export const fetchReturAble = () => async (dispatch) => {
  try {
    const { result } = await api.getReturAble();
    dispatch(getReturAble(result));
  } catch (err) {
    console.error(err);
  }
};

export const createRetur = (data) => async (dispatch) => {
  try {
    const result = await api.postRetur(data);
    dispatch(showAlert({ message: result?.message, show: true }));
    dispatch(setFormResult(result));
  } catch (err) {
    console.error(err);
  }
};

export const createResiRetur = (data) => async (dispatch) => {
  try {
    const result = await api.postResiRetur(data);
    dispatch(showAlert({ message: result?.message, show: true }));
    dispatch(setFormResult(result));
  } catch (err) {
    console.error(err);
  }
};

export const setFormOrder = (payload) => {
  storeLocalStorage(FORM_ORDER_KEY, JSON.stringify(payload));
  return {
    type: actionTypes.FORM_ORDER,
    data: payload,
  };
};

export const persistFormOrder = () => async (dispatch) => {
  try {
    const userData = JSON.parse(getLocalStorage(FORM_ORDER_KEY));
    dispatch(setFormOrder(userData));
  } catch (err) {
    //
  }
};

const getShipping = (data) => {
  return {
    type: actionTypes.GET_SHIPPING,
    data: data,
  };
};

export const fetchShipping = (query) => async (dispatch) => {
  try {
    const { result } = await api.postShippingAddress(query);
    dispatch(getShipping(result));
  } catch (err) {
    console.error(err);
  }
};

export const setSettings = (data) => {
  return {
    type: actionTypes.GET_SETTINGS,
    data: data,
  };
};

export const fetchSettings = (query) => async (dispatch) => {
  try {
    const { result } = await api.getSettings(query);
    dispatch(setSettings(result));
  } catch (err) {
    console.error(err);
  }
};
