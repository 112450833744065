import * as actionTypes from '../../../constants/actionTypes';
import { updateObject } from '../../../constants/utility';

const initialState = {
  dashboard: null,
  bannerpromo: null,
  listGambar: null,
  perkembanganJaringan: [],
  bonusBulanan: [],
  bonusHarian: [],
  redemptionStatement: [],
  DownlineReport: [],
  ReportOmset: [],
  bonus: null,
  upgrade: null,
  register: null,
  payout: null,
  formResult: null,
  promoOpt: null,
  promo: null,
  reportPPh: null,
};

const getBannerPromo = (state, data) => {
  return updateObject(state, {
    bannerpromo: data,
  });
};

const getReportOmset = (state, data) => {
  return updateObject(state, {
    ReportOmset: data,
  });
};

const getReportPPH = (state, data) => {
  return updateObject(state, {
    reportPPh: data,
  });
};

const getPerkembanganJaringan = (state, data) => {
  return updateObject(state, {
    perkembanganJaringan: data,
  });
};

const getDownlineReport = (state, data) => {
  return updateObject(state, {
    DownlineReport: data,
  });
};

const getRedemptionStatement = (state, data) => {
  return updateObject(state, {
    redemptionStatement: data,
  });
};

const getBonusBulanan = (state, data) => {
  return updateObject(state, {
    bonusBulanan: data,
  });
};

const getBonus = (state, data) => {
  return updateObject(state, {
    bonus: data,
  });
};

const getPayout = (state, data) => {
  return updateObject(state, {
    payout: data,
  });
};

const getBonusUnpayout = (state, data) => {
  return updateObject(state, {
    bonusUnpayout: data,
  });
};

const getUpgrade = (state, data) => {
  return updateObject(state, {
    upgrade: data,
  });
};

const getRegister = (state, data) => {
  return updateObject(state, {
    register: data,
  });
};

const getDashboard = (state, data) => {
  return updateObject(state, {
    dashboard: data,
  });
};

const getMedia = (state, data) => {
  return updateObject(state, {
    listGambar: data.listGambar,
  });
};

const getBanner = (state, data) => {
  return updateObject(state, {
    banner: data,
  });
};

const formResult = (state, data) => {
  return updateObject(state, {
    formResult: data,
  });
};

const getBonusHarian = (state, data) => {
  return updateObject(state, {
    bonusHarian: data,
  });
};

const getPromoOpt = (state, data) => {
  return updateObject(state, {
    promoOpt: data,
  });
};
const getPromoDetail = (state, data) => {
  return updateObject(state, {
    promo: data,
  });
};

const reducer = (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
    case actionTypes.GET_BANNER_PROMO:
      return getBannerPromo(state, data);
    case actionTypes.GET_REPORT_OMSET:
      return getReportOmset(state, data);
    case actionTypes.GET_MEDIA:
      return getMedia(state, data);
    case actionTypes.GET_REPORT_BONUS_HARIAN:
      return getBonusHarian(state, data);
    case actionTypes.GET_REPORT_PERKEMBANGAN_JARINGAN:
      return getPerkembanganJaringan(state, data);
    case actionTypes.GET_DOWNLINE_REPORT:
      return getDownlineReport(state, data);
    case actionTypes.GET_REDEMPTION_STATEMENT:
      return getRedemptionStatement(state, data);
    case actionTypes.GET_REPORT_BONUS:
      return getBonus(state, data);
    case actionTypes.SET_REPORT_PAYOUT:
      return getPayout(state, data);
    case actionTypes.GET_BONUS_UNPAYOUT:
      return getBonusUnpayout(state, data);
    case actionTypes.GET_REPORT_UPGRADE:
      return getUpgrade(state, data);
    case actionTypes.GET_REPORT_REGISTER:
      return getRegister(state, data);
    case actionTypes.GET_REPORT_DASHBOARD:
      return getDashboard(state, data);
    case actionTypes.REPORT_FORM_RESULT:
      return formResult(state, data);
    case actionTypes.GET_REPORT_BONUS_BULANAN:
      return getBonusBulanan(state, data);
    case actionTypes.GET_PROMO_OPT:
      return getPromoOpt(state, data);
    case actionTypes.GET_PROMO_DETAIL:
      return getPromoDetail(state, data);
    case actionTypes.GET_BANNER:
      return getBanner(state, data);
    case actionTypes.GET_REPORT_PPH:
      return getReportPPH(state, data);
    default:
      return state;
  }
};

export default reducer;
