import React, { useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { FaAngleDown, FaClock } from "react-icons/fa";
import { CardBody, Col, Container, Row } from "reactstrap";
import { formatDate } from "../../constants/utility";
import "./Collapsible.css";
import parse from "html-react-parser";

function Collapsible({data}) {
  const [isOpen, setIsOpen] = useState(false);
  const parentRef = useRef();
  const {title, content, created_at, updated_at} = data;

  return (
    <div
      className={"collapsible text-center"}
      onClick={() => setIsOpen(!isOpen)}
      style={{ cursor: "pointer" }}
    >
         {parse(content)}
    </div>
  );
}

export default Collapsible;
