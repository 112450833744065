import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Card, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  arr_kabupaten,
  arr_kecamatan,
  arr_propinsi,
} from "../../../assets/js/alamat";
import CardBasic from "../../../components/Cards/Basic";
import PageHeading from "../../../components/PageHeading";
import TextInput from "../../../components/TextInput";
import * as actions from "../../../redux/actions";
import {
  createAccountAddress,
  deleteAccountAddress,
  fetchAccountAddress,
  updateAccountAddress,
} from "../../../redux/actions/account";

const defaultOption = [{ value: "-", label: "-" }];

const Alamat = (props) => {
  const { name, title, parent } = props.data;
  const { address } = useSelector((state) => state.account);
  const [optKabupaten, setOptKabupaten] = useState(defaultOption);
  const [optKecamatan, setOptKecamatan] = useState(defaultOption);
  const [modal, setModal] = React.useState(false);
  const [kotaId, setKotaId] = useState(0);
  const [propinsiId, setPropinsiId] = useState(0);

  // Toggle for Modal
  const toggle = () => setModal(!modal);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actions.setMenuActive({ menuActive: parent || name, subMenuActive: name })
    );
    dispatch(fetchAccountAddress());
  }, [dispatch, name, parent]);

  const { register, handleSubmit, watch, formState, setValue, reset } = useForm(
    {
      defaultValues: {
        label: "",
        nama: "",
        alamat: "",
        kecamatan: "",
        kota: "",
        propinsi: "",
        phone: "",
        kode_pos: "",
        public_id: "",
      },
    }
  );
  const watchPropinsi = watch().propinsi;
  const watchKota = watch().kota;
  useEffect(() => {
    setOptKabupaten(defaultOption);
    setOptKecamatan(defaultOption);
    if (propinsiId !== watchPropinsi) {
      setValue("kecamatan", null);
      setValue("kota", null);
    }
    const key = parseInt(watchPropinsi);
    if (key > 0) {
      var newArray = arr_kabupaten.filter(function (e) {
        return e.prop === key;
      });
      setOptKabupaten(newArray);
      setOptKecamatan(defaultOption);
    }
  }, [propinsiId, watch, setValue, watchPropinsi]);

  useEffect(() => {
    if (kotaId !== watchKota) {
      setValue("kecamatan", null);
    }
    const key = parseInt(watchKota);
    if (key > 0) {
      var newArray = arr_kecamatan.filter(function (e) {
        return e.kab === key;
      });
      setOptKecamatan(newArray);
    }
  }, [kotaId, setValue, watch, watchKota]);

  const submit = (data) => {
    if (watch("public_id") && watch("public_id") !== "") {
      dispatch(updateAccountAddress(watch("public_id"), data));
    } else {
      dispatch(createAccountAddress(data));
    }
    setModal(false);
  };

  const deleteAlamat = () => {
    dispatch(deleteAccountAddress(watch("public_id")));
  };

  const handleClick = (data) => {
    setPropinsiId(data?.propinsi?.id);
    setKotaId(data?.kota?.id);
    setModal(true);
    setValue("label", data?.label);
    setValue("nama", data?.nama);
    setValue("alamat", data?.alamat);
    setValue("kecamatan", data?.kecamatan?.id);
    setValue("kota", data?.kota?.id);
    setValue("propinsi", data?.propinsi?.id);
    setValue("phone", data?.phone);
    setValue("kode_pos", data?.kode_pos);
    setValue("public_id", data?.public_id);
  };

  return (
    <div>
      <PageHeading title={title} />
      <CardBasic title="Alamat yang sudah tersimpan">
        <div>
          <button
            type="button"
            className="btn btn-primary-f btn-user mb-2"
            onClick={() => {
              setModal(true);
              reset();
            }}
          >
            Tambah Alamat
          </button>
          <Modal toggle={toggle} isOpen={modal} centered={true}>
            <form onSubmit={handleSubmit(submit)}>
              <ModalHeader
                toggle={toggle}
                close={
                  <div className="btn close" onClick={toggle}>
                    ×
                  </div>
                }
              >
                {watch("public_id") ? "Ubah" : "Tambah"} Alamat
              </ModalHeader>
              <ModalBody>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-sm-12 ">
                      <TextInput
                        layout="vertical"
                        label="Label Alamat"
                        type="text"
                        name="label"
                        placeholder=""
                        defaultValue=""
                        register={register}
                        errors={formState.errors}
                        validations={{ required: "Masukkan label alamat" }}
                      />
                    </div>

                    <div className="col-sm-12 col-md-6">
                      <TextInput
                        layout="vertical"
                        label="Provinsi"
                        type="select"
                        name="propinsi"
                        placeholder=""
                        register={register}
                        errors={formState.errors}
                        options={arr_propinsi}
                        validations={{ required: "Pilih propinsi" }}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <TextInput
                        layout="vertical"
                        label="Kota"
                        type="select"
                        name="kota"
                        placeholder=""
                        register={register}
                        errors={formState.errors}
                        options={optKabupaten}
                        validations={{ required: "Pilih kota" }}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <TextInput
                        layout="vertical"
                        label="Kecamatan"
                        type="select"
                        name="kecamatan"
                        placeholder=""
                        register={register}
                        errors={formState.errors}
                        options={optKecamatan}
                        validations={{ required: "Pilih Kecamatan" }}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <TextInput
                        layout="vertical"
                        label="Kode Pos"
                        type="text"
                        name="kode_pos"
                        placeholder=""
                        register={register}
                        errors={formState.errors}
                        validations={{ required: "Masukkan Kode Pos" }}
                      />
                    </div>
                    <div className="col-sm-12">
                      <TextInput
                        layout="vertical"
                        label="Alamat Kirim"
                        type="textarea"
                        name="alamat"
                        placeholder=""
                        register={register}
                        errors={formState.errors}
                        validations={{ required: "Masukkan detail alamat" }}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <TextInput
                        layout="vertical"
                        label="Nama Penerima"
                        type="text"
                        name="nama"
                        placeholder=""
                        register={register}
                        errors={formState.errors}
                        validations={{ required: "Masukkan nama penerima" }}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <TextInput
                        layout="vertical"
                        label="No Telp Penerima"
                        type="text"
                        name="phone"
                        placeholder=""
                        register={register}
                        errors={formState.errors}
                        validations={{ required: "Masukkan telpon penerima" }}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  {watch("public_id") ? (
                    <div
                      className="div fas fa-trash fa-lg fa-fw mr-2 text-red float-left align-content-center pt-3"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        deleteAlamat();
                        setModal(false);
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                  <button className="btn btn-primary-f float-right align-content-center mb-2">
                    Simpan
                  </button>
                  <div
                    className="btn btn-secondary float-right align-content-center mr-2"
                    onClick={() => {
                      setModal(false);
                    }}
                  >
                    Tutup
                  </div>
                </div>
              </ModalBody>
            </form>
          </Modal>
        </div>

        {address?.rows?.map((data, key) => {
          return (
            <Card
              className="mb-1"
              key={key}
              onClick={() => handleClick(data)}
              style={{ cursor: "pointer" }}
            >
              <div className="px-3  row row-cols-4">
                <div className="col">
                  <small>Label</small>
                  <br />
                  {data.label}
                </div>
                <div className="col">
                  <small>Nama Penerima</small>
                  <br />
                  {data.nama}
                </div>
                <div className="col">
                  <small>Nomor Telepon</small>
                  <br />
                  {data.phone}
                </div>
                <div className="col">
                  <small>Kota</small>
                  <br />
                  {data.kota?.nama}
                </div>
              </div>
              <div className="px-3  row row-cols-1">
                <div className="col">
                  <small>Alamat</small>
                  <br />
                  {`${data.alamat} Kec. ${data.kecamatan?.nama} Kab/Kota. ${data.kota?.nama} Prop. ${data.propinsi?.nama} KodePos ${data.kode_pos}`}
                </div>
              </div>
            </Card>
          );
        })}
      </CardBasic>
    </div>
  );
};

export default Alamat;
