import * as actionTypes from '../../../constants/actionTypes';
import { LOCAL_USER_KEY } from '../../../constants/keys';
import {
    getLocalStorage,
    removeLocalStorage,
    storeLocalStorage,
} from '../../../services/localServices';
import * as actions from '../../actions';
import { authLogin, postForgotPassword, postResetPassword } from '../api';
import { showAlert } from '../alert';

const loginStart = () => {
    return {
        type: actionTypes.LOGIN_START,
    };
};

const loginSuccess = (data) => {
    return {
        type: actionTypes.LOGIN_SUCCESS,
        data: data,
    };
};

const loginFail = (error) => {
    return {
        type: actionTypes.LOGIN_FAIL,
        error: error,
    };
};

const logoutSuccess = () => {
    return {
        type: actionTypes.LOGOUT,
    };
};

const setUserData = (data) => {
    return {
        type: actionTypes.AUTH_USER_DATA,
        data,
    };
};

export const persistAuth = () => async (dispatch) => {
    try {
        const userData = JSON.parse(getLocalStorage(LOCAL_USER_KEY));
        dispatch(setUserData(userData));
    } catch (err) {
        //
    }
};

export const login = (body) => async (dispatch) => {
    dispatch(loginStart());

    try {
        const { result, success, message } = await authLogin(body);

        if (!success) {
            dispatch(
                actions.showAlert({
                    message: message,
                    show: true,
                    type: 'danger',
                })
            );
            throw new Error(message);
        }

        // Save access token
        storeLocalStorage(LOCAL_USER_KEY, JSON.stringify(result));

        // Login success
        dispatch(loginSuccess(result));
        dispatch(
            actions.showAlert({
                message: message,
                show: true,
                type: 'success',
            })
        );
        // window.location = "/";
        // <Navigate to="/"/>
    } catch (err) {
        // Login error!
        dispatch(loginFail({ message: err.message }));
    }
};

export const logout = () => async (dispatch) => {
    removeLocalStorage(LOCAL_USER_KEY);
    dispatch(logoutSuccess());
};

export const setForgotPassword = (data) => {
    return {
        type: actionTypes.FORGOT_PASSWORD,
        data: data,
    };
};

export const forgotPassword = (body) => async (dispatch) => {
    try {
        const result = await postForgotPassword(body);
        dispatch(setForgotPassword(result));
        dispatch(showAlert({ message: result?.message, show: true }));
    } catch (err) {}
};

export const setResetPassword = (data) => {
    return {
        type: actionTypes.RESET_PASSWORD,
        data: data,
    };
};

export const resetPassword = (body) => async (dispatch) => {
    try {
        const result = await postResetPassword(body);
        dispatch(setResetPassword(result));
        dispatch(showAlert({ message: result?.message, show: true }));
    } catch (err) {
        // Login error!
        // dispatch(loginFail({ message: err.message }));
    }
};
