import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'reactstrap';
import { Button, Modal } from 'react-bootstrap';
import {
  arr_kabupaten,
  arr_kecamatan,
  arr_propinsi,
} from '../../../assets/js/alamat';
import CardBasic from '../../../components/Cards/Basic';
import PageHeading from '../../../components/PageHeading';
import TextInput from '../../../components/TextInput';
import MultiSelect from '../../../components/MultiSelect';
import * as actions from '../../../redux/actions';
import {
  fetchAccountProfile,
  fetchListBank,
  fetchResendVerifikasiBank,
  fetchResendVerifikasiNpwp,
  updateAccountProfile,
  updateAccountRekening,
  updateAccountNpwp,
  updateInformasiPewaris,
  updateAccountWithdraw,
} from '../../../redux/actions/account';
import {
  formatDate,
  formatNpwp,
  formatDate2,
} from '../../../constants/utility';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './index.css'

const defaultOption = [{ value: '-', label: '-' }];

const Profile = (props) => {
  const { name, title, parent } = props.data;
  const { public_id } = useSelector((state) => state.auth.data);
  const { profile, isBonusAvl, listBank, formResult } = useSelector(
    (state) => state.account
  );
  const [optKabupaten, setOptKabupaten] = useState(defaultOption);
  const [optKecamatan, setOptKecamatan] = useState(defaultOption);
  const [modalBank, setModalBank] = useState(false);
  const [modalNpwp, setModalNpwp] = useState(false);
  const [modalPewaris, setModalPewaris] = useState(false);
  const [optKotaDomisili, setOptKotaDomisili] = useState(defaultOption);

  const dispatch = useDispatch();

  const month = [
    'Januari',
    'Februari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember',
  ];

  useEffect(() => {
    dispatch(
      actions.setMenuActive({ menuActive: parent || name, subMenuActive: name })
    );
    dispatch(fetchListBank());
  }, [dispatch, parent, name]);

  useEffect(() => {
    if (public_id) dispatch(fetchAccountProfile(public_id));
  }, [dispatch, public_id]);

  const { register, handleSubmit, watch, formState, setValue, setFocus } =
    useForm({
      mode: 'onBlur',
      defaultValues: {
        agama: '',
        email: '',
        alamat: '',
        id_sponsor: '',
        jenis_kelamin: '',
        kecamatan: '',
        kode_pos: '',
        posisi: '',
        kota: '',
        nama: '',
        nama_sponsor: '',
        phone: '',
        nomor_identitas: '',
        negara: 'Indonesia',
        propinsi: '',
        npwp: '',
        nama_pemegang_pajak: '',
        status_kawin: '',
        tgl_lahir: '',
        propinsi_domisili: '',
      },
    });

  const [kotaId, setKotaId] = useState(null);
  const [kecamatanId, setKecamatanId] = useState(null);

  useEffect(() => {
    setValue('agama', profile?.agama);
    setValue('email', profile?.email);
    setValue('alamat', profile?.alamat);
    setValue('cabang', profile?.cabang);
    setValue('id_sponsor', profile?.sponsor?.id_member);
    setValue('jenis_kelamin', profile?.jenis_kelamin);
    setValue('kode_pos', profile?.kode_pos);
    setValue('posisi', profile?.posisi);
    setValue('id_member', profile?.id_member);
    setValue('username', profile?.username);
    setValue('nama', profile?.nama);
    setValue('nama_rekening', profile?.nama_rekening);
    setValue('nama_sponsor', profile?.sponsor?.nama);
    setValue('phone', profile?.phone);
    setValue('nomor_identitas', profile?.nomor_identitas);
    setValue('no_rekening', profile?.no_rekening);
    setValue('propinsi', profile?.propinsi?.id);
    setValue('npwp', profile?.npwp);
    setValue('nama_pemegang_pajak', profile?.nama_pemegang_pajak);
    setValue('status_kawin', profile?.status_kawin);
    setValue('tgl_lahir', profile?.tgl_lahir);
    setValue('propinsi_domisili', profile?.propinsi_domisili?.id);
    if (profile) {
      setKotaId(profile.kota?.id);
      setKecamatanId(profile.kecamatan?.id);
      setKotaDomisli(profile.kota_domisili?.id);
    }
    console.log(profile)
  }, [profile, setValue]);

  useEffect(() => {
    if (listBank && profile) {
      setValue('bank', profile?.kode_bank);
    }
  }, [profile, listBank, setValue]);

  const watchPropinsi = watch('propinsi');
  const watchKota = watch('kota');
  const watchPropinsiDomisili = watch('propinsi_domisili');
  const watchKotaDomisili = watch('kota_domisili');

  useEffect(() => {
    setOptKabupaten(defaultOption);
    setOptKecamatan(defaultOption);
    const key = parseInt(watchPropinsi);
    if (key > 0) {
      var newArray = arr_kabupaten.filter(function (e) {
        return e.prop === key;
      });
      setOptKabupaten(newArray);
      setOptKecamatan(defaultOption);
    }
  }, [watchPropinsi]);

  const [kotaDomisili, setKotaDomisli] = useState(0);
  useEffect(() => {
    setOptKotaDomisili(defaultOption);
    const key = parseInt(watchPropinsiDomisili);
    if (key > 0) {
      var newArray = arr_kabupaten.filter(function (e) {
        return e.prop === key;
      });
      setOptKotaDomisili(newArray);
    }
    setValue('kota_domisili', '');
  }, [watchPropinsiDomisili]);

  useEffect(() => {
    if (optKotaDomisili.length > 0) setValue('kota_domisili', kotaDomisili);
  }, [optKotaDomisili, kotaDomisili, setValue]);

  useEffect(() => {
    if (optKabupaten.length > 1) setValue('kota', kotaId);
  }, [optKabupaten, kotaId, setValue]);

  useEffect(() => {
    if (optKecamatan.length > 1) setValue('kecamatan', kecamatanId);
  }, [optKecamatan, kecamatanId, setValue]);

  useEffect(() => {
    const key = parseInt(watchKota);
    if (key > 0) {
      var newArray = arr_kecamatan.filter(function (e) {
        return e.kab === key;
      });
      setOptKecamatan(newArray);
    }
  }, [watchKota]);

  const submit = (data) => {
    const result = {
      nama: data?.nama,
      username: data?.username,
      nomor_identitas: data?.nomor_identitas,
      tgl_lahir: data?.tgl_lahir,
      jenis_kelamin: data?.jenis_kelamin,
      agama: data?.agama,
      status_kawin: data?.status_kawin,
      alamat: data?.alamat,
      kota: data?.kota,
      kecamatan: data?.kecamatan,
      propinsi: data?.propinsi,
      negara: data?.negara,
      kode_pos: data?.kode_pos,
      propinsi_domisili: data?.propinsi_domisili,
      kota_domisili: data?.kota_domisili,
    }
    dispatch(updateAccountProfile(public_id, result));
  };

  useEffect(() => {
    if (formResult) {
      dispatch(fetchAccountProfile(public_id));
      setModalBank(false);
      setModalNpwp(false);
      setModalPewaris(false);
    }
  }, [formResult, public_id, dispatch]);

  const resendVerifikasiBank = () => {
    dispatch(fetchResendVerifikasiBank(public_id));
  };

  const resendVerifikasiNpwp = () => {
    dispatch(fetchResendVerifikasiNpwp(public_id));
  };

  const EfektifBerlaku = `0` + `${new Date().getMonth() + 2}`;

  const domisRef = useRef(null);
  const [searchParams] = useSearchParams();
  const field = searchParams.get('field');
  const fallback = searchParams.get('fallback');

  useEffect(() => {
    if (field && field === 'domisili' && domisRef?.current) {
      domisRef.current.scrollIntoView({ behavior: 'smooth' });
      const sto = setTimeout(() => {
        setFocus('propinsi_domisili');
      }, 800);
      return () => clearTimeout(sto);
    }
  }, [field, domisRef]);

  const navigate = useNavigate();
  useEffect(() => {
    const validDomisili = profile?.propinsi_domisili && profile?.kota_domisili;
    console.log('xxx redirect back', validDomisili, formResult, fallback);
    if (validDomisili && fallback) {
      console.log('xxx redirect back');
      navigate(fallback);
      return;
    }
  }, [fallback, profile]);


  // --------- Start Handle auto withdraw ---------

  const [isOnToggle, setIsOnToggle] = useState(false)
  const [isModalWithdraw, setIsModalWithdraw] = useState(false)
  const [toggleData, setToggleData] = useState(null)

  useEffect(() => {
    setIsOnToggle(profile?.auto_widraw)
  }, [profile]);

  const handleCloseModal = (initialToggleState) => {
    setIsModalWithdraw(false)
    setIsOnToggle(initialToggleState)
  }

  const handleToogle = () => {
    setIsOnToggle(!isOnToggle)
    setIsModalWithdraw(!isModalWithdraw)
    setToggleData(!isOnToggle)
  }

  // --------- End Handle auto withdraw ---------


  return (
    <div className="mb-4">
      <PageHeading title={title} />
      <CardBasic title="Data Member">
        {profile?.status_npwp === 'UNVERIFIED' && (
          <Alert className="p-2 d-flex justify-content-between" color="warning">
            <div>
              <i className="fas fa-exclamation mr-1"></i>
              <strong>{`Update Your Profile`}</strong>
              <div className="mt-3">
                Masukan data NPWP Anda supaya bonus yang Anda terima dapat
                sesuai dengan pengenaan tarif pajak penghasilan menurut
                undang-undang pajak yang berlaku. Menurut undang-undang pajak
                yang belaku, pengisian data NPWP mendapatkan potongan pajak
                penghasilan yang lebih kecil
              </div>
            </div>
          </Alert>
        )}

        <form key={'update profile'} onSubmit={handleSubmit(submit)}>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="Member ID"
                readOnly
                type="text"
                name="id_member"
                placeholder="Member ID"
                register={register}
                errors={formState.errors}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="User Name"
                type="text"
                name="username"
                validations={{
                  required: 'Masukkan username',
                  pattern: {
                    value: /^[a-zA-Z0-9]*$/,
                    message:
                      'Username hanya boleh terdiri dari huruf dan angka, tanpa spasi',
                  },
                }}
                placeholder="Username"
                register={register}
                errors={formState.errors}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="Alamat Email"
                type="email"
                readOnly
                name="email"
                placeholder="Alamat Email"
                register={register}
                errors={formState.errors}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="Posisi"
                type="text"
                readOnly
                name="posisi"
                register={register}
                errors={formState.errors}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="No Identitas KTP/SIM"
                type="text"
                name="nomor_identitas"
                placeholder="Nomor Identitas KTP/SIM"
                register={register}
                validations={{
                  validate: (value) => {
                    const val = value.toString().replaceAll(' ', '');
                    if (val.length !== 16) return 'Nomor harus diisi 16 digit';
                    return true;
                  },
                  onChange: (e) => {
                    let val = e.target.value
                      .toString()
                      .replace(/\D/g, '')
                      .replaceAll(' ', '');
                    e.target.value = val;
                  },
                }}
                errors={formState.errors}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="Nama Lengkap"
                type="text"
                validations={{
                  required: 'Masukkan nama lengkap',
                  pattern: {
                    value: /^[a-zA-Z\s]*$/,
                    message:
                      'Nama lengkap hanya boleh terdiri dari huruf dan spasi',
                  },
                  maxLength: {
                    value: 60,
                    message: 'Nama lengkap tidak boleh melebihi 60 karakter',
                  },
                }}
                name="nama"
                placeholder="Nama Lengkap"
                register={register}
                errors={formState.errors}
              />
            </div>

            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="Tanggal Lahir"
                type="date-member-profile"
                name="tgl_lahir"
                placeholder="Tanggal Lahir"
                validations={{
                  required: 'Masukkan tanggal lahir',
                  validate: (value) => {
                    const birthDate = new Date(value);
                    const currentDate = new Date();
                    const minAgeDate = new Date(
                      currentDate.getFullYear() - 17,
                      currentDate.getMonth(),
                      currentDate.getDate()
                    );

                    if (birthDate > minAgeDate) {
                      return 'Anda harus berusia minimal 17 tahun';
                    }

                    return true;
                  },
                }}
                register={register}
                errors={formState.errors}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="Jenis Kelamin"
                type="radio"
                name="jenis_kelamin"
                placeholder="Pria/Wanita"
                defaultValue={watch('jenis_kelamin')}
                register={register}
                errors={formState.errors}
                options={[
                  { value: 'LAKI-LAKI', label: 'PRIA' },
                  { value: 'PEREMPUAN', label: 'WANITA' },
                ]}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="Agama"
                type="select"
                name="agama"
                placeholder="Agama"
                register={register}
                errors={formState.errors}
                validations={{ required: 'Pilih Agama' }}
                options={[
                  { value: 'ISLAM', label: 'ISLAM' },
                  { value: 'KATOLIK', label: 'KATOLIK' },
                  { value: 'PROTESTAN', label: 'KRISTEN / PROTESTAN' },
                  { value: 'HINDU', label: 'HINDU' },
                  { value: 'BUDHA', label: 'BUDHA' },
                  { value: 'KONGHUCU', label: 'KONGHUCU' },
                  { value: 'LAINNYA', label: 'LAINNYA' },
                ]}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="Status Pernikahan"
                type="select"
                name="status_kawin"
                placeholder=""
                register={register}
                errors={formState.errors}
                validations={{ required: 'Pilih Status' }}
                options={[
                  { value: 'BELUM KAWIN', label: 'BELUM MENIKAH' },
                  { value: 'KAWIN', label: 'SUDAH MENIKAH' },
                  { value: 'BERCERAI', label: 'BERCERAI' },
                  { value: 'LAINNYA', label: 'LAINNYA' },
                ]}
              />
            </div>

            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="Propinsi"
                type="select"
                name="propinsi"
                placeholder=""
                register={register}
                errors={formState.errors}
                validations={{ required: 'Pilih propinsi' }}
                options={arr_propinsi}
              />
            </div>

            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="Kab/Kota"
                type="select"
                name="kota"
                placeholder=""
                register={register}
                errors={formState.errors}
                validations={{ required: 'Pilih kabupaten' }}
                options={optKabupaten}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="Kecamatan"
                type="select"
                name="kecamatan"
                placeholder=""
                register={register}
                errors={formState.errors}
                validations={{ required: 'Pilih kecamatan' }}
                options={optKecamatan}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="Kode Pos"
                type="text"
                name="kode_pos"
                placeholder=""
                defaultValue={profile?.kode_pos}
                register={register}
                validations={{
                  required: 'Masukkan kode pos',
                  pattern: {
                    value: /^\d{5}$/,
                    message: 'Kode pos harus terdiri dari 5 digit angka',
                  },
                }}
                errors={formState.errors}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <TextInput
                readOnly={true}
                layout="vertical"
                label="No HP"
                type="text"
                name="phone"
                placeholder=""
                defaultValue={profile?.phone}
                validations={{ required: 'Masukkan nomor hp' }}
                register={register}
                errors={formState.errors}
              />
            </div>
            <div className="col-sm-12 col-md-12">
              <TextInput
                layout="vertical"
                label="Alamat Lengkap"
                type="textarea"
                name="alamat"
                placeholder=""
                register={register}
                validations={{ required: 'Masukkan alamat' }}
                errors={formState.errors}
              />
            </div>

            {field ? (
              <>
                <div
                  className="col-12"
                  ref={domisRef}
                  style={{ scrollMargin: '20px' }}>
                  <div className="alert alert-warning">
                    Lengkapi data domisili saat ini!
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="Propinsi Domisili"
                type="select"
                name="propinsi_domisili"
                placeholder=""
                register={register}
                errors={formState.errors}
                validations={{ required: 'Pilih Propinsi Domisili' }}
                options={arr_propinsi}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="Kota Domisili"
                type="select"
                name="kota_domisili"
                placeholder=""
                register={register}
                errors={formState.errors}
                validations={{ required: 'Pilih Kota/Kabupaten Domisili' }}
                options={optKotaDomisili}
              />
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="ID Sponsor"
                type="text"
                name="id_sponsor"
                readOnly
                placeholder=""
                register={register}
                errors={formState.errors}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="Nama Sponsor"
                type="text"
                name="nama_sponsor"
                placeholder=""
                defaultValue=""
                readOnly
                register={register}
                errors={formState.errors}
              />
            </div>
          </div>
          <hr />

          <button
            type="submit"
            className="btn btn-primary-f btn-user btn-block">
            &nbsp; SIMPAN
          </button>
        </form>
      </CardBasic>
      <div className="mt-4"></div>
      <CardBasic>
        <div className="d-flex justify-content-between">
          <h5 className="card-title mb-0  pt-2">Informasi Pewaris</h5>
          <span
            onClick={() => setModalPewaris(true)}
            className="btn btn-sm btn-light">
            <i className="fas fa-pencil-alt"></i>
          </span>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-6 col-sm-12 mb-2">
            <label className="font-weight-bold mb-0">
              No Identitas (KTP / SIM)
            </label>
            <div>{profile?.no_nik_passpor_penerima}</div>
          </div>
          <div className="col-md-6 col-sm-12 mb-2">
            <label className="font-weight-bold mb-0">
              Nama Lengkap Pewaris
            </label>
            <div>{profile?.nama_lengkap_pewaris}</div>
          </div>
          <div className="col-md-6 col-sm-12 mb-2">
            <label className="font-weight-bold mb-0">No HP</label>
            <div>{profile?.no_mobile_pewaris}</div>
          </div>
          <div className="col-md-6 col-sm-12 mb-2">
            <label className="font-weight-bold mb-0">Hubungan</label>
            <div>{profile?.hubungan_pewaris}</div>
          </div>
        </div>
      </CardBasic>
      <div className="mt-4"></div>
      <CardBasic>
        <div className="d-flex justify-content-between">
          <h5 className="card-title mb-0  pt-2">Rekening Bank</h5>
          <span
            onClick={() => setModalBank(true)}
            className="btn btn-sm btn-light">
            <i className="fas fa-pencil-alt"></i>
          </span>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-6 col-sm-12 mb-2">
            <label className="font-weight-bold mb-0">
              Nama Pemilik Rekening
            </label>
            <div>{profile?.nama_rekening}</div>
          </div>
          <div className="col-md-6 col-sm-12 mb-2">
            <label className="font-weight-bold mb-0">Nama Bank</label>
            <div>{profile?.bank}</div>
          </div>
          <div className="col-md-6 col-sm-12 mb-2">
            <label className="font-weight-bold mb-0">Nomor Rekening</label>
            <div>{profile?.no_rekening}</div>
          </div>
          <div className="col-md-6 col-sm-12 mb-2">
            <label className="font-weight-bold mb-0">Bank Cabang</label>
            <div>{profile?.cabang}</div>
          </div>
          <div className="col-sm-12 mb-2">
            <label className="font-weight-bold mb-0">Status</label>
            <div>
              <span
                className={`badge badge-${profile?.status_rekening === 'VERIFIED' ? 'success' : 'danger'
                  }`}>
                {profile?.status_rekening}
              </span>
            </div>
          </div>
          <div className="col-sm-12 mb-2">
            {profile?.otp_bank && profile.status_rekening === 'UNVERIFIED' && (
              <Alert
                className="p-2 d-flex justify-content-between"
                color="info">
                Silakan verifikasi dengan klik link yang dikirimkan ke alamat
                email.{' '}
                <button
                  onClick={() => resendVerifikasiBank()}
                  type="button"
                  className="btn btn-info btn-sm">
                  Kirim Ulang
                </button>
              </Alert>
            )}
          </div>
        </div>
      </CardBasic>
      <div className="mt-4"></div>
      <CardBasic>
        <div className="d-flex justify-content-between">
          <h5 className="card-title mb-0  pt-2">Nomor Pajak (NPWP)</h5>
          <span
            onClick={() => setModalNpwp(true)}
            className="btn btn-sm btn-light">
            <i className="fas fa-pencil-alt"></i>
          </span>
        </div>
        <hr />
        <div className="mb-2">
          <Alert className="p-2 d-flex justify-content-between" color="warning">
            {`Perubahan data NPWP setelah terhitung bonus (bonus keluar), akan efektif berlaku pada bulan berikutnya 
            (1 ${month[new Date().getMonth() + 1]
              } ${new Date().getFullYear()} )`}
          </Alert>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 mb-2">
            <label className="font-weight-bold mb-0">NPWP</label>
            <div>{formatNpwp(profile?.npwp)}</div>
          </div>
          <div className="col-md-6 col-sm-12 mb-2">
            <label className="font-weight-bold mb-0">Nama Pemegang Pajak</label>
            <div>{profile?.nama_pemegang_pajak}</div>
          </div>
          <div className="col-sm-12 mb-2">
            <label className="font-weight-bold mb-0">Status</label>
            <div>
              <span
                className={`badge badge-${profile?.status_npwp === 'VERIFIED' ? 'success' : 'danger'
                  }`}>
                {profile?.status_npwp}
              </span>
            </div>
          </div>
          <div className="col-sm-12 mb-2">
            {profile?.otp_npwp &&
              profile?.npwp_pending?.status === 'UNVERIFIED' && (
                <Alert
                  className="p-2 d-flex justify-content-between"
                  color="info">
                  Silakan verifikasi dengan klik link yang dikirimkan ke alamat
                  email, sebelum link expired:
                  {` ${formatDate(profile?.otp_npwp?.expire_at)} `} (Jika link
                  telah expired silahkan kirim ulang)
                  <button
                    onClick={() => resendVerifikasiNpwp()}
                    type="button"
                    className="btn btn-info btn-sm">
                    Kirim Ulang
                  </button>
                </Alert>
              )}
            {profile?.npwp_pending && (
              <>
                <div>
                  <div className="font-weight-bold mb-2">
                    Perubahan NPWP Pending
                  </div>
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td style={{ width: '130px' }}>Nomor</td>
                        <td style={{ width: '10px' }}>:</td>
                        <td>{formatNpwp(profile?.npwp_pending?.npwp)}</td>
                      </tr>
                      <tr>
                        <td style={{ width: '130px' }}>Nama</td>
                        <td style={{ width: '10px' }}>:</td>
                        <td>{profile?.npwp_pending?.nama_pemegang_pajak}</td>
                      </tr>
                      <tr>
                        <td style={{ width: '130px' }}>Status Verifikasi</td>
                        <td style={{ width: '10px' }}>:</td>
                        <td>{profile?.npwp_pending?.status}</td>
                      </tr>
                      <tr>
                        <td style={{ width: '130px' }}>Tanggal Ubah</td>
                        <td style={{ width: '10px' }}>:</td>
                        <td>
                          {formatDate2(
                            profile?.npwp_pending?.created_at,
                            false,
                            false,
                            true
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: '130px' }}>Efektif Berlaku</td>
                        <td style={{ width: '10px' }}>:</td>
                        <td>
                          {profile?.isBonusAvl ? (
                            `01/${EfektifBerlaku.slice(
                              -2
                            )}/${new Date().getFullYear()}`
                          ) : (
                            <i>
                              Akan berlaku setelah anda melakukan verifikasi.
                              Segera lakukan verifikasi!
                            </i>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      </CardBasic>

      <div className="mt-4"></div>
      <CardBasic>
        <div className="d-flex justify-content-between">
          <h5 className="card-title mb-0  pt-2">Auto Withdraw</h5>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-6 col-sm-12 mb-2">
            <div className='d-flex withdraw' style={{ gap: "4px" }}>
              <label>OFF</label>
              <label className="toggle-switch">
                <input type="checkbox" checked={isOnToggle} onChange={() => handleToogle} />
                <span className="switch" onClick={handleToogle} />
              </label>
              <label>ON</label>
              {/* <label>
                {
                  isOnToggle === false ? "Aktifkan Auto Withdraw" : "Nonaktifkan Auto Withdraw"
                }
              </label> */}
            </div>
          </div>
        </div>
      </CardBasic>


      {/* --------- Start Handle modal auto withdraw --------- */}
      <ModalAutoWithdraw
        handleShow={isModalWithdraw}
        handleClose={handleCloseModal}
        modalOption={{
          data: profile
        }}
        initialToggleState={toggleData}
      />
      {/* --------- Start Handle modal auto withdraw --------- */}

      <ModalFormBank
        show={modalBank}
        modalOption={{
          listBank: listBank,
          title: 'Edit Rekening Bank',
          data: profile,
        }}
        onHide={() => setModalBank(false)}
      />
      <ModalFormEditNpwp
        show={modalNpwp}
        modalOption={{
          title: 'Edit Nomor Pajak (NPWP)',
          data: { profile, isBonusAvl },
        }}
        onHide={() => setModalNpwp(false)}
      />
      <ModalFormPewaris
        show={modalPewaris}
        modalOption={{
          title: 'Edit Informasi Pewaris',
          data: { profile, isBonusAvl },
        }}
        onHide={() => setModalPewaris(false)}
      />
    </div>
  );
};

export const ModalFormBank = (props) => {
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: formState2,
    setValue: setValue2,
    control,
  } = useForm({ mode: 'onBlur' });
  const dispatch = useDispatch();
  const { color } = props;
  const { title, data, listBank } = props.modalOption;

  useEffect(() => {
    setValue2('nama_rekening', data?.nama_rekening || '');
    setValue2('no_rekening', data?.no_rekening || '');
    setValue2(
      'bank',
      listBank?.filter((item) => item.value === data?.kode_bank, [])[0] || null
    );
    setValue2('cabang', data?.cabang || '');
  }, [data, setValue2]);

  const submitRekening = (formData) => {
    dispatch(
      updateAccountRekening(data.public_id, {
        ...formData,
        bank: formData.bank.value,
      })
    );
  };

  return (
    <Modal
      {...props}
      size={`lg`}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header style={{ background: '#52567a' }}>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ color: 'white' }}>
          <strong>{title}</strong>
        </Modal.Title>
        <span className="btn" onClick={props.onHide}>
          &times;
        </span>
      </Modal.Header>
      <Modal.Body>
        <Alert className="p-2" color="danger">
          Mengubah rekening adalah tanggung jawab dari pengguna masing masing,
          mohon diperhatikan kembali untuk data yang dimasukkan.
        </Alert>
        <form key={'update rekening'} onSubmit={handleSubmit2(submitRekening)}>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="Nama Pemilik Rekening"
                type="text"
                name="nama_rekening"
                placeholder=""
                defaultValue=""
                validations={{
                  required: 'Masukkan nama pemilik rekening',
                  pattern: {
                    value: /^[a-zA-Z\s]*$/,
                    message:
                      'Nama pemilik rekening hanya boleh terdiri dari huruf dan spasi',
                  },
                }}
                register={register2}
                errors={formState2.errors}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="Nomor Rekening"
                type="text"
                name="no_rekening"
                validations={{
                  required: 'Masukkan nomor rekening',
                  pattern: {
                    value: /^\d{10,20}$/,
                    message:
                      'Nomor rekening harus terdiri dari 10 hingga 20 digit angka',
                  },
                }}
                placeholder=""
                register={register2}
                errors={formState2.errors}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <Controller
                name={`bank`}
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    layout="vertical"
                    name={`bank`}
                    label={`Nama Bank`}
                    register={register2}
                    errors={formState2.errors}
                    field={field}
                    options={listBank || []}
                    isMulti={false}
                  />
                )}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="Bank Cabang"
                type="text"
                name="cabang"
                validations={{
                  pattern: {
                    value: /^[a-zA-Z0-9\s]*$/,
                    message:
                      'Bank Cabang hanya boleh terdiri dari huruf, angka, dan spasi',
                  },
                }}
                placeholder=""
                register={register2}
                errors={formState2.errors}
              />
            </div>
          </div>
          <hr />
          <div className="w-100 d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={props.onHide}>
              Tutup
            </button>
            <button type="submit" className="btn btn-primary-f btn-user">
              Simpan
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export const ModalFormEditNpwp = (props) => {
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: formState2,
    setValue: setValue2,
  } = useForm({ mode: 'onBlur' });
  const dispatch = useDispatch();
  const { color } = props;
  const { title, data } = props.modalOption;
  const { profile, isBonusAvl, listBank, formResult } = useSelector(
    (state) => state.account
  );

  const submitNpwp = (formData) => {
    formData.npwp = formData.npwp.replace(/\D/g, ''); // remove all chars except numbers
    dispatch(updateAccountNpwp(data.profile.public_id, { ...formData }));
  };

  return (
    <Modal
      {...props}
      size={`lg`}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header style={{ background: '#52567a' }}>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ color: 'white' }}>
          <strong>{title}</strong>
        </Modal.Title>
        <span className="btn" onClick={props.onHide}>
          &times;
        </span>
      </Modal.Header>
      <Modal.Body>
        <Alert className="p-2" color="danger">
          Mengubah NPWP adalah tanggung jawab dari pengguna masing-masing, mohon
          diperhatikan kembali untuk data yang dimasukkan. <br /> <br />
          Anda dapat mengubah data NPWP, akan tetapi jika telah terhitung bonus
          maka perubahan data akan efektif pada bulan berikutnya.
          <br /> <br />
          Untuk Pengisian yang menggunakan NPWP ayah, maka isinya:{' '}
          {'<<nama Ayah>> qq <<nama Anda>>'}
        </Alert>
        <form key={'update npwp'} onSubmit={handleSubmit2(submitNpwp)}>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="NPWP"
                type="text"
                name="npwp"
                readOnly={data?.isBonusAvl?.status}
                placeholder=""
                defaultValue={profile?.npwp}
                register={register2}
                validations={{
                  pattern: {
                    value: /^\d{15,16}$/,
                    message:
                      'NPWP harus terdiri dari 15 hingga 16 digit angka tanpa spasi',
                    transform: (value) => value.replace(/\s/g, ''),
                  },
                }}
                errors={formState2.errors}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="Nama Pemegang Pajak"
                type="text"
                name="nama_pemegang_pajak"
                placeholder=""
                defaultValue={profile?.nama_pemegang_pajak}
                validations={{
                  pattern: {
                    value: /^[a-zA-Z\s]*$/,
                    message:
                      'Nama Pemegang Pajak hanya boleh terdiri dari huruf dan spasi',
                  },
                }}
                register={register2}
                errors={formState2.errors}
              />
            </div>
          </div>
          <hr />
          <div className="w-100 d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={props.onHide}>
              Tutup
            </button>
            <button type="submit" className="btn btn-primary-f btn-user">
              Simpan
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export const ModalFormPewaris = (props) => {
  const {
    register: register3,
    handleSubmit: handleSubmit3,
    formState: formState3,
    setValue: setValue3,
  } = useForm({ mode: 'onBlur' });
  const dispatch = useDispatch();
  const { color } = props;
  const { title, data } = props.modalOption;
  const { profile } = useSelector((state) => state.account);

  useEffect(() => {
    setValue3('no_nik_passpor_penerima', profile?.no_nik_passpor_penerima);
    setValue3('nama_lengkap_pewaris', profile?.nama_lengkap_pewaris);
    setValue3('no_mobile_pewaris', profile?.no_mobile_pewaris);
    setValue3('hubungan_pewaris', profile?.hubungan_pewaris);
  }, [data, setValue3]);

  const submitPewaris = (formData) => {
    dispatch(updateInformasiPewaris(data.profile.public_id, { ...formData }));
  };

  return (
    <Modal
      {...props}
      size={`lg`}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header style={{ background: '#52567a' }}>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ color: 'white' }}>
          <strong>{title}</strong>
        </Modal.Title>
        <span className="btn" onClick={props.onHide}>
          &times;
        </span>
      </Modal.Header>
      <Modal.Body>
        <Alert className="p-2" color="danger">
          Mengubah informasi pewaris adalah tanggung jawab dari pengguna masing
          - masing, mohon diperhatikan kembali untuk data yang dimasukkan.
        </Alert>
        <form key={'update npwp'} onSubmit={handleSubmit3(submitPewaris)}>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="No Identitas (KTP / SIM)"
                type="text"
                name="no_nik_passpor_penerima"
                placeholder="(Opsional)"
                validations={{
                  validate: (value) => {
                    if (!value) return true;
                    const val = value?.toString().replaceAll(' ', '');
                    if (value.length > 0) {
                      if (val.length !== 16)
                        return 'No Identitas harus diisi 16 digit';
                    }

                    return true;
                  },
                  onChange: (e) => {
                    let val = e.target.value
                      .toString()
                      .replace(/\D/g, '')
                      .replaceAll(' ', '');
                    e.target.value = val;
                  },
                }}
                defaultValue={profile?.no_nik_passpor_penerima}
                register={register3}
                errors={formState3.errors}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="Nama Lengkap Pewaris"
                type="text"
                name="nama_lengkap_pewaris"
                placeholder="(Opsional)"
                validations={{
                  pattern: {
                    value: /^[a-zA-Z\s]*$/,
                    message:
                      'Nama lengkap pewaris hanya boleh terdiri dari huruf dan spasi',
                  },
                }}
                defaultValue={profile?.nama_lengkap_pewaris}
                register={register3}
                errors={formState3.errors}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="No HP"
                type="text"
                name="no_mobile_pewaris"
                placeholder="(Opsional)"
                defaultValue={profile?.no_mobile_pewaris}
                validations={{
                  validate: (value) => {
                    if (!value) return true;
                    const val = value?.toString().replaceAll(' ', '');
                    if (value.length > 0) {
                      if (!val.startsWith('628'))
                        return 'Nomor tidak valid, contoh: 62 8xx xxx xxx';
                      if (val.length < 11) return 'Nomor minimal 10 digit';
                      if (val.length > 14) return 'Nomor maksimal 14 digit';
                    }
                    return true;
                  },
                  onChange: (e) => {
                    let val = e.target.value
                      .toString()
                      .replace(/\D/g, '')
                      .replaceAll(' ', '');
                    e.target.value = val;
                  },
                }}
                register={register3}
                errors={formState3.errors}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <TextInput
                layout="vertical"
                label="Hubungan"
                type="text"
                name="hubungan_pewaris"
                placeholder="(Opsional)"
                defaultValue={profile?.hubungan_pewaris}
                validations={{
                  pattern: {
                    value: /^[a-zA-Z\s]*$/,
                    message:
                      'Hubungan pewaris hanya boleh terdiri dari huruf dan spasi',
                  },
                }}
                register={register3}
                errors={formState3.errors}
              />
            </div>
          </div>
          <hr />
          <div className="w-100 d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={props.onHide}>
              Tutup
            </button>
            <button type="submit" className="btn btn-primary-f btn-user">
              Simpan
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

// --------- Start Handle modal auto withdraw ---------
export const ModalAutoWithdraw = ({ handleShow, handleClose, modalOption, initialToggleState }) => {

  const dispatch = useDispatch();
  const { data } = modalOption

  const putDataWithdraw = () => {
    dispatch(updateAccountWithdraw(data.public_id, {
      auto_widraw: initialToggleState
    }))
    handleClose(initialToggleState)
  }

  const closeModal = () => {
    handleClose(!initialToggleState)
  }

  return (
    <>
      <Modal show={handleShow} onHide={() => handleClose(initialToggleState)}>
        <Modal.Header >
          <Modal.Title>Konfirmasi Withdraw</Modal.Title>
        </Modal.Header>
        <Modal.Body>{initialToggleState === false ? "Klik YA jika anda yakin untuk menonaktifkan auto withdraw !" : "Klik YA jika anda yakin untuk mengaktifkan auto withdraw !"}</Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Tutup
          </Button>
          <Button variant="primary" onClick={putDataWithdraw}>
            Ya
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
// --------- Start Handle modal auto withdraw ---------

export default Profile;
