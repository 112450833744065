import React, { useEffect, useState } from 'react';
import PageHeading from '../../../components/PageHeading';
import { useDispatch, useSelector } from 'react-redux';
import CardBasic from '../../../components/Cards/Basic';
import { formatCurrency } from '../../../constants/utility';
import Skeleton from 'react-loading-skeleton';
import TextInput from '../../../components/TextInput';
import { Controller, useForm } from 'react-hook-form';
import { fetchBarang, fetchGudangStok, fetchShipping, setFormOrder } from '../../../redux/actions/transaction';
import { createAccountAddress, fetchAccountAddress, updateAccountAddress } from '../../../redux/actions/account';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { arr_kabupaten, arr_kecamatan, arr_propinsi } from '../../../assets/js/alamat';
import { deleteAccountAddress } from '../../../redux/actions/api';
import CardAlamat from '../RiwayatTransaksi/CreateTransaksi/component/CardAlamat';
import ModalDetailItem from '../RiwayatTransaksi/CreateTransaksi/component/ModalDetailItem';

const CekOngkir = (props) => {
  const {
    register,
    formState: { errors },
    setValue,
    control,
    watch,
    handleSubmit
  } = useForm({ mode: "onChange" });

  const dispatch = useDispatch();
  const { title } = props.data;
  const { address, formResult } = useSelector((state) => state.account);
  const [detailProduct, setDetailProduct] = useState(false)
  const { barang, formOrder, gudang, shipping } = useSelector((state) => state.transaction);
  const [posisi, setPosisi] = useState('PLATINUM')
  const [carts, setCarts] = useState([]);
  const [selected, setSelected] = useState(null);
  const [alamatasal, setAlamatAsal] = useState(null);
  const [modal, setModal] = useState(false)
  const [optLayanan, setOptLayanan] = useState([]);
  const [optEkspedisi, setOptEkspedisi] = useState([]);
  const [myEkspedisi, setMyEkspedisi] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [optKabupaten, setOptKabupaten] = useState([]);
  const [optKecamatan, setOptKecamatan] = useState([]);
  const [formAddress, setFormAddress] = useState(null);
  const watchEkspedisi = watch("ekspedisi");
  const [subsidiOngkir, setSubsidiOngkir] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    if (isOpen) {
      setOptKabupaten([]);
      setOptKecamatan([]);
    }
  }, [isOpen]);

  const watchPropinsi = watch("propinsi");
  const watchKota = watch("kota");
  useEffect(() => {
    const key = parseInt(watchPropinsi);

    if (key > 0) {
      var newArray = arr_kabupaten.filter(function (e) {
        return e.prop === key;
      });
      setOptKabupaten(newArray);
      setValue("kota", null);
      setValue("kecamatan", null);
    }
  }, [watchPropinsi, setValue]);

  useEffect(() => {
    const key = parseInt(watchKota);
    if (key > 0) {
      var newArray = arr_kecamatan.filter(function (e) {
        return e.kab === key;
      });
      setOptKecamatan(newArray);
    }
  }, [watchKota, optKabupaten]);


  useEffect(() => {
    if (formAddress?.public_id) {
      setValue("public_id", formAddress?.public_id || "");
    }
  }, [formAddress, setValue]);
  useEffect(() => {
    dispatch(fetchBarang());
  }, [dispatch]);


  const handleViewItemDetail = (index, e) => {
    if (e.target.type === 'button') return;
    setModalData(index);
    setDetailProduct(true);
  };

  const handleQty = (item, qty) => {
    const totalbv = carts?.reduce((a, b) => a + b.qty * b.value, 0) + qty * item.value;
    let bvmax

    if (posisi === 'BRONZE') {
      bvmax = 1
    } else if (posisi === 'SILVER') {
      bvmax = 2
    } else if (posisi === 'PLATINUM') {
      bvmax = 4
    }


    if (totalbv > bvmax && bvmax > 0) return;
    if (qty === 0) {
      const tmpCarts = carts?.filter((a) => a.public_id !== item.public_id, []);
      setCarts(tmpCarts);
    } else {
      const check = carts?.find((a) => a.public_id === item.public_id);
      if (!check) {
        setCarts([
          ...carts,
          {
            public_id: item.public_id,
            nama: item.nama,
            harga: item.harga,
            value: item.value,
            berat: item.berat,
            dpp: item.dpp,
            persen_ppn: item.persen_ppn,
            qty: qty,
          },
        ]);
      } else {
        const tmpCarts = carts
          ?.map((a) => {
            if (a.public_id === item.public_id) {
              return {
                ...a,
                qty: a.qty + qty,
              };
            }
            return a;
          }, [])
          .filter((filter) => filter.qty > 0, []);

        setCarts(tmpCarts);
      }
    }
  };
  const [disableMax, setDisambleMax] = useState(false);

  useEffect(() => {
    const totalbv = carts?.reduce((a, b) => a + b.qty * b.value, 0);
    let bvmax
    if (posisi === 'BRONZE') {
      bvmax = 1
    } else if (posisi === 'SILVER') {
      bvmax = 2
    } else if (posisi === 'PLATINUM') {
      bvmax = 4
    }

    setDisambleMax(totalbv >= bvmax && bvmax > 0);
  }, [carts, posisi]);

  const posisiOpt = [
    { value: "PLATINUM", label: "PLATINUM", bv: 4 },
    { value: "SILVER", label: "SILVER", bv: 2 },
    { value: "BRONZE", label: "BRONZE", bv: 1 },
  ];


  const watchGudang = watch("gudang_id");
  const watchJenisPengiriman = watch("jenis_pengiriman");
  const [valueItem, setValueItem] = useState(4)


  const handlePosisiChange = (e) => {
    const selectedPosisi = posisiOpt.find(option => option.value === e.target.value);
    setPosisi(selectedPosisi.value);
    setValueItem(selectedPosisi.bv);
    setCarts([]);
    setOptEkspedisi([]);
    setOptLayanan([]);
  };


  useEffect(() => {
    dispatch(fetchAccountAddress());
  }, [dispatch, formResult]);


  useEffect(() => {
    if (carts?.length && watchJenisPengiriman) {
      dispatch(
        fetchGudangStok({
          carts: carts.map(({ public_id: id, qty }) => ({ id, qty })),
          is_online_pickup: watchJenisPengiriman === "delivery" ? 1 : null,
          is_offline_pickup: watchJenisPengiriman === "pickup" ? 1 : null
        })
      );
    }
  }, [dispatch, carts, watchJenisPengiriman]);


  const closeForm = () => {
    setIsOpen(false);
    setFormAddress(null);
  };

  const closeModal = () => {
    setModal(false);
  };

  const deleteAlamat = () => {
    dispatch(deleteAccountAddress(watch("public_id")));
  };

  const submit = (data) => {
    if (watch("public_id") && watch("public_id") !== "") {
      dispatch(updateAccountAddress(watch("public_id"), data));
    } else {
      if (isChecked === true) {
        dispatch(createAccountAddress(data));
        dispatch(fetchAccountAddress());
        setIsChecked(false);
      } else {
        dispatch(createAccountAddress(data));
      }
    }
    closeForm();
    setModal(true);
  };

  const onSelectAlamat = (data) => {
    setSelected(data);
    setModal(false);

    const tmp = {
      ...formOrder,
      steps: formOrder?.steps?.map((a) => {
        if (a.name === "delivery") {
          return {
            ...a,
            passed: false,
            data: {
              ...data,
              gudang: gudang?.find((a) => a.public_id === watchGudang, null),
            },
          };
        }
        return a;
      }, []),
    };
    dispatch(setFormOrder(tmp));
  };

  useEffect(() => {
    if (
      !selected ||
      carts?.length === 0 ||
      !watchGudang ||
      !gudang ||
      !watchJenisPengiriman ||
      watchJenisPengiriman !== "delivery"
    )
      return;
    const origin = gudang?.find(
      (a) => a.public_id === watchGudang,
      null
    )?.id_kecamatan_kirim;
    if (!origin) return;

    setOptEkspedisi([]);
    setOptLayanan([]);

    dispatch(
      fetchShipping({
        originType: "subdistrict",
        origin: origin,
        destination_id: selected?.kecamatan?.id,
        weight: carts?.reduce((a, b) => a + b.qty * b.berat, 0),
        gudang_id: watchGudang,
      })
    );
  }, [
    selected,
    carts,
    watchGudang,
    gudang,
    watchJenisPengiriman,
    alamatasal,
    dispatch,
  ]);

  useEffect(() => {
    setValue("ekspedisi", "");
    setValue("layanan", "");
  }, [watchGudang, watchJenisPengiriman]);

  useEffect(() => {

    const Ekspedisi = shipping?.courirs
      ?.filter(fil => {
        fil.costs = fil.costs?.filter((layanan) => {
          const estimasiHari = layanan?.cost?.[0]?.etd
          if (!estimasiHari)
            return;
          if (estimasiHari) {
            const estimasiArray = estimasiHari.split('-').map(item => parseInt(item, 10));
            const list = {
              name: fil?.name,
              code: fil?.code,
              description: layanan?.description,
              service: layanan?.service,
              value: layanan?.cost?.[0]?.value,
              etd: parseInt(layanan?.cost?.[0]?.etd?.split('-')),
              etdx: layanan?.cost?.[0]?.etd
            }
            if ([2, 3, 4, 5, 6].some(num => estimasiArray.includes(num))) {
              // Mengutamakan Estimasi Tercepat
              return list
            } else {
              // Tidak Ada Estimasi Tercepat Tetap Tampil Layanan Yang Tersedia
              return parseInt(layanan?.cost?.[0]?.etd?.split('-')) > 6
            }
          }

        });
        return fil.costs.length > 0;
      })
      ?.map(a => {
        const hariTercepat = a?.costs
          ?.reduce((cepat, b) => {
            return parseInt(b?.cost[0]?.etd?.split('-')) < parseInt(cepat?.cost[0]?.etd?.split('-')) ? b : cepat;
          }, a?.costs[0]);
        return {
          code: a?.code,
          name: a?.name,
          estimasi: parseInt(hariTercepat.cost[0].etd?.split('-')),
          price: hariTercepat.cost[0].value,
          costs: [
            {
              cost: [{
                value: hariTercepat.cost[0].value,
                etd: hariTercepat.cost[0].etd,
                note: hariTercepat.cost[0].note
              }],
              description: hariTercepat.description,
              service: hariTercepat.service
            }
          ]
        };
      }, [])?.sort((a, b) =>
        // Mencari Harga Yang Paling Murah
        a?.price - b?.price
      )

    setOptEkspedisi(
      Ekspedisi?.map((a, ix) => {
        return {
          value: a.code === "J&T" ? "jnt" : a.code,
          label: a.name,
          data: a,
        };
      }, []).slice(0, 1)
    )

    const { values, ...datasub } = shipping?.subsidi || {};
    const isGudangSub = values?.find(
      (sub) => sub?.gudang?.public_id === watchGudang
    );

    if (!isGudangSub) return;

    const totalBv = carts?.reduce((a, b) => a + b.qty * b.value, 0);
    const qualifiedBv = isGudangSub.values
      .sort((a, b) => b.bv - a.bv, [])
      .find((a) => totalBv >= a.bv);

    if (!qualifiedBv) return;

    setSubsidiOngkir({
      ...datasub,
      ...isGudangSub,
      nominal:
        datasub?.jenis === "PERSENTASE"
          ? Math.floor(
            (carts?.reduce((a, b) => a + b.qty * b.dpp, 0) *
              (Number(qualifiedBv.value) / 100)) /
            1000
          ) * 1000
          : Number(qualifiedBv.value),
    });
  }, [shipping]);

  const [ongkos, setOngkos] = useState(null)
  useEffect(() => {
    setValue("layanan", "");
    setOptLayanan(
      optEkspedisi
        ?.find((a) => a.value === watchEkspedisi?.value)
        ?.data?.costs?.map((a) => {
          const hari = !a.cost[0]?.etd?.toLowerCase()?.includes("hari")
            ? "Hari"
            : "";
          const cost = a.cost[0]?.value;
          const potongan = subsidiOngkir
            ? Math.min(subsidiOngkir.nominal, cost)
            : 0;
          setOngkos(subsidiOngkir ? (cost - potongan) : cost)
          return {
            value: a.service,
            label: (
              <div
                dangerouslySetInnerHTML={{
                  __html: `
                  <div>${a.service} - ${a.description}. ${a.cost[0]?.etd
                    } ${hari}</div>
                  <div>${subsidiOngkir
                      ? `<strike>${formatCurrency(
                        cost
                      )}</strike>  ${formatCurrency(cost - potongan)}`
                      : formatCurrency(cost)
                    }
                  `,
                }}
              />
            ),
            data: {
              ...a,
              potongan,
            },
          };
        }, [])
    );
    setMyEkspedisi(
      shipping?.ekspedisi?.find((a) => a.kode === watchEkspedisi?.value)
    );
  }, [watchEkspedisi, optEkspedisi, setValue, shipping]);

  useEffect(() => {
    setValue(
      "is_asuransi",
      (myEkspedisi?.asuransi_fix || myEkspedisi?.asuransi_persen) &&
        myEkspedisi.wajib_asuransi
        ? true
        : false
    );
  }, [myEkspedisi, setValue]);

  useEffect(() => {
    if (selected && optEkspedisi) {
      setValue(
        "ekspedisi",
        optEkspedisi?.find((opt) => opt.value === selected?.kodeKurir)
      );
    }
  }, [selected, optEkspedisi, setValue]);

  useEffect(() => {
    if (selected && optLayanan) {
      // Berdasarkan Termurah
      setValue(
        "layanan",
        optLayanan.find((opt) => opt.value === optEkspedisi?.[0]?.data?.costs?.[0]?.service)
      );
    }
  }, [selected, optLayanan, setValue, optEkspedisi]);


  return (
    <>
      <div>
        <PageHeading title={title} />
        <CardBasic>
          <div className="card mb-2 p-3">
            <h5 className='border-bottom py-2 mb-4' style={{ color: '#3d405c' }}>
              Pilih Alamat
            </h5>
            <div className="card-body m-n2">
              {!selected ? (
                <span className='text-secondary'>Silahkan pilih alamat pengiriman</span>
              ) : (
                <>
                  <div>
                    {selected?.nama} ({selected?.phone}){" "}
                    <span className="badge badge-info">{selected?.label}</span>
                  </div>
                  <div>
                    {selected?.alamat}. Kecamatan {selected?.kecamatan?.nama},
                    Kabupaten/Kota {selected?.kota?.nama}, Propinsi{" "}
                    {selected?.propinsi?.nama}. Kode Pos {selected?.kode_pos}
                  </div>
                </>
              )}
              <div className="mt-2">
                <button
                  type="button"
                  className="btn btn-primary-f mb-2 btn-sm"
                  onClick={() => {
                    if (address?.rows?.length > 0) setModal(true);
                    else {
                      setIsOpen(true);
                    }
                  }}
                >
                  {address?.rows?.length > 0 ? "Pilih Alamat" : "Tambah Alamat"}
                </button>
              </div>
            </div>
          </div>

          <div className='border p-3' style={{ borderRadius: "5px" }}>
            <h5 className="border-bottom py-2 mb-4" style={{ color: "#3d405c" }}>
              Pilih Tipe
            </h5>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div class="form-group">
                  <label for="exampleFormControlSelect1">Tipe Calon Member</label>
                  <select
                    className="form-control"
                    id="exampleFormControlSelect1"
                    onChange={handlePosisiChange}
                    value={posisi}
                  >
                    {posisiOpt.map((option) => (
                      <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                  </select>

                </div>
              </div>
            </div>
          </div>
          <div className='border p-3 mt-2' style={{ borderRadius: "5px" }}>
            <h5 className='border-bottom py-2 mb-4' style={{ color: '#3d405c' }}>
              Pilih Paket/Item
            </h5>
            <div className='row row-cols-1 row-cols-sm-2 row-cols-md-4 mt-4'>
              {barang?.rows
                ?.filter((a) => a.value <= valueItem, [])
                ?.sort((a, b) => { return b.value - a.value })
                ?.map((item, index) => {
                  console.log(item)
                  const cart = carts?.find((a) => a.public_id === item.public_id);
                  return (
                    <div className='col mb-4' key={item.public_id}>
                      <div className='card custom-card d-flex'>
                        {item.gambars[0]?.lokasi ? (
                          <img
                            className='card-img-top'
                            src={item.gambars[0]?.lokasi}
                            alt='Card cap'
                            onClick={(e) => handleViewItemDetail(item, e)}
                            style={{ cursor: 'pointer' }}
                          />
                        ) : (
                          <Skeleton height={150} />
                        )}
                        <div className='card-body' style={{ cursor: 'pointer' }}>
                          <h6 className='card-title text-two-line'>{item.nama}</h6>
                          <div className='d-md-flex mb-3 mt-2 justify-content-between d-sm-block'>
                            <div className='card-title font-weight-bold py-1'>
                              {formatCurrency(item.harga)}
                            </div>
                            <div className='d-flex'>
                              <button
                                style={{ width: '25px', height: '30px' }}
                                type='button'
                                className='btn btn-sm btn-primary-f flex'
                                onClick={() => handleQty(item, -1)}
                                disabled={!cart || cart?.qty < 1}
                              >
                                -
                              </button>
                              <span
                                className='border'
                                style={{
                                  width: '35px',
                                  height: '30px',
                                  textAlign: 'center',
                                  padding: '2px',
                                  borderRadius: '2px',
                                  marginRight: '1px',
                                  marginLeft: '1px',
                                }}
                              >
                                {cart?.qty || 0}
                              </span>
                              <button
                                style={{ width: '25px', height: '30px' }}
                                type='button'
                                className='btn btn-sm btn-primary-f'
                                onClick={() => handleQty(item, 1)}
                                disabled={disableMax}
                              >
                                +
                              </button>
                            </div>
                          </div>
                          <span className='badge badge-info badge-phill py-1 px-2'>
                            {item.value} BV
                          </span>
                          <div className=''>{item.deskripsi}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className='border p-3 mt-2' style={{ borderRadius: "5px" }}>
            <h5 className="border-bottom py-2 mb-2" style={{ color: "#3d405c" }}>
              Pilih Metode Pengiriman
            </h5>
            <div className="card mb-2">
              <div className="card-body">
                <div className="mb-4">
                  <TextInput
                    layout="vertical"
                    label="Jenis Pengiriman"
                    type="radio"
                    name="jenis_pengiriman"
                    defaultValue={watch("jenis_pengiriman")}
                    register={register}
                    errors={errors}
                    options={[
                      {
                        value: "delivery",
                        label: "Pengiriman Online",
                        sublabel: "Paket Dikirim menggunakan jasa kurir/ekspedisi",
                      },
                    ]}
                  />
                </div>
                {
                  watchJenisPengiriman === "delivery" ?
                    (
                      <>
                        {
                          selected && (
                            <TextInput
                              layout="vertical"
                              label={
                                watchJenisPengiriman === "delivery"
                                  ? "Asal Pengiriman"
                                  : "Lokasi Pengambilan"
                              }
                              type="radio-kebawah"
                              name="gudang_id"
                              defaultValue={watch("gudang_id")}
                              register={register}
                              errors={errors}
                              options={gudang?.map((a) => {
                                return {
                                  value: a?.public_id,
                                  label: a?.nama,
                                  sublabel: a?.lokasi,
                                };
                              }, [])}
                            />
                          )
                        }

                        <>
                          {
                            !selected && (
                              <div className="alert alert-warning">
                                Anda belum mengatur alamat
                              </div>
                            )
                          }
                          {
                            selected && carts?.length === 0 && (
                              <div className="alert alert-warning">
                                Anda harus memilih item  terlebih dahulu
                              </div>
                            )
                          }
                          {selected && carts?.length > 0 && (
                            <div className="card mb-2">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-sm-12 col-md-4">
                                    <Controller
                                      name={"ekspedisi"}
                                      control={control}
                                      render={({ field }) => (
                                        <TextInput
                                          name={"ekspedisi"}
                                          label="Ekspedisi"
                                          type="react-select"
                                          placeholder="Ekspedisi"
                                          register={register}
                                          errors={errors}
                                          field={field}
                                          options={optEkspedisi}
                                          validations={{ required: true }}
                                        />
                                      )}
                                    />
                                  </div>
                                  <div className="col-sm-12 col-md-5">
                                    <Controller
                                      name={"layanan"}
                                      control={control}
                                      // rules={{ required: true }}
                                      render={({ field }) => (
                                        <TextInput
                                          name={"layanan"}
                                          label="Layanan"
                                          type="react-select"
                                          placeholder="Layanan"
                                          register={register}
                                          errors={errors}
                                          field={field}
                                          options={optLayanan}
                                          validations={{ required: true }}
                                        />
                                      )}
                                    />
                                  </div>
                                  {myEkspedisi ? (
                                    <>
                                      <div className="col-sm-12 col-md-3">
                                        {myEkspedisi?.asuransi_fix ||
                                          myEkspedisi?.asuransi_persen ? (
                                          <TextInput
                                            disabled={myEkspedisi?.wajib_asuransi}
                                            layout="vertical"
                                            label={`Asuransi ${myEkspedisi?.wajib_asuransi
                                              ? "(Wajib)"
                                              : "(Opsional)"
                                              }`}
                                            type="switch"
                                            name="is_asuransi"
                                            placeholder=""
                                            register={register}
                                            errors={errors}
                                            value={watch("is_asuransi")}
                                            options={[
                                              {
                                                value: true,
                                                label: `Ya, ${formatCurrency(
                                                  carts?.reduce(
                                                    (a, b) => a + b.qty * b.harga,
                                                    0
                                                  ) *
                                                  (myEkspedisi?.asuransi_persen / 100) +
                                                  myEkspedisi?.asuransi_fix
                                                )}`,
                                              },
                                              { value: false, label: "Tidak" },
                                            ]}
                                          // validations={{ required: "Pilih Layanan" }}
                                          />
                                        ) : (
                                          <div>
                                            <label className="font-weight-bold">Asuransi</label>
                                            <div
                                              style={{ fontSize: "0.8rem" }}
                                              className="italic"
                                            >
                                              <i>
                                                Tidak ada opsi asuransi untuk ekpedisi{" "}
                                                {myEkspedisi?.nama}
                                              </i>
                                            </div>
                                          </div>
                                        )}
                                      </div>

                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>

                              </div>
                            </div>
                          )}
                        </>
                      </>
                    ) : ""
                }

              </div>
            </div>
          </div>
          <div className='border p-3 mt-2' style={{ borderRadius: "5px" }}>
            <table className='table' style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>Paket</th>
                  <th className='text-right'>Qty</th>
                  <th className='text-right'>BV</th>
                  <th className='text-right'>Harga</th>
                </tr>
              </thead>
              <tbody>
                {carts?.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td>{item.nama}</td>
                      <td className='text-right'>{item.qty}</td>
                      <td className='text-right'>{item.qty * item.value}</td>
                      <td className='text-right'>
                        {formatCurrency(item.harga * item.qty)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <th className='text-right'>
                    {/* SubTotal */}
                  </th>
                  <th className='text-right'>
                    {/* {carts?.reduce((a, b) => a + b.qty, 0)} */}
                  </th>
                  <th className='text-right'>
                    {/* {carts?.reduce((a, b) => a + b.qty * b.value, 0)} */}
                    Sub Total
                  </th>
                  <th className='text-right'>
                    {formatCurrency(carts?.reduce((a, b) => a + b.qty * b.harga, 0))}
                  </th>
                </tr>
                {
                  ongkos !== null && (
                    <tr>
                      <th className='text-right'></th>
                      <th className='text-right'></th>
                      <th className='text-right'>
                        Ongkir
                      </th>
                      <th className='text-right'>
                        {
                          formatCurrency(ongkos)
                        }
                      </th>
                    </tr>
                  )
                }
                {
                  watch('is_asuransi') && (
                    <tr>
                      <th className='text-right'></th>
                      <th className='text-right'></th>
                      <th className='text-right'>
                        Asuransi
                      </th>
                      <th className='text-right'>
                        {
                          myEkspedisi ? formatCurrency(
                            carts?.reduce(
                              (a, b) => a + b.qty * b.harga,
                              0
                            ) *
                            (myEkspedisi?.asuransi_persen / 100) +
                            myEkspedisi?.asuransi_fix
                          ) : ""
                        }

                      </th>
                    </tr>
                  )
                }

                <tr>
                  <th className='text-right'></th>
                  <th className='text-right'></th>
                  <th className='text-right'>
                    Total
                  </th>
                  <th className='text-right'>
                    {
                      formatCurrency(
                        ongkos +
                        (carts?.reduce((a, b) => a + b.qty * b.harga, 0)) +
                        (watch('is_asuransi') ?
                          (
                            carts?.reduce((a, b) => a + b.qty * b.harga, 0) * (myEkspedisi?.asuransi_persen / 100) +
                            myEkspedisi?.asuransi_fix
                          )
                          : 0
                        )
                      )
                    }
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </CardBasic >
        <Modal toggle={() => closeForm()} isOpen={isOpen} centered={true}>
          <form onSubmit={handleSubmit(submit)}>
            <ModalHeader
              toggle={() => closeForm()}
              close={
                <div className="btn close" onClick={() => closeForm()}>
                  ×
                </div>
              }
            >
              {watch("public_id") ? "Ubah" : "Tambah"} Alamat
            </ModalHeader>
            <ModalBody>
              <div className="modal-body">
                <div className="row">
                  <div className="mt-1" style={{ fontSize: "14px" }}>
                    {formOrder?.jenis === "registrasi" && !watch("public_id") ? (
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          checked={isChecked}
                          // onChange={handleOnChange}
                          onChange={() => setIsChecked(!isChecked)}
                        />
                        {` `}Menggunakan Alamat Identitas{" "}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-sm-12 ">
                    <TextInput
                      layout="vertical"
                      label="Label Alamat"
                      type="text"
                      name="label"
                      placeholder=""
                      register={register}
                      errors={errors}
                      validations={{ required: "Masukkan label alamat" }}
                    />
                  </div>

                  <div className="col-sm-12 col-md-6">
                    <TextInput
                      layout="vertical"
                      label="Provinsi"
                      type="select"
                      name="propinsi"
                      placeholder=""
                      register={register}
                      errors={errors}
                      options={arr_propinsi}
                      validations={{ required: "Pilih propinsi" }}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <TextInput
                      layout="vertical"
                      label="Kota"
                      type="select"
                      name="kota"
                      placeholder=""
                      register={register}
                      errors={errors}
                      options={optKabupaten}
                      validations={{ required: "Pilih kota" }}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <TextInput
                      layout="vertical"
                      label="Kecamatan"
                      type="select"
                      name="kecamatan"
                      placeholder=""
                      register={register}
                      errors={errors}
                      options={optKecamatan}
                      validations={{ required: "Pilih Kecamatan" }}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <TextInput
                      layout="vertical"
                      label="Kode Pos"
                      type="text"
                      name="kode_pos"
                      placeholder=""
                      register={register}
                      errors={errors}
                      validations={{ required: "Masukkan Kode Pos" }}
                    />
                  </div>
                  <div className="col-sm-12">
                    <TextInput
                      layout="vertical"
                      label="Alamat Kirim"
                      type="textarea"
                      name="alamat"
                      placeholder=""
                      register={register}
                      errors={errors}
                      validations={{ required: "Masukkan detail alamat" }}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <TextInput
                      layout="vertical"
                      label="Nama Penerima"
                      type="text"
                      name="nama"
                      placeholder=""
                      register={register}
                      errors={errors}
                      validations={{ required: "Masukkan nama penerima" }}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <TextInput
                      layout="vertical"
                      label="No Telp Penerima"
                      type="text"
                      name="phone"
                      placeholder=""
                      register={register}
                      errors={errors}
                      validations={{ required: "Masukkan telpon penerima" }}
                    />
                  </div>
                </div>

                <hr />
                <div className="d-flex justify-content-between">
                  <div className="d-flex justify-content-end">
                    {watch("public_id") && (
                      <button
                        className="btn btn-danger float-right align-content-center mr-1"
                        onClick={() => {
                          deleteAlamat();
                          closeForm();
                        }}
                      >
                        <i className="fas fa-trash fa-lg fa-fw"></i>
                      </button>
                    )}
                    <div
                      className="btn btn-secondary float-right align-content-center"
                      onClick={() => {
                        closeForm();
                      }}
                    >
                      Tutup
                    </div>
                  </div>
                  <button className="btn btn-primary-f float-right align-content-center">
                    Simpan
                  </button>
                </div>
              </div>
            </ModalBody>
          </form>
        </Modal>
        <Modal
          toggle={() => closeModal()}
          isOpen={modal}
          centered={true}
          size={"lg"}
        >
          <ModalHeader
            toggle={() => closeModal()}
            close={
              <div className="btn close" onClick={() => closeModal()}>
                ×
              </div>
            }
          >
            Pilih Alamat
          </ModalHeader>
          <ModalBody>
            <div className="modal-body">
              <button
                type="button"
                className="btn btn-primary-f mb-2 btn-sm"
                onClick={() => {
                  setIsOpen(true);
                  // reset();
                }}
              >
                Tambah Alamat
              </button>
              <div className="list-group mb-4">
                {address?.rows?.map((item) => {
                  return (
                    <CardAlamat
                      data={item}
                      selected={selected}
                      onSelectAlamat={onSelectAlamat}
                      action={true}
                      setFormAddress={setFormAddress}
                      setIsOpen={setIsOpen}
                    // handleClick={handleClick}
                    // handleChooseAlamat={handleChooseAlamat}
                    />
                  );
                })}
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div >
      <ModalDetailItem
        data={modalData}
        isOpen={detailProduct}
        setIsOpen={setDetailProduct}
        handleSubmit={handleSubmit}
        submit={(data) => console.log(data)}
      />
    </>
  );
};



export default CekOngkir;
