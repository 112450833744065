import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {Link, Navigate, useSearchParams} from 'react-router-dom';
import logoBlack from '../../assets/img/logo-black.png'
import TextInput from '../../components/TextInput';
import * as actions from '../../redux/actions';
import { useForm } from "react-hook-form";
import { MainWrapper } from '../MainWrapper';
import { setResetPassword } from '../../redux/actions/login';

const ResetPassword = () => {
    const { loading, resetPassword } = useSelector((state) => state.auth);
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    
    const dispatch = useDispatch();
    const { register, handleSubmit, formState, watch} = useForm({mode:'onchange'});

    const handleResetPassword =(data) =>{
        data['token'] = token;
        dispatch(actions.resetPassword(data));
    }

    useEffect(() => {
      dispatch(setResetPassword(null))
    }, [dispatch])
    const password = useRef({});
    password.current = watch("password", "");

    if(resetPassword){
        return <Navigate to="/signin" />
    }

    return (
        <div className="container">
        {/* <!-- Outer Row --> */}
            <div className="row justify-content-center">
        
                <div className="col-xl-5 col-lg-5 col-md-10">
            
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="p-5">
                                <div className="text-center">
                                        <div className="top-brand">
                                    <a href="/">
                                            <img className="top-brand-img" src={logoBlack} width="50" alt="logo" />
                                            <div className="top-brand-text">Reset Password</div>
                                    </a>
                                        </div>
                                </div>
                                <hr/>
                                
                                <form onSubmit={handleSubmit(handleResetPassword)} className="user">
                                    <TextInput
                                        layout="vertical"
                                        placeholder="Password Baru"
                                        type="password"
                                        name="password"
                                        register={register}
                                        validations={{
                                        required: "Masukkan pasword baru",
                                        minLength: {
                                            value: 8,
                                            message: "Password setidaknya 8 karakter",
                                        },
                                        }}
                                        errors={formState.errors}
                                        className="form-control-user"
                                    />

                                    <TextInput
                                        layout="vertical"
                                        placeholder="Ulangi Password Baru"
                                        type="password"
                                        name="rpassword"
                                        register={register}
                                        validations={{
                                            required: "Ulangi pasword baru",
                                            validate: (value) =>
                                            value === password.current || "Password tidak sama",
                                        }}
                                        errors={formState.errors}
                                        className="form-control-user"
                                        />
                                    <button 
                                        disabled={loading}
                                        type="submit" 
                                        className="btn btn-primary-f btn-user btn-block"> 
                                        <i className={`fas ` + (!loading ? `fa-sync` : `fa-spinner fa-spin`)}></i> 
                                        &nbsp; RESET PASSWORD
                                    </button>
                                    {/* fa-spinner fa-spin */}
                                </form>
                               
                                <hr/>
                                <div className="text-center">
                                    <Link className="small" to="/signin">Signin</Link>
                                </div>
                            </div>
                        
                        </div>
                    </div>
            
                </div>
        
            </div>
    
        </div>
    )
}
export default MainWrapper(ResetPassword);