import store from "../redux/store";
//update object immutably
export const updateObject = (oldState, updatedProperties) => {
    return {
        ...oldState,
        ...updatedProperties,
    };
};

// Get FormData values
export const getFormData = (formElement) => {
    const formData = new FormData(formElement);
    const values = {};
    for (const [key, value] of formData) {
        values[key] = value;
    }
    return values;
};

/** Format currency number */
export const formatCurrency = (number) => {
    const text = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
    }).format(number || 0);

    return text.replace(/\xa0/g, " ").replace(/\u202f/g, " ").replace(" ", "");
};

/** Format date locale */
export const formatDate = (date, hideTime = false, short = false, hideDay = false) => {
    let dateObj = Date.parse(date);

    if (!dateObj) {
        dateObj = new Date(Date.now());
    }

    return new Intl.DateTimeFormat("id", {
        weekday: !short && !hideDay ? "long" : undefined,
        day: "numeric",
        month: !short ? "long" : "short",
        year: "numeric",
        hour: !hideTime ? "numeric" : undefined,
        minute: !hideTime ? "numeric" : undefined,
    }).format(dateObj);
};

/** Format date locale */
export const formatDateOnly = (date) => {
    let dateObj = Date.parse(date);

    if (!dateObj) {
        dateObj = new Date(Date.now());
    }

    return new Intl.DateTimeFormat("id", {
        day: "numeric",
        month: "short",
        year: "numeric",
    }).format(dateObj);
};

export const formatMonthYear = (date) => {
    let dateObj = Date.parse(date);

    if (!dateObj) {
        dateObj = new Date(Date.now());
    }

    return new Intl.DateTimeFormat("id", {
        month: "long",
        year: "numeric",
    }).format(dateObj);
};

export const formatTime = (date) => {
    const dateObj = Date.parse(date);

    if (!dateObj) {
        return "-";
    }

    return new Intl.DateTimeFormat("id", {
        hour: "numeric",
        minute: "numeric",
    }).format(dateObj);
};

const objectToFormData = (formData, data, key) => {
    if ((typeof data === "object" && data !== null) || Array.isArray(data)) {
        for (let i in data) {
            if (
                (typeof data[i] === "object" && data[i] !== null) ||
                Array.isArray(data[i])
            ) {
                objectToFormData(formData, data[i], key + "[" + i + "]");
            } else {
                formData.append(key + "[" + i + "]", data[i]);
            }
        }
    } else {
        formData.append(key, data);
    }
};

export const createFormData = (data) => {
    const form = new FormData();

    for (let key in data) {
        const item = data[key];
        if (typeof item !== "object") {
            form.append(key, item);
            console.log('xyf', key, item);
        } else {
            objectToFormData(form, item, key);
        }
    }

    return form;
};

export function formatNpwp(value) {
    if (typeof value === 'string') {
        return value.replace(/(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3})/, '$1.$2.$3.$4-$5.$6');
    }
}

export const formatDate2 = (date, hideTime = false, short = false, custom = false) => {
    let dateObj = Date.parse(date);

    if (!dateObj) {
        dateObj = new Date(Date.now());
    }

    if (custom) {
        return new Intl.DateTimeFormat(['ban', 'id'], {
            // weekday : 'long',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            // hour : 'numeric',
            // minute : 'numeric',
        }).format(dateObj)
        // return new Intl.DateTimeFormat("id", {
        //   weekday: !short ? "long" : undefined,

        // }).format(dateObj);
    }

    return new Intl.DateTimeFormat("id", {
        weekday: !short ? "long" : undefined,
        day: "numeric",
        month: !short ? "long" : "short",
        year: "numeric",
        hour: !hideTime ? "numeric" : undefined,
        minute: !hideTime ? "numeric" : undefined,
    }).format(dateObj);
};

export const canAccess = (menu, action) => {
    const {data: userData} = store.getState().auth;
    // console.log("userData", userData)
    return userData?.accesses?.[menu]?.includes(action)
}
  