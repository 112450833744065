import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Select from 'react-select';
import CardBasic from '../../../components/Cards/Basic';
import DatePicker from '../../../components/DatePicker';
import {
  formatCurrency,
  formatDate,
  formatDateOnly,
} from '../../../constants/utility';
import PageHeading from '../../../components/PageHeading';
import PrintingHeader from '../../../components/PrintingHeader';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import * as actions from '../../../redux/actions';
import {
  fetchDownloadBupot,
  fetchReportOmset,
  fetchReportPPh,
  setReportOmset,
} from '../../../redux/actions/report';

const PPhReport = (props) => {
  const { name, title, parent } = props.data;
  const { profile } = useSelector((state) => state.account);
  const { reportPPh, formResult } = useSelector((state) => state.report);
  const dispatch = useDispatch();

  let date = new Date();
  const [filterDate, setFilterDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );

  useEffect(() => {
    dispatch(
      actions.setMenuActive({ menuActive: parent || name, subMenuActive: name })
    );
  }, [dispatch, formResult, name, parent]);

  const getReport = () => {
    const params = {
      id_member: profile?.id_member,
      year: filterDate.getFullYear(),
    };
    dispatch(fetchReportPPh(params));
  };

  const downloadBupot = (id) => {
    dispatch(fetchDownloadBupot(id));
  };

  useEffect(() => {
    getReport();
  }, []);

  const columns = [
    {
      dataField: 'masa_pajak',
      text: 'Masa',
      headerClasses: 'p-1 text-center',
      classes: 'p-1 text-center',
    },
    {
      dataField: 'tahun_pajak',
      text: 'Tahun',
      headerClasses: 'p-1 text-center',
      classes: 'p-1 text-center',
    },
    {
      dataField: 'pembetulan',
      text: 'Pb',
      headerClasses: 'p-1 text-center',
      classes: 'p-1 text-center',
    },
    {
      dataField: 'no_bukti',
      text: 'Nomor',
      headerClasses: 'p-1 text-center',
      classes: 'p-1 text-center',
    },
    {
      dataField: 'npwp',
      text: 'NPWP',
      headerClasses: 'p-1 text-center',
      classes: 'p-1 text-center',
    },
    {
      dataField: 'nik',
      text: 'NIK',
      headerClasses: 'p-1 text-center',
      classes: 'p-1 text-center',
    },
    {
      dataField: 'nama',
      text: 'Nama',
      headerClasses: 'p-1 text-center',
      classes: 'p-1 text-center',
    },
    {
      dataField: 'kode_pajak',
      text: 'Kode',
      headerClasses: 'p-1 text-center',
      classes: 'p-1 text-center',
    },
    {
      dataField: 'jumlah_bruto',
      text: 'Bruto',
      headerClasses: 'p-1 text-center',
      classes: 'p-1 text-center',
      formatter: (val) => formatCurrency(val),
    },
    {
      dataField: 'jumlah_dpp',
      text: 'DPP',
      headerClasses: 'p-1 text-center',
      classes: 'p-1 text-center',
      formatter: (val) => formatCurrency(val),
    },
    {
      dataField: 'jumlah_pph',
      text: 'PPh',
      headerClasses: 'p-1 text-center',
      classes: 'p-1 text-center',
      formatter: (val) => formatCurrency(val),
    },
    {
      dataField: 'tarif',
      text: 'Tarif',
      headerClasses: 'p-1 text-center',
      classes: 'p-1 text-center',
    },
    {
      dataField: 'tanggal_bukti_potong',
      text: 'Tgl.Potong',
      headerClasses: 'p-1 text-center',
      classes: 'p-1 text-center',
    },
    {
      dataField: '_',
      text: '',
      headerClasses: 'p-1 text-center',
      classes: 'p-1 text-center',
      formatter: (val, row) => (
        <button
          onClick={() => downloadBupot(row.id)}
          className="btn btn-primary btn-sm text-nowrap">
          <i className="fa fa-download" /> bupot
        </button>
      ),
    },
  ];

  return (
    <div>
      <PageHeading title={title} />
      <CardBasic nopadding>
        <div className="py-3 px-3 d-sm-block d-md-flex justify-content-between">
          <div className="d-flex">
            <div className="m-1" style={{ width: '100px' }}>
              <DatePicker
                value={filterDate}
                onChange={setFilterDate}
                placeholder={'Filter tanggal mulai-akhir'}
                yearPicker={true}
              />
            </div>
            <div className="m-1">
              <button
                onClick={() => getReport()}
                className="btn btn-primary-f text-nowrap">
                <i className="fa fa-search"></i> Lihat
              </button>
            </div>
          </div>
        </div>
        <div className="table-fixed custom-scrollbar table-responsive my-table">
          <BootstrapTable
            className="bt-table"
            bootstrap4
            hover
            condensed
            noDataIndication={() => {
              return 'No data';
            }}
            bordered={false}
            keyField="id"
            data={reportPPh || []}
            columns={columns}
            // pagination={paginationFactory()}
          />
        </div>
      </CardBasic>
    </div>
  );
};

export default PPhReport;
