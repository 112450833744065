import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import CardAlamat from "../CardAlamat";
import TextInput from "../../../../../../components/TextInput";
import { useForm, Controller } from "react-hook-form";
import {
  arr_kabupaten,
  arr_kecamatan,
  arr_propinsi,
} from "../../../../../../assets/js/alamat";
import { useDispatch, useSelector } from "react-redux";
import {
  createAccountAddress,
  deleteAccountAddress,
  fetchAccountAddress,
  updateAccountAddress,
} from "../../../../../../redux/actions/account";
import { formatCurrency } from "../../../../../../constants/utility";
import {
  fetchShipping,
  fetchGudangStok,
  setFormOrder,
} from "../../../../../../redux/actions/transaction";
import ModalOverDemand from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap";

// import Skeleton from "react-loading-skeleton";

const FormDelivery = ({ onNavigateBack }) => {
  const [formAddress, setFormAddress] = useState(null);
  const {
    register,
    handleSubmit,
    watch,
    formState,
    setValue,
    reset,
    control,
    errors,
  } = useForm();
  const { address, formResult } = useSelector((state) => state.account);
  const { formOrder, shipping, gudang } = useSelector(
    (state) => state.transaction
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAccountAddress());
  }, [dispatch, formResult]);

  const watchGudang = watch("gudang_id");
  const watchJenisPengiriman = watch("jenis_pengiriman");

  useEffect(() => {
    if (gudang?.length === 1) setValue("gudang_id", gudang[0]?.public_id);
  }, [gudang, setValue]);

  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [optKabupaten, setOptKabupaten] = useState([]);
  const [optKecamatan, setOptKecamatan] = useState([]);
  const [optEkspedisi, setOptEkspedisi] = useState([]);
  const [optLayanan, setOptLayanan] = useState([]);
  const watchEkspedisi = watch("ekspedisi");
  const watchLayanan = watch("layanan");
  const [subsidiOngkir, setSubsidiOngkir] = useState(null);
  const [show, setShow] = useState(true);


  useEffect(() => {
    if (isOpen) {
      setOptKabupaten([]);
      setOptKecamatan([]);
    }
  }, [isOpen]);

  const watchPropinsi = watch("propinsi");
  const watchKota = watch("kota");
  useEffect(() => {
    const key = parseInt(watchPropinsi);

    if (key > 0) {
      var newArray = arr_kabupaten.filter(function (e) {
        return e.prop === key;
      });
      setOptKabupaten(newArray);
      setValue("kota", null);
      setValue("kecamatan", null);
    }
  }, [watchPropinsi, setValue]);

  useEffect(() => {
    const key = parseInt(watchKota);
    if (key > 0) {
      var newArray = arr_kecamatan.filter(function (e) {
        return e.kab === key;
      });
      setOptKecamatan(newArray);
    }
  }, [watchKota, optKabupaten]);

  useEffect(() => {
    if (isChecked) {
      setValue("kota", alamatasal?.idKota);
      setValue("kecamatan", alamatasal?.idKecamatan);
      setValue("label", alamatasal?.alamat);
      setValue("propinsi", alamatasal?.idPropinsi);
      setValue("alamat", alamatasal?.alamat);
      setValue("kode_pos", alamatasal?.kodePos);
      setValue("nama", alamatasal?.nama);
      setValue("phone", alamatasal?.phoneNumber);
    }
    if (formAddress?.public_id) {
      setValue("public_id", formAddress?.public_id || "");
    }
  }, [formAddress, optKabupaten, optKecamatan, isChecked, setValue]);

  const submit = (data) => {
    if (watch("public_id") && watch("public_id") !== "") {
      dispatch(updateAccountAddress(watch("public_id"), data));
    } else {
      if (isChecked === true) {
        dispatch(createAccountAddress(data));
        dispatch(fetchAccountAddress());
        // window.location.reload(true);
        setIsChecked(false);
      } else {
        dispatch(createAccountAddress(data));
      }
    }
    closeForm();
    setModal(true);
  };

  const deleteAlamat = () => {
    dispatch(deleteAccountAddress(watch("public_id")));
  };

  const closeForm = () => {
    setIsOpen(false);
    setFormAddress(null);
  };

  const closeModal = () => {
    setModal(false);
  };

  const [selected, setSelected] = useState(null);
  const [alamatasal, setAlamatAsal] = useState(null);

  const [carts, setCarts] = useState([]);
  useEffect(() => {
    setCarts(formOrder?.steps?.find((a) => a.name === "item")?.data);
    setSelected(formOrder?.steps?.find((a) => a.name === "delivery")?.data);
    setAlamatAsal(formOrder?.steps?.find((a) => a.name === "member")?.data);
    const deliv = formOrder?.steps?.find((a) => a.name === "delivery")?.data;

    if (deliv) {
      if (deliv.pickup_store !== undefined)
        setValue(
          "jenis_pengiriman",
          deliv?.pickup_store === true ? "pickup" : "delivery"
        );
      setValue("gudang_id", deliv.gudang?.public_id);
    }
  }, [formOrder]);

  const onSelectAlamat = (data) => {
    setSelected(data);
    setModal(false);

    const tmp = {
      ...formOrder,
      steps: formOrder?.steps?.map((a) => {
        if (a.name === "delivery") {
          return {
            ...a,
            passed: false,
            data: {
              ...data,
              gudang: gudang?.find((a) => a.public_id === watchGudang, null),
            },
          };
        }
        return a;
      }, []),
    };
    dispatch(setFormOrder(tmp));
  };

  useEffect(() => {
    if (
      !selected ||
      carts?.length === 0 ||
      !watchGudang ||
      !gudang ||
      !watchJenisPengiriman ||
      // isChecked ||
      watchJenisPengiriman !== "delivery"
    )
      return;
    const origin = gudang?.find(
      (a) => a.public_id === watchGudang,
      null
    )?.id_kecamatan_kirim;
    if (!origin) return;

    setOptEkspedisi([]);
    setOptLayanan([]);

    dispatch(
      fetchShipping({
        originType: "subdistrict",
        origin: origin,
        destination_id: selected?.kecamatan?.id,
        weight: carts?.reduce((a, b) => a + b.qty * b.berat, 0),
        gudang_id: watchGudang,
      })
    );
  }, [
    selected,
    carts,
    watchGudang,
    gudang,
    watchJenisPengiriman,
    alamatasal,
    // isChecked,
    dispatch,
  ]);

  useEffect(() => {
    setValue("ekspedisi", "");
    setValue("layanan", "");
  }, [watchGudang, watchJenisPengiriman]);

  useEffect(() => {
    // setOptEkspedisi(
    //   shipping?.courirs?.map((a) => {
    //     return {
    //       value: a.code === "J&T" ? "jnt" : a.code,
    //       label: a.name,
    //       data: a,
    //     };
    //   }, [])
    // );

    const Ekspedisi = shipping?.courirs
      ?.filter(fil => {
        fil.costs = fil.costs?.filter((layanan) => {
          const estimasiHari = layanan?.cost?.[0]?.etd
          if (!estimasiHari)
            return;
          if (estimasiHari) {
            // Melakukan Pengecualian untuk POS Ekonomi
            if (layanan?.service !== 'Pos Ekonomi') {
              const estimasiArray = estimasiHari.split('-').map(item => parseInt(item, 10));
              const list = {
                name: fil?.name,
                code: fil?.code,
                description: layanan?.description,
                service: layanan?.service,
                value: layanan?.cost?.[0]?.value,
                etd: parseInt(layanan?.cost?.[0]?.etd?.split('-')),
                etdx: layanan?.cost?.[0]?.etd
              }
              if ([2, 3, 4, 5, 6].some(num => estimasiArray.includes(num))) {
                // Mengutamakan Estimasi Tercepat
                return list
              } else {
                // Tidak Ada Estimasi Tercepat Tetap Tampil Layanan Yang Tersedia
                return parseInt(layanan?.cost?.[0]?.etd?.split('-')) > 6
              }
            }
          }

        });
        return fil.costs.length > 0;
      })
      ?.map(a => {
        const hariTercepat = a?.costs
          ?.reduce((cepat, b) => {
            return parseInt(b?.cost[0]?.etd?.split('-')) < parseInt(cepat?.cost[0]?.etd?.split('-')) ? b : cepat;
          }, a?.costs[0]);
        return {
          code: a?.code,
          name: a?.name,
          estimasi: parseInt(hariTercepat.cost[0].etd?.split('-')),
          price: hariTercepat.cost[0].value,
          costs: [
            {
              cost: [{
                value: hariTercepat.cost[0].value,
                etd: hariTercepat.cost[0].etd,
                note: hariTercepat.cost[0].note
              }],
              description: hariTercepat.description,
              service: hariTercepat.service
            }
          ]
        };
      }, [])?.sort((a, b) =>
        // Mencari Harga Yang Paling Murah
        a?.price - b?.price
      )

    setOptEkspedisi(
      Ekspedisi?.map((a, ix) => {
        return {
          value: a.code === "J&T" ? "jnt" : a.code,
          label: a.name,
          data: a,
        };
      }, []).slice(0, 1)
    )

    const { values, ...datasub } = shipping?.subsidi || {};
    const isGudangSub = values?.find(
      (sub) => sub?.gudang?.public_id === watchGudang
    );

    if (!isGudangSub) return;

    const totalBv = carts?.reduce((a, b) => a + b.qty * b.value, 0);
    const qualifiedBv = isGudangSub.values
      .sort((a, b) => b.bv - a.bv, [])
      .find((a) => totalBv >= a.bv);

    if (!qualifiedBv) return;

    setSubsidiOngkir({
      ...datasub,
      ...isGudangSub,
      nominal:
        datasub?.jenis === "PERSENTASE"
          ? Math.floor(
            (carts?.reduce((a, b) => a + b.qty * b.dpp, 0) *
              (Number(qualifiedBv.value) / 100)) /
            1000
          ) * 1000
          : Number(qualifiedBv.value),
    });
  }, [shipping]);

  const [myEkspedisi, setMyEkspedisi] = useState(null);

  useEffect(() => {
    setValue("layanan", "");
    setOptLayanan(
      optEkspedisi
        ?.find((a) => a.value === watchEkspedisi?.value)
        ?.data?.costs?.map((a) => {
          const hari = !a.cost[0]?.etd?.toLowerCase()?.includes("hari")
            ? "Hari"
            : "";
          const cost = a.cost[0]?.value;
          const potongan = subsidiOngkir
            ? Math.min(subsidiOngkir.nominal, cost)
            : 0;
          return {
            value: a.service,
            label: (
              <div
                dangerouslySetInnerHTML={{
                  __html: `
                  <div>${a.service} - ${a.description}. ${a.cost[0]?.etd
                    } ${hari}</div>
                  <div>${subsidiOngkir
                      ? `<strike>${formatCurrency(
                        cost
                      )}</strike>  ${formatCurrency(cost - potongan)}`
                      : formatCurrency(cost)
                    }
                  `,
                }}
              />
            ),
            data: {
              ...a,
              potongan,
            },
          };
        }, [])
    );
    setMyEkspedisi(
      shipping?.ekspedisi?.find((a) => a.kode === watchEkspedisi?.value)
    );
  }, [watchEkspedisi, optEkspedisi, setValue, shipping]);

  useEffect(() => {
    setValue(
      "is_asuransi",
      (myEkspedisi?.asuransi_fix || myEkspedisi?.asuransi_persen) &&
        myEkspedisi.wajib_asuransi
        ? true
        : false
    );
  }, [myEkspedisi, setValue]);

  useEffect(() => {
    if (selected && optEkspedisi) {
      setValue(
        "ekspedisi",
        optEkspedisi?.find((opt) => opt.value === selected?.kodeKurir)
      );
    }
  }, [selected, optEkspedisi, setValue]);

  useEffect(() => {
    if (selected && optLayanan) {
      // Berdasarkan Pilihan
      // setValue(
      //   "layanan",
      //   optLayanan.find((opt) => opt.value === selected?.kodeLayanan)
      // );

      // Berdasarkan Termurah
      setValue(
        "layanan",
        optLayanan.find((opt) => opt.value == optEkspedisi?.[0]?.data?.costs?.[0]?.service)
      );
    }
  }, [selected, optLayanan, setValue]);

  const onNext = () => {
    const tmp = {
      ...formOrder,
      activeIndex: formOrder.activeIndex + 1,
      activeStep: formOrder.steps.find(
        (a, i) => i === formOrder.activeIndex + 1
      )?.name,
      steps: formOrder?.steps?.map((a) => {
        if (a.name === "delivery") {
          const ispickup = watchJenisPengiriman === "pickup";
          return {
            ...a,
            passed: true,
            data: {
              ...a.data,
              pickup_store: ispickup,
              gudang: gudang?.find((a) => a.public_id === watchGudang, null),
              kodeKurir: optEkspedisi?.find(
                (a) => a.value === watchEkspedisi?.value
              )?.value,
              namaKurir: optEkspedisi?.find(
                (a) => a.value === watchEkspedisi?.value
              )?.label,
              kodeLayanan: optLayanan?.find(
                (a) => a.value === watchLayanan?.value
              )?.value,
              namaLayanan: optLayanan?.find(
                (a) => a.value === watchLayanan?.value
              )?.data.service,
              ongkir: optLayanan?.find((a) => a.value === watchLayanan?.value)
                ?.data?.cost[0]?.value,
              myEkspedisi: myEkspedisi,
              is_asuransi: watch("is_asuransi"),
              potonganOngkir: optLayanan?.find(
                (a) => a.value === watchLayanan?.value
              )?.data?.potongan,
              subsidiOngkir,
            },
          };
        }
        return a;
      }, []),
    };
    dispatch(setFormOrder(tmp));
  };

  const disabled =
    !watchGudang ||
    !watchJenisPengiriman ||
    (watchJenisPengiriman === "delivery" &&
      (!watchEkspedisi || !watchLayanan || !selected));

  useEffect(() => {
    if (carts?.length && watchJenisPengiriman) {
      dispatch(
        fetchGudangStok({
          carts: carts.map(({ public_id: id, qty }) => ({ id, qty })),
          is_online_pickup: watchJenisPengiriman === "delivery" ? 1 : null,
          is_offline_pickup: watchJenisPengiriman === "pickup" ? 1 : null
        })
      );
    }
  }, [dispatch, carts, watchJenisPengiriman]);

  return (
    <>
      <h5 className="border-bottom py-2 mb-2" style={{ color: "#3d405c" }}>
        Pilih Metode Pengiriman
      </h5>
      {
        watch('jenis_pengiriman') === 'pickup' ? <div className='alert alert-warning'>
          <strong>Pengiriman Pickup Store!</strong>
          <div>
            Maksimal pengambilan 14 hari dari tanggal transaksi / tanggal invoice
          </div>
        </div> : ''
      }
      <div className="card mb-2">
        <div className="card-body">
          <div className="mb-4">
            <TextInput
              layout="vertical"
              label="Jenis Pengiriman"
              type="radio"
              name="jenis_pengiriman"
              defaultValue={watch("jenis_pengiriman")}
              register={register}
              errors={formState.errors}
              options={[
                {
                  value: "delivery",
                  label: "Pengiriman Online",
                  sublabel: "Paket Dikirim menggunakan jasa kurir/ekspedisi",
                },
                {
                  value: "pickup",
                  label: "Pickup Store",
                  sublabel: "Paket diambil langsung di kantor",
                },
              ]}
            />
          </div>
          <div
            className="mb-4"
            hidden={
              watchJenisPengiriman === null ||
              watchJenisPengiriman === undefined
            }
          >
            <TextInput
              layout="vertical"
              label={
                watchJenisPengiriman === "delivery"
                  ? "Asal Pengiriman"
                  : "Lokasi Pengambilan"
              }
              type="radio-kebawah"
              name="gudang_id"
              defaultValue={watch("gudang_id")}
              register={register}
              errors={formState.errors}
              options={gudang?.map((a) => {
                return {
                  value: a?.public_id,
                  label: a?.nama,
                  sublabel: a?.lokasi,
                };
              }, [])}
            />
            {gudang?.length === 0 ? (
              <div className="alert alert-warning">
                Paket/Item yang Anda pilih tidak tersedia, Pilih jenis pengiriman lain.
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {watchJenisPengiriman === "delivery" ? (
        <>
          <div className="card mb-2">
            <div className="card-body">
              {!selected ? (
                "Belum ada alamat pengiriman dipilih"
              ) : (
                <>
                  <div>
                    {selected?.nama} ({selected?.phone}){" "}
                    <span className="badge badge-info">{selected?.label}</span>
                  </div>
                  <div>
                    {selected?.alamat}. Kecamatan {selected?.kecamatan?.nama},
                    Kabupaten/Kota {selected?.kota?.nama}, Propinsi{" "}
                    {selected?.propinsi?.nama}. Kode Pos {selected?.kode_pos}
                  </div>
                </>
              )}
              <div className="mt-2">
                <button
                  type="button"
                  className="btn btn-primary-f mb-2 btn-sm"
                  onClick={() => {
                    if (address?.rows?.length > 0) setModal(true);
                    else {
                      setIsOpen(true);
                      // reset();
                    }
                  }}
                >
                  {address?.rows?.length > 0 ? "Pilih Alamat" : "Tambah Alamat"}
                </button>
              </div>
            </div>
          </div>
          {selected && (
            <div className="card mb-2">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-12 col-md-4">
                    <Controller
                      name={"ekspedisi"}
                      control={control}
                      // rules={{ required: true }}
                      render={({ field }) => (
                        <TextInput
                          name={"ekspedisi"}
                          label="Ekspedisi"
                          type="react-select"
                          placeholder="Ekspedisi"
                          register={register}
                          errors={errors}
                          field={field}
                          options={optEkspedisi}
                          validations={{ required: true }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-12 col-md-5">
                    <Controller
                      name={"layanan"}
                      control={control}
                      // rules={{ required: true }}
                      render={({ field }) => (
                        <TextInput
                          name={"layanan"}
                          label="Layanan"
                          type="react-select"
                          placeholder="Layanan"
                          register={register}
                          errors={errors}
                          field={field}
                          options={optLayanan}
                          validations={{ required: true }}
                        />
                      )}
                    />
                  </div>
                  {myEkspedisi ? (
                    <div className="col-sm-12 col-md-3">
                      {myEkspedisi?.asuransi_fix ||
                        myEkspedisi?.asuransi_persen ? (
                        <TextInput
                          disabled={myEkspedisi?.wajib_asuransi}
                          layout="vertical"
                          label={`Asuransi ${myEkspedisi?.wajib_asuransi
                            ? "(Wajib)"
                            : "(Opsional)"
                            }`}
                          type="switch"
                          name="is_asuransi"
                          placeholder=""
                          register={register}
                          errors={formState.errors}
                          value={watch("is_asuransi")}
                          options={[
                            {
                              value: true,
                              label: `Ya, ${formatCurrency(
                                carts?.reduce(
                                  (a, b) => a + b.qty * b.harga,
                                  0
                                ) *
                                (myEkspedisi?.asuransi_persen / 100) +
                                myEkspedisi?.asuransi_fix
                              )}`,
                            },
                            { value: false, label: "Tidak" },
                          ]}
                        // validations={{ required: "Pilih Layanan" }}
                        />
                      ) : (
                        <div>
                          <label className="font-weight-bold">Asuransi</label>
                          <div
                            style={{ fontSize: "0.8rem" }}
                            className="italic"
                          >
                            <i>
                              Tidak ada opsi asuransi untuk ekpedisi{" "}
                              {myEkspedisi?.nama}
                            </i>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        ""
      )}




      {
        carts?.[0]?.nama?.includes('Seulliming') && (
          <ModalOverDemand show={show}>
            <ModalOverDemand.Header style={{ background: '#52567a' }}>
              <ModalOverDemand.Title style={{ color: 'white' }}>
                <div style={{ fontSize: '20px' }}>
                  <i className='fa fa-info mr-3'> </i>
                  <strong>Pesan Informasi</strong>
                </div>
              </ModalOverDemand.Title>
            </ModalOverDemand.Header>
            <ModalOverDemand.Body>
              <strong>Paket {carts?.[0]?.nama}!</strong>
              <div>
                Untuk transaksi ini akan terjadi delay pengiriman karena ada over demand
              </div>
            </ModalOverDemand.Body>
            <ModalOverDemand.Footer>
              <Button variant='secondary' onClick={() => setShow(false)} size="sm">
                Tutup
              </Button>
            </ModalOverDemand.Footer>
          </ModalOverDemand>
        )
      }

      <table className="table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th>Paket</th>
            <th className="text-right">Qty</th>
            <th className="text-right">BV</th>
            <th className="text-right">Harga</th>
          </tr>
        </thead>
        <tbody>
          {carts?.map((item, key) => {
            return (
              <tr key={key}>
                <td>{item.nama}</td>
                <td className="text-right">{item.qty}</td>
                <td className="text-right">{item.qty * item.value}</td>
                <td className="text-right">
                  {formatCurrency(item.harga * item.qty)}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th className="text-right">Total</th>
            <th className="text-right">
              {carts?.reduce((a, b) => a + b.qty, 0)}
            </th>
            <th className="text-right">
              {carts?.reduce((a, b) => a + b.qty * b.value, 0)}
            </th>
            <th className="text-right">
              {formatCurrency(carts?.reduce((a, b) => a + b.qty * b.harga, 0))}
            </th>
          </tr>
        </tfoot>
      </table>
      <hr />
      <div className="stepper-navigation">
        <div className="d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-outline-secondary btn-md font-weight-bold mr-1 px-10 flex-grow-1"
            onClick={() => onNavigateBack()}
          >
            <i className="fas fa-chevron-left mr-4"></i>
            Prev
          </button>
          <button
            onClick={() => onNext()}
            // disabled={!selected || !watchGudang || ((!watchEkspedisi || !watchLayanan) && watchJenisPengiriman === 'delivery') }
            disabled={disabled}
            type="submit"
            className="btn btn-primary-f btn-md font-weight-bold ml-1 px-10 flex-grow-1"
          >
            Next
            <i className="fas fa-chevron-right ml-4"></i>
          </button>
        </div>
      </div>

      {/* start modal */}
      <Modal toggle={() => closeForm()} isOpen={isOpen} centered={true}>
        <form onSubmit={handleSubmit(submit)}>
          <ModalHeader
            toggle={() => closeForm()}
            close={
              <div className="btn close" onClick={() => closeForm()}>
                ×
              </div>
            }
          >
            {watch("public_id") ? "Ubah" : "Tambah"} Alamat
          </ModalHeader>
          <ModalBody>
            <div className="modal-body">
              <div className="row">
                <div className="mt-1" style={{ fontSize: "14px" }}>
                  {formOrder?.jenis === "registrasi" && !watch("public_id") ? (
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        checked={isChecked}
                        // onChange={handleOnChange}
                        onChange={() => setIsChecked(!isChecked)}
                      />
                      {` `}Menggunakan Alamat Identitas{" "}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-sm-12 ">
                  <TextInput
                    layout="vertical"
                    label="Label Alamat"
                    type="text"
                    name="label"
                    placeholder=""
                    register={register}
                    errors={formState.errors}
                    validations={{ required: "Masukkan label alamat" }}
                  />
                </div>

                <div className="col-sm-12 col-md-6">
                  <TextInput
                    layout="vertical"
                    label="Provinsi"
                    type="select"
                    name="propinsi"
                    placeholder=""
                    register={register}
                    errors={formState.errors}
                    options={arr_propinsi}
                    validations={{ required: "Pilih propinsi" }}
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <TextInput
                    layout="vertical"
                    label="Kota"
                    type="select"
                    name="kota"
                    placeholder=""
                    register={register}
                    errors={formState.errors}
                    options={optKabupaten}
                    validations={{ required: "Pilih kota" }}
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <TextInput
                    layout="vertical"
                    label="Kecamatan"
                    type="select"
                    name="kecamatan"
                    placeholder=""
                    register={register}
                    errors={formState.errors}
                    options={optKecamatan}
                    validations={{ required: "Pilih Kecamatan" }}
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <TextInput
                    layout="vertical"
                    label="Kode Pos"
                    type="text"
                    name="kode_pos"
                    placeholder=""
                    register={register}
                    errors={formState.errors}
                    validations={{ required: "Masukkan Kode Pos" }}
                  />
                </div>
                <div className="col-sm-12">
                  <TextInput
                    layout="vertical"
                    label="Alamat Kirim"
                    type="textarea"
                    name="alamat"
                    placeholder=""
                    register={register}
                    errors={formState.errors}
                    validations={{ required: "Masukkan detail alamat" }}
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <TextInput
                    layout="vertical"
                    label="Nama Penerima"
                    type="text"
                    name="nama"
                    placeholder=""
                    register={register}
                    errors={formState.errors}
                    validations={{ required: "Masukkan nama penerima" }}
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <TextInput
                    layout="vertical"
                    label="No Telp Penerima"
                    type="text"
                    name="phone"
                    placeholder=""
                    register={register}
                    errors={formState.errors}
                    validations={{ required: "Masukkan telpon penerima" }}
                  />
                </div>
              </div>

              <hr />
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-end">
                  {watch("public_id") && (
                    <button
                      className="btn btn-danger float-right align-content-center mr-1"
                      onClick={() => {
                        deleteAlamat();
                        closeForm();
                      }}
                    >
                      <i className="fas fa-trash fa-lg fa-fw"></i>
                    </button>
                  )}
                  <div
                    className="btn btn-secondary float-right align-content-center"
                    onClick={() => {
                      closeForm();
                    }}
                  >
                    Tutup
                  </div>
                </div>
                <button className="btn btn-primary-f float-right align-content-center">
                  Simpan
                </button>
              </div>
            </div>
          </ModalBody>
        </form>
      </Modal>
      {/* end modal */}

      {/* modal pilih alamat */}
      <Modal
        toggle={() => closeModal()}
        isOpen={modal}
        centered={true}
        size={"lg"}
      >
        <ModalHeader
          toggle={() => closeModal()}
          close={
            <div className="btn close" onClick={() => closeModal()}>
              ×
            </div>
          }
        >
          Pilih Alamat
        </ModalHeader>
        <ModalBody>
          <div className="modal-body">
            <button
              type="button"
              className="btn btn-primary-f mb-2 btn-sm"
              onClick={() => {
                setIsOpen(true);
                // reset();
              }}
            >
              Tambah Alamat
            </button>
            <div className="list-group mb-4">
              {address?.rows?.map((item) => {
                return (
                  <CardAlamat
                    data={item}
                    selected={selected}
                    onSelectAlamat={onSelectAlamat}
                    action={true}
                    setFormAddress={setFormAddress}
                    setIsOpen={setIsOpen}
                  // handleClick={handleClick}
                  // handleChooseAlamat={handleChooseAlamat}
                  />
                );
              })}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default FormDelivery;
