import React, { useState } from 'react';
import Select from 'react-select';

const TextInput = ({
  name,
  type,
  placeholder,
  defaultValue,
  className,
  label,
  register,
  validations,
  errors,
  readOnly,
  layout,
  options,
  value,
  disabled,
  custompassword,
  isMulti = false,
  field,
}) => {
  const nested = name.split('.');
  let error = null;
  if (nested.length > 0) {
    let nError = { ...errors };
    nested.forEach((a) => {
      error = nError?.[a];
      nError = error;
    });
  } else {
    error = errors[name];
  }
  if (error) console.log('xww', 'error', error, name);

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  return (
    <div className={`form-group` + (layout === 'horizontal' ? ` row` : ``)}>
      {label ? (
        <label
          htmlFor={name}
          className={
            `font-weight-bold` +
            (layout === 'horizontal' ? ` col-sm-4 col-form-label` : ``)
          }>
          {label}
        </label>
      ) : (
        ''
      )}
      <div className={layout === 'horizontal' ? `col-sm-8` : ``}>
        {type === 'textarea' ? (
          <textarea
            rows={3}
            id={name}
            readOnly={readOnly}
            defaultValue={defaultValue}
            placeholder={placeholder}
            {...register(name, validations)}
            className={
              `form-control large ${className} ` +
              (error ? `border-danger` : ``)
            }
          />
        ) : type === 'select' ? (
          <>
            {' '}
            <select
              id={name}
              readOnly={readOnly}
              defaultValue={defaultValue}
              value={defaultValue}
              placeholder={placeholder}
              {...register(name, validations)}
              className="form-control">
              <option value="">-</option>
              {options?.map((opt, index) => (
                <option value={opt.value} key={index}>
                  {opt.label}
                </option>
              ))}
            </select>
            {error ? (
              <div className="text-danger small py-2 text-right">
                {error.message}
              </div>
            ) : (
              ''
            )}
          </>
        ) : type === 'react-select' ? (
          <Select
            isMulti={isMulti}
            name={name}
            id={name}
            placeholder={placeholder}
            {...field}
            isDisabled={readOnly}
            isOptionDisabled={(option) => option.isdisabled}
            options={options}
            styles={{
              control: (provided) => ({
                ...provided,
                borderRadius: className?.includes('borad-0') ? '0' : '0.35rem',
                borderTopLeftRadius:
                  className?.includes('borad-0') &&
                  className?.includes('borad-tl') &&
                  '0.35rem',
                borderTopRightRadius:
                  className?.includes('borad-0') &&
                  className?.includes('borad-tr') &&
                  '0.35rem',
                borderBottomLeftRadius:
                  className?.includes('borad-0') &&
                  className?.includes('borad-bl') &&
                  '0.35rem',
                borderBottomRightRadius:
                  className?.includes('borad-0') &&
                  className?.includes('borad-br') &&
                  '0.35rem',
              }),
            }}
          />
        ) : type === 'radio' ? (
          <>
            <div className="mx-auto pt-0">
              {options?.map((item, k) => {
                return (
                  <div
                    key={k}
                    className="custom-control custom-radio d-inline mr-4">
                    <input
                      className="custom-control-input"
                      type="radio"
                      id={name + k}
                      readOnly={readOnly}
                      placeholder={placeholder}
                      name={name}
                      value={item.value}
                      checked={item.value === defaultValue}
                      {...register(name, validations)}
                    />
                    {/* <input type="radio" name="r"/> */}
                    <label
                      htmlFor={name + k}
                      className="custom-control-label user-select-none cursoir-pointer">
                      {item.label}
                      {item.sublabel && (
                        <div className="text-xs">{item.sublabel}</div>
                      )}
                    </label>
                  </div>
                );
              })}
            </div>
          </>
        ) : type === 'radio-kebawah' ? (
          <>
            <div className="mx-auto pt-0">
              {options?.map((item, k) => {
                return (
                  <div key={k} className="custom-control custom-radio mr-4">
                    <input
                      className="custom-control-input"
                      type="radio"
                      id={name + k}
                      readOnly={readOnly}
                      placeholder={placeholder}
                      name={name}
                      value={item.value}
                      checked={item.value === defaultValue}
                      {...register(name, validations)}
                    />
                    {/* <input type="radio" name="r"/> */}
                    <label
                      htmlFor={name + k}
                      className="custom-control-label user-select-none cursoir-pointer">
                      {item.label}
                      {item.sublabel && (
                        <div className="text-xs">{item.sublabel}</div>
                      )}
                    </label>
                  </div>
                );
              })}
            </div>
          </>
        ) : type === 'switch' ? (
          <>
            <div className="custom-control custom-switch">
              <input
                disabled={disabled}
                className="custom-control-input"
                type="checkbox"
                id={name}
                readOnly={readOnly}
                placeholder={placeholder}
                name={name}
                {...register(name, validations)}
              />
              <label
                className="custom-control-label user-select-none"
                for={name}>
                {options?.find((a) => a.value === value)?.label}
                {value}
              </label>
            </div>
          </>
        ) : type === 'date-member-profile' ? (
          <>
            <input
              type="date"
              className={
                `form-control large ${className} ` +
                (error ? `border-danger` : ``)
              }
              id={name}
              readOnly={readOnly}
              defaultValue={defaultValue}
              placeholder={placeholder}
              {...register(name, validations)}
            />
            {error ? (
              <div className="text-danger small py-2 text-right">
                {error.message}
              </div>
            ) : (
              ''
            )}
          </>
        ) : custompassword === 'custompassword' ? (
          <>
            <div style={{ position: 'relative' }}>
              <input
                type={passwordShown ? "text" : "password"}
                className={
                  `form-control large ${className} ` +
                  (error ? `border-danger` : ``)
                }
                id={name}
                readOnly={readOnly}
                defaultValue={defaultValue}
                placeholder={placeholder}
                {...register(name, validations)}
              />
              {
                passwordShown ? <i onClick={togglePasswordVisiblity} style={{ position: 'absolute', top: 20, right: 20, cursor: 'pointer' }} class="fa fa-eye fa-xs"></i> :
                  <i onClick={togglePasswordVisiblity} style={{ position: 'absolute', top: 20, right: 20, cursor: 'pointer' }} class="fa fa-eye-slash fa-xs"></i>
              }
            </div>


            {error ? (
              <div className="text-danger small py-2 text-right">
                {error.message}
              </div>
            ) : (
              ''
            )}
          </>
        ) : (
          <>
            <input
              type={type}
              className={
                `form-control large ${className} ` +
                (error ? `border-danger` : ``)
              }
              id={name}
              readOnly={readOnly}
              defaultValue={defaultValue}
              placeholder={placeholder}
              {...register(name, validations)}
            />
            {error ? (
              <div className="text-danger small py-2 text-right">
                {error.message}
              </div>
            ) : (
              ''
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TextInput;
