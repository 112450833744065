import React, { useRef, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import TextInput from "../TextInput";
import MultiSelect from "../MultiSelect";
// import Select from "react-select";
import "./style.css";

const FilterPopover = (props) => {
  const { filterCount = 0, filterChanges, filters } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const [show, setShow] = useState(false);
  const divContent = useRef(null);
  // useOutsideClick(divContent, show);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        divContent.current &&
        !divContent.current.contains(event.target) &&
        show
      ) {
        setShow(false);
      }
    }

    function onKeyup(e) {
      if (e.key === "Escape" && show) setShow(false);
    }

    window.addEventListener("keyup", onKeyup);

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
      // remove listener
      window.removeEventListener("keyup", onKeyup);
    };
  }, [divContent, show]);

  const onApplied = (data) => {
    filterChanges(data);
    setShow(false);
  };

  return (
    <div className="filter-popover" style={{minWidth:"60px", maxHeight:"40px"}}>
      <button
        type="button"
        className="btn border border-gray btn-block"
        onClick={() => setShow(true)}
      >
        <div className="d-flex align-items-center">
          <span className="fas fa-filter fa-sm" />
          <span className="d-none d-md-inline">Filter</span>
          <span className="badge badge-light text-gray">{filterCount}</span>
        </div>
      </button>
      <div
        ref={divContent}
        className={
          `filter-popover-content shadow-lg rounded border border-gray p-4` +
          (show === true ? ` show` : ``)
        }
      >
        <div>
          {/* <span onClick={()=> setShow(false)} className="btn btn-sm float-right"><i className="fa fa-times fa-sm"></i></span> */}
          <h5>Filter</h5>
          <hr />
        </div>

        <form onSubmit={handleSubmit(onApplied)}>
          {filters.map((filter, key) => {
            if(filter.type === "multiselect" ){
              return (
                <Controller
                  key={key}
                  name={filter.name}
                  control={control}
                  defaultValue={filter.filtered}
                  render={({ field }) => (
                    <MultiSelect
                      layout="vertical"
                      name={filter.name}
                      label={filter.label}
                      register={register}
                      errors={errors}
                      field={field}
                      options={filter.options}
                    />
                  )}
                />
              )
            }else {
              return <TextInput
                key={key}
                layout="vertical"
                label={filter.label}
                type={filter.type}
                name={filter.name}
                register={register}
                errors={errors}
                options={filter.options}
              />
            }
          })}

          <hr />
          <button
            type="submit"
            className="btn btn-primary-f btn-user btn-block"
          >
            Terapkan
          </button>
        </form>
      </div>
    </div>
  );
};

export default FilterPopover;
