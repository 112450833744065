import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { useDispatch, useSelector } from 'react-redux';
import CardBasic from '../../../components/Cards/Basic';
import DatePicker from '../../../components/DatePicker';
//Navigation
import PageHeading from '../../../components/PageHeading';
import { formatDate } from '../../../constants/utility';
import * as actions from '../../../redux/actions';
import { createRetur, fetchRetur } from '../../../redux/actions/transaction';
import TransaksiDetail from './TransaksiDetail';
import moment from 'moment';
import './index.css';

const TransaksiRetur = (props) => {
  const { name, title, parent } = props.data;
  const dispatch = useDispatch();

  const [sort, setSort] = useState(null);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(20);
  const [totalSize, setTotalSize] = useState(0);
  const { retur, formResult, returAbles } = useSelector(
    (state) => state.transaction
  );
  const [activeId, setActiveId] = useState(null);
  const [formData, setFormData] = useState(null);
  const [formOpen, setFormOpen] = useState(false);

  // const [filter, setFilter] = useState("ALL");
  let date = new Date();
  const [filterDate, setFilterDate] = useState({
    start: new Date(date.setDate(date.getDate() - 30)),
    end: new Date(),
  });

  useEffect(() => {
    if (!filterDate.start || !filterDate.end) {
      return;
    }

    const params = {
      page_size: sizePerPage,
      page: page,
      sort: sort,
      filter: {
        tanggal: {
          op: 'between',
          value: {
            from: moment(filterDate.start).format('YYYY-MM-DD'),
            to: moment(filterDate.end).format('YYYY-MM-DD'),
          },
        },
      },
    };
    if (formResult) setActiveId(formResult.result?.public_id);
    dispatch(fetchRetur(params));
  }, [dispatch, formResult, sort, page, sizePerPage, filterDate]);

  useEffect(() => {
    if (retur) {
      setTotalSize(retur.total_count);
      console.log('xna', activeId);
      setFormData(retur.rows?.find((a) => a.public_id === activeId));
    }
  }, [retur, activeId]);

  useEffect(() => {
    if (formData) setActiveId(formData.public_id);
  }, [formData]);

  const columns = [
    {
      dataField: 'tanggal',
      text: 'Tanggal',
      sort: true,
      // headerStyle: (colum, colIndex) => {
      //   return { width: "30%" };
      // },
      headerClasses: (column, colIndex) => {
        return 'py-2 px-2';
      },
      classes: (column, colIndex) => {
        return 'p-2';
      },
      formatter: (val) => {
        return formatDate(val, false, false, true);
      },
    },
    {
      dataField: 'order.no_order',
      text: 'No Order',
      // sort: true,
      headerClasses: (column, colIndex) => {
        return 'py-2 px-2';
      },
      classes: (column, colIndex) => {
        return 'p-2';
      },
    },
    {
      dataField: 'no_retur',
      text: 'No Retur',
      sort: true,
      headerClasses: (column, colIndex) => {
        return 'py-2 px-2';
      },
      classes: (column, colIndex) => {
        return 'p-2';
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      headerClasses: (column, colIndex) => {
        return 'py-2 px-2';
      },
      classes: (column, colIndex) => {
        return 'p-2';
      },
    },
  ];

  useEffect(() => {
    dispatch(
      actions.setMenuActive({ menuActive: parent || name, subMenuActive: name })
    );
  }, [dispatch, name, parent]);

  const filters = [];

  if (retur?.opt_member?.length > 0) {
    let options = retur.opt_member.map((v) => {
      return { value: v.id_member, label: v.id_member + '-' + v.nama };
    });
    filters.push({
      name: 'member',
      label: 'Member',
      type: 'multiselect',
      options: options,
    });
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setFormData(row);
      setFormOpen(true);
    },
  };

  const handleTableChange = (
    type,
    { sortField, sortOrder, data, page, sizePerPage }
  ) => {
    if (type === 'sort') {
      setSort({
        orderby: sortField,
        order: sortOrder,
      });
    }

    if (type === 'pagination') {
      setPage(page);
      setSizePerPage(sizePerPage);
    }
  };

  const onTambahPres = () => {
    setFormData(false);
    setFormOpen(true);
  };

  const onSubmit = (data) => {
    dispatch(createRetur(data));
  };

  return (
    <div>
      <PageHeading title={title} />
      <div className="row animate-col-size-change">
        <div className="col-md-12 p-2">
          <CardBasic nopadding>
            <div className="card-retur py-3 px-3 d-lg-flex justify-content-between p-2">
              <DatePicker
                value={filterDate.start}
                endDate={filterDate.end}
                onChange={setFilterDate}
                range
              />
              <button
                className="btn btn-retur btn-primary-f btn-md text-nowrap"
                disabled={returAbles?.length === 0}
                style={{ cursor: 'pointer' }}
                onClick={() => onTambahPres()}>
                Buat Retur
              </button>
            </div>

            <RemotePagination
              data={retur ? retur.rows : []}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              columns={columns}
              onRowClick={rowEvents}
              onTableChange={handleTableChange}
            />
          </CardBasic>
        </div>
        <TransaksiDetail
          formOpen={formOpen}
          formData={formData}
          onSubmit={(data) => onSubmit(data)}
          setFormOpen={setFormOpen}
        />
      </div>{' '}
    </div>
  );
};

const RemotePagination = ({
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
  columns,
  onRowClick,
}) => {
  const indication = () => {
    return 'No data';
  };
  return (
    <div className="table-fixied custom-scrollbar table-responsive my-table">
      <BootstrapTable
        key={'bt123'}
        remote
        className="bt-table"
        bootstrap4
        hover
        condensed
        noDataIndication={indication}
        bordered={false}
        keyField="no_order"
        data={data}
        columns={columns}
        pagination={paginationFactory({ page, sizePerPage, totalSize })}
        rowEvents={onRowClick}
        onTableChange={onTableChange}
      />
    </div>
  );
};

export default TransaksiRetur;
