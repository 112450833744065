import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../constants/utility";

const initialState = {
  suggestUsername: null,
  checkUsername: null
};

const getSuggestUsername = (state, data) => {
  return updateObject(state, {
    suggestUsername: data,
  });
};

const getCheckUsername = (state, data) => {
  return updateObject(state, {
    checkUsername: data,
  });
};

const reducer = (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
    case actionTypes.USERNAME:
      return getSuggestUsername(state, data);
    case actionTypes.USERNAME_CHECK:
      return getCheckUsername(state, data);
    default:
      return state;
  }
};

export default reducer;
