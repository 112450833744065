import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  formatCurrency,
  formatDate,
  formatDate2,
  formatDateOnly,
} from '../../../constants/utility';
const RiwayatClaim = ({ data, promo }) => {
  const columns = [
    {
      dataField: '_',
      text: 'No',
      headerClasses: 'py-2 px-2 text-center',
      classes: 'p-2',
      headerStyle: (colum, colIndex) => {
        return { width: '40px' };
      },
      formatter: (cel, row, rowIndex) => (
        <div className="text-center">{rowIndex + 1}</div>
      ),
      footer: '-',
    },
    {
      dataField: 'tanggal',
      text: 'Tanggal',
      headerStyle: { width: '90px' },
      headerClasses: 'py-2 px-2 text-left',
      classes: 'p-2',
      formatter: (val, row) => formatDateOnly(row?.tgl_claim, true),
      footer: '',
    },
    {
      dataField: 'no',
      text: 'Nomor',
      headerStyle: { width: '180px' },
      headerClasses: 'py-2 px-2 text-left',
      classes: 'p-2',
      footer: '',
    },
    {
      dataField: '_1',
      text: 'Promo',
      headerStyle: { width: '140px' },
      headerClasses: 'py-2 px-2 text-left',
      classes: 'p-2',
      formatter: () => promo?.nama,
      footer: '',
    },
    {
      dataField: '_2',
      text: `${promo?.syarat_poin ? 'BV' : 'Nominal'}`,
      headerClasses: 'py-2 px-2 text-left',
      headerStyle: { width: '100px' },
      classes: 'p-2',
      formatter: (_, row) =>
        promo?.syarat_poin ? row.poin_claimed * 4 : row.nominal_claimed,
      footer: '',
    },
  ];

  const expandRow = {
    renderer: (row) => (
      <div>
        {row.details && (
          <ul style={{ listStyle: 'none' }}>
            {row.details?.map((item, index) => {
              return (
                <li key={index}>
                  {`- ${formatDate2(item.tanggal, true, true, true)} - ${item.order.no_invoice
                    } - ${item.jenis_order === 'reg' ? 'REG' :
                      item.jenis_order === 'upg' ? 'UPG' :
                        item.jenis_order === 'ro' ? 'RO' :
                          ''} - ${item.poin * 4 || formatCurrency(item.nominal)
                    }${item.poin ? ' BV' : ''}`}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    ),
    // expanded: expanded,
    // showExpandColumn: false,
    // expandColumnPosition: 'left',
  };
  return (
    <div className="bt-table border" style={{ overflowY: 'auto' }}>
      <BootstrapTable
        className="bt-table"
        bootstrap4
        hover
        condensed
        noDataIndication={() => {
          return 'No data';
        }}
        bordered={false}
        keyField="id"
        data={data || []}
        columns={columns}
        expandRow={expandRow}
      />
    </div>
  );
};

export default RiwayatClaim;
