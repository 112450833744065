import React from "react";
import useChart from "../useChart";
import { formatCurrency } from '../../../constants/utility';

const ChartDonut = (props) => {
  const canvasRef = React.useRef(null);

  useChart(
    canvasRef,
    {
      type: "pie",
      data: {
        labels: [
          `Registrasi ${(( props?.registrasi / (props?.registrasi + props?.upgrade + props?.repeatorder )) * 100).toFixed(1)}%`,
          `Upgrade  ${(( props?.upgrade / (props?.registrasi + props?.upgrade + props?.repeatorder )) * 100).toFixed(1)}%`,
          `Repeat Order ${(( props?.repeatorder / (props?.registrasi + props?.upgrade + props?.repeatorder )) * 100).toFixed(1)}%`,
        ],
        datasets: [
          {
            data: [props?.registrasi, props?.upgrade, props?.repeatorder ],
            backgroundColor: ["#198754", "#0D6EFD","#FFC107"],
            hoverBackgroundColor: ["#198754", "#0D6EFD","#FFC107"],
            hoverBorderColor: "rgba(234, 236, 244, 1)",
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        tooltips: {
          backgroundColor: "rgb(255,255,255)",
          bodyFontColor: "#858796",
          borderColor: "#dddfeb",
          borderWidth: 1,
          displayColors: false,
          caretPadding: 20,
        },
        legend: {
          display: false,
        },

        cutoutPercentage: 80,
      },
    },
    props.cref
  );

  return (
    <>
      <div>
        <canvas style={{height:"180px"}} id={props.cref} ref={canvasRef} />
      </div>
      </>
  );
};

export default ChartDonut;
