import * as actionTypes from "../../../constants/actionTypes";

export const setExpandSidebar = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_EXPAND_SIDEBAR,
    });
  };
};
export const setMenuActive = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_MENU_ACTIVE,
      data: data,
    });
  };
};
