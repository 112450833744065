import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import CardBasic from "../../../components/Cards/Basic";
import DatePicker from "../../../components/DatePicker";
import PageHeading from "../../../components/PageHeading";
import { formatCurrency, formatDate } from "../../../constants/utility";
import * as actions from "../../../redux/actions";
import {
  createPayout,
  fetchBonus,
  fetchBonusUnpayout,
  fetchPayout,
} from "../../../redux/actions/report";
import {
  Alert,
  Card,
  Collapse,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
//Navigation
import { AuthWrapper } from "../../AuthWrapper";
import Select from "react-select";
import { Link } from "react-router-dom";

const Withdraw = (props) => {
  const { name, title, parent } = props.data;
  const { bonusUnpayout, payout, formResult } = useSelector((state) => state.report);
  const { profile } = useSelector((state) => state.account);
  const [optionBonus, setOptionBonus] = useState([]);
  const [modal, setModal] = React.useState(false);
  const page = 1;
  const sizePerPage = 10;
  const [filterStatus, setFilterStatus] = useState([]);
  const [openDetails, setOpenDetails] = useState([]);
  let date = new Date();
  const [filterDate, setFilterDate] = useState({
    start: new Date(date.setDate(date.getDate() - 30)),
    end: new Date(),
  });
  const dispatch = useDispatch();

  useEffect(() => {
    // if (bonusUnpayout && !optionBonus.length) 
    setOptionBonus(bonusUnpayout)
  }, [bonusUnpayout, optionBonus, formResult]);

  useEffect(() => {
    dispatch(
      actions.setMenuActive({ menuActive: parent || name, subMenuActive: name })
    );
    dispatch(fetchBonusUnpayout());
  }, [dispatch, parent, name, formResult]);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (!filterDate.start || !filterDate.end) {
      return;
    }

    const params = {
      page_size: sizePerPage,
      page: page,
      filter: {
        created_at: {
          op: "between",
          value: {
            from: filterDate.start,
            to: filterDate.end,
          },
        },
      },
    };

    if (filterStatus.length > 0) {
      params.filter["status"] = {
        op: "in",
        value: filterStatus.map((item) => item.value, []),
      };
    }

    dispatch(fetchPayout(params));
  }, [filterDate, page, sizePerPage, dispatch, filterStatus, formResult]);

  // const handlePageClick = async (data) => {
  //   let currentPage = data.selected + 1;
  //   dispatch(fetchBonus({ page_size: sizePerPage, page: currentPage }));
  // };

  const submitCreatePayout = () => {
    const filterData = optionBonus?.filter((item) => item.is_selected === true);
    const tempData = filterData.map(function (obj) {
      return obj.public_id;
    });

    dispatch(
      createPayout({
        bonus_ids: tempData,
      })
    );

    setModal(false);
  };

  return (
    <div>
      <PageHeading title={title} />
      <Modal toggle={toggle} isOpen={modal} centered={true}>
        <ModalHeader style={{ background: '#52567a' }} closeButton>
          <strong style={{ color: 'white' }}>Create Withdraw</strong>
        </ModalHeader>
        <ModalBody>
          {profile?.status_rekening === 'UNVERIFIED' ?
            <Alert className="alert" color="danger">Tidak bisa melakukan widthraw, rekening belum terverifikasi. Buka <Link to="/account/profile">halaman profil </Link>untuk verifikasi.</Alert> :
            <>
              <div className="" style={{ overflowY: "auto", maxHeight: "300px", overflowX: "hidden" }}
              >
                {optionBonus?.length < 1 ? "Belum ada bonus" :
                  <>
                    <div className="px-2 row row-cols-2 row-cols-sm-2 row-cols-md-3">
                      <div className="col">Tanggal</div>
                      <div className="col">Jenis Bonus</div>
                      <div className="col">Bonus</div>
                    </div>
                    {
                      optionBonus?.map((item, index) => (
                        <div className="px-2  row row-cols-2 row-cols-sm-2 row-cols-md-3">
                          <div className="col">
                            <input
                              type={"checkbox"}
                              className="mr-2"
                              checked={item.is_selected === "false" || item.is_selected === false ? false : true}
                              onClick={() => {
                                const tempData = [...optionBonus];
                                if (tempData[index].is_selected === "false") {
                                  tempData[index].is_selected = true;
                                } else {
                                  tempData[index].is_selected =
                                    !tempData[index].is_selected;
                                }
                                setOptionBonus(tempData);
                              }}
                            />
                            {formatDate(item?.tanggal, true, false, true)}
                          </div>
                          <div className="col">
                            {item.jenis_bonus}
                          </div>
                          <div className="col">
                            {formatCurrency(item.bonus_dibayar)}
                          </div>
                        </div>
                      ))
                    }
                  </>
                }
              </div>
              <hr></hr>
              <div>
                {optionBonus?.length > 0 && (
                  <>
                    {" "}
                    <strong>
                      Total bonus dipilih :{" "}
                      {formatCurrency(optionBonus?.filter((dt) => dt.is_selected === true).reduce((accum, item) => accum + parseInt(item.bonus_dibayar), 0))}
                    </strong>
                    <button
                      className="btn btn-primary-f  float-right align-content-center mb-2"
                      onClick={submitCreatePayout}
                    >
                      Submit
                    </button>
                    <div
                      className="btn btn-secondary   mr-2 float-right align-content-center"
                      onClick={() => {
                        setModal(false);
                      }}
                    >
                      Cancel
                    </div>
                  </>
                )}
              </div>
            </>
          }
        </ModalBody>
      </Modal>

      <CardBasic>
        <div
          className="row row-cols-1 row-cols-sm-2 row-cols-md-3"
          style={{ fontSize: "14px" }}
        >
          <div className="col">
            <div className="row">
              <label className="font-weight-bold col-md-12">Tanggal</label>
              <div className="col-md-12">
                <DatePicker
                  value={filterDate.start}
                  endDate={filterDate.end}
                  onChange={setFilterDate}
                  range
                />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="row">
              <label className="font-weight-bold col-md-12">Status</label>
              <div className="col-md-12">
                <Select
                  placeholder={"Status Withdraw"}
                  isMulti={true}
                  name={"status"}
                  id={"status"}
                  onChange={(val) => setFilterStatus(val)}
                  options={[
                    { value: "queued", label: "QUEUED" },
                    { value: "processed", label: "PROCESSED" },
                    { value: "completed", label: "COMPLETED" },
                    { value: "rejected", label: "REJECTED" },
                    { value: "failed", label: "FAILED" },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="row float-right text-right">
              <div className="col-md-12 float-right" style={{ marginTop: "28px" }}>
                <button className="btn btn-primary-f float-right" onClick={() => { setModal(true) }}>
                  {optionBonus?.length ? `(${optionBonus?.length})` : ''} Create Withdraw
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row px-3 pb-1 row-cols-2 row-cols-sm-3 row-cols-md-6">
          <div className="col text-center">
            <small>Not Queued Yet</small>
            <br />
            <strong>{formatCurrency(optionBonus?.reduce((accum, item) => accum + parseInt(item.bonus_dibayar), 0))}</strong>
          </div>
          <div className="col text-center">
            <small>Queued</small>
            <br />
            <strong>{formatCurrency(payout?.summary?.queued)}</strong>
          </div>
          <div className="col text-center">
            <small>Processed</small>
            <br />
            <strong>{formatCurrency(payout?.summary?.processed)}</strong>
          </div>
          <div className="col text-center">
            <small>Completed</small>
            <br />
            <strong>{formatCurrency(payout?.summary?.completed)}</strong>
          </div>
          <div className="col text-center">
            <small>Rejected</small>
            <br />
            <strong>{formatCurrency(payout?.summary?.rejected)}</strong>
          </div>
          <div className="col text-center">
            <small>Failed</small>
            <br />
            <strong>{formatCurrency(payout?.summary?.failed)}</strong>
          </div>

        </div>

        <hr />

        <div className="mt-2 mb-4">
          {payout?.rows.length === 0 ? (
            <Card className="mb-1">
              <div className="p-4" style={{ fontSize: "14px" }}>Tidak ada payout</div>
            </Card>
          ) :
            payout?.rows?.map((item, key) => {
              if (item.bonuses.length == 0 && item.status === 'draft') {
                return false
              } else {
                return (
                  <Card className="mb-1" key={key}>
                    <div className="px-2 py-1 row row-cols-2 row-cols-sm-2 row-cols-md-5">
                      <div className="col">
                        <small>Tanggal</small> <br /> {formatDate(item?.created_at, true, false, true)}
                      </div>
                      <div className="col">
                        <small>Nomor</small> <br /> {item?.no}
                      </div>
                      <div className="col">
                        <small>Status</small> <br /> {item?.status}
                      </div>
                      <div className="col">
                        <small>Nomor Rekening</small> <br /> {item?.no_rekening}
                      </div>
                      <div className="col">
                        <small>Bank</small> <br /> <small>{item?.bank?.nama}</small>
                      </div>
                      <div className="col">
                        <small>Jumlah Bonus</small> <br /> {formatCurrency(item?.total_bonus)}
                      </div>
                      <div className="col">
                        <small>Biaya Admin</small> <br /> {formatCurrency(item?.admin_fee)}
                      </div>
                      <div className="col">
                        <small>Total</small> <br /> {formatCurrency(item?.nominal)}
                      </div>
                      <div className="col">
                        <small>Tanggal Transfer</small> <br /> {item?.processed_at ? formatDate(item?.processed_at, false, false, true) : "-"}
                      </div>
                      <div className="col">
                        <small>Detail</small>
                        <br />
                        <span
                          className="badge badge-pill badge-info btn-primary-f"
                          style={{ cursor: "pointer" }}
                          onClick={() => setOpenDetails(!openDetails?.includes(item.public_id) ? [...openDetails, item.public_id] : openDetails.filter(ix => ix !== item.public_id, []))}
                        >
                          {!openDetails?.includes(item.public_id) ? "Tampikan Detail" : "Sembunyikan Detail"}
                        </span>
                      </div>
                    </div>
                    <div className="px-2 py-1" style={{ fontSize: "14px" }}>
                      <div className="p-0 m-0">
                        <Collapse isOpen={openDetails?.includes(item.public_id)}>
                          <hr className="m-0 p-0 mb-2" />
                          Detail Payout :
                          <div className="px-2 py-1 row row-cols-2 row-cols-sm-2 row-cols-md-4" key={-1}>
                            <div className="col"><strong>No</strong></div>
                            <div className="col"><strong>Tanggal</strong></div>
                            <div className="col"><strong>Jenis Bonus</strong></div>
                            <div className="col"><strong>Bonus</strong></div>
                          </div>
                          {item?.bonuses?.map((child, index) => {
                            return (
                              <div className="px-2 py-1 row row-cols-2 row-cols-sm-2 row-cols-md-4" key={index}>
                                <div className="col">{index + 1}</div>
                                <div className="col">{formatDate(child?.tanggal, true, false, true)}</div>
                                <div className="col">{child.jenis_bonus}</div>
                                <div className="col">{formatCurrency(child.bonus_dibayar)}</div>
                              </div>
                            );
                          })}
                        </Collapse>
                      </div>
                    </div>
                  </Card>
                );
              }

            })}
        </div>
        {/* <ReactPaginate
          previousLabel={"prev"}
          nextLabel={"next"}
          breakLabel={"..."}
          pageCount={bonusUnpayout?.total_page}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        /> */}
      </CardBasic>
    </div>
  );
};

export default Withdraw;
