import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardInfo from '../../components/Cards/Info';
import ChartDonut from '../../components/Charts/Donut';
import Pie from '../../components/Charts/Pie';
import ModalAction from '../../components/ModalAction';
import PageHeading from '../../components/PageHeading';
import {
  formatCurrency,
  formatDate,
  formatDateOnly,
} from '../../constants/utility';
import * as actions from '../../redux/actions';
import {
  fetchDashboard,
  fetchBannerOne,
  fetchBannerPromo,
  claimPromo,
} from '../../redux/actions/report';
import { AuthWrapper } from '../AuthWrapper';
import './Collapsible';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdOpenInNew } from "react-icons/md";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CardBasic from '../../components/Cards/Basic';
import { updateKodeEtik } from '../../redux/actions/account';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';
import Marquee from 'react-fast-marquee';
import { fetchInformasi } from '../../redux/actions/informasi';
import Collapsible from './Collapsible';
import logoBlack from '../../assets/img/logo-black.png';
import { ModalFooter } from 'reactstrap';
import { CgCopy } from "react-icons/cg";
import { setFormOrder } from '../../redux/actions/transaction';
import Banner from './banner';
require('dotenv').config();

const Dashboard = (props) => {
  const { name, parent } = props.data;
  const dispatch = useDispatch();
  const { dashboard, bannerpromo, banner } = useSelector(
    (state) => state.report
  );
  const { profile, formResult } = useSelector((state) => state.account);
  const { listInformasi } = useSelector((state) => state.informasi);
  const { formOrder } = useSelector((state) => state.transaction);

  const [modalShow, setModalShow] = useState(false);
  const [modaloption, setModalOption] = useState({});

  useEffect(() => {
    dispatch(
      actions.setMenuActive({ menuActive: parent || name, subMenuActive: name })
    );

    dispatch(fetchBannerOne('banner-member'));
    dispatch(fetchDashboard());
    dispatch(fetchBannerPromo());
  }, [dispatch, name, parent]);

  let downlineKanan = dashboard?.members?.downline?.total_downline_kanan;
  let downlineKiri = dashboard?.members?.downline?.total_downline_kiri;

  const [show, setShow] = useState(true);
  const navigate = useNavigate();

  let textAPI = process.env.REACT_APP_API_URL.includes('dev');
  let joinmeURL = process.env.REACT_APP_API_URL_JOINME;

  const handleClose = () => {
    dispatch(updateKodeEtik(profile?.public_id, isChecked));
  };

  useEffect(() => {
    dispatch(fetchInformasi({ page_size: 5, page: 1 }));
  }, [dispatch]);

  useEffect(() => {
    if (formResult) setShow(false);
  }, [formResult]);

  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  //Total Bonus
  const [isVisibleTb, setVisibleTb] = useState(true);
  const onClickTb = () => setVisibleTb(!isVisibleTb);
  //Bonus Terbayar
  const [isVisibleBt, setVisibleBt] = useState(true);
  const onClickBt = () => setVisibleBt(!isVisibleBt);
  //Bonus Pending
  const [isVisibleBp, setVisibleBp] = useState(true);
  const onClickBp = () => setVisibleBp(!isVisibleBp);

  const [marquee, setmarquee] = useState(false);
  const [datamarquee, setdatamarquee] = useState('');
  const handleMarquee = () => setmarquee(false);

  function handleShow(data) {
    setdatamarquee(listInformasi?.rows?.[data]);
    setmarquee(true);
  }

  const createOrder = (jenis) => {
    if (formOrder?.jenis !== jenis) {
      const formData = {
        jenis: jenis,
        minBv: 0,
        activeIndex: 0,
        activeStep: 'item',
        steps: [
          {
            name: 'item',
            label: 'Item/Paket',
            icon: 'fas fa-shopping-bag',
            data: null,
            passed: false,
          },
          {
            name: 'delivery',
            label: 'Pengiriman',
            icon: 'fas fa-shipping-fast',
            data: null,
            passed: false,
          },
          {
            name: 'payment',
            label: 'Pembayaran',
            icon: 'fas fa-money-check',
            data: null,
            passed: false,
          },
        ],
      };
      if (jenis === 'registrasi') {
        formData.activeStep = 'member';
        formData.steps = [
          {
            name: 'member',
            data: null,
            label: 'Member',
            icon: 'fas fa-user',
            passed: false,
          },
          ...formData.steps,
        ];
      }
      dispatch(setFormOrder(formData));
    }
    if (jenis === 'registrasi') {
      navigate(`/transaksi/order-transaksi/registrasi`);
    }
    if (jenis === 'upgrade') {
      navigate(`/transaksi/order-transaksi/upgrade`);
    }
    // if (jenis === 'repeat-order') {
    //   navigate(`/transaksi/order-transaksi/repeat-order`);
    // }
    if (jenis === 'withdraw') {
      navigate(`/bonus/withdraw`);
    }
    if (jenis === 'lihat-jaringan') {
      navigate(`/report/tree`);
    }
  };

  const modalClaim = (data) => {
    setModalShow(true);
    setModalOption({
      title: `List ${data}`,
      content: (
        <div>
          {
            bannerpromo?.filter((f) => f?.reward?.includes(data) && f?.status === true)
              ?.map((get) => {
                return (
                  <div className='row justify-content-between py-2' key={get?.public_id}>
                    <div>
                      <strong>{get?.nama} - {get?.syarat_poin * 4} {` BV`}</strong>
                      <div>{get?.reward}</div>
                    </div>
                    <div>
                      {console.log(get?.total_claimed, 'aaa claimed')}
                      {console.log(get?.kelipatan, 'aaa')}
                      {
                        get?.kelipatan === false && get?.total_claimed > 0
                          ? (<Button
                            disabled={true}
                            className="float-right cursor-pointer mt-2"
                            style={{
                              width: '150px',
                              fontWeight: 'bold',
                              textShadow: '0 5px 10px rgba(0, 0, 0, 0.5)',
                            }}
                            type="button"
                            variant={`outline-secondary`}
                            size="sm">
                            Sudah Ambil {` `}
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </Button>) : get?.kelipatan === true && get?.total_get_promo > 0 || get?.kelipatan === false && get?.total_get_promo > 0 && get?.total_claimed === 0
                            ? (<Button
                              onClick={() =>
                                actionClaim({
                                  data: {
                                    promo_id: get?.public_id,
                                    nama: get?.nama,
                                    syarat_poin: get?.syarat_poin
                                  },
                                })
                              }
                              className="float-right cursor-pointer mt-1"
                              style={{
                                width: '150px',
                                fontWeight: 'bold',
                                textShadow: '0 5px 10px rgba(0, 0, 0, 0.5)',
                                backgroundColor: 'green',
                              }}
                              type="button"
                              variant={`success`}
                              size="sm">
                              <i
                                className="fa fa-exclamation-circle mr-2"
                                aria-hidden="true"></i>
                              Claim {data}
                            </Button>)
                            : (
                              <Button
                                disabled={true}
                                className="cursor-pointer mt-1"
                                style={{
                                  width: '150px',
                                  fontWeight: 'bold',
                                  textShadow: '0 5px 10px rgba(0, 0, 0, 0.5)',
                                }}
                                type="button"
                                variant={`outline-secondary`}
                                size="sm">
                                Belum Mencapai {` `}
                                <i className="fa fa-times" aria-hidden="true"></i>
                              </Button>
                            )
                      }
                      {/* {
                        get?.total_get_promo > 0 ?
                          get?.kelipatan === false && get?.total_claimed > 0 ? (
                            <Button
                              disabled={true}
                              className="float-right cursor-pointer mt-2"
                              style={{
                                minWidth: '100px',
                                fontWeight: 'bold',
                                textShadow: '0 5px 10px rgba(0, 0, 0, 0.5)',
                              }}
                              type="button"
                              variant={`outline-secondary`}
                              size="sm">
                              Sudah Ambil {` `}
                              <i className="fa fa-check" aria-hidden="true"></i>
                            </Button>
                          )
                            : (
                              <Button
                                onClick={() =>
                                  actionClaim({
                                    data: {
                                      promo_id: get?.public_id,
                                      nama: get?.nama,
                                      syarat_poin: get?.syarat_poin
                                    },
                                  })
                                }
                                className="float-right cursor-pointer mt-1"
                                style={{
                                  width: '150px',
                                  fontWeight: 'bold',
                                  textShadow: '0 5px 10px rgba(0, 0, 0, 0.5)',
                                  backgroundColor: 'green',
                                }}
                                type="button"
                                variant={`success`}
                                size="sm">
                                <i
                                  className="fa fa-exclamation-circle mr-2"
                                  aria-hidden="true"></i>
                                Claim {data}
                              </Button>
                            )
                          : (
                            <Button
                              disabled={true}
                              className="cursor-pointer mt-1"
                              style={{
                                width: '150px',
                                fontWeight: 'bold',
                                textShadow: '0 5px 10px rgba(0, 0, 0, 0.5)',
                              }}
                              type="button"
                              variant={`outline-secondary`}
                              size="sm">
                              Belum Mencapai {` `}
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </Button>
                          )
                      } */}

                    </div>
                  </div>)
              })
          }
          <hr />
          <Button
            className="float-left"
            size="sm"
            variant={`outline-secondary`}
            onClick={() => setModalShow(false)}>
            Tutup
          </Button>
        </div>
      ),
      color: 'danger',
      size: 'md',
    });
  };

  const modalConfirmClaim = (data) => {
    setModalShow(true);
    setModalOption({
      title: 'Konfirmasi',
      content: (
        <div>
          <p>
            Anda Yakin Akan Claim Reward{' '}
            <span style={{ fontWeight: 'bold' }}>{data?.data?.nama} </span> ?
          </p>
          <hr />
          <Button
            className="float-left"
            size="sm"
            variant={`outline-secondary`}
            onClick={() => setModalShow(false)}>
            Tutup
          </Button>
          <Button
            onClick={() => {
              dispatch(claimPromo({ promo_id: data?.data?.promo_id }));
              setModalShow(false);
            }}
            className="float-right"
            style={{
              backgroundColor: 'green',
              color: 'white',
              fontWeight: 'bold',
              textShadow: '0 5px 10px rgba(0, 0, 0, 0.5)',
            }}
            type="button"
            variant={`success`}
            size="sm">
            <i className="fa fa-check" aria-hidden="true"></i> Claim
          </Button>
        </div>
      ),
      color: 'danger',
      size: 'md',
    });
  };

  const actionClaim = (data) => {
    modalConfirmClaim(data);
  };

  const modalPreviewClaim = (data) => {
    setModalShow(true);
    setModalOption({
      content: (
        <div>
          <img
            className="rounded"
            src={data?.url_banner}
            style={{ width: '100%' }}
          />

          <hr />
          <Button
            className="float-left"
            size="sm"
            variant={`outline-secondary`}
            onClick={() => setModalShow(false)}>
            Tutup
          </Button>
        </div>
      ),
      color: 'danger',
      size: 'xl',
    });
  };
  const promoReward = bannerpromo?.filter((f) => f?.reward?.includes('Reward'))
  const promoTrip = bannerpromo?.filter((f) => f?.reward?.includes('Trip'))
  return (
    <div>
      <div className="row">
        <Modal show={marquee} onHide={handleMarquee} size="lg">
          <Modal.Header>
            <img alt={'logo'} src={logoBlack} width={50} height={50} />
            <Modal.Title>{datamarquee.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mt-3" key={datamarquee.public_id}>
              <Collapsible data={datamarquee} />
            </div>
          </Modal.Body>
          <ModalFooter>
            <Button variant="primary" onClick={handleMarquee}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <Col xs={2} style={{ backgroundColor: 'red' }}>
          <h6 className="mt-2" style={{ color: 'white' }}>
            <strong>News</strong>
          </h6>
        </Col>
        <Col xs={10} style={{ backgroundColor: 'black' }}>
          <h6 className="mt-2" style={{ color: 'white' }}>
            <strong>
              <Marquee direction="left" gradient={false}>
                {listInformasi?.rows?.map((e, i) => {
                  return (
                    <div className="row" key={i}>
                      <span
                        className="col mr-5 ml-5"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleShow(i)}>
                        {e.title} {formatDateOnly(e.created_at)}
                      </span>
                    </div>
                  );
                })}
              </Marquee>
            </strong>
          </h6>
        </Col>
      </div>
      <PageHeading title="Dashboard" />

      {/* Warning Akun Terkena Suspend */}
      {dashboard?.suspend?.is_suspended && (
        <div className="alert alert-warning">
          <div>
            <i className="fas fa-user-lock mr-2 fa-2x"></i>
            <strong> This Account Has Been Suspended.</strong>
          </div>
        </div>
      )}

      {/* Notifikasi Produk UnderCutting */}
      {profile?.status_kodeetik === false ? (
        <Modal show={show} centered>
          <Modal.Header style={{ background: '#52567a' }}>
            <Modal.Title style={{ color: 'white' }}>
              <div style={{ fontSize: '16px' }}>
                <strong>Notice: Product Undercutting Important Reminder</strong>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="mb-3"
              style={{ fontWeight: 'bold', color: 'black', fontSize: '14px' }}>
              <strong>
                DILARANG MENJUAL DI BAWAH HARGA DAN BERJUALAN DI MARKETPLACE.
              </strong>
            </div>

            <div className="mb-3" style={{ color: 'grey', fontSize: '14px' }}>
              Apabila Anda tertangkap dan terbukti melakukan pelanggaran
              Undercutting dan menjual secara online di marketplace akan
              dikenakan sanksi berupa suspend akun dan ancaman pidana 12 tahun
              penjara atau Denda maksimal Rp. 12.000.000.000 (Dua belas milyar
              rupiah)
            </div>
            <div
              className="mt-5 mb-3"
              style={{ fontWeight: 'bold', color: 'black', fontSize: '14px' }}>
              <strong>
                PROHIBITED FROM SELLING UNDER THE OFFICIAL PRICE OR SELLING IN
                THE ONLINE PLATFORM.
              </strong>
            </div>

            <div className="mb-3" style={{ color: 'grey', fontSize: '14px' }}>
              If you are caught and proven to have committed a violation of
              undercutting and selling product via online platform, you will be
              submitted to Account suspend and 12 years Prison threats also Rp.
              12.000.000.000 fines (twelve billion rupiah)
            </div>

            <div className="mt-5" style={{ fontSize: '14px' }}>
              <div className="checkbox">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleOnChange}
                />
                {` `}Saya telah membaca, menyetujui dan bersedia terikat{' '}
                <u>
                  <a
                    target="_blank"
                    href="https://thefavored-one.com/kode-etik">
                    Kode Etik
                  </a>
                </u>{' '}
                yang ditetapkan THE FAVORED ONE
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleClose}
              disabled={isChecked ? false : true}>
              <strong>Setuju</strong>
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        ''
      )}

      {/* <!-- Banner --> */}
      <Banner data={banner} />

      {/* <!-- Content Row Total --> */}
      <div className="row">
        <div className="col d-block d-lg-none d-md-none">
          <CardInfo
            color="warning"
            value={
              <Container>
                <Row>
                  <Col>
                    <div style={{ fontSize: '14px' }}>
                      <Button
                        size="lg"
                        className="rounded-pill border border-white border-1"
                        onClick={() => createOrder('registrasi')}>
                        <i className="fa fa-user-alt"></i>
                      </Button>
                    </div>
                    <div style={{ fontSize: '14px' }}>Registrasi</div>
                  </Col>
                  <Col>
                    <div style={{ fontSize: '14px' }}>
                      <Button
                        size="lg"
                        className="rounded-pill border border-white border-1"
                        href={
                          textAPI === true
                            ? 'https://devmember.thefavored-one.com/bonus/withdraw'
                            : 'https://member.thefavored-one.com/bonus/withdraw'
                        }
                        // target="_blank"
                        rel="noreferrer">
                        <i className="fa fa-money-bill-wave-alt"></i>
                      </Button>
                    </div>
                    <div style={{ fontSize: '14px' }}>Tarik Bonus</div>
                  </Col>
                </Row>
                <Row className="mt-4">
                  {/*                 <Col>
                    <div style={{ fontSize: '14px' }}>
                      <Button
                        size="lg"
                        className="rounded-pill border border-white border-1"
                        onClick={() => createOrder('repeat-order')}>
                        <i className="fa fa-shopping-bag"></i>
                      </Button>
                    </div>
                    <div style={{ fontSize: '14px' }}>Repeat Order</div>
                  </Col> */}
                </Row>
                <Row className="mt-4">
                  <Col>
                    <div style={{ fontSize: '14px' }}>
                      <Button
                        size="lg"
                        className="rounded-pill border border-white border-1"
                        onClick={() => createOrder('upgrade')}>
                        <i className="fa fa-exchange-alt"></i>
                      </Button>
                    </div>
                    <div style={{ fontSize: '14px' }}>Upgrade</div>
                  </Col>
                  <Col>
                    <div style={{ fontSize: '14px' }}>
                      <Button
                        size="lg"
                        className="rounded-pill border border-white border-1"
                        href={
                          textAPI === true
                            ? 'https://devmember.thefavored-one.com/report/tree'
                            : 'https://member.thefavored-one.com/report/tree'
                        }
                        // target="_blank"
                        rel="noreferrer">
                        <i className="fa fa-chart-bar"></i>
                      </Button>
                    </div>

                    <div style={{ fontSize: '14px' }}>Lihat Jaringan</div>
                  </Col>
                </Row>
              </Container>
            }
            size="12"
          />
        </div>

        <CardInfo
          title={
            <div className="text-center">
              <div style={{ fontSize: '12px' }}>
                <i className="fas fa-donate fa-2x mr-1"></i>
                <strong>Total Bonus</strong>
              </div>
            </div>
          }
          color="primary"
          value={
            <div className="text-center">
              <span
                style={{ cursor: 'pointer' }}
                onClick={onClickTb}
                className="badge">
                {isVisibleTb
                  ? 'Show'
                  : formatCurrency(dashboard?.members?.bonus?.total_bonus)}
              </span>
            </div>
          }
          size="3"
        />
        <CardInfo
          title={
            <div className="text-center">
              <div style={{ fontSize: '12px' }}>
                <i className="fas fa-vote-yea fa-2x mr-1"></i>
                <strong>Bonus Terbayar</strong>
              </div>
            </div>
          }
          color="success"
          value={
            <>
              <span
                style={{ cursor: 'pointer' }}
                onClick={onClickBt}
                className="badge">
                {isVisibleBt
                  ? 'Show'
                  : formatCurrency(dashboard?.members?.bonus?.total_dibayar)}
              </span>
            </>
          }
          size="3"
        />
        <CardInfo
          title={
            <div className="text-center">
              <div style={{ fontSize: '12px' }}>
                <i className="fas fa-clock fa-2x mr-1"></i>
                <strong>Bonus Pending</strong>
              </div>
            </div>
          }
          color="warning"
          value={
            <>
              <span
                style={{ cursor: 'pointer' }}
                onClick={onClickBp}
                className="badge">
                {isVisibleBp
                  ? 'Show'
                  : formatCurrency(dashboard?.members?.bonus?.total_pending)}
              </span>
            </>
          }
          size="3"
        />
        <CardInfo
          title={
            <div className="text-center">
              <div style={{ fontSize: '12px' }}>
                <i className="fas fa-calendar fa-2x mr-1"></i>
                <strong>Expired Pairing</strong>
              </div>
            </div>
          }
          color="danger"
          value={
            <>
              <span style={{ cursor: 'pointer' }} className="badge">
                {formatDateOnly(dashboard?.members?.pairing?.tanggal_expired) || '-'}
              </span>
            </>
          }
          size="3"
        />

        <CardInfo
          title={
            <div>
              <strong>My Account</strong>
            </div>
          }
          color="warning"
          size="3"
          value={
            <div style={{ marginTop: "20px" }}>
              <i className="fas fa-user-tie " style={{ fontSize: "90px" }}></i>
              <div style={{ marginTop: "14px" }}>{dashboard?.members?.nama}</div>
              {/* <div className="mt-2">{dashboard?.members?.id_member}</div> */}
              <div className='d-flex justify-content-between' style={{ marginTop: "10px" }}>
                <div style={{ fontSize: "14px" }}>
                  ID Member
                </div>
                <div style={{ fontSize: "14px" }}>
                  {dashboard?.members?.id_member}
                </div>
              </div>
              <div className='d-flex justify-content-between' style={{ marginTop: "10px" }}>
                <div style={{ fontSize: "14px" }}>
                  Posisi
                </div>
                <div style={{ fontSize: "14px" }}>
                  {dashboard?.members?.posisi}
                </div>
              </div>
              <div className='d-flex justify-content-between' style={{ marginTop: "10px" }}>
                <div style={{ fontSize: "14px" }}>
                  Sponsored
                </div>
                <div style={{ fontSize: "14px" }}>
                  {dashboard?.members?.sponsored?.jumlah}
                </div>
              </div>
              <div style={{ marginTop: "14px" }}>
                <div style={{ fontSize: "14px" }}>
                  Link Account
                </div>
                <div style={{ fontSize: "17px", fontStyle: "italic", fontWeight: "lighter", textAlign: "center", marginTop: "10px", gap: "5px" }}>
                  <span className='d-block'>
                    {joinmeURL + profile?.kode_referal}
                  </span>
                </div>
              </div>
              <div className='d-flex justify-content-center align-items-center mt-2' style={{ gap: "5px" }}>
                <CopyToClipboard text={joinmeURL + profile?.kode_referal} >
                  <CgCopy style={{ cursor: "pointer", backgroundColor: "AppWorkspace", fontSize: "15px" }} />
                </CopyToClipboard>
                <MdOpenInNew onClick={() => window.open(`${joinmeURL + profile?.kode_referal}`, '_blank')} style={{ cursor: "pointer", backgroundColor: "AppWorkspace", fontSize: "15px" }} />
              </div>
            </div>
          }
        />

        <CardInfo
          title={
            <div>
              <strong>PAIRING</strong>
            </div>
          }
          color="warning"
          size="9"
          value={
            <div className="container">
              <div className="row">
                <div className="col">
                  <div
                    className="mt-1 text-primary"
                    style={{ fontSize: '15px', color: 'grey' }}>
                    <strong>Downline Kiri</strong>
                  </div>
                  <div
                    className="mt-3 "
                    style={{
                      fontSize: '15px',
                      color: 'grey',
                    }}>
                    {dashboard?.members?.downline?.total_downline_kiri}
                  </div>
                </div>
                <div className="col">
                  <div
                    className="mt-1 text-primary"
                    style={{ fontSize: '15px' }}>
                    <strong>Jumlah Pair</strong>
                  </div>
                  <div
                    className="mt-3 "
                    style={{
                      fontSize: '17px',
                      color: 'grey',
                    }}>
                    {dashboard?.members?.pairing?.jumlah_pair}
                  </div>
                </div>
                <div className="col">
                  <div
                    className="mt-1 text-primary"
                    style={{ fontSize: '15px', color: 'grey' }}>
                    <strong>Downline Kanan</strong>
                  </div>
                  <div
                    className="mt-3 "
                    style={{
                      fontSize: '15px',
                      color: 'grey',
                    }}>
                    {dashboard?.members?.downline?.total_downline_kanan}
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col">
                  <div
                    className="mt-1 text-primary"
                    style={{ fontSize: '15px', color: 'grey' }}>
                    <strong>Bv Kiri</strong>
                  </div>
                  <div
                    className="mt-3 "
                    style={{
                      fontSize: '15px',
                      color: 'grey',
                    }}>
                    {dashboard?.members?.pairing?.jumlah_bv_kiri}
                  </div>
                </div>
                <div className="col">
                  <div
                    className="mt-1 text-primary"
                    style={{ fontSize: '15px' }}>
                    <strong>Pair Bv</strong>
                  </div>
                  <div
                    className="mt-3 "
                    style={{
                      fontSize: '17px',
                      color: 'grey',
                    }}>
                    {dashboard?.members?.pairing?.jumlah_pair_bv}
                  </div>
                </div>
                <div className="col">
                  <div
                    className="mt-1 text-primary"
                    style={{ fontSize: '15px', color: 'grey' }}>
                    <strong>Bv Kanan</strong>
                  </div>
                  <div
                    className="mt-3 "
                    style={{
                      fontSize: '15px',
                      color: 'grey',
                    }}>
                    {dashboard?.members?.pairing?.jumlah_bv_kanan}
                  </div>
                </div>
              </div>

              <hr />
              <div className="mt-3  text-warning" style={{ fontSize: '15px' }}>
                Order
              </div>
              <div className="row"></div>
              <div className="col">
                <div>
                  <Pie
                    cref={'chart1'}
                    registrasi={
                      dashboard?.members?.orders?.totalorderregistrasi
                    }
                    upgrade={dashboard?.members?.orders?.totalorderupgrade}
                    repeatorder={dashboard?.members?.orders?.totalrepeatorder}
                  />
                </div>
              </div>
            </div>
          }
        />
      </div>

      <ModalAction
        show={modalShow}
        modalOption={modaloption}
        onHide={() => setModalShow(false)}
      />


      {/* Promo Trip */}
      {
        promoTrip?.length > 0 ? (
          <div
            className="card card-info mb-2 shadow bg-white rounded">
            <div className="card-body container">
              <div className="row">
                <img
                  className="rounded"
                  src={promoTrip?.[0]?.url_banner || ''}
                  style={{ width: '100%' }}
                  onClick={() => modalPreviewClaim(promoTrip?.[0])}
                />
              </div>
              <div className="mt-2">
                <div className="mt-3 text-center text-success font-weight-bold ">
                  {promoTrip?.filter((f) => f?.total_get_promo > 0 && f?.member_bisa_klaim === true )?.length > 0  ? (<div>
                    <Button
                      onClick={() => modalClaim('Trip')}
                      className="float-right cursor-pointer mt-1"
                      style={{
                        width: '150px',
                        fontWeight: 'bold',
                        textShadow: '0 5px 10px rgba(0, 0, 0, 0.5)',
                        backgroundColor: 'green',
                      }}
                      type="button"
                      variant={`success`}
                      size="sm">
                      <i
                        className="fa fa-exclamation-circle mr-2"
                        aria-hidden="true"></i>
                      Claim Trip{` `}
                    </Button>
                  </div>) :
                    (<div>
                      <Button
                        disabled={true}
                        className="cursor-pointer mt-1"
                        style={{
                          width: '150px',
                          fontWeight: 'bold',
                          textShadow: '0 5px 10px rgba(0, 0, 0, 0.5)',
                        }}
                        type="button"
                        variant={`outline-secondary`}
                        size="sm">
                        Belum Mencapai {` `}
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </Button>
                    </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        ) : ''
      }
      {/* Promo Reward */}
      {
        promoReward?.length > 0 ? (
          <div
            className="card card-info mb-2 shadow bg-white rounded">
            <div className="card-body container">
              <div className="row">
                <img
                  className="rounded"
                  src={promoReward?.[0]?.url_banner || ''}
                  style={{ width: '100%' }}
                  onClick={() => modalPreviewClaim(promoReward?.[0])}
                />
              </div>
              <div className="mt-2">
                <div className="mt-3 text-center text-success font-weight-bold ">
                  {promoReward?.filter((f) => f?.total_get_promo > 0)?.length > 0 ? (<div>
                    <Button
                      onClick={() => modalClaim('Reward')}
                      className="float-right cursor-pointer mt-1"
                      style={{
                        width: '150px',
                        fontWeight: 'bold',
                        textShadow: '0 5px 10px rgba(0, 0, 0, 0.5)',
                        backgroundColor: 'green',
                      }}
                      type="button"
                      variant={`success`}
                      size="sm">
                      <i
                        className="fa fa-exclamation-circle mr-2"
                        aria-hidden="true"></i>
                      Claim Reward{` `}
                    </Button>
                  </div>) : (<div>
                    <Button
                      disabled={true}
                      className="cursor-pointer mt-1"
                      style={{
                        width: '150px',
                        fontWeight: 'bold',
                        textShadow: '0 5px 10px rgba(0, 0, 0, 0.5)',
                      }}
                      type="button"
                      variant={`outline-secondary`}
                      size="sm">
                      Belum Mencapai {` `}
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </Button>
                  </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : ''
      }

      {/* Downline Chart & Recent Sponsor */}
      <div className="row">
        {(downlineKanan > 0 || downlineKiri > 0) && (
          <div className="col-xl-6 col-lg-4 col-sm-12">
            <ChartDonut
              cref={'chart0'}
              childKanan={downlineKanan}
              childKiri={downlineKiri}
              downline={downlineKanan + downlineKiri}
            />
          </div>
        )}

        {dashboard?.members?.sponsored?.recently &&
          dashboard?.members?.sponsored?.jumlah > 0 && (
            <div className="recent-card col-xl-6 col-lg-6 col-sm-12">
              {
                dashboard?.members?.sponsored?.recently?.map((get) => console.log(get, 'hehe'))

              }
              <CardBasic
                title={
                  <div>
                    <strong>Recent Sponsored</strong>
                  </div>
                }>
                {dashboard?.members?.sponsored?.recently?.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      width: '100%',
                      height: 30,
                      borderRadius: 5,
                      border: `1px solid ${index === 0
                        ? '#FF7F3F'
                        : index === 1
                          ? '#FABB51'
                          : index === 2
                            ? '#A3DA8D'
                            : '#7CD1B8'
                        }`,
                      margin: '5px',
                    }}
                    className="px-3">
                    <span
                      style={{
                        color: `${index === 0
                          ? '#FF7F3F'
                          : index === 1
                            ? '#FABB51'
                            : index === 2
                              ? '#A3DA8D'
                              : '#7CD1B8'
                          }`,
                      }}>
                      #{index + 1}.
                    </span>


                    <span
                      style={{
                        cursor: 'pointer',
                        backgroundColor:
                          `${item?.posisi === 'BRONZE' ?
                            "rgba(92,184,92)" :
                            item?.posisi === 'SILVER' ?
                              "rgba(91,192,222)" :
                              item?.posisi === 'PLATINUM' ?
                                "rgba(240,173,78)" : ""
                          }`,
                        color: 'white',
                        fontStyle: 'bold',
                        fontSize: '9px',
                      }}
                      className="badge mr-1 ml-1">
                      <strong>{item?.posisi}</strong>
                    </span>
                    {' ' + item.nama}
                  </div>
                ))}
              </CardBasic>
            </div>
          )
        }
      </div >
    </div >
  );
};

export default Dashboard;
