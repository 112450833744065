import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import CardBasic from '../../../components/Cards/Basic';
import DatePicker from '../../../components/DatePicker';
import {
  formatCurrency,
  formatDate,
  formatDateOnly,
} from '../../../constants/utility';
import PageHeading from '../../../components/PageHeading';
import PrintingHeader from '../../../components/PrintingHeader';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import * as actions from '../../../redux/actions';
import { fetchRedemptionStatement } from '../../../redux/actions/report';

const RedemptionStatement = (props) => {
  const { name, title, parent } = props.data;
  const { profile } = useSelector((state) => state.account);
  const { redemptionStatement, formResult } = useSelector(
    (state) => state.report
  );
  const { id } = useSelector((state) => state.auth.data);

  const dispatch = useDispatch();

  let date = new Date();
  const [filterDate, setFilterDate] = useState({
    start: new Date(date.getFullYear(), date.getMonth(), 1),
    end: new Date(),
  });

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const getReport = () => {
    if (!filterDate.start || !filterDate.end) {
      return;
    }

    const params = {
      filter: {
        member: id,
        completed_at: {
          op: 'between',
          value: {
            from: moment(filterDate.start).format('YYYY-MM-DD'),
            to: moment(filterDate.end).format('YYYY-MM-DD'),
          },
        },
      },
    };

    dispatch(fetchRedemptionStatement(params));
  };

  useEffect(() => {
    dispatch(
      actions.setMenuActive({ menuActive: parent || name, subMenuActive: name })
    );
  }, [dispatch, formResult, name, parent]);

  const columns = [
    {
      dataField: '_',
      text: 'No',
      headerClasses: 'py-2 px-2 text-center',
      classes: 'p-2',
      headerStyle: (colum, colIndex) => {
        return { width: '40px' };
      },
      formatter: (cel, row, rowIndex) => rowIndex + 1,
      footer: '',
      footerClasses: 'text-center',
      classes: 'p-2 text-center',
    },
    // {
    //     dataField: "created_at",
    //     text: "Tgl Request",
    //     sort: true,
    //     headerClasses: "py-2 px-2 text-center",
    //     classes: "p-2 text-left",
    //     formatter: (val) => formatDate(val, true, false, true),
    //     footer: "-"
    // },
    {
      dataField: 'completed_at',
      text: 'Tanggal',
      sort: true,
      headerClasses: 'py-2 px-2 text-center',
      classes: 'p-2 text-center',
      footerClasses: 'text-center',
      formatter: (val) => formatDateOnly(val),
      footer: 'Total',
    },
    {
      dataField: '_a',
      text: 'Bonus Bruto',
      headerClasses: 'py-2 px-2 text-center',
      classes: 'p-2 text-center',
      formatter: (val, row) => {
        return (
          <div>
            {formatCurrency(
              row?.bonuses?.reduce((a, b) => a + b.bonus_bruto, 0)
            )}
            <div className="text-left">
              Rincian :
              {row?.bonuses?.map((a) => {
                return (
                  <div>
                    - Bns {a?.jenis_bonus}{' '}
                    {formatDate(a?.tanggal, true, true, true)}{' '}
                    {formatCurrency(a?.bonus_bruto)}
                  </div>
                );
              })}
            </div>
          </div>
        );
      },
      footerClasses: 'text-right',
      footer: (row) =>
        formatCurrency(
          redemptionStatement?.reduce(
            (a, b) => a + b?.bonuses?.reduce((a, b) => a + b?.bonus_bruto, 0),
            0
          )
        ),
    },
    {
      dataField: '_b',
      text: 'PPH',
      headerClasses: 'py-2 px-2 text-center',
      classes: 'p-2 text-right',
      formatter: (val, row) =>
        formatCurrency(
          row?.bonuses?.reduce(
            (a, b) => a + b.bonus_bruto - b?.bonus_dibayar,
            0
          )
        ),
      footerClasses: 'text-right',
      footer: (row) =>
        formatCurrency(
          redemptionStatement?.reduce(
            (a, b) =>
              a +
              b?.bonuses?.reduce(
                (a, b) => a + b?.bonus_bruto - b?.bonus_dibayar,
                0
              ),
            0
          )
        ),
    },
    {
      dataField: 'admin_fee',
      text: 'Biaya Admin',
      headerClasses: 'py-2 px-2 text-center',
      classes: 'p-2 text-right',
      formatter: (val) => formatCurrency(val),
      footerClasses: 'text-right',
      footer: (row) => formatCurrency(row.reduce((a, b) => a + b, 0)),
    },
    {
      dataField: 'nominal',
      text: 'Bonus Diterima',
      headerClasses: 'py-2 px-2 text-center',
      classes: 'p-2 text-right',
      formatter: (val) => formatCurrency(val),
      footerClasses: 'text-right',
      footer: (row) => formatCurrency(row.reduce((a, b) => a + b, 0)),
    },
    // {
    //     dataField: "_c",
    //     text: "Rincian",
    //     headerClasses: "py-2 px-2 text-center",
    //     classes: "p-2 text-left",
    //     footerClasses: 'text-center',
    //     formatter: (val, row) => {
    //         return (
    //             <div>
    //                 {row?.bonuses?.map(a => {
    //                     return (
    //                         <div>- Bns {a?.jenis_bonus} {formatCurrency(a?.bonus_bruto)}</div>
    //                     )
    //                 })}
    //             </div>
    //         )
    //     },
    //     footer: '-',
    // },
  ];

  return (
    <div>
      <PageHeading title={title} />
      <CardBasic nopadding>
        <div className="py-3 px-3 d-sm-block d-md-flex justify-content-between">
          <div className="d-flex">
            <div className="m-1">
              <DatePicker
                value={filterDate.start}
                endDate={filterDate.end}
                onChange={setFilterDate}
                placeholder={'Filter tanggal mulai-akhir'}
                range
              />
            </div>
            <div className="m-1">
              <button
                onClick={() => getReport()}
                className="btn btn-primary-f text-nowrap">
                <i className="fa fa-search"></i> Lihat
              </button>
            </div>
          </div>
        </div>

        <div className="table-fixed custom-scrollbar table-responsive my-table">
          <div className="container-print media-print-p2" ref={printRef}>
            <PrintingHeader
              title={'Laporan Penarikan Bonus'}
              subtitle={`Member ${profile?.nama || ''}`}
              date={[filterDate.start, filterDate.end]}
              printBy={null}
              printAt={null}
            />
            <div className="bt-table">
              <BootstrapTable
                className="bt-table"
                bootstrap4
                hover
                condensed
                noDataIndication={() => {
                  return 'No data';
                }}
                bordered={false}
                keyField="no_order"
                data={redemptionStatement || []}
                columns={columns}
              // pagination={paginationFactory()}
              />
            </div>
          </div>
        </div>
      </CardBasic>
    </div>
  );
};

export default RedemptionStatement;
