import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import "bootstrap/dist/js/bootstrap.bundle.min";

//SBAdmin2 Style
import "./styles/scss/sb-admin-2.scss";

import "./assets/css/general.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
