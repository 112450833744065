import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Select from 'react-select';
import CardBasic from "../../../components/Cards/Basic";
import DatePicker from "../../../components/DatePicker";
import { formatCurrency, formatDate, formatDateOnly } from '../../../constants/utility';
import PageHeading from "../../../components/PageHeading";
import PrintingHeader from "../../../components/PrintingHeader";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import * as actions from "../../../redux/actions";
import { fetchReportOmset, setReportOmset } from "../../../redux/actions/report";
import Default from './default';
import Pribadi from './pribadi';
import SponsorGrup from './sponsorgrup';

const Omset = (props) => {
    const { name, title, parent } = props.data;
    const { profile } = useSelector((state) => state.account);
    const { ReportOmset, formResult } = useSelector((state) => state.report);
    const dispatch = useDispatch();
    const [jenisOmset, setjenisOmset] = useState({ value: 'default', label: 'Default' })

    let date = new Date();
    const [filterDate, setFilterDate] = useState({
        start: new Date(date.getFullYear(), date.getMonth(), 1),
        end: new Date(),
    });

    const printRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    useEffect(() => {
        dispatch(
            actions.setMenuActive({ menuActive: parent || name, subMenuActive: name })
        );
    }, [dispatch, formResult, name, parent]);

    useEffect(() => {
        dispatch(setReportOmset(null));
    }, [dispatch])

    const getReport = () => {
        const params = {
            member: profile?.id_member,
            filter: {
                tgl_invoice: {
                    op: "between",
                    value: {
                        from: moment(filterDate.start).format('YYYY-MM-DD'),
                        to: moment(filterDate.end).format('YYYY-MM-DD'),
                    },
                },
            },
        };
        if (jenisOmset) params.filter['jenisOmset'] = jenisOmset;
        dispatch(fetchReportOmset(params));
    }

    return (
        <div>
            <PageHeading title={title} />
            <CardBasic nopadding>
                <div className="py-3 px-3 d-sm-block d-md-flex justify-content-between">
                    <div className="d-flex">
                        <div style={{ width: '250px' }} className="m-1">
                            <Select
                                placeholder={'Filter'}
                                isMulti={false}
                                name={'jenisOmset'}
                                id={'jenisOmset'}
                                defaultValue={jenisOmset}
                                onChange={(val) => {
                                    setjenisOmset(val);
                                }}
                                options={[
                                    { value: 'default', label: 'Default' },
                                    { value: 'pribadi', label: 'Pribadi' },
                                    { value: 'sponsorgrup', label: 'Sponsor Grup' },
                                ]}
                            />
                        </div>
                        <div className="m-1">
                            <DatePicker
                                value={filterDate.start}
                                endDate={filterDate.end}
                                onChange={setFilterDate}
                                placeholder={"Filter tanggal mulai-akhir"}
                                range
                            />
                        </div>
                        <div className="m-1">
                            <button
                                onClick={() => getReport()}
                                className="btn btn-primary-f text-nowrap"
                            >
                                <i className="fa fa-search"></i> Lihat
                            </button>
                        </div>
                    </div>
                </div>
                <div className="table-fixed custom-scrollbar table-responsive my-table">
                    <div
                        className="container-print media-print-p2"
                        ref={printRef}>
                        <PrintingHeader
                            title={
                                jenisOmset.value === 'default' ? `Omset Member` :
                                    jenisOmset.value === 'pribadi' ? `Omset Pribadi Member` :
                                        jenisOmset.value === 'sponsorgrup' ? `Omset Sponsor Grup Member` : `Omset Member`}
                            subtitle={`${profile?.id_member} - ${profile?.nama || ""}`}
                            date={[filterDate.start, filterDate.end]}
                            printBy={null}
                            printAt={null}
                        />

                        {jenisOmset.value === "default" ? (
                            <Default data={ReportOmset?.Omset || []} />
                        ) : jenisOmset.value === "pribadi" ? (
                            <Pribadi
                                data={
                                    ReportOmset?.Omset?.find(
                                        ({ nama }) => nama === "Omset Pribadi"
                                    )?.detail_pribadi || []
                                }
                            />
                        ) : jenisOmset.value === "sponsorgrup" ? (
                            <SponsorGrup
                                data={
                                    ReportOmset?.Omset?.find(
                                        ({ nama }) => nama === "Omset Sponsor Group"
                                    )?.detail_group || []
                                }
                            />
                        ) : (
                            <Default data={ReportOmset?.Omset || []} />
                        )}
                    </div>
                </div>
            </CardBasic>
        </div>
    );
};

export default Omset;