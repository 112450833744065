import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { Alert } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import CardBasic from "../../../../components/Cards/Basic";
import { formatCurrency, formatDate } from "../../../../constants/utility";
import {
  fetchTracking,
  nullTracking,
  createPayment,
  updateProcessSelesai,
  fetchTransactionInvoice,
  resetPayment,
  getInvoice,
} from "../../../../redux/actions/transaction";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ModalAction from "../../../../components/ModalAction";
import PageHeading from "../../../../components/PageHeading";
import { useCallback } from "react";
const midtransScriptUrl = process.env.REACT_APP_MIDTRANS_SNAP_JS;
const myMidtransClientKey = process.env.REACT_APP_MIDTRANS_PAYMENT_CLIENT_KEY;

const no_close_actions = ["bca_klikpay", "danamon_online"];

const TransaksiDetail = (props) => {
  // const { setRowDetail } = props;
  const { id } = useParams();
  const { title } = props.data;
  const dispatch = useDispatch();
  const { invoice, formResult } = useSelector((state) => state.transaction);
  const { track, paymentToken } = useSelector((state) => state.transaction);
  const [modalOption, setModalOption] = useState({});
  const [modalShow, setModalShow] = useState(false);

  // const [publicId, setPublicId] = useState(null);
  // useEffect(() => {
  //   if(formResult) setPublicId(formResult.result?.public_id)
  // }, [formResult, setPublicId])

  const [snapReady, setSnapReady] = useState(false);
  const [reSnap, setReSnap] = useState(true);
  // const [paymentToken, setPaymentToken] = useState(null);
  useEffect(() => {
    dispatch(getInvoice(null));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchTransactionInvoice(id));
  }, [dispatch, id, formResult]);

  useEffect(() => {
    let scriptTag = document.createElement("script");
    scriptTag.src = midtransScriptUrl;
    scriptTag.setAttribute("data-client-key", myMidtransClientKey);
    document.body.appendChild(scriptTag);
    setSnapReady(true);
    return () => {
      document.body.removeChild(scriptTag);
    };
  }, []);

  const openSnap = useCallback(() => {
    console.log("xyy token", paymentToken?.token?.token || paymentToken?.token);
    window.snap.pay(paymentToken?.token?.token || paymentToken?.token, {
      onSuccess: function (result) {
        dispatch(fetchTransactionInvoice(id));
        dispatch(resetPayment(result));
      },
      onPending: function (result) {
        setReSnap(false);
        dispatch(fetchTransactionInvoice(id));
        dispatch(resetPayment(result));
      },
      onError: function (result) {
        dispatch(fetchTransactionInvoice(id));
        dispatch(resetPayment(result));
      },
      onClose: function () {
        dispatch(resetPayment());
      },
    });
  });

  useEffect(() => {
    if (track) {
      setModalOption({
        title: "Tracking Order",
        content: (
          <div className="pr-3">
            {track ? (
              <ul className="timeline">
                {track?.rajaongkir?.result?.manifest?.map((item, index) => {
                  return (
                    <li key={index}>
                      <a href="#" target="_blank">
                        {item?.city_name}
                      </a>
                      <p className="float-right">
                        {item?.manifest_date} | {item?.manifest_time}
                      </p>
                      <p>{item?.manifest_description}</p>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <></>
            )}
          </div>
        ),
        color: "info",
        size: "lg",
        data: invoice,
      });
      dispatch(nullTracking());
      // props.setActionChild(false);
      fetchTransactionInvoice(id);
    }
  }, [track, id, dispatch, invoice]);

  const modalTrack = (data) => {
    setModalShow(true);
    setModalOption({
      title: "Tracking Order",
      content: (
        <>
          <div className="pr-3">
            <ul className="timeline">
              <li>
                <h3>
                  <Skeleton />
                </h3>

                <p>
                  <Skeleton count={5} />
                </p>
              </li>
            </ul>
          </div>
          <div className="px-3 py-3 border-top">
            <div className="flex">
              {/* <button
                className="btn mr-2 btn-outline-secondary btn-sm"
                onClick={() => setRowDetail(null)}
              >
                Tutup
              </button> */}
            </div>
          </div>
        </>
      ),
      color: "info",
      size: "lg",
      data: data,
    });
    // props.setActionChild(false);
  };

  const modalTandaiSelesai = (invoice) => {
    setModalShow(true);
    setModalOption({
      title: "Tandai Selesai",
      content: (
        <div>
          <p>
            Anda yakin akan menandai pesanan SELESAI ?{" "}
            <strong>{invoice?.no_order}</strong> ?
          </p>
          <hr />
          <Button
            className="float-left"
            size="sm"
            variant={`outline-secondary`}
            onClick={() => setModalShow(false)}
          >
            Batal
          </Button>
          <Button
            className="float-right"
            variant={`primary`}
            size="sm"
            onClick={() => {
              dispatch(updateProcessSelesai(invoice?.no_order));
              setModalShow(false);
            }}
          >
            Proses
          </Button>
        </div>
      ),
      color: "danger",
      size: "md",
      // data: data,
    });
    //props.setActionChild(false);
  };

  useEffect(() => {
    // console.log(
    //   'xyy req create payment',
    //   invoice?.pembayaran,
    //   invoice?.status_order
    // );
    if (!paymentToken && invoice?.status_order === "PENDING") {
      console.log("xyy req create payment 2");
      dispatch(createPayment({ no_order: invoice?.no_order }));
    }
    // setPaymentToken(invoice?.pembayaran?.token);
  }, [invoice, paymentToken, dispatch]);

  useEffect(() => {
    // console.log('xyy resnap', paymentToken , snapReady , invoice?.status_order , reSnap, invoice?.pembayaran);
    console.log(
      "xna",
      no_close_actions.includes(invoice?.payment_method),
      invoice?.payment_method
    );
    if (
      paymentToken &&
      snapReady &&
      invoice?.payment_method !== "cash" &&
      invoice?.payment_method !== "transferbca" &&
      invoice?.status_order === "PENDING" &&
      reSnap &&
      !no_close_actions.includes(invoice?.payment_method)
    ) {
      const sto = setTimeout(() => {
        openSnap();
      }, 2000); /**todo belum tahu ini agar tak usah pakai sto. */

      return () => clearTimeout(sto);
    }
  }, [paymentToken, invoice, reSnap, snapReady, openSnap]);

  const navigate = useNavigate();
  return (
    <>
      <PageHeading goBack={() => navigate(-1)} title={title} />
      <div
      // className={`col-md-5 detail-area ` + (data ? `show-detail-area` : ``)}
      >
        <CardBasic nopadding>
          <div className="py-4 px-4">
            <div className="border rounded p-2 row">
              <div className="col-sm-12 col-md-5">
                <div className="d-flex">
                  <div className="text-left">No Transaksi</div>
                  <div className="flex-grow-1 text-right">
                    {invoice?.no_order}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="text-left">Tanggal</div>
                  <div className="flex-grow-1 text-right">
                    {formatDate(invoice?.tgl_order, false, false, true)}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="text-left">Jenis</div>
                  <div className="flex-grow-1 text-right">
                    {invoice?.join_member === true
                      ? `Registrasi Member  ${invoice?.posisi} - ${invoice?.sisi}`
                      : invoice?.upgrade_member === true
                      ? "Upgrade Member"
                      : "Repeat Order"}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="text-left">Status</div>
                  {invoice?.status_order === "PENDING" && (
                    <div className="flex-grow-1 text-right">
                      <span className="badge badge-pill badge-warning">
                        <strong>{invoice?.status_order}</strong>
                      </span>
                    </div>
                  )}
                  {invoice?.status_order === "PAID" && (
                    <div className="flex-grow-1 text-right">
                      <span className="badge badge-pill badge-info">
                        <strong>{invoice?.status_order}</strong>
                      </span>
                    </div>
                  )}
                  {invoice?.status_order === "PICKUPABLE" && (
                    <div className="flex-grow-1 text-right">
                      <span className="badge badge-pill badge-info">
                        <strong>{invoice?.status_order}</strong>
                      </span>
                    </div>
                  )}
                  {invoice?.status_order === "DELIVERY" && (
                    <div className="flex-grow-1 text-right">
                      <span className="badge badge-pill badge-info">
                        <strong>{invoice?.status_order}</strong>
                      </span>
                    </div>
                  )}
                  {invoice?.status_order === "RECEIVED" && (
                    <div className="flex-grow-1 text-right">
                      <span className="badge badge-pill badge-info">
                        <strong>{invoice?.status_order}</strong>
                      </span>
                    </div>
                  )}
                  {invoice?.status_order === "DONE" && (
                    <div className="flex-grow-1 text-right">
                      <span className="badge badge-pill badge-success">
                        <strong>{invoice?.status_order}</strong>
                      </span>
                    </div>
                  )}
                  {invoice?.status_order === "RETUR" && (
                    <div className="flex-grow-1 text-right">
                      <span className="badge badge-pill badge-success">
                        <strong>{invoice?.status_order}</strong>
                      </span>
                    </div>
                  )}
                  {invoice?.status_order === "CANCEL" && (
                    <div className="flex-grow-1 text-right">
                      <span className="badge badge-pill badge-danger">
                        <strong>{invoice?.status_order}</strong>
                      </span>
                    </div>
                  )}
                  {invoice?.status_order === "EXPIRED" && (
                    <div className="flex-grow-1 text-right">
                      <span className="badge badge-pill badge-danger">
                        <strong>{invoice?.status_order}</strong>
                      </span>
                    </div>
                  )}
                </div>
                <div className="d-flex">
                  <div className="text-left">Total</div>
                  <div className="flex-grow-1 text-right">
                    {formatCurrency(invoice?.total_harga)}
                  </div>
                </div>
                {invoice?.status_order === "RECEIVED" ? (
                  <div className="d-flex">
                    <div className="text-left"></div>
                    <div className="flex-grow-1 text-right mt-2">
                      <button
                        className="btn btn-sm btn-success "
                        onClick={() => {
                          modalTandaiSelesai(invoice);
                        }}
                      >
                        {" "}
                        <i className="fa fa-check"></i> Tandai Selesai
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-sm-12 col-md-2"></div>
              <div className="col-sm-12 col-md-5">
                {[
                  "PICKUPABLE",
                  "PAID",
                  "DELIVERY",
                  "WAREHOUSE",
                  "RECEIVED",
                  "RETUR",
                  "DONE",
                ].includes(invoice?.status_order) && (
                  <>
                    <div className="d-flex">
                      <div className="text-left">No Invoice</div>
                      <div className="flex-grow-1 text-right">
                        {invoice?.no_invoice}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="text-left">Tanggal Invoice</div>
                      <div className="flex-grow-1 text-right">
                        {formatDate(invoice?.tgl_invoice, false, false, true)}
                      </div>
                    </div>
                  </>
                )}
                <div className="d-flex">
                  <div className="text-left">Pembayaran</div>
                  <div className="flex-grow-1 text-right">
                    {invoice?.status_order === "PENDING" ? (
                      <div className="badge badge-warning">
                        Menunggu Pembayaran
                      </div>
                    ) : [
                        "PICKUPABLE",
                        "PAID",
                        "DELIVERY",
                        "WAREHOUSE",
                        "RECEIVED",
                        "RETUR",
                        "DONE",
                      ].includes(invoice?.status_order) ? (
                      <div className="badge badge-success">
                        Pembayaran berhasil
                      </div>
                    ) : invoice?.status_order === "EXPIRED" ? (
                      <div className="badge badge-danger">
                        Pembayaran Kadaluwarsa
                      </div>
                    ) : (
                      ""
                    )}
                    <div>
                      {invoice ? (
                        invoice?.payment_method === "cash" ? (
                          "Pembayaran Tunai"
                        ) : invoice?.payment_method === "transferbca" ? (
                          <div>
                            Transfer BCA
                            <div>
                              NoRek: {invoice?.transferbca?.nomor_rekening_bca}{" "}
                              <br /> a/n :{" "}
                              {invoice?.transferbca?.nama_rekening_bca}
                              <br />
                              Cab: {
                                invoice?.transferbca?.cabang_rekening_bca
                              }{" "}
                            </div>
                          </div>
                        ) : invoice?.payment_method === "credit_card" ? (
                          "Kartu Kredit/Debit"
                        ) : invoice?.payment_method === "danamon_online" ? (
                          "Danamon Online Banking"
                        ) : invoice?.payment_method === "cimb_clicks" ? (
                          "CIMB Clicks"
                        ) : invoice?.payment_method === "akulaku" ? (
                          "Akulaku Paylater"
                        ) : (
                          <>
                            {invoice?.pembayaran?.payment_type ===
                              "bank_transfer" &&
                              `VA Bank ${invoice?.pembayaran?.va_bank?.toUpperCase()} - ${
                                invoice?.pembayaran?.va_number
                              }`}
                            {invoice?.pembayaran?.payment_type === "echannel" &&
                              `VA Bank Mandiri - ${invoice?.pembayaran?.biller_code}${invoice?.pembayaran?.bill_key}`}
                            {invoice?.pembayaran?.payment_type === "qris" &&
                              invoice?.pembayaran?.acquirer ===
                                "airpay shopee" &&
                              "ShopeePay"}
                            {invoice?.pembayaran?.payment_type === "qris" &&
                              invoice?.pembayaran?.acquirer === "gopay" &&
                              "Gopay"}
                            {invoice?.pembayaran?.payment_type === "cstore" &&
                              `${invoice?.pembayaran?.store?.toUpperCase()} - ${
                                invoice?.pembayaran?.payment_code
                              }`}
                            {invoice?.pembayaran?.payment_type ===
                              "bca_klikpay" && `BCA KlikPay`}
                          </>
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {invoice?.status_order === "PENDING" ? (
                  <>
                    <div className="d-flex">
                      <div className="text-left">Batas Pembayaran</div>
                      <div className="flex-grow-1 text-right">
                        {invoice?.pembayaran?.expired_time
                          ? formatDate(
                              invoice?.pembayaran?.expired_time,
                              false,
                              false,
                              true
                            )
                          : "-"}
                        {invoice?.payment_method !== "cash" &&
                        invoice?.payment_method !== "transferbca" ? (
                          <div>
                            <button
                              className="btn btn-primary-f btn-sm"
                              onClick={() => openSnap()}
                            >
                              <strong>Bayar Sekarang</strong>
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-sm-12 col-md-6">
                <div className="">
                  <div className="font-weight-bold mb-2">
                    Detail {invoice?.join_member ? "Calon Member" : "Pembeli"}
                  </div>
                  <div className="d-flex">
                    <div style={{ width: "120px" }}>Nama</div>
                    <div style={{ width: "20px" }}>:</div>
                    <div className="flex-grow-1">{invoice?.nama}</div>
                  </div>
                  {invoice?.join_member && (
                    <>
                      <div className="d-flex">
                        <div style={{ width: "120px" }}>Identitas</div>
                        <div style={{ width: "20px" }}>:</div>
                        <div className="flex-grow-1">
                          {invoice?.nomor_identitas} ({invoice?.jenis_kelamin})
                        </div>
                      </div>
                      <div className="d-flex">
                        <div style={{ width: "120px" }}>Tanggal Lahir</div>
                        <div style={{ width: "20px" }}>:</div>
                        <div className="flex-grow-1">{invoice?.tgl_lahir}</div>
                      </div>
                      <div className="d-flex">
                        <div style={{ width: "120px" }}>Status Kawin</div>
                        <div style={{ width: "20px" }}>:</div>
                        <div className="flex-grow-1">
                          {invoice?.status_kawin}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div style={{ width: "120px" }}>Agama</div>
                        <div style={{ width: "20px" }}>:</div>
                        <div className="flex-grow-1">{invoice?.agama}</div>
                      </div>
                    </>
                  )}

                  <div className="d-flex">
                    <div style={{ width: "120px" }}>Alamat</div>
                    <div style={{ width: "20px" }}>:</div>
                    <div className="flex-grow-1">
                      {invoice?.alamat}
                      <br />
                      {"Kec. " +
                        invoice?.kecamatan.nama +
                        ", Kota " +
                        invoice?.kota.nama +
                        ", Prop. " +
                        invoice?.propinsi.nama}
                      <br />
                      {invoice?.kode_pos}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div style={{ width: "120px" }}>HP/Email</div>
                    <div style={{ width: "20px" }}>:</div>
                    <div className="flex-grow-1">
                      {invoice?.phone} / {invoice?.email}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div style={{ width: "120px" }}>Domisili</div>
                    <div style={{ width: "20px" }}>:</div>
                    <div className="flex-grow-1">
                      {invoice?.kota_domisili?.nama} -
                      {invoice?.propinsi_domisili?.nama}
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  {invoice?.pickup_store === true ? (
                    <>
                      <div className="font-weight-bold mt-2">Pickup Store</div>
                      <div>Paket diambil langsung di kantor :</div>
                      <div>{invoice?.gudang?.nama}</div>
                      <div>{invoice?.gudang?.lokasi}</div>
                    </>
                  ) : (
                    <>
                      <div className="font-weight-bold mt-2">
                        Detail Pengiriman
                      </div>
                      <div className="d-flex">
                        <div style={{ width: "120px" }}>Nama</div>
                        <div style={{ width: "20px" }}>:</div>
                        <div className="flex-grow-1">{invoice?.nama_kirim}</div>
                      </div>
                      <div className="d-flex">
                        <div style={{ width: "120px" }}>Alamat</div>
                        <div style={{ width: "20px" }}>:</div>
                        <div className="flex-grow-1">
                          {invoice?.alamat_kirim}
                          <br />
                          {"Kec. " +
                            invoice?.kecamatan_kirim.nama +
                            ", Kota " +
                            invoice?.kota_kirim.nama +
                            ", Prop. " +
                            invoice?.propinsi_kirim.nama}
                          <br />
                          {invoice?.kode_pos_kirim}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div style={{ width: "120px" }}>Hp</div>
                        <div style={{ width: "20px" }}>:</div>
                        <div className="flex-grow-1">
                          {invoice?.phone_kirim}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div style={{ width: "120px" }}>Eksepedisi</div>
                        <div style={{ width: "20px" }}>:</div>
                        <div className="flex-grow-1">
                          {invoice?.ekspedisi
                            ?.replace("|", " - ")
                            ?.toUpperCase()}{" "}
                          {invoice?.diskon_biaya_kirim > 0 ? (
                            <>
                              <strike>
                                {formatCurrency(invoice?.biaya_kirim)}
                              </strike>
                              {"     "}
                              {formatCurrency(
                                invoice?.biaya_kirim -
                                  invoice?.diskon_biaya_kirim
                              )}
                            </>
                          ) : (
                            formatCurrency(invoice?.biaya_kirim)
                          )}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div style={{ width: "120px" }}>Resi</div>
                        <div style={{ width: "20px" }}>:</div>
                        <div className="flex-grow-1">
                          {invoice?.no_resi || "-"}
                          <div>
                            {invoice?.no_resi &&
                              ["DELIVERY", "RECEIVED", "DONE"].includes(
                                invoice?.status_order
                              ) && (
                                <button
                                  className="btn btn-sm btn-info mr-2"
                                  onClick={() => {
                                    modalTrack();
                                    dispatch(
                                      fetchTracking({
                                        public_id: invoice?.public_id,
                                        waybill: invoice?.no_resi,
                                        courier:
                                          invoice?.ekspedisi.split("|")[0],
                                      })
                                    );
                                  }}
                                >
                                  {" "}
                                  <i className="fa fa-truck"></i> Lacak
                                </button>
                              )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <label className="font-weight-bold">Detail Pesanan</label>
                <table className="table table-bordered table-horizontal">
                  <thead>
                    <tr>
                      <th>Nama</th>
                      <th>Harga</th>
                      <th>Qty</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoice?.order_details.map((item, key) => {
                      return (
                        <tr key={key}>
                          <td className="text-left">{item.barang.nama}</td>
                          <td className="text-right">
                            {formatCurrency(item.harga)}
                          </td>
                          <td className="text-right">{item.qty}</td>
                          <td className="text-right">
                            {formatCurrency(item.qty * item.harga)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="d-flex justify-content-end">
                  <div>
                    Total Barang (
                    {invoice?.order_details?.reduce((a, b) => a + b.qty, 0)})
                  </div>
                  <div className="px-2">:</div>
                  <div style={{ width: "120px" }} className="text-right">
                    {formatCurrency(
                      invoice?.order_details?.reduce(
                        (a, b) => a + b.qty * b.harga,
                        0
                      )
                    )}
                  </div>
                </div>
                {invoice?.pickup_store === false ? (
                  <>
                    <div className="d-flex justify-content-end">
                      <div>
                        Ongkos Kirim (
                        {invoice?.order_details?.reduce(
                          (a, b) => a + b.qty * b.berat,
                          0
                        ) / 1000}{" "}
                        kg)
                      </div>
                      <div className="px-2">:</div>
                      <div style={{ width: "120px" }} className="text-right">
                        {formatCurrency(invoice?.biaya_kirim)}
                      </div>
                    </div>
                    {invoice?.diskon_biaya_kirim > 0 ? (
                      <div className="d-flex justify-content-end">
                        <div>Diskon Ongkos Kirim</div>
                        <div className="px-2">:</div>
                        <div style={{ width: "120px" }} className="text-right">
                          ({formatCurrency(invoice?.diskon_biaya_kirim)})
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
                {invoice?.asuransi_kirim ? (
                  <div className="d-flex justify-content-end">
                    <div>Asuransi Pengiriman</div>
                    <div className="px-2">:</div>
                    <div style={{ width: "120px" }} className="text-right">
                      {formatCurrency(invoice?.asuransi_kirim)}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {invoice?.biaya_pembayaran > 0 ? (
                  <div className="d-flex justify-content-end">
                    <div>Biaya Jasa Aplikasi </div>
                    <div className="px-2">:</div>
                    <div style={{ width: "120px" }} className="text-right">
                      {formatCurrency(invoice?.biaya_pembayaran)}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="d-flex justify-content-end font-weight-bold">
                  <div>Total Pembayaran </div>
                  <div className="px-2">:</div>
                  <div style={{ width: "120px" }} className="text-right">
                    {formatCurrency(invoice?.total_harga)}
                  </div>
                </div>
                {invoice?.is_ppn ? (
                  <>
                    <div className="d-flex justify-content-end mt-4">
                      <div>Dasar Pengenaan Pajak</div>
                      <div className="px-2">:</div>
                      <div style={{ width: "120px" }} className="text-right">
                        {formatCurrency(invoice?.total_dpp)}
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div>PPN</div>
                      <div className="px-2">:</div>
                      <div style={{ width: "120px" }} className="text-right">
                        {formatCurrency(invoice?.total_ppn)}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <hr />
                <label className="font-weight-bold">Riwayat Order</label>
                <div className="list-group">
                  <div className="list-group-item list-group-item-action flex-column align-items-start">
                    <p className="mb-0">
                      {formatDate(invoice?.tgl_order, false, false, true)}{" "}
                      PENDING
                    </p>
                    <small>oleh : Pembeli</small>
                  </div>
                  {invoice?.order_histories?.map((item) => {
                    return (
                      <>
                        <div className="list-group-item list-group-item-action flex-column align-items-start">
                          <p className="mb-0">
                            {formatDate(item.tanggal, false, false, true)}{" "}
                            {item.status}{" "}
                          </p>
                          <small>
                            oleh :{" "}
                            {item.id_admin === 0
                              ? "User"
                              : item.admin?.nama || "System"}
                          </small>
                          {item.status === "RECEIVED" && (
                            <>
                              <br />
                              <small className="border-t">
                                Diterima pada :{" "}
                                {formatDate(
                                  invoice?.received_at,
                                  false,
                                  false,
                                  true
                                )}
                              </small>
                              <br />
                              <small className="border-t">
                                Penerima :{" "}
                                {invoice?.received_by || invoice?.nama_kirim}
                              </small>
                            </>
                          )}
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="mt-3">
                  <Alert
                    color={
                      invoice?.status_order === "PENDING"
                        ? "warning"
                        : invoice?.status_order === "CANCEL"
                        ? "danger"
                        : invoice?.status_order === "DONE"
                        ? "success"
                        : invoice?.status_order === "DELIVERY"
                        ? "primary"
                        : invoice?.status_order === "PAID"
                        ? "info"
                        : "hidden"
                    }
                  >
                    <p>
                      {invoice?.status_order === "PENDING"
                        ? "Pembayaran pending"
                        : invoice?.status_order === "CANCEL"
                        ? "Transaksi dibatalkan"
                        : invoice?.status_order === "DONE"
                        ? "Transaksi selesai"
                        : invoice?.status_order === "DELIVERY"
                        ? "Transaksi dalam perjalanan"
                        : invoice?.status_order === "PAID"
                        ? "Pembayaran success"
                        : ""}
                    </p>
                    <hr />
                    <p className="mb-0">
                      {invoice?.status_order === "PENDING"
                        ? "Segera lakukan pembayaran untuk transaksimu"
                        : invoice?.status_order === "CANCEL"
                        ? "Transaksi telah dibatalkan"
                        : invoice?.status_order === "DONE"
                        ? "Transaksi telah selesai"
                        : invoice?.status_order === "DELIVERY"
                        ? "Transaksi dalam pengiriman courier"
                        : invoice?.status_order === "PAID"
                        ? "Pembayaran success ,pesananmu akan segera dikirim"
                        : ""}
                    </p>
                  </Alert>
                </div>
              </div>
            </div>
          </div>
        </CardBasic>
        <ModalAction
          show={modalShow}
          modalOption={modalOption}
          onHide={() => setModalShow(false)}
        />
      </div>
    </>
  );
};

export default TransaksiDetail;
