import React, {
  useState,
  useRef,
  useLayoutEffect,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import axios from "axios";

const UsernameWithSugestion = ({
  name,
  type,
  placeholder,
  className,
  label,
  register,
  errors,
  readOnly,
  layout,
  fullName,
  onSet
}) => {
  const nested = name.split('.');
  let error = null;
  if (nested.length > 0) {
    let nError = { ...errors };
    nested.forEach((a) => {
      error = nError?.[a];
      nError = error;
    });
  } else {
    error = errors[name];
  }
  if (error) console.log('username', 'error', error, name);

  const { data: token } = useSelector((state) => state?.auth);
  const [username, setUsername] = useState();
  const [sugestion, setSugestion] = useState([]);
  const [errorMessage, setErrorMessage] = useState();

  const throtled = useRef(false);

  const handleValidation = async () => {
    const { data } = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/member/opt/username-check`,
      data: {
        check: {
          username,
          fullName
        }
      },
      headers: {
        Authorization: `Bearer ${token?.access_token}`,
      },
    });

    setSugestion(data?.result?.sugestion);
    setErrorMessage(data?.result?.errorMessage);
  }

  useLayoutEffect(() => {
    if (throtled.current) {
      let debounce = setTimeout(async () => {
        handleValidation();
      }, 500);
      return () => {
        return clearTimeout(debounce);
      }
    } else {
      throtled.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, fullName]);

  return (
    <div className={`form-group` + (layout === 'horizontal' ? ` row` : ``)}>
      {label && (
        <label
          htmlFor={name}
          className={
            `font-weight-bold` +
            (layout === 'horizontal' ? ` col-sm-4 col-form-label` : ``)
          }>
          {label}
        </label>
      )}

      <div className={layout === 'horizontal' ? `col-sm-8` : ``}>
        <input
          id={name}
          className={`form-control large ${className} ` + (error ? `border-danger` : ``)}
          type={type}
          readOnly={readOnly}
          placeholder={placeholder}
          {...register(name, {
            required: "Masukkan username",
            validate: (value) => {
              const regex = /^[a-zA-Z0-9]*$/;
              if (!regex.test(value)) {
                return false
              } else {
                setUsername(value);
                return true;
              }
            },
            onChange: (e) => {
              let val = e.target.value
                .toString()
                .replace(/[^a-zA-Z0-9]/g, "")
                .replaceAll(" ", "");
              e.target.value = val;
            },
          })}
        />
        <div className="d-flex" style={{ gap: 2, justifyContent: "space-between", alignItems: "center" }}>
          <div className="d-flex" style={{ gap: 4, maxWidth: "200px" }}>
            {sugestion && (
              sugestion.map((item, key) => {
                return (
                  <p
                    onClick={() => {
                      onSet(item);
                      setUsername(item);
                    }}
                    key={key}
                    onMouseEnter={(event) => {
                      event.target.style.textDecoration = "underline"
                    }}
                    onMouseLeave={(event) => {
                      event.target.style.textDecoration = "none"
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {item?.toLowerCase()}
                  </p>
                )
              })
            )}
          </div>
          {error && (
            <div className="text-danger small text-right mt-3">
              {error.message}
            </div>
          )}
          {errorMessage && (
            <div className="text-danger small text-right mt-3">
              {errorMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UsernameWithSugestion;
