import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../constants/utility";

const initialState = {
  listInformasi: null,
  formResult: null,
};

const getInformasiList = (state, data) => {
  return updateObject(state, {
    listInformasi: data,
  });
};

const formResult = (state, data) => {
  return updateObject(state, {
    formResult: data,
  });
};

const reducer = (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
    case actionTypes.GET_INFORMASI_LIST:
      return getInformasiList(state, data);
    case actionTypes.INFORMASI_FORM_RESULT:
      return formResult(state, data);
    default:
      return state;
  }
};

export default reducer;
