import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import logoBlack from '../../assets/img/logo-black.png';
import TextInput from '../../components/TextInput';
import * as actions from '../../redux/actions';
import { useForm } from 'react-hook-form';
import { MainWrapper } from '../MainWrapper';
import { setForgotPassword } from '../../redux/actions/login';
import { Alert } from 'reactstrap';

const ForgotPassword = () => {
  const { loading, forgotPassword } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleForgotPassword = (data) => {
    dispatch(actions.forgotPassword(data));
  };

  useEffect(() => {
    dispatch(setForgotPassword(null));
  }, [dispatch]);

  return (
    <div className='container'>
      {/* <!-- Outer Row --> */}
      <div className='row justify-content-center'>
        <div className='col-xl-5 col-lg-5 col-md-10'>
          <div className='card o-hidden border-0 shadow-lg my-5'>
            <div className='card-body p-0'>
              <div className='p-5'>
                <div className='text-center'>
                  <div className='top-brand'>
                    <a href='/'>
                      <img
                        className='top-brand-img'
                        src={logoBlack}
                        width='50'
                        alt='logo'
                      />
                      <div className='top-brand-text'>Lupa Password</div>
                    </a>
                  </div>
                </div>
                <hr />
                {!forgotPassword ? (
                  <form
                    onSubmit={handleSubmit(handleForgotPassword)}
                    className='user'
                  >
                    {/* <h6 className='text-center font-weight-bold text-dark mt-4 mb-2'>Lupa Password</h6> */}
                    <p className='text-center'>
                      Masukkan ID Member / Username untuk mengirim link reset
                    </p>
                    <TextInput
                      type='text'
                      name='username_or_id'
                      placeholder='ID Member / Username'
                      register={register}
                      validations={{
                        required: 'ID Member / Username harus diisi',
                      }}
                      errors={errors}
                      className='form-control-user'
                    />
                    <button
                      disabled={loading}
                      type='submit'
                      className='btn btn-primary-f btn-user btn-block'
                    >
                      <i
                        className={
                          `fas ` + (!loading ? `fa-sync` : `fa-spinner fa-spin`)
                        }
                      ></i>
                      &nbsp; KIRIM RESET LINK
                    </button>
                    {/* fa-spinner fa-spin */}
                  </form>
                ) : (
                  <Alert color='success'>
                    Link reset password telah dikirim ke alamat email
                  </Alert>
                )}
                <hr />
                <div className='text-center'>
                  <Link className='small' to='/signin'>
                    Signin
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MainWrapper(ForgotPassword);
