import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { formatDateOnly } from '../../../constants/utility';
const Details = ({ data, is_poin }) => {
  const columns = [
    {
      dataField: '_',
      text: 'No',
      headerClasses: 'py-2 px-2 text-center',
      classes: 'p-2',
      headerStyle: (colum, colIndex) => {
        return { width: '40px' };
      },
      formatter: (cel, row, rowIndex) => (
        <div className="text-center">{rowIndex + 1}</div>
      ),
      footer: (colData) => <div className="text-center">{colData?.length}</div>,
    },
    {
      dataField: 'tgl_join',
      text: 'Tanggal Join',
      headerStyle: (colum, colIndex) => {
        return { width: '90px' };
      },
      headerClasses: (column, colIndex) => {
        return 'py-2 px-2 text-left';
      },
      classes: (column, colIndex) => {
        return 'p-2';
      },
      formatter: (val, row) => (
        <div>
          {formatDateOnly(row?.order?.member_terbentuk?.tgl_join, true)}
        </div>
      ),
      footer: '',
    },
    {
      dataField: '',
      text: 'Member Sponsor',
      headerStyle: (colum, colIndex) => {
        return { width: '180px' };
      },
      headerClasses: (column, colIndex) => {
        return 'py-2 px-2 text-left';
      },
      classes: (column, colIndex) => {
        return 'p-2';
      },
      formatter: (val, row) => (
        <div>{`${row?.order?.member_terbentuk?.id_member} - ${row?.order?.member_terbentuk?.nama}`}</div>
      ),
      footer: '',
    },
    {
      dataField: 'order.no_invoice',
      text: 'Invoice',
      headerStyle: { width: '180px' },
      headerClasses: 'py-2 px-2 text-left',
      classes: 'p-2',
      footer: '',
    },
    {
      dataField: 'jenis_order',
      text: 'Jenis',
      headerStyle: (colum, colIndex) => {
        return { width: '90px' };
      },
      headerClasses: (column, colIndex) => {
        return 'py-2 px-2 text-center';
      },
      classes: (column, colIndex) => {
        return 'p-2 text-center';
      },
      formatter: (val, row) => (val == 'reg' ? 'Registrasi' : val == 'upg' ? 'Upgrade' : val == 'ro' ? 'Repeat Order' : ''),
      footer: '',
    },
    {
      dataField: 'poin',
      text: `${is_poin ? 'BV' : 'Nominal'}`,
      headerStyle: (colum, colIndex) => {
        return { width: '90px' };
      },
      headerClasses: (column, colIndex) => {
        return 'py-2 px-2 text-center';
      },
      classes: (column, colIndex) => {
        return 'p-2 text-center';
      },
      footerClasses: 'text-center',
      formatter: (val, row) => (is_poin ? val * 4 : row.nominal),
      footer: () =>
        data?.reduce((a, b) => a + (is_poin ? b.poin * 4 : b.nominal), 0),
    },
    {
      dataField: 'claim_by',
      text: `Status Klaim`,
      headerStyle: (colum, colIndex) => {
        return { width: '90px' };
      },
      headerClasses: (column, colIndex) => {
        return 'py-2 px-2 text-center';
      },
      classes: (column, colIndex) => {
        return 'p-2 text-center';
      },
      formatter: (val, row) => (val ? 'Sudah' : 'Belum'),
      footer: () => '',
    },
  ];

  return (
    <div className="bt-table border" style={{ overflowY: 'auto' }}>
      <BootstrapTable
        className="bt-table"
        bootstrap4
        hover
        condensed
        noDataIndication={() => {
          return 'No data';
        }}
        bordered={false}
        keyField="id"
        data={data || []}
        columns={columns}
      />
    </div>
  );
};

export default Details;
