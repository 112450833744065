import React, { useEffect, useState, useRef } from 'react';

const formatPhone = (val) => {
  let newVal = '';
  for (let i = 0; i < val?.length; i++) {
    newVal += val[i];
    if (i !== val.length - 1) {
      if (i === 1) newVal += ' ';
      if (i === 4) newVal += ' ';
      if (i === 7) newVal += ' ';
      if (i === 10) newVal += ' ';
    }
  }
  return newVal;
};

const PhoneInput = ({
  name,
  placeholder,
  defaultValue,
  className,
  label,
  register,
  errors,
  readOnly,
  layout,
}) => {
  const nested = name.split('.');
  let error = null;
  if (nested.length > 0) {
    let nError = { ...errors };
    nested.forEach((a) => {
      error = nError?.[a];
      nError = error;
    });
  } else {
    error = errors[name];
  }
  if (error) console.log('xww', 'error', error, name);

  const countries = [
    { code: '62', name: 'ID', label: 'Indonesia' },
    { code: '61', name: 'AU', label: 'Australia' },
    { code: '65', name: 'SG', label: 'Singapura' },
    { code: '60', name: 'MY', label: 'Malaysia' }

  ];
  const [defCountry, setDefCountry] = useState(countries[0]);
  const [phone, setPhone] = useState('')

  useEffect(() => {
    setPhone(`${defCountry.code}`)
  }, [defCountry])

  return (
    <div className={`form-group` + (layout === 'horizontal' ? ` row` : ``)}>
      {label ? (
        <label
          htmlFor={name}
          className={
            `font-weight-bold` +
            (layout === 'horizontal' ? ` col-sm-4 col-form-label` : ``)
          }>
          {label}
        </label>
      ) : (
        ''
      )}
      <div className={layout === 'horizontal' ? `col-sm-8` : ``}>
        <>
          <div className="input-group">
            <div className="input-group-prepend">
              <button
                className="btn dropdown-toggle border"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                {defCountry?.name}
              </button>
              <div className="dropdown-menu">
                {countries?.map(({ label }, ix) => (
                  <a alt={label}
                    key={label}
                    className="dropdown-item"
                    onClick={() => setDefCountry(countries[ix])}>
                    {label}
                  </a>
                ))}
              </div>
            </div>
            <input
              type="text"
              maxLength={20}
              name={name}
              className={
                `form-control large ${className} ` +
                (error ? `border-danger` : ``)
              }
              value={phone}
              readOnly={readOnly}
              placeholder={placeholder}
              defaultValue={defaultValue}
              {...register(name, {
                required: true,
                validate: (value) => {
                  const val = value.toString().replaceAll(' ', '');
                  if (defCountry.name === 'ID') {
                    if (!val.startsWith('628'))
                      return 'Nomor tidak valid, contoh: 62 8xx xxx xxx';
                    if (val.length < 11) return 'Nomor minimal 10 digit';
                    if (val.length > 14) return 'Nomor maksimal 14 digit';
                  }
                  if (val.length < 11) return 'Nomor minimal 10 digit';
                  if (val.length > 14) return 'Nomor maksimal 14 digit';
                  return true;
                },
                onChange: (e) => {
                  let val = e.target.value
                    .toString()
                    .replace(/\D/g, '')
                    .replaceAll(' ', '');
                  while (val.startsWith('0')) {
                    val = val.slice(1);
                  }
                  if (!val.startsWith(`${defCountry.code}`) && val.length > 0) val = `${defCountry.code}`;
                  if (val.startsWith(`${defCountry.code}0`)) val = `${defCountry.code}${val.slice(3)}`;
                  if (val === `${defCountry.code}6`) val = `${defCountry.code}`;
                  if (val === '') val = `${defCountry.code}`;
                  e.target.value = formatPhone(val);
                  setPhone(formatPhone(val))
                },
              })}
            />
          </div>
          {error ? (
            <div className="text-danger small py-2 text-right">
              {error.message}
            </div>
          ) : (
            ''
          )}
        </>
      </div>
    </div>
  );
};

export default PhoneInput;
export { formatPhone };
