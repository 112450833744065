import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import thunk from "redux-thunk";
// internal imports
import reducers from "../reducers";


const middleware = [thunk];
if (process.env.REACT_APP_NODE_ENV === 'development') {
  middleware.push(logger);
}

//thunk middleware is used to intercept actions so as to make API call before dispatching result to reducer
const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
