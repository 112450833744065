import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// import logoBlack from "../../../assets/img/logo-black.png";
import { fetchVerifikasiNpwp } from '../../../redux/actions/account';

const VerifikasiNpwp = () => {
    const {token}  = useParams();
    const dispatch = useDispatch();
    const {formResult} = useSelector((state)=> state.account);
    
    useEffect(() => {
        if(token){
            dispatch(fetchVerifikasiNpwp(token))
        }
    }, [token, dispatch])
    console.log("result", formResult)

    
    if(!formResult) return "";
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-8 col-md-10">
                    <div className='mt-4'>
                        <div className={`alert alert-${formResult.valid ? 'success' : 'danger'}`} role="alert">
                            <h4 className="alert-heading">{formResult.valid ? 'Verifikasi Berhasil' : 'Verifikasi Gagal'}</h4>
                            <p>{formResult.message}</p>
                            <hr/>
                            <a href='https://member.thefavored-one.com/account/profile' className='btn btn-info btn-md'>Kembali ke Halaman Member</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerifikasiNpwp