import * as actionTypes from '../../../constants/actionTypes';
import { downloadBlob } from '../../../services/NetworkService';
import { showAlert } from '../alert';
import * as api from '../api';

// local functions

const getOmset = (data) => {
  return {
    type: actionTypes.GET_REPORT_OMSET,
    data: data,
  };
};

const getPerkembanganJaringan = (data) => {
  return {
    type: actionTypes.GET_REPORT_PERKEMBANGAN_JARINGAN,
    data: data,
  };
};

const getUpgrade = (data) => {
  return {
    type: actionTypes.GET_REPORT_UPGRADE,
    data: data,
  };
};

const getRegister = (data) => {
  return {
    type: actionTypes.GET_REPORT_REGISTER,
    data: data,
  };
};

const getBonus = (data) => {
  return {
    type: actionTypes.GET_REPORT_BONUS,
    data: data,
  };
};

const getBonusBulanan = (data) => {
  return {
    type: actionTypes.GET_REPORT_BONUS_BULANAN,
    data: data,
  };
};

const getRedemptionStatement = (data) => {
  return {
    type: actionTypes.GET_REDEMPTION_STATEMENT,
    data: data,
  };
};

const setPayout = (data) => {
  return {
    type: actionTypes.SET_REPORT_PAYOUT,
    data: data,
  };
};

const setBonusUnPayout = (data) => {
  return {
    type: actionTypes.GET_BONUS_UNPAYOUT,
    data: data,
  };
};

const getDashboard = (data) => {
  return {
    type: actionTypes.GET_REPORT_DASHBOARD,
    data: data,
  };
};

const setBanner = (data) => {
  return {
    type: actionTypes.GET_BANNER,
    data: data,
  };
};

const setMedia = (data) => {
  return {
    type: actionTypes.GET_MEDIA,
    data: data,
  };
};

// export actions

export const setFormResult = (payload) => {
  return {
    type: actionTypes.REPORT_FORM_RESULT,
    data: payload,
  };
};

export const fetchPerkembanganJaringan = (query) => async (dispatch) => {
  try {
    const { result } = await api.getPerkembanganJaringan(query);
    dispatch(getPerkembanganJaringan(result));
  } catch (err) {
    console.error(err);
  }
};

export const fetchBonus = (query) => async (dispatch) => {
  try {
    const { result } = await api.getBonus(query);
    dispatch(getBonus(result));
  } catch (err) {
    console.error(err);
  }
};

export const fetchBonusBulanan = (query) => async (dispatch) => {
  try {
    const { result } = await api.getBonusBulanan(query);
    dispatch(getBonusBulanan(result));
  } catch (err) {
    console.error(err);
  }
};

const getBonusHarian = (data) => {
  return {
    type: actionTypes.GET_REPORT_BONUS_HARIAN,
    data: data,
  };
};

export const fetchBonusHarian = (query) => async (dispatch) => {
  try {
    const { result } = await api.getBonusHarian(query);
    dispatch(getBonusHarian(result));
  } catch (err) {
    console.error(err);
  }
};

export const fetchRedemptionStatement = (query) => async (dispatch) => {
  try {
    const { result } = await api.getRedemptionStatement(query);
    dispatch(getRedemptionStatement(result));
  } catch (err) {
    console.error(err);
  }
};

export const setDownlineReport = (data) => {
  return {
    type: actionTypes.GET_DOWNLINE_REPORT,
    data: data,
  };
};

export const fetchDownlineReport = (query) => async (dispatch) => {
  try {
    const { result } = await api.getDownlineReport(query);
    dispatch(setDownlineReport(result));
  } catch (err) {
    console.error(err);
  }
};

export const fetchBonusUnpayout = () => async (dispatch) => {
  try {
    const { result } = await api.getBonusUnpayout();
    dispatch(setBonusUnPayout(result));
  } catch (err) {
    console.error(err);
  }
};

export const fetchUpgrade = (query) => async (dispatch) => {
  try {
    const { result } = await api.getUpgrade(query);
    dispatch(getUpgrade(result));
  } catch (err) {
    console.error(err);
  }
};

export const fetchRegister = (query) => async (dispatch) => {
  try {
    const { result } = await api.getRegister(query);
    dispatch(getRegister(result));
  } catch (err) {
    console.error(err);
  }
};

export const fetchDashboard = (query) => async (dispatch) => {
  try {
    const { result } = await api.getDashboard(query);
    dispatch(getDashboard(result));
  } catch (err) {
    console.error(err);
  }
};

export const setBannerPromo = (data) => {
  return {
    type: actionTypes.GET_BANNER_PROMO,
    data: data,
  };
};

export const fetchBannerPromo = (query) => async (dispatch) => {
  try {
    const { result } = await api.getBannerPromo(query);
    dispatch(setBannerPromo(result));
  } catch (err) {
    console.error(err);
  }
};

export const claimPromo = (query) => async (dispatch) => {
  try {
    const result = await api.postClaimPromo(query);
    dispatch(showAlert({ message: result?.message, show: true }));
    dispatch(setFormResult(result));
    dispatch(fetchBannerPromo());
  } catch (err) {
    console.error(err);
  }
};

export const fetchBannerOne = (query) => async (dispatch) => {
  try {
    const { result } = await api.getBannerOne(query);
    dispatch(setBanner(result));
  } catch (err) {
    console.error(err);
  }
};

export const createUpgrade = (query) => async (dispatch) => {
  try {
    const result = await api.postUpgrade(query);
    dispatch(showAlert({ message: result?.message, show: true }));
    dispatch(setFormResult(result));
    dispatch(fetchUpgrade());
  } catch (err) {
    console.error(err);
  }
};

export const createRegister = (query) => async (dispatch) => {
  try {
    const result = await api.postRegister(query);
    dispatch(showAlert({ message: result?.message, show: true }));
    dispatch(setFormResult(result));
    dispatch(fetchUpgrade());
    dispatch(fetchRegister());
    dispatch(fetchPerkembanganJaringan());
  } catch (err) {
    console.error(err);
  }
};

export const createPayout = (query) => async (dispatch) => {
  try {
    const result = await api.postPayout(query);
    dispatch(showAlert({ message: result?.message, show: true }));
    dispatch(setFormResult(result));
  } catch (err) {
    console.error(err);
  }
};

export const fetchPayout = (query) => async (dispatch) => {
  try {
    const { result } = await api.getPayout(query);
    dispatch(setPayout(result));
  } catch (err) {
    console.error(err);
  }
};

export const fetchReportOmset = (query) => async (dispatch) => {
  try {
    const { result } = await api.getReportOmset(query);
    dispatch(getOmset(result));
  } catch (err) {
    console.error(err);
  }
};

const getPPh = (data) => {
  return {
    type: actionTypes.GET_REPORT_PPH,
    data: data,
  };
};
export const fetchReportPPh = (query) => async (dispatch) => {
  try {
    const { result } = await api.getReportPPh(query);
    dispatch(getPPh(result));
  } catch (err) {
    console.error(err);
  }
};

export const fetchDownloadBupot = (id) => async (dispatch) => {
  try {
    return downloadBlob(
      `/reports/download-bupot/${id}`,
      {},
      `${Date.now()}.pdf`
    );
  } catch (err) {
    console.error(err);
  }
};

export const setReportOmset = (data) => {
  return {
    type: actionTypes.GET_REPORT_OMSET,
    data: data,
  };
};

export const setPromoOpt = (data) => {
  return {
    type: actionTypes.GET_PROMO_OPT,
    data: data,
  };
};
export const fetchPromoOpt = (query) => async (dispatch) => {
  try {
    const { result } = await api.getPromoOpt(query);
    dispatch(setPromoOpt(result));
  } catch (err) {
    console.error(err);
  }
};

export const setPromoDetail = (data) => {
  return {
    type: actionTypes.GET_PROMO_DETAIL,
    data: data,
  };
};
export const fetchPromoDetail = (id) => async (dispatch) => {
  try {
    const { result } = await api.getPromoDetail(id);
    dispatch(setPromoDetail(result));
  } catch (err) {
    console.error(err);
  }
};
