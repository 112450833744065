import * as actionTypes from '../../../constants/actionTypes';
import { updateObject } from '../../../constants/utility';

const initialState = {
  barang: null,
  history: null,
  kode: null,
  track: null,
  invoice: null,
  paymentToken: null,
  paymentAddress: null,
  downline: null,
  paymentMethod: null,
  formResult: null,
  retur: null,
  returAbles: null,
  pengirimanCost: null,
  formOrder: null,
  shipping: null,
  gudang: null,
  settings: null,
};

const getPengirimanCost = (state, data) => {
  return updateObject(state, {
    pengirimanCost: data,
  });
};

const getTracking = (state, data) => {
  return updateObject(state, {
    track: data,
  });
};

const getKode = (state, data) => {
  return updateObject(state, {
    kode: data,
  });
};

const getBarang = (state, data) => {
  return updateObject(state, {
    barang: data,
  });
};

const getDownline = (state, data) => {
  return updateObject(state, {
    downline: data,
  });
};

const getInvoice = (state, data) => {
  return updateObject(state, {
    invoice: data,
  });
};

const getHistory = (state, data) => {
  return updateObject(state, {
    history: data,
  });
};

const getPaymenMethod = (state, data) => {
  return updateObject(state, {
    paymentMethod: data,
  });
};

const paymentToken = (state, data) => {
  return updateObject(state, {
    paymentToken: data,
  });
};

const paymentAddress = (state, data) => {
  return updateObject(state, {
    paymentAddress: data,
  });
};

const formResult = (state, data) => {
  return updateObject(state, {
    formResult: data,
  });
};

const setRetur = (state, data) => {
  return updateObject(state, {
    retur: data,
  });
};

const setReturAble = (state, data) => {
  return updateObject(state, {
    returAbles: data,
  });
};

const setFormOrder = (state, data) => {
  return updateObject(state, {
    formOrder: data,
  });
};

const setShipping = (state, data) => {
  return updateObject(state, {
    shipping: data,
  });
};

const setGudang = (state, data) => {
  return updateObject(state, {
    gudang: data,
  });
};

const getSettings = (state, data) => {
  return updateObject(state, {
    settings: data,
  });
};

const reducer = (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
    case actionTypes.GET_SHIPPING:
      return setShipping(state, data);
    case actionTypes.FORM_ORDER:
      return setFormOrder(state, data);
    case actionTypes.GET_RETUR_ABLE:
      return setReturAble(state, data);
    case actionTypes.GET_RETUR:
      return setRetur(state, data);
    case actionTypes.GET_BARANG:
      return getBarang(state, data);
    case actionTypes.GET_TRACKING:
      return getTracking(state, data);
    case actionTypes.PENGIRIMAN_COST:
      return getPengirimanCost(state, data);
    case actionTypes.GET_PAYMENT_METHOD:
      return getPaymenMethod(state, data);
    case actionTypes.GET_TRANSACTION_INVOICE:
      return getInvoice(state, data);
    case actionTypes.GET_TRANSACTION_DOWNLINE:
      return getDownline(state, data);
    case actionTypes.GET_TRANSACTION_KODE:
      return getKode(state, data);
    case actionTypes.GET_TRANSACTION_HISTORY:
      return getHistory(state, data);
    case actionTypes.TRANSACTION_PAYMENT_TOKEN:
      return paymentToken(state, data);
    case actionTypes.TRANSACTION_PAYMENT_ADDRESS:
      return paymentAddress(state, data);
    case actionTypes.TRANSACTION_FORM_RESULT:
      return formResult(state, data);
    case actionTypes.GET_GUDANG:
      return setGudang(state, data);
    case actionTypes.SET_PROCESS_SELESAI:
      return formResult(state, data);
    case actionTypes.GET_SETTINGS:
      return getSettings(state, data);
    default:
      return state;
  }
};

export default reducer;
