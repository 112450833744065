import React from 'react';
// import { Chart } from "chart.js";
import CardBasic from '../../Cards/Basic';
// import { dataSet } from "../data";
import useChart from '../useChart';

const ChartDonut = (props) => {
  const canvasRef = React.useRef(null);
  // const [data, setData] = React.useState(dataSet);

  useChart(
    canvasRef,
    {
      type: 'doughnut',
      data: {
        labels: [
          `Kiri ${((props?.childKiri / props?.downline) * 100).toFixed(2)}%`,
          `Kanan ${((props?.childKanan / props?.downline) * 100).toFixed(2)}%`,
        ],
        datasets: [
          {
            data: [props?.childKiri, props?.childKanan],
            backgroundColor: ['#FABB51', '#3E8E7E'],
            hoverBackgroundColor: ['#FABB51', '#3E8E7E'],
            hoverBorderColor: 'rgba(234, 236, 244, 1)',
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        tooltips: {
          backgroundColor: 'rgb(255,255,255)',
          bodyFontColor: '#858796',
          borderColor: '#dddfeb',
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: false,
          caretPadding: 10,
        },
        legend: {
          display: false,
        },

        cutoutPercentage: 80,
      },
    },
    props.cref
  );

  return (
    <CardBasic
      title={
        <div>
          <strong>Downline Chart</strong>
        </div>
      }>
      <div className="chart-pie pt-4">
        <canvas id={props.cref} ref={canvasRef} />
      </div>
      <hr style={{ marginTop: '37px' }} /> Total Downline :
      <strong> {props?.downline} Member </strong>
    </CardBasic>
  );
};

export default ChartDonut;
