import React from "react";
import { Link } from "react-router-dom";

const CardBasic = (props) => {
  const { title, nopadding, hidden, goBack } = props;

  return (
    <div className="card shadow" hidden={hidden}>
      {title ? (
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">
            {goBack ? (
              <Link to={goBack}>
                <span
                  className="btn btn-primary btn-sm mr-3"
                  style={{ width: "50px" }}
                >
                  <i className="fas fa-arrow-left"></i>
                </span>
              </Link>
            ) : (
              ""
            )}
            {title}
          </h6>
        </div>
      ) : (
        ""
      )}
      <div className={`card-body ${nopadding ? "p-0" : ""}`}>
        {props.children}
      </div>
    </div>
  );
};

export default CardBasic;

// import React, { Component } from "react";

// class CardBasic extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       title: "",
//       useClose: false,
//     };
//   }

//   componentDidMount() {
//     this.setState({ title: this.props.title });
//     this.setState({ useClose: this.props.useClose });
//   }

//   render() {
//     return (
//       <div className="card shadow mb-4">
//         {this.state.title ? (
//           <>
//             {" "}
//             <div className="card-header py-3">
//               <div className="d-flex justify-content-between">
//                 <h6 className="m-0 font-weight-bold text-primary">
//                   {this.state.title}
//                 </h6>
//                 {this.state.useClose ? (
//                   <span
//                     className=" m-0 font-weight-bold text-primary "
//                     style={{ cursor: "pointer" }}
//                     onClick={() => {
//                       this.state.useClose(false);
//                     }}
//                   >
//                     X
//                   </span>
//                 ) : (
//                   ""
//                 )}
//               </div>
//             </div>
//           </>
//         ) : (
//           ""
//         )}
//         <div className="card-body">{this.props.children}</div>
//       </div>
//     );
//   }
// }

// export default CardBasic;
