import React from 'react'
import { formatDate, formatDate2 } from '../../constants/utility'
import logo from "../../assets/img/logo-black.png";

const PrintingHeader = ({
  title,
  subtitle,
  date,
  printBy,
  printAt,
  printCounter
}) => {
  return (
    <div className="print-header">
      <div className="d-none d-print-block">
        <div className="print-comany d-flex p-2 mb-2">
          <img className="company-logo" src={logo} alt="logo" width="50" />
          <div className="ml-2">
            <div className="company-name font-weight-bold">THE FAVORED-ONE</div>
            <div className="company-address-1 text-xs">Jl Karang Asem IV No 34 Surabaya</div>
            <div className="company-address-2 text-sm"></div>
          </div>
        </div>
        <div className="mt-2 text-xs text-right font-weight-bold">{printBy} {formatDate(printAt, false, true)} {printCounter ? ` | ${printCounter}x` : ''}</div>
      </div>
      <div className="text-center mb-1 font-weight-bold print-title">{title}</div>
      {subtitle ? <div className="text-center mb-1 font-weight-bold print-title">{subtitle}</div> : '' }
      {date?.length ? (
        <div className="text-center font-weight-bold text-xs mb-2">
          {date.length === 1 ? 
            `Tanggal ${formatDate2(date[0], true, true, true)}`
            :
            `Periode : ${formatDate2(date[0], true, true, true)} s.d ${formatDate2(date[1], true, true, true)}`
          }
        </div>

      ) : ''}
    </div>
  )
}

export default PrintingHeader