import * as actionTypes from "../../../constants/actionTypes";
import { showAlert } from "../alert";
import * as api from "../api";

// local functions

const getAccountProfile = (data) => {
  return {
    type: actionTypes.GET_ACCOUNT_PROFILE,
    data: data,
  };
};

const checkMemberBonusStatus = (data) => {
  return {
    type: actionTypes.CHECK_MEMBER_BONUS_STATUS,
    data: data,
  };
};

const getListBank = (data) => {
  return {
    type: actionTypes.GET_LIST_BANK,
    data: data,
  };
};

const getAccountAddress = (data) => {
  return {
    type: actionTypes.GET_ACCOUNT_ADDRESS,
    data: data,
  };
};

// export actions

export const setFormResult = (payload) => {
  return {
    type: actionTypes.ACCOUNT_FORM_RESULT,
    data: payload,
  };
};

export const fetchVerifikasiBank = (token) => async (dispatch) => {
  try {
    const { result } = await api.postVerifikasiBank(token);
    dispatch(setFormResult(result));
  } catch (err) {
    console.error(err);
  }
};

export const fetchVerifikasiNpwp = (token) => async (dispatch) => {
  try {
    const { result } = await api.postVerifikasiNpwp(token);
    dispatch(setFormResult(result));
  } catch (err) {
    console.error(err);
  }
};

export const fetchResendVerifikasiBank = (query) => async (dispatch) => {
  try {
    const { result } = await api.getResendVerfifikasiBank(query);
    dispatch(showAlert({ message: result?.message, show: true }));
    dispatch(setFormResult(result));
  } catch (err) {
    console.error(err);
  }
};

export const fetchResendVerifikasiNpwp = (query) => async (dispatch) => {
  try {
    const { result } = await api.getResendVerfifikasiNpwp(query);
    dispatch(showAlert({ message: result?.message, show: true }));
    dispatch(setFormResult(result));
  } catch (err) {
    console.error(err);
  }
};

export const fetchAccountProfile =
  (query, reset = false) =>
  async (dispatch) => {
    try {
      if (reset) dispatch(getAccountProfile(null));
      const { result } = await api.getAccountProfile(query);
      dispatch(getAccountProfile(result));
    } catch (err) {
      console.error(err);
    }
  };

export const fetchCheckMemberBonusStatus = (query) => async (dispatch) => {
  try {
    const { result } = await api.checkMemberBonusStatus(query);
    dispatch(checkMemberBonusStatus(result));
  } catch (err) {
    console.error(err);
  }
};

export const fetchListBank = (query) => async (dispatch) => {
  try {
    const { result } = await api.getListBank(query);
    dispatch(getListBank(result));
  } catch (err) {
    console.error(err);
  }
};

export const fetchAccountAddress = () => async (dispatch) => {
  try {
    const { result } = await api.getAccountAddress();
    dispatch(getAccountAddress(result));
  } catch (err) {
    console.error(err);
  }
};

export const updateAccountProfile = (id, data) => async (dispatch) => {
  try {
    const result = await api.putAccountProfile(id, data);

    dispatch(showAlert({ message: result?.message, show: true }));
    dispatch(setFormResult(result));
  } catch (err) {
    console.error(err);
  }
};

export const updateAccountPassword = (data) => async (dispatch) => {
  try {
    const result = await api.putAccountPassword(data);
    dispatch(showAlert({ message: result?.message, show: true }));
    dispatch(setFormResult(result));
  } catch (err) {
    console.error(err);
  }
};

export const updateAccountRekening = (id, data) => async (dispatch) => {
  try {
    const result = await api.putAccountRekening(id, data);
    dispatch(showAlert({ message: result?.message, show: true }));
    dispatch(setFormResult(result));
    console.log("resultxx", result);
  } catch (err) {
    console.error(err);
  }
};

export const updateAccountWithdraw = (id, data) => async (dispatch) => {
  try {
    const result = await api.putAccountWithdraw(id, data);
    dispatch(showAlert({ message: result?.message, show: true }));
    dispatch(setFormResult(result));
    console.log("resultxx", result);
  } catch (err) {
    console.error(err);
  }
};

export const updateInformasiPewaris = (id, data) => async (dispatch) => {
  try {
    const result = await api.putInformasiPewaris(id, data);
    dispatch(showAlert({ message: result?.message, show: true }));
    dispatch(setFormResult(result));
  } catch (err) {
    console.error(err);
  }
};

export const updateAccountNpwp = (id, data) => async (dispatch) => {
  try {
    const result = await api.putAccountNpwp(id, data);
    dispatch(showAlert({ message: result?.message, show: true }));
    dispatch(setFormResult(result));
    console.log("resultxx", result);
  } catch (err) {
    console.error(err);
  }
};

export const createAccountAddress = (data) => async (dispatch) => {
  try {
    const result = await api.postAccountAddress(data);
    dispatch(showAlert({ message: result?.message, show: true }));
    dispatch(fetchAccountAddress());
    dispatch(setFormResult(result));
  } catch (err) {
    console.error(err);
  }
};

export const updateAccountAddress = (id, data) => async (dispatch) => {
  try {
    const result = await api.putAccountAddress(id, data);
    dispatch(showAlert({ message: result?.message, show: true }));
    dispatch(fetchAccountAddress());
    dispatch(setFormResult(result));
  } catch (err) {
    console.error(err);
  }
};

export const deleteAccountAddress = (id) => async (dispatch) => {
  try {
    const result = await api.deleteAccountAddress(id);
    dispatch(showAlert({ message: result?.message, show: true }));
    dispatch(fetchAccountAddress());
    dispatch(setFormResult(result));
  } catch (err) {
    console.error(err);
  }
};

export const updateKodeEtik = (id, isChecked) => async (dispatch) => {
  try {
    const result = await api.putKodeEtik(id, isChecked);
    dispatch(showAlert({ message: result?.message, show: true }));
    dispatch(setFormResult(result));
  } catch (err) {
    console.error(err);
  }
};
