import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import {
  createResiRetur, fetchReturAble, fetchTracking,
} from "../../../../redux/actions/transaction";
import { Controller, useForm } from "react-hook-form";
import MultiSelect from "../../../../components/MultiSelect";
import TextInput from "../../../../components/TextInput";
import { fetchAccountAddress } from "../../../../redux/actions/account";
import { formatDate } from "../../../../constants/utility";
import ModalAction from "../../../../components/ModalAction";

const TransaksiDetail = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    setError,
    clearErrors
  } = useForm();
  const { formData, setFormOpen, formOpen } = props;
  const dispatch = useDispatch();
  const { returAbles, track } = useSelector((state) => state.transaction);
  const { address } = useSelector((state) => state.account);
  // const [query, setQuery] = useState("");
  // const [searchQuery, setSearchQuery] = useState("");
  // const [confirmModal, setConfirmModal] = useState(false);

  useEffect(() => {
    console.log('xyy', 'fetch returable')
    dispatch(fetchReturAble());
    dispatch(fetchAccountAddress());
  }, []);

  const [listReturable, setListReturable] = useState([]);
  useEffect(() => {
    setListReturable(returAbles?.map(a => {
      return {
        value: a.public_id,
        label: a.no_invoice
      }
    }, []));
  }, [returAbles])

  // useEffect(()=>{
  //   console.log('xyy', 'errors', errors);
  // },[errors])


  const orderInput = watch('no_invoice');
  const savedAddressInput = watch('saved_address');
  const detailsInput = watch('details');
  const [selectedOrder, setSelectedOrder] = useState(null);
  useEffect(() => {
    // console.log('xyy', orderInput);
    const selected = returAbles?.find(a => a.public_id === orderInput?.value);
    setSelectedOrder(selected);
    if (selected) {
      setValue(
        'details',
        selected?.order_details?.map(a => {
          return {
            public_id: a.public_id,
            id_barang: a.barang.public_id,
            nama: a.barang.nama,
            qty: a.qty,
            qty_retur: a.qty
          }
        }, [])
      )
    }
    // console.log('xyy', orderInput?.value, listReturable)
  }, [orderInput, setSelectedOrder, returAbles, setValue]);

  const [unSelectedOrder, setUnselectedOrder] = useState([]);
  useEffect(() => {
    console.log('xyy unSelectedOrder', unSelectedOrder);
  }, [unSelectedOrder])


  const onRemoveItemRetur = (public_id) => {
    const unSelecteds = detailsInput.filter(a => a.public_id === public_id, []);
    setUnselectedOrder([...unSelectedOrder, ...unSelecteds]);

    const details = detailsInput.filter(a => a.public_id !== public_id, []);
    setValue('details', details);
  }

  const onAddItemRetur = (item) => {
    setUnselectedOrder(unSelectedOrder.filter(a => a.public_id !== item.public_id, []));
    setValue('details', [...detailsInput, item]);
  }

  // const [files, setFiles] = useState([{file: null}]);
  const [selectedAddress, setSelectedAddress] = useState();
  const [sameAddress, setSameAddress] = useState(true);

  useEffect(() => {
    if (sameAddress) {
      setSelectedAddress({
        nama_kirim: selectedOrder?.nama_kirim,
        phone_kirim: selectedOrder?.phone_kirim,
        alamat_kirim: selectedOrder?.alamat_kirim,
        kode_pos_kirim: selectedOrder?.kode_pos_kirim,
        kecamatan_kirim: selectedOrder?.kecamatan_kirim?.nama,
        kota_kirim: selectedOrder?.kota_kirim?.nama,
        propinsi_kirim: selectedOrder?.propinsi_kirim?.nama

      })
    } else {
      setSelectedAddress(null);
    }
  }, [sameAddress, selectedOrder]);

  useEffect(() => {
    // console.log('xyy ss', savedAddressInput)
    if (savedAddressInput && !sameAddress) {
      setSelectedAddress({
        public_id: savedAddressInput?.public_id,
        nama_kirim: savedAddressInput?.nama,
        phone_kirim: savedAddressInput?.phone,
        alamat_kirim: savedAddressInput?.alamat,
        kode_pos_kirim: savedAddressInput?.kode_pos,
        kecamatan_kirim: savedAddressInput?.kecamatan?.nama,
        kota_kirim: savedAddressInput?.kota?.nama,
        propinsi_kirim: savedAddressInput?.propinsi?.nama

      })
    }
  }, [savedAddressInput, setSelectedAddress, sameAddress])

  useEffect(() => {
    setValue('files', [null])
  }, [setValue]);

  const inputFiles = watch('files');
  const inputImg = watch('img');
  useEffect(() => {
    // console.log('xxy img', inputImg?.[0]);
    if (inputImg?.length > 0) {
      // let formData = new FormData();
      // for()
      // formData.append('img', watch('img'))
      // dispatch(createRetur(formData));
    }
  }, [inputImg])


  const onSelectFile = (e, index) => {
    clearErrors();
    if (e.target.files[0] === undefined) return;
    // console.log('xxy',e.target.files[0])
    if (e.target.files[0].type === "video/mp4" && e.target.files[0].size > 5000000) { // Max 5MB
      setError('file_count', { type: 'focus', message: 'File size video maksimal 5MB' }, { shouldFocus: true });
      return;
    }

    if (e.target.files[0].type.includes("image") && e.target.files[0].size > 1000000) { // Max 1MB
      setError('file_count', { type: 'focus', message: 'File size gambar maksimal 500KB' }, { shouldFocus: true });
      return;
    }

    const files = [...inputFiles];
    files[index] = e.target.files[0]
    if (files.length < 3) files.push(null);
    setValue('files', files);
  }

  const onRemoveFile = (index) => {
    // setValue('files', inputFiles.splice(index, 1));
    let tempData = watch("files");
    tempData.splice(index, 1);
    setValue("files", tempData);
    const nullExist = inputFiles.findIndex(a => a === null);
    if (inputFiles.length < 3 && nullExist < 0) {
      setValue('files', [...inputFiles, null])
    }
    // console.log('xyy', index, inputFiles);
  }


  // useEffect(() => {
  //   const timeOutId = setTimeout(() => setSearchQuery(query), 500);
  //   return () => clearTimeout(timeOutId);
  // }, [query]);

  // useEffect(() => {
  //   dispatch(fetchDownline({ search: searchQuery }));
  // }, [searchQuery, dispatch]);

  const handleSubmitForm = (data) => {

    const datax = {
      ...data, details: data.details.map(a => {
        return {
          detail_id: a.public_id,
          qty: a.qty_retur
        }
      }, [])
    }
    // console.log('xyy', datax);
    clearErrors();
    const formData = new FormData();
    const files = data.files.filter(a => a !== null, []);
    if (files.length < 3) {
      setError('file_count', { type: 'focus', message: 'Anda harus upload 3 gambar/video' }, { shouldFocus: true });
      return;
    }
    files.forEach(a => {
      formData.append('files', a);
    })

    formData.append("no_invoice", datax.no_invoice.value);
    formData.append("deskripsi", datax.deskripsi);
    formData.append("same_address", datax.same_address);
    if (!datax.same_address) formData.append("saved_address", datax.saved_address?.public_id);
    for (var i = 0; i < datax.details.length; i++) {
      const detail = datax.details[i];
      formData.append(`details[]`, JSON.stringify(detail));
    }
    props.onSubmit(formData);
  }

  useEffect(() => {
    console.log('xyy selectedOrder', selectedOrder)
  }, [selectedOrder])

  const handleSubmitResi = (data) => {
    const payload = {
      public_id: formData?.public_id,
      ekspedisi: `${data?.ekspedisi?.value}`,
      resi: data?.resi,
    }
    // console.log("xna",payload);
    dispatch(createResiRetur(payload));
  }

  const [modalOption, setModalOption] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const modalTrack = (data) => {
    setModalShow(true);
    setModalOption({
      title: "Tracking Order",
      content: (
        <>
          <div className="pr-3">
            <ul className="timeline">
              <li>
                <h3>
                  <Skeleton />
                </h3>

                <p>
                  <Skeleton count={5} />
                </p>
              </li>
            </ul>
          </div>
          <div className="px-3 py-3 border-top">
            <div className="flex">
              {/* <button
                className="btn mr-2 btn-outline-secondary btn-sm"
                onClick={() => setRowDetail(null)}
              >
                Tutup
              </button> */}
            </div>
          </div>
        </>
      ),
      color: "info",
      size: "lg",
      data: data,
    });
    // props.setActionChild(false);
  };

  useEffect(() => {
    if (track) {
      setModalOption({
        title: "Tracking Order",
        content: (
          <div className="pr-3">
            {track ? (
              <ul className="timeline">
                {track?.rajaongkir?.result?.manifest?.map((item, index) => {
                  return (
                    <li key={index}>
                      <a href="#" target="_blank">{item?.city_name}</a>
                      <p className="float-right">
                        {item?.manifest_date} | {item?.manifest_time}
                      </p>
                      <p>{item?.manifest_description}</p>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <></>
            )}
          </div>
        ),
        color: "info",
        size: "lg",
      });
    }
  }, [track]);

  const lacak = () => {
    modalTrack();
    setFormOpen(false);
    dispatch(fetchTracking(
      {
        public_id: formData?.public_id,
        waybill: formData?.no_resi,
        courier: formData?.ekspedisi.split("|")[0],
      }
    ))
  }

  return (
    <>
      <Modal
        show={formOpen}
        size={'lg'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setFormOpen(false)}
      >
        <Modal.Header style={{ background: '#52567a' }}>
          <Modal.Title style={{ color: 'white' }} id="contained-modal-title-vcenter text-sm"><strong>{!formData ? 'Buat' : ''} Retur Barang</strong></Modal.Title>
          <span className="btn" onClick={() => setFormOpen(false)}>
            &times;
          </span>
        </Modal.Header>
        <Modal.Body>
          {formData ? (
            <div>
              <div className="d-flex">
                <div className="w-50">
                  <div>No Retur : <span className="font-weight-bold">{formData?.no_retur}</span></div>
                  <div>No Invoice : <span className="font-weight-bold">{formData?.order?.no_invoice}</span></div>
                </div>
                <div className="w-50">
                  <div>Tanggal : <span className="font-weight-bold">{formatDate(formData?.tanggal, false, false, true)}</span></div>
                  <div>Status : <span className="font-weight-bold">{formData?.status}</span></div>
                </div>
              </div>
              {formData?.status === 'PENDING' && (<div className="alert alert-info mt-1 mb-1">Permintaan retur Anda sedang diperiksa untuk di APPROVED</div>)}
              {formData?.status === 'APPROVED' && (
                <>
                  <div className="alert alert-info mt-1 mb-1">Permintaan retur Anda telah di setujui, silakan kirim paket ke kantor kami di :
                    <div className="px-2">
                      <table style={{ fontSize: "0.9rem" }}>
                        <tr style={{ verticalAlign: "top" }}>
                          <td>Penerima</td>
                          <td className="px-2">:</td>
                          <td>The Favored One (Bag. Gudang)</td>
                        </tr>
                        <tr style={{ verticalAlign: "top" }}>
                          <td>Alamat</td>
                          <td className="px-2">:</td>
                          <td>Jalan Rungkut Asri XII / 18B, Surabaya, Jawa Timur 60293</td>
                        </tr>
                        <tr style={{ verticalAlign: "top" }}>
                          <td>Keterangan</td>
                          <td className="px-2">:</td>
                          <td>Retur FV01-2210-RJ-000001</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="row border rounded">
                      <div className="col-md-5 col-sm-12 p-2">
                        <div className="font-weight-bold">Input Resi</div>
                        Jika Anda mengirimkan paket melalui jasa ekspedisi, masukkan nomor resi pengiriman agar memudahkan proses pelacakan pengiriman</div>
                      <div className="col-md-7 col-sm-12 p-2">
                        <form onSubmit={handleSubmit(handleSubmitResi)}>
                          <div className="">
                            <div>
                              <Controller
                                name={`ekspedisi`}
                                control={control}
                                validations={{ required: "Pilih Ekspedisi" }}
                                render={({ field }) => (
                                  <MultiSelect
                                    layout="horizontal"
                                    name={`ekspedisi`}
                                    label={`Ekspedisi`}
                                    placeholder={`Pilih Ekspedisi`}
                                    register={register}
                                    errors={errors}
                                    isClearable={true}
                                    field={field}
                                    options={[
                                      { value: "pos", label: "POS" },
                                      { value: "jne", label: "JNE" },
                                      { value: "jnt", label: "J&T" },
                                      { value: "ninja", label: "Ninja" },
                                      { value: "sicepat", label: "Sicepat" },
                                      { value: "anteraja", label: "Anteraja" },
                                    ]}
                                    isMulti={false}
                                    validations={{ required: "Pilih Ekspedisi" }}
                                  />
                                )}
                              />
                            </div>
                            <TextInput
                              layout="horizontal"
                              type="text"
                              label="Nomor Resi"
                              name={`resi`}
                              placeholder="Nomor Resi"
                              register={register}
                              errors={errors}
                              validations={{ required: "Masukkan Nomor Resi" }}
                            />
                            <input type="submit" style={{ height: "40px" }} className="btn btn-primary-f btn-md" value={"Kirim"} />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {formData?.status === 'DELIVERY' && (<div className="alert alert-info mt-1 mb-1">Paket yang Anda retur sedang dikirim menuju kantor kami. <button onClick={() => lacak()} className="btn btn-info btn-sm">Lacak</button></div>)}
              {formData?.status === 'RECEIVED' && (<div className="alert alert-info mt-1 mb-1">Paket yang Anda retur telah kami terima, kami akan mengirimkan ulang sesuai alamat yang telah dipilih</div>)}
              {formData?.status === 'DONE' && (<div className="alert alert-info mt-1 mb-1">Retur Anda telah selesai. Barang Anda kami ganti dan telah buatkan invoice baru <a className="text-nowrap" href={`/transaksi/order-transaksi/detail/${formData?.kirim_ulang?.public_id}`}>{formData?.kirim_ulang?.no_invoice}</a></div>)}
              {formData?.status === 'REJECTED' && (<div className="alert alert-info mt-1 mb-1">Permintaan retur Anda ditolak dengan alasan : <br /> <em><q>{formData?.alasan_ditolak}</q></em></div>)}
              <div className="mt-2">
                <table className="table">
                  <thead>
                    <th>Barang</th>
                    <th>Qty</th>
                  </thead>
                  <tbody>
                    {formData?.retur_jual_details?.map((row, i) => {
                      return (
                        <tr key={i} className="">
                          <td>{row.barang?.nama}</td>
                          <td>{row?.qty}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <hr className="mt-4" />
                <div className="row">
                  <div className="col-md-6">
                    <div className="">
                      <span className="font-weight-bold">Keterangan/Alasan :</span> <br />
                      {formData.deskripsi}
                    </div>
                    <div className="mt-2 mb-2">
                      <span className="font-weight-bold">Bukti Foto/Video : </span>
                      <div className="d-flex">
                        {formData?.retur_jual_files?.map((a, index) => {
                          return (
                            <a href={a.file} target="_blank" rel="noopener noreferrer">
                              <div className="m-1" style={{ width: "100px", height: "100px" }}>
                                {a.type === 'image' ?
                                  <img style={{ width: "100%", height: "100%" }} alt={`file-${index}`} src={a.file} />
                                  :
                                  <div className="p-2 border w-100 h-100"><i className="fa fa-video"></i></div>
                                  // <img style={{width:"100%", height:"100%"}} alt={`file-${index}`} src={a.file} />
                                }
                              </div>
                            </a>
                          )
                        })}
                      </div>
                    </div>
                    <div>
                      <span className="font-weight-bold">Alamat Pengiriman Ulang :</span><br />
                      <p>
                        {formData?.nama_kirim} - ({formData?.phone_kirim})<br />
                        {formData?.alamat_kirim}<br />
                        {formData?.kecamatan?.nama}, {formData?.kota?.nama}, {formData?.propinsi?.nama}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="font-weight-bold">Riwayat Retur</label>
                    <div className="list-group">
                      <div className="list-group-item list-group-item-action flex-column align-items-start">
                        <p className="mb-0">
                          {formatDate(formData.tanggal, false, false, true)} PENDING
                        </p>
                        <small>oleh : Pembeli</small>
                      </div>
                      {formData?.retur_jual_histories?.map((item) => {
                        return (
                          <>
                            <div className="list-group-item list-group-item-action flex-column align-items-start">
                              <p className="mb-0">
                                {formatDate(item.tanggal, false, false, true)}{" "}
                                {item?.status}{" "}
                              </p>
                              <small>oleh : {item?.status === 'DELIVERY' ? 'Member' : item.admin?.nama}</small>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit(handleSubmitForm)}>
              <div style={{ height: "calc(100vh - 220px)", overflowY: "auto", overflowX: "hidden" }} className="custom-scrollbar p-1">
                <div className="d-flex">
                  <div className="" style={{ width: "50%" }}>
                    <Controller
                      name={`no_invoice`}
                      control={control}
                      validations={{ required: "Pilih Invoice" }}
                      render={({ field }) => (
                        <MultiSelect
                          layout="horizontal"
                          name={`no_invoice`}
                          label={`Pilih Invoice`}
                          placeholder={`No Invoice`}
                          register={register}
                          errors={errors}
                          field={field}
                          options={listReturable || []}
                          isMulti={false}
                          validations={{ required: "Pilih order" }}
                        />
                      )}
                    />
                  </div>
                </div>
                {selectedOrder &&
                  (
                    <div>
                      <div className="d-flex justify-content-between align-items-center py-2">
                        <div className="font-weight-bold">List Item</div> {"  "}
                        {unSelectedOrder?.length > 0 && (
                          <div className="btn-group">
                            <button type="button" className="btn btn-info btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              Add Item
                            </button>
                            <div className="dropdown-menu">
                              {unSelectedOrder?.map((a, i) => {
                                return (
                                  <div onClick={() => onAddItemRetur(a)} className="dropdown-item cursor-pointer" key={i}>{a.nama}</div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="d-flex border-bottom border-top">
                        <div className="p-1 flex-grow-1">Barang</div>
                        <div className="p-1 text-center" style={{ width: "70px" }}>Qty</div>
                        <div className="p-1" style={{ width: "80px" }}>Retur Qty</div>
                        <div className="p-1" style={{ width: "30px" }}></div>
                      </div>
                      <div className="">
                        {detailsInput?.length < 1 && (<div className="text-danger font-italic p-2 text-center">Tidak ada item diretur</div>)}
                        {detailsInput?.map((a, i) => {
                          return (
                            <div className="d-flex" style={{ background: `${i % 2 !== 0 ? '#eee' : 'none'}` }}>
                              <div className="px-1 py-2 flex-grow-1">{a.nama}</div>
                              <div className="p-1 py-2 text-right" style={{ width: "70px" }}>{a.qty}</div>
                              <div className="p-1" style={{ width: "80px" }}>
                                <TextInput
                                  layout="vertical"
                                  type="number"
                                  name={`details.${i}.qty_retur`}
                                  placeholder=""
                                  defaultValue=""
                                  register={register}
                                  errors={errors}
                                  validations={{ required: "Masukkan Qty", max: { value: a.qty, message: `Max ${a.qty}` } }}
                                />
                              </div>
                              <div style={{ width: "30px" }} className="p-1" onClick={() => onRemoveItemRetur(a.public_id)}>
                                <button type="button" className="btn btn-sm btn-danger" style={{ fontSize: "0.6rem" }}><i className="fa fa-trash"></i></button>
                              </div>
                              {/* {a.items.map((b, x) => {
                                return (
                                  <div className="d-flex">
                                    <div className="px-1 py-2" style={{width:"120px"}}>{b.nama}</div>
                                    <div className="px-1 py-2 text-center" style={{width:"70px"}}>{b.qty}</div>
                                    <div className="p-1 form-group-no-margin" style={{width:"80px"}}>
                                      <TextInput
                                        layout="vertical"
                                        type="number"
                                        name={`details.${i}.items.${x}.qty_retur`}
                                        placeholder=""
                                        defaultValue=""
                                        register={register}
                                        errors={errors}
                                        validations={{ required: "Masukkan label alamat" }}
                                      />
                                    </div>
                                    <div className="p-1" onClick={() => onRemoveItemRetur(b.public_id)}>
                                      <button type="button" className="btn btn-sm btn-danger" style={{fontSize:"0.6rem"}}><i className="fa fa-trash"></i></button>
                                    </div>
                                  </div>
                                )
                              })} */}
                              {/* </div> */}
                            </div>
                          )
                        })}

                      </div>
                      <div className="border-bottom mt-2"></div>
                      <div className="row mt-2">
                        {/* <input accept="image/*" type="file" name="img" {...register("img" )} multiple /> */}
                        <div className="col-md-5">
                          <TextInput
                            label={'Alasan'}
                            layout="vertical"
                            type="textarea"
                            name={`deskripsi`}
                            placeholder="Alasan Retur"
                            register={register}
                            errors={errors}
                            validations={{ required: "Alasan harus diisi" }}
                          />
                        </div>
                        <div className="col-md-7">
                          <label className="font-weight-bold">Bukti Foto/Video</label>
                          <div className="d-flex">
                            {watch('files')?.map((a, i) => {
                              return <InputFile
                                {...a}
                                index={i}
                                handleChange={(e) => onSelectFile(e, i)}
                                removeFile={() => onRemoveFile(i)}
                                file={a}
                              />
                            })}
                          </div>
                          {errors['file_count'] && (<div className="text-danger font-italic font-weight-light ml-2" style={{ fontSize: "0.8rem" }}>{errors['file_count'].message}</div>)}
                        </div>
                      </div>
                      <div className="border-bottom mt-4"></div>
                      <div className="row mt-4">

                        <div className="col-md-12">
                          <label className="user-select-none p-2">
                            <input
                              name="same_address"
                              {...register("same_address",)}
                              type={"checkbox"}
                              className="mr-2"
                              checked={sameAddress}
                              onClick={() => setSameAddress(!sameAddress)}
                            />
                            Alamat kirim sama dengan Alamat Order
                          </label>
                        </div>
                        {!sameAddress && (
                          <div className="col-md-6">
                            <Controller
                              name={`saved_address`}
                              control={control}
                              validations={{ required: "Pilih Alamat" }}
                              render={({ field }) => (
                                <MultiSelect
                                  layout="vertical"
                                  name={`saved_address`}
                                  // label={`Order`}
                                  placeholder={`Pilih Alamat`}
                                  register={register}
                                  errors={errors}
                                  field={field}
                                  options={address?.rows?.map(a => {
                                    return {
                                      ...a,
                                      value: a.public_id,
                                      label: `${a.label} - ${a.nama}`
                                    }
                                  }, []) || []}
                                  isMulti={false}
                                  validations={{ required: "Pilih Alamat" }}
                                />
                              )}
                            />
                          </div>
                        )}
                      </div>
                      {selectedAddress && (
                        <div className="border p-2 rounded">
                          <label>Penerima : {selectedAddress?.nama_kirim} - {selectedAddress?.phone_kirim}</label>
                          <div>
                            Alamat : {selectedAddress?.alamat_kirim} - {selectedAddress?.kode_pos_kirim}. {" "}
                            {selectedAddress?.kecamatan_kirim} {",  "}
                            {selectedAddress?.kota_kirim} {",  "}
                            {selectedAddress?.propinsi_kirim}
                          </div>
                        </div>
                      )}
                    </div>
                  )
                }
                <div style={{ height: "100px" }}></div>
              </div>

              <div style={{
                marginLeft: "-1rem",
                marginRight: "-1rem",
                marginBottom: "-1rem"
              }}>
                <Modal.Footer>
                  <input
                    disabled={detailsInput?.length === 0 || Object.keys(errors).length > 0 || !selectedOrder}
                    type="submit"
                    className="btn btn-primary-f btn-md" value={"Retur"} />
                </Modal.Footer>
              </div>

            </form>
          )}
        </Modal.Body>
      </Modal>
      <ModalAction
        show={modalShow}
        modalOption={modalOption}
        onHide={() => {
          setModalShow(false);
          setFormOpen(true);
        }}
      />
    </>
  );
};

const InputFile = ({ handleChange, removeFile, file, index, error }) => {
  const inputRef = useRef(null);
  const onUploadBtnPres = () => {
    inputRef.current.click();
  }

  // console.log('xxy', file)
  return (
    <div key={index} className="d-flex">
      <div style={{ width: "90px", height: "90px", borderRadius: "5px", padding: "5px", position: "relative" }} className="border mb-1">
        {file !== null && file?.type !== 'video/mp4' && (<img style={{ width: "100%", height: "100%" }} alt={`file-${index}`} src={URL.createObjectURL(file)} />)}
        {file !== null && file?.type === 'video/mp4' && (<a href={URL.createObjectURL(file)}><i className="fa fa-play text-primary"></i><small>Video uploaded</small></a>)}
        {file !== null && (
          <div style={{ position: "absolute", bottom: 0, left: 0, right: 0, background: "rgba(0,0,0,.5)" }}>
            <span className="btn btn-danger btn-sm float-right m-1" style={{ fontSize: "0.7rem" }} onClick={() => removeFile()}><i className="fa fa-times"></i></span>
          </div>)
        }

      </div>
      <input
        hidden
        type="file"
        name={`files.${index}`}
        accept="video/*,image/*"
        ref={inputRef} onChange={handleChange} />

      {file === null && (
        <button
          type="button"
          onClick={() => onUploadBtnPres()}
          style={{ height: "30px" }}
          className="btn btn-sm btn-info ml-1">
          <i className="fa fa-camera"></i> &nbsp;Upload
        </button>
      )}
    </div>
  )
}

export default TransaksiDetail;