import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../constants/utility";

const initialState = {
  profile: null,
  isBonusAvl: null,
  address: null,
  listBank: null,
  formResult: null,
};

const getAccountProfile = (state, data) => {
  return updateObject(state, {
    profile: data,
  });
};

const checkMemberStatusBonus = (state, data) => {
  return updateObject(state, {
    isBonusAvl: data,
  });
};

const getListBank = (state, data) => {
  return updateObject(state, {
    listBank: data,
  });
};

const getAccountAddress = (state, data) => {
  return updateObject(state, {
    address: data,
  });
};

const formResult = (state, data) => {
  return updateObject(state, {
    formResult: data,
  });
};

const reducer = (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
    case actionTypes.GET_ACCOUNT_PROFILE:
      return getAccountProfile(state, data);
    case actionTypes.CHECK_MEMBER_BONUS_STATUS:
      return checkMemberStatusBonus(state, data);
    case actionTypes.GET_LIST_BANK:
      return getListBank(state, data);
    case actionTypes.GET_ACCOUNT_ADDRESS:
      return getAccountAddress(state, data);
    case actionTypes.ACCOUNT_FORM_RESULT:
      return formResult(state, data);
    default:
      return state;
  }
};

export default reducer;
