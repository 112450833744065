import React from "react";
import BinaryTreeNode from "./BinaryTreeNode";
import "./tree.css";

const BinaryTree = ({
  rootUser,
  allUsers,
  setModal,
  setUplineQuery,
  searchFunction,
  setUpline,
  setNamaUpline,
  setSisiRegister,
  level,
}) => {
  return (
    <div className="genealogy-tree">
      <ul>
        <BinaryTreeNode
          allUsers={allUsers}
          user={rootUser}
          setModal={setModal}
          searchFunction={searchFunction}
          setUpline={setUpline}
          setNamaUpline={setNamaUpline}
          setSisiRegister={setSisiRegister}
          maxDeep={level}
        />
      </ul>
    </div>
  );
};

export default BinaryTree;
