import React from "react";

const Searchbar = (props) => {
  const { placeholder = "Pencarian", onChange, onEnter } = props;
  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      onEnter(e.target.value);
    }
  };

  return (
    <div className="form-group has-search">
      <span
        className="fas fa-search fa-sm"
        style={{
          position: "absolute",
          zIndex: "2",
          display: "block",
          width: "2.375rem",
          height: "2.375rem",
          lineHeight: "2.375rem",
          textAlign: "center",
          pointerEvents: "none",
          color: "#aaa",
        }}
      ></span>
      <input
        type="text"
        className="form-control"
        placeholder={placeholder}
        maxlength={props.maxlength || ""}
        pattern={props.pattern || ""}
        onKeyDown={onEnter ? onKeyDown : null}
        onChange={onChange ? (e) => onChange(e.target.value) : null}
        style={{ paddingLeft: "2rem" }}
      />
    </div>
  );
};

export default Searchbar;
