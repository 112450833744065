import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardBasic from '../../../components/Cards/Basic';
import { formatDateOnly } from '../../../constants/utility';
import PageHeading from '../../../components/PageHeading';
import PrintingHeader from '../../../components/PrintingHeader';
import * as actions from '../../../redux/actions';
import {
  fetchPromoDetail,
  setPromoDetail,
  fetchPromoOpt,
} from '../../../redux/actions/report';
import Select from 'react-select';
import Resume from './resume';

const Promo = (props) => {
  const { name, title, parent } = props.data;
  const { formResult, promo, promoOpt } = useSelector((state) => state.report);
  const dispatch = useDispatch();
  const [promoSelect, setPromoSelect] = useState(null);

  useEffect(() => {
    if (promoSelect) dispatch(fetchPromoDetail(promoSelect.value));
  }, [promoSelect]);

  useEffect(() => {
    dispatch(
      actions.setMenuActive({ menuActive: parent || name, subMenuActive: name })
    );
    dispatch(fetchPromoOpt());
  }, [dispatch, name, parent]);


  useEffect(() => {
    dispatch(setPromoDetail(null));
  }, [promoSelect, dispatch]);


  return (
    <div className="">
      <PageHeading title={title} />
      <CardBasic nopadding>
        <div className="m-2" style={{ minHeight: '600px' }}>
          <div className="d-flex p-2">
            <div className="m-1" style={{ minWidth: '200px' }}>
              <Select
                placeholder={'Pilih PROMO'}
                isMulti={false}
                name={'posisimember'}
                id={'posisimember'}
                onChange={(val) => setPromoSelect(val)}
                options={promoOpt?.map(
                  ({ public_id, nama }) => ({
                    value: public_id,
                    label: nama,
                  }),
                  []
                )}
              />
            </div>
          </div>
          <Resume promo={promo} />
        </div>
      </CardBasic>
    </div>
  );
};

export default Promo;
