import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CardBasic from "../../../../components/Cards/Basic";
import PageHeading from "../../../../components/PageHeading";

import * as actions from "../../../../redux/actions";

import {
  getInvoice,
  setFormOrder,
  setFormResult,
} from "../../../../redux/actions/transaction";
import "./createTransaksi.css";
import "react-loading-skeleton/dist/skeleton.css";
import FormMember from "./component/FormMember";
import FormItem from "./component/FormItem";
import FormDelivery from "./component/FormDelivery";
import FormPayment from "./component/FormPayment";

toast.configure();

const CreateTransaksi = (props) => {
  const { name, title, parent } = props.data;
  const { jenis } = useParams();
  const [pageTitle, setPageTitle] = useState("");

  useEffect(() => {
    if (jenis !== 'registrasi' && jenis !== 'upgrade') {
      navigate('/transaksi/order-transaksi')
    } else {
      setPageTitle(`${title} - ${jenis?.replace("-", " ")?.toUpperCase()}`);
    }
  }, [jenis, setPageTitle, title]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      actions.setMenuActive({
        menuActive: parent || name,
        subMenuActive: "order-transaksi",
      })
    );
    dispatch(getInvoice(null));
  }, [dispatch, name, parent]);

  useEffect(() => {
    dispatch(setFormResult(null));
  }, [dispatch]);

  const { formOrder } = useSelector((state) => state.transaction);
  const { data: userData } = useSelector((state) => state.auth);
  if (userData?.posisi === "PLATINUM" && formOrder?.jenis === "upgrade")
    navigate("/transaksi/order-transaksi", { replace: true });

  const onNaigateIndicator = (step, index) => {
    if (index === formOrder?.activeIndex) return;
    const last = formOrder?.steps?.[index - 1];
    if (index > formOrder?.activeIndex && last.passed === false) return;
    console.log("xyy", step, index);

    const toStep = formOrder?.steps?.find((a, i) => i === index);
    dispatch(
      setFormOrder({
        ...formOrder,
        activeStep: toStep?.name,
        activeIndex: index,
      })
    );
  };

  const onNavigateBack = () => {
    if (formOrder.activeIndex === 0) return;
    const tmp = {
      ...formOrder,
      activeIndex: formOrder.activeIndex - 1,
      activeStep: formOrder?.steps?.[formOrder.activeIndex - 1]?.name,
    };
    dispatch(setFormOrder(tmp));
  };

  return (
    <div className="mb-2">
      <PageHeading goBack={() => navigate(-1)} title={pageTitle} />
      <div className="alert alert-info" role="alert">
        Pastikan Anda sudah memasukkan atau mengedit nomor NPWP Anda dengan
        benar setelah selesai proses registrasi ini, untuk menghindari tambahan
        pajak saat menerima Bonus.
      </div>
      <CardBasic>
        <div>
          <div className="stepper-heading d-flex justify-content-between">
            {formOrder?.steps?.map((step, index) => {
              const { name, label, icon } = step;
              return (
                <div
                  onClick={() => onNaigateIndicator(step, index)}
                  className={`step-indicator ${(formOrder?.activeIndex >= index || step.passed === true) &&
                    "step-indicator-active"
                    }`}
                  key={name}
                >
                  <div className="step-indicator-icon">
                    <i className={`${icon}`}></i>
                  </div>
                  <div className="step-label">{label}</div>
                  {index > 0 && <div className="step-indicator-line"></div>}
                </div>
              );
            })}
          </div>
          <div className="stepper-content">
            {formOrder.activeStep === "member" && <FormMember />}
            {formOrder.activeStep === "item" && (
              <FormItem onNavigateBack={onNavigateBack} />
            )}
            {formOrder.activeStep === "delivery" && (
              <FormDelivery onNavigateBack={onNavigateBack} />
            )}
            {formOrder.activeStep === "payment" && (
              <FormPayment onNavigateBack={onNavigateBack} />
            )}
          </div>
        </div>
      </CardBasic>
    </div>
  );
};

export default CreateTransaksi;
