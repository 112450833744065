import React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

const DatePicker = ({
  value,
  endDate,
  range,
  onChange,
  monthPicker = false,
  yearPicker = false,
}) => {
  const formatLabel = () => {
    const formatd = 'dd/MM/yyyy';
    const start = format(value || Date.now(), formatd);
    if (range) {
      const end = format(endDate || Date.now(), formatd);
      return `${start} - ${end}`;
    }
    return start;
  };

  return (
    <div className={`relative ${range ? 'w-60' : 'w-40'}`}>
      <ReactDatePicker
        showMonthYearPicker={monthPicker}
        showYearPicker={yearPicker}
        show
        selected={value}
        onChange={(date) => {
          if (!onChange) return;

          // Ranged value
          if (range) {
            const [start, end] = date;
            console.log('xna', start, end);
            return onChange({ start, end });
          }

          onChange(date);
        }}
        selectsStart
        startDate={value}
        endDate={endDate}
        selectsRange={range}
        dateFormat={
          yearPicker ? 'yyyy' : monthPicker ? 'MMM yyyy' : 'dd/MM/yyyy'
        }
        customInput={
          monthPicker || yearPicker ?
            <input type="text" className="form-control" value={formatLabel()} />
            :
            <div class="col-auto">
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text bg-transparent" style={{ borderRight: "none" }}>
                    <i class="fa fa-regular fa-calendar"></i>
                  </div>
                </div>
                <input type="text" className="form-control date" style={{ cursor: "pointer", padding: "10px" }} value={formatLabel()} />
              </div>
            </div>
        }
      />
    </div>
  );
};

export default DatePicker;
