import React, { useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { FaAngleDown, FaClock } from "react-icons/fa";
import { CardBody, Col, Container, Row } from "reactstrap";
import { formatDate } from "../../constants/utility";
import "./Collapsible.css";
import parse from "html-react-parser";

function Collapsible({data}) {
  const [isOpen, setIsOpen] = useState(false);
  const parentRef = useRef();
  const {title, content, created_at, updated_at} = data;

  return (
    <div
      className={"collapsible"}
      onClick={() => setIsOpen(!isOpen)}
      style={{ cursor: "pointer" }}
    >
      <Card className={"mb-3 p-0"}>
        <CardBody>
          <div className="d-flex">
            <div className="toggle mr-2">
              <FaAngleDown />
            </div>
            <div>
              <strong>{title?.toUpperCase()}</strong>
            </div>
          </div>
          <div
            className={"content-parent"}
            ref={parentRef}
            style={
              isOpen
                ? { height: parentRef.current.scrollHeight + "px" }
                : { height: "0px" }
            }
          >
            <hr></hr>
            <div className="mt-3 p-2">
                {parse(content)}
                <hr />
                <div className="badge badge-info p-2"><FaClock/> {formatDate(created_at)}</div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default Collapsible;
