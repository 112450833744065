import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../redux/actions";

export const Alert = () => {
  const dispatch = useDispatch();
  const { position, type, dismissable, show, message } = useSelector(
    (state) => state.alert
  );

  const hideAlert = () => {
    dispatch(actions.hideAlert());
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      // hideAlert();
      dispatch(actions.hideAlert());
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [show, dispatch]);

  return (
    <div className={`alert-${position} ${show ? "alert-show" : ""}`}>
      <div
        style={{
          minWidth: window.innerWidth < 768 ? '350px' : '400px',
          maxWidth: "80%"
        }}
        className={
          `text-${type}  alert fade shadow-lg alert-${type} ` +
          (dismissable ? `alert-dismissible ` : ``) +
          (show ? `show` : `hidden-alert`)
        }
        role="alert"
      >
        {message}
        {/* <button type="button" className="close" data-dismiss="alert" aria-label="Close"> */}
        <button
          type="button"
          className="close pl-4"
          aria-label="Close"
          onClick={() => hideAlert()}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  );
};
