import React, { useEffect, useState } from "react";
// import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Col, Popover, Row } from "reactstrap";
import {
  formatCurrency,
  formatDate2,
} from "../../../../../../constants/utility";
import { useDispatch, useSelector } from "react-redux";
import {
  createTransaction,
  fetchPaymentMethod,
  fetchSettings,
  setFormOrder,
} from "../../../../../../redux/actions/transaction";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Alert } from "reactstrap";

toast.configure();
const FormPayment = ({ onNavigateBack }) => {
  const dispatch = useDispatch();

  const { formOrder, paymentMethod, formResult, settings } = useSelector(
    (state) => state.transaction
  );
  const { profile } = useSelector((state) => state.account);
  const [member, setMember] = useState(null);
  const [carts, setCarts] = useState(null);
  const [delivery, setDelivery] = useState(null);
  const [payment, setPayment] = useState(null);

  useEffect(() => {
    setMember(formOrder?.steps?.find((a) => a.name === "member")?.data);
    setCarts(formOrder?.steps?.find((a) => a.name === "item")?.data);
    setDelivery(formOrder?.steps?.find((a) => a.name === "delivery")?.data);
    setPayment(formOrder?.steps?.find((a) => a.name === "payment")?.data);
  }, [formOrder]);

  useEffect(() => {
    dispatch(fetchPaymentMethod(null));
    dispatch(fetchSettings());
  }, [dispatch]);

  const [popoverOpen, setPopoverOpen] = useState(false);
  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const onChosePayment = (data) => {
    //(productDanKirim * (float_charge / 100) + fix_charge) * ppn
    const totalBarang = carts?.reduce((a, b) => a + b.qty * b.harga, 0);
    const ongkir = delivery?.ongkir;
    const totalBarangOngkir = totalBarang + (ongkir || 0);

    // kusus CC (2.9% + 2000), yang kena ppn cuma 2000 nya.
    const totalBiaya = Math.ceil(
      data?.name === "credit_card"
        ? (totalBarangOngkir + data?.fix_charge * (1 + data?.ppn / 100)) /
        (1 - data?.float_charge / 100) -
        totalBarangOngkir
        : (totalBarangOngkir * (data?.float_charge / 100) + data?.fix_charge) *
        (data?.ppn / 100 + 1)
    );
    // console.log('xxx', totalBiaya);

    const tmp = {
      ...formOrder,
      steps: formOrder?.steps?.map((a) => {
        if (a.name === "payment") {
          return {
            ...a,
            passed: true,
            data: {
              ...data,
              totalBiaya: totalBiaya,
            },
          };
        }
        return a;
      }, []),
    };
    console.log("xna totalan ", totalBiaya, tmp);
    dispatch(setFormOrder(tmp));
  };

  const notifyError = (content) =>
    toast.error(content, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  const navigate = useNavigate();
  const bayar = () => {
    if (!payment) return notifyError("Pilih pembayaran!");
    const data = {
      pickup_store: delivery?.pickup_store,
      id_gudang: delivery?.gudang?.public_id,
      id_kirim: delivery?.public_id,
      biaya_kirim: delivery?.ongkir,
      ekspedisi: `${delivery?.kodeKurir}|${delivery.kodeLayanan}`,
      subsidi_ongkir_id: delivery?.subsidiOngkir?.public_id,
      is_asuransi: delivery?.is_asuransi,
      details: carts,
      payment_method: payment?.name,
      join_member: formOrder?.jenis === "registrasi",
      upgrade_member: formOrder?.jenis === "upgrade",
      is_ro: formOrder?.jenis === "repeat-order"
    };

    if (formOrder?.jenis === "registrasi") {
      data["member"] = {
        nomor_identitas: member?.noIdentitas,
        nama: member?.nama,
        alamat: member?.alamat,
        id_kecamatan: member?.idKecamatan,
        id_kota: member?.idKota,
        id_propinsi: member?.idPropinsi,
        phone: member?.phoneNumber,
        email: member?.email,
        username: member?.username,
        kode_pos: member?.kodePos,
        posisi: member?.posisi,
        sisi: member?.sisi,
        jenis_kelamin: member?.jenisKelamin,
        agama: member?.agama,
        status_kawin: member?.statusKawin,
        negara: member?.negara,
        tgl_lahir: member?.tglLahir,
        id_kota_domisili: member?.idKotaDomisili,
        id_propinsi_domisili: member?.idPropinsiDomisili,
      };
    }
    // console.log("bayar", data);
    dispatch(createTransaction(data));
  };

  useEffect(() => {
    if (formResult?.success && formResult?.result?.public_id) {
      dispatch(setFormOrder(null));
      // dispatch(createPayment({no_order: formResult?.result?.no_order}));
      navigate(
        "/transaksi/order-transaksi/detail/" + formResult?.result?.public_id,
        { replace: true }
      );
    }
  }, [formResult, navigate, dispatch]);

  const [isChecked, setIsChecked] = useState(false);
  const handleOnChange = () => {
    console.log(isChecked);
    setIsChecked(!isChecked);
  };

  const asuransi_kirim =
    delivery?.is_asuransi || delivery?.myEkspedisi?.wajib_asuransi
      ? carts?.reduce((a, b) => a + b.qty * b.harga, 0) *
      (delivery?.myEkspedisi?.asuransi_persen / 100) +
      delivery?.myEkspedisi?.asuransi_fix
      : 0;

  const use_ppn = settings?.find(({ key }) => key === "use_ppn");
  return (
    <>
      <h5 className="border-bottom py-2 mb-4" style={{ color: "#3d405c" }}>
        Pembayaran
      </h5>

      <div className="row">
        <div className="col-sm-12 col-md-5">
          {formOrder?.jenis === "registrasi" && (
            <>
              <Alert className="alert alert-warning">
                <i className="fa fa-info mr-3"> </i>
                <strong>Pastikan Data Calon Member Sudah Benar.</strong>
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  ×
                </button>
              </Alert>
              <div className="card mb-2">
                <div className="card-body">
                  <div>Data Sponsor</div>
                  <div className="d-flex">
                    <div className="px-1" style={{ width: "120px" }}>
                      ID Sponsor
                    </div>
                    <div className="px-2">:</div>
                    <div className="flex-grow-1">{profile?.id_member}</div>
                  </div>
                  <div className="d-flex">
                    <div className="px-1" style={{ width: "120px" }}>
                      Nama
                    </div>
                    <div className="px-2">:</div>
                    <div className="flex-grow-1">{profile?.nama}</div>
                  </div>
                  <hr />

                  <div>Data Calon Member</div>
                  <div className="d-flex">
                    <div className="px-1" style={{ width: "120px" }}>
                      Jenis
                    </div>
                    <div className="px-2">:</div>
                    <div className="flex-grow-1">
                      {member?.posisi} -{" "}
                      {member?.sisi === "R" ? "Kanan" : "Kiri"}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="px-1" style={{ width: "120px" }}>
                      Data Pribadi
                    </div>
                    <div className="px-2">:</div>
                    <div className="flex-grow-1">
                      <div>
                        {member?.nama?.toUpperCase()} ({member?.jenisKelamin})
                      </div>
                      <div>
                        {formatDate2(member?.tglLahir, true, false, false)}
                      </div>
                      <div>
                        <p style={{ color: "blue" }}>{member?.email}</p>
                      </div>
                      <div>{member?.phoneNumber}</div>
                      <div>
                        {member?.statusKawin} - {member?.agama}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="px-1" style={{ width: "120px" }}>
                      Alamat
                    </div>
                    <div className="px-2">:</div>
                    <div className="flex-grow-1">
                      <div>{member?.alamat}</div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="card mb-2">
            <div className="card-body">
              <div className="font-weight-bold">Metode Pengiriman</div>
              {!delivery?.pickup_store ? (
                <>
                  <div>Alamat Kirim</div>
                  <div>
                    {delivery?.nama} ({delivery?.phone}){" "}
                    <span className="badge badge-info">{delivery?.label}</span>
                  </div>
                  <div>
                    {delivery?.alamat}. Kecamatan {delivery?.kecamatan?.nama},
                    Kabupaten/Kota {delivery?.kota?.nama}, Propinsi{" "}
                    {delivery?.propinsi?.nama}. Kode Pos {delivery?.kode_pos}
                  </div>
                  <div>{delivery?.namaLayanan}</div>
                </>
              ) : (
                <>
                  <div>Pickup Store</div>
                  <div>{delivery?.gudang?.nama}</div>
                  <div>{delivery?.gudang?.lokasi}</div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-7">
          <table className="table ctb" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>Paket</th>
                <th className="text-right">Qty</th>
                <th className="text-right">BV</th>
                <th className="text-right">Harga</th>
              </tr>
            </thead>
            <tbody>
              {carts?.map((item, key) => {
                return (
                  <tr key={key}>
                    <td>{item.nama}</td>
                    <td className="text-right">{item.qty}</td>
                    <td className="text-right">{item.qty * item.value}</td>
                    <td className="text-right">
                      {formatCurrency(item.harga * item.qty)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <th className="text-right">Total</th>
                <th className="text-right">
                  {carts?.reduce((a, b) => a + b.qty, 0)}
                </th>
                <th className="text-right">
                  {carts?.reduce((a, b) => a + b.qty * b.value, 0)}
                </th>
                <th className="text-right">
                  {formatCurrency(
                    carts?.reduce((a, b) => a + b.qty * b.harga, 0)
                  )}
                </th>
              </tr>
            </tfoot>
          </table>
          {
            delivery?.pickup_store === true ? <div className='alert alert-warning'>
              <strong>Pengiriman Pickup Store!</strong>
              <div>
                Maksimal pengambilan 14 hari dari tanggal transaksi / tanggal invoice
              </div>
            </div> : ''
          }

          <div>
            <button
              className="btn btn-outline-info form-control"
              type="button"
              id="PopoverPembayaran"
              onClick={togglePopover}
            >
              {payment?.icon && (
                <img
                  alt="icon"
                  src={payment?.icon}
                  width={30}
                  className="mr-2"
                />
              )}
              {payment?.label ? payment?.label : "Pilih Metode Pembayaran"}
            </button>
            <Popover
              placement="bottom"
              isOpen={popoverOpen}
              target="PopoverPembayaran"
              toggle={togglePopover}
            >
              <div
                style={{
                  width: "100%",
                  maxHeight: 300,
                  overflowY: "auto",
                  overflowX: "auto",
                }}
              >
                {paymentMethod?.map((item, index) => (
                  <div
                    className="flex p-1 my-2 mx-1 border-1 border border-info rounded"
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      togglePopover();
                      onChosePayment(item);
                    }}
                  >
                    <img
                      src={item.icon}
                      width={30}
                      className="mr-2"
                      alt="icon"
                    ></img>
                    {item.label}
                    {item.name !== "credit_card" && item.name !== "qris" ? (
                      <>
                        <div className="flex-1">
                          Biaya Transaksi :{" "}
                          {item.fix_charge > 0
                            ? formatCurrency(item.fix_charge)
                            : ""}
                          {item.fix_charge > 0 &&
                            item.float_charge > 0 &&
                            " & "}
                          {item.float_charge ? item.float_charge : ""}{" "}
                          {item.float_charge > 0 && " % "}
                        </div>
                        <div className="flex-1">PPN : {item.ppn} %</div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </div>
            </Popover>
          </div>
          <div>
            <div className="font-weight-bold mt-4 mb-2">Ringkasan</div>
            <div className="d-flex justify-content-between">
              <div>Total Barang ({carts?.reduce((a, b) => a + b.qty, 0)})</div>
              <div>
                {formatCurrency(
                  carts?.reduce((a, b) => a + b.qty * b.harga, 0)
                )}
              </div>
            </div>
            {!delivery?.pickup_store ? (
              <>
                <div className="d-flex justify-content-between">
                  <div>
                    Ongkos Kirim (
                    {carts?.reduce((a, b) => a + b.qty * b.berat, 0) / 1000} Kg)
                  </div>
                  <div>{formatCurrency(delivery?.ongkir)}</div>
                </div>
                {delivery?.subsidiOngkir ? (
                  <div className="d-flex justify-content-between">
                    <div>Diskon Ongkos Kirim</div>
                    <div>({formatCurrency(delivery?.potonganOngkir)})</div>
                  </div>
                ) : (
                  ""
                )}
                {delivery?.is_asuransi ? (
                  <div className="d-flex justify-content-between">
                    <div>Asuransi Pengiriman</div>
                    <div>{formatCurrency(asuransi_kirim)}</div>
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            {payment?.name !== "cash" ? (
              <div className="d-flex justify-content-between">
                <div>Biaya Jasa Aplikasi</div>
                <div>{formatCurrency(payment?.totalBiaya)}</div>
              </div>
            ) : (
              ""
            )}
            <hr />
            <div className="d-flex justify-content-between font-weight-bold">
              <div>Total Pembayaran</div>
              <div>
                {formatCurrency(
                  carts?.reduce((a, b) => a + b.qty * b.harga, 0) +
                  (delivery?.ongkir || 0) +
                  (payment?.totalBiaya || 0) +
                  (asuransi_kirim || 0) -
                  (delivery?.potonganOngkir || 0)
                )}
              </div>
            </div>

            {use_ppn?.value === "true" ? (
              <>
                <hr />
                <div className="d-flex justify-content-end mt-4">
                  <div>Dasar Pengenaan Pajak</div>
                  <div className="px-2">:</div>
                  <div style={{ width: "120px" }} className="text-right">
                    {formatCurrency(
                      carts?.reduce((a, b) => a + b.qty * Number(b.dpp), 0) || 0
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <div>PPN</div>
                  <div className="px-2">:</div>
                  <div style={{ width: "120px" }} className="text-right">
                    {formatCurrency(
                      carts?.reduce((a, b) => a + b.qty * Number(b.harga), 0) -
                      (carts?.reduce(
                        (a, b) => a + b.qty * Number(b.dpp),
                        0
                      ) || 0)
                    )}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <hr />
      <div className="stepper-navigation">
        <div className="col-sm-12 pt-3 pb-2">
          <h6>
            <strong>Terms Of Conditions</strong>
          </h6>
          <input
            className="mr-2"
            type="checkbox"
            checked={isChecked}
            onChange={handleOnChange}
          />{" "}
          Saya telah membaca dan Saya menyetujui
          <a
            className="ml-2 mr-2"
            target="_blank"
            href="https://thefavored-one.com/kode-etik"
          >
            Kode Etik
          </a>
          yang telah ditetapkan TheFavored One
        </div>
        <div className="d-flex justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn btn-outline-secondary btn-md font-weight-bold mr-1 px-10 flex-grow-1"
            onClick={() => onNavigateBack()}
          >
            <i className="fas fa-chevron-left mr-4"></i>
            Prev
          </button>
          <button
            disabled={isChecked ? false : true}
            onClick={() => bayar()}
            type="submit"
            className="btn btn-primary-f btn-md font-weight-bold ml-1 px-10 flex-grow-1"
          >
            BAYAR
            <i className="fas fa-chevron-right ml-4"></i>
          </button>
        </div>
      </div>
    </>
  );
};

export default FormPayment;
