import React, { useEffect, useState } from 'react';
import Swiper from 'swiper/swiper-bundle';

const Banner = ({ data }) => {
  const _is_speed = 5000;
  const swiper = new Swiper('.swiper', {
    // Optional parameters
    direction: 'horizontal',
    speed: 1000,
    autoplay: {
      delay: _is_speed,
      waitForTransition: true,
      disableOnInteraction: false,
    },
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  const getResponsiveHeight = () => {
    return window.innerWidth < 768 ? '150px' : '400px';
  };
  const [bannerHeight, setBannerHeight] = useState(getResponsiveHeight)

  return (
    <div>
      <div className="swiper mb-4">
        <div className="swiper-wrapper">
          {data?.banner_items
            ?.sort((a, b) => a.number - b.number)
            ?.map(({ nama, url, link, description }) => (
              <div key={nama} className="swiper-slide">
                <img src={url} style={{ width: '100%', height: bannerHeight }}></img>
              </div>
            ))}
        </div>
        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css"
        />
        <script src="https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.js"></script>
      </div>
    </div>
  );
};

export default Banner;
