import React, { useState, useEffect } from 'react';
import { formatCurrency, formatDateOnly } from '../../../constants/utility';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Details from './details';
import RiwayatClaim from './riwayat-claim';
import { useDispatch, useSelector } from 'react-redux';
import { claimPromo, fetchPromoDetail, setPromoDetail } from '../../../redux/actions/report'
import Button from 'react-bootstrap/Button';
import ModalAction from '../../../components/ModalAction';


const Resume = ({ promo }) => {
  const [modalShow, setModalShow] = useState(false);
  const [modaloption, setModalOption] = useState({});
  const { message } = useSelector((state) => state.alert);

  const dispatch = useDispatch();


  useEffect(() => {
    if (message === 'claim berhasil') {
      dispatch(fetchPromoDetail(promo?.promo?.public_id))
    }
  }, [message])



  const handleClaim = () => {
    dispatch(claimPromo({ promo_id: promo?.promo?.public_id }));
    setModalShow(false);
  };

  const [tabKey, setTabKey] = useState('details');

  if (!promo)
    return (
      <div className="p-2">
        <div className="alert alert-info">Silakan Pilih Promo</div>
      </div>
    );

  const sisa =
    promo?.omsets
      ?.filter(({ claim_by }) => claim_by === null, [])
      ?.reduce(
        (a, b) => a + (promo?.promo?.syarat_poin ? b.poin : b.nominal),
        0
      ) || 0;

  const treshold =
    promo?.promo?.syarat_poin || promo?.promo?.syarat_nominal || 0;

  const isKelipatan = promo?.promo?.kelipatan;
  const bisaDiklaim = Math.floor(sisa / treshold);

  const sisaLagiUntukClaim = sisa - bisaDiklaim * treshold;

  const totalOmset = promo?.omsets?.reduce(
    (a, b) => a + (promo?.promo?.syarat_poin ? b.poin * 4 : b.nominal),
    0
  );
  const totalClaimed = promo?.omsets
    ?.filter(({ claim_by }) => claim_by !== null)
    ?.reduce((a, b) => a + (promo?.promo?.syarat_poin ? b.poin : b.nominal), 0);
  const totalReg = promo?.omsets
    ?.filter(({ jenis_order }) => jenis_order === 'reg', [])
    ?.reduce((a, b) => a + b.poin * 4, 0);
  const totalUpg = promo?.omsets
    ?.filter(({ jenis_order }) => jenis_order === 'upg', [])
    ?.reduce((a, b) => a + b.poin * 4, 0);
  const totalRo = promo?.omsets
    ?.filter(({ jenis_order }) => jenis_order === 'ro', [])
    ?.reduce((a, b) => a + b.poin * 4, 0);
  const totalClaimedPerPromo = Object.values(
    promo?.omsets
      ?.filter(({ claim_by }) => claim_by !== null)
      ?.reduce((a, b) => {
        const key = b.claim_by;
        a[key] = a[key] || {
          nama: b.claim_promo?.master_promo?.nama,
          value: 0,
          label: b.claim_promo?.master_promo?.syarat_poin ? 'poin' : '',
        };
        a[key].value += b.claim_promo?.master_promo?.syarat_poin
          ? b.poin
          : b.nominal;
        return a;
      }, {}) || {}
  )?.map(
    ({ nama, value, label }) =>
      `${nama} : ${label !== 'poin' ? `Rp${formatCurrency(value)}` : value * 4
      } ${label == 'poin' ? 'bv' : label}`
  );


  const progressText = !isKelipatan && promo?.claims?.length > 0
    ? `${totalClaimed * 4}/${treshold * 4}`
    : promo?.claims?.length == 0 && isKelipatan
      ? `${(sisaLagiUntukClaim * 4) + (bisaDiklaim * (treshold * 4))}/${treshold * 4}`
      : `${(sisaLagiUntukClaim * 4) + bisaDiklaim * (treshold * 4)}/${treshold * 4}`;
  const progressValue = !isKelipatan && promo?.claims?.length > 0
    ? totalClaimed / treshold
    : promo?.claims?.length == 0 && isKelipatan
      ? sisaLagiUntukClaim / treshold : (totalClaimed / treshold) + (treshold * bisaDiklaim)

  const claimes =
    promo?.claims?.map(
      (claim) => ({
        ...claim,
        details: promo?.omsets?.filter(
          ({ claim_by }) => claim_by === claim.id,
          []
        ),
      }),
      []
    ) || [];

  // console.log(promo?.promo?.public_id)
  const modalConfirmClaim = (data) => {
    setModalShow(true);
    setModalOption({
      title: 'Konfirmasi',
      content: (
        <div>
          <p>
            Anda Yakin Akan Claim 1x Promo <strong>{promo?.promo?.nama}</strong>
            <span style={{ fontWeight: 'bold' }}>{data?.data?.nama} </span> ?
          </p>
          <hr />
          <Button
            className="float-left"
            size="sm"
            variant={`outline-secondary`}
            onClick={() => setModalShow(false)}>
            Tutup
          </Button>
          <Button
            onClick={handleClaim}
            className="float-right"
            style={{
              backgroundColor: 'green',
              color: 'white',
              fontWeight: 'bold',
              textShadow: '0 5px 10px rgba(0, 0, 0, 0.5)',
            }}
            type="button"
            variant={`success`}
            size="sm">
            <i className="fa fa-check" aria-hidden="true"></i> Claim
          </Button>
        </div>
      ),
      color: 'danger',
      size: 'md',
    });
  };

  return (
    <div className="w-100 p-2">
      <ModalAction
        show={modalShow}
        modalOption={modaloption}
        onHide={() => setModalShow(false)}
      />
      {promo.is_qualified ? (
        <>
          <div className="row">
            <div className="col-md-6 col-sm-12 p-2">
              <div className="alert alert-primary bg-white mb-0">
                <div className="text-center">
                  Progres{' '}
                  <label className="text-xs px-1 m-0">{progressText}</label>
                </div>
                <ProgressBar now={progressValue * 100} />
                <div className="text-center text-xs p-1">
                  <i>
                    (
                    {promo?.claims?.length > 0 && !isKelipatan ? 'Promo telah diklaim, tidak berlaku kelipatan'
                      : promo?.claims?.length == 0 && isKelipatan
                        ? `${bisaDiklaim}x bisa diklaim, ${(treshold - sisaLagiUntukClaim) * 4
                        } bv lagi untuk claim berikutnya`
                        : `${bisaDiklaim ? bisaDiklaim : 0}x bisa diklaim`}
                    )
                  </i>
                </div>
                {(promo?.promo?.member_bisa_klaim == true && bisaDiklaim > 0) && (isKelipatan || !isKelipatan && promo?.claims?.length == 0 && bisaDiklaim > 0) ? (
                  <div className='text-center'>
                    <button
                      onClick={() => modalConfirmClaim()}
                      className="btn"
                      style={{
                        backgroundColor: 'green',
                        color: 'white',
                        fontWeight: 'bold',
                        textShadow: '0 5px 10px rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      <i className="fa fa-check" aria-hidden="true"></i> Claim
                    </button>
                  </div>
                ) : ''}

              </div>
            </div>
            <div className="col-md-6 col-sm-12 p-2">
              <div
                style={{ minHeight: '90px' }}
                className={`alert text-center bg-white mb-0 ${promo?.claims?.length ? 'alert-success' : 'alert-secondary'
                  }`}>
                <div className="text-center">Diklaim</div>
                {promo?.claims?.length ? (
                  <span>
                    {promo?.claims?.length}x <b>{promo?.promo?.reward}</b>{' '}
                    berhasil diklaim
                  </span>
                ) : (
                  '0 berhasil diklaim'
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12 p-2">
              <div className="alert alert-info text-center  bg-white  mb-0">
                <div className="text-center">
                  Total Perolehan {promo?.promo?.syarat_poin ? 'BV' : 'OMSET'}
                </div>
                <div className="font-weight-bold">
                  {totalOmset} ({totalReg} REG, {totalUpg} UPGRADE{totalRo > 0 ? `, ${totalRo} RO` : ''})
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 p-2">
              <div className="alert alert-info text-center bg-white  mb-0">
                <div className="text-center">
                  Total {promo?.promo?.syarat_poin ? 'BV' : 'OMSET'} Klaim
                </div>
                <div className="font-weight-bold">
                  {totalClaimed * 4} (
                  {!totalClaimed
                    ? 'belum ada klaim'
                    : totalClaimedPerPromo?.join(' | ')}
                  )
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="">
          <div className="alert alert-danger">
            Anda tidak memenuhi kualifikasi untuk promo ini
          </div>
        </div>
      )
      }
      <div>
        <div className="alert alert-info">
          <ul className="p-0 pl-2 text-xs m-0">
            <li>
              Periode {formatDateOnly(promo?.promo?.periode_awal)} sd{' '}
              {formatDateOnly(promo?.promo?.periode_akhir)}
            </li>
            <li>{promo?.promo?.deskripsi}</li>
            <li>
              Setiap{' '}
              {promo?.promo?.syarat_poin
                ? `${promo?.promo?.syarat_poin * 4} bv`
                : `Rp${formatCurrency(promo?.promo?.syarat_nominal)}`}{' '}
              terkumpul mendapat {promo?.promo?.reward}
            </li>
          </ul>
        </div>
      </div>
      {
        promo.is_qualified ? (
          <Tabs
            id="controlled-tab-example"
            activeKey={tabKey}
            onSelect={(val) => setTabKey(val)}
            className="bg-white">
            <Tab
              eventKey="details"
              title="Detail Poin/Omset"
              className="bg-white">
              <div className="py-2">
                <Details
                  data={promo?.omsets || []}
                  is_poin={promo?.promo?.syarat_poin > 0}
                />
              </div>
            </Tab>
            <Tab eventKey="claims" title="Riwayat Klaim">
              <div className="py-2">
                <RiwayatClaim data={claimes} promo={promo?.promo} />
              </div>
            </Tab>
          </Tabs>
        ) : (
          ''
        )
      }
    </div >
  );
};

export default Resume;
