import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import { formatCurrency, formatDate2 } from '../../../constants/utility';

const SponsorGrup = ({ data }) => {
  const columns = [
    {
      dataField: "_",
      text: "No",
      headerClasses: "py-2 px-2 text-center",
      classes: "p-2",
      headerStyle: (colum, colIndex) => {
        return { width: "40px" };
      },
      formatter: (cel, row, rowIndex) => rowIndex + 1,
      footer: ''
    },
    {
      dataField: "tgl_join", text: "Tgl Join",
      headerStyle: (colum, colIndex) => {
        return { width: "170px" };
      },
      headerClasses: (column, colIndex) => {
        return "py-2 px-1 text-left";
      },
      classes: (column, colIndex) => {
        return "p-2 text-left";
      },
      formatter: (val) => {
        return <div className="text-left">{formatDate2(val, false, false, true)}</div>;
      },
      footer: ''

    },
    {
      dataField: "nama",
      text: "Downline Sponsor",
      headerClasses: "py-2 px-2 text-left",
      classes: "p-2 text-left",
      formatter: (val, row) => `${row?.id_member} - ${row?.nama}`,
      footer: ''
    },
    {
      dataField: "upgrade_member",
      text: "Upgrade",
      headerClasses: "py-2 px-2 text-center",
      classes: "p-2 text-left",
      formatter: (val) => <div className='text-center'>{val === 1 ? 'UPGRADE' : ''}</div>,
      footer: ''
    },
    {
      dataField: "",
      text: "Tgl Upgrade",
      headerClasses: "py-2 px-2 text-center",
      classes: "p-2 text-left",
      formatter: (val, row) => (
        <div className="text-center">
          {row?.tglupgrade
            ? formatDate2(row?.tglupgrade, false, false, true)
            : ""}
        </div>
      ), footer: ''
    },
    {
      dataField: "sum_total_barang",
      text: "Total",
      headerClasses: "py-2 px-2 text-right",
      classes: "p-2 text-right",
      formatter: (val, row) => { return formatCurrency(val) },
      footer: val => <div className="text-right" style={{ fontSize: '14px' }}>{formatCurrency(val.reduce((a, b) => a + b, 0))}</div>,

    },

  ];


  return (

    <div className="bt-table">
      <BootstrapTable
        className="bt-table"
        bootstrap4
        hover
        condensed
        noDataIndication={() => { return "No data"; }}
        bordered={false}
        keyField="no_order"
        data={data || []}
        columns={columns} />
    </div>

  )
}

export default SponsorGrup