import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Alert, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import PageHeading from "../../../components/PageHeading";
// import Searchbar from "../../../components/Searchbar";
import * as actions from "../../../redux/actions";
import { fetchAccountProfile } from "../../../redux/actions/account";
import { fetchPerkembanganJaringan } from "../../../redux/actions/report";
// import BinaryTree from "./Pohon/lib/BinaryTree";
import "./Pohon/lib/tree.css";
// import AsyncSelect from "react-select/async";

import { getOptDownline } from "../../../redux/actions/api";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import PohonTree from "./Pohon";
import FolderTree from "./Folder";

const PerkembanganJaringan = (props) => {
  const { name, title, parent } = props.data;
  const { perkembanganJaringan, formResult } = useSelector(
    (state) => state.report
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [query, setQuery] = useState("");
  const [upline, setUpline] = useState(false);
  const [downlineKiri, setDownlineKiri] = useState(false);
  const [downlineKanan, setDownlineKanan] = useState(false);
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const { public_id } = useSelector((state) => state.auth.data);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actions.setMenuActive({ menuActive: parent || name, subMenuActive: name })
    );
  }, [dispatch, formResult, name, parent]);

  useEffect(() => {
    dispatch(fetchAccountProfile(public_id));
  }, [dispatch, public_id]);

  const [downlineOpts, setDownlineOpts] = useState([]);

  useEffect(() => {
    if (searchQuery) {
      getOptDownline(searchQuery).then((result) => {
        const opts = result?.result?.map(({ id_member, nama }) => ({
          value: id_member,
          label: `${id_member} - ${nama}`,
        }));
        if (opts.length) setDownlineOpts(opts);
      });
    }
  }, [searchQuery, dispatch]);

  useEffect(() => {
    if (query.length < 3) return;
    const timeOutId = setTimeout(() => setSearchQuery(query), 300);
    return () => clearTimeout(timeOutId);
  }, [query]);

  const [optlevel, setOptLevel] = useState([{ value: 3, label: "3 Level" }]);
  const [level, setLevel] = useState(3);
  useEffect(() => {
    if (perkembanganJaringan) {
      setData(perkembanganJaringan?.tree || []);
      setUpline(perkembanganJaringan?.tree?.[0]?.id_upline);
      setDownlineKiri(perkembanganJaringan?.tree?.[0]?.downlinekiri_id_member);
      setDownlineKanan(
        perkembanganJaringan?.tree?.[0]?.downlinekanan_id_member
      );

      setOptLevel(perkembanganJaringan?.levels || []);
    }
  }, [perkembanganJaringan]);

  const [cariId, setCariId] = useState(null);
  useEffect(() => {
    if (!cariId || cariId?.length < 10) return;
    dispatch(fetchPerkembanganJaringan({ search: cariId, level }));
  }, [cariId]);

  useEffect(() => {
    dispatch(fetchPerkembanganJaringan({ search: cariId, level }));
  }, [level]);

  const [tabKey, setTabKey] = useState("pohon");

  return (
    <div>
      <PageHeading title={title} />
      <div className="card shadow">
        <Tabs
          id="controlled-tab-example"
          activeKey={tabKey}
          onSelect={(k) => setTabKey(k)}
          className="bg-white"
        >
          <Tab eventKey="pohon" title="Pohon" className="bg-white">
            <PohonTree
              data={data}
              downlineKanan={downlineKanan}
              downlineKiri={downlineKiri}
              downlineOpts={downlineOpts}
              upline={upline}
              setCariId={(value) => setCariId(value)}
              setQuery={(value) => setQuery(value)}
            />
          </Tab>
          <Tab eventKey="folder" title="Folder">
            <FolderTree
              data={data}
              setCariId={(value) => setCariId(value)}
              setQuery={(value) => setQuery(value)}
              downlineOpts={downlineOpts}
              downlineKiri={downlineKiri}
              downlineKanan={downlineKanan}
              upline={upline}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default PerkembanganJaringan;
