import React from "react";
import Select from 'react-select'
import CreateAble from 'react-select/creatable'

const MulitSelect = ({ 
    name, 
    type, 
    placeholder, 
    defaultValue, 
    className, 
    label,
    register,
    validations,
    errors,
    readOnly,
    layout,
    options,
    onChange,
    field,
    isMulti = true,
    isClearable = false
 }) => {
    const error = errors[name];
    return (
        <div className={`form-group`+(layout === 'horizontal' ? ` row` : ``)}>
            {label ?
                <label 
                    htmlFor={name} 
                    className={
                        `font-weight-bold` +
                        (layout === 'horizontal' ? ` col-sm-4 col-form-label` : ``)
                    }
                    >
                    {label}
                </label> 
            : ''}
            <div className={layout === 'horizontal' ? `col-sm-8` : ``}>
                {!isClearable ?
                <Select 
                    placeholder={placeholder}
                    isMulti={isMulti}
                    name={name}
                    id={name}
                    onChange={(val)=>console.log(val)}
                    {...register(name, validations)}
                    {...field}
                    className={error && 'border-danger'}
                    options={options}/>
                : 
                <CreateAble
                    isClearable
                    placeholder={placeholder}
                    isMulti={isMulti}
                    name={name}
                    id={name}
                    onChange={(val)=>console.log(val)}
                    {...register(name, validations)}
                    {...field}
                    className={error && 'border-danger'}
                    options={options}/>
                }
                {error && (<div className="text-danger small py-2 text-right">{error.message}</div>)}
            </div>
        </div>
    )
};

export default MulitSelect;