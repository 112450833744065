import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../constants/utility";

const initialState = {
  position: "bottom-right",
  type: "success",
  show: false,
  dismissable: true,
  message: "",
  isLoading: false,
};

const showAlert = (state, data) => {
  return updateObject(state, {
    position: data.position ? data.position : "bottom-right",
    type: data.type ? data.type : "success",
    show: data.show ? data.show : false,
    dismissable: data.dismissable ? data.dismissable : false,
    message: data.message ? data.message : "",
  });
};

const hideAlert = (state) => {
  return updateObject(state, {
    show: false,
  });
};

const isLoading = (state, data) => {
  return updateObject(state, {
    isLoading: data,
  });
};
const reducer = (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
    case actionTypes.SHOW_ALERT:
      return showAlert(state, data);
    case actionTypes.SHOW_PROGRESSBAR:
      return isLoading(state, data);
    case actionTypes.HIDE_ALERT:
      return hideAlert(state);
    default:
      return state;
  }
};

export default reducer;
