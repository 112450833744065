import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Select from 'react-select';
import CardBasic from '../../../components/Cards/Basic';
import DatePicker from '../../../components/DatePicker';
import {
  formatCurrency,
  formatDate,
  formatDateOnly,
} from '../../../constants/utility';
import PageHeading from '../../../components/PageHeading';
import PrintingHeader from '../../../components/PrintingHeader';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import * as actions from '../../../redux/actions';
import {
  fetchDownlineReport,
  setDownlineReport,
} from '../../../redux/actions/report';
import Searchbar from '../../../components/Searchbar';

const DownlineReport = (props) => {
  const { name, title, parent } = props.data;
  const { profile } = useSelector((state) => state.account);
  const { DownlineReport, formResult } = useSelector((state) => state.report);
  const dispatch = useDispatch();

  const [posisiMember, setposisiMember] = useState(null);
  const [cariNama, setCariNama] = useState(null);
  const [isVisibleTotal, setVisibleTotal] = useState(true);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(20);
  const [totalSize, setTotalSize] = useState(0);

  useEffect(() => {
    setTotalSize(DownlineReport?.totalSize?.[0]?.count);
  }, [DownlineReport]);

  useEffect(() => {
    dispatch(setDownlineReport(null));
  }, [profile, dispatch]);

  const getReport = (data = null) => {
    const params = {
      member: profile?.id_member,
      filter: {
        page: page,
        sizePerPage: sizePerPage,
        // completed_at: {
        //     op: "between",
        //     value: {
        //         from: moment(filterDate.start).format('YYYY-MM-DD'),
        //         to: moment(filterDate.end).format('YYYY-MM-DD'),
        //     },
        // },
      },
    };
    if (data?.summary === false) params.filter['summary'] = false;
    if (posisiMember) params.filter['posisiMember'] = posisiMember;
    if (cariNama) params.filter['cariNama'] = cariNama;
    dispatch(fetchDownlineReport(params));
  };

  useEffect(() => {
    dispatch(
      actions.setMenuActive({ menuActive: parent || name, subMenuActive: name })
    );
  }, [dispatch, formResult, name, parent]);

  const onClickTotal = () => {
    setVisibleTotal(!isVisibleTotal);
    if (isVisibleTotal) {
      getReport({ summary: false });
    }
  };

  const columns = [
    {
      dataField: '_',
      text: 'No',
      headerClasses: 'py-2 px-2 text-center',
      classes: 'p-2',
      headerStyle: (colum, colIndex) => {
        return { width: '40px' };
      },
      formatter: (cel, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: 'tgl_join',
      text: 'Tanggal Join',
      headerClasses: 'py-2 px-2 text-left',
      classes: 'p-2 text-left',
      headerStyle: (colum, colIndex) => {
        return { width: '140px' };
      },
      formatter: (val) => formatDateOnly(val),
    },
    {
      dataField: 'id_member',
      text: 'Downline',
      headerClasses: 'py-2 px-2 text-left',
      classes: 'p-2 text-left',
      formatter: (val, row) => {
        return row?.id_member + ' - ' + row?.nama?.toString().toUpperCase();
      },
    },
    // {
    //   dataField: 'upline',
    //   text: 'Upline',
    //   headerClasses: 'py-2 px-2 text-left',
    //   classes: 'p-2 text-left',
    //   formatter: (val, row) => {
    //     return (
    //       row?.upline_id_member +
    //       ' - ' +
    //       row?.upline_nama?.toString().toUpperCase()
    //     );
    //   },
    // },
    {
      dataField: 'sponsored',
      text: 'Sponsor',
      headerClasses: 'py-2 px-2 text-left',
      classes: 'p-2 text-left',
      formatter: (val, row) => val,
    },
    {
      dataField: 'upgrade_member',
      text: 'Upgrade',
      headerClasses: 'py-2 px-2 text-center',
      headerStyle: (colum, colIndex) => {
        return { width: '140px' };
      },
      classes: 'p-2 text-center',
      formatter: (val, row) => val,
    },
    {
      dataField: 'posisi',
      text: 'BV',
      headerClasses: 'py-2 px-2 text-center',
      headerStyle: (colum, colIndex) => {
        return { width: '40px' };
      },
      classes: 'p-2 text-center',
      formatter: (val) => val === 'PLATINUM' ? 4 : val === 'SILVER' ? 2 : val === 'BRONZE' ? 1 : 0,
    },
    {
      dataField: 'posisi',
      text: 'Posisi',
      headerClasses: 'py-2 px-2 text-center',
      headerStyle: (colum, colIndex) => {
        return { width: '140px' };
      },
      classes: 'p-2 text-center',
      formatter: (val) => val,
    },
  ];

  const handleTableChange = (type, { page, sizePerPage }) => {
    if (type === 'pagination') {
      setPage(page);
      setSizePerPage(sizePerPage);
      if (isVisibleTotal == false) {
        setVisibleTotal(!isVisibleTotal);
      }
    }
  };

  useEffect(
    (data = null) => {
      if (profile) {
        const params = {
          member: profile?.id_member,
          filter: {
            page: page,
            sizePerPage: sizePerPage,
          },
        };
        if (data?.summary === false) params.filter['summary'] = false;
        if (data?.exportcsv === true) params['exportcsv'] = true;
        if (posisiMember) params.filter['posisiMember'] = posisiMember;
        dispatch(fetchDownlineReport(params));
      }
    },
    [page]
  );

  return (
    <div>
      <PageHeading title={title} />
      <CardBasic nopadding>
        <div className="row py-3 px-3 d-sm-block d-md-flex justify-content-between">
          <div className="col-sm-12 col-md-6 p-0 m-0">
            <div className="d-flex">
              <div style={{ width: '40%', cursor: 'pointer' }} className="mr-1">
                <Select
                  placeholder={'Posisi'}
                  isMulti={false}
                  name={'posisimember'}
                  id={'posisimember'}
                  defaultValue={posisiMember}
                  onChange={(val) => {
                    setposisiMember(val);
                  }}
                  options={[
                    { value: 'BRONZE', label: 'BRONZE' },
                    { value: 'SILVER', label: 'SILVER' },
                    { value: 'PLATINUM', label: 'PLATINUM' },
                  ]}
                />
              </div>
              <div style={{ width: '60%' }}>
                <Searchbar
                  placeholder="Cari Nama Member"
                  onChange={(val) => setCariNama(val)}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <button
              onClick={() => getReport()}
              className="btn btn-primary-f text-nowrap float-right float-md-left">
              <i className="fa fa-search"></i> Lihat
            </button>
          </div>
        </div>
        <div className="table-fixed custom-scrollbar table-responsive my-table">
          <div className="container-print media-print-p2">
            <PrintingHeader
              title={'Laporan Downline'}
              subtitle={`${profile?.id_member} - ${profile?.nama || ''}`}
            />
            {DownlineReport && (
              <button
                className="ml-3 my-3 bg-primary d-flex align-items-center"
                style={{
                  fontSize: '11px',
                  borderRadius: '10px',
                  border: 'none',
                  color: 'white',
                  padding: '10px',
                }}
                onClick={onClickTotal}>
                <i
                  className="fa fa-search mr-2"
                  style={{ fontSize: '13px' }}></i>
                <span style={{ fontWeight: 'bold' }}>
                  {isVisibleTotal ? 'Lihat Total' : 'Tutup Total'}
                </span>
              </button>
            )}

            {!isVisibleTotal && (
              <div className="container my-3">
                <div className="row">
                  <div
                    className="col px-2 d-flex"
                    style={{ fontSize: '0.85rem' }}>
                    <div className="mr-2" style={{ width: '180px' }}>
                      Total Semua Downline
                    </div>
                    <div className="mr-2">:</div>
                    <div className="font-weight-bold">
                      {DownlineReport?.summary?.[0]?.TotalSemua}
                    </div>
                  </div>
                  <div
                    className="col px-2 d-flex"
                    style={{ fontSize: '0.85rem' }}>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col px-2 d-flex"
                    style={{ fontSize: '0.85rem' }}>
                    <div className="mr-2" style={{ width: '180px' }}>
                      Downline Kiri
                    </div>
                    <div className="mr-2">:</div>
                    <div className="font-weight-bold">
                      {DownlineReport?.summary?.[0]?.TotalDownlineKiri +
                        '  ( ' +
                        parseFloat(
                          DownlineReport?.summary?.[0]?.PersenKiri
                        ).toFixed(2) +
                        '% ) '}
                    </div>
                  </div>
                  <div
                    className="col px-2 d-flex"
                    style={{ fontSize: '0.85rem' }}>
                    <div className="mr-2" style={{ width: '180px' }}>
                      Downline Kanan
                    </div>
                    <div className="mr-2">:</div>
                    <div className="font-weight-bold">
                      {DownlineReport?.summary?.[0]?.TotalDownlineKanan +
                        '  ( ' +
                        parseFloat(
                          DownlineReport?.summary?.[0]?.PersenKanan
                        ).toFixed(2) +
                        '% )'}
                    </div>
                  </div>
                  <div
                    className="col px-2 d-flex"
                    style={{ fontSize: '0.85rem' }}>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col px-2 d-flex"
                    style={{ fontSize: '0.85rem' }}>
                    <div className="mr-2" style={{ width: '180px' }}>
                      Downline Kiri Bronze
                    </div>
                    <div className="mr-2">:</div>
                    <div className="font-weight-bold">
                      {DownlineReport?.summary?.[0]?.TotalDownlineKiriBronze}
                    </div>
                  </div>
                  <div
                    className="col px-2 d-flex"
                    style={{ fontSize: '0.85rem' }}>
                    <div className="mr-2" style={{ width: '180px' }}>
                      Downline Kanan Bronze
                    </div>
                    <div className="mr-2">:</div>
                    <div className="font-weight-bold">
                      {DownlineReport?.summary?.[0]?.TotalDownlineKananBronze}
                    </div>
                  </div>
                  <div
                    className="col px-2 d-flex"
                    style={{ fontSize: '0.85rem' }}>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col px-2 d-flex"
                    style={{ fontSize: '0.85rem' }}>
                    <div className="mr-2" style={{ width: '180px' }}>
                      Downline Kiri Silver
                    </div>
                    <div className="mr-2">:</div>
                    <div className="font-weight-bold">
                      {DownlineReport?.summary?.[0]?.TotalDownlineKiriSilver}
                    </div>
                  </div>
                  <div
                    className="col px-2 d-flex"
                    style={{ fontSize: '0.85rem' }}>
                    <div className="mr-2" style={{ width: '180px' }}>
                      Downline Kanan Silver
                    </div>
                    <div className="mr-2">:</div>
                    <div className="font-weight-bold">
                      {DownlineReport?.summary?.[0]?.TotalDownlineKananSilver}
                    </div>
                  </div>
                  <div
                    className="col px-2 d-flex"
                    style={{ fontSize: '0.85rem' }}>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col px-2 d-flex"
                    style={{ fontSize: '0.85rem' }}>
                    <div className="mr-2" style={{ width: '180px' }}>
                      Downline Kiri Platinum
                    </div>
                    <div className="mr-2">:</div>
                    <div className="font-weight-bold">
                      {DownlineReport?.summary?.[0]?.TotalDownlineKiriPlatinum}
                    </div>
                  </div>
                  <div
                    className="col px-2 d-flex"
                    style={{ fontSize: '0.85rem' }}>
                    <div className="mr-2" style={{ width: '180px' }}>
                      Downline Kanan Platinum
                    </div>
                    <div className="mr-2">:</div>
                    <div className="font-weight-bold">
                      {DownlineReport?.summary?.[0]?.TotalDownlineKananPlatinum}
                    </div>
                  </div>
                  <div
                    className="col px-2 d-flex"
                    style={{ fontSize: '0.85rem' }}>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col px-2 d-flex"
                    style={{ fontSize: '0.85rem' }}>
                    <div className="mr-2" style={{ width: '180px' }}>
                      Downline Platinum
                    </div>
                    <div className="mr-2">:</div>
                    <div className="font-weight-bold">
                      {DownlineReport?.summary?.[0]?.TotalPlatinum +
                        '  ( ' +
                        parseFloat(
                          DownlineReport?.summary?.[0]?.PersenPlatinum
                        ).toFixed(2) +
                        '% ) '}
                    </div>
                  </div>
                  <div
                    className="col px-2 d-flex"
                    style={{ fontSize: '0.85rem' }}>
                    <div className="mr-2" style={{ width: '180px' }}>
                      Downline Silver
                    </div>
                    <div className="mr-2">:</div>
                    <div className="font-weight-bold">
                      {DownlineReport?.summary?.[0]?.TotalSilver +
                        '  ( ' +
                        parseFloat(
                          DownlineReport?.summary?.[0]?.PersenSilver
                        ).toFixed(2) +
                        '% )'}
                    </div>
                  </div>
                  <div
                    className="col px-2 d-flex"
                    style={{ fontSize: '0.85rem' }}>
                    <div className="mr-2" style={{ width: '180px' }}>
                      Downline Bronze
                    </div>
                    <div className="mr-2">:</div>
                    <div className="font-weight-bold">
                      {DownlineReport?.summary?.[0]?.TotalBronze +
                        '  ( ' +
                        parseFloat(
                          DownlineReport?.summary?.[0]?.PersenBronze
                        ).toFixed(2) +
                        '% )'}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col px-2 d-flex"
                    style={{ fontSize: '0.85rem' }}>
                    <div className="mr-2" style={{ width: '180px' }}>
                      Sponsored Platinum
                    </div>
                    <div className="mr-2">:</div>
                    <div className="font-weight-bold">
                      {DownlineReport?.summary?.[0]?.SponsorPlatinum}
                    </div>
                  </div>
                  <div
                    className="col px-2 d-flex"
                    style={{ fontSize: '0.85rem' }}>
                    <div className="mr-2" style={{ width: '180px' }}>
                      Sponsored Silver
                    </div>
                    <div className="mr-2">:</div>
                    <div className="font-weight-bold">
                      {DownlineReport?.summary?.[0]?.SponsorSilver}
                    </div>
                  </div>
                  <div
                    className="col px-2 d-flex"
                    style={{ fontSize: '0.85rem' }}>
                    <div className="mr-2" style={{ width: '180px' }}>
                      Sponsored Bronze
                    </div>
                    <div className="mr-2">:</div>
                    <div className="font-weight-bold">
                      {DownlineReport?.summary?.[0]?.SponsorBronze}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col px-2 d-flex"
                    style={{ fontSize: '0.85rem' }}>
                    <div className="mr-2" style={{ width: '180px' }}>
                      BV Sponsored
                    </div>
                    <div className="mr-2">:</div>
                    <div className="font-weight-bold">
                      {
                        (DownlineReport?.summary?.[0]?.SponsorPlatinum * 4)
                        + (DownlineReport?.summary?.[0]?.SponsorSilver * 2)
                        + (DownlineReport?.summary?.[0]?.SponsorBronze * 1)
                      }
                    </div>
                  </div>
                  <div
                    className="col px-2 d-flex"
                    style={{ fontSize: '0.85rem' }}>
                    <div className="mr-2" style={{ width: '180px' }}>
                      BV non Sponsored
                    </div>
                    <div className="mr-2">:</div>
                    <div className="font-weight-bold">
                      {
                        (
                          (DownlineReport?.summary?.[0]?.TotalPlatinum * 4) +
                          (DownlineReport?.summary?.[0]?.TotalSilver * 2) +
                          ((DownlineReport?.summary?.[0]?.TotalBronze * 1))
                        )
                        -
                        (
                          (DownlineReport?.summary?.[0]?.SponsorPlatinum * 4)
                          + (DownlineReport?.summary?.[0]?.SponsorSilver * 2)
                          + (DownlineReport?.summary?.[0]?.SponsorBronze * 1)
                        )
                      }
                    </div>
                  </div>
                  <div
                    className="col px-2 d-flex"
                    style={{ fontSize: '0.85rem' }}>
                    <div className="mr-2" style={{ width: '180px' }}>
                      BV Total
                    </div>
                    <div className="mr-2">:</div>
                    <div className="font-weight-bold">
                      {
                        (DownlineReport?.summary?.[0]?.TotalPlatinum * 4) +
                        (DownlineReport?.summary?.[0]?.TotalSilver * 2) +
                        ((DownlineReport?.summary?.[0]?.TotalBronze * 1))
                      }
                    </div>
                  </div>
                </div>
              </div>
            )}

            <RemotePagination
              data={DownlineReport?.member || []}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              columns={columns}
              onTableChange={handleTableChange}
            />
          </div>
        </div>
      </CardBasic>
    </div>
  );
};

const RemotePagination = ({
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
  columns,
}) => {
  const indication = () => {
    return 'No data';
  };
  return (
    <div className="table-fixied custom-scrollbar table-responsive my-table mt-3">
      <BootstrapTable
        remote
        className="bt-table"
        bootstrap4
        hover
        condensed
        noDataIndication={indication}
        bordered={false}
        keyField="no_order"
        data={data}
        columns={columns}
        pagination={paginationFactory({ page, sizePerPage, totalSize })}
        onTableChange={onTableChange}
      />
    </div>
  );
};

export default DownlineReport;
