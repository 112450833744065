const example = [
  {
    id: "0001",
    nama: "FAVOR",
    hasChild: true,
    isOpen: true,
    sisi: "L",
    posisi: "Platinum",
    pair: 79,
    leftDownline: 100,
    rightDownline: 79,
    childs: [
      {
        id: "0002",
        nama: "KEVIN RUBAMA",
        hasChild: true,
        isOpen: true,
        sisi: "L",
        posisi: "Platinum",
        pair: 79,
        leftDownline: 100,
        rightDownline: 79,
        childs: [
          {
            id: "0004",
            nama: "Dimas Sakti",
            hasChild: false,
            sisi: "L",
            posisi: "Platinum",
            pair: 79,
            leftDownline: 100,
            rightDownline: 79,
          },
          {
            id: "0005",
            nama: "KACONG CILI",
            hasChild: true,
            sisi: "R",
            posisi: "Silver",
            pair: 79,
            leftDownline: 100,
            rightDownline: 79,
          },
        ],
      },
      {
        id: "0003",
        nama: "Sola Lasola",
        hasChild: false,
        sisi: "R",
        posisi: "Silver",
        pair: 79,
        leftDownline: 100,
        rightDownline: 79,
      },
    ],
  },
];

const emptyNode = {
  downlinekanan_id_member: "",
  downlinekiri_id_member: "",
  hasChild: "",
  id: "",
  id_member: "",
  id_sponsor: "",
  id_upline: "",
  isOpen: "",
  jumlah_child_kanan: "",
  jumlah_child_kiri: "",
  jumlah_pair: "",
  nama: "",
  nama_sponsor: "",
  posisi: "",
  public_id: "",
  root: "",
  sisa_pair_kanan: "",
  sisa_pair_kiri: "",
  sisi: "",
  username: "",
  childs: null,
};

module.exports = { example, emptyNode };
