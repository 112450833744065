import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import { formatCurrency, formatDate2 } from '../../../constants/utility';

const Pribadi = ({ data }) => {
  const columns = [
    {
      dataField: "_",
      text: "No",
      headerStyle: { width: '40px' },
      headerClasses: 'py-2 px-2 text-center',
      classes: 'p-2 text-center',
      formatter: (cel, row, rowIndex) => rowIndex + 1,
      footer: ''
    },
    {
      dataField: "tgl_invoice", text: "Tgl Invoice",
      headerStyle: { width: '120px' },
      headerClasses: 'py-2 px-2 text-center',
      classes: 'p-2 text-center',
      formatter: (val) => {
        return <div className="text-center">{formatDate2(val, false, false, true)}</div>;
      },
      footer: ''

    },
    {
      dataField: "no_invoice",
      text: "No Invoice",
      headerStyle: { width: '250px' },
      headerClasses: 'py-2 px-2 text-center',
      classes: 'p-2 text-center',
      formatter: (val, row) => { return val },
      footer: ''
    },
    {
      dataField: "nama",
      text: "Nama",
      headerStyle: { width: '180px' },
      headerClasses: 'py-2 px-2 text-center',
      classes: 'p-2 text-left',
      formatter: (val, row) => { return val },
      footer: ''
    },
    {
      dataField: "total_value",
      text: "BV",
      headerStyle: { width: '40px' },
      headerClasses: 'py-2 px-2 text-center',
      classes: 'p-2 text-center',
      formatter: (val, row) => { return val },
      footer: val => <div className="text-center" style={{ fontSize: '14px' }}>{val.reduce((a, b) => a + b, 0)}</div>,
    },
    {
      dataField: "jenis_order",
      text: "Jenis Order",
      headerStyle: { width: '120px' },
      headerClasses: 'py-2 px-2 text-center',
      classes: 'p-2 text-center',
      formatter: (val, row) => val === 'reg' ? 'REGISTRASI' : val === 'upg' ? 'UPGRADE' : val === 'ro' ? 'Repeat Order' : '',
      footer: ''
    },
    {
      dataField: "total_barang",
      text: "Total",
      headerStyle: { width: '150px' },
      headerClasses: 'py-2 px-2 text-center',
      classes: 'p-2 text-right',
      formatter: (val, row) => { return formatCurrency(val) },
      footer: val => <div className="text-right" style={{ fontSize: '14px' }}>{formatCurrency(val.reduce((a, b) => a + b, 0))}</div>,

    },

  ];


  return (

    <div className="bt-table">
      <BootstrapTable
        className="bt-table"
        bootstrap4
        hover
        condensed
        noDataIndication={() => { return "No data"; }}
        bordered={false}
        keyField="no_invoice"
        data={data || []}
        columns={columns} />
    </div>

  )
}

export default Pribadi