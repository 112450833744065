import React, { useState } from "react";
import { Carousel, CarouselControl, CarouselIndicators, CarouselItem, Modal, ModalBody, ModalHeader } from "reactstrap";
import { formatCurrency } from "../../../../../../constants/utility";

const ModalDetailItem = ({
  data,
  isOpen,
  setIsOpen,
  handleSubmit,
  submit,
}) => {
  console.log("xyy",data);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === data?.gambars?.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? data?.gambars?.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <Modal
        toggle={()=> setIsOpen(false)}
        // onClosed={()=> setIsOpen(false)}
        isOpen={isOpen}
        centered={true}
        className={"modal-lg"}
      >
        <form onSubmit={handleSubmit(submit)}>
          <ModalHeader
            // toggle={toggleViewDetails}
            close={
              <div className="btn close" onClick={() => setIsOpen(false)}>
                ×
              </div>
            }
          >
            Detail Barang
          </ModalHeader>
          <ModalBody>
            <div className="modal-body">
              <div className="container">
                <div className="col-md-auto">
                  <section className="panel">
                    <div className="panel-body">
                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <div className="pro-img-details mb-3">
                            <Carousel
                              activeIndex={activeIndex}
                              next={next}
                              previous={previous}
                              // {...args}
                              className={"text-center"}
                              centerMode={true}
                              infiniteLoop={true}
                            >
                              {/* <CarouselIndicators
                                items={data?.gambars}
                                activeIndex={activeIndex}
                                onClickHandler={goToIndex}
                              /> */}
                              {data?.gambars?.map((item, index) => {
                                return (
                                  <CarouselItem 
                                    key={index}>
                                      <img
                                        src={item?.lokasi}
                                        alt="Card Product"
                                      />
                                  </CarouselItem>
                                )
                              })}
                            </Carousel>
                            <div>
                               <CarouselControl
                                  direction="prev"
                                  directionText="."
                                  onClickHandler={previous}
                                />
                                <CarouselControl
                                  direction="next"
                                  directionText="."
                                  onClickHandler={next}
                                />

                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div className="col">
                            <h4 className="pro-d-title font-weight-bold">
                              <a href="#">{data?.nama}</a>
                            </h4>
                            <p>{data?.deskripsi}</p>
                            <span className="pro-price">
                              {formatCurrency(data?.harga)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </ModalBody>
        </form>
      </Modal>
    </>
  );
};

export default ModalDetailItem;
