import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const BinaryTreeNode = ({
  allUsers,
  user,
  deep = 1,
  maxDeep = 4,
  setModal,
  searchFunction,
  setUpline,
  setNamaUpline,
  setSisiRegister,
}) => {
  const { upgrade } = useSelector((state) => state.report);
  const fakeUserKanan = {
    id: null,
    downlinekiri_id_member: null,
    downlinekanan_id_member: null,
    upID: user?.id_member,
    upline: user?.public_id,
    nama: user?.nama,
    sisi: "R",
  };

  const fakeUserKiri = {
    id: null,
    downlinekiri_id_member: null,
    downlinekanan_id_member: null,
    upID: user?.id_member,
    upline: user?.public_id,
    nama: user?.nama,
    sisi: "L",
  };

  let leftChild = allUsers.find(
    (item) => item.id_member === user.downlinekiri_id_member
  );
  if (!leftChild) {
    leftChild = fakeUserKiri;
  }
  let rightChild = allUsers.find(
    (item) => item.id_member === user.downlinekanan_id_member
  );
  if (!rightChild) {
    rightChild = fakeUserKanan;
  }

  let size = 120;
  let windowAvailWidth = window.screen.width;
  windowAvailWidth < 1080 && (size = 112);
  windowAvailWidth < 768 && (size = 100);
  let scale = window.devicePixelRatio;
  let widthBox = Math.min(Math.floor(size * scale), 120) + "px";
  let heightBox = Math.min(Math.floor(size * scale * 1.2), 120) + "px";

  return (
    <li>
      {user?.id !== null ? (
        <a
          href="#"
          style={{ cursor: user?.root ? "default" : "pointer" }}
          onClick={() => !user?.root && searchFunction(user?.id_member)}
          data-bs-toggle="tooltip-member"
          data-bs-placement="right"
          title={`
            Detail : 
            ID Member : ${user?.id_member} ,
            Nama Member : ${user?.nama} ,
            Posisi : ${user?.posisi ? user?.posisi : "-"} ,
            Sisi : ${user?.sisi === "L"
              ? "Kiri"
              : user?.sisi === "R"
                ? "Kanan"
                : "Root"
            } ,
            Jumlah Pair : ${user?.jumlah_pair} ,
            Jumlah Pair Bv : ${user?.jumlah_pair_bv} ,
            Jumlah Downline Kiri : ${user?.jumlah_child_kiri} ,
            Jumlah Downline Kanan : ${user?.jumlah_child_kanan} ,
            Sisa Bv Kiri : ${user?.sisa_bv_kiri} ,
            Sisa Bv Kanan : ${user?.sisa_bv_kanan} ,
          `}
        >
          <div
            className="member-view-box justify-content-center"
            style={{
              backgroundColor: "#393949",
              height: `${heightBox}`,
              width: `${widthBox}`,
              color: "white",
              borderRadius: "6px",
              borderBottomLeftRadius: "6px",
              borderBottomRightRadius: "6px",
              position: "relative",
            }}
          >
            <div className="p-0 pt-1 px-1">
              <i className="fa fa-user" style={{ fontSize: "20px" }} />
              <div
                className="member-image"
                style={{ fontSize: "10px", fontWeight: "bold" }}
              >
                <div style={{ wordWrap: "break-word" }}>{user?.nama}</div>
              </div>
              <div
                className="bg-white text-dark mt-1"
                style={{
                  width: "100%",
                  borderRadius: "3px",
                  fontWeight: "bold",
                }}
              >
                <div className="col">{user?.id_member}</div>
                {/* Update 2024 */}
                <div
                  className={`col badge badge-pill badge-${user?.posisi === "PLATINUM" ? "warning" :
                    user?.posisi === "SILVER" ? "info" : user?.posisi === "BRONZE" ? "success" : ''
                    } p-1 m-0`}
                >
                  {user?.posisi}
                </div>
              </div>
            </div>
            <div className="m-0 p-0" style={{ fontSize: "9px" }}>
              Pair bv {user?.jumlah_pair_bv}
            </div>
            <div className="m-0 p-0" style={{ fontSize: "9px" }}>
              L{user?.sisa_bv_kiri} - R{user?.sisa_bv_kanan}
            </div>
            {/* <div className="m-0" style={{ fontSize: "9px", wordWrap: "break-word" }}>
              SponsBy: {user?.nama_sponsor ? user?.nama_sponsor : "-"}
            </div> */}
          </div>
        </a>
      ) : (
        <a>
          <div
            className="member-view-box justify-content-center"
            style={{
              backgroundColor: "#ccc",
              width: `${widthBox}`,
              height: `${heightBox}`,
              color: "white",
              borderRadius: "6px",
              borderBottomLeftRadius: "6px",
              borderBottomRightRadius: "6px",
              position: "relative",
            }}
          >
            <div className="p-2"></div>
          </div>
        </a>
      )}

      {deep <= maxDeep && (
        <ul>
          <BinaryTreeNode
            deep={deep + 1}
            maxDeep={maxDeep}
            allUsers={allUsers}
            user={leftChild}
            searchFunction={searchFunction}
            setUpline={setUpline}
            setNamaUpline={setNamaUpline}
            setSisiRegister={setSisiRegister}
          />
          <BinaryTreeNode
            deep={deep + 1}
            maxDeep={maxDeep}
            allUsers={allUsers}
            user={rightChild}
            searchFunction={searchFunction}
            setUpline={setUpline}
            setNamaUpline={setNamaUpline}
            setSisiRegister={setSisiRegister}
          />
        </ul>
      )}
    </li>
  );
};

export default BinaryTreeNode;
