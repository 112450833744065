import React from "react";
import { Card } from "reactstrap";

const CardAlamat = ({ data, action, setIsOpen, setFormAddress, selected, onSelectAlamat }) => {
  return (
    <Card
      className="mb-1 py-2"
      // onClick={() => handleClick(data)}
      style={{ cursor: "pointer", borderColor: data?.public_id === selected?.public_id ? "#3d405c" : "#ccc" }}
    >
      <div className="mb-2 mx-3 border-bottom d-flex justify-content-between">
        <button
          type="button"
          className="btn btn-primary-f btn-sm mb-2"
          onClick={() => {
            onSelectAlamat(data);
          }}
        >
          Pilih Alamat
        </button>
        <button
          type="button"
          className="btn btn-secondary btn-sm mb-2"
          onClick={() => {
            setFormAddress(data);
            setIsOpen(true);
          }}
        >
          Ubah Alamat
        </button>
      </div>
      <div className="px-3 row">
        <div className="col">
          <small>Label</small>
          <br />
          {data.label}
        </div>
        <div className="col">
          <small>Nama Penerima</small>
          <br />
          {data.nama}
        </div>
        <div className="col">
          <small>Nomor Telepon</small>
          <br />
          {data.phone}
        </div>
        <div className="col">
          <small>Kota</small>
          <br />
          {data.kota?.nama}
        </div>
       
      </div>
      <div className="px-3  row">
        <div className="col-8">
          <small>Alamat</small>
          <br />
          {`${data.alamat} Kec. ${data.kecamatan?.nama} Kab/Kota. ${data.kota?.nama} Prop. ${data.propinsi?.nama} KodePos ${data.kode_pos}`}
        </div>
      </div>
    </Card>
  );
};

export default CardAlamat;
