import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import CardBasic from "../../../components/Cards/Basic";
import PageHeading from "../../../components/PageHeading";
import TextInput from "../../../components/TextInput";
import * as actions from "../../../redux/actions";
import { updateAccountPassword } from "../../../redux/actions/account";
import { AuthWrapper } from "../../AuthWrapper";
import Alert from 'react-bootstrap/Alert';


const ChangePassword = (props) => {
  const { name, title, parent } = props.data;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actions.setMenuActive({ menuActive: parent || name, subMenuActive: name })
    );
  }, [dispatch, name, parent]);

  const { register, handleSubmit, watch, formState } = useForm({
    defaultValues: {
      password_lama: "",
      password_baru: "",
      rpassword_baru: "",
    },
  });

  const password = useRef({});
  password.current = watch("password_baru", "");

  const submit = (data) => {
    dispatch(updateAccountPassword(data));
    setShow(true)
  };

  const [show, setShow] = useState(false);


  return (
    <div>
      <PageHeading title={title} />
      <Alert show={show} className="alert alert-warning">
        <i className="fa fa-info mr-3"> </i><strong>Password telah berhasil diubah.</strong>
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-hidden="true"
          onClick={()=> window.location.reload()}
        >
          ×
        </button>
      </Alert>
      <CardBasic title="Ubah Password">
        <form onSubmit={handleSubmit(submit)}>
          <div className="row">
            <div className="col-sm-12">
              <TextInput
                layout="vertical"
                label="Password Lama"
                type="password"
                name="password_lama"
                placeholder=""
                register={register}
                validations={{ required: "Masukkan pasword lama" }}
                errors={formState.errors}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 ">
              <TextInput
                layout="vertical"
                label="Password Baru"
                type="password"
                name="password_baru"
                register={register}
                validations={{
                  required: "Masukkan pasword baru",
                  minLength: {
                    value: 8,
                    message: "Password setidaknya 8 karakter",
                  },
                }}
                errors={formState.errors}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 ">
              <TextInput
                layout="vertical"
                label="Ulangi Password Baru"
                type="password"
                name="rpassword_baru"
                register={register}
                validations={{
                  required: "Ulangi pasword baru",
                  validate: (value) =>
                    value === password.current || "Password tidak sama",
                }}
                errors={formState.errors}
              />
            </div>
          </div>
          <hr />

          <button
            // disabled={loading}
            type="submit"
            className="btn btn-primary-f btn-user btn-block"
          >
            {/* <i className={`fas ` + (!loading ? `fa-sign-in-alt` : `fa-spinner fa-spin`)}></i>  */}
            &nbsp; SIMPAN
          </button>
        </form>
      </CardBasic>
    </div>
  );
};

export default ChangePassword;
