import React, { useEffect } from 'react';
import { Alert } from '../../components/Alert';
import Sidebar from '../../components/Navigation/Sidebar';
import Topbar from '../../components/Navigation/Topbar';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchAccountProfile } from '../../redux/actions/account';
import { useLocation } from 'react-router-dom';
import './index.css';
// custom hook to get the current pathname in React

const usePathname = () => {
  const location = useLocation();
  return location.pathname;
};

export const AuthWrapper = (props) => {
  const menu = useSelector((state) => state.menu);
  const { profile } = useSelector((state) => state.account);
  const { data: user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    if (user?.public_id) dispatch(fetchAccountProfile(user.public_id, true));
  }, [dispatch, user]);

  const path = usePathname();
  useEffect(() => {
    if (path === '/account/profile') return;
    if (
      profile?.propinsi_domisili === null ||
      profile?.kota_domisili === null
    ) {
      navigate(`account/profile?field=domisili&fallback=${path}`);
      return;
    }
    // console.log("aaa", menuActive);
  }, [menu, profile]);

  return (
    <>
      <div>
        <div id="wrapper">
          <Sidebar key="sidebar" />
          <div
            id="content-wrapper"
            className="content-wrapper d-flex flex-column">
            <div id="content">
              <Topbar />
              <div className="container-fluid custom-scrollbar content-scroller">
                <div className="inner-contain mb-4">{props.children}</div>
                <footer className="sticky-footer">
                  <ul className="foot-custom list-inline d-flex justify-content-around">
                    <li className="list-inline-item">
                      <a
                        href={`${process.env.REACT_APP_LANDING_URL}/kode-etik`}
                        className="text-muted">
                        Kode Etik
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href={`${process.env.REACT_APP_LANDING_URL}/privacy-policy`}
                        className="text-muted">
                        Kebijakan Privasi
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href={`${process.env.REACT_APP_LANDING_URL}/shipping-policy`}
                        className="text-muted">
                        Kebijakan Pengiriman
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href={`${process.env.REACT_APP_LANDING_URL}/retur-refund-policy`}
                        className="text-muted">
                        Kebijakan Pengembalian
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href={`${process.env.REACT_APP_LANDING_URL}/faq`}
                        className="text-muted">
                        FAQ
                      </a>
                    </li>
                  </ul>
                  <div className="container ">
                    <div className="copyright text-center mt-5 mb-2">
                      <span>
                        Copyright &copy;{' '}
                        <span style={{ fontWeight: 'bold' }}>
                          THE FAVORED ONE
                        </span>
                      </span>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </div>
          <a className="scroll-to-top rounded" href="#page-top">
            <i className="fas fa-angle-up"></i>
          </a>
        </div>
      </div>
      <Alert />
    </>
  );
};
