import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Card, Collapse } from "reactstrap";
import CardBasic from "../../../components/Cards/Basic";
import CardInfo from "../../../components/Cards/Info";
import DatePicker from "../../../components/DatePicker";
import PageHeading from "../../../components/PageHeading";
import { formatCurrency, formatDate } from "../../../constants/utility";
import * as actions from "../../../redux/actions";
import { fetchBonus } from "../../../redux/actions/report";
//Navigation
import { AuthWrapper } from "../../AuthWrapper";
import Select from "react-select";


const RincianBonus = (props) => {
  const { name, title, parent } = props.data;
  const { bonus } = useSelector((state) => state.report);
  const page = 1;
  const sizePerPage = 10;
  const [filterStatus, setFilterStatus] = useState([]);
  const [openDetails, setOpenDetails] = useState([]);

  let date = new Date();
  const [filterDate, setFilterDate] = useState({
    start: new Date(date.setDate(date.getDate() - 30)),
    end: new Date(),
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      actions.setMenuActive({ menuActive: parent || name, subMenuActive: name })
    );
  }, [dispatch, parent, name]);

  useEffect(() => {
    if (!filterDate.start || !filterDate.end) {
      return;
    }

    const params = {
      page_size: sizePerPage,
      page: page,
      filter: {
        tanggal: {
          op: "between",
          value: {
            from: filterDate.start,
            to: filterDate.end,
          },
        },
      },
    };

    if (filterStatus.length > 0) {
      params.filter["status_transfer"] = {
        op: "in",
        value: filterStatus.map((item) => item.value, []),
      };
    }
    dispatch(fetchBonus(params));
  }, [filterDate, page, sizePerPage, dispatch, filterStatus]);

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    dispatch(fetchBonus({ page_size: sizePerPage, page: currentPage }));
  };

  const [open1, setOpen1] = useState(false);

  return (
    <div>
      <PageHeading title={title} />
      <CardBasic>
        <div
          className="row row-cols-1 row-cols-sm-2 row-cols-md-3"
          style={{ fontSize: "14px" }}
        >
          <div className="col">
            <div className="row">
              <label className="font-weight-bold col-md-12">Tanggal</label>
              <div className="col-md-12">
                <DatePicker
                  value={filterDate.start}
                  endDate={filterDate.end}
                  onChange={setFilterDate}
                  range
                />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="row">
              <label className="font-weight-bold col-md-12">Status</label>
              <div className="col-md-12">
                <Select
                  placeholder={"Status Transfer"}
                  isMulti={true}
                  name={"status"}
                  id={"status"}
                  onChange={(val) => setFilterStatus(val)}
                  options={[
                    { value: "PENDING", label: "PENDING" },
                    { value: "PROCESSED", label: "PROCESSED" },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        {/* <div className="d-flex justify-content-center mt-4 "> */}
        <div className="row mt-4 mb-2">
          <CardInfo
            title={(<div><strong>SUCCESS PAYMENT</strong></div>)}
            icon="check-circle"
            color="success"
            value={formatCurrency(bonus?.bonus_summary?.dibayar?.total)}
            size="6"
          />

          <CardInfo
            title={(<div><strong>PENDING PAYMENT</strong></div>)}
            icon="clock"
            color="warning"
            value={formatCurrency(bonus?.bonus_summary?.pending?.total)}
            size="6"
          />
        </div>
        <div className="mt-2 mb-4">
          {bonus?.total_count === 0 ? (
            <Card className="mb-1">
              <div className="p-4" style={{ fontSize: "14px" }}>
                Tidak ada bonus
              </div>
            </Card>
          ) : (
            bonus?.rows?.map((item, key) => {
              return (
                <Card className="mb-1" key={key}>
                  <div className="px-2 py-1 row row-cols-2 row-cols-sm-2 row-cols-md-4">
                    <div className="col">
                      <small>Tanggal</small>
                      <br />
                      {formatDate(item?.tanggal, true, false, true)}
                    </div>

                    <div className="col">
                      <small>Jenis</small>
                      <br />
                      {item?.jenis_bonus}
                    </div>

                    <div className="col">
                      <small>Status Transfer</small>
                      <br />
                      {item?.payout?.status === "completed"
                        ? "COMPLETED"
                        : item?.status_transfer}
                    </div>
                    <div className="col">
                      <small>Tanggal Transfer</small>
                      <br />
                      {item?.tgl_transfer
                        ? formatDate(item?.tgl_transfer, false, false, true)
                        : "-"}
                    </div>
                  </div>
                  <div className="px-2 py-1 row row-cols-2 row-cols-sm-2 row-cols-md-4">

                    <div className="col">
                      <small>Bonus Bruto</small>
                      <br />
                      {formatCurrency(item?.bonus_bruto)}
                    </div>

                    <div className="col">
                      <small>Potongan Pph</small>
                      <br />
                      {formatCurrency(item?.pot_pph)}
                    </div>

                    <div className="col">
                      <small>Bonus Setelah Pph</small>
                      <br />
                      {formatCurrency(item?.bonus_dibayar)}
                    </div>

                    <div className="col">
                      <small>Payout</small>
                      <br />
                      {item.payout?.status === "queued"
                        ? "Widthdraw Requested"
                        : item.payout?.status === "processed"
                          ? "Withdraw Processed"
                          : item.payout?.status === "completed"
                            ? "Withdraw Completed"
                            : item.payout?.status === "rejected"
                              ? "Withdraw Rejected"
                              : item.payout?.status === "failed"
                                ? "Withdraw Failed"
                                : "-"}
                    </div>

                    <div className="col">
                      <small>Detail</small>
                      <br />
                      <span
                        className="badge badge-pill badge-info btn-primary-f"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          setOpenDetails(
                            !openDetails?.includes(item?.public_id)
                              ? [...openDetails, item?.public_id]
                              : openDetails.filter(
                                (ix) => ix !== item?.public_id,
                                []
                              )
                          )
                        }
                      >
                        {!openDetails?.includes(item?.public_id)
                          ? "Tampikan Detail"
                          : "Sembunyikan Detail"}
                      </span>
                    </div>
                  </div>
                  <div className="px-2 py-1" style={{ fontSize: "14px" }}>
                    {item.jenis_bonus === "SPONSOR" && (
                      <div className="p-0 m-0">
                        <hr className="m-0 p-0 mb-2" />
                        <Collapse
                          isOpen={openDetails?.includes(item?.public_id)}
                          style={{
                            overflowY: "scroll",
                            maxHeight: "230px",
                            overflowX: "hidden",
                          }}
                        >
                          Detail :
                          <div className="px-2 py-1 row row-cols-2 row-cols-sm-2 row-cols-md-5">
                            <div className="col">
                              <strong>No.</strong>
                            </div>
                            <div className="col">
                              <strong>ID member</strong>
                            </div>
                            <div className="col">
                              <strong>Nama</strong>
                            </div>
                            <div className="col">
                              <strong>Posisi</strong>
                            </div>
                            <div className="col">
                              <strong>Bonus</strong>
                            </div>
                          </div>
                          {item?.bonus_sponsors?.map((child, index) => {
                            return (
                              <div
                                className="px-2 py-1 row row-cols-2 row-cols-sm-2 row-cols-md-5"
                                key={index}
                              >
                                <div className="col">{index + 1}</div>
                                <div className="col">
                                  {child?.sponsor_detail_rekrutan?.member?.id_member}
                                </div>
                                <div className="col">
                                  {child?.sponsor_detail_rekrutan?.nama}
                                </div>
                                <div className="col">
                                  {child?.sponsor_detail_rekrutan?.posisi}
                                </div>
                                <div className="col">
                                  {formatCurrency(child?.bonus_dibayar)}
                                </div>
                              </div>
                            );
                          })}
                        </Collapse>
                      </div>
                    )}

                    {item.jenis_bonus === "RO" && (
                      <div className="p-0 m-0">
                        <hr className="m-0 p-0 mb-2" />
                        <Collapse
                          isOpen={openDetails?.includes(item?.public_id)}
                          style={{
                            overflowY: "scroll",
                            maxHeight: "230px",
                            overflowX: "hidden",
                          }}
                        >
                          Detail :
                          <div className="px-2 py-1 row row-cols-2 row-cols-sm-2 row-cols-md-4">
                            <div className="col">
                              <strong>No.</strong>
                            </div>
                            <div className="col">
                              <strong>Posisi Saat RO</strong>
                            </div>
                            <div className="col">
                              <strong>Nominal RO</strong>
                            </div>
                            <div className="col">
                              <strong>Bonus Dibayar</strong>
                            </div>
                          </div>
                          {item?.bonus_ros?.map((child, index) => {
                            return (
                              <div className="px-2 py-1 row row-cols-2 row-cols-sm-2 row-cols-md-4">
                                <div className="col">{index + 1}</div>
                                <div className="col">{child?.sponsored_member?.posisi}</div>
                                <div className="col">
                                  {formatCurrency(child?.nominal_ro)}
                                </div>
                                <div className="col">
                                  {formatCurrency(child?.bonus_dibayar)}
                                </div>
                              </div>
                            );
                          })}
                        </Collapse>
                      </div>
                    )}

                    {item.jenis_bonus === "RO-SPONSOR" && (
                      <div className="p-0 m-0">
                        <hr className="m-0 p-0 mb-2" />
                        <Collapse
                          isOpen={openDetails?.includes(item?.public_id)}
                          style={{
                            overflowY: "scroll",
                            maxHeight: "230px",
                            overflowX: "hidden",
                          }}
                        >
                          Detail :
                          <div className="px-2 py-1 row row-cols-2 row-cols-sm-2 row-cols-md-4">
                            <div className="col">
                              <strong>No.</strong>
                            </div>
                            <div className="col">
                              <strong>ID Downline</strong>
                            </div>
                            <div className="col">
                              <strong>Nama</strong>
                            </div>
                            <div className="col">
                              <strong>Bonus Dibayar</strong>
                            </div>
                          </div>
                          {item?.bonus_ro_sponsorings?.map((child, index) => {
                            return (
                              <div className="px-2 py-1 row row-cols-2 row-cols-sm-2 row-cols-md-4">
                                <div className="col">{index + 1}</div>
                                <div className="col">{child?.sponsored_member?.id_member}</div>
                                <div className="col">{child?.sponsored_member?.nama}</div>
                                <div className="col">{formatCurrency(child?.bonus_dibayar)}</div>
                              </div>
                            );
                          })}
                        </Collapse>
                      </div>
                    )}
                    {item.jenis_bonus === "PAIR" && (
                      <div className="p-0 m-0">
                        <hr className="m-0 p-0 mb-2" />
                        <Collapse
                          isOpen={openDetails?.includes(item?.public_id)}
                          style={{
                            overflowY: "scroll",
                            maxHeight: "230px",
                            overflowX: "hidden",
                          }}
                        >
                          Detail :
                          <div className="px-2 py-1 row row-cols-2 row-cols-sm-2 row-cols-md-5">
                            <div className="col">
                              <strong>Posisi</strong>
                            </div>
                            <div className="col">
                              <strong>Pertambahan Bv Pair</strong>
                            </div>
                            <div className="col">
                              <strong>Pair Bv Dibayar</strong>
                            </div>
                            <div className="col">
                              <strong>Bv Max</strong>
                            </div>
                            <div className="col">
                              <strong>Bonus</strong>
                            </div>
                          </div>
                          {item?.bonus_pairs?.map((child, index) => {
                            return (
                              <div className="px-2 py-1 row row-cols-2 row-cols-sm-2 row-cols-md-5">
                                <div className="col">{child?.posisi}</div>
                                <div className="col">{child?.jumlah_increment_pair_bv}</div>
                                <div className="col">{child?.bonus_pair_details?.reduce((a, b) => a + b?.jumlah_pair_dibayar, 0)}</div>
                                <div className="col">{child?.bonus_pair_details?.reduce((a, b) => a + b?.max_pair_dibayar, 0)}</div>
                                <div className="col">{formatCurrency(child?.bonus_dibayar)}</div>
                              </div>
                            );
                          })}

                          <div className="px-2 py-1 row row-cols-2 row-cols-sm-2 row-cols-md-5">
                            <div className="col">
                              <strong> </strong>
                            </div>
                          </div>
                          <hr
                            style={{
                              height: '0.1px',
                            }}
                          />

                          <div className="px-2 py-1 row row-cols-2 row-cols-sm-2 row-cols-md-5">
                            <div className="col">
                              <strong>Jumlah Pair Dibayar</strong>
                            </div>
                            <div className="col">
                              <strong>Max Pair Dibayar</strong>
                            </div>
                            <div className="col">
                              <strong>Bonus Per Pair</strong>
                            </div>
                            <div className="col">
                              <strong>Bonus Dibayar</strong>
                            </div>
                          </div>

                          {item?.bonus_pairs?.[0]?.bonus_pair_details?.map((child, index) => {
                            console.log(index)
                            return (
                              <div className="px-2 py-1 row row-cols-2 row-cols-sm-2 row-cols-md-5" key={index}>
                                <div className="col">{child?.jumlah_pair_dibayar}</div>
                                <div className="col">{child?.max_pair_dibayar}</div>
                                <div className="col">{formatCurrency(child?.bonus_per_pair)}</div>
                                <div className="col">{formatCurrency(child?.bonus_dibayar)}</div>
                              </div>
                            );
                          })}

                        </Collapse>
                      </div>
                    )}
                    {item.jenis_bonus === "PASSUP" && (
                      <div className="p-0 m-0">
                        <hr className="m-0 p-0 mb-2" />
                        <Collapse
                          isOpen={openDetails?.includes(item?.public_id)}
                          style={{
                            overflowY: "scroll",
                            maxHeight: "230px",
                            overflowX: "hidden",
                          }}
                        >
                          Detail :
                          <div className="px-2 py-1 row row-cols-2 row-cols-sm-2 row-cols-md-5">
                            <div className="col">
                              <strong>No.</strong>
                            </div>
                            <div className="col">
                              <strong>ID Member</strong>
                            </div>
                            <div className="col">
                              <strong>Nama</strong>
                            </div>
                            <div className="col">
                              <strong>Posisi</strong>
                            </div>
                            <div className="col">
                              <strong>Bonus</strong>
                            </div>
                          </div>
                          {item?.bonus_pass_ups?.map((child, index) => {
                            return (
                              <div className="px-2 py-1 row row-cols-2 row-cols-sm-2 row-cols-md-5">
                                <div className="col">{index + 1}</div>
                                <div className="col">
                                  {child?.passup_detail_rekrutan?.member?.id_member}
                                </div>
                                <div className="col">
                                  {child?.passup_detail_rekrutan?.member?.nama}
                                </div>
                                <div className="col">
                                  {child?.passup_detail_rekrutan?.posisi}
                                </div>
                                <div className="col">
                                  {formatCurrency(child?.bonus_dibayar)}
                                </div>
                              </div>
                            );
                          })}
                        </Collapse>
                      </div>
                    )}
                  </div>
                </Card>
              );
            })
          )}
        </div>
        <ReactPaginate
          previousLabel={"prev"}
          nextLabel={"next"}
          breakLabel={"..."}
          pageCount={bonus?.total_page}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </CardBasic>
    </div>
  );
};

export default RincianBonus;
