import React, { useEffect } from 'react';
import { useState } from 'react';

const CardInfo = (props) => {
  const [state, setState] = useState({
    value: props?.value,
    title: props?.title,
    icon: 'fas fa-calendar fa-2x text-gray-300',
    color: 'primary',
    cardClass: '',
    titleClass: '',
    size: '6',
  });

  useEffect(() => {
    setState({
      //...state,
      cardClass: `card border-left-${props?.color} shadow h-100 py-2`,
      titleClass: `text-xs font-weight-bold text-${props?.color} text-uppercase mb-1`,
      icon: `fas fa-${props?.icon} fa-2x text-gray-300`,
      value: props?.value,
      title: props?.title,
      size: props?.size,
    });
  }, [props, setState]);

  return (
    <div className={`col-xl-${state.size} col-md-6 mb-4`}>
      <div className={state.cardClass}>
        <div className="card-body">
          <div className="row no-gutters align-items-center">
            <div className="col mr-2 align-items-center text-center">
              <div className={state.titleClass}>{state.title}</div>
              <div
                className={`h5 mb-0 font-weight-bold ${
                  state.value == 'AKTIF' && 'text-primary'
                }  ${state.value == 'INAKTIF' && 'text-danger'}`}>
                {state.value}
              </div>
            </div>
            <div className="col-auto">
              <i className={state.icon}></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardInfo;
