import React from "react";
import Chart from "chart.js/auto";
const useChart = (nodeRef, options, status) => {
  let chartStatus0 = Chart.getChart(status); // <canvas> id
  if (chartStatus0 !== undefined) {
    chartStatus0.destroy();
  }
  React.useEffect(() => {
    new Chart(nodeRef.current, options);
    console.log("render");
  }, [nodeRef, options]);

  return {};
};

export default useChart;
