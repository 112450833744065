import * as actionTypes from "../../../constants/actionTypes";
import * as api from "../api";

const getInformasiList = (data) => {
  return {
    type: actionTypes.GET_INFORMASI_LIST,
    data: data,
  };
};

export const fetchInformasi = (params) => async (dispatch) => {
  try {
    const { result } = await api.getInformasi(params);
    dispatch(getInformasiList(result));
  } catch (err) {
    console.error(err);
  }
};
