// import { createFormData } from '../../constants/utility';
import http from '../../services/NetworkService';

export const postTrack = (data) => {
  return http.post(`/shipping-waybill`, data);
};

export const authLogin = (data) => {
  return http.post('/login', { ...data, from: 'WEB' });
};

export const postForgotPassword = (data) => {
  return http.post('/forgot-password', { ...data });
};

export const postResetPassword = (data) => {
  return http.post('/reset-password', { ...data });
};


export const getUsername = (query) => {
  const params = {
    filter: query?.filter
      ? encodeURIComponent(JSON.stringify(query?.filter))
      : null,
  };
  return http.get('/opt/username-suggest', { params });
};

export const UsernameCheck = (data) => {
  return http.post('/opt/username-check', data);
};

// ------------ API REPORT --------//

export const getReportOmset = (query) => {
  const params = {
    member: query.member,
    filter: query?.filter
      ? encodeURIComponent(JSON.stringify(query?.filter))
      : null,
  };
  return http.get('/reports/omset', { params });
};

export const getReportPPh = (params) => {
  return http.get('/reports/pph', { params });
};

export const getPerkembanganJaringan = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    cariId: query?.search,
    level: query?.level,
  };
  return http.get('/reports/tree', { params });
};

export const getPendapatan = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
  };
  return http.get('/report-pendapatan', { params });
};

export const getBonusBulanan = (query) => {
  const params = {
    id: query?.id,
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
    filter: query?.filter
      ? encodeURIComponent(JSON.stringify(query?.filter))
      : null,
  };
  return http.get('/reports/bonus-bulanan', { params });
};

export const getBonusHarian = (query) => {
  const params = {
    id: query?.id,
    filter: query?.filter
      ? encodeURIComponent(JSON.stringify(query?.filter))
      : null,
  };
  return http.get('/reports/bonus-harian', { params });
};

export const getRedemptionStatement = (query) => {
  const params = {
    id: query?.id,
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
    filter: query?.filter
      ? encodeURIComponent(JSON.stringify(query?.filter))
      : null,
  };
  return http.get('/reports/redemption-statement', { params });
};

export const getDownlineReport = (query) => {
  const params = {
    member: query.member,
    search: query?.search,
    filter: query?.filter
      ? encodeURIComponent(JSON.stringify(query?.filter))
      : null,
  };
  return http.get('/reports/downline-report', { params });
};

export const getBonus = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
    filter: query?.filter
      ? encodeURIComponent(JSON.stringify(query?.filter))
      : null,
  };
  return http.get('/bonus', { params });
};

export const getPayout = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
    filter: query?.filter
      ? encodeURIComponent(JSON.stringify(query?.filter))
      : null,
  };
  return http.get('/bonus/payout', { params });
};

export const getBonusUnpayout = () => {
  return http.get('/bonus/unpayout');
};

export const getUpgrade = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
  };
  return http.get('/upgrade', { params });
};

export const postUpgrade = (data) => {
  return http.post(`/upgrade`, data);
};

export const postPayout = (data) => {
  return http.post(`/bonus/payout`, data);
};

export const postRegister = (data) => {
  return http.post(`/register`, data);
};

export const getRegister = (data) => {
  return http.get(`/register`, { params: data });
};

export const postClaimPromo = (data) => {
  return http.post(`/promo/claim`, data);
};

export const getBannerPromo = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
  };
  return http.get('/dashboard/banner-promo', { params });
};

export const getDashboard = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
  };
  return http.get('/dashboard', { params });
};
export const getBannerOne = (query) => {
  return http.get(`/dashboard/banner/${query}`);
};

export const getMedia = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
    filter: query?.filter
      ? encodeURIComponent(JSON.stringify(query?.filter))
      : null,
  };
  return http.get('/settings/media', { params });
};

//--------------------------------//

// ------------ API Account --------//

export const getAccountProfile = (params) => {
  return http.get(`/account/${params}`);
};

export const checkMemberBonusStatus = (params) => {
  return http.get(`/bonus/status/${params}`);
};

export const getListBank = () => {
  return http.get(`/list-bank`);
};

export const getAccountAddress = () => {
  return http.get(`/shipping-address`);
};

export const putAccountProfile = (id, data) => {
  return http.put(`/account/${id}`, data);
};

export const putAccountPassword = (data) => {
  return http.put(`/account/change-password/`, data);
};
export const putKodeEtik = (id, isChecked) => {
  return http.put(`/account/kode-etik/${id}`, isChecked);
};

export const postAccountAddress = (data) => {
  return http.post(`/shipping-address`, data);
};

export const putAccountAddress = (id, data) => {
  return http.put(`/shipping-address/${id}`, data);
};
export const deleteAccountAddress = (id) => {
  return http.delete(`/shipping-address/${id}`);
};

export const putAccountRekening = (id, data) => {
  return http.put(`/account/rekening/${id}`, data);
};

export const putAccountWithdraw = (id, data) => {
  return http.put(`/account/set-withdrawals/${id}`, data);
};


export const putInformasiPewaris = (id, data) => {
  return http.put(`/account/pewaris/${id}`, data);
};

export const putAccountNpwp = (id, data) => {
  return http.put(`/account/npwp/${id}`, data);
};

export const postVerifikasiBank = (token) => {
  return http.post(`/account/validate-bank`, { token: token });
};

export const postVerifikasiNpwp = (token) => {
  return http.post(`/account/validate-npwp`, { token: token });
};

export const getResendVerfifikasiBank = (id) => {
  return http.get(`/account/resend-bank-validation/${id}`);
};

export const getResendVerfifikasiNpwp = (id) => {
  return http.get(`/account/resend-npwp-validation/${id}`);
};

//--------------------------------//

// ------------ API Setting --------//

export const getSettingUserGroup = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
  };
  return http.get('/setting-usergroup', { params });
};

export const getSettingUserAccess = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
  };
  return http.get('/setting-useraccess', { params });
};

export const putSettingUserAccess = (id, data) => {
  return http.put(`/setting-useraccess/${id}`, data);
};

export const putSettingUserGroup = (id, data) => {
  return http.put(`/setting-usergroup/${id}`, data);
};

export const postSettingUserAccess = (data) => {
  return http.post(`/setting-useraccess`, data);
};

export const postSettingUserGroup = (data) => {
  return http.post(`/setting-usergroup`, data);
};

//--------------------------------//

// ------------ API Informasi --------//

export const getInformasiList = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
  };
  return http.get('/informasi', { params });
};

export const putInformasi = (id, data) => {
  return http.put(`/informasi/${id}`, data);
};

export const postInformasi = (data) => {
  return http.post(`/informasi`, data);
};

//--------------------------------//

// ------------ API Transaction --------//

export const getBarang = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 100,
    search: query?.search,
  };
  return http.get('/product', { params });
};

export const getPaymentMethod = () => {
  return http.get('/payment-method');
};

export const getGudang = () => {
  return http.get('/gudang');
};

export const postGudangStok = (data) => {
  return http.post('/gudang', data);
};

export const getDownline = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
  };
  return http.get('/transaction/code/transfer', { params });
};

export const getTransactionOrder = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
  };
  return http.get('/transaction-order', { params });
};

export const getTransactionHistory = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search || null,
    filter: query?.filter
      ? encodeURIComponent(JSON.stringify(query?.filter))
      : null,
    orderby: query?.sort ? query?.sort.orderby : null,
    order: query?.sort ? query?.sort.order : null,
  };
  return http.get('/transaction', { params });
};

export const getTransactionKode = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
    orderby: query?.sort ? query?.sort.orderby : null,
    order: query?.sort ? query?.sort.order : null,
    filter: query?.filter ? query?.filter : null,
  };
  return http.get('/transaction/code', { params });
};

export const getRetur = (query) => {
  const params = {
    page: query?.page || 1,
    page_size: query?.page_size || 10,
    search: query?.search,
    orderby: query?.sort ? query?.sort.orderby : null,
    order: query?.sort ? query?.sort.order : null,
    filter: query?.filter ? query?.filter : null,
  };
  return http.get('/transaction/retur', { params });
};

export const getReturAble = () => {
  return http.get('/transaction/retur-able');
};

export const postRetur = (data) => {
  return http.post(`/transaction/retur`, data);
};

export const postResiRetur = (data) => {
  return http.post(`/transaction/retur/resi`, data);
};

export const getTransactionInvoice = (query) => {
  return http.get(`/transaction/${query}`);
};

export const putProcessSelesai = (id, data) => {
  return http.get(`/transaction/process-selesai/${id}`, data);
};

export const postPayment = (data) => {
  return http.post(`/payment`, data);
};

export const postPaymentAddress = (data) => {
  return http.post(`/shipping-cost`, data);
};

export const postTransaction = (data) => {
  return http.post(`/transaction`, data);
};

export const postKodeTransfer = (data) => {
  return http.post(`/transaction/code/transfer`, data);
};

export const putTransactionKode = (id, data) => {
  return http.put(`/transaction/${id}`, data);
};

//--------------------------------//

export const putUpgradeProfile = (id, data) => {
  return http.put(`/account/rekening/${id}`, data);
};

// ------------ API informasi --------//

export const getInformasi = (params) => {
  return http.get(`/news/`, { params });
};

export const postShippingAddress = (data) => {
  return http.post(`/shipping-cost`, data);
};

export const getSettings = () => {
  return http.get('/settings');
};

// ----------------  Report

export const getOptDownline = (search) => {
  const params = {
    search: search,
  };
  return http.get('/opt/downline', { params });
};

export const getSubTree = (id) => {
  const params = {
    cariId: id,
  };
  return http.get('/reports/sub-tree', { params });
};

export const getPromoOpt = (query) => {
  const params = {};
  return http.get('/promo/opt', { params });
};

export const getPromoDetail = (id, query = {}) => {
  const params = query;
  return http.get(`/promo/${id}`, { params });
};
