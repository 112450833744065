import axios from 'axios';
import { LOCAL_USER_KEY } from '../constants/keys';
import { showProgressBar } from '../redux/actions/alert';
import { showAlert } from '../redux/actions/index';
import store from '../redux/store';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/member`,
  // baseURL: `http://localhost:3001/member`,
  timeout: 300000,
});

instance.interceptors.request.use(
  (config) => {
    store.dispatch(showProgressBar(true));
    const authState = store.getState().auth;
    const token = authState.data?.access_token;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => {
    store.dispatch(showProgressBar(false));
    return response.data;
  },
  (error) => {
    store.dispatch(showProgressBar(false));
    if (error.response?.status === 401 || error.response?.status === 403) {
      console.log('errorx', error);
      localStorage.removeItem(LOCAL_USER_KEY);
      window.location = '/signin';
    } else {
      store.dispatch(
        showAlert({
          message: error.response.data.message || error.message,
          type: 'danger',
          show: true,
        })
      );
      return Promise.reject(
        error.response?.data || { message: 'Network error!' }
      );
    }
  }
);

export const downloadBlob = async (url, params, filename) => {
  try {
    // Create request
    const response = await instance({
      url,
      method: 'GET',
      params,
      responseType: 'blob',
    });

    const blobUrl = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', filename);
    document.body.appendChild(link);

    // Download file
    link.click();
    setTimeout(() => link.remove(), 1000);
  } catch (err) {
    store.dispatch(
      showAlert({
        message: 'Gagal export file',
        type: 'danger',
        show: true,
      })
    );
    // store.dispatch(showToast("Gagal unduh file! err: " + err.message, "error"));
  }
};

export default instance;
