import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import * as actions from "../../../redux/actions";
import { menus } from "../../../settings";

const Sidebar = () => {
  const dispatch = useDispatch();
  const { expandSidebar, menuActive, subMenuActive } = useSelector(
    (state) => state.menu
  );

  const setExpandSidebar = () => {
    dispatch(actions.setExpandSidebar());
  };

  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
  }, []);

  useEffect(() => {
    if (!isMobile) setExpandSidebar()
  }, [isMobile])

  return (
    <>
      <div className={`bg-overlay ${expandSidebar && 'bg-overlay-show'}`}></div>
      <div
        id="nav-wrapper"
        onClick={(e) => {
          if (e.target.id === 'nav-wrapper') setExpandSidebar();
        }}
        className={`nav-wrapper ${expandSidebar && 'nav-wrapper-show'}`}>
        <ul
          className={
            `navbar-nav bg-gradient-darkf sidebar sidebar-dark accordion` +
            (expandSidebar ? ` toggled` : ` `)
          }
          id="accordionSidebar"
        >
          {/* <!-- Sidebar - Brand --> */}
          <Link className="sidebar-brand" to="/">
            <div className="sidebar-brand-icon mb-1">
            </div>
            <div className="sidebar-brand-text"> THE FAVORED ONE</div>
          </Link>

          {/* <!-- Divider --> */}
          <hr className="sidebar-divider my-0" />

          {menus.map((menu) => {
            if (menu.hidden) return null;
            return (
              <div key={menu.name}>
                {menu.heading ? (
                  <div className="sidebar-heading">{menu.heading}</div>
                ) : (
                  ""
                )}
                <li
                  className={
                    `nav-item` + (menuActive === menu.name ? ` active` : ``)
                  }
                >
                  <Link
                    key={menu.name}
                    className={`nav-link` + (menu.subs ? ` collapsed` : ``)}
                    href="#"
                    data-toggle={menu.subs ? `collapse` : ``}
                    data-target={menu.subs ? `#` + menu.name + `Child` : ""}
                    aria-controls="collapseTwo"
                    to={menu.path}
                    onClick={() => {
                      if (!menu?.subs && isMobile) setExpandSidebar();
                    }}
                  // onClick={!menu.subs ? () => menu.callback(menu.name, '') : null}
                  >
                    <i className={`fas fa-fw ` + menu.icon}></i>
                    <span>{menu.label}</span>
                  </Link>
                  {menu.subs ? (
                    <div
                      id={menu.name + `Child`}
                      className={
                        `collapse` + (menuActive === menu.name ? ` show` : ``)
                      }
                      aria-labelledby="headingTwo"
                      data-parent="#accordionSidebar"
                    >
                      <div className="bg-white py-2 collapse-inner rounded">
                        {menu.subs.map((sub) => {
                          if (sub.hidden) return "";
                          return (
                            <Link
                              key={sub.name}
                              className={
                                `collapse-item` +
                                (subMenuActive === sub.name ? ` active` : ``)
                              }
                              to={sub.path}
                              onClick={() => {
                                if (isMobile) setExpandSidebar();
                              }}
                            >
                              {sub.label}
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </li>
                {menu.devider ? <hr className="sidebar-divider" /> : ""}
              </div>
            );
          })}

          {/* <hr className="sidebar-divider" /> */}
          <div className="text-center d-none d-md-inline">
            {/* <button
              onClick={() => {
                setExpandSidebar();
              }}
              className="rounded-circle border-0"
              id="sidebarToggle"
            ></button> */}
          </div>
        </ul>

      </div>
    </>
  );
};

export default Sidebar;
