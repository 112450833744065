import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import BinaryTree from "./lib/BinaryTree";
const PohonTree = ({
  downlineOpts,
  data,
  setCariId,
  setQuery,
  downlineKanan,
  downlineKiri,
  upline,
}) => {
  // console.log("yyy test");

  const gTreeRef = useRef();
  const gTreeRefWrap = useRef();

  useEffect(() => {
    if (gTreeRef.current) {
      const sto = setTimeout(() => {
        const x =
          (gTreeRef.current.scrollWidth - gTreeRef.current.offsetWidth) / 2;
        gTreeRef.current.scrollLeft += x;
      }, 100);
      return () => clearTimeout(sto);
    }
  }, [gTreeRef]);

  return (
    <div ref={gTreeRefWrap}>
      <div className="d-block d-lg-flex p-3">
        <div className="d-flex flex-column flex-md-row mb-2 mb-md:0">
          <div style={{ width: "250px", maxWidth: "100%" }}>
            <Select
              placeholder="Cari ID/Nama"
              defaultOptions
              cacheOptions
              onChange={({ value }) => setCariId(value)}
              onInputChange={(val) => {
                console.log(val, "yyy");
                setQuery(val);
              }}
              options={downlineOpts}
            />
          </div>
        </div>
        <div className="w-100 mb-2">
          <div className="d-flex justify-content-start justify-content-lg-end flex-row w-100">
            <button
              className="btn btn-sm btn-primary-f mx-1 px-2"
              onClick={() => setCariId(downlineKiri)}
              disabled={data?.[0]?.downlinekiri_id_member ? false : true}
            >
              DownL
            </button>
            <button
              className="btn btn-sm btn-primary-f mx-1 px-2"
              onClick={() => setCariId(upline)}
              disabled={data?.[0]?.root ? true : false}
            >
              UP
            </button>

            <button
              className="btn btn-sm btn-primary-f mx-1 px-2"
              onClick={() => setCariId(downlineKanan)}
              disabled={data?.[0]?.downlinekanan_id_member ? false : true}
            >
              DownR
            </button>
          </div>
        </div>
      </div>
      <div
        ref={gTreeRef}
        style={{ userSelect: "none" }}
        className="genealogy-body genealogy-scroll"
      >
        {data && (
          <BinaryTree
            allUsers={data}
            searchFunction={(data) => setCariId(data)}
            rootUser={data?.[0]}
            level={3}
          />
        )}
      </div>
    </div>
  );
};

export default PohonTree;
