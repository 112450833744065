export const arr_propinsi = [
  { value: 1, label: "Bali" },
  { value: 2, label: "Bangka Belitung" },
  { value: 3, label: "Banten" },
  { value: 4, label: "Bengkulu" },
  { value: 5, label: "DI Yogyakarta" },
  { value: 6, label: "DKI Jakarta" },
  { value: 7, label: "Gorontalo" },
  { value: 8, label: "Jambi" },
  { value: 9, label: "Jawa Barat" },
  { value: 10, label: "Jawa Tengah" },
  { value: 11, label: "Jawa Timur" },
  { value: 12, label: "Kalimantan Barat" },
  { value: 13, label: "Kalimantan Selatan" },
  { value: 14, label: "Kalimantan Tengah" },
  { value: 15, label: "Kalimantan Timur" },
  { value: 16, label: "Kalimantan Utara" },
  { value: 17, label: "Kepulauan Riau" },
  { value: 18, label: "Lampung" },
  { value: 19, label: "Maluku" },
  { value: 20, label: "Maluku Utara" },
  { value: 21, label: "Nanggroe Aceh Darussalam (NAD)" },
  { value: 22, label: "Nusa Tenggara Barat (NTB)" },
  { value: 23, label: "Nusa Tenggara Timur (NTT)" },
  { value: 24, label: "Papua" },
  { value: 25, label: "Papua Barat" },
  { value: 26, label: "Riau" },
  { value: 27, label: "Sulawesi Barat" },
  { value: 28, label: "Sulawesi Selatan" },
  { value: 29, label: "Sulawesi Tengah" },
  { value: 30, label: "Sulawesi Tenggara" },
  { value: 31, label: "Sulawesi Utara" },
  { value: 32, label: "Sumatera Barat" },
  { value: 33, label: "Sumatera Selatan" },
  { value: 34, label: "Sumatera Utara" },
];

export const arr_kabupaten = [
  { value: 1, label: "Badung", prop: 1 },
  { value: 2, label: "Bangli", prop: 1 },
  { value: 3, label: "Buleleng", prop: 1 },
  { value: 4, label: "Denpasar", prop: 1 },
  { value: 5, label: "Gianyar", prop: 1 },
  { value: 6, label: "Jembrana", prop: 1 },
  { value: 7, label: "Karangasem", prop: 1 },
  { value: 8, label: "Klungkung", prop: 1 },
  { value: 9, label: "Tabanan", prop: 1 },
  { value: 10, label: "Bangka", prop: 2 },
  { value: 11, label: "Bangka Barat", prop: 2 },
  { value: 12, label: "Bangka Selatan", prop: 2 },
  { value: 13, label: "Bangka Tengah", prop: 2 },
  { value: 14, label: "Belitung", prop: 2 },
  { value: 15, label: "Belitung Timur", prop: 2 },
  { value: 16, label: "Pangkal Pinang", prop: 2 },
  { value: 17, label: "Cilegon", prop: 3 },
  { value: 18, label: "Lebak", prop: 3 },
  { value: 19, label: "Pandeglang", prop: 3 },
  { value: 20, label: "Serang", prop: 3 },
  { value: 21, label: "Tangerang", prop: 3 },
  { value: 22, label: "Tangerang Selatan", prop: 3 },
  { value: 23, label: "Bengkulu", prop: 4 },
  { value: 24, label: "Bengkulu Selatan", prop: 4 },
  { value: 25, label: "Bengkulu Tengah", prop: 4 },
  { value: 26, label: "Bengkulu Utara", prop: 4 },
  { value: 27, label: "Kaur", prop: 4 },
  { value: 28, label: "Kepahiang", prop: 4 },
  { value: 29, label: "Lebong", prop: 4 },
  { value: 30, label: "Muko Muko", prop: 4 },
  { value: 31, label: "Rejang Lebong", prop: 4 },
  { value: 32, label: "Seluma", prop: 4 },
  { value: 33, label: "Bantul", prop: 5 },
  { value: 34, label: "Gunung Kidul", prop: 5 },
  { value: 35, label: "Kulon Progo", prop: 5 },
  { value: 36, label: "Sleman", prop: 5 },
  { value: 37, label: "Yogyakarta", prop: 5 },
  { value: 38, label: "Jakarta Barat", prop: 6 },
  { value: 39, label: "Jakarta Pusat", prop: 6 },
  { value: 40, label: "Jakarta Selatan", prop: 6 },
  { value: 41, label: "Jakarta Timur", prop: 6 },
  { value: 42, label: "Jakarta Utara", prop: 6 },
  { value: 43, label: "Kepulauan Seribu", prop: 6 },
  { value: 44, label: "Boalemo", prop: 7 },
  { value: 45, label: "Bone Bolango", prop: 7 },
  { value: 46, label: "Gorontalo", prop: 7 },
  { value: 47, label: "Gorontalo Utara", prop: 7 },
  { value: 48, label: "Pohuwato", prop: 7 },
  { value: 49, label: "Batang Hari", prop: 8 },
  { value: 50, label: "Bungo", prop: 8 },
  { value: 51, label: "Jambi", prop: 8 },
  { value: 52, label: "Kerinci", prop: 8 },
  { value: 53, label: "Merangin", prop: 8 },
  { value: 54, label: "Muaro Jambi", prop: 8 },
  { value: 55, label: "Sarolangun", prop: 8 },
  { value: 56, label: "Sungaipenuh", prop: 8 },
  { value: 57, label: "Tanjung Jabung Barat", prop: 8 },
  { value: 58, label: "Tanjung Jabung Timur", prop: 8 },
  { value: 59, label: "Tebo", prop: 8 },
  { value: 60, label: "Bandung", prop: 9 },
  { value: 61, label: "Bandung Barat", prop: 9 },
  { value: 62, label: "Banjar", prop: 9 },
  { value: 63, label: "Bekasi", prop: 9 },
  { value: 64, label: "Bogor", prop: 9 },
  { value: 65, label: "Ciamis", prop: 9 },
  { value: 66, label: "Cianjur", prop: 9 },
  { value: 67, label: "Cimahi", prop: 9 },
  { value: 68, label: "Cirebon", prop: 9 },
  { value: 69, label: "Depok", prop: 9 },
  { value: 70, label: "Garut", prop: 9 },
  { value: 71, label: "Indramayu", prop: 9 },
  { value: 72, label: "Karawang", prop: 9 },
  { value: 73, label: "Kuningan", prop: 9 },
  { value: 74, label: "Majalengka", prop: 9 },
  { value: 75, label: "Pangandaran", prop: 9 },
  { value: 76, label: "Purwakarta", prop: 9 },
  { value: 77, label: "Subang", prop: 9 },
  { value: 78, label: "Sukabumi", prop: 9 },
  { value: 79, label: "Sumedang", prop: 9 },
  { value: 80, label: "Tasikmalaya", prop: 9 },
  { value: 81, label: "Banjarnegara", prop: 10 },
  { value: 82, label: "Banyumas", prop: 10 },
  { value: 83, label: "Batang", prop: 10 },
  { value: 84, label: "Blora", prop: 10 },
  { value: 85, label: "Boyolali", prop: 10 },
  { value: 86, label: "Brebes", prop: 10 },
  { value: 87, label: "Cilacap", prop: 10 },
  { value: 88, label: "Demak", prop: 10 },
  { value: 89, label: "Grobogan", prop: 10 },
  { value: 90, label: "Jepara", prop: 10 },
  { value: 91, label: "Karanganyar", prop: 10 },
  { value: 92, label: "Kebumen", prop: 10 },
  { value: 93, label: "Kendal", prop: 10 },
  { value: 94, label: "Klaten", prop: 10 },
  { value: 95, label: "Kudus", prop: 10 },
  { value: 96, label: "Magelang", prop: 10 },
  { value: 97, label: "Pati", prop: 10 },
  { value: 98, label: "Pekalongan", prop: 10 },
  { value: 99, label: "Pemalang", prop: 10 },
  { value: 100, label: "Purbalingga", prop: 10 },
  { value: 101, label: "Purworejo", prop: 10 },
  { value: 102, label: "Rembang", prop: 10 },
  { value: 103, label: "Salatiga", prop: 10 },
  { value: 104, label: "Semarang", prop: 10 },
  { value: 105, label: "Sragen", prop: 10 },
  { value: 106, label: "Sukoharjo", prop: 10 },
  { value: 107, label: "Surakarta (Solo)", prop: 10 },
  { value: 108, label: "Tegal", prop: 10 },
  { value: 109, label: "Temanggung", prop: 10 },
  { value: 110, label: "Wonogiri", prop: 10 },
  { value: 111, label: "Wonosobo", prop: 10 },
  { value: 112, label: "Bangkalan", prop: 11 },
  { value: 113, label: "Banyuwangi", prop: 11 },
  { value: 114, label: "Batu", prop: 11 },
  { value: 115, label: "Blitar", prop: 11 },
  { value: 116, label: "Bojonegoro", prop: 11 },
  { value: 117, label: "Bondowoso", prop: 11 },
  { value: 118, label: "Gresik", prop: 11 },
  { value: 119, label: "Jember", prop: 11 },
  { value: 120, label: "Jombang", prop: 11 },
  { value: 121, label: "Kediri", prop: 11 },
  { value: 122, label: "Lamongan", prop: 11 },
  { value: 123, label: "Lumajang", prop: 11 },
  { value: 124, label: "Madiun", prop: 11 },
  { value: 125, label: "Magetan", prop: 11 },
  { value: 126, label: "Malang", prop: 11 },
  { value: 127, label: "Mojokerto", prop: 11 },
  { value: 128, label: "Nganjuk", prop: 11 },
  { value: 129, label: "Ngawi", prop: 11 },
  { value: 130, label: "Pacitan", prop: 11 },
  { value: 131, label: "Pamekasan", prop: 11 },
  { value: 132, label: "Pasuruan", prop: 11 },
  { value: 133, label: "Ponorogo", prop: 11 },
  { value: 134, label: "Probolinggo", prop: 11 },
  { value: 135, label: "Sampang", prop: 11 },
  { value: 136, label: "Sidoarjo", prop: 11 },
  { value: 137, label: "Situbondo", prop: 11 },
  { value: 138, label: "Sumenep", prop: 11 },
  { value: 139, label: "Surabaya", prop: 11 },
  { value: 140, label: "Trenggalek", prop: 11 },
  { value: 141, label: "Tuban", prop: 11 },
  { value: 142, label: "Tulungagung", prop: 11 },
  { value: 143, label: "Bengkayang", prop: 12 },
  { value: 144, label: "Kapuas Hulu", prop: 12 },
  { value: 145, label: "Kayong Utara", prop: 12 },
  { value: 146, label: "Ketapang", prop: 12 },
  { value: 147, label: "Kubu Raya", prop: 12 },
  { value: 148, label: "Landak", prop: 12 },
  { value: 149, label: "Melawi", prop: 12 },
  { value: 150, label: "Pontianak", prop: 12 },
  { value: 151, label: "Sambas", prop: 12 },
  { value: 152, label: "Sanggau", prop: 12 },
  { value: 153, label: "Sekadau", prop: 12 },
  { value: 154, label: "Singkawang", prop: 12 },
  { value: 155, label: "Sintang", prop: 12 },
  { value: 156, label: "Balangan", prop: 13 },
  { value: 157, label: "Banjar", prop: 13 },
  { value: 158, label: "Banjarbaru", prop: 13 },
  { value: 159, label: "Banjarmasin", prop: 13 },
  { value: 160, label: "Barito Kuala", prop: 13 },
  { value: 161, label: "Hulu Sungai Selatan", prop: 13 },
  { value: 162, label: "Hulu Sungai Tengah", prop: 13 },
  { value: 163, label: "Hulu Sungai Utara", prop: 13 },
  { value: 164, label: "Kotabaru", prop: 13 },
  { value: 165, label: "Tabalong", prop: 13 },
  { value: 166, label: "Tanah Bumbu", prop: 13 },
  { value: 167, label: "Tanah Laut", prop: 13 },
  { value: 168, label: "Tapin", prop: 13 },
  { value: 169, label: "Barito Selatan", prop: 14 },
  { value: 170, label: "Barito Timur", prop: 14 },
  { value: 171, label: "Barito Utara", prop: 14 },
  { value: 172, label: "Gunung Mas", prop: 14 },
  { value: 173, label: "Kapuas", prop: 14 },
  { value: 174, label: "Katingan", prop: 14 },
  { value: 175, label: "Kotawaringin Barat", prop: 14 },
  { value: 176, label: "Kotawaringin Timur", prop: 14 },
  { value: 177, label: "Lamandau", prop: 14 },
  { value: 178, label: "Murung Raya", prop: 14 },
  { value: 179, label: "Palangka Raya", prop: 14 },
  { value: 180, label: "Pulang Pisau", prop: 14 },
  { value: 181, label: "Seruyan", prop: 14 },
  { value: 182, label: "Sukamara", prop: 14 },
  { value: 183, label: "Balikpapan", prop: 15 },
  { value: 184, label: "Berau", prop: 15 },
  { value: 185, label: "Bontang", prop: 15 },
  { value: 186, label: "Kutai Barat", prop: 15 },
  { value: 187, label: "Kutai Kartanegara", prop: 15 },
  { value: 188, label: "Kutai Timur", prop: 15 },
  { value: 189, label: "Paser", prop: 15 },
  { value: 190, label: "Penajam Paser Utara", prop: 15 },
  { value: 191, label: "Samarinda", prop: 15 },
  { value: 192, label: "Bulungan (Bulongan)", prop: 16 },
  { value: 193, label: "Malinau", prop: 16 },
  { value: 194, label: "Nunukan", prop: 16 },
  { value: 195, label: "Tana Tidung", prop: 16 },
  { value: 196, label: "Tarakan", prop: 16 },
  { value: 197, label: "Batam", prop: 17 },
  { value: 198, label: "Bintan", prop: 17 },
  { value: 199, label: "Karimun", prop: 17 },
  { value: 200, label: "Kepulauan Anambas", prop: 17 },
  { value: 201, label: "Lingga", prop: 17 },
  { value: 202, label: "Natuna", prop: 17 },
  { value: 203, label: "Tanjung Pinang", prop: 17 },
  { value: 204, label: "Bandar Lampung", prop: 18 },
  { value: 205, label: "Lampung Barat", prop: 18 },
  { value: 206, label: "Lampung Selatan", prop: 18 },
  { value: 207, label: "Lampung Tengah", prop: 18 },
  { value: 208, label: "Lampung Timur", prop: 18 },
  { value: 209, label: "Lampung Utara", prop: 18 },
  { value: 210, label: "Mesuji", prop: 18 },
  { value: 211, label: "Metro", prop: 18 },
  { value: 212, label: "Pesawaran", prop: 18 },
  { value: 213, label: "Pesisir Barat", prop: 18 },
  { value: 214, label: "Pringsewu", prop: 18 },
  { value: 215, label: "Tanggamus", prop: 18 },
  { value: 216, label: "Tulang Bawang", prop: 18 },
  { value: 217, label: "Tulang Bawang Barat", prop: 18 },
  { value: 218, label: "Way Kanan", prop: 18 },
  { value: 219, label: "Ambon", prop: 19 },
  { value: 220, label: "Buru", prop: 19 },
  { value: 221, label: "Buru Selatan", prop: 19 },
  { value: 222, label: "Kepulauan Aru", prop: 19 },
  { value: 223, label: "Maluku Barat Daya", prop: 19 },
  { value: 224, label: "Maluku Tengah", prop: 19 },
  { value: 225, label: "Maluku Tenggara", prop: 19 },
  { value: 226, label: "Maluku Tenggara Barat", prop: 19 },
  { value: 227, label: "Seram Bagian Barat", prop: 19 },
  { value: 228, label: "Seram Bagian Timur", prop: 19 },
  { value: 229, label: "Tual", prop: 19 },
  { value: 230, label: "Halmahera Barat", prop: 20 },
  { value: 231, label: "Halmahera Selatan", prop: 20 },
  { value: 232, label: "Halmahera Tengah", prop: 20 },
  { value: 233, label: "Halmahera Timur", prop: 20 },
  { value: 234, label: "Halmahera Utara", prop: 20 },
  { value: 235, label: "Kepulauan Sula", prop: 20 },
  { value: 236, label: "Pulau Morotai", prop: 20 },
  { value: 237, label: "Ternate", prop: 20 },
  { value: 238, label: "Tidore Kepulauan", prop: 20 },
  { value: 239, label: "Aceh Barat", prop: 21 },
  { value: 240, label: "Aceh Barat Daya", prop: 21 },
  { value: 241, label: "Aceh Besar", prop: 21 },
  { value: 242, label: "Aceh Jaya", prop: 21 },
  { value: 243, label: "Aceh Selatan", prop: 21 },
  { value: 244, label: "Aceh Singkil", prop: 21 },
  { value: 245, label: "Aceh Tamiang", prop: 21 },
  { value: 246, label: "Aceh Tengah", prop: 21 },
  { value: 247, label: "Aceh Tenggara", prop: 21 },
  { value: 248, label: "Aceh Timur", prop: 21 },
  { value: 249, label: "Aceh Utara", prop: 21 },
  { value: 250, label: "Banda Aceh", prop: 21 },
  { value: 251, label: "Bener Meriah", prop: 21 },
  { value: 252, label: "Bireuen", prop: 21 },
  { value: 253, label: "Gayo Lues", prop: 21 },
  { value: 254, label: "Langsa", prop: 21 },
  { value: 255, label: "Lhokseumawe", prop: 21 },
  { value: 256, label: "Nagan Raya", prop: 21 },
  { value: 257, label: "Pidie", prop: 21 },
  { value: 258, label: "Pidie Jaya", prop: 21 },
  { value: 259, label: "Sabang", prop: 21 },
  { value: 260, label: "Simeulue", prop: 21 },
  { value: 261, label: "Subulussalam", prop: 21 },
  { value: 262, label: "Bima", prop: 22 },
  { value: 263, label: "Dompu", prop: 22 },
  { value: 264, label: "Lombok Barat", prop: 22 },
  { value: 265, label: "Lombok Tengah", prop: 22 },
  { value: 266, label: "Lombok Timur", prop: 22 },
  { value: 267, label: "Lombok Utara", prop: 22 },
  { value: 268, label: "Mataram", prop: 22 },
  { value: 269, label: "Sumbawa", prop: 22 },
  { value: 270, label: "Sumbawa Barat", prop: 22 },
  { value: 271, label: "Alor", prop: 23 },
  { value: 272, label: "Belu", prop: 23 },
  { value: 273, label: "Ende", prop: 23 },
  { value: 274, label: "Flores Timur", prop: 23 },
  { value: 275, label: "Kupang", prop: 23 },
  { value: 276, label: "Lembata", prop: 23 },
  { value: 277, label: "Manggarai", prop: 23 },
  { value: 278, label: "Manggarai Barat", prop: 23 },
  { value: 279, label: "Manggarai Timur", prop: 23 },
  { value: 280, label: "Nagekeo", prop: 23 },
  { value: 281, label: "Ngada", prop: 23 },
  { value: 282, label: "Rote Ndao", prop: 23 },
  { value: 283, label: "Sabu Raijua", prop: 23 },
  { value: 284, label: "Sikka", prop: 23 },
  { value: 285, label: "Sumba Barat", prop: 23 },
  { value: 286, label: "Sumba Barat Daya", prop: 23 },
  { value: 287, label: "Sumba Tengah", prop: 23 },
  { value: 288, label: "Sumba Timur", prop: 23 },
  { value: 289, label: "Timor Tengah Selatan", prop: 23 },
  { value: 290, label: "Timor Tengah Utara", prop: 23 },
  { value: 291, label: "Asmat", prop: 24 },
  { value: 292, label: "Biak Numfor", prop: 24 },
  { value: 293, label: "Boven Digoel", prop: 24 },
  { value: 294, label: "Deiyai (Deliyai)", prop: 24 },
  { value: 295, label: "Dogiyai", prop: 24 },
  { value: 296, label: "Intan Jaya", prop: 24 },
  { value: 297, label: "Jayapura", prop: 24 },
  { value: 298, label: "Jayawijaya", prop: 24 },
  { value: 299, label: "Keerom", prop: 24 },
  { value: 300, label: "Kepulauan Yapen (Yapen Waropen)", prop: 24 },
  { value: 301, label: "Lanny Jaya", prop: 24 },
  { value: 302, label: "Mamberamo Raya", prop: 24 },
  { value: 303, label: "Mamberamo Tengah", prop: 24 },
  { value: 304, label: "Mappi", prop: 24 },
  { value: 305, label: "Merauke", prop: 24 },
  { value: 306, label: "Mimika", prop: 24 },
  { value: 307, label: "Nabire", prop: 24 },
  { value: 308, label: "Nduga", prop: 24 },
  { value: 309, label: "Paniai", prop: 24 },
  { value: 310, label: "Pegunungan Bintang", prop: 24 },
  { value: 311, label: "Puncak", prop: 24 },
  { value: 312, label: "Puncak Jaya", prop: 24 },
  { value: 313, label: "Sarmi", prop: 24 },
  { value: 314, label: "Supiori", prop: 24 },
  { value: 315, label: "Tolikara", prop: 24 },
  { value: 316, label: "Waropen", prop: 24 },
  { value: 317, label: "Yahukimo", prop: 24 },
  { value: 318, label: "Yalimo", prop: 24 },
  { value: 319, label: "Fakfak", prop: 25 },
  { value: 320, label: "Kaimana", prop: 25 },
  { value: 321, label: "Manokwari", prop: 25 },
  { value: 322, label: "Manokwari Selatan", prop: 25 },
  { value: 323, label: "Maybrat", prop: 25 },
  { value: 324, label: "Pegunungan Arfak", prop: 25 },
  { value: 325, label: "Raja Ampat", prop: 25 },
  { value: 326, label: "Sorong", prop: 25 },
  { value: 327, label: "Sorong Selatan", prop: 25 },
  { value: 328, label: "Tambrauw", prop: 25 },
  { value: 329, label: "Teluk Bintuni", prop: 25 },
  { value: 330, label: "Teluk Wondama", prop: 25 },
  { value: 331, label: "Bengkalis", prop: 26 },
  { value: 332, label: "Dumai", prop: 26 },
  { value: 333, label: "Indragiri Hilir", prop: 26 },
  { value: 334, label: "Indragiri Hulu", prop: 26 },
  { value: 335, label: "Kampar", prop: 26 },
  { value: 336, label: "Kepulauan Meranti", prop: 26 },
  { value: 337, label: "Kuantan Singingi", prop: 26 },
  { value: 338, label: "Pekanbaru", prop: 26 },
  { value: 339, label: "Pelalawan", prop: 26 },
  { value: 340, label: "Rokan Hilir", prop: 26 },
  { value: 341, label: "Rokan Hulu", prop: 26 },
  { value: 342, label: "Siak", prop: 26 },
  { value: 343, label: "Majene", prop: 27 },
  { value: 344, label: "Mamasa", prop: 27 },
  { value: 345, label: "Mamuju", prop: 27 },
  { value: 346, label: "Mamuju Utara", prop: 27 },
  { value: 347, label: "Polewali Mandar", prop: 27 },
  { value: 348, label: "Bantaeng", prop: 28 },
  { value: 349, label: "Barru", prop: 28 },
  { value: 350, label: "Bone", prop: 28 },
  { value: 351, label: "Bulukumba", prop: 28 },
  { value: 352, label: "Enrekang", prop: 28 },
  { value: 353, label: "Gowa", prop: 28 },
  { value: 354, label: "Jeneponto", prop: 28 },
  { value: 355, label: "Luwu", prop: 28 },
  { value: 356, label: "Luwu Timur", prop: 28 },
  { value: 357, label: "Luwu Utara", prop: 28 },
  { value: 358, label: "Makassar", prop: 28 },
  { value: 359, label: "Maros", prop: 28 },
  { value: 360, label: "Palopo", prop: 28 },
  { value: 361, label: "Pangkajene Kepulauan", prop: 28 },
  { value: 362, label: "Parepare", prop: 28 },
  { value: 363, label: "Pinrang", prop: 28 },
  { value: 364, label: "Selayar (Kepulauan Selayar)", prop: 28 },
  { value: 365, label: "Sidenreng Rappang/Rapang", prop: 28 },
  { value: 366, label: "Sinjai", prop: 28 },
  { value: 367, label: "Soppeng", prop: 28 },
  { value: 368, label: "Takalar", prop: 28 },
  { value: 369, label: "Tana Toraja", prop: 28 },
  { value: 370, label: "Toraja Utara", prop: 28 },
  { value: 371, label: "Wajo", prop: 28 },
  { value: 372, label: "Banggai", prop: 29 },
  { value: 373, label: "Banggai Kepulauan", prop: 29 },
  { value: 374, label: "Buol", prop: 29 },
  { value: 375, label: "Donggala", prop: 29 },
  { value: 376, label: "Morowali", prop: 29 },
  { value: 377, label: "Palu", prop: 29 },
  { value: 378, label: "Parigi Moutong", prop: 29 },
  { value: 379, label: "Poso", prop: 29 },
  { value: 380, label: "Sigi", prop: 29 },
  { value: 381, label: "Tojo Una-Una", prop: 29 },
  { value: 382, label: "Toli-Toli", prop: 29 },
  { value: 383, label: "Bau-Bau", prop: 30 },
  { value: 384, label: "Bombana", prop: 30 },
  { value: 385, label: "Buton", prop: 30 },
  { value: 386, label: "Buton Utara", prop: 30 },
  { value: 387, label: "Kendari", prop: 30 },
  { value: 388, label: "Kolaka", prop: 30 },
  { value: 389, label: "Kolaka Utara", prop: 30 },
  { value: 390, label: "Konawe", prop: 30 },
  { value: 391, label: "Konawe Selatan", prop: 30 },
  { value: 392, label: "Konawe Utara", prop: 30 },
  { value: 393, label: "Muna", prop: 30 },
  { value: 394, label: "Wakatobi", prop: 30 },
  { value: 395, label: "Bitung", prop: 31 },
  { value: 396, label: "Bolaang Mongondow (Bolmong)", prop: 31 },
  { value: 397, label: "Bolaang Mongondow Selatan", prop: 31 },
  { value: 398, label: "Bolaang Mongondow Timur", prop: 31 },
  { value: 399, label: "Bolaang Mongondow Utara", prop: 31 },
  { value: 400, label: "Kepulauan Sangihe", prop: 31 },
  { value: 401, label: "Kepulauan Siau Tagulandang Biaro (Sitaro)", prop: 31 },
  { value: 402, label: "Kepulauan Talaud", prop: 31 },
  { value: 403, label: "Kotamobagu", prop: 31 },
  { value: 404, label: "Manado", prop: 31 },
  { value: 405, label: "Minahasa", prop: 31 },
  { value: 406, label: "Minahasa Selatan", prop: 31 },
  { value: 407, label: "Minahasa Tenggara", prop: 31 },
  { value: 408, label: "Minahasa Utara", prop: 31 },
  { value: 409, label: "Tomohon", prop: 31 },
  { value: 410, label: "Agam", prop: 32 },
  { value: 411, label: "Bukittinggi", prop: 32 },
  { value: 412, label: "Dharmasraya", prop: 32 },
  { value: 413, label: "Kepulauan Mentawai", prop: 32 },
  { value: 414, label: "Lima Puluh Koto/Kota", prop: 32 },
  { value: 415, label: "Padang", prop: 32 },
  { value: 416, label: "Padang Panjang", prop: 32 },
  { value: 417, label: "Padang Pariaman", prop: 32 },
  { value: 418, label: "Pariaman", prop: 32 },
  { value: 419, label: "Pasaman", prop: 32 },
  { value: 420, label: "Pasaman Barat", prop: 32 },
  { value: 421, label: "Payakumbuh", prop: 32 },
  { value: 422, label: "Pesisir Selatan", prop: 32 },
  { value: 423, label: "Sawah Lunto", prop: 32 },
  { value: 424, label: "Sijunjung (Sawah Lunto Sijunjung)", prop: 32 },
  { value: 425, label: "Solok", prop: 32 },
  { value: 426, label: "Solok Selatan", prop: 32 },
  { value: 427, label: "Tanah Datar", prop: 32 },
  { value: 428, label: "Banyuasin", prop: 33 },
  { value: 429, label: "Empat Lawang", prop: 33 },
  { value: 430, label: "Lahat", prop: 33 },
  { value: 431, label: "Lubuk Linggau", prop: 33 },
  { value: 432, label: "Muara Enim", prop: 33 },
  { value: 433, label: "Musi Banyuasin", prop: 33 },
  { value: 434, label: "Musi Rawas", prop: 33 },
  { value: 435, label: "Ogan Ilir", prop: 33 },
  { value: 436, label: "Ogan Komering Ilir", prop: 33 },
  { value: 437, label: "Ogan Komering Ulu", prop: 33 },
  { value: 438, label: "Ogan Komering Ulu Selatan", prop: 33 },
  { value: 439, label: "Ogan Komering Ulu Timur", prop: 33 },
  { value: 440, label: "Pagar Alam", prop: 33 },
  { value: 441, label: "Palembang", prop: 33 },
  { value: 442, label: "Prabumulih", prop: 33 },
  { value: 443, label: "Asahan", prop: 34 },
  { value: 444, label: "Batu Bara", prop: 34 },
  { value: 445, label: "Binjai", prop: 34 },
  { value: 446, label: "Dairi", prop: 34 },
  { value: 447, label: "Deli Serdang", prop: 34 },
  { value: 448, label: "Gunungsitoli", prop: 34 },
  { value: 449, label: "Humbang Hasundutan", prop: 34 },
  { value: 450, label: "Karo", prop: 34 },
  { value: 451, label: "Labuhan Batu", prop: 34 },
  { value: 452, label: "Labuhan Batu Selatan", prop: 34 },
  { value: 453, label: "Labuhan Batu Utara", prop: 34 },
  { value: 454, label: "Langkat", prop: 34 },
  { value: 455, label: "Mandailing Natal", prop: 34 },
  { value: 456, label: "Medan", prop: 34 },
  { value: 457, label: "Nias", prop: 34 },
  { value: 458, label: "Nias Barat", prop: 34 },
  { value: 459, label: "Nias Selatan", prop: 34 },
  { value: 460, label: "Nias Utara", prop: 34 },
  { value: 461, label: "Padang Lawas", prop: 34 },
  { value: 462, label: "Padang Lawas Utara", prop: 34 },
  { value: 463, label: "Padang Sidempuan", prop: 34 },
  { value: 464, label: "Pakpak Bharat", prop: 34 },
  { value: 465, label: "Pematang Siantar", prop: 34 },
  { value: 466, label: "Samosir", prop: 34 },
  { value: 467, label: "Serdang Bedagai", prop: 34 },
  { value: 468, label: "Sibolga", prop: 34 },
  { value: 469, label: "Simalungun", prop: 34 },
  { value: 470, label: "Tanjung Balai", prop: 34 },
  { value: 471, label: "Tapanuli Selatan", prop: 34 },
  { value: 472, label: "Tapanuli Tengah", prop: 34 },
  { value: 473, label: "Tapanuli Utara", prop: 34 },
  { value: 474, label: "Tebing Tinggi", prop: 34 },
  { value: 475, label: "Toba Samosir", prop: 34 },
];

export const arr_kecamatan = [
  { value: 258, label: "Abiansemal", kab: 1, prop: 1 },
  { value: 259, label: "Kuta", kab: 1, prop: 1 },
  { value: 260, label: "Kuta Selatan", kab: 1, prop: 1 },
  { value: 261, label: "Kuta Utara", kab: 1, prop: 1 },
  { value: 262, label: "Mengwi", kab: 1, prop: 1 },
  { value: 263, label: "Petang", kab: 1, prop: 1 },
  { value: 472, label: "Bangli", kab: 2, prop: 1 },
  { value: 473, label: "Kintamani", kab: 2, prop: 1 },
  { value: 474, label: "Susut", kab: 2, prop: 1 },
  { value: 475, label: "Tembuku", kab: 2, prop: 1 },
  { value: 1279, label: "Banjar", kab: 3, prop: 1 },
  { value: 1280, label: "Buleleng", kab: 3, prop: 1 },
  { value: 1281, label: "Busungbiu", kab: 3, prop: 1 },
  { value: 1282, label: "Gerokgak", kab: 3, prop: 1 },
  { value: 1283, label: "Kubutambahan", kab: 3, prop: 1 },
  { value: 1284, label: "Sawan", kab: 3, prop: 1 },
  { value: 1285, label: "Seririt", kab: 3, prop: 1 },
  { value: 1286, label: "Sukasada", kab: 3, prop: 1 },
  { value: 1287, label: "Tejakula", kab: 3, prop: 1 },
  { value: 1573, label: "Denpasar Barat", kab: 4, prop: 1 },
  { value: 1574, label: "Denpasar Selatan", kab: 4, prop: 1 },
  { value: 1575, label: "Denpasar Timur", kab: 4, prop: 1 },
  { value: 1576, label: "Denpasar Utara", kab: 4, prop: 1 },
  { value: 1764, label: "Belah Batuh (Blahbatuh)", kab: 5, prop: 1 },
  { value: 1765, label: "Gianyar", kab: 5, prop: 1 },
  { value: 1766, label: "Payangan", kab: 5, prop: 1 },
  { value: 1767, label: "Sukawati", kab: 5, prop: 1 },
  { value: 1768, label: "Tampak Siring", kab: 5, prop: 1 },
  { value: 1769, label: "Tegallalang", kab: 5, prop: 1 },
  { value: 1770, label: "Ubud", kab: 5, prop: 1 },
  { value: 2232, label: "Jembrana", kab: 6, prop: 1 },
  { value: 2233, label: "Melaya", kab: 6, prop: 1 },
  { value: 2234, label: "Mendoyo", kab: 6, prop: 1 },
  { value: 2235, label: "Negara", kab: 6, prop: 1 },
  { value: 2236, label: "Pekutatan", kab: 6, prop: 1 },
  { value: 2370, label: "Abang", kab: 7, prop: 1 },
  { value: 2371, label: "Bebandem", kab: 7, prop: 1 },
  { value: 2372, label: "Karang Asem", kab: 7, prop: 1 },
  { value: 2373, label: "Kubu", kab: 7, prop: 1 },
  { value: 2374, label: "Manggis", kab: 7, prop: 1 },
  { value: 2375, label: "Rendang", kab: 7, prop: 1 },
  { value: 2376, label: "Selat", kab: 7, prop: 1 },
  { value: 2377, label: "Sidemen", kab: 7, prop: 1 },
  { value: 2748, label: "Banjarangkan", kab: 8, prop: 1 },
  { value: 2749, label: "Dawan", kab: 8, prop: 1 },
  { value: 2750, label: "Klungkung", kab: 8, prop: 1 },
  { value: 2751, label: "Nusapenida", kab: 8, prop: 1 },
  { value: 6179, label: "Baturiti", kab: 9, prop: 1 },
  { value: 6180, label: "Kediri", kab: 9, prop: 1 },
  { value: 6181, label: "Kerambitan", kab: 9, prop: 1 },
  { value: 6182, label: "Marga", kab: 9, prop: 1 },
  { value: 6183, label: "Penebel", kab: 9, prop: 1 },
  { value: 6184, label: "Pupuan", kab: 9, prop: 1 },
  { value: 6185, label: "Selemadeg", kab: 9, prop: 1 },
  { value: 6186, label: "Selemadeg / Salamadeg Timur", kab: 9, prop: 1 },
  { value: 6187, label: "Selemadeg / Salemadeg Barat", kab: 9, prop: 1 },
  { value: 6188, label: "Tabanan", kab: 9, prop: 1 },
  { value: 426, label: "Bakam", kab: 10, prop: 2 },
  { value: 427, label: "Belinyu", kab: 10, prop: 2 },
  { value: 428, label: "Mendo Barat", kab: 10, prop: 2 },
  { value: 429, label: "Merawang", kab: 10, prop: 2 },
  { value: 430, label: "Pemali", kab: 10, prop: 2 },
  { value: 431, label: "Puding Besar", kab: 10, prop: 2 },
  { value: 432, label: "Riau Silip", kab: 10, prop: 2 },
  { value: 433, label: "Sungai Liat", kab: 10, prop: 2 },
  { value: 434, label: "Jebus", kab: 11, prop: 2 },
  { value: 435, label: "Kelapa", kab: 11, prop: 2 },
  { value: 436, label: "Mentok (Muntok)", kab: 11, prop: 2 },
  { value: 437, label: "Parittiga", kab: 11, prop: 2 },
  { value: 438, label: "Simpang Teritip", kab: 11, prop: 2 },
  { value: 439, label: "Tempilang", kab: 11, prop: 2 },
  { value: 440, label: "Air Gegas", kab: 12, prop: 2 },
  { value: 441, label: "Kepulauan Pongok", kab: 12, prop: 2 },
  { value: 442, label: "Lepar Pongok", kab: 12, prop: 2 },
  { value: 443, label: "Payung", kab: 12, prop: 2 },
  { value: 444, label: "Pulau Besar", kab: 12, prop: 2 },
  { value: 445, label: "Simpang Rimba", kab: 12, prop: 2 },
  { value: 446, label: "Toboali", kab: 12, prop: 2 },
  { value: 447, label: "Tukak Sadai", kab: 12, prop: 2 },
  { value: 448, label: "Koba", kab: 13, prop: 2 },
  { value: 449, label: "Lubuk Besar", kab: 13, prop: 2 },
  { value: 450, label: "Namang", kab: 13, prop: 2 },
  { value: 451, label: "Pangkalan Baru", kab: 13, prop: 2 },
  { value: 452, label: "Simpang Katis", kab: 13, prop: 2 },
  { value: 453, label: "Sungai Selan", kab: 13, prop: 2 },
  { value: 761, label: "Badau", kab: 14, prop: 2 },
  { value: 762, label: "Membalong", kab: 14, prop: 2 },
  { value: 763, label: "Selat Nasik", kab: 14, prop: 2 },
  { value: 764, label: "Sijuk", kab: 14, prop: 2 },
  { value: 765, label: "Tanjung Pandan", kab: 14, prop: 2 },
  { value: 766, label: "Damar", kab: 15, prop: 2 },
  { value: 767, label: "Dendang", kab: 15, prop: 2 },
  { value: 768, label: "Gantung", kab: 15, prop: 2 },
  { value: 769, label: "Kelapa Kampit", kab: 15, prop: 2 },
  { value: 770, label: "Manggar", kab: 15, prop: 2 },
  { value: 771, label: "Simpang Pesak", kab: 15, prop: 2 },
  { value: 772, label: "Simpang Renggiang", kab: 15, prop: 2 },
  { value: 4713, label: "Bukit Intan", kab: 16, prop: 2 },
  { value: 4714, label: "Gabek", kab: 16, prop: 2 },
  { value: 4715, label: "Gerunggang", kab: 16, prop: 2 },
  { value: 4716, label: "Girimaya", kab: 16, prop: 2 },
  { value: 4717, label: "Pangkal Balam", kab: 16, prop: 2 },
  { value: 4718, label: "Rangkui", kab: 16, prop: 2 },
  { value: 4719, label: "Taman Sari", kab: 16, prop: 2 },
  { value: 1461, label: "Cibeber", kab: 17, prop: 3 },
  { value: 1462, label: "Cilegon", kab: 17, prop: 3 },
  { value: 1463, label: "Citangkil", kab: 17, prop: 3 },
  { value: 1464, label: "Ciwandan", kab: 17, prop: 3 },
  { value: 1465, label: "Gerogol", kab: 17, prop: 3 },
  { value: 1466, label: "Jombang", kab: 17, prop: 3 },
  { value: 1467, label: "Pulomerak", kab: 17, prop: 3 },
  { value: 1468, label: "Purwakarta", kab: 17, prop: 3 },
  { value: 3298, label: "Banjarsari", kab: 18, prop: 3 },
  { value: 3299, label: "Bayah", kab: 18, prop: 3 },
  { value: 3300, label: "Bojongmanik", kab: 18, prop: 3 },
  { value: 3301, label: "Cibadak", kab: 18, prop: 3 },
  { value: 3302, label: "Cibeber", kab: 18, prop: 3 },
  { value: 3303, label: "Cigemblong", kab: 18, prop: 3 },
  { value: 3304, label: "Cihara", kab: 18, prop: 3 },
  { value: 3305, label: "Cijaku", kab: 18, prop: 3 },
  { value: 3306, label: "Cikulur", kab: 18, prop: 3 },
  { value: 3307, label: "Cileles", kab: 18, prop: 3 },
  { value: 3308, label: "Cilograng", kab: 18, prop: 3 },
  { value: 3309, label: "Cimarga", kab: 18, prop: 3 },
  { value: 3310, label: "Cipanas", kab: 18, prop: 3 },
  { value: 3311, label: "Cirinten", kab: 18, prop: 3 },
  { value: 3312, label: "Curugbitung", kab: 18, prop: 3 },
  { value: 3313, label: "Gunung Kencana", kab: 18, prop: 3 },
  { value: 3314, label: "Kalanganyar", kab: 18, prop: 3 },
  { value: 3315, label: "Lebakgedong", kab: 18, prop: 3 },
  { value: 3316, label: "Leuwidamar", kab: 18, prop: 3 },
  { value: 3317, label: "Maja", kab: 18, prop: 3 },
  { value: 3318, label: "Malingping", kab: 18, prop: 3 },
  { value: 3319, label: "Muncang", kab: 18, prop: 3 },
  { value: 3320, label: "Panggarangan", kab: 18, prop: 3 },
  { value: 3321, label: "Rangkasbitung", kab: 18, prop: 3 },
  { value: 3322, label: "Sajira", kab: 18, prop: 3 },
  { value: 3323, label: "Sobang", kab: 18, prop: 3 },
  { value: 3324, label: "Wanasalam", kab: 18, prop: 3 },
  { value: 3325, label: "Warunggunung", kab: 18, prop: 3 },
  { value: 4655, label: "Angsana", kab: 19, prop: 3 },
  { value: 4656, label: "Banjar", kab: 19, prop: 3 },
  { value: 4657, label: "Bojong", kab: 19, prop: 3 },
  { value: 4658, label: "Cadasari", kab: 19, prop: 3 },
  { value: 4659, label: "Carita", kab: 19, prop: 3 },
  { value: 4660, label: "Cibaliung", kab: 19, prop: 3 },
  { value: 4661, label: "Cibitung", kab: 19, prop: 3 },
  { value: 4662, label: "Cigeulis", kab: 19, prop: 3 },
  { value: 4663, label: "Cikeudal (Cikedal)", kab: 19, prop: 3 },
  { value: 4664, label: "Cikeusik", kab: 19, prop: 3 },
  { value: 4665, label: "Cimanggu", kab: 19, prop: 3 },
  { value: 4666, label: "Cimanuk", kab: 19, prop: 3 },
  { value: 4667, label: "Cipeucang", kab: 19, prop: 3 },
  { value: 4668, label: "Cisata", kab: 19, prop: 3 },
  { value: 4669, label: "Jiput", kab: 19, prop: 3 },
  { value: 4670, label: "Kaduhejo", kab: 19, prop: 3 },
  { value: 4671, label: "Karang Tanjung", kab: 19, prop: 3 },
  { value: 4672, label: "Koroncong", kab: 19, prop: 3 },
  { value: 4673, label: "Labuan", kab: 19, prop: 3 },
  { value: 4674, label: "Majasari", kab: 19, prop: 3 },
  { value: 4675, label: "Mandalawangi", kab: 19, prop: 3 },
  { value: 4676, label: "Mekarjaya", kab: 19, prop: 3 },
  { value: 4677, label: "Menes", kab: 19, prop: 3 },
  { value: 4678, label: "Munjul", kab: 19, prop: 3 },
  { value: 4679, label: "Pagelaran", kab: 19, prop: 3 },
  { value: 4680, label: "Pandeglang", kab: 19, prop: 3 },
  { value: 4681, label: "Panimbang", kab: 19, prop: 3 },
  { value: 4682, label: "Patia", kab: 19, prop: 3 },
  { value: 4683, label: "Picung", kab: 19, prop: 3 },
  { value: 4684, label: "Pulosari", kab: 19, prop: 3 },
  { value: 4685, label: "Saketi", kab: 19, prop: 3 },
  { value: 4686, label: "Sindangresmi", kab: 19, prop: 3 },
  { value: 4687, label: "Sobang", kab: 19, prop: 3 },
  { value: 4688, label: "Sukaresmi", kab: 19, prop: 3 },
  { value: 4689, label: "Sumur", kab: 19, prop: 3 },
  { value: 5540, label: "Anyar", kab: 20, prop: 3 },
  { value: 5541, label: "Bandung", kab: 20, prop: 3 },
  { value: 5542, label: "Baros", kab: 20, prop: 3 },
  { value: 5543, label: "Binuang", kab: 20, prop: 3 },
  { value: 5544, label: "Bojonegara", kab: 20, prop: 3 },
  { value: 5545, label: "Carenang (Cerenang)", kab: 20, prop: 3 },
  { value: 5546, label: "Cikande", kab: 20, prop: 3 },
  { value: 5547, label: "Cikeusal", kab: 20, prop: 3 },
  { value: 5548, label: "Cinangka", kab: 20, prop: 3 },
  { value: 5549, label: "Ciomas", kab: 20, prop: 3 },
  { value: 5550, label: "Ciruas", kab: 20, prop: 3 },
  { value: 5551, label: "Gunungsari", kab: 20, prop: 3 },
  { value: 5552, label: "Jawilan", kab: 20, prop: 3 },
  { value: 5553, label: "Kibin", kab: 20, prop: 3 },
  { value: 5554, label: "Kopo", kab: 20, prop: 3 },
  { value: 5555, label: "Kragilan", kab: 20, prop: 3 },
  { value: 5556, label: "Kramatwatu", kab: 20, prop: 3 },
  { value: 5557, label: "Lebak Wangi", kab: 20, prop: 3 },
  { value: 5558, label: "Mancak", kab: 20, prop: 3 },
  { value: 5559, label: "Pabuaran", kab: 20, prop: 3 },
  { value: 5560, label: "Padarincang", kab: 20, prop: 3 },
  { value: 5561, label: "Pamarayan", kab: 20, prop: 3 },
  { value: 5562, label: "Petir", kab: 20, prop: 3 },
  { value: 5563, label: "Pontang", kab: 20, prop: 3 },
  { value: 5564, label: "Pulo Ampel", kab: 20, prop: 3 },
  { value: 5565, label: "Tanara", kab: 20, prop: 3 },
  { value: 5566, label: "Tirtayasa", kab: 20, prop: 3 },
  { value: 5567, label: "Tunjung Teja", kab: 20, prop: 3 },
  { value: 5568, label: "Waringin Kurung", kab: 20, prop: 3 },
  { value: 5569, label: "Cipocok Jaya", kab: 20, prop: 3 },
  { value: 5570, label: "Curug", kab: 20, prop: 3 },
  { value: 5571, label: "Kasemen", kab: 20, prop: 3 },
  { value: 5572, label: "Serang", kab: 20, prop: 3 },
  { value: 5573, label: "Taktakan", kab: 20, prop: 3 },
  { value: 5574, label: "Walantaka", kab: 20, prop: 3 },
  { value: 6268, label: "Balaraja", kab: 21, prop: 3 },
  { value: 6269, label: "Cikupa", kab: 21, prop: 3 },
  { value: 6270, label: "Cisauk", kab: 21, prop: 3 },
  { value: 6271, label: "Cisoka", kab: 21, prop: 3 },
  { value: 6272, label: "Curug", kab: 21, prop: 3 },
  { value: 6273, label: "Gunung Kaler", kab: 21, prop: 3 },
  { value: 6274, label: "Jambe", kab: 21, prop: 3 },
  { value: 6275, label: "Jayanti", kab: 21, prop: 3 },
  { value: 6276, label: "Kelapa Dua", kab: 21, prop: 3 },
  { value: 6277, label: "Kemiri", kab: 21, prop: 3 },
  { value: 6278, label: "Kosambi", kab: 21, prop: 3 },
  { value: 6279, label: "Kresek", kab: 21, prop: 3 },
  { value: 6280, label: "Kronjo", kab: 21, prop: 3 },
  { value: 6281, label: "Legok", kab: 21, prop: 3 },
  { value: 6282, label: "Mauk", kab: 21, prop: 3 },
  { value: 6283, label: "Mekar Baru", kab: 21, prop: 3 },
  { value: 6284, label: "Pagedangan", kab: 21, prop: 3 },
  { value: 6285, label: "Pakuhaji", kab: 21, prop: 3 },
  { value: 6286, label: "Panongan", kab: 21, prop: 3 },
  { value: 6287, label: "Pasar Kemis", kab: 21, prop: 3 },
  { value: 6288, label: "Rajeg", kab: 21, prop: 3 },
  { value: 6289, label: "Sepatan", kab: 21, prop: 3 },
  { value: 6290, label: "Sepatan Timur", kab: 21, prop: 3 },
  { value: 6291, label: "Sindang Jaya", kab: 21, prop: 3 },
  { value: 6292, label: "Solear", kab: 21, prop: 3 },
  { value: 6293, label: "Sukadiri", kab: 21, prop: 3 },
  { value: 6294, label: "Sukamulya", kab: 21, prop: 3 },
  { value: 6295, label: "Teluknaga", kab: 21, prop: 3 },
  { value: 6296, label: "Tigaraksa", kab: 21, prop: 3 },
  { value: 6297, label: "Batuceper", kab: 21, prop: 3 },
  { value: 6298, label: "Benda", kab: 21, prop: 3 },
  { value: 6299, label: "Cibodas", kab: 21, prop: 3 },
  { value: 6300, label: "Ciledug", kab: 21, prop: 3 },
  { value: 6301, label: "Cipondoh", kab: 21, prop: 3 },
  { value: 6302, label: "Jatiuwung", kab: 21, prop: 3 },
  { value: 6303, label: "Karang Tengah", kab: 21, prop: 3 },
  { value: 6304, label: "Karawaci", kab: 21, prop: 3 },
  { value: 6305, label: "Larangan", kab: 21, prop: 3 },
  { value: 6306, label: "Neglasari", kab: 21, prop: 3 },
  { value: 6307, label: "Periuk", kab: 21, prop: 3 },
  { value: 6308, label: "Pinang (Penang)", kab: 21, prop: 3 },
  { value: 6309, label: "Tangerang", kab: 21, prop: 3 },
  { value: 6310, label: "Ciputat", kab: 22, prop: 3 },
  { value: 6311, label: "Ciputat Timur", kab: 22, prop: 3 },
  { value: 6312, label: "Pamulang", kab: 22, prop: 3 },
  { value: 6313, label: "Pondok Aren", kab: 22, prop: 3 },
  { value: 6314, label: "Serpong", kab: 22, prop: 3 },
  { value: 6315, label: "Serpong Utara", kab: 22, prop: 3 },
  { value: 6316, label: "Setu", kab: 22, prop: 3 },
  { value: 832, label: "Gading Cempaka", kab: 23, prop: 4 },
  { value: 833, label: "Kampung Melayu", kab: 23, prop: 4 },
  { value: 834, label: "Muara Bangka Hulu", kab: 23, prop: 4 },
  { value: 835, label: "Ratu Agung", kab: 23, prop: 4 },
  { value: 836, label: "Ratu Samban", kab: 23, prop: 4 },
  { value: 837, label: "Selebar", kab: 23, prop: 4 },
  { value: 838, label: "Singaran Pati", kab: 23, prop: 4 },
  { value: 839, label: "Sungai Serut", kab: 23, prop: 4 },
  { value: 840, label: "Teluk Segara", kab: 23, prop: 4 },
  { value: 841, label: "Air Nipis", kab: 24, prop: 4 },
  { value: 842, label: "Bunga Mas", kab: 24, prop: 4 },
  { value: 843, label: "Kedurang", kab: 24, prop: 4 },
  { value: 844, label: "Kedurang Ilir", kab: 24, prop: 4 },
  { value: 845, label: "Kota Manna", kab: 24, prop: 4 },
  { value: 846, label: "Manna", kab: 24, prop: 4 },
  { value: 847, label: "Pasar Manna", kab: 24, prop: 4 },
  { value: 848, label: "Pino", kab: 24, prop: 4 },
  { value: 849, label: "Pinoraya", kab: 24, prop: 4 },
  { value: 850, label: "Seginim", kab: 24, prop: 4 },
  { value: 851, label: "Ulu Manna", kab: 24, prop: 4 },
  { value: 852, label: "Bang Haji", kab: 25, prop: 4 },
  { value: 853, label: "Karang Tinggi", kab: 25, prop: 4 },
  { value: 854, label: "Merigi Kelindang", kab: 25, prop: 4 },
  { value: 855, label: "Merigi Sakti", kab: 25, prop: 4 },
  { value: 856, label: "Pagar Jati", kab: 25, prop: 4 },
  { value: 857, label: "Pematang Tiga", kab: 25, prop: 4 },
  { value: 858, label: "Pondok Kelapa", kab: 25, prop: 4 },
  { value: 859, label: "Pondok Kubang", kab: 25, prop: 4 },
  { value: 860, label: "Taba Penanjung", kab: 25, prop: 4 },
  { value: 861, label: "Talang Empat", kab: 25, prop: 4 },
  { value: 862, label: "Air Besi", kab: 26, prop: 4 },
  { value: 863, label: "Air Napal", kab: 26, prop: 4 },
  { value: 864, label: "Air Padang", kab: 26, prop: 4 },
  { value: 865, label: "Arga Makmur", kab: 26, prop: 4 },
  { value: 866, label: "Arma Jaya", kab: 26, prop: 4 },
  { value: 867, label: "Batik Nau", kab: 26, prop: 4 },
  { value: 868, label: "Enggano", kab: 26, prop: 4 },
  { value: 869, label: "Giri Mulia", kab: 26, prop: 4 },
  { value: 870, label: "Hulu Palik", kab: 26, prop: 4 },
  { value: 871, label: "Kerkap", kab: 26, prop: 4 },
  { value: 872, label: "Ketahun", kab: 26, prop: 4 },
  { value: 873, label: "Lais", kab: 26, prop: 4 },
  { value: 874, label: "Napal Putih", kab: 26, prop: 4 },
  { value: 875, label: "Padang Jaya", kab: 26, prop: 4 },
  { value: 876, label: "Putri Hijau", kab: 26, prop: 4 },
  { value: 877, label: "Tanjung Agung Palik", kab: 26, prop: 4 },
  { value: 878, label: "Ulok Kupai", kab: 26, prop: 4 },
  { value: 2450, label: "Kaur Selatan", kab: 27, prop: 4 },
  { value: 2451, label: "Kaur Tengah", kab: 27, prop: 4 },
  { value: 2452, label: "Kaur Utara", kab: 27, prop: 4 },
  { value: 2453, label: "Kelam Tengah", kab: 27, prop: 4 },
  { value: 2454, label: "Kinal", kab: 27, prop: 4 },
  { value: 2455, label: "Luas", kab: 27, prop: 4 },
  { value: 2456, label: "Lungkang Kule", kab: 27, prop: 4 },
  { value: 2457, label: "Maje", kab: 27, prop: 4 },
  { value: 2458, label: "Muara Sahung", kab: 27, prop: 4 },
  { value: 2459, label: "Nasal", kab: 27, prop: 4 },
  { value: 2460, label: "Padang Guci Hilir", kab: 27, prop: 4 },
  { value: 2461, label: "Padang Guci Hulu", kab: 27, prop: 4 },
  { value: 2462, label: "Semidang Gumai (Gumay)", kab: 27, prop: 4 },
  { value: 2463, label: "Tanjung Kemuning", kab: 27, prop: 4 },
  { value: 2464, label: "Tetap (Muara Tetap)", kab: 27, prop: 4 },
  { value: 2563, label: "Bermani Ilir", kab: 28, prop: 4 },
  { value: 2564, label: "Kebawetan (Kabawetan)", kab: 28, prop: 4 },
  { value: 2565, label: "Kepahiang", kab: 28, prop: 4 },
  { value: 2566, label: "Merigi", kab: 28, prop: 4 },
  { value: 2567, label: "Muara Kemumu", kab: 28, prop: 4 },
  { value: 2568, label: "Seberang Musi", kab: 28, prop: 4 },
  { value: 2569, label: "Tebat Karai", kab: 28, prop: 4 },
  { value: 2570, label: "Ujan Mas", kab: 28, prop: 4 },
  { value: 3326, label: "Amen", kab: 29, prop: 4 },
  { value: 3327, label: "Bingin Kuning", kab: 29, prop: 4 },
  { value: 3328, label: "Lebong Atas", kab: 29, prop: 4 },
  { value: 3329, label: "Lebong Sakti", kab: 29, prop: 4 },
  { value: 3330, label: "Lebong Selatan", kab: 29, prop: 4 },
  { value: 3331, label: "Lebong Tengah", kab: 29, prop: 4 },
  { value: 3332, label: "Lebong Utara", kab: 29, prop: 4 },
  { value: 3333, label: "Pelabai", kab: 29, prop: 4 },
  { value: 3334, label: "Pinang Belapis", kab: 29, prop: 4 },
  { value: 3335, label: "Rimbo Pengadang", kab: 29, prop: 4 },
  { value: 3336, label: "Topos", kab: 29, prop: 4 },
  { value: 3337, label: "Uram Jaya", kab: 29, prop: 4 },
  { value: 4142, label: "Air Dikit", kab: 30, prop: 4 },
  { value: 4143, label: "Air Majunto", kab: 30, prop: 4 },
  { value: 4144, label: "Air Rami", kab: 30, prop: 4 },
  { value: 4145, label: "Ipuh (Muko-Muko Selatan)", kab: 30, prop: 4 },
  { value: 4146, label: "Kota Mukomuko (Mukomuko Utara)", kab: 30, prop: 4 },
  { value: 4147, label: "Lubuk Pinang", kab: 30, prop: 4 },
  { value: 4148, label: "Malin Deman", kab: 30, prop: 4 },
  { value: 4149, label: "Penarik", kab: 30, prop: 4 },
  { value: 4150, label: "Pondok Suguh", kab: 30, prop: 4 },
  { value: 4151, label: "Selagan Raya", kab: 30, prop: 4 },
  { value: 4152, label: "Sungai Rumbai", kab: 30, prop: 4 },
  { value: 4153, label: "Teramang Jaya", kab: 30, prop: 4 },
  { value: 4154, label: "Teras Terunjam", kab: 30, prop: 4 },
  { value: 4155, label: "V Koto", kab: 30, prop: 4 },
  { value: 4156, label: "XIV Koto", kab: 30, prop: 4 },
  { value: 5274, label: "Bermani Ulu", kab: 31, prop: 4 },
  { value: 5275, label: "Bermani Ulu Raya", kab: 31, prop: 4 },
  { value: 5276, label: "Binduriang", kab: 31, prop: 4 },
  { value: 5277, label: "Curup", kab: 31, prop: 4 },
  { value: 5278, label: "Curup Selatan", kab: 31, prop: 4 },
  { value: 5279, label: "Curup Tengah", kab: 31, prop: 4 },
  { value: 5280, label: "Curup Timur", kab: 31, prop: 4 },
  { value: 5281, label: "Curup Utara", kab: 31, prop: 4 },
  { value: 5282, label: "Kota Padang", kab: 31, prop: 4 },
  { value: 5283, label: "Padang Ulak Tanding", kab: 31, prop: 4 },
  { value: 5284, label: "Selupu Rejang", kab: 31, prop: 4 },
  { value: 5285, label: "Sindang Beliti Ilir", kab: 31, prop: 4 },
  { value: 5286, label: "Sindang Beliti Ulu", kab: 31, prop: 4 },
  { value: 5287, label: "Sindang Daratan", kab: 31, prop: 4 },
  { value: 5288, label: "Sindang Kelingi", kab: 31, prop: 4 },
  { value: 5465, label: "Air Periukan", kab: 32, prop: 4 },
  { value: 5466, label: "Ilir Talo", kab: 32, prop: 4 },
  { value: 5467, label: "Lubuk Sandi", kab: 32, prop: 4 },
  { value: 5468, label: "Seluma", kab: 32, prop: 4 },
  { value: 5469, label: "Seluma Barat", kab: 32, prop: 4 },
  { value: 5470, label: "Seluma Selatan", kab: 32, prop: 4 },
  { value: 5471, label: "Seluma Timur", kab: 32, prop: 4 },
  { value: 5472, label: "Seluma Utara", kab: 32, prop: 4 },
  { value: 5473, label: "Semidang Alas", kab: 32, prop: 4 },
  { value: 5474, label: "Semidang Alas Maras", kab: 32, prop: 4 },
  { value: 5475, label: "Sukaraja", kab: 32, prop: 4 },
  { value: 5476, label: "Talo", kab: 32, prop: 4 },
  { value: 5477, label: "Talo Kecil", kab: 32, prop: 4 },
  { value: 5478, label: "Ulu Talo", kab: 32, prop: 4 },
  { value: 537, label: "Bambang Lipuro", kab: 33, prop: 5 },
  { value: 538, label: "Banguntapan", kab: 33, prop: 5 },
  { value: 539, label: "Bantul", kab: 33, prop: 5 },
  { value: 540, label: "Dlingo", kab: 33, prop: 5 },
  { value: 541, label: "Imogiri", kab: 33, prop: 5 },
  { value: 542, label: "Jetis", kab: 33, prop: 5 },
  { value: 543, label: "Kasihan", kab: 33, prop: 5 },
  { value: 544, label: "Kretek", kab: 33, prop: 5 },
  { value: 545, label: "Pajangan", kab: 33, prop: 5 },
  { value: 546, label: "Pandak", kab: 33, prop: 5 },
  { value: 547, label: "Piyungan", kab: 33, prop: 5 },
  { value: 548, label: "Pleret", kab: 33, prop: 5 },
  { value: 549, label: "Pundong", kab: 33, prop: 5 },
  { value: 550, label: "Sanden", kab: 33, prop: 5 },
  { value: 551, label: "Sedayu", kab: 33, prop: 5 },
  { value: 552, label: "Sewon", kab: 33, prop: 5 },
  { value: 553, label: "Srandakan", kab: 33, prop: 5 },
  { value: 1865, label: "Gedang Sari", kab: 34, prop: 5 },
  { value: 1866, label: "Girisubo", kab: 34, prop: 5 },
  { value: 1867, label: "Karangmojo", kab: 34, prop: 5 },
  { value: 1868, label: "Ngawen", kab: 34, prop: 5 },
  { value: 1869, label: "Nglipar", kab: 34, prop: 5 },
  { value: 1870, label: "Paliyan", kab: 34, prop: 5 },
  { value: 1871, label: "Panggang", kab: 34, prop: 5 },
  { value: 1872, label: "Patuk", kab: 34, prop: 5 },
  { value: 1873, label: "Playen", kab: 34, prop: 5 },
  { value: 1874, label: "Ponjong", kab: 34, prop: 5 },
  { value: 1875, label: "Purwosari", kab: 34, prop: 5 },
  { value: 1876, label: "Rongkop", kab: 34, prop: 5 },
  { value: 1877, label: "Sapto Sari", kab: 34, prop: 5 },
  { value: 1878, label: "Semanu", kab: 34, prop: 5 },
  { value: 1879, label: "Semin", kab: 34, prop: 5 },
  { value: 1880, label: "Tanjungsari", kab: 34, prop: 5 },
  { value: 1881, label: "Tepus", kab: 34, prop: 5 },
  { value: 1882, label: "Wonosari", kab: 34, prop: 5 },
  { value: 2930, label: "Galur", kab: 35, prop: 5 },
  { value: 2931, label: "Girimulyo", kab: 35, prop: 5 },
  { value: 2932, label: "Kalibawang", kab: 35, prop: 5 },
  { value: 2933, label: "Kokap", kab: 35, prop: 5 },
  { value: 2934, label: "Lendah", kab: 35, prop: 5 },
  { value: 2935, label: "Nanggulan", kab: 35, prop: 5 },
  { value: 2936, label: "Panjatan", kab: 35, prop: 5 },
  { value: 2937, label: "Pengasih", kab: 35, prop: 5 },
  { value: 2938, label: "Samigaluh", kab: 35, prop: 5 },
  { value: 2939, label: "Sentolo", kab: 35, prop: 5 },
  { value: 2940, label: "Temon", kab: 35, prop: 5 },
  { value: 2941, label: "Wates", kab: 35, prop: 5 },
  { value: 5779, label: "Berbah", kab: 36, prop: 5 },
  { value: 5780, label: "Cangkringan", kab: 36, prop: 5 },
  { value: 5781, label: "Depok", kab: 36, prop: 5 },
  { value: 5782, label: "Gamping", kab: 36, prop: 5 },
  { value: 5783, label: "Godean", kab: 36, prop: 5 },
  { value: 5784, label: "Kalasan", kab: 36, prop: 5 },
  { value: 5785, label: "Minggir", kab: 36, prop: 5 },
  { value: 5786, label: "Mlati", kab: 36, prop: 5 },
  { value: 5787, label: "Moyudan", kab: 36, prop: 5 },
  { value: 5788, label: "Ngaglik", kab: 36, prop: 5 },
  { value: 5789, label: "Ngemplak", kab: 36, prop: 5 },
  { value: 5790, label: "Pakem", kab: 36, prop: 5 },
  { value: 5791, label: "Prambanan", kab: 36, prop: 5 },
  { value: 5792, label: "Seyegan", kab: 36, prop: 5 },
  { value: 5793, label: "Sleman", kab: 36, prop: 5 },
  { value: 5794, label: "Tempel", kab: 36, prop: 5 },
  { value: 5795, label: "Turi", kab: 36, prop: 5 },
  { value: 6981, label: "Danurejan", kab: 37, prop: 5 },
  { value: 6982, label: "Gedong Tengen", kab: 37, prop: 5 },
  { value: 6983, label: "Gondokusuman", kab: 37, prop: 5 },
  { value: 6984, label: "Gondomanan", kab: 37, prop: 5 },
  { value: 6985, label: "Jetis", kab: 37, prop: 5 },
  { value: 6986, label: "Kotagede", kab: 37, prop: 5 },
  { value: 6987, label: "Kraton", kab: 37, prop: 5 },
  { value: 6988, label: "Mantrijeron", kab: 37, prop: 5 },
  { value: 6989, label: "Mergangsan", kab: 37, prop: 5 },
  { value: 6990, label: "Ngampilan", kab: 37, prop: 5 },
  { value: 6991, label: "Pakualaman", kab: 37, prop: 5 },
  { value: 6992, label: "Tegalrejo", kab: 37, prop: 5 },
  { value: 6993, label: "Umbulharjo", kab: 37, prop: 5 },
  { value: 6994, label: "Wirobrajan", kab: 37, prop: 5 },
  { value: 2087, label: "Cengkareng", kab: 38, prop: 6 },
  { value: 2088, label: "Grogol Petamburan", kab: 38, prop: 6 },
  { value: 2089, label: "Kalideres", kab: 38, prop: 6 },
  { value: 2090, label: "Kebon Jeruk", kab: 38, prop: 6 },
  { value: 2091, label: "Kembangan", kab: 38, prop: 6 },
  { value: 2092, label: "Palmerah", kab: 38, prop: 6 },
  { value: 2093, label: "Taman Sari", kab: 38, prop: 6 },
  { value: 2094, label: "Tambora", kab: 38, prop: 6 },
  { value: 2095, label: "Cempaka Putih", kab: 39, prop: 6 },
  { value: 2096, label: "Gambir", kab: 39, prop: 6 },
  { value: 2097, label: "Johar Baru", kab: 39, prop: 6 },
  { value: 2098, label: "Kemayoran", kab: 39, prop: 6 },
  { value: 2099, label: "Menteng", kab: 39, prop: 6 },
  { value: 2100, label: "Sawah Besar", kab: 39, prop: 6 },
  { value: 2101, label: "Senen", kab: 39, prop: 6 },
  { value: 2102, label: "Tanah Abang", kab: 39, prop: 6 },
  { value: 2103, label: "Cilandak", kab: 40, prop: 6 },
  { value: 2104, label: "Jagakarsa", kab: 40, prop: 6 },
  { value: 2105, label: "Kebayoran Baru", kab: 40, prop: 6 },
  { value: 2106, label: "Kebayoran Lama", kab: 40, prop: 6 },
  { value: 2107, label: "Mampang Prapatan", kab: 40, prop: 6 },
  { value: 2108, label: "Pancoran", kab: 40, prop: 6 },
  { value: 2109, label: "Pasar Minggu", kab: 40, prop: 6 },
  { value: 2110, label: "Pesanggrahan", kab: 40, prop: 6 },
  { value: 2111, label: "Setia Budi", kab: 40, prop: 6 },
  { value: 2112, label: "Tebet", kab: 40, prop: 6 },
  { value: 2113, label: "Cakung", kab: 41, prop: 6 },
  { value: 2114, label: "Cipayung", kab: 41, prop: 6 },
  { value: 2115, label: "Ciracas", kab: 41, prop: 6 },
  { value: 2116, label: "Duren Sawit", kab: 41, prop: 6 },
  { value: 2117, label: "Jatinegara", kab: 41, prop: 6 },
  { value: 2118, label: "Kramat Jati", kab: 41, prop: 6 },
  { value: 2119, label: "Makasar", kab: 41, prop: 6 },
  { value: 2120, label: "Matraman", kab: 41, prop: 6 },
  { value: 2121, label: "Pasar Rebo", kab: 41, prop: 6 },
  { value: 2122, label: "Pulo Gadung", kab: 41, prop: 6 },
  { value: 2123, label: "Cilincing", kab: 42, prop: 6 },
  { value: 2124, label: "Kelapa Gading", kab: 42, prop: 6 },
  { value: 2125, label: "Koja", kab: 42, prop: 6 },
  { value: 2126, label: "Pademangan", kab: 42, prop: 6 },
  { value: 2127, label: "Penjaringan", kab: 42, prop: 6 },
  { value: 2128, label: "Tanjung Priok", kab: 42, prop: 6 },
  { value: 2622, label: "Kepulauan Seribu Selatan", kab: 43, prop: 6 },
  { value: 2623, label: "Kepulauan Seribu Utara", kab: 43, prop: 6 },
  { value: 1015, label: "Botumoita (Botumoito)", kab: 44, prop: 7 },
  { value: 1016, label: "Dulupi", kab: 44, prop: 7 },
  { value: 1017, label: "Mananggu", kab: 44, prop: 7 },
  { value: 1018, label: "Paguyaman", kab: 44, prop: 7 },
  { value: 1019, label: "Paguyaman Pantai", kab: 44, prop: 7 },
  { value: 1020, label: "Tilamuta", kab: 44, prop: 7 },
  { value: 1021, label: "Wonosari", kab: 44, prop: 7 },
  { value: 1199, label: "Bone", kab: 45, prop: 7 },
  { value: 1200, label: "Bone Raya", kab: 45, prop: 7 },
  { value: 1201, label: "Bonepantai", kab: 45, prop: 7 },
  { value: 1202, label: "Botu Pingge", kab: 45, prop: 7 },
  { value: 1203, label: "Bulango Selatan", kab: 45, prop: 7 },
  { value: 1204, label: "Bulango Timur", kab: 45, prop: 7 },
  { value: 1205, label: "Bulango Ulu", kab: 45, prop: 7 },
  { value: 1206, label: "Bulango Utara", kab: 45, prop: 7 },
  { value: 1207, label: "Bulawa", kab: 45, prop: 7 },
  { value: 1208, label: "Kabila", kab: 45, prop: 7 },
  { value: 1209, label: "Kabila Bone", kab: 45, prop: 7 },
  { value: 1210, label: "Pinogu", kab: 45, prop: 7 },
  { value: 1211, label: "Suwawa", kab: 45, prop: 7 },
  { value: 1212, label: "Suwawa Selatan", kab: 45, prop: 7 },
  { value: 1213, label: "Suwawa Tengah", kab: 45, prop: 7 },
  { value: 1214, label: "Suwawa Timur", kab: 45, prop: 7 },
  { value: 1215, label: "Tapa", kab: 45, prop: 7 },
  { value: 1216, label: "Tilongkabila", kab: 45, prop: 7 },
  { value: 1771, label: "Asparaga", kab: 46, prop: 7 },
  { value: 1772, label: "Batudaa", kab: 46, prop: 7 },
  { value: 1773, label: "Batudaa Pantai", kab: 46, prop: 7 },
  { value: 1774, label: "Bilato", kab: 46, prop: 7 },
  { value: 1775, label: "Biluhu", kab: 46, prop: 7 },
  { value: 1776, label: "Boliohuto (Boliyohuto)", kab: 46, prop: 7 },
  { value: 1777, label: "Bongomeme", kab: 46, prop: 7 },
  { value: 1778, label: "Dungaliyo", kab: 46, prop: 7 },
  { value: 1779, label: "Limboto", kab: 46, prop: 7 },
  { value: 1780, label: "Limboto Barat", kab: 46, prop: 7 },
  { value: 1781, label: "Mootilango", kab: 46, prop: 7 },
  { value: 1782, label: "Pulubala", kab: 46, prop: 7 },
  { value: 1783, label: "Tabongo", kab: 46, prop: 7 },
  { value: 1784, label: "Telaga", kab: 46, prop: 7 },
  { value: 1785, label: "Telaga Biru", kab: 46, prop: 7 },
  { value: 1786, label: "Telaga Jaya", kab: 46, prop: 7 },
  { value: 1787, label: "Tibawa", kab: 46, prop: 7 },
  { value: 1788, label: "Tilango", kab: 46, prop: 7 },
  { value: 1789, label: "Tolangohula", kab: 46, prop: 7 },
  { value: 1790, label: "Dumbo Raya", kab: 46, prop: 7 },
  { value: 1791, label: "Dungingi", kab: 46, prop: 7 },
  { value: 1792, label: "Hulonthalangi", kab: 46, prop: 7 },
  { value: 1793, label: "Kota Barat", kab: 46, prop: 7 },
  { value: 1794, label: "Kota Selatan", kab: 46, prop: 7 },
  { value: 1795, label: "Kota Tengah", kab: 46, prop: 7 },
  { value: 1796, label: "Kota Timur", kab: 46, prop: 7 },
  { value: 1797, label: "Kota Utara", kab: 46, prop: 7 },
  { value: 1798, label: "Sipatana", kab: 46, prop: 7 },
  { value: 1799, label: "Anggrek", kab: 47, prop: 7 },
  { value: 1800, label: "Atinggola", kab: 47, prop: 7 },
  { value: 1801, label: "Biau", kab: 47, prop: 7 },
  { value: 1802, label: "Gentuma Raya", kab: 47, prop: 7 },
  { value: 1803, label: "Kwandang", kab: 47, prop: 7 },
  { value: 1804, label: "Monano", kab: 47, prop: 7 },
  { value: 1805, label: "Ponelo Kepulauan", kab: 47, prop: 7 },
  { value: 1806, label: "Sumalata", kab: 47, prop: 7 },
  { value: 1807, label: "Sumalata Timur", kab: 47, prop: 7 },
  { value: 1808, label: "Tolinggula", kab: 47, prop: 7 },
  { value: 1809, label: "Tomolito", kab: 47, prop: 7 },
  { value: 5042, label: "Buntulia", kab: 48, prop: 7 },
  { value: 5043, label: "Dengilo", kab: 48, prop: 7 },
  { value: 5044, label: "Duhiadaa", kab: 48, prop: 7 },
  { value: 5045, label: "Lemito", kab: 48, prop: 7 },
  { value: 5046, label: "Marisa", kab: 48, prop: 7 },
  { value: 5047, label: "Paguat", kab: 48, prop: 7 },
  { value: 5048, label: "Patilanggio", kab: 48, prop: 7 },
  { value: 5049, label: "Popayato", kab: 48, prop: 7 },
  { value: 5050, label: "Popayato Barat", kab: 48, prop: 7 },
  { value: 5051, label: "Popayato Timur", kab: 48, prop: 7 },
  { value: 5052, label: "Randangan", kab: 48, prop: 7 },
  { value: 5053, label: "Taluditi (Taluduti)", kab: 48, prop: 7 },
  { value: 5054, label: "Wanggarasi", kab: 48, prop: 7 },
  { value: 700, label: "Bajubang", kab: 49, prop: 8 },
  { value: 701, label: "Batin XXIV", kab: 49, prop: 8 },
  { value: 702, label: "Maro Sebo Ilir", kab: 49, prop: 8 },
  { value: 703, label: "Maro Sebo Ulu", kab: 49, prop: 8 },
  { value: 704, label: "Mersam", kab: 49, prop: 8 },
  { value: 705, label: "Muara Bulian", kab: 49, prop: 8 },
  { value: 706, label: "Muara Tembesi", kab: 49, prop: 8 },
  { value: 707, label: "Pemayung", kab: 49, prop: 8 },
  { value: 1308, label: "Bathin II Babeko", kab: 50, prop: 8 },
  { value: 1309, label: "Bathin II Pelayang", kab: 50, prop: 8 },
  { value: 1310, label: "Bathin III", kab: 50, prop: 8 },
  { value: 1311, label: "Bathin III Ulu", kab: 50, prop: 8 },
  { value: 1312, label: "Bungo Dani", kab: 50, prop: 8 },
  { value: 1313, label: "Jujuhan", kab: 50, prop: 8 },
  { value: 1314, label: "Jujuhan Ilir", kab: 50, prop: 8 },
  { value: 1315, label: "Limbur Lubuk Mengkuang", kab: 50, prop: 8 },
  { value: 1316, label: "Muko-Muko Batin VII", kab: 50, prop: 8 },
  { value: 1317, label: "Pasar Muara Bungo", kab: 50, prop: 8 },
  { value: 1318, label: "Pelepat", kab: 50, prop: 8 },
  { value: 1319, label: "Pelepat Ilir", kab: 50, prop: 8 },
  { value: 1320, label: "Rantau Pandan", kab: 50, prop: 8 },
  { value: 1321, label: "Rimbo Tengah", kab: 50, prop: 8 },
  { value: 1322, label: "Tanah Sepenggal", kab: 50, prop: 8 },
  { value: 1323, label: "Tanah Sepenggal Lintas", kab: 50, prop: 8 },
  { value: 1324, label: "Tanah Tumbuh", kab: 50, prop: 8 },
  { value: 2129, label: "Danau Teluk", kab: 51, prop: 8 },
  { value: 2130, label: "Jambi Selatan", kab: 51, prop: 8 },
  { value: 2131, label: "Jambi Timur", kab: 51, prop: 8 },
  { value: 2132, label: "Jelutung", kab: 51, prop: 8 },
  { value: 2133, label: "Kota Baru", kab: 51, prop: 8 },
  { value: 2134, label: "Pasar Jambi", kab: 51, prop: 8 },
  { value: 2135, label: "Pelayangan", kab: 51, prop: 8 },
  { value: 2136, label: "Telanaipura", kab: 51, prop: 8 },
  { value: 2686, label: "Air Hangat", kab: 52, prop: 8 },
  { value: 2687, label: "Air Hangat Barat", kab: 52, prop: 8 },
  { value: 2688, label: "Air Hangat Timur", kab: 52, prop: 8 },
  { value: 2689, label: "Batang Merangin", kab: 52, prop: 8 },
  { value: 2690, label: "Bukitkerman", kab: 52, prop: 8 },
  { value: 2691, label: "Danau Kerinci", kab: 52, prop: 8 },
  { value: 2692, label: "Depati Tujuh", kab: 52, prop: 8 },
  { value: 2693, label: "Gunung Kerinci", kab: 52, prop: 8 },
  { value: 2694, label: "Gunung Raya", kab: 52, prop: 8 },
  { value: 2695, label: "Gunung Tujuh", kab: 52, prop: 8 },
  { value: 2696, label: "Kayu Aro", kab: 52, prop: 8 },
  { value: 2697, label: "Kayu Aro Barat", kab: 52, prop: 8 },
  { value: 2698, label: "Keliling Danau", kab: 52, prop: 8 },
  { value: 2699, label: "Sitinjau Laut", kab: 52, prop: 8 },
  { value: 2700, label: "Siulak", kab: 52, prop: 8 },
  { value: 2701, label: "Siulak Mukai", kab: 52, prop: 8 },
  { value: 3938, label: "Bangko", kab: 53, prop: 8 },
  { value: 3939, label: "Bangko Barat", kab: 53, prop: 8 },
  { value: 3940, label: "Batang Masumai", kab: 53, prop: 8 },
  { value: 3941, label: "Jangkat", kab: 53, prop: 8 },
  { value: 3942, label: "Lembah Masurai", kab: 53, prop: 8 },
  { value: 3943, label: "Margo Tabir", kab: 53, prop: 8 },
  { value: 3944, label: "Muara Siau", kab: 53, prop: 8 },
  { value: 3945, label: "Nalo Tantan", kab: 53, prop: 8 },
  { value: 3946, label: "Pamenang", kab: 53, prop: 8 },
  { value: 3947, label: "Pamenang Barat", kab: 53, prop: 8 },
  { value: 3948, label: "Pamenang Selatan", kab: 53, prop: 8 },
  { value: 3949, label: "Pangkalan Jambu", kab: 53, prop: 8 },
  { value: 3950, label: "Renah Pembarap", kab: 53, prop: 8 },
  { value: 3951, label: "Renah Pemenang", kab: 53, prop: 8 },
  { value: 3952, label: "Sungai Manau", kab: 53, prop: 8 },
  { value: 3953, label: "Sungai Tenang", kab: 53, prop: 8 },
  { value: 3954, label: "Tabir", kab: 53, prop: 8 },
  { value: 3955, label: "Tabir Barat", kab: 53, prop: 8 },
  { value: 3956, label: "Tabir Ilir", kab: 53, prop: 8 },
  { value: 3957, label: "Tabir Lintas", kab: 53, prop: 8 },
  { value: 3958, label: "Tabir Selatan", kab: 53, prop: 8 },
  { value: 3959, label: "Tabir Timur", kab: 53, prop: 8 },
  { value: 3960, label: "Tabir Ulu", kab: 53, prop: 8 },
  { value: 3961, label: "Tiang Pumpung", kab: 53, prop: 8 },
  { value: 4131, label: "Bahar Selatan", kab: 54, prop: 8 },
  { value: 4132, label: "Bahar Utara", kab: 54, prop: 8 },
  { value: 4133, label: "Jambi Luar Kota", kab: 54, prop: 8 },
  { value: 4134, label: "Kumpeh", kab: 54, prop: 8 },
  { value: 4135, label: "Kumpeh Ulu", kab: 54, prop: 8 },
  { value: 4136, label: "Maro Sebo", kab: 54, prop: 8 },
  { value: 4137, label: "Mestong", kab: 54, prop: 8 },
  { value: 4138, label: "Sekernan", kab: 54, prop: 8 },
  { value: 4139, label: "Sungai Bahar", kab: 54, prop: 8 },
  { value: 4140, label: "Sungai Gelam", kab: 54, prop: 8 },
  { value: 4141, label: "Taman Rajo / Rejo", kab: 54, prop: 8 },
  { value: 5433, label: "Air Hitam", kab: 55, prop: 8 },
  { value: 5434, label: "Batang Asai", kab: 55, prop: 8 },
  { value: 5435, label: "Bathin VIII (Batin VIII)", kab: 55, prop: 8 },
  { value: 5436, label: "Cermin Nan Gadang", kab: 55, prop: 8 },
  { value: 5437, label: "Limun", kab: 55, prop: 8 },
  { value: 5438, label: "Mandiangin", kab: 55, prop: 8 },
  { value: 5439, label: "Pauh", kab: 55, prop: 8 },
  { value: 5440, label: "Pelawan", kab: 55, prop: 8 },
  { value: 5441, label: "Sarolangun", kab: 55, prop: 8 },
  { value: 5442, label: "Singkut", kab: 55, prop: 8 },
  { value: 6118, label: "Hamparan Rawang", kab: 56, prop: 8 },
  { value: 6119, label: "Koto Baru", kab: 56, prop: 8 },
  { value: 6120, label: "Kumun Debai", kab: 56, prop: 8 },
  { value: 6121, label: "Pesisir Bukit", kab: 56, prop: 8 },
  { value: 6122, label: "Pondok Tinggi", kab: 56, prop: 8 },
  { value: 6123, label: "Sungai Bungkal", kab: 56, prop: 8 },
  { value: 6124, label: "Sungai Penuh", kab: 56, prop: 8 },
  { value: 6125, label: "Tanah Kampung", kab: 56, prop: 8 },
  { value: 6343, label: "Batang Asam", kab: 57, prop: 8 },
  { value: 6344, label: "Betara", kab: 57, prop: 8 },
  { value: 6345, label: "Bram Itam", kab: 57, prop: 8 },
  { value: 6346, label: "Kuala Betara", kab: 57, prop: 8 },
  { value: 6347, label: "Merlung", kab: 57, prop: 8 },
  { value: 6348, label: "Muara Papalik", kab: 57, prop: 8 },
  { value: 6349, label: "Pengabuan", kab: 57, prop: 8 },
  { value: 6350, label: "Renah Mendaluh", kab: 57, prop: 8 },
  { value: 6351, label: "Seberang Kota", kab: 57, prop: 8 },
  { value: 6352, label: "Senyerang", kab: 57, prop: 8 },
  { value: 6353, label: "Tebing Tinggi", kab: 57, prop: 8 },
  { value: 6354, label: "Tungkal Ilir", kab: 57, prop: 8 },
  { value: 6355, label: "Tungkal Ulu", kab: 57, prop: 8 },
  { value: 6356, label: "Berbak", kab: 58, prop: 8 },
  { value: 6357, label: "Dendang", kab: 58, prop: 8 },
  { value: 6358, label: "Geragai", kab: 58, prop: 8 },
  { value: 6359, label: "Kuala Jambi", kab: 58, prop: 8 },
  { value: 6360, label: "Mendahara", kab: 58, prop: 8 },
  { value: 6361, label: "Mendahara Ulu", kab: 58, prop: 8 },
  { value: 6362, label: "Muara Sabak Barat", kab: 58, prop: 8 },
  { value: 6363, label: "Muara Sabak Timur", kab: 58, prop: 8 },
  { value: 6364, label: "Nipah Panjang", kab: 58, prop: 8 },
  { value: 6365, label: "Rantau Rasau", kab: 58, prop: 8 },
  { value: 6366, label: "Sadu", kab: 58, prop: 8 },
  { value: 6490, label: "Muara Tabir", kab: 59, prop: 8 },
  { value: 6491, label: "Rimbo Bujang", kab: 59, prop: 8 },
  { value: 6492, label: "Rimbo Ilir", kab: 59, prop: 8 },
  { value: 6493, label: "Rimbo Ulu", kab: 59, prop: 8 },
  { value: 6494, label: "Serai Serumpun", kab: 59, prop: 8 },
  { value: 6495, label: "Sumay", kab: 59, prop: 8 },
  { value: 6496, label: "Tebo Ilir", kab: 59, prop: 8 },
  { value: 6497, label: "Tebo Tengah", kab: 59, prop: 8 },
  { value: 6498, label: "Tebo Ulu", kab: 59, prop: 8 },
  { value: 6499, label: "Tengah Ilir", kab: 59, prop: 8 },
  { value: 6500, label: "VII Koto", kab: 59, prop: 8 },
  { value: 6501, label: "VII Koto Ilir", kab: 59, prop: 8 },
  { value: 307, label: "Arjasari", kab: 60, prop: 9 },
  { value: 308, label: "Baleendah", kab: 60, prop: 9 },
  { value: 309, label: "Banjaran", kab: 60, prop: 9 },
  { value: 310, label: "Bojongsoang", kab: 60, prop: 9 },
  { value: 311, label: "Cangkuang", kab: 60, prop: 9 },
  { value: 312, label: "Cicalengka", kab: 60, prop: 9 },
  { value: 313, label: "Cikancung", kab: 60, prop: 9 },
  { value: 314, label: "Cilengkrang", kab: 60, prop: 9 },
  { value: 315, label: "Cileunyi", kab: 60, prop: 9 },
  { value: 316, label: "Cimaung", kab: 60, prop: 9 },
  { value: 317, label: "Cimeunyan", kab: 60, prop: 9 },
  { value: 318, label: "Ciparay", kab: 60, prop: 9 },
  { value: 319, label: "Ciwidey", kab: 60, prop: 9 },
  { value: 320, label: "Dayeuhkolot", kab: 60, prop: 9 },
  { value: 321, label: "Ibun", kab: 60, prop: 9 },
  { value: 322, label: "Katapang", kab: 60, prop: 9 },
  { value: 323, label: "Kertasari", kab: 60, prop: 9 },
  { value: 324, label: "Kutawaringin", kab: 60, prop: 9 },
  { value: 325, label: "Majalaya", kab: 60, prop: 9 },
  { value: 326, label: "Margaasih", kab: 60, prop: 9 },
  { value: 327, label: "Margahayu", kab: 60, prop: 9 },
  { value: 328, label: "Nagreg", kab: 60, prop: 9 },
  { value: 329, label: "Pacet", kab: 60, prop: 9 },
  { value: 330, label: "Pameungpeuk", kab: 60, prop: 9 },
  { value: 331, label: "Pangalengan", kab: 60, prop: 9 },
  { value: 332, label: "Paseh", kab: 60, prop: 9 },
  { value: 333, label: "Pasirjambu", kab: 60, prop: 9 },
  { value: 334, label: "Ranca Bali", kab: 60, prop: 9 },
  { value: 335, label: "Rancaekek", kab: 60, prop: 9 },
  { value: 336, label: "Solokan Jeruk", kab: 60, prop: 9 },
  { value: 337, label: "Soreang", kab: 60, prop: 9 },
  { value: 338, label: "Andir", kab: 60, prop: 9 },
  { value: 339, label: "Antapani (Cicadas)", kab: 60, prop: 9 },
  { value: 340, label: "Arcamanik", kab: 60, prop: 9 },
  { value: 341, label: "Astana Anyar", kab: 60, prop: 9 },
  { value: 342, label: "Babakan Ciparay", kab: 60, prop: 9 },
  { value: 343, label: "Bandung Kidul", kab: 60, prop: 9 },
  { value: 344, label: "Bandung Kulon", kab: 60, prop: 9 },
  { value: 345, label: "Bandung Wetan", kab: 60, prop: 9 },
  { value: 346, label: "Batununggal", kab: 60, prop: 9 },
  { value: 347, label: "Bojongloa Kaler", kab: 60, prop: 9 },
  { value: 348, label: "Bojongloa Kidul", kab: 60, prop: 9 },
  { value: 349, label: "Buahbatu (Margacinta)", kab: 60, prop: 9 },
  { value: 350, label: "Cibeunying Kaler", kab: 60, prop: 9 },
  { value: 351, label: "Cibeunying Kidul", kab: 60, prop: 9 },
  { value: 352, label: "Cibiru", kab: 60, prop: 9 },
  { value: 353, label: "Cicendo", kab: 60, prop: 9 },
  { value: 354, label: "Cidadap", kab: 60, prop: 9 },
  { value: 355, label: "Cinambo", kab: 60, prop: 9 },
  { value: 356, label: "Coblong", kab: 60, prop: 9 },
  { value: 357, label: "Gedebage", kab: 60, prop: 9 },
  { value: 358, label: "Kiaracondong", kab: 60, prop: 9 },
  { value: 359, label: "Lengkong", kab: 60, prop: 9 },
  { value: 360, label: "Mandalajati", kab: 60, prop: 9 },
  { value: 361, label: "Panyileukan", kab: 60, prop: 9 },
  { value: 362, label: "Rancasari", kab: 60, prop: 9 },
  { value: 363, label: "Regol", kab: 60, prop: 9 },
  { value: 364, label: "Sukajadi", kab: 60, prop: 9 },
  { value: 365, label: "Sukasari", kab: 60, prop: 9 },
  { value: 366, label: "Sumur Bandung", kab: 60, prop: 9 },
  { value: 367, label: "Ujung Berung", kab: 60, prop: 9 },
  { value: 368, label: "Batujajar", kab: 61, prop: 9 },
  { value: 369, label: "Cihampelas", kab: 61, prop: 9 },
  { value: 370, label: "Cikalong Wetan", kab: 61, prop: 9 },
  { value: 371, label: "Cililin", kab: 61, prop: 9 },
  { value: 372, label: "Cipatat", kab: 61, prop: 9 },
  { value: 373, label: "Cipeundeuy", kab: 61, prop: 9 },
  { value: 374, label: "Cipongkor", kab: 61, prop: 9 },
  { value: 375, label: "Cisarua", kab: 61, prop: 9 },
  { value: 376, label: "Gununghalu", kab: 61, prop: 9 },
  { value: 377, label: "Lembang", kab: 61, prop: 9 },
  { value: 378, label: "Ngamprah", kab: 61, prop: 9 },
  { value: 379, label: "Padalarang", kab: 61, prop: 9 },
  { value: 380, label: "Parongpong", kab: 61, prop: 9 },
  { value: 381, label: "Rongga", kab: 61, prop: 9 },
  { value: 382, label: "Saguling", kab: 61, prop: 9 },
  { value: 383, label: "Sindangkerta", kab: 61, prop: 9 },
  { value: 495, label: "Banjar", kab: 62, prop: 9 },
  { value: 496, label: "Langensari", kab: 62, prop: 9 },
  { value: 497, label: "Pataruman", kab: 62, prop: 9 },
  { value: 498, label: "Purwaharja", kab: 62, prop: 9 },
  { value: 726, label: "Babelan", kab: 63, prop: 9 },
  { value: 727, label: "Bojongmangu", kab: 63, prop: 9 },
  { value: 728, label: "Cabangbungin", kab: 63, prop: 9 },
  { value: 729, label: "Cibarusah", kab: 63, prop: 9 },
  { value: 730, label: "Cibitung", kab: 63, prop: 9 },
  { value: 731, label: "Cikarang Barat", kab: 63, prop: 9 },
  { value: 732, label: "Cikarang Pusat", kab: 63, prop: 9 },
  { value: 733, label: "Cikarang Selatan", kab: 63, prop: 9 },
  { value: 734, label: "Cikarang Timur", kab: 63, prop: 9 },
  { value: 735, label: "Cikarang Utara", kab: 63, prop: 9 },
  { value: 736, label: "Karangbahagia", kab: 63, prop: 9 },
  { value: 737, label: "Kedung Waringin", kab: 63, prop: 9 },
  { value: 738, label: "Muara Gembong", kab: 63, prop: 9 },
  { value: 739, label: "Pebayuran", kab: 63, prop: 9 },
  { value: 740, label: "Serang Baru", kab: 63, prop: 9 },
  { value: 741, label: "Setu", kab: 63, prop: 9 },
  { value: 742, label: "Sukakarya", kab: 63, prop: 9 },
  { value: 743, label: "Sukatani", kab: 63, prop: 9 },
  { value: 744, label: "Sukawangi", kab: 63, prop: 9 },
  { value: 745, label: "Tambelang", kab: 63, prop: 9 },
  { value: 746, label: "Tambun Selatan", kab: 63, prop: 9 },
  { value: 747, label: "Tambun Utara", kab: 63, prop: 9 },
  { value: 748, label: "Tarumajaya", kab: 63, prop: 9 },
  { value: 749, label: "Bantar Gebang", kab: 63, prop: 9 },
  { value: 750, label: "Bekasi Barat", kab: 63, prop: 9 },
  { value: 751, label: "Bekasi Selatan", kab: 63, prop: 9 },
  { value: 752, label: "Bekasi Timur", kab: 63, prop: 9 },
  { value: 753, label: "Bekasi Utara", kab: 63, prop: 9 },
  { value: 754, label: "Jati Sampurna", kab: 63, prop: 9 },
  { value: 755, label: "Jatiasih", kab: 63, prop: 9 },
  { value: 756, label: "Medan Satria", kab: 63, prop: 9 },
  { value: 757, label: "Mustika Jaya", kab: 63, prop: 9 },
  { value: 758, label: "Pondok Gede", kab: 63, prop: 9 },
  { value: 759, label: "Pondok Melati", kab: 63, prop: 9 },
  { value: 760, label: "Rawalumbu", kab: 63, prop: 9 },
  { value: 1022, label: "Babakan Madang", kab: 64, prop: 9 },
  { value: 1023, label: "Bojonggede", kab: 64, prop: 9 },
  { value: 1024, label: "Caringin", kab: 64, prop: 9 },
  { value: 1025, label: "Cariu", kab: 64, prop: 9 },
  { value: 1026, label: "Ciampea", kab: 64, prop: 9 },
  { value: 1027, label: "Ciawi", kab: 64, prop: 9 },
  { value: 1028, label: "Cibinong", kab: 64, prop: 9 },
  { value: 1029, label: "Cibungbulang", kab: 64, prop: 9 },
  { value: 1030, label: "Cigombong", kab: 64, prop: 9 },
  { value: 1031, label: "Cigudeg", kab: 64, prop: 9 },
  { value: 1032, label: "Cijeruk", kab: 64, prop: 9 },
  { value: 1033, label: "Cileungsi", kab: 64, prop: 9 },
  { value: 1034, label: "Ciomas", kab: 64, prop: 9 },
  { value: 1035, label: "Cisarua", kab: 64, prop: 9 },
  { value: 1036, label: "Ciseeng", kab: 64, prop: 9 },
  { value: 1037, label: "Citeureup", kab: 64, prop: 9 },
  { value: 1038, label: "Dramaga", kab: 64, prop: 9 },
  { value: 1039, label: "Gunung Putri", kab: 64, prop: 9 },
  { value: 1040, label: "Gunung Sindur", kab: 64, prop: 9 },
  { value: 1041, label: "Jasinga", kab: 64, prop: 9 },
  { value: 1042, label: "Jonggol", kab: 64, prop: 9 },
  { value: 1043, label: "Kemang", kab: 64, prop: 9 },
  { value: 1044, label: "Klapa Nunggal (Kelapa Nunggal)", kab: 64, prop: 9 },
  { value: 1045, label: "Leuwiliang", kab: 64, prop: 9 },
  { value: 1046, label: "Leuwisadeng", kab: 64, prop: 9 },
  { value: 1047, label: "Megamendung", kab: 64, prop: 9 },
  { value: 1048, label: "Nanggung", kab: 64, prop: 9 },
  { value: 1049, label: "Pamijahan", kab: 64, prop: 9 },
  { value: 1050, label: "Parung", kab: 64, prop: 9 },
  { value: 1051, label: "Parung Panjang", kab: 64, prop: 9 },
  { value: 1052, label: "Ranca Bungur", kab: 64, prop: 9 },
  { value: 1053, label: "Rumpin", kab: 64, prop: 9 },
  { value: 1054, label: "Sukajaya", kab: 64, prop: 9 },
  { value: 1055, label: "Sukamakmur", kab: 64, prop: 9 },
  { value: 1056, label: "Sukaraja", kab: 64, prop: 9 },
  { value: 1057, label: "Tajurhalang", kab: 64, prop: 9 },
  { value: 1058, label: "Tamansari", kab: 64, prop: 9 },
  { value: 1059, label: "Tanjungsari", kab: 64, prop: 9 },
  { value: 1060, label: "Tenjo", kab: 64, prop: 9 },
  { value: 1061, label: "Tenjolaya", kab: 64, prop: 9 },
  { value: 1062, label: "Bogor Barat - Kota", kab: 64, prop: 9 },
  { value: 1063, label: "Bogor Selatan - Kota", kab: 64, prop: 9 },
  { value: 1064, label: "Bogor Tengah - Kota", kab: 64, prop: 9 },
  { value: 1065, label: "Bogor Timur - Kota", kab: 64, prop: 9 },
  { value: 1066, label: "Bogor Utara - Kota", kab: 64, prop: 9 },
  { value: 1067, label: "Tanah Sereal", kab: 64, prop: 9 },
  { value: 1379, label: "Banjarsari", kab: 65, prop: 9 },
  { value: 1380, label: "Baregbeg", kab: 65, prop: 9 },
  { value: 1381, label: "Ciamis", kab: 65, prop: 9 },
  { value: 1382, label: "Cidolog", kab: 65, prop: 9 },
  { value: 1383, label: "Cihaurbeuti", kab: 65, prop: 9 },
  { value: 1384, label: "Cijeungjing", kab: 65, prop: 9 },
  { value: 1385, label: "Cikoneng", kab: 65, prop: 9 },
  { value: 1386, label: "Cimaragas", kab: 65, prop: 9 },
  { value: 1387, label: "Cipaku", kab: 65, prop: 9 },
  { value: 1388, label: "Cisaga", kab: 65, prop: 9 },
  { value: 1389, label: "Jatinagara", kab: 65, prop: 9 },
  { value: 1390, label: "Kawali", kab: 65, prop: 9 },
  { value: 1391, label: "Lakbok", kab: 65, prop: 9 },
  { value: 1392, label: "Lumbung", kab: 65, prop: 9 },
  { value: 1393, label: "Pamarican", kab: 65, prop: 9 },
  { value: 1394, label: "Panawangan", kab: 65, prop: 9 },
  { value: 1395, label: "Panjalu", kab: 65, prop: 9 },
  { value: 1396, label: "Panumbangan", kab: 65, prop: 9 },
  { value: 1397, label: "Purwadadi", kab: 65, prop: 9 },
  { value: 1398, label: "Rajadesa", kab: 65, prop: 9 },
  { value: 1399, label: "Rancah", kab: 65, prop: 9 },
  { value: 1400, label: "Sadananya", kab: 65, prop: 9 },
  { value: 1401, label: "Sindangkasih", kab: 65, prop: 9 },
  { value: 1402, label: "Sukadana", kab: 65, prop: 9 },
  { value: 1403, label: "Sukamantri", kab: 65, prop: 9 },
  { value: 1404, label: "Tambaksari", kab: 65, prop: 9 },
  { value: 1405, label: "Agrabinta", kab: 66, prop: 9 },
  { value: 1406, label: "Bojongpicung", kab: 66, prop: 9 },
  { value: 1407, label: "Campaka", kab: 66, prop: 9 },
  { value: 1408, label: "Campaka Mulya", kab: 66, prop: 9 },
  { value: 1409, label: "Cianjur", kab: 66, prop: 9 },
  { value: 1410, label: "Cibeber", kab: 66, prop: 9 },
  { value: 1411, label: "Cibinong", kab: 66, prop: 9 },
  { value: 1412, label: "Cidaun", kab: 66, prop: 9 },
  { value: 1413, label: "Cijati", kab: 66, prop: 9 },
  { value: 1414, label: "Cikadu", kab: 66, prop: 9 },
  { value: 1415, label: "Cikalongkulon", kab: 66, prop: 9 },
  { value: 1416, label: "Cilaku", kab: 66, prop: 9 },
  { value: 1417, label: "Cipanas", kab: 66, prop: 9 },
  { value: 1418, label: "Ciranjang", kab: 66, prop: 9 },
  { value: 1419, label: "Cugenang", kab: 66, prop: 9 },
  { value: 1420, label: "Gekbrong", kab: 66, prop: 9 },
  { value: 1421, label: "Haurwangi", kab: 66, prop: 9 },
  { value: 1422, label: "Kadupandak", kab: 66, prop: 9 },
  { value: 1423, label: "Karangtengah", kab: 66, prop: 9 },
  { value: 1424, label: "Leles", kab: 66, prop: 9 },
  { value: 1425, label: "Mande", kab: 66, prop: 9 },
  { value: 1426, label: "Naringgul", kab: 66, prop: 9 },
  { value: 1427, label: "Pacet", kab: 66, prop: 9 },
  { value: 1428, label: "Pagelaran", kab: 66, prop: 9 },
  { value: 1429, label: "Pasirkuda", kab: 66, prop: 9 },
  { value: 1430, label: "Sindangbarang", kab: 66, prop: 9 },
  { value: 1431, label: "Sukaluyu", kab: 66, prop: 9 },
  { value: 1432, label: "Sukanagara", kab: 66, prop: 9 },
  { value: 1433, label: "Sukaresmi", kab: 66, prop: 9 },
  { value: 1434, label: "Takokak", kab: 66, prop: 9 },
  { value: 1435, label: "Tanggeung", kab: 66, prop: 9 },
  { value: 1436, label: "Warungkondang", kab: 66, prop: 9 },
  { value: 1469, label: "Cimahi Selatan", kab: 67, prop: 9 },
  { value: 1470, label: "Cimahi Tengah", kab: 67, prop: 9 },
  { value: 1471, label: "Cimahi Utara", kab: 67, prop: 9 },
  { value: 1472, label: "Arjawinangun", kab: 68, prop: 9 },
  { value: 1473, label: "Astanajapura", kab: 68, prop: 9 },
  { value: 1474, label: "Babakan", kab: 68, prop: 9 },
  { value: 1475, label: "Beber", kab: 68, prop: 9 },
  { value: 1476, label: "Ciledug", kab: 68, prop: 9 },
  { value: 1477, label: "Ciwaringin", kab: 68, prop: 9 },
  { value: 1478, label: "Depok", kab: 68, prop: 9 },
  { value: 1479, label: "Dukupuntang", kab: 68, prop: 9 },
  { value: 1480, label: "Gebang", kab: 68, prop: 9 },
  { value: 1481, label: "Gegesik", kab: 68, prop: 9 },
  { value: 1482, label: "Gempol", kab: 68, prop: 9 },
  { value: 1483, label: "Greged (Greget)", kab: 68, prop: 9 },
  { value: 1484, label: "Gunung Jati (Cirebon Utara)", kab: 68, prop: 9 },
  { value: 1485, label: "Jamblang", kab: 68, prop: 9 },
  { value: 1486, label: "Kaliwedi", kab: 68, prop: 9 },
  { value: 1487, label: "Kapetakan", kab: 68, prop: 9 },
  { value: 1488, label: "Karangsembung", kab: 68, prop: 9 },
  { value: 1489, label: "Karangwareng", kab: 68, prop: 9 },
  { value: 1490, label: "Kedawung", kab: 68, prop: 9 },
  { value: 1491, label: "Klangenan", kab: 68, prop: 9 },
  { value: 1492, label: "Lemahabang", kab: 68, prop: 9 },
  { value: 1493, label: "Losari", kab: 68, prop: 9 },
  { value: 1494, label: "Mundu", kab: 68, prop: 9 },
  { value: 1495, label: "Pabedilan", kab: 68, prop: 9 },
  { value: 1496, label: "Pabuaran", kab: 68, prop: 9 },
  { value: 1497, label: "Palimanan", kab: 68, prop: 9 },
  { value: 1498, label: "Pangenan", kab: 68, prop: 9 },
  { value: 1499, label: "Panguragan", kab: 68, prop: 9 },
  { value: 1500, label: "Pasaleman", kab: 68, prop: 9 },
  { value: 1501, label: "Plered", kab: 68, prop: 9 },
  { value: 1502, label: "Plumbon", kab: 68, prop: 9 },
  { value: 1503, label: "Sedong", kab: 68, prop: 9 },
  { value: 1504, label: "Sumber", kab: 68, prop: 9 },
  { value: 1505, label: "Suranenggala", kab: 68, prop: 9 },
  { value: 1506, label: "Susukan", kab: 68, prop: 9 },
  { value: 1507, label: "Susukan Lebak", kab: 68, prop: 9 },
  { value: 1508, label: "Talun (Cirebon Selatan)", kab: 68, prop: 9 },
  { value: 1509, label: "Tengah Tani", kab: 68, prop: 9 },
  { value: 1510, label: "Waled", kab: 68, prop: 9 },
  { value: 1511, label: "Weru", kab: 68, prop: 9 },
  { value: 1512, label: "Harjamukti", kab: 68, prop: 9 },
  { value: 1513, label: "Kejaksan", kab: 68, prop: 9 },
  { value: 1514, label: "Kesambi", kab: 68, prop: 9 },
  { value: 1515, label: "Lemahwungkuk", kab: 68, prop: 9 },
  { value: 1516, label: "Pekalipan", kab: 68, prop: 9 },
  { value: 1577, label: "Beji", kab: 69, prop: 9 },
  { value: 1578, label: "Bojongsari", kab: 69, prop: 9 },
  { value: 1579, label: "Cilodong", kab: 69, prop: 9 },
  { value: 1580, label: "Cimanggis", kab: 69, prop: 9 },
  { value: 1581, label: "Cinere", kab: 69, prop: 9 },
  { value: 1582, label: "Cipayung", kab: 69, prop: 9 },
  { value: 1583, label: "Limo", kab: 69, prop: 9 },
  { value: 1584, label: "Pancoran Mas", kab: 69, prop: 9 },
  { value: 1585, label: "Sawangan", kab: 69, prop: 9 },
  { value: 1586, label: "Sukmajaya", kab: 69, prop: 9 },
  { value: 1587, label: "Tapos", kab: 69, prop: 9 },
  { value: 1711, label: "Banjarwangi", kab: 70, prop: 9 },
  { value: 1712, label: "Banyuresmi", kab: 70, prop: 9 },
  { value: 1713, label: "Bayongbong", kab: 70, prop: 9 },
  { value: 1714, label: "Blubur Limbangan", kab: 70, prop: 9 },
  { value: 1715, label: "Bungbulang", kab: 70, prop: 9 },
  { value: 1716, label: "Caringin", kab: 70, prop: 9 },
  { value: 1717, label: "Cibalong", kab: 70, prop: 9 },
  { value: 1718, label: "Cibatu", kab: 70, prop: 9 },
  { value: 1719, label: "Cibiuk", kab: 70, prop: 9 },
  { value: 1720, label: "Cigedug", kab: 70, prop: 9 },
  { value: 1721, label: "Cihurip", kab: 70, prop: 9 },
  { value: 1722, label: "Cikajang", kab: 70, prop: 9 },
  { value: 1723, label: "Cikelet", kab: 70, prop: 9 },
  { value: 1724, label: "Cilawu", kab: 70, prop: 9 },
  { value: 1725, label: "Cisewu", kab: 70, prop: 9 },
  { value: 1726, label: "Cisompet", kab: 70, prop: 9 },
  { value: 1727, label: "Cisurupan", kab: 70, prop: 9 },
  { value: 1728, label: "Garut Kota", kab: 70, prop: 9 },
  { value: 1729, label: "Kadungora", kab: 70, prop: 9 },
  { value: 1730, label: "Karangpawitan", kab: 70, prop: 9 },
  { value: 1731, label: "Karangtengah", kab: 70, prop: 9 },
  { value: 1732, label: "Kersamanah", kab: 70, prop: 9 },
  { value: 1733, label: "Leles", kab: 70, prop: 9 },
  { value: 1734, label: "Leuwigoong", kab: 70, prop: 9 },
  { value: 1735, label: "Malangbong", kab: 70, prop: 9 },
  { value: 1736, label: "Mekarmukti", kab: 70, prop: 9 },
  { value: 1737, label: "Pakenjeng", kab: 70, prop: 9 },
  { value: 1738, label: "Pameungpeuk", kab: 70, prop: 9 },
  { value: 1739, label: "Pamulihan", kab: 70, prop: 9 },
  { value: 1740, label: "Pangatikan", kab: 70, prop: 9 },
  { value: 1741, label: "Pasirwangi", kab: 70, prop: 9 },
  { value: 1742, label: "Peundeuy", kab: 70, prop: 9 },
  { value: 1743, label: "Samarang", kab: 70, prop: 9 },
  { value: 1744, label: "Selaawi", kab: 70, prop: 9 },
  { value: 1745, label: "Singajaya", kab: 70, prop: 9 },
  { value: 1746, label: "Sucinaraja", kab: 70, prop: 9 },
  { value: 1747, label: "Sukaresmi", kab: 70, prop: 9 },
  { value: 1748, label: "Sukawening", kab: 70, prop: 9 },
  { value: 1749, label: "Talegong", kab: 70, prop: 9 },
  { value: 1750, label: "Tarogong Kaler", kab: 70, prop: 9 },
  { value: 1751, label: "Tarogong Kidul", kab: 70, prop: 9 },
  { value: 1752, label: "Wanaraja", kab: 70, prop: 9 },
  { value: 2050, label: "Anjatan", kab: 71, prop: 9 },
  { value: 2051, label: "Arahan", kab: 71, prop: 9 },
  { value: 2052, label: "Balongan", kab: 71, prop: 9 },
  { value: 2053, label: "Bangodua", kab: 71, prop: 9 },
  { value: 2054, label: "Bongas", kab: 71, prop: 9 },
  { value: 2055, label: "Cantigi", kab: 71, prop: 9 },
  { value: 2056, label: "Cikedung", kab: 71, prop: 9 },
  { value: 2057, label: "Gabuswetan", kab: 71, prop: 9 },
  { value: 2058, label: "Gantar", kab: 71, prop: 9 },
  { value: 2059, label: "Haurgeulis", kab: 71, prop: 9 },
  { value: 2060, label: "Indramayu", kab: 71, prop: 9 },
  { value: 2061, label: "Jatibarang", kab: 71, prop: 9 },
  { value: 2062, label: "Juntinyuat", kab: 71, prop: 9 },
  { value: 2063, label: "Kandanghaur", kab: 71, prop: 9 },
  { value: 2064, label: "Karangampel", kab: 71, prop: 9 },
  { value: 2065, label: "Kedokan Bunder", kab: 71, prop: 9 },
  { value: 2066, label: "Kertasemaya", kab: 71, prop: 9 },
  { value: 2067, label: "Krangkeng", kab: 71, prop: 9 },
  { value: 2068, label: "Kroya", kab: 71, prop: 9 },
  { value: 2069, label: "Lelea", kab: 71, prop: 9 },
  { value: 2070, label: "Lohbener", kab: 71, prop: 9 },
  { value: 2071, label: "Losarang", kab: 71, prop: 9 },
  { value: 2072, label: "Pasekan", kab: 71, prop: 9 },
  { value: 2073, label: "Patrol", kab: 71, prop: 9 },
  { value: 2074, label: "Sindang", kab: 71, prop: 9 },
  { value: 2075, label: "Sliyeg", kab: 71, prop: 9 },
  { value: 2076, label: "Sukagumiwang", kab: 71, prop: 9 },
  { value: 2077, label: "Sukra", kab: 71, prop: 9 },
  { value: 2078, label: "Trisi/Terisi", kab: 71, prop: 9 },
  { value: 2079, label: "Tukdana", kab: 71, prop: 9 },
  { value: 2080, label: "Widasari", kab: 71, prop: 9 },
  { value: 2378, label: "Banyusari", kab: 72, prop: 9 },
  { value: 2379, label: "Batujaya", kab: 72, prop: 9 },
  { value: 2380, label: "Ciampel", kab: 72, prop: 9 },
  { value: 2381, label: "Cibuaya", kab: 72, prop: 9 },
  { value: 2382, label: "Cikampek", kab: 72, prop: 9 },
  { value: 2383, label: "Cilamaya Kulon", kab: 72, prop: 9 },
  { value: 2384, label: "Cilamaya Wetan", kab: 72, prop: 9 },
  { value: 2385, label: "Cilebar", kab: 72, prop: 9 },
  { value: 2386, label: "Jatisari", kab: 72, prop: 9 },
  { value: 2387, label: "Jayakerta", kab: 72, prop: 9 },
  { value: 2388, label: "Karawang Barat", kab: 72, prop: 9 },
  { value: 2389, label: "Karawang Timur", kab: 72, prop: 9 },
  { value: 2390, label: "Klari", kab: 72, prop: 9 },
  { value: 2391, label: "Kotabaru", kab: 72, prop: 9 },
  { value: 2392, label: "Kutawaluya", kab: 72, prop: 9 },
  { value: 2393, label: "Lemahabang", kab: 72, prop: 9 },
  { value: 2394, label: "Majalaya", kab: 72, prop: 9 },
  { value: 2395, label: "Pakisjaya", kab: 72, prop: 9 },
  { value: 2396, label: "Pangkalan", kab: 72, prop: 9 },
  { value: 2397, label: "Pedes", kab: 72, prop: 9 },
  { value: 2398, label: "Purwasari", kab: 72, prop: 9 },
  { value: 2399, label: "Rawamerta", kab: 72, prop: 9 },
  { value: 2400, label: "Rengasdengklok", kab: 72, prop: 9 },
  { value: 2401, label: "Talagasari", kab: 72, prop: 9 },
  { value: 2402, label: "Tegalwaru", kab: 72, prop: 9 },
  { value: 2403, label: "Telukjambe Barat", kab: 72, prop: 9 },
  { value: 2404, label: "Telukjambe Timur", kab: 72, prop: 9 },
  { value: 2405, label: "Tempuran", kab: 72, prop: 9 },
  { value: 2406, label: "Tirtajaya", kab: 72, prop: 9 },
  { value: 2407, label: "Tirtamulya", kab: 72, prop: 9 },
  { value: 2942, label: "Ciawigebang", kab: 73, prop: 9 },
  { value: 2943, label: "Cibeureum", kab: 73, prop: 9 },
  { value: 2944, label: "Cibingbin", kab: 73, prop: 9 },
  { value: 2945, label: "Cidahu", kab: 73, prop: 9 },
  { value: 2946, label: "Cigandamekar", kab: 73, prop: 9 },
  { value: 2947, label: "Cigugur", kab: 73, prop: 9 },
  { value: 2948, label: "Cilebak", kab: 73, prop: 9 },
  { value: 2949, label: "Cilimus", kab: 73, prop: 9 },
  { value: 2950, label: "Cimahi", kab: 73, prop: 9 },
  { value: 2951, label: "Ciniru", kab: 73, prop: 9 },
  { value: 2952, label: "Cipicung", kab: 73, prop: 9 },
  { value: 2953, label: "Ciwaru", kab: 73, prop: 9 },
  { value: 2954, label: "Darma", kab: 73, prop: 9 },
  { value: 2955, label: "Garawangi", kab: 73, prop: 9 },
  { value: 2956, label: "Hantara", kab: 73, prop: 9 },
  { value: 2957, label: "Jalaksana", kab: 73, prop: 9 },
  { value: 2958, label: "Japara", kab: 73, prop: 9 },
  { value: 2959, label: "Kadugede", kab: 73, prop: 9 },
  { value: 2960, label: "Kalimanggis", kab: 73, prop: 9 },
  { value: 2961, label: "Karangkancana", kab: 73, prop: 9 },
  { value: 2962, label: "Kramat Mulya", kab: 73, prop: 9 },
  { value: 2963, label: "Kuningan", kab: 73, prop: 9 },
  { value: 2964, label: "Lebakwangi", kab: 73, prop: 9 },
  { value: 2965, label: "Luragung", kab: 73, prop: 9 },
  { value: 2966, label: "Maleber", kab: 73, prop: 9 },
  { value: 2967, label: "Mandirancan", kab: 73, prop: 9 },
  { value: 2968, label: "Nusaherang", kab: 73, prop: 9 },
  { value: 2969, label: "Pancalang", kab: 73, prop: 9 },
  { value: 2970, label: "Pasawahan", kab: 73, prop: 9 },
  { value: 2971, label: "Selajambe", kab: 73, prop: 9 },
  { value: 2972, label: "Sindangagung", kab: 73, prop: 9 },
  { value: 2973, label: "Subang", kab: 73, prop: 9 },
  { value: 3553, label: "Argapura", kab: 74, prop: 9 },
  { value: 3554, label: "Banjaran", kab: 74, prop: 9 },
  { value: 3555, label: "Bantarujeg", kab: 74, prop: 9 },
  { value: 3556, label: "Cigasong", kab: 74, prop: 9 },
  { value: 3557, label: "Cikijing", kab: 74, prop: 9 },
  { value: 3558, label: "Cingambul", kab: 74, prop: 9 },
  { value: 3559, label: "Dawuan", kab: 74, prop: 9 },
  { value: 3560, label: "Jatitujuh", kab: 74, prop: 9 },
  { value: 3561, label: "Jatiwangi", kab: 74, prop: 9 },
  { value: 3562, label: "Kadipaten", kab: 74, prop: 9 },
  { value: 3563, label: "Kasokandel", kab: 74, prop: 9 },
  { value: 3564, label: "Kertajati", kab: 74, prop: 9 },
  { value: 3565, label: "Lemahsugih", kab: 74, prop: 9 },
  { value: 3566, label: "Leuwimunding", kab: 74, prop: 9 },
  { value: 3567, label: "Ligung", kab: 74, prop: 9 },
  { value: 3568, label: "Maja", kab: 74, prop: 9 },
  { value: 3569, label: "Majalengka", kab: 74, prop: 9 },
  { value: 3570, label: "Malausma", kab: 74, prop: 9 },
  { value: 3571, label: "Palasah", kab: 74, prop: 9 },
  { value: 3572, label: "Panyingkiran", kab: 74, prop: 9 },
  { value: 3573, label: "Rajagaluh", kab: 74, prop: 9 },
  { value: 3574, label: "Sindang", kab: 74, prop: 9 },
  { value: 3575, label: "Sindangwangi", kab: 74, prop: 9 },
  { value: 3576, label: "Sukahaji", kab: 74, prop: 9 },
  { value: 3577, label: "Sumberjaya", kab: 74, prop: 9 },
  { value: 3578, label: "Talaga", kab: 74, prop: 9 },
  { value: 4690, label: "Cigugur", kab: 75, prop: 9 },
  { value: 4691, label: "Cijulang", kab: 75, prop: 9 },
  { value: 4692, label: "Cimerak", kab: 75, prop: 9 },
  { value: 4693, label: "Kalipucang", kab: 75, prop: 9 },
  { value: 4694, label: "Langkaplancar", kab: 75, prop: 9 },
  { value: 4695, label: "Mangunjaya", kab: 75, prop: 9 },
  { value: 4696, label: "Padaherang", kab: 75, prop: 9 },
  { value: 4697, label: "Pangandaran", kab: 75, prop: 9 },
  { value: 4698, label: "Parigi", kab: 75, prop: 9 },
  { value: 4699, label: "Sidamulih", kab: 75, prop: 9 },
  { value: 5217, label: "Babakancikao", kab: 76, prop: 9 },
  { value: 5218, label: "Bojong", kab: 76, prop: 9 },
  { value: 5219, label: "Bungursari", kab: 76, prop: 9 },
  { value: 5220, label: "Campaka", kab: 76, prop: 9 },
  { value: 5221, label: "Cibatu", kab: 76, prop: 9 },
  { value: 5222, label: "Darangdan", kab: 76, prop: 9 },
  { value: 5223, label: "Jatiluhur", kab: 76, prop: 9 },
  { value: 5224, label: "Kiarapedes", kab: 76, prop: 9 },
  { value: 5225, label: "Maniis", kab: 76, prop: 9 },
  { value: 5226, label: "Pasawahan", kab: 76, prop: 9 },
  { value: 5227, label: "Plered", kab: 76, prop: 9 },
  { value: 5228, label: "Pondoksalam", kab: 76, prop: 9 },
  { value: 5229, label: "Purwakarta", kab: 76, prop: 9 },
  { value: 5230, label: "Sukasari", kab: 76, prop: 9 },
  { value: 5231, label: "Sukatani", kab: 76, prop: 9 },
  { value: 5232, label: "Tegal Waru", kab: 76, prop: 9 },
  { value: 5233, label: "Wanayasa", kab: 76, prop: 9 },
  { value: 5883, label: "Binong", kab: 77, prop: 9 },
  { value: 5884, label: "Blanakan", kab: 77, prop: 9 },
  { value: 5885, label: "Ciasem", kab: 77, prop: 9 },
  { value: 5886, label: "Ciater", kab: 77, prop: 9 },
  { value: 5887, label: "Cibogo", kab: 77, prop: 9 },
  { value: 5888, label: "Cijambe", kab: 77, prop: 9 },
  { value: 5889, label: "Cikaum", kab: 77, prop: 9 },
  { value: 5890, label: "Cipeundeuy", kab: 77, prop: 9 },
  { value: 5891, label: "Cipunagara", kab: 77, prop: 9 },
  { value: 5892, label: "Cisalak", kab: 77, prop: 9 },
  { value: 5893, label: "Compreng", kab: 77, prop: 9 },
  { value: 5894, label: "Dawuan", kab: 77, prop: 9 },
  { value: 5895, label: "Jalancagak", kab: 77, prop: 9 },
  { value: 5896, label: "Kalijati", kab: 77, prop: 9 },
  { value: 5897, label: "Kasomalang", kab: 77, prop: 9 },
  { value: 5898, label: "Legonkulon", kab: 77, prop: 9 },
  { value: 5899, label: "Pabuaran", kab: 77, prop: 9 },
  { value: 5900, label: "Pagaden", kab: 77, prop: 9 },
  { value: 5901, label: "Pagaden Barat", kab: 77, prop: 9 },
  { value: 5902, label: "Pamanukan", kab: 77, prop: 9 },
  { value: 5903, label: "Patokbeusi", kab: 77, prop: 9 },
  { value: 5904, label: "Purwadadi", kab: 77, prop: 9 },
  { value: 5905, label: "Pusakajaya", kab: 77, prop: 9 },
  { value: 5906, label: "Pusakanagara", kab: 77, prop: 9 },
  { value: 5907, label: "Sagalaherang", kab: 77, prop: 9 },
  { value: 5908, label: "Serangpanjang", kab: 77, prop: 9 },
  { value: 5909, label: "Subang", kab: 77, prop: 9 },
  { value: 5910, label: "Sukasari", kab: 77, prop: 9 },
  { value: 5911, label: "Tambakdahan", kab: 77, prop: 9 },
  { value: 5912, label: "Tanjungsiang", kab: 77, prop: 9 },
  { value: 5918, label: "Bantargadung", kab: 78, prop: 9 },
  { value: 5919, label: "Bojong Genteng", kab: 78, prop: 9 },
  { value: 5920, label: "Caringin", kab: 78, prop: 9 },
  { value: 5921, label: "Ciambar", kab: 78, prop: 9 },
  { value: 5922, label: "Cibadak", kab: 78, prop: 9 },
  { value: 5923, label: "Cibitung", kab: 78, prop: 9 },
  { value: 5924, label: "Cicantayan", kab: 78, prop: 9 },
  { value: 5925, label: "Cicurug", kab: 78, prop: 9 },
  { value: 5926, label: "Cidadap", kab: 78, prop: 9 },
  { value: 5927, label: "Cidahu", kab: 78, prop: 9 },
  { value: 5928, label: "Cidolog", kab: 78, prop: 9 },
  { value: 5929, label: "Ciemas", kab: 78, prop: 9 },
  { value: 5930, label: "Cikakak", kab: 78, prop: 9 },
  { value: 5931, label: "Cikembar", kab: 78, prop: 9 },
  { value: 5932, label: "Cikidang", kab: 78, prop: 9 },
  { value: 5933, label: "Cimanggu", kab: 78, prop: 9 },
  { value: 5934, label: "Ciracap", kab: 78, prop: 9 },
  { value: 5935, label: "Cireunghas", kab: 78, prop: 9 },
  { value: 5936, label: "Cisaat", kab: 78, prop: 9 },
  { value: 5937, label: "Cisolok", kab: 78, prop: 9 },
  { value: 5938, label: "Curugkembar", kab: 78, prop: 9 },
  { value: 5939, label: "Geger Bitung", kab: 78, prop: 9 },
  { value: 5940, label: "Gunung Guruh", kab: 78, prop: 9 },
  { value: 5941, label: "Jampang Kulon", kab: 78, prop: 9 },
  { value: 5942, label: "Jampang Tengah", kab: 78, prop: 9 },
  { value: 5943, label: "Kabandungan", kab: 78, prop: 9 },
  { value: 5944, label: "Kadudampit", kab: 78, prop: 9 },
  { value: 5945, label: "Kalapa Nunggal", kab: 78, prop: 9 },
  { value: 5946, label: "Kali Bunder", kab: 78, prop: 9 },
  { value: 5947, label: "Kebonpedes", kab: 78, prop: 9 },
  { value: 5948, label: "Lengkong", kab: 78, prop: 9 },
  { value: 5949, label: "Nagrak", kab: 78, prop: 9 },
  { value: 5950, label: "Nyalindung", kab: 78, prop: 9 },
  { value: 5951, label: "Pabuaran", kab: 78, prop: 9 },
  { value: 5952, label: "Parakan Salak", kab: 78, prop: 9 },
  { value: 5953, label: "Parung Kuda", kab: 78, prop: 9 },
  { value: 5954, label: "Pelabuhan/Palabuhan Ratu", kab: 78, prop: 9 },
  { value: 5955, label: "Purabaya", kab: 78, prop: 9 },
  { value: 5956, label: "Sagaranten", kab: 78, prop: 9 },
  { value: 5957, label: "Simpenan", kab: 78, prop: 9 },
  { value: 5958, label: "Sukabumi", kab: 78, prop: 9 },
  { value: 5959, label: "Sukalarang", kab: 78, prop: 9 },
  { value: 5960, label: "Sukaraja", kab: 78, prop: 9 },
  { value: 5961, label: "Surade", kab: 78, prop: 9 },
  { value: 5962, label: "Tegal Buleud", kab: 78, prop: 9 },
  { value: 5963, label: "Waluran", kab: 78, prop: 9 },
  { value: 5964, label: "Warung Kiara", kab: 78, prop: 9 },
  { value: 5965, label: "Baros", kab: 78, prop: 9 },
  { value: 5966, label: "Cibeureum", kab: 78, prop: 9 },
  { value: 5967, label: "Cikole", kab: 78, prop: 9 },
  { value: 5968, label: "Citamiang", kab: 78, prop: 9 },
  { value: 5969, label: "Gunung Puyuh", kab: 78, prop: 9 },
  { value: 5970, label: "Lembursitu", kab: 78, prop: 9 },
  { value: 5971, label: "Warudoyong", kab: 78, prop: 9 },
  { value: 6065, label: "Buahdua", kab: 79, prop: 9 },
  { value: 6066, label: "Cibugel", kab: 79, prop: 9 },
  { value: 6067, label: "Cimalaka", kab: 79, prop: 9 },
  { value: 6068, label: "Cimanggung", kab: 79, prop: 9 },
  { value: 6069, label: "Cisarua", kab: 79, prop: 9 },
  { value: 6070, label: "Cisitu", kab: 79, prop: 9 },
  { value: 6071, label: "Conggeang", kab: 79, prop: 9 },
  { value: 6072, label: "Darmaraja", kab: 79, prop: 9 },
  { value: 6073, label: "Ganeas", kab: 79, prop: 9 },
  { value: 6074, label: "Jatigede", kab: 79, prop: 9 },
  { value: 6075, label: "Jatinangor", kab: 79, prop: 9 },
  { value: 6076, label: "Jatinunggal", kab: 79, prop: 9 },
  { value: 6077, label: "Pamulihan", kab: 79, prop: 9 },
  { value: 6078, label: "Paseh", kab: 79, prop: 9 },
  { value: 6079, label: "Rancakalong", kab: 79, prop: 9 },
  { value: 6080, label: "Situraja", kab: 79, prop: 9 },
  { value: 6081, label: "Sukasari", kab: 79, prop: 9 },
  { value: 6082, label: "Sumedang Selatan", kab: 79, prop: 9 },
  { value: 6083, label: "Sumedang Utara", kab: 79, prop: 9 },
  { value: 6084, label: "Surian", kab: 79, prop: 9 },
  { value: 6085, label: "Tanjungkerta", kab: 79, prop: 9 },
  { value: 6086, label: "Tanjungmedar", kab: 79, prop: 9 },
  { value: 6087, label: "Tanjungsari", kab: 79, prop: 9 },
  { value: 6088, label: "Tomo", kab: 79, prop: 9 },
  { value: 6089, label: "Ujungjaya", kab: 79, prop: 9 },
  { value: 6090, label: "Wado", kab: 79, prop: 9 },
  { value: 6436, label: "Bantarkalong", kab: 80, prop: 9 },
  { value: 6437, label: "Bojongasih", kab: 80, prop: 9 },
  { value: 6438, label: "Bojonggambir", kab: 80, prop: 9 },
  { value: 6439, label: "Ciawi", kab: 80, prop: 9 },
  { value: 6440, label: "Cibalong", kab: 80, prop: 9 },
  { value: 6441, label: "Cigalontang", kab: 80, prop: 9 },
  { value: 6442, label: "Cikalong", kab: 80, prop: 9 },
  { value: 6443, label: "Cikatomas", kab: 80, prop: 9 },
  { value: 6444, label: "Cineam", kab: 80, prop: 9 },
  { value: 6445, label: "Cipatujah", kab: 80, prop: 9 },
  { value: 6446, label: "Cisayong", kab: 80, prop: 9 },
  { value: 6447, label: "Culamega", kab: 80, prop: 9 },
  { value: 6448, label: "Gunung Tanjung", kab: 80, prop: 9 },
  { value: 6449, label: "Jamanis", kab: 80, prop: 9 },
  { value: 6450, label: "Jatiwaras", kab: 80, prop: 9 },
  { value: 6451, label: "Kadipaten", kab: 80, prop: 9 },
  { value: 6452, label: "Karang Jaya", kab: 80, prop: 9 },
  { value: 6453, label: "Karangnunggal", kab: 80, prop: 9 },
  { value: 6454, label: "Leuwisari", kab: 80, prop: 9 },
  { value: 6455, label: "Mangunreja", kab: 80, prop: 9 },
  { value: 6456, label: "Manonjaya", kab: 80, prop: 9 },
  { value: 6457, label: "Padakembang", kab: 80, prop: 9 },
  { value: 6458, label: "Pagerageung", kab: 80, prop: 9 },
  { value: 6459, label: "Pancatengah", kab: 80, prop: 9 },
  { value: 6460, label: "Parungponteng", kab: 80, prop: 9 },
  { value: 6461, label: "Puspahiang", kab: 80, prop: 9 },
  { value: 6462, label: "Rajapolah", kab: 80, prop: 9 },
  { value: 6463, label: "Salawu", kab: 80, prop: 9 },
  { value: 6464, label: "Salopa", kab: 80, prop: 9 },
  { value: 6465, label: "Sariwangi", kab: 80, prop: 9 },
  { value: 6466, label: "Singaparna", kab: 80, prop: 9 },
  { value: 6467, label: "Sodonghilir", kab: 80, prop: 9 },
  { value: 6468, label: "Sukahening", kab: 80, prop: 9 },
  { value: 6469, label: "Sukaraja", kab: 80, prop: 9 },
  { value: 6470, label: "Sukarame", kab: 80, prop: 9 },
  { value: 6471, label: "Sukaratu", kab: 80, prop: 9 },
  { value: 6472, label: "Sukaresik", kab: 80, prop: 9 },
  { value: 6473, label: "Tanjungjaya", kab: 80, prop: 9 },
  { value: 6474, label: "Taraju", kab: 80, prop: 9 },
  { value: 6475, label: "Bungursari", kab: 80, prop: 9 },
  { value: 6476, label: "Cibeureum", kab: 80, prop: 9 },
  { value: 6477, label: "Cihideung", kab: 80, prop: 9 },
  { value: 6478, label: "Cipedes", kab: 80, prop: 9 },
  { value: 6479, label: "Indihiang", kab: 80, prop: 9 },
  { value: 6480, label: "Kawalu", kab: 80, prop: 9 },
  { value: 6481, label: "Mangkubumi", kab: 80, prop: 9 },
  { value: 6482, label: "Purbaratu", kab: 80, prop: 9 },
  { value: 6483, label: "Tamansari", kab: 80, prop: 9 },
  { value: 6484, label: "Tawang", kab: 80, prop: 9 },
  { value: 509, label: "Banjarmangu", kab: 81, prop: 10 },
  { value: 510, label: "Banjarnegara", kab: 81, prop: 10 },
  { value: 511, label: "Batur", kab: 81, prop: 10 },
  { value: 512, label: "Bawang", kab: 81, prop: 10 },
  { value: 513, label: "Kalibening", kab: 81, prop: 10 },
  { value: 514, label: "Karangkobar", kab: 81, prop: 10 },
  { value: 515, label: "Madukara", kab: 81, prop: 10 },
  { value: 516, label: "Mandiraja", kab: 81, prop: 10 },
  { value: 517, label: "Pagedongan", kab: 81, prop: 10 },
  { value: 518, label: "Pagentan", kab: 81, prop: 10 },
  { value: 519, label: "Pandanarum", kab: 81, prop: 10 },
  { value: 520, label: "Pejawaran", kab: 81, prop: 10 },
  { value: 521, label: "Punggelan", kab: 81, prop: 10 },
  { value: 522, label: "Purwonegoro", kab: 81, prop: 10 },
  { value: 523, label: "Purworejo Klampok", kab: 81, prop: 10 },
  { value: 524, label: "Rakit", kab: 81, prop: 10 },
  { value: 525, label: "Sigaluh", kab: 81, prop: 10 },
  { value: 526, label: "Susukan", kab: 81, prop: 10 },
  { value: 527, label: "Wanadadi (Wonodadi)", kab: 81, prop: 10 },
  { value: 528, label: "Wanayasa", kab: 81, prop: 10 },
  { value: 573, label: "Ajibarang", kab: 82, prop: 10 },
  { value: 574, label: "Banyumas", kab: 82, prop: 10 },
  { value: 575, label: "Baturaden", kab: 82, prop: 10 },
  { value: 576, label: "Cilongok", kab: 82, prop: 10 },
  { value: 577, label: "Gumelar", kab: 82, prop: 10 },
  { value: 578, label: "Jatilawang", kab: 82, prop: 10 },
  { value: 579, label: "Kalibagor", kab: 82, prop: 10 },
  { value: 580, label: "Karanglewas", kab: 82, prop: 10 },
  { value: 581, label: "Kebasen", kab: 82, prop: 10 },
  { value: 582, label: "Kedung Banteng", kab: 82, prop: 10 },
  { value: 583, label: "Kembaran", kab: 82, prop: 10 },
  { value: 584, label: "Kemranjen", kab: 82, prop: 10 },
  { value: 585, label: "Lumbir", kab: 82, prop: 10 },
  { value: 586, label: "Patikraja", kab: 82, prop: 10 },
  { value: 587, label: "Pekuncen", kab: 82, prop: 10 },
  { value: 588, label: "Purwojati", kab: 82, prop: 10 },
  { value: 589, label: "Purwokerto Barat", kab: 82, prop: 10 },
  { value: 590, label: "Purwokerto Selatan", kab: 82, prop: 10 },
  { value: 591, label: "Purwokerto Timur", kab: 82, prop: 10 },
  { value: 592, label: "Purwokerto Utara", kab: 82, prop: 10 },
  { value: 593, label: "Rawalo", kab: 82, prop: 10 },
  { value: 594, label: "Sokaraja", kab: 82, prop: 10 },
  { value: 595, label: "Somagede", kab: 82, prop: 10 },
  { value: 596, label: "Sumbang", kab: 82, prop: 10 },
  { value: 597, label: "Sumpiuh", kab: 82, prop: 10 },
  { value: 598, label: "Tambak", kab: 82, prop: 10 },
  { value: 599, label: "Wangon", kab: 82, prop: 10 },
  { value: 685, label: "Bandar", kab: 83, prop: 10 },
  { value: 686, label: "Banyuputih", kab: 83, prop: 10 },
  { value: 687, label: "Batang", kab: 83, prop: 10 },
  { value: 688, label: "Bawang", kab: 83, prop: 10 },
  { value: 689, label: "Blado", kab: 83, prop: 10 },
  { value: 690, label: "Gringsing", kab: 83, prop: 10 },
  { value: 691, label: "Kandeman", kab: 83, prop: 10 },
  { value: 692, label: "Limpung", kab: 83, prop: 10 },
  { value: 693, label: "Pecalungan", kab: 83, prop: 10 },
  { value: 694, label: "Reban", kab: 83, prop: 10 },
  { value: 695, label: "Subah", kab: 83, prop: 10 },
  { value: 696, label: "Tersono", kab: 83, prop: 10 },
  { value: 697, label: "Tulis", kab: 83, prop: 10 },
  { value: 698, label: "Warungasem", kab: 83, prop: 10 },
  { value: 699, label: "Wonotunggal", kab: 83, prop: 10 },
  { value: 999, label: "Banjarejo", kab: 84, prop: 10 },
  { value: 1000, label: "Blora kota", kab: 84, prop: 10 },
  { value: 1001, label: "Bogorejo", kab: 84, prop: 10 },
  { value: 1002, label: "Cepu", kab: 84, prop: 10 },
  { value: 1003, label: "Japah", kab: 84, prop: 10 },
  { value: 1004, label: "Jati", kab: 84, prop: 10 },
  { value: 1005, label: "Jepon", kab: 84, prop: 10 },
  { value: 1006, label: "Jiken", kab: 84, prop: 10 },
  { value: 1007, label: "Kedungtuban", kab: 84, prop: 10 },
  { value: 1008, label: "Kradenan", kab: 84, prop: 10 },
  { value: 1009, label: "Kunduran", kab: 84, prop: 10 },
  { value: 1010, label: "Ngawen", kab: 84, prop: 10 },
  { value: 1011, label: "Randublatung", kab: 84, prop: 10 },
  { value: 1012, label: "Sambong", kab: 84, prop: 10 },
  { value: 1013, label: "Todanan", kab: 84, prop: 10 },
  { value: 1014, label: "Tunjungan", kab: 84, prop: 10 },
  { value: 1240, label: "Ampel", kab: 85, prop: 10 },
  { value: 1241, label: "Andong", kab: 85, prop: 10 },
  { value: 1242, label: "Banyudono", kab: 85, prop: 10 },
  { value: 1243, label: "Boyolali", kab: 85, prop: 10 },
  { value: 1244, label: "Cepogo", kab: 85, prop: 10 },
  { value: 1245, label: "Juwangi", kab: 85, prop: 10 },
  { value: 1246, label: "Karanggede", kab: 85, prop: 10 },
  { value: 1247, label: "Kemusu", kab: 85, prop: 10 },
  { value: 1248, label: "Klego", kab: 85, prop: 10 },
  { value: 1249, label: "Mojosongo", kab: 85, prop: 10 },
  { value: 1250, label: "Musuk", kab: 85, prop: 10 },
  { value: 1251, label: "Ngemplak", kab: 85, prop: 10 },
  { value: 1252, label: "Nogosari", kab: 85, prop: 10 },
  { value: 1253, label: "Sambi", kab: 85, prop: 10 },
  { value: 1254, label: "Sawit", kab: 85, prop: 10 },
  { value: 1255, label: "Selo", kab: 85, prop: 10 },
  { value: 1256, label: "Simo", kab: 85, prop: 10 },
  { value: 1257, label: "Teras", kab: 85, prop: 10 },
  { value: 1258, label: "Wonosegoro", kab: 85, prop: 10 },
  { value: 1259, label: "Banjarharjo", kab: 86, prop: 10 },
  { value: 1260, label: "Bantarkawung", kab: 86, prop: 10 },
  { value: 1261, label: "Brebes", kab: 86, prop: 10 },
  { value: 1262, label: "Bulakamba", kab: 86, prop: 10 },
  { value: 1263, label: "Bumiayu", kab: 86, prop: 10 },
  { value: 1264, label: "Jatibarang", kab: 86, prop: 10 },
  { value: 1265, label: "Kersana", kab: 86, prop: 10 },
  { value: 1266, label: "Ketanggungan", kab: 86, prop: 10 },
  { value: 1267, label: "Larangan", kab: 86, prop: 10 },
  { value: 1268, label: "Losari", kab: 86, prop: 10 },
  { value: 1269, label: "Paguyangan", kab: 86, prop: 10 },
  { value: 1270, label: "Salem", kab: 86, prop: 10 },
  { value: 1271, label: "Sirampog", kab: 86, prop: 10 },
  { value: 1272, label: "Songgom", kab: 86, prop: 10 },
  { value: 1273, label: "Tanjung", kab: 86, prop: 10 },
  { value: 1274, label: "Tonjong", kab: 86, prop: 10 },
  { value: 1275, label: "Wanasari", kab: 86, prop: 10 },
  { value: 1437, label: "Adipala", kab: 87, prop: 10 },
  { value: 1438, label: "Bantarsari", kab: 87, prop: 10 },
  { value: 1439, label: "Binangun", kab: 87, prop: 10 },
  { value: 1440, label: "Cilacap Selatan", kab: 87, prop: 10 },
  { value: 1441, label: "Cilacap Tengah", kab: 87, prop: 10 },
  { value: 1442, label: "Cilacap Utara", kab: 87, prop: 10 },
  { value: 1443, label: "Cimanggu", kab: 87, prop: 10 },
  { value: 1444, label: "Cipari", kab: 87, prop: 10 },
  { value: 1445, label: "Dayeuhluhur", kab: 87, prop: 10 },
  { value: 1446, label: "Gandrungmangu", kab: 87, prop: 10 },
  { value: 1447, label: "Jeruklegi", kab: 87, prop: 10 },
  { value: 1448, label: "Kampung Laut", kab: 87, prop: 10 },
  { value: 1449, label: "Karangpucung", kab: 87, prop: 10 },
  { value: 1450, label: "Kawunganten", kab: 87, prop: 10 },
  { value: 1451, label: "Kedungreja", kab: 87, prop: 10 },
  { value: 1452, label: "Kesugihan", kab: 87, prop: 10 },
  { value: 1453, label: "Kroya", kab: 87, prop: 10 },
  { value: 1454, label: "Majenang", kab: 87, prop: 10 },
  { value: 1455, label: "Maos", kab: 87, prop: 10 },
  { value: 1456, label: "Nusawungu", kab: 87, prop: 10 },
  { value: 1457, label: "Patimuan", kab: 87, prop: 10 },
  { value: 1458, label: "Sampang", kab: 87, prop: 10 },
  { value: 1459, label: "Sidareja", kab: 87, prop: 10 },
  { value: 1460, label: "Wanareja", kab: 87, prop: 10 },
  { value: 1559, label: "Bonang", kab: 88, prop: 10 },
  { value: 1560, label: "Demak", kab: 88, prop: 10 },
  { value: 1561, label: "Dempet", kab: 88, prop: 10 },
  { value: 1562, label: "Gajah", kab: 88, prop: 10 },
  { value: 1563, label: "Guntur", kab: 88, prop: 10 },
  { value: 1564, label: "Karang Tengah", kab: 88, prop: 10 },
  { value: 1565, label: "Karanganyar", kab: 88, prop: 10 },
  { value: 1566, label: "Karangawen", kab: 88, prop: 10 },
  { value: 1567, label: "Kebonagung", kab: 88, prop: 10 },
  { value: 1568, label: "Mijen", kab: 88, prop: 10 },
  { value: 1569, label: "Mranggen", kab: 88, prop: 10 },
  { value: 1570, label: "Sayung", kab: 88, prop: 10 },
  { value: 1571, label: "Wedung", kab: 88, prop: 10 },
  { value: 1572, label: "Wonosalam", kab: 88, prop: 10 },
  { value: 1846, label: "Brati", kab: 89, prop: 10 },
  { value: 1847, label: "Gabus", kab: 89, prop: 10 },
  { value: 1848, label: "Geyer", kab: 89, prop: 10 },
  { value: 1849, label: "Godong", kab: 89, prop: 10 },
  { value: 1850, label: "Grobogan", kab: 89, prop: 10 },
  { value: 1851, label: "Gubug", kab: 89, prop: 10 },
  { value: 1852, label: "Karangrayung", kab: 89, prop: 10 },
  { value: 1853, label: "Kedungjati", kab: 89, prop: 10 },
  { value: 1854, label: "Klambu", kab: 89, prop: 10 },
  { value: 1855, label: "Kradenan", kab: 89, prop: 10 },
  { value: 1856, label: "Ngaringan", kab: 89, prop: 10 },
  { value: 1857, label: "Penawangan", kab: 89, prop: 10 },
  { value: 1858, label: "Pulokulon", kab: 89, prop: 10 },
  { value: 1859, label: "Purwodadi", kab: 89, prop: 10 },
  { value: 1860, label: "Tanggungharjo", kab: 89, prop: 10 },
  { value: 1861, label: "Tawangharjo", kab: 89, prop: 10 },
  { value: 1862, label: "Tegowanu", kab: 89, prop: 10 },
  { value: 1863, label: "Toroh", kab: 89, prop: 10 },
  { value: 1864, label: "Wirosari", kab: 89, prop: 10 },
  { value: 2248, label: "Bangsri", kab: 90, prop: 10 },
  { value: 2249, label: "Batealit", kab: 90, prop: 10 },
  { value: 2250, label: "Donorojo", kab: 90, prop: 10 },
  { value: 2251, label: "Jepara", kab: 90, prop: 10 },
  { value: 2252, label: "Kalinyamatan", kab: 90, prop: 10 },
  { value: 2253, label: "Karimunjawa", kab: 90, prop: 10 },
  { value: 2254, label: "Kedung", kab: 90, prop: 10 },
  { value: 2255, label: "Keling", kab: 90, prop: 10 },
  { value: 2256, label: "Kembang", kab: 90, prop: 10 },
  { value: 2257, label: "Mayong", kab: 90, prop: 10 },
  { value: 2258, label: "Mlonggo", kab: 90, prop: 10 },
  { value: 2259, label: "Nalumsari", kab: 90, prop: 10 },
  { value: 2260, label: "Pakis Aji", kab: 90, prop: 10 },
  { value: 2261, label: "Pecangaan", kab: 90, prop: 10 },
  { value: 2262, label: "Tahunan", kab: 90, prop: 10 },
  { value: 2263, label: "Welahan", kab: 90, prop: 10 },
  { value: 2353, label: "Colomadu", kab: 91, prop: 10 },
  { value: 2354, label: "Gondangrejo", kab: 91, prop: 10 },
  { value: 2355, label: "Jaten", kab: 91, prop: 10 },
  { value: 2356, label: "Jatipuro", kab: 91, prop: 10 },
  { value: 2357, label: "Jatiyoso", kab: 91, prop: 10 },
  { value: 2358, label: "Jenawi", kab: 91, prop: 10 },
  { value: 2359, label: "Jumantono", kab: 91, prop: 10 },
  { value: 2360, label: "Jumapolo", kab: 91, prop: 10 },
  { value: 2361, label: "Karanganyar", kab: 91, prop: 10 },
  { value: 2362, label: "Karangpandan", kab: 91, prop: 10 },
  { value: 2363, label: "Kebakkramat", kab: 91, prop: 10 },
  { value: 2364, label: "Kerjo", kab: 91, prop: 10 },
  { value: 2365, label: "Matesih", kab: 91, prop: 10 },
  { value: 2366, label: "Mojogedang", kab: 91, prop: 10 },
  { value: 2367, label: "Ngargoyoso", kab: 91, prop: 10 },
  { value: 2368, label: "Tasikmadu", kab: 91, prop: 10 },
  { value: 2369, label: "Tawangmangu", kab: 91, prop: 10 },
  { value: 2471, label: "Adimulyo", kab: 92, prop: 10 },
  { value: 2472, label: "Alian/Aliyan", kab: 92, prop: 10 },
  { value: 2473, label: "Ambal", kab: 92, prop: 10 },
  { value: 2474, label: "Ayah", kab: 92, prop: 10 },
  { value: 2475, label: "Bonorowo", kab: 92, prop: 10 },
  { value: 2476, label: "Buayan", kab: 92, prop: 10 },
  { value: 2477, label: "Buluspesantren", kab: 92, prop: 10 },
  { value: 2478, label: "Gombong", kab: 92, prop: 10 },
  { value: 2479, label: "Karanganyar", kab: 92, prop: 10 },
  { value: 2480, label: "Karanggayam", kab: 92, prop: 10 },
  { value: 2481, label: "Karangsambung", kab: 92, prop: 10 },
  { value: 2482, label: "Kebumen", kab: 92, prop: 10 },
  { value: 2483, label: "Klirong", kab: 92, prop: 10 },
  { value: 2484, label: "Kutowinangun", kab: 92, prop: 10 },
  { value: 2485, label: "Kuwarasan", kab: 92, prop: 10 },
  { value: 2486, label: "Mirit", kab: 92, prop: 10 },
  { value: 2487, label: "Padureso", kab: 92, prop: 10 },
  { value: 2488, label: "Pejagoan", kab: 92, prop: 10 },
  { value: 2489, label: "Petanahan", kab: 92, prop: 10 },
  { value: 2490, label: "Poncowarno", kab: 92, prop: 10 },
  { value: 2491, label: "Prembun", kab: 92, prop: 10 },
  { value: 2492, label: "Puring", kab: 92, prop: 10 },
  { value: 2493, label: "Rowokele", kab: 92, prop: 10 },
  { value: 2494, label: "Sadang", kab: 92, prop: 10 },
  { value: 2495, label: "Sempor", kab: 92, prop: 10 },
  { value: 2496, label: "Sruweng", kab: 92, prop: 10 },
  { value: 2533, label: "Boja", kab: 93, prop: 10 },
  { value: 2534, label: "Brangsong", kab: 93, prop: 10 },
  { value: 2535, label: "Cepiring", kab: 93, prop: 10 },
  { value: 2536, label: "Gemuh", kab: 93, prop: 10 },
  { value: 2537, label: "Kaliwungu", kab: 93, prop: 10 },
  { value: 2538, label: "Kaliwungu Selatan", kab: 93, prop: 10 },
  { value: 2539, label: "Kangkung", kab: 93, prop: 10 },
  { value: 2540, label: "Kendal", kab: 93, prop: 10 },
  { value: 2541, label: "Limbangan", kab: 93, prop: 10 },
  { value: 2542, label: "Ngampel", kab: 93, prop: 10 },
  { value: 2543, label: "Pagerruyung", kab: 93, prop: 10 },
  { value: 2544, label: "Patean", kab: 93, prop: 10 },
  { value: 2545, label: "Patebon", kab: 93, prop: 10 },
  { value: 2546, label: "Pegandon", kab: 93, prop: 10 },
  { value: 2547, label: "Plantungan", kab: 93, prop: 10 },
  { value: 2548, label: "Ringinarum", kab: 93, prop: 10 },
  { value: 2549, label: "Rowosari", kab: 93, prop: 10 },
  { value: 2550, label: "Singorojo", kab: 93, prop: 10 },
  { value: 2551, label: "Sukorejo", kab: 93, prop: 10 },
  { value: 2552, label: "Weleri", kab: 93, prop: 10 },
  { value: 2722, label: "Bayat", kab: 94, prop: 10 },
  { value: 2723, label: "Cawas", kab: 94, prop: 10 },
  { value: 2724, label: "Ceper", kab: 94, prop: 10 },
  { value: 2725, label: "Delanggu", kab: 94, prop: 10 },
  { value: 2726, label: "Gantiwarno", kab: 94, prop: 10 },
  { value: 2727, label: "Jatinom", kab: 94, prop: 10 },
  { value: 2728, label: "Jogonalan", kab: 94, prop: 10 },
  { value: 2729, label: "Juwiring", kab: 94, prop: 10 },
  { value: 2730, label: "Kalikotes", kab: 94, prop: 10 },
  { value: 2731, label: "Karanganom", kab: 94, prop: 10 },
  { value: 2732, label: "Karangdowo", kab: 94, prop: 10 },
  { value: 2733, label: "Karangnongko", kab: 94, prop: 10 },
  { value: 2734, label: "Kebonarum", kab: 94, prop: 10 },
  { value: 2735, label: "Kemalang", kab: 94, prop: 10 },
  { value: 2736, label: "Klaten Selatan", kab: 94, prop: 10 },
  { value: 2737, label: "Klaten Tengah", kab: 94, prop: 10 },
  { value: 2738, label: "Klaten Utara", kab: 94, prop: 10 },
  { value: 2739, label: "Manisrenggo", kab: 94, prop: 10 },
  { value: 2740, label: "Ngawen", kab: 94, prop: 10 },
  { value: 2741, label: "Pedan", kab: 94, prop: 10 },
  { value: 2742, label: "Polanharjo", kab: 94, prop: 10 },
  { value: 2743, label: "Prambanan", kab: 94, prop: 10 },
  { value: 2744, label: "Trucuk", kab: 94, prop: 10 },
  { value: 2745, label: "Tulung", kab: 94, prop: 10 },
  { value: 2746, label: "Wedi", kab: 94, prop: 10 },
  { value: 2747, label: "Wonosari", kab: 94, prop: 10 },
  { value: 2921, label: "Bae", kab: 95, prop: 10 },
  { value: 2922, label: "Dawe", kab: 95, prop: 10 },
  { value: 2923, label: "Gebog", kab: 95, prop: 10 },
  { value: 2924, label: "Jati", kab: 95, prop: 10 },
  { value: 2925, label: "Jekulo", kab: 95, prop: 10 },
  { value: 2926, label: "Kaliwungu", kab: 95, prop: 10 },
  { value: 2927, label: "Kudus Kota", kab: 95, prop: 10 },
  { value: 2928, label: "Mejobo", kab: 95, prop: 10 },
  { value: 2929, label: "Undaan", kab: 95, prop: 10 },
  { value: 3511, label: "Bandongan", kab: 96, prop: 10 },
  { value: 3512, label: "Borobudur", kab: 96, prop: 10 },
  { value: 3513, label: "Candimulyo", kab: 96, prop: 10 },
  { value: 3514, label: "Dukun", kab: 96, prop: 10 },
  { value: 3515, label: "Grabag", kab: 96, prop: 10 },
  { value: 3516, label: "Kajoran", kab: 96, prop: 10 },
  { value: 3517, label: "Kaliangkrik", kab: 96, prop: 10 },
  { value: 3518, label: "Mertoyudan", kab: 96, prop: 10 },
  { value: 3519, label: "Mungkid", kab: 96, prop: 10 },
  { value: 3520, label: "Muntilan", kab: 96, prop: 10 },
  { value: 3521, label: "Ngablak", kab: 96, prop: 10 },
  { value: 3522, label: "Ngluwar", kab: 96, prop: 10 },
  { value: 3523, label: "Pakis", kab: 96, prop: 10 },
  { value: 3524, label: "Salam", kab: 96, prop: 10 },
  { value: 3525, label: "Salaman", kab: 96, prop: 10 },
  { value: 3526, label: "Sawangan", kab: 96, prop: 10 },
  { value: 3527, label: "Secang", kab: 96, prop: 10 },
  { value: 3528, label: "Srumbung", kab: 96, prop: 10 },
  { value: 3529, label: "Tegalrejo", kab: 96, prop: 10 },
  { value: 3530, label: "Tempuran", kab: 96, prop: 10 },
  { value: 3531, label: "Windusari", kab: 96, prop: 10 },
  { value: 3532, label: "Magelang Selatan", kab: 96, prop: 10 },
  { value: 3533, label: "Magelang Tengah", kab: 96, prop: 10 },
  { value: 3534, label: "Magelang Utara", kab: 96, prop: 10 },
  { value: 4821, label: "Batangan", kab: 97, prop: 10 },
  { value: 4822, label: "Cluwak", kab: 97, prop: 10 },
  { value: 4823, label: "Dukuhseti", kab: 97, prop: 10 },
  { value: 4824, label: "Gabus", kab: 97, prop: 10 },
  { value: 4825, label: "Gembong", kab: 97, prop: 10 },
  { value: 4826, label: "Gunungwungkal", kab: 97, prop: 10 },
  { value: 4827, label: "Jaken", kab: 97, prop: 10 },
  { value: 4828, label: "Jakenan", kab: 97, prop: 10 },
  { value: 4829, label: "Juwana", kab: 97, prop: 10 },
  { value: 4830, label: "Kayen", kab: 97, prop: 10 },
  { value: 4831, label: "Margorejo", kab: 97, prop: 10 },
  { value: 4832, label: "Margoyoso", kab: 97, prop: 10 },
  { value: 4833, label: "Pati", kab: 97, prop: 10 },
  { value: 4834, label: "Pucakwangi", kab: 97, prop: 10 },
  { value: 4835, label: "Sukolilo", kab: 97, prop: 10 },
  { value: 4836, label: "Tambakromo", kab: 97, prop: 10 },
  { value: 4837, label: "Tayu", kab: 97, prop: 10 },
  { value: 4838, label: "Tlogowungu", kab: 97, prop: 10 },
  { value: 4839, label: "Trangkil", kab: 97, prop: 10 },
  { value: 4840, label: "Wedarijaksa", kab: 97, prop: 10 },
  { value: 4841, label: "Winong", kab: 97, prop: 10 },
  { value: 4891, label: "Bojong", kab: 98, prop: 10 },
  { value: 4892, label: "Buaran", kab: 98, prop: 10 },
  { value: 4893, label: "Doro", kab: 98, prop: 10 },
  { value: 4894, label: "Kajen", kab: 98, prop: 10 },
  { value: 4895, label: "Kandangserang", kab: 98, prop: 10 },
  { value: 4896, label: "Karanganyar", kab: 98, prop: 10 },
  { value: 4897, label: "Karangdadap", kab: 98, prop: 10 },
  { value: 4898, label: "Kedungwuni", kab: 98, prop: 10 },
  { value: 4899, label: "Kesesi", kab: 98, prop: 10 },
  { value: 4900, label: "Lebakbarang", kab: 98, prop: 10 },
  { value: 4901, label: "Paninggaran", kab: 98, prop: 10 },
  { value: 4902, label: "Petungkriono/Petungkriyono", kab: 98, prop: 10 },
  { value: 4903, label: "Siwalan", kab: 98, prop: 10 },
  { value: 4904, label: "Sragi", kab: 98, prop: 10 },
  { value: 4905, label: "Talun", kab: 98, prop: 10 },
  { value: 4906, label: "Tirto", kab: 98, prop: 10 },
  { value: 4907, label: "Wiradesa", kab: 98, prop: 10 },
  { value: 4908, label: "Wonokerto", kab: 98, prop: 10 },
  { value: 4909, label: "Wonopringgo", kab: 98, prop: 10 },
  { value: 4910, label: "Pekalongan Barat", kab: 98, prop: 10 },
  { value: 4911, label: "Pekalongan Selatan", kab: 98, prop: 10 },
  { value: 4912, label: "Pekalongan Timur", kab: 98, prop: 10 },
  { value: 4913, label: "Pekalongan Utara", kab: 98, prop: 10 },
  { value: 4938, label: "Ampelgading", kab: 99, prop: 10 },
  { value: 4939, label: "Bantarbolang", kab: 99, prop: 10 },
  { value: 4940, label: "Belik", kab: 99, prop: 10 },
  { value: 4941, label: "Bodeh", kab: 99, prop: 10 },
  { value: 4942, label: "Comal", kab: 99, prop: 10 },
  { value: 4943, label: "Moga", kab: 99, prop: 10 },
  { value: 4944, label: "Pemalang", kab: 99, prop: 10 },
  { value: 4945, label: "Petarukan", kab: 99, prop: 10 },
  { value: 4946, label: "Pulosari", kab: 99, prop: 10 },
  { value: 4947, label: "Randudongkal", kab: 99, prop: 10 },
  { value: 4948, label: "Taman", kab: 99, prop: 10 },
  { value: 4949, label: "Ulujami", kab: 99, prop: 10 },
  { value: 4950, label: "Warungpring", kab: 99, prop: 10 },
  { value: 4951, label: "Watukumpul", kab: 99, prop: 10 },
  { value: 5199, label: "Bobotsari", kab: 100, prop: 10 },
  { value: 5200, label: "Bojongsari", kab: 100, prop: 10 },
  { value: 5201, label: "Bukateja", kab: 100, prop: 10 },
  { value: 5202, label: "Kaligondang", kab: 100, prop: 10 },
  { value: 5203, label: "Kalimanah", kab: 100, prop: 10 },
  { value: 5204, label: "Karanganyar", kab: 100, prop: 10 },
  { value: 5205, label: "Karangjambu", kab: 100, prop: 10 },
  { value: 5206, label: "Karangmoncol", kab: 100, prop: 10 },
  { value: 5207, label: "Karangreja", kab: 100, prop: 10 },
  { value: 5208, label: "Kejobong", kab: 100, prop: 10 },
  { value: 5209, label: "Kemangkon", kab: 100, prop: 10 },
  { value: 5210, label: "Kertanegara", kab: 100, prop: 10 },
  { value: 5211, label: "Kutasari", kab: 100, prop: 10 },
  { value: 5212, label: "Mrebet", kab: 100, prop: 10 },
  { value: 5213, label: "Padamara", kab: 100, prop: 10 },
  { value: 5214, label: "Pengadegan", kab: 100, prop: 10 },
  { value: 5215, label: "Purbalingga", kab: 100, prop: 10 },
  { value: 5216, label: "Rembang", kab: 100, prop: 10 },
  { value: 5234, label: "Bagelen", kab: 101, prop: 10 },
  { value: 5235, label: "Banyuurip", kab: 101, prop: 10 },
  { value: 5236, label: "Bayan", kab: 101, prop: 10 },
  { value: 5237, label: "Bener", kab: 101, prop: 10 },
  { value: 5238, label: "Bruno", kab: 101, prop: 10 },
  { value: 5239, label: "Butuh", kab: 101, prop: 10 },
  { value: 5240, label: "Gebang", kab: 101, prop: 10 },
  { value: 5241, label: "Grabag", kab: 101, prop: 10 },
  { value: 5242, label: "Kaligesing", kab: 101, prop: 10 },
  { value: 5243, label: "Kemiri", kab: 101, prop: 10 },
  { value: 5244, label: "Kutoarjo", kab: 101, prop: 10 },
  { value: 5245, label: "Loano", kab: 101, prop: 10 },
  { value: 5246, label: "Ngombol", kab: 101, prop: 10 },
  { value: 5247, label: "Pituruh", kab: 101, prop: 10 },
  { value: 5248, label: "Purwodadi", kab: 101, prop: 10 },
  { value: 5249, label: "Purworejo", kab: 101, prop: 10 },
  { value: 5289, label: "Bulu", kab: 102, prop: 10 },
  { value: 5290, label: "Gunem", kab: 102, prop: 10 },
  { value: 5291, label: "Kaliori", kab: 102, prop: 10 },
  { value: 5292, label: "Kragan", kab: 102, prop: 10 },
  { value: 5293, label: "Lasem", kab: 102, prop: 10 },
  { value: 5294, label: "Pamotan", kab: 102, prop: 10 },
  { value: 5295, label: "Pancur", kab: 102, prop: 10 },
  { value: 5296, label: "Rembang", kab: 102, prop: 10 },
  { value: 5297, label: "Sale", kab: 102, prop: 10 },
  { value: 5298, label: "Sarang", kab: 102, prop: 10 },
  { value: 5299, label: "Sedan", kab: 102, prop: 10 },
  { value: 5300, label: "Sluke", kab: 102, prop: 10 },
  { value: 5301, label: "Sulang", kab: 102, prop: 10 },
  { value: 5302, label: "Sumber", kab: 102, prop: 10 },
  { value: 5352, label: "Argomulyo", kab: 103, prop: 10 },
  { value: 5353, label: "Sidomukti", kab: 103, prop: 10 },
  { value: 5354, label: "Sidorejo", kab: 103, prop: 10 },
  { value: 5355, label: "Tingkir", kab: 103, prop: 10 },
  { value: 5479, label: "Ambarawa", kab: 104, prop: 10 },
  { value: 5480, label: "Bancak", kab: 104, prop: 10 },
  { value: 5481, label: "Bandungan", kab: 104, prop: 10 },
  { value: 5482, label: "Banyubiru", kab: 104, prop: 10 },
  { value: 5483, label: "Bawen", kab: 104, prop: 10 },
  { value: 5484, label: "Bergas", kab: 104, prop: 10 },
  { value: 5485, label: "Bringin", kab: 104, prop: 10 },
  { value: 5486, label: "Getasan", kab: 104, prop: 10 },
  { value: 5487, label: "Jambu", kab: 104, prop: 10 },
  { value: 5488, label: "Kaliwungu", kab: 104, prop: 10 },
  { value: 5489, label: "Pabelan", kab: 104, prop: 10 },
  { value: 5490, label: "Pringapus", kab: 104, prop: 10 },
  { value: 5491, label: "Sumowono", kab: 104, prop: 10 },
  { value: 5492, label: "Suruh", kab: 104, prop: 10 },
  { value: 5493, label: "Susukan", kab: 104, prop: 10 },
  { value: 5494, label: "Tengaran", kab: 104, prop: 10 },
  { value: 5495, label: "Tuntang", kab: 104, prop: 10 },
  { value: 5496, label: "Ungaran Barat", kab: 104, prop: 10 },
  { value: 5497, label: "Ungaran Timur", kab: 104, prop: 10 },
  { value: 5498, label: "Banyumanik", kab: 104, prop: 10 },
  { value: 5499, label: "Candisari", kab: 104, prop: 10 },
  { value: 5500, label: "Gajah Mungkur", kab: 104, prop: 10 },
  { value: 5501, label: "Gayamsari", kab: 104, prop: 10 },
  { value: 5502, label: "Genuk", kab: 104, prop: 10 },
  { value: 5503, label: "Gunungpati", kab: 104, prop: 10 },
  { value: 5504, label: "Mijen", kab: 104, prop: 10 },
  { value: 5505, label: "Ngaliyan", kab: 104, prop: 10 },
  { value: 5506, label: "Pedurungan", kab: 104, prop: 10 },
  { value: 5507, label: "Semarang Barat", kab: 104, prop: 10 },
  { value: 5508, label: "Semarang Selatan", kab: 104, prop: 10 },
  { value: 5509, label: "Semarang Tengah", kab: 104, prop: 10 },
  { value: 5510, label: "Semarang Timur", kab: 104, prop: 10 },
  { value: 5511, label: "Semarang Utara", kab: 104, prop: 10 },
  { value: 5512, label: "Tembalang", kab: 104, prop: 10 },
  { value: 5513, label: "Tugu", kab: 104, prop: 10 },
  { value: 5863, label: "Gemolong", kab: 105, prop: 10 },
  { value: 5864, label: "Gesi", kab: 105, prop: 10 },
  { value: 5865, label: "Gondang", kab: 105, prop: 10 },
  { value: 5866, label: "Jenar", kab: 105, prop: 10 },
  { value: 5867, label: "Kalijambe", kab: 105, prop: 10 },
  { value: 5868, label: "Karangmalang", kab: 105, prop: 10 },
  { value: 5869, label: "Kedawung", kab: 105, prop: 10 },
  { value: 5870, label: "Masaran", kab: 105, prop: 10 },
  { value: 5871, label: "Miri", kab: 105, prop: 10 },
  { value: 5872, label: "Mondokan", kab: 105, prop: 10 },
  { value: 5873, label: "Ngrampal", kab: 105, prop: 10 },
  { value: 5874, label: "Plupuh", kab: 105, prop: 10 },
  { value: 5875, label: "Sambirejo", kab: 105, prop: 10 },
  { value: 5876, label: "Sambung Macan", kab: 105, prop: 10 },
  { value: 5877, label: "Sidoharjo", kab: 105, prop: 10 },
  { value: 5878, label: "Sragen", kab: 105, prop: 10 },
  { value: 5879, label: "Sukodono", kab: 105, prop: 10 },
  { value: 5880, label: "Sumberlawang", kab: 105, prop: 10 },
  { value: 5881, label: "Tangen", kab: 105, prop: 10 },
  { value: 5882, label: "Tanon", kab: 105, prop: 10 },
  { value: 5977, label: "Baki", kab: 106, prop: 10 },
  { value: 5978, label: "Bendosari", kab: 106, prop: 10 },
  { value: 5979, label: "Bulu", kab: 106, prop: 10 },
  { value: 5980, label: "Gatak", kab: 106, prop: 10 },
  { value: 5981, label: "Grogol", kab: 106, prop: 10 },
  { value: 5982, label: "Kartasura", kab: 106, prop: 10 },
  { value: 5983, label: "Mojolaban", kab: 106, prop: 10 },
  { value: 5984, label: "Nguter", kab: 106, prop: 10 },
  { value: 5985, label: "Polokarto", kab: 106, prop: 10 },
  { value: 5986, label: "Sukoharjo", kab: 106, prop: 10 },
  { value: 5987, label: "Tawangsari", kab: 106, prop: 10 },
  { value: 5988, label: "Weru", kab: 106, prop: 10 },
  { value: 6162, label: "Banjarsari", kab: 107, prop: 10 },
  { value: 6163, label: "Jebres", kab: 107, prop: 10 },
  { value: 6164, label: "Laweyan", kab: 107, prop: 10 },
  { value: 6165, label: "Pasar Kliwon", kab: 107, prop: 10 },
  { value: 6166, label: "Serengan", kab: 107, prop: 10 },
  { value: 6502, label: "Adiwerna", kab: 108, prop: 10 },
  { value: 6503, label: "Balapulang", kab: 108, prop: 10 },
  { value: 6504, label: "Bojong", kab: 108, prop: 10 },
  { value: 6505, label: "Bumijawa", kab: 108, prop: 10 },
  { value: 6506, label: "Dukuhturi", kab: 108, prop: 10 },
  { value: 6507, label: "Dukuhwaru", kab: 108, prop: 10 },
  { value: 6508, label: "Jatinegara", kab: 108, prop: 10 },
  { value: 6509, label: "Kedung Banteng", kab: 108, prop: 10 },
  { value: 6510, label: "Kramat", kab: 108, prop: 10 },
  { value: 6511, label: "Lebaksiu", kab: 108, prop: 10 },
  { value: 6512, label: "Margasari", kab: 108, prop: 10 },
  { value: 6513, label: "Pagerbarang", kab: 108, prop: 10 },
  { value: 6514, label: "Pangkah", kab: 108, prop: 10 },
  { value: 6515, label: "Slawi", kab: 108, prop: 10 },
  { value: 6516, label: "Surodadi", kab: 108, prop: 10 },
  { value: 6517, label: "Talang", kab: 108, prop: 10 },
  { value: 6518, label: "Tarub", kab: 108, prop: 10 },
  { value: 6519, label: "Warurejo", kab: 108, prop: 10 },
  { value: 6520, label: "Margadana", kab: 108, prop: 10 },
  { value: 6521, label: "Tegal Barat", kab: 108, prop: 10 },
  { value: 6522, label: "Tegal Selatan", kab: 108, prop: 10 },
  { value: 6523, label: "Tegal Timur", kab: 108, prop: 10 },
  { value: 6561, label: "Bansari", kab: 109, prop: 10 },
  { value: 6562, label: "Bejen", kab: 109, prop: 10 },
  { value: 6563, label: "Bulu", kab: 109, prop: 10 },
  { value: 6564, label: "Candiroto", kab: 109, prop: 10 },
  { value: 6565, label: "Gemawang", kab: 109, prop: 10 },
  { value: 6566, label: "Jumo", kab: 109, prop: 10 },
  { value: 6567, label: "Kaloran", kab: 109, prop: 10 },
  { value: 6568, label: "Kandangan", kab: 109, prop: 10 },
  { value: 6569, label: "Kedu", kab: 109, prop: 10 },
  { value: 6570, label: "Kledung", kab: 109, prop: 10 },
  { value: 6571, label: "Kranggan", kab: 109, prop: 10 },
  { value: 6572, label: "Ngadirejo", kab: 109, prop: 10 },
  { value: 6573, label: "Parakan", kab: 109, prop: 10 },
  { value: 6574, label: "Pringsurat", kab: 109, prop: 10 },
  { value: 6575, label: "Selopampang", kab: 109, prop: 10 },
  { value: 6576, label: "Temanggung", kab: 109, prop: 10 },
  { value: 6577, label: "Tembarak", kab: 109, prop: 10 },
  { value: 6578, label: "Tlogomulyo", kab: 109, prop: 10 },
  { value: 6579, label: "Tretep", kab: 109, prop: 10 },
  { value: 6580, label: "Wonoboyo", kab: 109, prop: 10 },
  { value: 6885, label: "Baturetno", kab: 110, prop: 10 },
  { value: 6886, label: "Batuwarno", kab: 110, prop: 10 },
  { value: 6887, label: "Bulukerto", kab: 110, prop: 10 },
  { value: 6888, label: "Eromoko", kab: 110, prop: 10 },
  { value: 6889, label: "Girimarto", kab: 110, prop: 10 },
  { value: 6890, label: "Giritontro", kab: 110, prop: 10 },
  { value: 6891, label: "Giriwoyo", kab: 110, prop: 10 },
  { value: 6892, label: "Jatipurno", kab: 110, prop: 10 },
  { value: 6893, label: "Jatiroto", kab: 110, prop: 10 },
  { value: 6894, label: "Jatisrono", kab: 110, prop: 10 },
  { value: 6895, label: "Karangtengah", kab: 110, prop: 10 },
  { value: 6896, label: "Kismantoro", kab: 110, prop: 10 },
  { value: 6897, label: "Manyaran", kab: 110, prop: 10 },
  { value: 6898, label: "Ngadirojo", kab: 110, prop: 10 },
  { value: 6899, label: "Nguntoronadi", kab: 110, prop: 10 },
  { value: 6900, label: "Paranggupito", kab: 110, prop: 10 },
  { value: 6901, label: "Pracimantoro", kab: 110, prop: 10 },
  { value: 6902, label: "Puhpelem", kab: 110, prop: 10 },
  { value: 6903, label: "Purwantoro", kab: 110, prop: 10 },
  { value: 6904, label: "Selogiri", kab: 110, prop: 10 },
  { value: 6905, label: "Sidoharjo", kab: 110, prop: 10 },
  { value: 6906, label: "Slogohimo", kab: 110, prop: 10 },
  { value: 6907, label: "Tirtomoyo", kab: 110, prop: 10 },
  { value: 6908, label: "Wonogiri", kab: 110, prop: 10 },
  { value: 6909, label: "Wuryantoro", kab: 110, prop: 10 },
  { value: 6910, label: "Garung", kab: 111, prop: 10 },
  { value: 6911, label: "Kalibawang", kab: 111, prop: 10 },
  { value: 6912, label: "Kalikajar", kab: 111, prop: 10 },
  { value: 6913, label: "Kaliwiro", kab: 111, prop: 10 },
  { value: 6914, label: "Kejajar", kab: 111, prop: 10 },
  { value: 6915, label: "Kepil", kab: 111, prop: 10 },
  { value: 6916, label: "Kertek", kab: 111, prop: 10 },
  { value: 6917, label: "Leksono", kab: 111, prop: 10 },
  { value: 6918, label: "Mojotengah", kab: 111, prop: 10 },
  { value: 6919, label: "Sapuran", kab: 111, prop: 10 },
  { value: 6920, label: "Selomerto", kab: 111, prop: 10 },
  { value: 6921, label: "Sukoharjo", kab: 111, prop: 10 },
  { value: 6922, label: "Wadaslintang", kab: 111, prop: 10 },
  { value: 6923, label: "Watumalang", kab: 111, prop: 10 },
  { value: 6924, label: "Wonosobo", kab: 111, prop: 10 },
  { value: 454, label: "Arosbaya", kab: 112, prop: 11 },
  { value: 455, label: "Bangkalan", kab: 112, prop: 11 },
  { value: 456, label: "Blega", kab: 112, prop: 11 },
  { value: 457, label: "Burneh", kab: 112, prop: 11 },
  { value: 458, label: "Galis", kab: 112, prop: 11 },
  { value: 459, label: "Geger", kab: 112, prop: 11 },
  { value: 460, label: "Kamal", kab: 112, prop: 11 },
  { value: 461, label: "Klampis", kab: 112, prop: 11 },
  { value: 462, label: "Kokop", kab: 112, prop: 11 },
  { value: 463, label: "Konang", kab: 112, prop: 11 },
  { value: 464, label: "Kwanyar", kab: 112, prop: 11 },
  { value: 465, label: "Labang", kab: 112, prop: 11 },
  { value: 466, label: "Modung", kab: 112, prop: 11 },
  { value: 467, label: "Sepulu", kab: 112, prop: 11 },
  { value: 468, label: "Socah", kab: 112, prop: 11 },
  { value: 469, label: "Tanah Merah", kab: 112, prop: 11 },
  { value: 470, label: "Tanjungbumi", kab: 112, prop: 11 },
  { value: 471, label: "Tragah", kab: 112, prop: 11 },
  { value: 600, label: "Bangorejo", kab: 113, prop: 11 },
  { value: 601, label: "Banyuwangi", kab: 113, prop: 11 },
  { value: 602, label: "Cluring", kab: 113, prop: 11 },
  { value: 603, label: "Gambiran", kab: 113, prop: 11 },
  { value: 604, label: "Genteng", kab: 113, prop: 11 },
  { value: 605, label: "Giri", kab: 113, prop: 11 },
  { value: 606, label: "Glagah", kab: 113, prop: 11 },
  { value: 607, label: "Glenmore", kab: 113, prop: 11 },
  { value: 608, label: "Kabat", kab: 113, prop: 11 },
  { value: 609, label: "Kalibaru", kab: 113, prop: 11 },
  { value: 610, label: "Kalipuro", kab: 113, prop: 11 },
  { value: 611, label: "Licin", kab: 113, prop: 11 },
  { value: 612, label: "Muncar", kab: 113, prop: 11 },
  { value: 613, label: "Pesanggaran", kab: 113, prop: 11 },
  { value: 614, label: "Purwoharjo", kab: 113, prop: 11 },
  { value: 615, label: "Rogojampi", kab: 113, prop: 11 },
  { value: 616, label: "Sempu", kab: 113, prop: 11 },
  { value: 617, label: "Siliragung", kab: 113, prop: 11 },
  { value: 618, label: "Singojuruh", kab: 113, prop: 11 },
  { value: 619, label: "Songgon", kab: 113, prop: 11 },
  { value: 620, label: "Srono", kab: 113, prop: 11 },
  { value: 621, label: "Tegaldlimo", kab: 113, prop: 11 },
  { value: 622, label: "Tegalsari", kab: 113, prop: 11 },
  { value: 623, label: "Wongsorejo", kab: 113, prop: 11 },
  { value: 708, label: "Batu", kab: 114, prop: 11 },
  { value: 709, label: "Bumiaji", kab: 114, prop: 11 },
  { value: 710, label: "Junrejo", kab: 114, prop: 11 },
  { value: 974, label: "Bakung", kab: 115, prop: 11 },
  { value: 975, label: "Binangun", kab: 115, prop: 11 },
  { value: 976, label: "Doko", kab: 115, prop: 11 },
  { value: 977, label: "Gandusari", kab: 115, prop: 11 },
  { value: 978, label: "Garum", kab: 115, prop: 11 },
  { value: 979, label: "Kademangan", kab: 115, prop: 11 },
  { value: 980, label: "Kanigoro", kab: 115, prop: 11 },
  { value: 981, label: "Kesamben", kab: 115, prop: 11 },
  { value: 982, label: "Nglegok", kab: 115, prop: 11 },
  { value: 983, label: "Panggungrejo", kab: 115, prop: 11 },
  { value: 984, label: "Ponggok", kab: 115, prop: 11 },
  { value: 985, label: "Sanan Kulon", kab: 115, prop: 11 },
  { value: 986, label: "Selopuro", kab: 115, prop: 11 },
  { value: 987, label: "Selorejo", kab: 115, prop: 11 },
  { value: 988, label: "Srengat", kab: 115, prop: 11 },
  { value: 989, label: "Sutojayan", kab: 115, prop: 11 },
  { value: 990, label: "Talun", kab: 115, prop: 11 },
  { value: 991, label: "Udanawu", kab: 115, prop: 11 },
  { value: 992, label: "Wates", kab: 115, prop: 11 },
  { value: 993, label: "Wlingi", kab: 115, prop: 11 },
  { value: 994, label: "Wonodadi", kab: 115, prop: 11 },
  { value: 995, label: "Wonotirto", kab: 115, prop: 11 },
  { value: 996, label: "Kepanjen Kidul", kab: 115, prop: 11 },
  { value: 997, label: "Sanan Wetan", kab: 115, prop: 11 },
  { value: 998, label: "Sukorejo", kab: 115, prop: 11 },
  { value: 1068, label: "Balen", kab: 116, prop: 11 },
  { value: 1069, label: "Baureno", kab: 116, prop: 11 },
  { value: 1070, label: "Bojonegoro", kab: 116, prop: 11 },
  { value: 1071, label: "Bubulan", kab: 116, prop: 11 },
  { value: 1072, label: "Dander", kab: 116, prop: 11 },
  { value: 1073, label: "Gayam", kab: 116, prop: 11 },
  { value: 1074, label: "Gondang", kab: 116, prop: 11 },
  { value: 1075, label: "Kalitidu", kab: 116, prop: 11 },
  { value: 1076, label: "Kanor", kab: 116, prop: 11 },
  { value: 1077, label: "Kapas", kab: 116, prop: 11 },
  { value: 1078, label: "Kasiman", kab: 116, prop: 11 },
  { value: 1079, label: "Kedewan", kab: 116, prop: 11 },
  { value: 1080, label: "Kedungadem", kab: 116, prop: 11 },
  { value: 1081, label: "Kepoh Baru", kab: 116, prop: 11 },
  { value: 1082, label: "Malo", kab: 116, prop: 11 },
  { value: 1083, label: "Margomulyo", kab: 116, prop: 11 },
  { value: 1084, label: "Ngambon", kab: 116, prop: 11 },
  { value: 1085, label: "Ngasem", kab: 116, prop: 11 },
  { value: 1086, label: "Ngraho", kab: 116, prop: 11 },
  { value: 1087, label: "Padangan", kab: 116, prop: 11 },
  { value: 1088, label: "Purwosari", kab: 116, prop: 11 },
  { value: 1089, label: "Sekar", kab: 116, prop: 11 },
  { value: 1090, label: "Sugihwaras", kab: 116, prop: 11 },
  { value: 1091, label: "Sukosewu", kab: 116, prop: 11 },
  { value: 1092, label: "Sumberrejo", kab: 116, prop: 11 },
  { value: 1093, label: "Tambakrejo", kab: 116, prop: 11 },
  { value: 1094, label: "Temayang", kab: 116, prop: 11 },
  { value: 1095, label: "Trucuk", kab: 116, prop: 11 },
  { value: 1149, label: "Binakal", kab: 117, prop: 11 },
  { value: 1150, label: "Bondowoso", kab: 117, prop: 11 },
  { value: 1151, label: "Botolinggo", kab: 117, prop: 11 },
  { value: 1152, label: "Cermee", kab: 117, prop: 11 },
  { value: 1153, label: "Curahdami", kab: 117, prop: 11 },
  { value: 1154, label: "Grujugan", kab: 117, prop: 11 },
  { value: 1155, label: "Jambe Sari Darus Sholah", kab: 117, prop: 11 },
  { value: 1156, label: "Klabang", kab: 117, prop: 11 },
  { value: 1157, label: "Maesan", kab: 117, prop: 11 },
  { value: 1158, label: "Pakem", kab: 117, prop: 11 },
  { value: 1159, label: "Prajekan", kab: 117, prop: 11 },
  { value: 1160, label: "Pujer", kab: 117, prop: 11 },
  { value: 1161, label: "Sempol", kab: 117, prop: 11 },
  { value: 1162, label: "Sukosari", kab: 117, prop: 11 },
  { value: 1163, label: "Sumber Wringin", kab: 117, prop: 11 },
  { value: 1164, label: "Taman Krocok", kab: 117, prop: 11 },
  { value: 1165, label: "Tamanan", kab: 117, prop: 11 },
  { value: 1166, label: "Tapen", kab: 117, prop: 11 },
  { value: 1167, label: "Tegalampel", kab: 117, prop: 11 },
  { value: 1168, label: "Tenggarang", kab: 117, prop: 11 },
  { value: 1169, label: "Tlogosari", kab: 117, prop: 11 },
  { value: 1170, label: "Wonosari", kab: 117, prop: 11 },
  { value: 1171, label: "Wringin", kab: 117, prop: 11 },
  { value: 1828, label: "Balong Panggang", kab: 118, prop: 11 },
  { value: 1829, label: "Benjeng", kab: 118, prop: 11 },
  { value: 1830, label: "Bungah", kab: 118, prop: 11 },
  { value: 1831, label: "Cerme", kab: 118, prop: 11 },
  { value: 1832, label: "Driyorejo", kab: 118, prop: 11 },
  { value: 1833, label: "Duduk Sampeyan", kab: 118, prop: 11 },
  { value: 1834, label: "Dukun", kab: 118, prop: 11 },
  { value: 1835, label: "Gresik", kab: 118, prop: 11 },
  { value: 1836, label: "Kebomas", kab: 118, prop: 11 },
  { value: 1837, label: "Kedamean", kab: 118, prop: 11 },
  { value: 1838, label: "Manyar", kab: 118, prop: 11 },
  { value: 1839, label: "Menganti", kab: 118, prop: 11 },
  { value: 1840, label: "Panceng", kab: 118, prop: 11 },
  { value: 1841, label: "Sangkapura", kab: 118, prop: 11 },
  { value: 1842, label: "Sidayu", kab: 118, prop: 11 },
  { value: 1843, label: "Tambak", kab: 118, prop: 11 },
  { value: 1844, label: "Ujung Pangkah", kab: 118, prop: 11 },
  { value: 1845, label: "Wringin Anom", kab: 118, prop: 11 },
  { value: 2201, label: "Ajung", kab: 119, prop: 11 },
  { value: 2202, label: "Ambulu", kab: 119, prop: 11 },
  { value: 2203, label: "Arjasa", kab: 119, prop: 11 },
  { value: 2204, label: "Balung", kab: 119, prop: 11 },
  { value: 2205, label: "Bangsalsari", kab: 119, prop: 11 },
  { value: 2206, label: "Gumuk Mas", kab: 119, prop: 11 },
  { value: 2207, label: "Jelbuk", kab: 119, prop: 11 },
  { value: 2208, label: "Jenggawah", kab: 119, prop: 11 },
  { value: 2209, label: "Jombang", kab: 119, prop: 11 },
  { value: 2210, label: "Kalisat", kab: 119, prop: 11 },
  { value: 2211, label: "Kaliwates", kab: 119, prop: 11 },
  { value: 2212, label: "Kencong", kab: 119, prop: 11 },
  { value: 2213, label: "Ledokombo", kab: 119, prop: 11 },
  { value: 2214, label: "Mayang", kab: 119, prop: 11 },
  { value: 2215, label: "Mumbulsari", kab: 119, prop: 11 },
  { value: 2216, label: "Pakusari", kab: 119, prop: 11 },
  { value: 2217, label: "Panti", kab: 119, prop: 11 },
  { value: 2218, label: "Patrang", kab: 119, prop: 11 },
  { value: 2219, label: "Puger", kab: 119, prop: 11 },
  { value: 2220, label: "Rambipuji", kab: 119, prop: 11 },
  { value: 2221, label: "Semboro", kab: 119, prop: 11 },
  { value: 2222, label: "Silo", kab: 119, prop: 11 },
  { value: 2223, label: "Sukorambi", kab: 119, prop: 11 },
  { value: 2224, label: "Sukowono", kab: 119, prop: 11 },
  { value: 2225, label: "Sumber Baru", kab: 119, prop: 11 },
  { value: 2226, label: "Sumber Jambe", kab: 119, prop: 11 },
  { value: 2227, label: "Sumber Sari", kab: 119, prop: 11 },
  { value: 2228, label: "Tanggul", kab: 119, prop: 11 },
  { value: 2229, label: "Tempurejo", kab: 119, prop: 11 },
  { value: 2230, label: "Umbulsari", kab: 119, prop: 11 },
  { value: 2231, label: "Wuluhan", kab: 119, prop: 11 },
  { value: 2264, label: "Bandar Kedung Mulyo", kab: 120, prop: 11 },
  { value: 2265, label: "Bareng", kab: 120, prop: 11 },
  { value: 2266, label: "Diwek", kab: 120, prop: 11 },
  { value: 2267, label: "Gudo", kab: 120, prop: 11 },
  { value: 2268, label: "Jogoroto", kab: 120, prop: 11 },
  { value: 2269, label: "Jombang", kab: 120, prop: 11 },
  { value: 2270, label: "Kabuh", kab: 120, prop: 11 },
  { value: 2271, label: "Kesamben", kab: 120, prop: 11 },
  { value: 2272, label: "Kudu", kab: 120, prop: 11 },
  { value: 2273, label: "Megaluh", kab: 120, prop: 11 },
  { value: 2274, label: "Mojoagung", kab: 120, prop: 11 },
  { value: 2275, label: "Mojowarno", kab: 120, prop: 11 },
  { value: 2276, label: "Ngoro", kab: 120, prop: 11 },
  { value: 2277, label: "Ngusikan", kab: 120, prop: 11 },
  { value: 2278, label: "Perak", kab: 120, prop: 11 },
  { value: 2279, label: "Peterongan", kab: 120, prop: 11 },
  { value: 2280, label: "Plandaan", kab: 120, prop: 11 },
  { value: 2281, label: "Ploso", kab: 120, prop: 11 },
  { value: 2282, label: "Sumobito", kab: 120, prop: 11 },
  { value: 2283, label: "Tembelang", kab: 120, prop: 11 },
  { value: 2284, label: "Wonosalam", kab: 120, prop: 11 },
  { value: 2497, label: "Badas", kab: 121, prop: 11 },
  { value: 2498, label: "Banyakan", kab: 121, prop: 11 },
  { value: 2499, label: "Gampengrejo", kab: 121, prop: 11 },
  { value: 2500, label: "Grogol", kab: 121, prop: 11 },
  { value: 2501, label: "Gurah", kab: 121, prop: 11 },
  { value: 2502, label: "Kandangan", kab: 121, prop: 11 },
  { value: 2503, label: "Kandat", kab: 121, prop: 11 },
  { value: 2504, label: "Kayen Kidul", kab: 121, prop: 11 },
  { value: 2505, label: "Kepung", kab: 121, prop: 11 },
  { value: 2506, label: "Kras", kab: 121, prop: 11 },
  { value: 2507, label: "Kunjang", kab: 121, prop: 11 },
  { value: 2508, label: "Mojo", kab: 121, prop: 11 },
  { value: 2509, label: "Ngadiluwih", kab: 121, prop: 11 },
  { value: 2510, label: "Ngancar", kab: 121, prop: 11 },
  { value: 2511, label: "Ngasem", kab: 121, prop: 11 },
  { value: 2512, label: "Pagu", kab: 121, prop: 11 },
  { value: 2513, label: "Papar", kab: 121, prop: 11 },
  { value: 2514, label: "Pare", kab: 121, prop: 11 },
  { value: 2515, label: "Plemahan", kab: 121, prop: 11 },
  { value: 2516, label: "Plosoklaten", kab: 121, prop: 11 },
  { value: 2517, label: "Puncu", kab: 121, prop: 11 },
  { value: 2518, label: "Purwoasri", kab: 121, prop: 11 },
  { value: 2519, label: "Ringinrejo", kab: 121, prop: 11 },
  { value: 2520, label: "Semen", kab: 121, prop: 11 },
  { value: 2521, label: "Tarokan", kab: 121, prop: 11 },
  { value: 2522, label: "Wates", kab: 121, prop: 11 },
  { value: 2523, label: "Kediri Kota", kab: 121, prop: 11 },
  { value: 2524, label: "Mojoroto", kab: 121, prop: 11 },
  { value: 2525, label: "Pesantren", kab: 121, prop: 11 },
  { value: 3113, label: "Babat", kab: 122, prop: 11 },
  { value: 3114, label: "Bluluk", kab: 122, prop: 11 },
  { value: 3115, label: "Brondong", kab: 122, prop: 11 },
  { value: 3116, label: "Deket", kab: 122, prop: 11 },
  { value: 3117, label: "Glagah", kab: 122, prop: 11 },
  { value: 3118, label: "Kalitengah", kab: 122, prop: 11 },
  { value: 3119, label: "Karang Geneng", kab: 122, prop: 11 },
  { value: 3120, label: "Karangbinangun", kab: 122, prop: 11 },
  { value: 3121, label: "Kedungpring", kab: 122, prop: 11 },
  { value: 3122, label: "Kembangbahu", kab: 122, prop: 11 },
  { value: 3123, label: "Lamongan", kab: 122, prop: 11 },
  { value: 3124, label: "Laren", kab: 122, prop: 11 },
  { value: 3125, label: "Maduran", kab: 122, prop: 11 },
  { value: 3126, label: "Mantup", kab: 122, prop: 11 },
  { value: 3127, label: "Modo", kab: 122, prop: 11 },
  { value: 3128, label: "Ngimbang", kab: 122, prop: 11 },
  { value: 3129, label: "Paciran", kab: 122, prop: 11 },
  { value: 3130, label: "Pucuk", kab: 122, prop: 11 },
  { value: 3131, label: "Sambeng", kab: 122, prop: 11 },
  { value: 3132, label: "Sarirejo", kab: 122, prop: 11 },
  { value: 3133, label: "Sekaran", kab: 122, prop: 11 },
  { value: 3134, label: "Solokuro", kab: 122, prop: 11 },
  { value: 3135, label: "Sugio", kab: 122, prop: 11 },
  { value: 3136, label: "Sukodadi", kab: 122, prop: 11 },
  { value: 3137, label: "Sukorame", kab: 122, prop: 11 },
  { value: 3138, label: "Tikung", kab: 122, prop: 11 },
  { value: 3139, label: "Turi", kab: 122, prop: 11 },
  { value: 3427, label: "Candipuro", kab: 123, prop: 11 },
  { value: 3428, label: "Gucialit", kab: 123, prop: 11 },
  { value: 3429, label: "Jatiroto", kab: 123, prop: 11 },
  { value: 3430, label: "Kedungjajang", kab: 123, prop: 11 },
  { value: 3431, label: "Klakah", kab: 123, prop: 11 },
  { value: 3432, label: "Kunir", kab: 123, prop: 11 },
  { value: 3433, label: "Lumajang", kab: 123, prop: 11 },
  { value: 3434, label: "Padang", kab: 123, prop: 11 },
  { value: 3435, label: "Pasirian", kab: 123, prop: 11 },
  { value: 3436, label: "Pasrujambe/Pasujambe", kab: 123, prop: 11 },
  { value: 3437, label: "Pronojiwo", kab: 123, prop: 11 },
  { value: 3438, label: "Randuagung", kab: 123, prop: 11 },
  { value: 3439, label: "Ranuyoso", kab: 123, prop: 11 },
  { value: 3440, label: "Rowokangkung", kab: 123, prop: 11 },
  { value: 3441, label: "Senduro", kab: 123, prop: 11 },
  { value: 3442, label: "Sukodono", kab: 123, prop: 11 },
  { value: 3443, label: "Sumbersuko", kab: 123, prop: 11 },
  { value: 3444, label: "Tekung", kab: 123, prop: 11 },
  { value: 3445, label: "Tempeh", kab: 123, prop: 11 },
  { value: 3446, label: "Tempursari", kab: 123, prop: 11 },
  { value: 3447, label: "Yosowilangun", kab: 123, prop: 11 },
  { value: 3493, label: "Balerejo", kab: 124, prop: 11 },
  { value: 3494, label: "Dagangan", kab: 124, prop: 11 },
  { value: 3495, label: "Dolopo", kab: 124, prop: 11 },
  { value: 3496, label: "Geger", kab: 124, prop: 11 },
  { value: 3497, label: "Gemarang", kab: 124, prop: 11 },
  { value: 3498, label: "Jiwan", kab: 124, prop: 11 },
  { value: 3499, label: "Kare", kab: 124, prop: 11 },
  { value: 3500, label: "Kebonsari", kab: 124, prop: 11 },
  { value: 3501, label: "Madiun", kab: 124, prop: 11 },
  { value: 3502, label: "Mejayan", kab: 124, prop: 11 },
  { value: 3503, label: "Pilangkenceng", kab: 124, prop: 11 },
  { value: 3504, label: "Saradan", kab: 124, prop: 11 },
  { value: 3505, label: "Sawahan", kab: 124, prop: 11 },
  { value: 3506, label: "Wonoasri", kab: 124, prop: 11 },
  { value: 3507, label: "Wungu", kab: 124, prop: 11 },
  { value: 3508, label: "Kartoharjo", kab: 124, prop: 11 },
  { value: 3509, label: "Manguharjo", kab: 124, prop: 11 },
  { value: 3510, label: "Taman", kab: 124, prop: 11 },
  { value: 3535, label: "Barat", kab: 125, prop: 11 },
  { value: 3536, label: "Bendo", kab: 125, prop: 11 },
  { value: 3537, label: "Karangrejo", kab: 125, prop: 11 },
  { value: 3538, label: "Karas", kab: 125, prop: 11 },
  { value: 3539, label: "Kartoharjo (Kertoharjo)", kab: 125, prop: 11 },
  { value: 3540, label: "Kawedanan", kab: 125, prop: 11 },
  { value: 3541, label: "Lembeyan", kab: 125, prop: 11 },
  { value: 3542, label: "Magetan", kab: 125, prop: 11 },
  { value: 3543, label: "Maospati", kab: 125, prop: 11 },
  { value: 3544, label: "Ngariboyo", kab: 125, prop: 11 },
  { value: 3545, label: "Nguntoronadi", kab: 125, prop: 11 },
  { value: 3546, label: "Panekan", kab: 125, prop: 11 },
  { value: 3547, label: "Parang", kab: 125, prop: 11 },
  { value: 3548, label: "Plaosan", kab: 125, prop: 11 },
  { value: 3549, label: "Poncol", kab: 125, prop: 11 },
  { value: 3550, label: "Sidorejo", kab: 125, prop: 11 },
  { value: 3551, label: "Sukomoro", kab: 125, prop: 11 },
  { value: 3552, label: "Takeran", kab: 125, prop: 11 },
  { value: 3634, label: "Blimbing", kab: 126, prop: 11 },
  { value: 3635, label: "Kedungkandang", kab: 126, prop: 11 },
  { value: 3636, label: "Klojen", kab: 126, prop: 11 },
  { value: 3637, label: "Lowokwaru", kab: 126, prop: 11 },
  { value: 3638, label: "Sukun", kab: 126, prop: 11 },
  { value: 3601, label: "Ampelgading", kab: 126, prop: 11 },
  { value: 3602, label: "Bantur", kab: 126, prop: 11 },
  { value: 3603, label: "Bululawang", kab: 126, prop: 11 },
  { value: 3604, label: "Dampit", kab: 126, prop: 11 },
  { value: 3605, label: "Dau", kab: 126, prop: 11 },
  { value: 3606, label: "Donomulyo", kab: 126, prop: 11 },
  { value: 3607, label: "Gedangan", kab: 126, prop: 11 },
  { value: 3608, label: "Gondanglegi", kab: 126, prop: 11 },
  { value: 3609, label: "Jabung", kab: 126, prop: 11 },
  { value: 3610, label: "Kalipare", kab: 126, prop: 11 },
  { value: 3611, label: "Karangploso", kab: 126, prop: 11 },
  { value: 3612, label: "Kasembon", kab: 126, prop: 11 },
  { value: 3613, label: "Kepanjen", kab: 126, prop: 11 },
  { value: 3614, label: "Kromengan", kab: 126, prop: 11 },
  { value: 3615, label: "Lawang", kab: 126, prop: 11 },
  { value: 3616, label: "Ngajung (Ngajum)", kab: 126, prop: 11 },
  { value: 3617, label: "Ngantang", kab: 126, prop: 11 },
  { value: 3618, label: "Pagak", kab: 126, prop: 11 },
  { value: 3619, label: "Pagelaran", kab: 126, prop: 11 },
  { value: 3620, label: "Pakis", kab: 126, prop: 11 },
  { value: 3621, label: "Pakisaji", kab: 126, prop: 11 },
  { value: 3622, label: "Poncokusumo", kab: 126, prop: 11 },
  { value: 3623, label: "Pujon", kab: 126, prop: 11 },
  { value: 3624, label: "Singosari", kab: 126, prop: 11 },
  { value: 3625, label: "Sumbermanjing Wetan", kab: 126, prop: 11 },
  { value: 3626, label: "Sumberpucung", kab: 126, prop: 11 },
  { value: 3627, label: "Tajinan", kab: 126, prop: 11 },
  { value: 3628, label: "Tirtoyudo", kab: 126, prop: 11 },
  { value: 3629, label: "Tumpang", kab: 126, prop: 11 },
  { value: 3630, label: "Turen", kab: 126, prop: 11 },
  { value: 3631, label: "Wagir", kab: 126, prop: 11 },
  { value: 3632, label: "Wajak", kab: 126, prop: 11 },
  { value: 3633, label: "Wonosari", kab: 126, prop: 11 },
  { value: 4070, label: "Bangsal", kab: 127, prop: 11 },
  { value: 4071, label: "Dawar Blandong", kab: 127, prop: 11 },
  { value: 4072, label: "Dlanggu", kab: 127, prop: 11 },
  { value: 4073, label: "Gedeg", kab: 127, prop: 11 },
  { value: 4074, label: "Gondang", kab: 127, prop: 11 },
  { value: 4075, label: "Jatirejo", kab: 127, prop: 11 },
  { value: 4076, label: "Jetis", kab: 127, prop: 11 },
  { value: 4077, label: "Kemlagi", kab: 127, prop: 11 },
  { value: 4078, label: "Kutorejo", kab: 127, prop: 11 },
  { value: 4079, label: "Mojoanyar", kab: 127, prop: 11 },
  { value: 4080, label: "Mojosari", kab: 127, prop: 11 },
  { value: 4081, label: "Ngoro", kab: 127, prop: 11 },
  { value: 4082, label: "Pacet", kab: 127, prop: 11 },
  { value: 4083, label: "Pungging", kab: 127, prop: 11 },
  { value: 4084, label: "Puri", kab: 127, prop: 11 },
  { value: 4085, label: "Sooko", kab: 127, prop: 11 },
  { value: 4086, label: "Trawas", kab: 127, prop: 11 },
  { value: 4087, label: "Trowulan", kab: 127, prop: 11 },
  { value: 4088, label: "Magersari", kab: 127, prop: 11 },
  { value: 4089, label: "Prajurit Kulon", kab: 127, prop: 11 },
  { value: 4323, label: "Bagor", kab: 128, prop: 11 },
  { value: 4324, label: "Baron", kab: 128, prop: 11 },
  { value: 4325, label: "Berbek", kab: 128, prop: 11 },
  { value: 4326, label: "Gondang", kab: 128, prop: 11 },
  { value: 4327, label: "Jatikalen", kab: 128, prop: 11 },
  { value: 4328, label: "Kertosono", kab: 128, prop: 11 },
  { value: 4329, label: "Lengkong", kab: 128, prop: 11 },
  { value: 4330, label: "Loceret", kab: 128, prop: 11 },
  { value: 4331, label: "Nganjuk", kab: 128, prop: 11 },
  { value: 4332, label: "Ngetos", kab: 128, prop: 11 },
  { value: 4333, label: "Ngluyu", kab: 128, prop: 11 },
  { value: 4334, label: "Ngronggot", kab: 128, prop: 11 },
  { value: 4335, label: "Pace", kab: 128, prop: 11 },
  { value: 4336, label: "Patianrowo", kab: 128, prop: 11 },
  { value: 4337, label: "Prambon", kab: 128, prop: 11 },
  { value: 4338, label: "Rejoso", kab: 128, prop: 11 },
  { value: 4339, label: "Sawahan", kab: 128, prop: 11 },
  { value: 4340, label: "Sukomoro", kab: 128, prop: 11 },
  { value: 4341, label: "Tanjunganom", kab: 128, prop: 11 },
  { value: 4342, label: "Wilangan", kab: 128, prop: 11 },
  { value: 4343, label: "Bringin", kab: 129, prop: 11 },
  { value: 4344, label: "Geneng", kab: 129, prop: 11 },
  { value: 4345, label: "Gerih", kab: 129, prop: 11 },
  { value: 4346, label: "Jogorogo", kab: 129, prop: 11 },
  { value: 4347, label: "Karanganyar", kab: 129, prop: 11 },
  { value: 4348, label: "Karangjati", kab: 129, prop: 11 },
  { value: 4349, label: "Kasreman", kab: 129, prop: 11 },
  { value: 4350, label: "Kedunggalar", kab: 129, prop: 11 },
  { value: 4351, label: "Kendal", kab: 129, prop: 11 },
  { value: 4352, label: "Kwadungan", kab: 129, prop: 11 },
  { value: 4353, label: "Mantingan", kab: 129, prop: 11 },
  { value: 4354, label: "Ngawi", kab: 129, prop: 11 },
  { value: 4355, label: "Ngrambe", kab: 129, prop: 11 },
  { value: 4356, label: "Padas", kab: 129, prop: 11 },
  { value: 4357, label: "Pangkur", kab: 129, prop: 11 },
  { value: 4358, label: "Paron", kab: 129, prop: 11 },
  { value: 4359, label: "Pitu", kab: 129, prop: 11 },
  { value: 4360, label: "Sine", kab: 129, prop: 11 },
  { value: 4361, label: "Widodaren", kab: 129, prop: 11 },
  { value: 4522, label: "Arjosari", kab: 130, prop: 11 },
  { value: 4523, label: "Bandar", kab: 130, prop: 11 },
  { value: 4524, label: "Donorojo", kab: 130, prop: 11 },
  { value: 4525, label: "Kebon Agung", kab: 130, prop: 11 },
  { value: 4526, label: "Nawangan", kab: 130, prop: 11 },
  { value: 4527, label: "Ngadirojo", kab: 130, prop: 11 },
  { value: 4528, label: "Pacitan", kab: 130, prop: 11 },
  { value: 4529, label: "Pringkuku", kab: 130, prop: 11 },
  { value: 4530, label: "Punung", kab: 130, prop: 11 },
  { value: 4531, label: "Sudimoro", kab: 130, prop: 11 },
  { value: 4532, label: "Tegalombo", kab: 130, prop: 11 },
  { value: 4533, label: "Tulakan", kab: 130, prop: 11 },
  { value: 4642, label: "Batumarmar", kab: 131, prop: 11 },
  { value: 4643, label: "Galis", kab: 131, prop: 11 },
  { value: 4644, label: "Kadur", kab: 131, prop: 11 },
  { value: 4645, label: "Larangan", kab: 131, prop: 11 },
  { value: 4646, label: "Pademawu", kab: 131, prop: 11 },
  { value: 4647, label: "Pakong", kab: 131, prop: 11 },
  { value: 4648, label: "Palengaan", kab: 131, prop: 11 },
  { value: 4649, label: "Pamekasan", kab: 131, prop: 11 },
  { value: 4650, label: "Pasean", kab: 131, prop: 11 },
  { value: 4651, label: "Pegantenan", kab: 131, prop: 11 },
  { value: 4652, label: "Proppo", kab: 131, prop: 11 },
  { value: 4653, label: "Tlanakan", kab: 131, prop: 11 },
  { value: 4654, label: "Waru", kab: 131, prop: 11 },
  { value: 4793, label: "Bangil", kab: 132, prop: 11 },
  { value: 4794, label: "Beji", kab: 132, prop: 11 },
  { value: 4795, label: "Gempol", kab: 132, prop: 11 },
  { value: 4796, label: "Gondang Wetan", kab: 132, prop: 11 },
  { value: 4797, label: "Grati", kab: 132, prop: 11 },
  { value: 4798, label: "Kejayan", kab: 132, prop: 11 },
  { value: 4799, label: "Kraton", kab: 132, prop: 11 },
  { value: 4800, label: "Lekok", kab: 132, prop: 11 },
  { value: 4801, label: "Lumbang", kab: 132, prop: 11 },
  { value: 4802, label: "Nguling", kab: 132, prop: 11 },
  { value: 4803, label: "Pandaan", kab: 132, prop: 11 },
  { value: 4804, label: "Pasrepan", kab: 132, prop: 11 },
  { value: 4805, label: "Pohjentrek", kab: 132, prop: 11 },
  { value: 4806, label: "Prigen", kab: 132, prop: 11 },
  { value: 4807, label: "Purwodadi", kab: 132, prop: 11 },
  { value: 4808, label: "Purwosari", kab: 132, prop: 11 },
  { value: 4809, label: "Puspo", kab: 132, prop: 11 },
  { value: 4810, label: "Rejoso", kab: 132, prop: 11 },
  { value: 4811, label: "Rembang", kab: 132, prop: 11 },
  { value: 4812, label: "Sukorejo", kab: 132, prop: 11 },
  { value: 4813, label: "Tosari", kab: 132, prop: 11 },
  { value: 4814, label: "Tutur", kab: 132, prop: 11 },
  { value: 4815, label: "Winongan", kab: 132, prop: 11 },
  { value: 4816, label: "Wonorejo", kab: 132, prop: 11 },
  { value: 4817, label: "Bugul Kidul", kab: 132, prop: 11 },
  { value: 4818, label: "Gadingrejo", kab: 132, prop: 11 },
  { value: 4819, label: "Panggungrejo", kab: 132, prop: 11 },
  { value: 4820, label: "Purworejo", kab: 132, prop: 11 },
  { value: 5071, label: "Babadan", kab: 133, prop: 11 },
  { value: 5072, label: "Badegan", kab: 133, prop: 11 },
  { value: 5073, label: "Balong", kab: 133, prop: 11 },
  { value: 5074, label: "Bungkal", kab: 133, prop: 11 },
  { value: 5075, label: "Jambon", kab: 133, prop: 11 },
  { value: 5076, label: "Jenangan", kab: 133, prop: 11 },
  { value: 5077, label: "Jetis", kab: 133, prop: 11 },
  { value: 5078, label: "Kauman", kab: 133, prop: 11 },
  { value: 5079, label: "Mlarak", kab: 133, prop: 11 },
  { value: 5080, label: "Ngebel", kab: 133, prop: 11 },
  { value: 5081, label: "Ngrayun", kab: 133, prop: 11 },
  { value: 5082, label: "Ponorogo", kab: 133, prop: 11 },
  { value: 5083, label: "Pudak", kab: 133, prop: 11 },
  { value: 5084, label: "Pulung", kab: 133, prop: 11 },
  { value: 5085, label: "Sambit", kab: 133, prop: 11 },
  { value: 5086, label: "Sampung", kab: 133, prop: 11 },
  { value: 5087, label: "Sawoo", kab: 133, prop: 11 },
  { value: 5088, label: "Siman", kab: 133, prop: 11 },
  { value: 5089, label: "Slahung", kab: 133, prop: 11 },
  { value: 5090, label: "Sooko", kab: 133, prop: 11 },
  { value: 5091, label: "Sukorejo", kab: 133, prop: 11 },
  { value: 5141, label: "Bantaran", kab: 134, prop: 11 },
  { value: 5142, label: "Banyu Anyar", kab: 134, prop: 11 },
  { value: 5143, label: "Besuk", kab: 134, prop: 11 },
  { value: 5144, label: "Dringu", kab: 134, prop: 11 },
  { value: 5145, label: "Gading", kab: 134, prop: 11 },
  { value: 5146, label: "Gending", kab: 134, prop: 11 },
  { value: 5147, label: "Kota Anyar", kab: 134, prop: 11 },
  { value: 5148, label: "Kraksaan", kab: 134, prop: 11 },
  { value: 5149, label: "Krejengan", kab: 134, prop: 11 },
  { value: 5150, label: "Krucil", kab: 134, prop: 11 },
  { value: 5151, label: "Kuripan", kab: 134, prop: 11 },
  { value: 5152, label: "Leces", kab: 134, prop: 11 },
  { value: 5153, label: "Lumbang", kab: 134, prop: 11 },
  { value: 5154, label: "Maron", kab: 134, prop: 11 },
  { value: 5155, label: "Paiton", kab: 134, prop: 11 },
  { value: 5156, label: "Pajarakan", kab: 134, prop: 11 },
  { value: 5157, label: "Pakuniran", kab: 134, prop: 11 },
  { value: 5158, label: "Sukapura", kab: 134, prop: 11 },
  { value: 5159, label: "Sumber", kab: 134, prop: 11 },
  { value: 5160, label: "Sumberasih", kab: 134, prop: 11 },
  { value: 5161, label: "Tegal Siwalan", kab: 134, prop: 11 },
  { value: 5162, label: "Tiris", kab: 134, prop: 11 },
  { value: 5163, label: "Tongas", kab: 134, prop: 11 },
  { value: 5164, label: "Wonomerto", kab: 134, prop: 11 },
  { value: 5165, label: "Kademangan", kab: 134, prop: 11 },
  { value: 5166, label: "Kanigaran", kab: 134, prop: 11 },
  { value: 5167, label: "Kedopok (Kedopak)", kab: 134, prop: 11 },
  { value: 5168, label: "Mayangan", kab: 134, prop: 11 },
  { value: 5169, label: "Wonoasih", kab: 134, prop: 11 },
  { value: 5394, label: "Banyuates", kab: 135, prop: 11 },
  { value: 5395, label: "Camplong", kab: 135, prop: 11 },
  { value: 5396, label: "Jrengik", kab: 135, prop: 11 },
  { value: 5397, label: "Karang Penang", kab: 135, prop: 11 },
  { value: 5398, label: "Kedungdung", kab: 135, prop: 11 },
  { value: 5399, label: "Ketapang", kab: 135, prop: 11 },
  { value: 5400, label: "Omben", kab: 135, prop: 11 },
  { value: 5401, label: "Pangarengan", kab: 135, prop: 11 },
  { value: 5402, label: "Robatal", kab: 135, prop: 11 },
  { value: 5403, label: "Sampang", kab: 135, prop: 11 },
  { value: 5404, label: "Sokobanah", kab: 135, prop: 11 },
  { value: 5405, label: "Sreseh", kab: 135, prop: 11 },
  { value: 5406, label: "Tambelangan", kab: 135, prop: 11 },
  { value: 5407, label: "Torjun", kab: 135, prop: 11 },
  { value: 5631, label: "Balongbendo", kab: 136, prop: 11 },
  { value: 5632, label: "Buduran", kab: 136, prop: 11 },
  { value: 5633, label: "Candi", kab: 136, prop: 11 },
  { value: 5634, label: "Gedangan", kab: 136, prop: 11 },
  { value: 5635, label: "Jabon", kab: 136, prop: 11 },
  { value: 5636, label: "Krembung", kab: 136, prop: 11 },
  { value: 5637, label: "Krian", kab: 136, prop: 11 },
  { value: 5638, label: "Porong", kab: 136, prop: 11 },
  { value: 5639, label: "Prambon", kab: 136, prop: 11 },
  { value: 5640, label: "Sedati", kab: 136, prop: 11 },
  { value: 5641, label: "Sidoarjo", kab: 136, prop: 11 },
  { value: 5642, label: "Sukodono", kab: 136, prop: 11 },
  { value: 5643, label: "Taman", kab: 136, prop: 11 },
  { value: 5644, label: "Tanggulangin", kab: 136, prop: 11 },
  { value: 5645, label: "Tarik", kab: 136, prop: 11 },
  { value: 5646, label: "Tulangan", kab: 136, prop: 11 },
  { value: 5647, label: "Waru", kab: 136, prop: 11 },
  { value: 5648, label: "Wonoayu", kab: 136, prop: 11 },
  { value: 5762, label: "Arjasa", kab: 137, prop: 11 },
  { value: 5763, label: "Asembagus", kab: 137, prop: 11 },
  { value: 5764, label: "Banyuglugur", kab: 137, prop: 11 },
  { value: 5765, label: "Banyuputih", kab: 137, prop: 11 },
  { value: 5766, label: "Besuki", kab: 137, prop: 11 },
  { value: 5767, label: "Bungatan", kab: 137, prop: 11 },
  { value: 5768, label: "Jangkar", kab: 137, prop: 11 },
  { value: 5769, label: "Jatibanteng", kab: 137, prop: 11 },
  { value: 5770, label: "Kapongan", kab: 137, prop: 11 },
  { value: 5771, label: "Kendit", kab: 137, prop: 11 },
  { value: 5772, label: "Mangaran", kab: 137, prop: 11 },
  { value: 5773, label: "Mlandingan", kab: 137, prop: 11 },
  { value: 5774, label: "Panarukan", kab: 137, prop: 11 },
  { value: 5775, label: "Panji", kab: 137, prop: 11 },
  { value: 5776, label: "Situbondo", kab: 137, prop: 11 },
  { value: 5777, label: "Suboh", kab: 137, prop: 11 },
  { value: 5778, label: "Sumbermalang", kab: 137, prop: 11 },
  { value: 6091, label: "Ambunten", kab: 138, prop: 11 },
  { value: 6092, label: "Arjasa", kab: 138, prop: 11 },
  { value: 6093, label: "Batang Batang", kab: 138, prop: 11 },
  { value: 6094, label: "Batuan", kab: 138, prop: 11 },
  { value: 6095, label: "Batuputih", kab: 138, prop: 11 },
  { value: 6096, label: "Bluto", kab: 138, prop: 11 },
  { value: 6097, label: "Dasuk", kab: 138, prop: 11 },
  { value: 6098, label: "Dungkek", kab: 138, prop: 11 },
  { value: 6099, label: "Ganding", kab: 138, prop: 11 },
  { value: 6100, label: "Gapura", kab: 138, prop: 11 },
  { value: 6101, label: "Gayam", kab: 138, prop: 11 },
  { value: 6102, label: "Gili Ginting (Giligenteng)", kab: 138, prop: 11 },
  { value: 6103, label: "Guluk Guluk", kab: 138, prop: 11 },
  { value: 6104, label: "Kalianget", kab: 138, prop: 11 },
  { value: 6105, label: "Kangayan", kab: 138, prop: 11 },
  { value: 6106, label: "Kota Sumenep", kab: 138, prop: 11 },
  { value: 6107, label: "Lenteng", kab: 138, prop: 11 },
  { value: 6108, label: "Manding", kab: 138, prop: 11 },
  { value: 6109, label: "Masalembu", kab: 138, prop: 11 },
  { value: 6110, label: "Nonggunong", kab: 138, prop: 11 },
  { value: 6111, label: "Pasongsongan", kab: 138, prop: 11 },
  { value: 6112, label: "Pragaan", kab: 138, prop: 11 },
  { value: 6113, label: "Raas (Raas)", kab: 138, prop: 11 },
  { value: 6114, label: "Rubaru", kab: 138, prop: 11 },
  { value: 6115, label: "Sapeken", kab: 138, prop: 11 },
  { value: 6116, label: "Saronggi", kab: 138, prop: 11 },
  { value: 6117, label: "Talango", kab: 138, prop: 11 },
  { value: 6131, label: "Asemrowo", kab: 139, prop: 11 },
  { value: 6132, label: "Benowo", kab: 139, prop: 11 },
  { value: 6133, label: "Bubutan", kab: 139, prop: 11 },
  { value: 6134, label: "Bulak", kab: 139, prop: 11 },
  { value: 6135, label: "Dukuh Pakis", kab: 139, prop: 11 },
  { value: 6136, label: "Gayungan", kab: 139, prop: 11 },
  { value: 6137, label: "Genteng", kab: 139, prop: 11 },
  { value: 6138, label: "Gubeng", kab: 139, prop: 11 },
  { value: 6139, label: "Gununganyar", kab: 139, prop: 11 },
  { value: 6140, label: "Jambangan", kab: 139, prop: 11 },
  { value: 6141, label: "Karangpilang", kab: 139, prop: 11 },
  { value: 6142, label: "Kenjeran", kab: 139, prop: 11 },
  { value: 6143, label: "Krembangan", kab: 139, prop: 11 },
  { value: 6144, label: "Lakar Santri", kab: 139, prop: 11 },
  { value: 6145, label: "Mulyorejo", kab: 139, prop: 11 },
  { value: 6146, label: "Pabean Cantikan", kab: 139, prop: 11 },
  { value: 6147, label: "Pakal", kab: 139, prop: 11 },
  { value: 6148, label: "Rungkut", kab: 139, prop: 11 },
  { value: 6149, label: "Sambikerep", kab: 139, prop: 11 },
  { value: 6150, label: "Sawahan", kab: 139, prop: 11 },
  { value: 6151, label: "Semampir", kab: 139, prop: 11 },
  { value: 6152, label: "Simokerto", kab: 139, prop: 11 },
  { value: 6153, label: "Sukolilo", kab: 139, prop: 11 },
  { value: 6154, label: "Sukomanunggal", kab: 139, prop: 11 },
  { value: 6155, label: "Tambaksari", kab: 139, prop: 11 },
  { value: 6156, label: "Tandes", kab: 139, prop: 11 },
  { value: 6157, label: "Tegalsari", kab: 139, prop: 11 },
  { value: 6158, label: "Tenggilis Mejoyo", kab: 139, prop: 11 },
  { value: 6159, label: "Wiyung", kab: 139, prop: 11 },
  { value: 6160, label: "Wonocolo", kab: 139, prop: 11 },
  { value: 6161, label: "Wonokromo", kab: 139, prop: 11 },
  { value: 6759, label: "Bendungan", kab: 140, prop: 11 },
  { value: 6760, label: "Dongko", kab: 140, prop: 11 },
  { value: 6761, label: "Durenan", kab: 140, prop: 11 },
  { value: 6762, label: "Gandusari", kab: 140, prop: 11 },
  { value: 6763, label: "Kampak", kab: 140, prop: 11 },
  { value: 6764, label: "Karangan", kab: 140, prop: 11 },
  { value: 6765, label: "Munjungan", kab: 140, prop: 11 },
  { value: 6766, label: "Panggul", kab: 140, prop: 11 },
  { value: 6767, label: "Pogalan", kab: 140, prop: 11 },
  { value: 6768, label: "Pule", kab: 140, prop: 11 },
  { value: 6769, label: "Suruh", kab: 140, prop: 11 },
  { value: 6770, label: "Trenggalek", kab: 140, prop: 11 },
  { value: 6771, label: "Tugu", kab: 140, prop: 11 },
  { value: 6772, label: "Watulimo", kab: 140, prop: 11 },
  { value: 6778, label: "Bancar", kab: 141, prop: 11 },
  { value: 6779, label: "Bangilan", kab: 141, prop: 11 },
  { value: 6780, label: "Grabagan", kab: 141, prop: 11 },
  { value: 6781, label: "Jatirogo", kab: 141, prop: 11 },
  { value: 6782, label: "Jenu", kab: 141, prop: 11 },
  { value: 6783, label: "Kenduruan", kab: 141, prop: 11 },
  { value: 6784, label: "Kerek", kab: 141, prop: 11 },
  { value: 6785, label: "Merakurak", kab: 141, prop: 11 },
  { value: 6786, label: "Montong", kab: 141, prop: 11 },
  { value: 6787, label: "Palang", kab: 141, prop: 11 },
  { value: 6788, label: "Parengan", kab: 141, prop: 11 },
  { value: 6789, label: "Plumpang", kab: 141, prop: 11 },
  { value: 6790, label: "Rengel", kab: 141, prop: 11 },
  { value: 6791, label: "Semanding", kab: 141, prop: 11 },
  { value: 6792, label: "Senori", kab: 141, prop: 11 },
  { value: 6793, label: "Singgahan", kab: 141, prop: 11 },
  { value: 6794, label: "Soko", kab: 141, prop: 11 },
  { value: 6795, label: "Tambakboyo", kab: 141, prop: 11 },
  { value: 6796, label: "Tuban", kab: 141, prop: 11 },
  { value: 6797, label: "Widang", kab: 141, prop: 11 },
  { value: 6821, label: "Bandung", kab: 142, prop: 11 },
  { value: 6822, label: "Besuki", kab: 142, prop: 11 },
  { value: 6823, label: "Boyolangu", kab: 142, prop: 11 },
  { value: 6824, label: "Campur Darat", kab: 142, prop: 11 },
  { value: 6825, label: "Gondang", kab: 142, prop: 11 },
  { value: 6826, label: "Kalidawir", kab: 142, prop: 11 },
  { value: 6827, label: "Karang Rejo", kab: 142, prop: 11 },
  { value: 6828, label: "Kauman", kab: 142, prop: 11 },
  { value: 6829, label: "Kedungwaru", kab: 142, prop: 11 },
  { value: 6830, label: "Ngantru", kab: 142, prop: 11 },
  { value: 6831, label: "Ngunut", kab: 142, prop: 11 },
  { value: 6832, label: "Pagerwojo", kab: 142, prop: 11 },
  { value: 6833, label: "Pakel", kab: 142, prop: 11 },
  { value: 6834, label: "Pucanglaban", kab: 142, prop: 11 },
  { value: 6835, label: "Rejotangan", kab: 142, prop: 11 },
  { value: 6836, label: "Sendang", kab: 142, prop: 11 },
  { value: 6837, label: "Sumbergempol", kab: 142, prop: 11 },
  { value: 6838, label: "Tanggung Gunung", kab: 142, prop: 11 },
  { value: 6839, label: "Tulungagung", kab: 142, prop: 11 },
  { value: 815, label: "Bengkayang", kab: 143, prop: 12 },
  { value: 816, label: "Capkala", kab: 143, prop: 12 },
  { value: 817, label: "Jagoi Babang", kab: 143, prop: 12 },
  { value: 818, label: "Ledo", kab: 143, prop: 12 },
  { value: 819, label: "Lembah Bawang", kab: 143, prop: 12 },
  { value: 820, label: "Lumar", kab: 143, prop: 12 },
  { value: 821, label: "Monterado", kab: 143, prop: 12 },
  { value: 822, label: "Samalantan", kab: 143, prop: 12 },
  { value: 823, label: "Sanggau Ledo", kab: 143, prop: 12 },
  { value: 824, label: "Seluas", kab: 143, prop: 12 },
  { value: 825, label: "Siding", kab: 143, prop: 12 },
  { value: 826, label: "Sungai Betung", kab: 143, prop: 12 },
  { value: 827, label: "Sungai Raya", kab: 143, prop: 12 },
  { value: 828, label: "Sungai Raya Kepulauan", kab: 143, prop: 12 },
  { value: 829, label: "Suti Semarang", kab: 143, prop: 12 },
  { value: 830, label: "Teriak", kab: 143, prop: 12 },
  { value: 831, label: "Tujuh Belas", kab: 143, prop: 12 },
  { value: 2330, label: "Badau", kab: 144, prop: 12 },
  { value: 2331, label: "Batang Lupar", kab: 144, prop: 12 },
  { value: 2332, label: "Bika", kab: 144, prop: 12 },
  { value: 2333, label: "Boyan Tanjung", kab: 144, prop: 12 },
  { value: 2334, label: "Bunut Hilir", kab: 144, prop: 12 },
  { value: 2335, label: "Bunut Hulu", kab: 144, prop: 12 },
  { value: 2336, label: "Embaloh Hilir", kab: 144, prop: 12 },
  { value: 2337, label: "Embaloh Hulu", kab: 144, prop: 12 },
  { value: 2338, label: "Empanang", kab: 144, prop: 12 },
  { value: 2339, label: "Hulu Gurung", kab: 144, prop: 12 },
  { value: 2340, label: "Jongkong (Jengkong)", kab: 144, prop: 12 },
  { value: 2341, label: "Kalis", kab: 144, prop: 12 },
  { value: 2342, label: "Mentebah", kab: 144, prop: 12 },
  { value: 2343, label: "Pengkadan (Batu Datu)", kab: 144, prop: 12 },
  { value: 2344, label: "Puring Kencana", kab: 144, prop: 12 },
  { value: 2345, label: "Putussibau Selatan", kab: 144, prop: 12 },
  { value: 2346, label: "Putussibau Utara", kab: 144, prop: 12 },
  { value: 2347, label: "Seberuang", kab: 144, prop: 12 },
  { value: 2348, label: "Selimbau", kab: 144, prop: 12 },
  { value: 2349, label: "Semitau", kab: 144, prop: 12 },
  { value: 2350, label: "Silat Hilir", kab: 144, prop: 12 },
  { value: 2351, label: "Silat Hulu", kab: 144, prop: 12 },
  { value: 2352, label: "Suhaid", kab: 144, prop: 12 },
  { value: 2465, label: "Kepulauan Karimata", kab: 145, prop: 12 },
  {
    value: 2466,
    label: "Pulau Maya (Pulau Maya Karimata)",
    kab: 145,
    prop: 12,
  },
  { value: 2467, label: "Seponti", kab: 145, prop: 12 },
  { value: 2468, label: "Simpang Hilir", kab: 145, prop: 12 },
  { value: 2469, label: "Sukadana", kab: 145, prop: 12 },
  { value: 2470, label: "Teluk Batang", kab: 145, prop: 12 },
  { value: 2702, label: "Air Upas", kab: 146, prop: 12 },
  { value: 2703, label: "Benua Kayong", kab: 146, prop: 12 },
  { value: 2704, label: "Delta Pawan", kab: 146, prop: 12 },
  { value: 2705, label: "Hulu Sungai", kab: 146, prop: 12 },
  { value: 2706, label: "Jelai Hulu", kab: 146, prop: 12 },
  { value: 2707, label: "Kendawangan", kab: 146, prop: 12 },
  { value: 2708, label: "Manis Mata", kab: 146, prop: 12 },
  { value: 2709, label: "Marau", kab: 146, prop: 12 },
  { value: 2710, label: "Matan Hilir Selatan", kab: 146, prop: 12 },
  { value: 2711, label: "Matan Hilir Utara", kab: 146, prop: 12 },
  { value: 2712, label: "Muara Pawan", kab: 146, prop: 12 },
  { value: 2713, label: "Nanga Tayap", kab: 146, prop: 12 },
  { value: 2714, label: "Pemahan", kab: 146, prop: 12 },
  { value: 2715, label: "Sandai", kab: 146, prop: 12 },
  { value: 2716, label: "Simpang Dua", kab: 146, prop: 12 },
  { value: 2717, label: "Simpang Hulu", kab: 146, prop: 12 },
  { value: 2718, label: "Singkup", kab: 146, prop: 12 },
  { value: 2719, label: "Sungai Laur", kab: 146, prop: 12 },
  { value: 2720, label: "Sungai Melayu Rayak", kab: 146, prop: 12 },
  { value: 2721, label: "Tumbang Titi", kab: 146, prop: 12 },
  { value: 2912, label: "Batu Ampar", kab: 147, prop: 12 },
  { value: 2913, label: "Kuala Mandor-B", kab: 147, prop: 12 },
  { value: 2914, label: "Kubu", kab: 147, prop: 12 },
  { value: 2915, label: "Rasau Jaya", kab: 147, prop: 12 },
  { value: 2916, label: "Sei/Sungai Ambawang", kab: 147, prop: 12 },
  { value: 2917, label: "Sei/Sungai Kakap", kab: 147, prop: 12 },
  { value: 2918, label: "Sei/Sungai Raya", kab: 147, prop: 12 },
  { value: 2919, label: "Teluk/Telok Pakedai", kab: 147, prop: 12 },
  { value: 2920, label: "Terentang", kab: 147, prop: 12 },
  { value: 3247, label: "Air Besar", kab: 148, prop: 12 },
  { value: 3248, label: "Banyuke Hulu", kab: 148, prop: 12 },
  { value: 3249, label: "Jelimpo", kab: 148, prop: 12 },
  { value: 3250, label: "Kuala Behe", kab: 148, prop: 12 },
  { value: 3251, label: "Mandor", kab: 148, prop: 12 },
  { value: 3252, label: "Mempawah Hulu", kab: 148, prop: 12 },
  { value: 3253, label: "Menjalin", kab: 148, prop: 12 },
  { value: 3254, label: "Menyuke", kab: 148, prop: 12 },
  { value: 3255, label: "Meranti", kab: 148, prop: 12 },
  { value: 3256, label: "Ngabang", kab: 148, prop: 12 },
  { value: 3257, label: "Sebangki", kab: 148, prop: 12 },
  { value: 3258, label: "Sengah Temila", kab: 148, prop: 12 },
  { value: 3259, label: "Sompak", kab: 148, prop: 12 },
  { value: 3927, label: "Belimbing", kab: 149, prop: 12 },
  { value: 3928, label: "Belimbing Hulu", kab: 149, prop: 12 },
  { value: 3929, label: "Ella Hilir", kab: 149, prop: 12 },
  { value: 3930, label: "Menukung", kab: 149, prop: 12 },
  { value: 3931, label: "Nanga Pinoh", kab: 149, prop: 12 },
  { value: 3932, label: "Pinoh Selatan", kab: 149, prop: 12 },
  { value: 3933, label: "Pinoh Utara", kab: 149, prop: 12 },
  { value: 3934, label: "Sayan", kab: 149, prop: 12 },
  { value: 3935, label: "Sokan", kab: 149, prop: 12 },
  { value: 3936, label: "Tanah Pinoh", kab: 149, prop: 12 },
  { value: 3937, label: "Tanah Pinoh Barat", kab: 149, prop: 12 },
  { value: 5092, label: "Anjongan", kab: 150, prop: 12 },
  { value: 5093, label: "Mempawah Hilir", kab: 150, prop: 12 },
  { value: 5094, label: "Mempawah Timur", kab: 150, prop: 12 },
  { value: 5095, label: "Sadaniang", kab: 150, prop: 12 },
  { value: 5096, label: "Segedong", kab: 150, prop: 12 },
  { value: 5097, label: "Sei/Sungai Kunyit", kab: 150, prop: 12 },
  { value: 5098, label: "Sei/Sungai Pinyuh", kab: 150, prop: 12 },
  { value: 5099, label: "Siantan", kab: 150, prop: 12 },
  { value: 5100, label: "Toho", kab: 150, prop: 12 },
  { value: 5101, label: "Pontianak Barat", kab: 150, prop: 12 },
  { value: 5102, label: "Pontianak Kota", kab: 150, prop: 12 },
  { value: 5103, label: "Pontianak Selatan", kab: 150, prop: 12 },
  { value: 5104, label: "Pontianak Tenggara", kab: 150, prop: 12 },
  { value: 5105, label: "Pontianak Timur", kab: 150, prop: 12 },
  { value: 5106, label: "Pontianak Utara", kab: 150, prop: 12 },
  { value: 5366, label: "Galing", kab: 151, prop: 12 },
  { value: 5367, label: "Jawai", kab: 151, prop: 12 },
  { value: 5368, label: "Jawai Selatan", kab: 151, prop: 12 },
  { value: 5369, label: "Paloh", kab: 151, prop: 12 },
  { value: 5370, label: "Pemangkat", kab: 151, prop: 12 },
  { value: 5371, label: "Sajad", kab: 151, prop: 12 },
  { value: 5372, label: "Sajingan Besar", kab: 151, prop: 12 },
  { value: 5373, label: "Salatiga", kab: 151, prop: 12 },
  { value: 5374, label: "Sambas", kab: 151, prop: 12 },
  { value: 5375, label: "Sebawi", kab: 151, prop: 12 },
  { value: 5376, label: "Sejangkung", kab: 151, prop: 12 },
  { value: 5377, label: "Selakau", kab: 151, prop: 12 },
  { value: 5378, label: "Selakau Timur", kab: 151, prop: 12 },
  { value: 5379, label: "Semparuk", kab: 151, prop: 12 },
  { value: 5380, label: "Subah", kab: 151, prop: 12 },
  { value: 5381, label: "Tangaran", kab: 151, prop: 12 },
  { value: 5382, label: "Tebas", kab: 151, prop: 12 },
  { value: 5383, label: "Tekarang", kab: 151, prop: 12 },
  { value: 5384, label: "Teluk Keramat", kab: 151, prop: 12 },
  { value: 5408, label: "Balai", kab: 152, prop: 12 },
  { value: 5409, label: "Beduai (Beduwai)", kab: 152, prop: 12 },
  { value: 5410, label: "Bonti", kab: 152, prop: 12 },
  { value: 5411, label: "Entikong", kab: 152, prop: 12 },
  { value: 5412, label: "Jangkang", kab: 152, prop: 12 },
  { value: 5413, label: "Kapuas (Sanggau Kapuas)", kab: 152, prop: 12 },
  { value: 5414, label: "Kembayan", kab: 152, prop: 12 },
  { value: 5415, label: "Meliau", kab: 152, prop: 12 },
  { value: 5416, label: "Mukok", kab: 152, prop: 12 },
  { value: 5417, label: "Noyan", kab: 152, prop: 12 },
  { value: 5418, label: "Parindu", kab: 152, prop: 12 },
  { value: 5419, label: "Sekayam", kab: 152, prop: 12 },
  { value: 5420, label: "Tayan Hilir", kab: 152, prop: 12 },
  { value: 5421, label: "Tayan Hulu", kab: 152, prop: 12 },
  { value: 5422, label: "Toba", kab: 152, prop: 12 },
  { value: 5447, label: "Belitang", kab: 153, prop: 12 },
  { value: 5448, label: "Belitang Hilir", kab: 153, prop: 12 },
  { value: 5449, label: "Belitang Hulu", kab: 153, prop: 12 },
  { value: 5450, label: "Nanga Mahap", kab: 153, prop: 12 },
  { value: 5451, label: "Nanga Taman", kab: 153, prop: 12 },
  { value: 5452, label: "Sekadau Hilir", kab: 153, prop: 12 },
  { value: 5453, label: "Sekadau Hulu", kab: 153, prop: 12 },
  { value: 5734, label: "Singkawang Barat", kab: 154, prop: 12 },
  { value: 5735, label: "Singkawang Selatan", kab: 154, prop: 12 },
  { value: 5736, label: "Singkawang Tengah", kab: 154, prop: 12 },
  { value: 5737, label: "Singkawang Timur", kab: 154, prop: 12 },
  { value: 5738, label: "Singkawang Utara", kab: 154, prop: 12 },
  { value: 5748, label: "Ambalau", kab: 155, prop: 12 },
  { value: 5749, label: "Binjai Hulu", kab: 155, prop: 12 },
  { value: 5750, label: "Dedai", kab: 155, prop: 12 },
  { value: 5751, label: "Kayan Hilir", kab: 155, prop: 12 },
  { value: 5752, label: "Kayan Hulu", kab: 155, prop: 12 },
  { value: 5753, label: "Kelam Permai", kab: 155, prop: 12 },
  { value: 5754, label: "Ketungau Hilir", kab: 155, prop: 12 },
  { value: 5755, label: "Ketungau Hulu", kab: 155, prop: 12 },
  { value: 5756, label: "Ketungau Tengah", kab: 155, prop: 12 },
  { value: 5757, label: "Sepauk", kab: 155, prop: 12 },
  { value: 5758, label: "Serawai (Nanga Serawai)", kab: 155, prop: 12 },
  { value: 5759, label: "Sintang", kab: 155, prop: 12 },
  { value: 5760, label: "Sungai Tebelian", kab: 155, prop: 12 },
  { value: 5761, label: "Tempunak", kab: 155, prop: 12 },
  { value: 264, label: "Awayan", kab: 156, prop: 13 },
  { value: 265, label: "Batu Mandi", kab: 156, prop: 13 },
  { value: 266, label: "Halong", kab: 156, prop: 13 },
  { value: 267, label: "Juai", kab: 156, prop: 13 },
  { value: 268, label: "Lampihong", kab: 156, prop: 13 },
  { value: 269, label: "Paringin", kab: 156, prop: 13 },
  { value: 270, label: "Paringin Selatan", kab: 156, prop: 13 },
  { value: 271, label: "Tebing Tinggi", kab: 156, prop: 13 },
  { value: 476, label: "Aluh-Aluh", kab: 157, prop: 13 },
  { value: 477, label: "Aranio", kab: 157, prop: 13 },
  { value: 478, label: "Astambul", kab: 157, prop: 13 },
  { value: 479, label: "Beruntung Baru", kab: 157, prop: 13 },
  { value: 480, label: "Gambut", kab: 157, prop: 13 },
  { value: 481, label: "Karang Intan", kab: 157, prop: 13 },
  { value: 482, label: "Kertak Hanyar", kab: 157, prop: 13 },
  { value: 483, label: "Martapura Barat", kab: 157, prop: 13 },
  { value: 484, label: "Martapura Kota", kab: 157, prop: 13 },
  { value: 485, label: "Martapura Timur", kab: 157, prop: 13 },
  { value: 486, label: "Mataraman", kab: 157, prop: 13 },
  { value: 487, label: "Pengaron", kab: 157, prop: 13 },
  { value: 488, label: "Peramasan", kab: 157, prop: 13 },
  { value: 489, label: "Sambung Makmur", kab: 157, prop: 13 },
  { value: 490, label: "Sei/Sungai Pinang", kab: 157, prop: 13 },
  { value: 491, label: "Sei/Sungai Tabuk", kab: 157, prop: 13 },
  { value: 492, label: "Simpang Empat", kab: 157, prop: 13 },
  { value: 493, label: "Tatah Makmur", kab: 157, prop: 13 },
  { value: 494, label: "Telaga Bauntung", kab: 157, prop: 13 },
  { value: 499, label: "Banjar Baru Selatan", kab: 158, prop: 13 },
  { value: 500, label: "Banjar Baru Utara", kab: 158, prop: 13 },
  { value: 501, label: "Cempaka", kab: 158, prop: 13 },
  { value: 502, label: "Landasan Ulin", kab: 158, prop: 13 },
  { value: 503, label: "Liang Anggang", kab: 158, prop: 13 },
  { value: 504, label: "Banjarmasin Barat", kab: 159, prop: 13 },
  { value: 505, label: "Banjarmasin Selatan", kab: 159, prop: 13 },
  { value: 506, label: "Banjarmasin Tengah", kab: 159, prop: 13 },
  { value: 507, label: "Banjarmasin Timur", kab: 159, prop: 13 },
  { value: 508, label: "Banjarmasin Utara", kab: 159, prop: 13 },
  { value: 624, label: "Alalak", kab: 160, prop: 13 },
  { value: 625, label: "Anjir Muara", kab: 160, prop: 13 },
  { value: 626, label: "Anjir Pasar", kab: 160, prop: 13 },
  { value: 627, label: "Bakumpai", kab: 160, prop: 13 },
  { value: 628, label: "Barambai", kab: 160, prop: 13 },
  { value: 629, label: "Belawang", kab: 160, prop: 13 },
  { value: 630, label: "Cerbon", kab: 160, prop: 13 },
  { value: 631, label: "Jejangkit", kab: 160, prop: 13 },
  { value: 632, label: "Kuripan", kab: 160, prop: 13 },
  { value: 633, label: "Mandastana", kab: 160, prop: 13 },
  { value: 634, label: "Marabahan", kab: 160, prop: 13 },
  { value: 635, label: "Mekar Sari", kab: 160, prop: 13 },
  { value: 636, label: "Rantau Badauh", kab: 160, prop: 13 },
  { value: 637, label: "Tabukan", kab: 160, prop: 13 },
  { value: 638, label: "Tabunganen", kab: 160, prop: 13 },
  { value: 639, label: "Tamban", kab: 160, prop: 13 },
  { value: 640, label: "Wanaraya", kab: 160, prop: 13 },
  { value: 1974, label: "Angkinang", kab: 161, prop: 13 },
  { value: 1975, label: "Daha Barat", kab: 161, prop: 13 },
  { value: 1976, label: "Daha Selatan", kab: 161, prop: 13 },
  { value: 1977, label: "Daha Utara", kab: 161, prop: 13 },
  { value: 1978, label: "Kalumpang (Kelumpang)", kab: 161, prop: 13 },
  { value: 1979, label: "Kandangan", kab: 161, prop: 13 },
  { value: 1980, label: "Loksado", kab: 161, prop: 13 },
  { value: 1981, label: "Padang Batung", kab: 161, prop: 13 },
  { value: 1982, label: "Simpur", kab: 161, prop: 13 },
  { value: 1983, label: "Sungai Raya", kab: 161, prop: 13 },
  { value: 1984, label: "Telaga Langsat", kab: 161, prop: 13 },
  { value: 1985, label: "Barabai", kab: 162, prop: 13 },
  { value: 1986, label: "Batang Alai Selatan", kab: 162, prop: 13 },
  { value: 1987, label: "Batang Alai Timur", kab: 162, prop: 13 },
  { value: 1988, label: "Batang Alai Utara", kab: 162, prop: 13 },
  { value: 1989, label: "Batu Benawa", kab: 162, prop: 13 },
  { value: 1990, label: "Hantakan", kab: 162, prop: 13 },
  { value: 1991, label: "Haruyan", kab: 162, prop: 13 },
  { value: 1992, label: "Labuan Amas Selatan", kab: 162, prop: 13 },
  { value: 1993, label: "Labuan Amas Utara", kab: 162, prop: 13 },
  { value: 1994, label: "Limpasu", kab: 162, prop: 13 },
  { value: 1995, label: "Pandawan", kab: 162, prop: 13 },
  { value: 1996, label: "Amuntai Selatan", kab: 163, prop: 13 },
  { value: 1997, label: "Amuntai Tengah", kab: 163, prop: 13 },
  { value: 1998, label: "Amuntai Utara", kab: 163, prop: 13 },
  { value: 1999, label: "Babirik", kab: 163, prop: 13 },
  { value: 2000, label: "Banjang", kab: 163, prop: 13 },
  { value: 2001, label: "Danau Panggang", kab: 163, prop: 13 },
  { value: 2002, label: "Haur Gading", kab: 163, prop: 13 },
  { value: 2003, label: "Paminggir", kab: 163, prop: 13 },
  { value: 2004, label: "Sungai Pandan", kab: 163, prop: 13 },
  { value: 2005, label: "Sungai Tabukan", kab: 163, prop: 13 },
  { value: 2849, label: "Hampang", kab: 164, prop: 13 },
  { value: 2850, label: "Kelumpang Barat", kab: 164, prop: 13 },
  { value: 2851, label: "Kelumpang Hilir", kab: 164, prop: 13 },
  { value: 2852, label: "Kelumpang Hulu", kab: 164, prop: 13 },
  { value: 2853, label: "Kelumpang Selatan", kab: 164, prop: 13 },
  { value: 2854, label: "Kelumpang Tengah", kab: 164, prop: 13 },
  { value: 2855, label: "Kelumpang Utara", kab: 164, prop: 13 },
  { value: 2856, label: "Pamukan Barat", kab: 164, prop: 13 },
  { value: 2857, label: "Pamukan Selatan", kab: 164, prop: 13 },
  { value: 2858, label: "Pamukan Utara", kab: 164, prop: 13 },
  { value: 2859, label: "Pulau Laut Barat", kab: 164, prop: 13 },
  { value: 2860, label: "Pulau Laut Kepulauan", kab: 164, prop: 13 },
  { value: 2861, label: "Pulau Laut Selatan", kab: 164, prop: 13 },
  { value: 2862, label: "Pulau Laut Tanjung Selayar", kab: 164, prop: 13 },
  { value: 2863, label: "Pulau Laut Tengah", kab: 164, prop: 13 },
  { value: 2864, label: "Pulau Laut Timur", kab: 164, prop: 13 },
  { value: 2865, label: "Pulau Laut Utara", kab: 164, prop: 13 },
  { value: 2866, label: "Pulau Sebuku", kab: 164, prop: 13 },
  { value: 2867, label: "Pulau Sembilan", kab: 164, prop: 13 },
  { value: 2868, label: "Sampanahan", kab: 164, prop: 13 },
  { value: 2869, label: "Sungai Durian", kab: 164, prop: 13 },
  { value: 6167, label: "Banua Lawas", kab: 165, prop: 13 },
  { value: 6168, label: "Bintang Ara", kab: 165, prop: 13 },
  { value: 6169, label: "Haruai", kab: 165, prop: 13 },
  { value: 6170, label: "Jaro", kab: 165, prop: 13 },
  { value: 6171, label: "Kelua (Klua)", kab: 165, prop: 13 },
  { value: 6172, label: "Muara Harus", kab: 165, prop: 13 },
  { value: 6173, label: "Muara Uya", kab: 165, prop: 13 },
  { value: 6174, label: "Murung Pudak", kab: 165, prop: 13 },
  { value: 6175, label: "Pugaan", kab: 165, prop: 13 },
  { value: 6176, label: "Tanjung", kab: 165, prop: 13 },
  { value: 6177, label: "Tanta", kab: 165, prop: 13 },
  { value: 6178, label: "Upau", kab: 165, prop: 13 },
  { value: 6233, label: "Angsana", kab: 166, prop: 13 },
  { value: 6234, label: "Batulicin", kab: 166, prop: 13 },
  { value: 6235, label: "Karang Bintang", kab: 166, prop: 13 },
  { value: 6236, label: "Kuranji", kab: 166, prop: 13 },
  { value: 6237, label: "Kusan Hilir", kab: 166, prop: 13 },
  { value: 6238, label: "Kusan Hulu", kab: 166, prop: 13 },
  { value: 6239, label: "Mantewe", kab: 166, prop: 13 },
  { value: 6240, label: "Satui", kab: 166, prop: 13 },
  { value: 6241, label: "Simpang Empat", kab: 166, prop: 13 },
  { value: 6242, label: "Sungai Loban", kab: 166, prop: 13 },
  { value: 6257, label: "Bajuin", kab: 167, prop: 13 },
  { value: 6258, label: "Bati-Bati", kab: 167, prop: 13 },
  { value: 6259, label: "Batu Ampar", kab: 167, prop: 13 },
  { value: 6260, label: "Bumi Makmur", kab: 167, prop: 13 },
  { value: 6261, label: "Jorong", kab: 167, prop: 13 },
  { value: 6262, label: "Kintap", kab: 167, prop: 13 },
  { value: 6263, label: "Kurau", kab: 167, prop: 13 },
  { value: 6264, label: "Panyipatan", kab: 167, prop: 13 },
  { value: 6265, label: "Pelaihari", kab: 167, prop: 13 },
  { value: 6266, label: "Takisung", kab: 167, prop: 13 },
  { value: 6267, label: "Tambang Ulang", kab: 167, prop: 13 },
  { value: 6420, label: "Bakarangan", kab: 168, prop: 13 },
  { value: 6421, label: "Binuang", kab: 168, prop: 13 },
  { value: 6422, label: "Bungur", kab: 168, prop: 13 },
  { value: 6423, label: "Candi Laras Selatan", kab: 168, prop: 13 },
  { value: 6424, label: "Candi Laras Utara", kab: 168, prop: 13 },
  { value: 6425, label: "Hatungun", kab: 168, prop: 13 },
  { value: 6426, label: "Lokpaikat", kab: 168, prop: 13 },
  { value: 6427, label: "Piani", kab: 168, prop: 13 },
  { value: 6428, label: "Salam Babaris", kab: 168, prop: 13 },
  { value: 6429, label: "Tapin Selatan", kab: 168, prop: 13 },
  { value: 6430, label: "Tapin Tengah", kab: 168, prop: 13 },
  { value: 6431, label: "Tapin Utara", kab: 168, prop: 13 },
  { value: 641, label: "Dusun Hilir", kab: 169, prop: 14 },
  { value: 642, label: "Dusun Selatan", kab: 169, prop: 14 },
  { value: 643, label: "Dusun Utara", kab: 169, prop: 14 },
  { value: 644, label: "Gunung Bintang Awai", kab: 169, prop: 14 },
  { value: 645, label: "Jenamas", kab: 169, prop: 14 },
  { value: 646, label: "Karau Kuala", kab: 169, prop: 14 },
  { value: 647, label: "Awang", kab: 170, prop: 14 },
  { value: 648, label: "Benua Lima", kab: 170, prop: 14 },
  { value: 649, label: "Dusun Tengah", kab: 170, prop: 14 },
  { value: 650, label: "Dusun Timur", kab: 170, prop: 14 },
  { value: 651, label: "Karusen Janang", kab: 170, prop: 14 },
  { value: 652, label: "Paju Epat", kab: 170, prop: 14 },
  { value: 653, label: "Paku", kab: 170, prop: 14 },
  { value: 654, label: "Patangkep Tutui", kab: 170, prop: 14 },
  { value: 655, label: "Pematang Karau", kab: 170, prop: 14 },
  { value: 656, label: "Raren Batuah", kab: 170, prop: 14 },
  { value: 657, label: "Gunung Purei", kab: 171, prop: 14 },
  { value: 658, label: "Gunung Timang", kab: 171, prop: 14 },
  { value: 659, label: "Lahei", kab: 171, prop: 14 },
  { value: 660, label: "Lahei Barat", kab: 171, prop: 14 },
  { value: 661, label: "Montallat (Montalat)", kab: 171, prop: 14 },
  { value: 662, label: "Teweh Baru", kab: 171, prop: 14 },
  { value: 663, label: "Teweh Selatan", kab: 171, prop: 14 },
  { value: 664, label: "Teweh Tengah", kab: 171, prop: 14 },
  { value: 665, label: "Teweh Timur", kab: 171, prop: 14 },
  { value: 1883, label: "Damang Batu", kab: 172, prop: 14 },
  { value: 1884, label: "Kahayan Hulu Utara", kab: 172, prop: 14 },
  { value: 1885, label: "Kurun", kab: 172, prop: 14 },
  { value: 1886, label: "Manuhing", kab: 172, prop: 14 },
  { value: 1887, label: "Manuhing Raya", kab: 172, prop: 14 },
  { value: 1888, label: "Mihing Raya", kab: 172, prop: 14 },
  { value: 1889, label: "Miri Manasa", kab: 172, prop: 14 },
  { value: 1890, label: "Rungan", kab: 172, prop: 14 },
  { value: 1891, label: "Rungan Barat", kab: 172, prop: 14 },
  { value: 1892, label: "Rungan Hulu", kab: 172, prop: 14 },
  { value: 1893, label: "Sepang (Sepang Simin)", kab: 172, prop: 14 },
  { value: 1894, label: "Tewah", kab: 172, prop: 14 },
  { value: 2313, label: "Basarang", kab: 173, prop: 14 },
  { value: 2314, label: "Bataguh", kab: 173, prop: 14 },
  { value: 2315, label: "Dadahup", kab: 173, prop: 14 },
  { value: 2316, label: "Kapuas Barat", kab: 173, prop: 14 },
  { value: 2317, label: "Kapuas Hilir", kab: 173, prop: 14 },
  { value: 2318, label: "Kapuas Hulu", kab: 173, prop: 14 },
  { value: 2319, label: "Kapuas Kuala", kab: 173, prop: 14 },
  { value: 2320, label: "Kapuas Murung", kab: 173, prop: 14 },
  { value: 2321, label: "Kapuas Tengah", kab: 173, prop: 14 },
  { value: 2322, label: "Kapuas Timur", kab: 173, prop: 14 },
  { value: 2323, label: "Mandau Talawang", kab: 173, prop: 14 },
  { value: 2324, label: "Mantangai", kab: 173, prop: 14 },
  { value: 2325, label: "Pasak Talawang", kab: 173, prop: 14 },
  { value: 2326, label: "Pulau Petak", kab: 173, prop: 14 },
  { value: 2327, label: "Selat", kab: 173, prop: 14 },
  { value: 2328, label: "Tamban Catur", kab: 173, prop: 14 },
  { value: 2329, label: "Timpah", kab: 173, prop: 14 },
  { value: 2437, label: "Bukit Raya", kab: 174, prop: 14 },
  { value: 2438, label: "Kamipang", kab: 174, prop: 14 },
  { value: 2439, label: "Katingan Hilir", kab: 174, prop: 14 },
  { value: 2440, label: "Katingan Hulu", kab: 174, prop: 14 },
  { value: 2441, label: "Katingan Kuala", kab: 174, prop: 14 },
  { value: 2442, label: "Katingan Tengah", kab: 174, prop: 14 },
  { value: 2443, label: "Marikit", kab: 174, prop: 14 },
  { value: 2444, label: "Mendawai", kab: 174, prop: 14 },
  { value: 2445, label: "Petak Malai", kab: 174, prop: 14 },
  { value: 2446, label: "Pulau Malan", kab: 174, prop: 14 },
  {
    value: 2447,
    label: "Sanaman Mantikei (Senamang Mantikei)",
    kab: 174,
    prop: 14,
  },
  { value: 2448, label: "Tasik Payawan", kab: 174, prop: 14 },
  {
    value: 2449,
    label: "Tewang Sanggalang Garing (Sangalang)",
    kab: 174,
    prop: 14,
  },
  { value: 2874, label: "Arut Selatan", kab: 175, prop: 14 },
  { value: 2875, label: "Arut Utara", kab: 175, prop: 14 },
  { value: 2876, label: "Kotawaringin Lama", kab: 175, prop: 14 },
  { value: 2877, label: "Kumai", kab: 175, prop: 14 },
  { value: 2878, label: "Pangkalan Banteng", kab: 175, prop: 14 },
  { value: 2879, label: "Pangkalan Lada", kab: 175, prop: 14 },
  { value: 2880, label: "Antang Kalang", kab: 176, prop: 14 },
  { value: 2881, label: "Baamang", kab: 176, prop: 14 },
  { value: 2882, label: "Bukit Santuei", kab: 176, prop: 14 },
  { value: 2883, label: "Cempaga", kab: 176, prop: 14 },
  { value: 2884, label: "Cempaga Hulu", kab: 176, prop: 14 },
  { value: 2885, label: "Kota Besi", kab: 176, prop: 14 },
  { value: 2886, label: "Mentawa Baru (Ketapang)", kab: 176, prop: 14 },
  { value: 2887, label: "Mentaya Hilir Selatan", kab: 176, prop: 14 },
  { value: 2888, label: "Mentaya Hilir Utara", kab: 176, prop: 14 },
  { value: 2889, label: "Mentaya Hulu", kab: 176, prop: 14 },
  { value: 2890, label: "Parenggean", kab: 176, prop: 14 },
  { value: 2891, label: "Pulau Hanaut", kab: 176, prop: 14 },
  { value: 2892, label: "Seranau", kab: 176, prop: 14 },
  { value: 2893, label: "Telaga Antang", kab: 176, prop: 14 },
  { value: 2894, label: "Telawang", kab: 176, prop: 14 },
  { value: 2895, label: "Teluk Sampit", kab: 176, prop: 14 },
  { value: 2896, label: "Tualan Hulu", kab: 176, prop: 14 },
  { value: 3105, label: "Batangkawa", kab: 177, prop: 14 },
  { value: 3106, label: "Belantikan Raya", kab: 177, prop: 14 },
  { value: 3107, label: "Bulik", kab: 177, prop: 14 },
  { value: 3108, label: "Bulik Timur", kab: 177, prop: 14 },
  { value: 3109, label: "Delang", kab: 177, prop: 14 },
  { value: 3110, label: "Lamandau", kab: 177, prop: 14 },
  { value: 3111, label: "Menthobi Raya", kab: 177, prop: 14 },
  { value: 3112, label: "Sematu Jaya", kab: 177, prop: 14 },
  { value: 4190, label: "Barito Tuhup Raya", kab: 178, prop: 14 },
  { value: 4191, label: "Laung Tuhup", kab: 178, prop: 14 },
  { value: 4192, label: "Murung", kab: 178, prop: 14 },
  { value: 4193, label: "Permata Intan", kab: 178, prop: 14 },
  { value: 4194, label: "Seribu Riam", kab: 178, prop: 14 },
  { value: 4195, label: "Sumber Barito", kab: 178, prop: 14 },
  { value: 4196, label: "Sungai Babuat", kab: 178, prop: 14 },
  { value: 4197, label: "Tanah Siang", kab: 178, prop: 14 },
  { value: 4198, label: "Tanah Siang Selatan", kab: 178, prop: 14 },
  { value: 4199, label: "Uut Murung", kab: 178, prop: 14 },
  { value: 4604, label: "Bukit Batu", kab: 179, prop: 14 },
  { value: 4605, label: "Jekan Raya", kab: 179, prop: 14 },
  { value: 4606, label: "Pahandut", kab: 179, prop: 14 },
  { value: 4607, label: "Rakumpit", kab: 179, prop: 14 },
  { value: 4608, label: "Sebangau", kab: 179, prop: 14 },
  { value: 5170, label: "Banama Tingang", kab: 180, prop: 14 },
  { value: 5171, label: "Jabiren Raya", kab: 180, prop: 14 },
  { value: 5172, label: "Kahayan Hilir", kab: 180, prop: 14 },
  { value: 5173, label: "Kahayan Kuala", kab: 180, prop: 14 },
  { value: 5174, label: "Kahayan Tengah", kab: 180, prop: 14 },
  { value: 5175, label: "Maliku", kab: 180, prop: 14 },
  { value: 5176, label: "Pandih Batu", kab: 180, prop: 14 },
  { value: 5177, label: "Sebangau Kuala", kab: 180, prop: 14 },
  { value: 5592, label: "Batu Ampar", kab: 181, prop: 14 },
  { value: 5593, label: "Danau Seluluk", kab: 181, prop: 14 },
  { value: 5594, label: "Danau Sembuluh", kab: 181, prop: 14 },
  { value: 5595, label: "Hanau", kab: 181, prop: 14 },
  { value: 5596, label: "Seruyan Hilir", kab: 181, prop: 14 },
  { value: 5597, label: "Seruyan Hilir Timur", kab: 181, prop: 14 },
  { value: 5598, label: "Seruyan Hulu", kab: 181, prop: 14 },
  { value: 5599, label: "Seruyan Raya", kab: 181, prop: 14 },
  { value: 5600, label: "Seruyan Tengah", kab: 181, prop: 14 },
  { value: 5601, label: "Suling Tambun", kab: 181, prop: 14 },
  { value: 5972, label: "Balai Riam", kab: 182, prop: 14 },
  { value: 5973, label: "Jelai", kab: 182, prop: 14 },
  { value: 5974, label: "Pantai Lunci", kab: 182, prop: 14 },
  { value: 5975, label: "Permata Kecubung", kab: 182, prop: 14 },
  { value: 5976, label: "Sukamara", kab: 182, prop: 14 },
  { value: 272, label: "Balikpapan Barat", kab: 183, prop: 15 },
  { value: 273, label: "Balikpapan Kota", kab: 183, prop: 15 },
  { value: 274, label: "Balikpapan Selatan", kab: 183, prop: 15 },
  { value: 275, label: "Balikpapan Tengah", kab: 183, prop: 15 },
  { value: 276, label: "Balikpapan Timur", kab: 183, prop: 15 },
  { value: 277, label: "Balikpapan Utara", kab: 183, prop: 15 },
  { value: 879, label: "Batu Putih", kab: 184, prop: 15 },
  { value: 880, label: "Biatan", kab: 184, prop: 15 },
  { value: 881, label: "Biduk-Biduk", kab: 184, prop: 15 },
  { value: 882, label: "Derawan (Pulau Derawan)", kab: 184, prop: 15 },
  { value: 883, label: "Gunung Tabur", kab: 184, prop: 15 },
  { value: 884, label: "Kelay", kab: 184, prop: 15 },
  { value: 885, label: "Maratua", kab: 184, prop: 15 },
  { value: 886, label: "Sambaliung", kab: 184, prop: 15 },
  { value: 887, label: "Segah", kab: 184, prop: 15 },
  { value: 888, label: "Tabalar", kab: 184, prop: 15 },
  { value: 889, label: "Talisayan", kab: 184, prop: 15 },
  { value: 890, label: "Tanjung Redeb", kab: 184, prop: 15 },
  { value: 891, label: "Teluk Bayur", kab: 184, prop: 15 },
  { value: 1217, label: "Bontang Barat", kab: 185, prop: 15 },
  { value: 1218, label: "Bontang Selatan", kab: 185, prop: 15 },
  { value: 1219, label: "Bontang Utara", kab: 185, prop: 15 },
  { value: 3004, label: "Barong Tongkok", kab: 186, prop: 15 },
  { value: 3005, label: "Bentian Besar", kab: 186, prop: 15 },
  { value: 3006, label: "Bongan", kab: 186, prop: 15 },
  { value: 3007, label: "Damai", kab: 186, prop: 15 },
  { value: 3008, label: "Jempang", kab: 186, prop: 15 },
  { value: 3009, label: "Laham", kab: 186, prop: 15 },
  { value: 3010, label: "Linggang Bigung", kab: 186, prop: 15 },
  { value: 3011, label: "Long Apari", kab: 186, prop: 15 },
  { value: 3012, label: "Long Bagun", kab: 186, prop: 15 },
  { value: 3013, label: "Long Hubung", kab: 186, prop: 15 },
  { value: 3014, label: "Long Iram", kab: 186, prop: 15 },
  { value: 3015, label: "Long Pahangai", kab: 186, prop: 15 },
  {
    value: 3016,
    label: "Manor Bulatin (Mook Manaar Bulatn)",
    kab: 186,
    prop: 15,
  },
  { value: 3017, label: "Melak", kab: 186, prop: 15 },
  { value: 3018, label: "Muara Lawa", kab: 186, prop: 15 },
  { value: 3019, label: "Muara Pahu", kab: 186, prop: 15 },
  { value: 3020, label: "Nyuatan", kab: 186, prop: 15 },
  { value: 3021, label: "Penyinggahan", kab: 186, prop: 15 },
  { value: 3022, label: "Sekolaq Darat", kab: 186, prop: 15 },
  { value: 3023, label: "Siluq Ngurai", kab: 186, prop: 15 },
  { value: 3024, label: "Tering", kab: 186, prop: 15 },
  { value: 3025, label: "Anggana", kab: 187, prop: 15 },
  { value: 3026, label: "Kembang Janggut", kab: 187, prop: 15 },
  { value: 3027, label: "Kenohan", kab: 187, prop: 15 },
  { value: 3028, label: "Kota Bangun", kab: 187, prop: 15 },
  { value: 3029, label: "Loa Janan", kab: 187, prop: 15 },
  { value: 3030, label: "Loa Kulu", kab: 187, prop: 15 },
  { value: 3031, label: "Marang Kayu", kab: 187, prop: 15 },
  { value: 3032, label: "Muara Badak", kab: 187, prop: 15 },
  { value: 3033, label: "Muara Jawa", kab: 187, prop: 15 },
  { value: 3034, label: "Muara Kaman", kab: 187, prop: 15 },
  { value: 3035, label: "Muara Muntai", kab: 187, prop: 15 },
  { value: 3036, label: "Muara Wis", kab: 187, prop: 15 },
  { value: 3037, label: "Samboja (Semboja)", kab: 187, prop: 15 },
  { value: 3038, label: "Sanga-Sanga", kab: 187, prop: 15 },
  { value: 3039, label: "Sebulu", kab: 187, prop: 15 },
  { value: 3040, label: "Tabang", kab: 187, prop: 15 },
  { value: 3041, label: "Tenggarong", kab: 187, prop: 15 },
  { value: 3042, label: "Tenggarong Seberang", kab: 187, prop: 15 },
  { value: 3043, label: "Batu Ampar", kab: 188, prop: 15 },
  { value: 3044, label: "Bengalon", kab: 188, prop: 15 },
  { value: 3045, label: "Busang", kab: 188, prop: 15 },
  { value: 3046, label: "Kaliorang", kab: 188, prop: 15 },
  { value: 3047, label: "Karangan", kab: 188, prop: 15 },
  { value: 3048, label: "Kaubun", kab: 188, prop: 15 },
  { value: 3049, label: "Kongbeng", kab: 188, prop: 15 },
  { value: 3050, label: "Long Mesangat (Mesengat)", kab: 188, prop: 15 },
  { value: 3051, label: "Muara Ancalong", kab: 188, prop: 15 },
  { value: 3052, label: "Muara Bengkal", kab: 188, prop: 15 },
  { value: 3053, label: "Muara Wahau", kab: 188, prop: 15 },
  { value: 3054, label: "Rantau Pulung", kab: 188, prop: 15 },
  { value: 3055, label: "Sandaran", kab: 188, prop: 15 },
  { value: 3056, label: "Sangatta Selatan", kab: 188, prop: 15 },
  { value: 3057, label: "Sangatta Utara", kab: 188, prop: 15 },
  { value: 3058, label: "Sangkulirang", kab: 188, prop: 15 },
  { value: 3059, label: "Telen", kab: 188, prop: 15 },
  { value: 3060, label: "Teluk Pandan", kab: 188, prop: 15 },
  { value: 4783, label: "Batu Engau", kab: 189, prop: 15 },
  { value: 4784, label: "Batu Sopang", kab: 189, prop: 15 },
  { value: 4785, label: "Kuaro", kab: 189, prop: 15 },
  { value: 4786, label: "Long Ikis", kab: 189, prop: 15 },
  { value: 4787, label: "Long Kali", kab: 189, prop: 15 },
  { value: 4788, label: "Muara Komam", kab: 189, prop: 15 },
  { value: 4789, label: "Muara Samu", kab: 189, prop: 15 },
  { value: 4790, label: "Pasir Belengkong", kab: 189, prop: 15 },
  { value: 4791, label: "Tanah Grogot", kab: 189, prop: 15 },
  { value: 4792, label: "Tanjung Harapan", kab: 189, prop: 15 },
  { value: 4960, label: "Babulu", kab: 190, prop: 15 },
  { value: 4961, label: "Penajam", kab: 190, prop: 15 },
  { value: 4962, label: "Sepaku", kab: 190, prop: 15 },
  { value: 4963, label: "Waru", kab: 190, prop: 15 },
  { value: 5356, label: "Loa Janan Ilir", kab: 191, prop: 15 },
  { value: 5357, label: "Palaran", kab: 191, prop: 15 },
  { value: 5358, label: "Samarinda Ilir", kab: 191, prop: 15 },
  { value: 5359, label: "Samarinda Kota", kab: 191, prop: 15 },
  { value: 5360, label: "Samarinda Seberang", kab: 191, prop: 15 },
  { value: 5361, label: "Samarinda Ulu", kab: 191, prop: 15 },
  { value: 5362, label: "Samarinda Utara", kab: 191, prop: 15 },
  { value: 5363, label: "Sambutan", kab: 191, prop: 15 },
  { value: 5364, label: "Sungai Kunjang", kab: 191, prop: 15 },
  { value: 5365, label: "Sungai Pinang", kab: 191, prop: 15 },
  { value: 1298, label: "Peso", kab: 192, prop: 16 },
  { value: 1299, label: "Peso Hilir/Ilir", kab: 192, prop: 16 },
  { value: 1300, label: "Pulau Bunyu", kab: 192, prop: 16 },
  { value: 1301, label: "Sekatak", kab: 192, prop: 16 },
  { value: 1302, label: "Tanjung Palas", kab: 192, prop: 16 },
  { value: 1303, label: "Tanjung Palas Barat", kab: 192, prop: 16 },
  { value: 1304, label: "Tanjung Palas Tengah", kab: 192, prop: 16 },
  { value: 1305, label: "Tanjung Palas Timur", kab: 192, prop: 16 },
  { value: 1306, label: "Tanjung Palas Utara", kab: 192, prop: 16 },
  { value: 1307, label: "Tanjung Selor", kab: 192, prop: 16 },
  { value: 3639, label: "Bahau Hulu", kab: 193, prop: 16 },
  { value: 3640, label: "Kayan Hilir", kab: 193, prop: 16 },
  { value: 3641, label: "Kayan Hulu", kab: 193, prop: 16 },
  { value: 3642, label: "Kayan Selatan", kab: 193, prop: 16 },
  { value: 3643, label: "Malinau Barat", kab: 193, prop: 16 },
  { value: 3644, label: "Malinau Kota", kab: 193, prop: 16 },
  { value: 3645, label: "Malinau Selatan", kab: 193, prop: 16 },
  { value: 3646, label: "Malinau Selatan Hilir", kab: 193, prop: 16 },
  { value: 3647, label: "Malinau Selatan Hulu", kab: 193, prop: 16 },
  { value: 3648, label: "Malinau Utara", kab: 193, prop: 16 },
  { value: 3649, label: "Mentarang", kab: 193, prop: 16 },
  { value: 3650, label: "Mentarang Hulu", kab: 193, prop: 16 },
  { value: 3651, label: "Pujungan", kab: 193, prop: 16 },
  { value: 3652, label: "Sungai Boh", kab: 193, prop: 16 },
  { value: 3653, label: "Sungai Tubu", kab: 193, prop: 16 },
  { value: 4422, label: "Krayan", kab: 194, prop: 16 },
  { value: 4423, label: "Krayan Selatan", kab: 194, prop: 16 },
  { value: 4424, label: "Lumbis", kab: 194, prop: 16 },
  { value: 4425, label: "Lumbis Ogong", kab: 194, prop: 16 },
  { value: 4426, label: "Nunukan", kab: 194, prop: 16 },
  { value: 4427, label: "Nunukan Selatan", kab: 194, prop: 16 },
  { value: 4428, label: "Sebatik", kab: 194, prop: 16 },
  { value: 4429, label: "Sebatik Barat", kab: 194, prop: 16 },
  { value: 4430, label: "Sebatik Tengah", kab: 194, prop: 16 },
  { value: 4431, label: "Sebatik Timur", kab: 194, prop: 16 },
  { value: 4432, label: "Sebatik Utara", kab: 194, prop: 16 },
  { value: 4433, label: "Sebuku", kab: 194, prop: 16 },
  { value: 4434, label: "Sei Menggaris", kab: 194, prop: 16 },
  { value: 4435, label: "Sembakung", kab: 194, prop: 16 },
  { value: 4436, label: "Tulin Onsoi", kab: 194, prop: 16 },
  { value: 6210, label: "Betayau", kab: 195, prop: 16 },
  { value: 6211, label: "Sesayap", kab: 195, prop: 16 },
  { value: 6212, label: "Sesayap Hilir", kab: 195, prop: 16 },
  { value: 6213, label: "Tana Lia (Tanah Lia)", kab: 195, prop: 16 },
  { value: 6432, label: "Tarakan Barat", kab: 196, prop: 16 },
  { value: 6433, label: "Tarakan Tengah", kab: 196, prop: 16 },
  { value: 6434, label: "Tarakan Timur", kab: 196, prop: 16 },
  { value: 6435, label: "Tarakan Utara", kab: 196, prop: 16 },
  { value: 673, label: "Batam Kota", kab: 197, prop: 17 },
  { value: 674, label: "Batu Aji", kab: 197, prop: 17 },
  { value: 675, label: "Batu Ampar", kab: 197, prop: 17 },
  { value: 676, label: "Belakang Padang", kab: 197, prop: 17 },
  { value: 677, label: "Bengkong", kab: 197, prop: 17 },
  { value: 678, label: "Bulang", kab: 197, prop: 17 },
  { value: 679, label: "Galang", kab: 197, prop: 17 },
  { value: 680, label: "Lubuk Baja", kab: 197, prop: 17 },
  { value: 681, label: "Nongsa", kab: 197, prop: 17 },
  { value: 682, label: "Sagulung", kab: 197, prop: 17 },
  { value: 683, label: "Sei/Sungai Beduk", kab: 197, prop: 17 },
  { value: 684, label: "Sekupang", kab: 197, prop: 17 },
  { value: 939, label: "Bintan Pesisir", kab: 198, prop: 17 },
  { value: 940, label: "Bintan Timur", kab: 198, prop: 17 },
  { value: 941, label: "Bintan Utara", kab: 198, prop: 17 },
  { value: 942, label: "Gunung Kijang", kab: 198, prop: 17 },
  { value: 943, label: "Mantang", kab: 198, prop: 17 },
  { value: 944, label: "Seri/Sri Kuala Lobam", kab: 198, prop: 17 },
  { value: 945, label: "Tambelan", kab: 198, prop: 17 },
  { value: 946, label: "Teluk Bintan", kab: 198, prop: 17 },
  { value: 947, label: "Teluk Sebong", kab: 198, prop: 17 },
  { value: 948, label: "Toapaya", kab: 198, prop: 17 },
  { value: 2408, label: "Belat", kab: 199, prop: 17 },
  { value: 2409, label: "Buru", kab: 199, prop: 17 },
  { value: 2410, label: "Durai", kab: 199, prop: 17 },
  { value: 2411, label: "Karimun", kab: 199, prop: 17 },
  { value: 2412, label: "Kundur", kab: 199, prop: 17 },
  { value: 2413, label: "Kundur Barat", kab: 199, prop: 17 },
  { value: 2414, label: "Kundur Utara", kab: 199, prop: 17 },
  { value: 2415, label: "Meral", kab: 199, prop: 17 },
  { value: 2416, label: "Meral Barat", kab: 199, prop: 17 },
  { value: 2417, label: "Moro", kab: 199, prop: 17 },
  { value: 2418, label: "Tebing", kab: 199, prop: 17 },
  { value: 2419, label: "Ungar", kab: 199, prop: 17 },
  { value: 2571, label: "Jemaja", kab: 200, prop: 17 },
  { value: 2572, label: "Jemaja Timur", kab: 200, prop: 17 },
  { value: 2573, label: "Palmatak", kab: 200, prop: 17 },
  { value: 2574, label: "Siantan", kab: 200, prop: 17 },
  { value: 2575, label: "Siantan Selatan", kab: 200, prop: 17 },
  { value: 2576, label: "Siantan Tengah", kab: 200, prop: 17 },
  { value: 2577, label: "Siantan Timur", kab: 200, prop: 17 },
  { value: 3364, label: "Lingga", kab: 201, prop: 17 },
  { value: 3365, label: "Lingga Timur", kab: 201, prop: 17 },
  { value: 3366, label: "Lingga Utara", kab: 201, prop: 17 },
  { value: 3367, label: "Selayar", kab: 201, prop: 17 },
  { value: 3368, label: "Senayang", kab: 201, prop: 17 },
  { value: 3369, label: "Singkep", kab: 201, prop: 17 },
  { value: 3370, label: "Singkep Barat", kab: 201, prop: 17 },
  { value: 3371, label: "Singkep Pesisir", kab: 201, prop: 17 },
  { value: 4267, label: "Bunguran Barat", kab: 202, prop: 17 },
  { value: 4268, label: "Bunguran Selatan", kab: 202, prop: 17 },
  { value: 4269, label: "Bunguran Tengah", kab: 202, prop: 17 },
  { value: 4270, label: "Bunguran Timur", kab: 202, prop: 17 },
  { value: 4271, label: "Bunguran Timur Laut", kab: 202, prop: 17 },
  { value: 4272, label: "Bunguran Utara", kab: 202, prop: 17 },
  { value: 4273, label: "Midai", kab: 202, prop: 17 },
  { value: 4274, label: "Pulau Laut", kab: 202, prop: 17 },
  { value: 4275, label: "Pulau Tiga", kab: 202, prop: 17 },
  { value: 4276, label: "Serasan", kab: 202, prop: 17 },
  { value: 4277, label: "Serasan Timur", kab: 202, prop: 17 },
  { value: 4278, label: "Subi", kab: 202, prop: 17 },
  { value: 6367, label: "Bukit Bestari", kab: 203, prop: 17 },
  { value: 6368, label: "Tanjung Pinang Barat", kab: 203, prop: 17 },
  { value: 6369, label: "Tanjung Pinang Kota", kab: 203, prop: 17 },
  { value: 6370, label: "Tanjung Pinang Timur", kab: 203, prop: 17 },
  { value: 287, label: "Bumi Waras", kab: 204, prop: 18 },
  { value: 288, label: "Enggal", kab: 204, prop: 18 },
  { value: 289, label: "Kedamaian", kab: 204, prop: 18 },
  { value: 290, label: "Kedaton", kab: 204, prop: 18 },
  { value: 291, label: "Kemiling", kab: 204, prop: 18 },
  { value: 292, label: "Labuhan Ratu", kab: 204, prop: 18 },
  { value: 293, label: "Langkapura", kab: 204, prop: 18 },
  { value: 294, label: "Panjang", kab: 204, prop: 18 },
  { value: 295, label: "Rajabasa", kab: 204, prop: 18 },
  { value: 296, label: "Sukabumi", kab: 204, prop: 18 },
  { value: 297, label: "Sukarame", kab: 204, prop: 18 },
  { value: 298, label: "Tanjung Karang Barat", kab: 204, prop: 18 },
  { value: 299, label: "Tanjung Karang Pusat", kab: 204, prop: 18 },
  { value: 300, label: "Tanjung Karang Timur", kab: 204, prop: 18 },
  { value: 301, label: "Tanjung Senang", kab: 204, prop: 18 },
  { value: 302, label: "Telukbetung Barat", kab: 204, prop: 18 },
  { value: 303, label: "Telukbetung Selatan", kab: 204, prop: 18 },
  { value: 304, label: "Telukbetung Timur", kab: 204, prop: 18 },
  { value: 305, label: "Telukbetung Utara", kab: 204, prop: 18 },
  { value: 306, label: "Way Halim", kab: 204, prop: 18 },
  { value: 3140, label: "Air Hitam", kab: 205, prop: 18 },
  { value: 3141, label: "Balik Bukit", kab: 205, prop: 18 },
  { value: 3142, label: "Bandar Negeri Suoh", kab: 205, prop: 18 },
  { value: 3143, label: "Batu Brak", kab: 205, prop: 18 },
  { value: 3144, label: "Batu Ketulis", kab: 205, prop: 18 },
  { value: 3145, label: "Belalau", kab: 205, prop: 18 },
  { value: 3146, label: "Gedung Surian", kab: 205, prop: 18 },
  { value: 3147, label: "Kebun Tebu", kab: 205, prop: 18 },
  { value: 3148, label: "Lumbok Seminung", kab: 205, prop: 18 },
  { value: 3149, label: "Pagar Dewa", kab: 205, prop: 18 },
  { value: 3150, label: "Sekincau", kab: 205, prop: 18 },
  { value: 3151, label: "Sukau", kab: 205, prop: 18 },
  { value: 3152, label: "Sumber Jaya", kab: 205, prop: 18 },
  { value: 3153, label: "Suoh", kab: 205, prop: 18 },
  { value: 3154, label: "Way Tenong", kab: 205, prop: 18 },
  { value: 3155, label: "Bakauheni", kab: 206, prop: 18 },
  { value: 3156, label: "Candipuro", kab: 206, prop: 18 },
  { value: 3157, label: "Jati Agung", kab: 206, prop: 18 },
  { value: 3158, label: "Kalianda", kab: 206, prop: 18 },
  { value: 3159, label: "Katibung", kab: 206, prop: 18 },
  { value: 3160, label: "Ketapang", kab: 206, prop: 18 },
  { value: 3161, label: "Merbau Mataram", kab: 206, prop: 18 },
  { value: 3162, label: "Natar", kab: 206, prop: 18 },
  { value: 3163, label: "Palas", kab: 206, prop: 18 },
  { value: 3164, label: "Penengahan", kab: 206, prop: 18 },
  { value: 3165, label: "Rajabasa", kab: 206, prop: 18 },
  { value: 3166, label: "Sidomulyo", kab: 206, prop: 18 },
  { value: 3167, label: "Sragi", kab: 206, prop: 18 },
  { value: 3168, label: "Tanjung Bintang", kab: 206, prop: 18 },
  { value: 3169, label: "Tanjung Sari", kab: 206, prop: 18 },
  { value: 3170, label: "Way Panji", kab: 206, prop: 18 },
  { value: 3171, label: "Way Sulan", kab: 206, prop: 18 },
  { value: 3172, label: "Anak Ratu Aji", kab: 207, prop: 18 },
  { value: 3173, label: "Anak Tuha", kab: 207, prop: 18 },
  { value: 3174, label: "Bandar Mataram", kab: 207, prop: 18 },
  { value: 3175, label: "Bandar Surabaya", kab: 207, prop: 18 },
  { value: 3176, label: "Bangunrejo", kab: 207, prop: 18 },
  { value: 3177, label: "Bekri", kab: 207, prop: 18 },
  { value: 3178, label: "Bumi Nabung", kab: 207, prop: 18 },
  { value: 3179, label: "Bumi Ratu Nuban", kab: 207, prop: 18 },
  { value: 3180, label: "Gunung Sugih", kab: 207, prop: 18 },
  { value: 3181, label: "Kalirejo", kab: 207, prop: 18 },
  { value: 3182, label: "Kota Gajah", kab: 207, prop: 18 },
  { value: 3183, label: "Padang Ratu", kab: 207, prop: 18 },
  { value: 3184, label: "Pubian", kab: 207, prop: 18 },
  { value: 3185, label: "Punggur", kab: 207, prop: 18 },
  { value: 3186, label: "Putra Rumbia", kab: 207, prop: 18 },
  { value: 3187, label: "Rumbia", kab: 207, prop: 18 },
  { value: 3188, label: "Selagai Lingga", kab: 207, prop: 18 },
  { value: 3189, label: "Sendang Agung", kab: 207, prop: 18 },
  { value: 3190, label: "Seputih Agung", kab: 207, prop: 18 },
  { value: 3191, label: "Seputih Banyak", kab: 207, prop: 18 },
  { value: 3192, label: "Seputih Mataram", kab: 207, prop: 18 },
  { value: 3193, label: "Seputih Raman", kab: 207, prop: 18 },
  { value: 3194, label: "Seputih Surabaya", kab: 207, prop: 18 },
  { value: 3195, label: "Terbanggi Besar", kab: 207, prop: 18 },
  { value: 3196, label: "Terusan Nunyai", kab: 207, prop: 18 },
  { value: 3197, label: "Trimurjo", kab: 207, prop: 18 },
  { value: 3198, label: "Way Pangubuan (Pengubuan)", kab: 207, prop: 18 },
  { value: 3199, label: "Way Seputih", kab: 207, prop: 18 },
  { value: 3200, label: "Bandar Sribawono", kab: 208, prop: 18 },
  { value: 3201, label: "Batanghari", kab: 208, prop: 18 },
  { value: 3202, label: "Batanghari Nuban", kab: 208, prop: 18 },
  { value: 3203, label: "Braja Slebah", kab: 208, prop: 18 },
  { value: 3204, label: "Bumi Agung", kab: 208, prop: 18 },
  { value: 3205, label: "Gunung Pelindung", kab: 208, prop: 18 },
  { value: 3206, label: "Jabung", kab: 208, prop: 18 },
  { value: 3207, label: "Labuhan Maringgai", kab: 208, prop: 18 },
  { value: 3208, label: "Labuhan Ratu", kab: 208, prop: 18 },
  { value: 3209, label: "Marga Sekampung", kab: 208, prop: 18 },
  { value: 3210, label: "Margatiga", kab: 208, prop: 18 },
  { value: 3211, label: "Mataram Baru", kab: 208, prop: 18 },
  { value: 3212, label: "Melinting", kab: 208, prop: 18 },
  { value: 3213, label: "Metro Kibang", kab: 208, prop: 18 },
  { value: 3214, label: "Pasir Sakti", kab: 208, prop: 18 },
  { value: 3215, label: "Pekalongan", kab: 208, prop: 18 },
  { value: 3216, label: "Purbolinggo", kab: 208, prop: 18 },
  { value: 3217, label: "Raman Utara", kab: 208, prop: 18 },
  { value: 3218, label: "Sekampung", kab: 208, prop: 18 },
  { value: 3219, label: "Sekampung Udik", kab: 208, prop: 18 },
  { value: 3220, label: "Sukadana", kab: 208, prop: 18 },
  { value: 3221, label: "Waway Karya", kab: 208, prop: 18 },
  { value: 3222, label: "Way Bungur (Purbolinggo Utara)", kab: 208, prop: 18 },
  { value: 3223, label: "Way Jepara", kab: 208, prop: 18 },
  { value: 3224, label: "Abung Barat", kab: 209, prop: 18 },
  { value: 3225, label: "Abung Kunang", kab: 209, prop: 18 },
  { value: 3226, label: "Abung Pekurun", kab: 209, prop: 18 },
  { value: 3227, label: "Abung Selatan", kab: 209, prop: 18 },
  { value: 3228, label: "Abung Semuli", kab: 209, prop: 18 },
  { value: 3229, label: "Abung Surakarta", kab: 209, prop: 18 },
  { value: 3230, label: "Abung Tengah", kab: 209, prop: 18 },
  { value: 3231, label: "Abung Timur", kab: 209, prop: 18 },
  { value: 3232, label: "Abung Tinggi", kab: 209, prop: 18 },
  { value: 3233, label: "Blambangan Pagar", kab: 209, prop: 18 },
  { value: 3234, label: "Bukit Kemuning", kab: 209, prop: 18 },
  { value: 3235, label: "Bunga Mayang", kab: 209, prop: 18 },
  { value: 3236, label: "Hulu Sungkai", kab: 209, prop: 18 },
  { value: 3237, label: "Kotabumi", kab: 209, prop: 18 },
  { value: 3238, label: "Kotabumi Selatan", kab: 209, prop: 18 },
  { value: 3239, label: "Kotabumi Utara", kab: 209, prop: 18 },
  { value: 3240, label: "Muara Sungkai", kab: 209, prop: 18 },
  { value: 3241, label: "Sungkai Barat", kab: 209, prop: 18 },
  { value: 3242, label: "Sungkai Jaya", kab: 209, prop: 18 },
  { value: 3243, label: "Sungkai Selatan", kab: 209, prop: 18 },
  { value: 3244, label: "Sungkai Tengah", kab: 209, prop: 18 },
  { value: 3245, label: "Sungkai Utara", kab: 209, prop: 18 },
  { value: 3246, label: "Tanjung Raja", kab: 209, prop: 18 },
  { value: 3982, label: "Mesuji", kab: 210, prop: 18 },
  { value: 3983, label: "Mesuji Timur", kab: 210, prop: 18 },
  { value: 3984, label: "Panca Jaya", kab: 210, prop: 18 },
  { value: 3985, label: "Rawa Jitu Utara", kab: 210, prop: 18 },
  { value: 3986, label: "Simpang Pematang", kab: 210, prop: 18 },
  { value: 3987, label: "Tanjung Raya", kab: 210, prop: 18 },
  { value: 3988, label: "Way Serdang", kab: 210, prop: 18 },
  { value: 3989, label: "Metro Barat", kab: 211, prop: 18 },
  { value: 3990, label: "Metro Pusat", kab: 211, prop: 18 },
  { value: 3991, label: "Metro Selatan", kab: 211, prop: 18 },
  { value: 3992, label: "Metro Timur", kab: 211, prop: 18 },
  { value: 3993, label: "Metro Utara", kab: 211, prop: 18 },
  { value: 4964, label: "Gedong Tataan (Gedung Tataan)", kab: 212, prop: 18 },
  { value: 4965, label: "Kedondong", kab: 212, prop: 18 },
  { value: 4966, label: "Marga Punduh", kab: 212, prop: 18 },
  { value: 4967, label: "Negeri Katon", kab: 212, prop: 18 },
  { value: 4968, label: "Padang Cermin", kab: 212, prop: 18 },
  { value: 4969, label: "Punduh Pidada (Pedada)", kab: 212, prop: 18 },
  { value: 4970, label: "Tegineneng", kab: 212, prop: 18 },
  { value: 4971, label: "Way Khilau", kab: 212, prop: 18 },
  { value: 4972, label: "Way Lima", kab: 212, prop: 18 },
  { value: 4973, label: "Bengkunat", kab: 213, prop: 18 },
  { value: 4974, label: "Bengkunat Belimbing", kab: 213, prop: 18 },
  { value: 4975, label: "Karya Penggawa", kab: 213, prop: 18 },
  { value: 4976, label: "Krui Selatan", kab: 213, prop: 18 },
  { value: 4977, label: "Lemong", kab: 213, prop: 18 },
  { value: 4978, label: "Ngambur", kab: 213, prop: 18 },
  { value: 4979, label: "Pesisir Selatan", kab: 213, prop: 18 },
  { value: 4980, label: "Pesisir Tengah", kab: 213, prop: 18 },
  { value: 4981, label: "Pesisir Utara", kab: 213, prop: 18 },
  { value: 4982, label: "Pulau Pisang", kab: 213, prop: 18 },
  { value: 4983, label: "Way Krui", kab: 213, prop: 18 },
  { value: 5132, label: "Adi Luwih", kab: 214, prop: 18 },
  { value: 5133, label: "Ambarawa", kab: 214, prop: 18 },
  { value: 5134, label: "Banyumas", kab: 214, prop: 18 },
  { value: 5135, label: "Gading Rejo", kab: 214, prop: 18 },
  { value: 5136, label: "Pagelaran", kab: 214, prop: 18 },
  { value: 5137, label: "Pagelaran Utara", kab: 214, prop: 18 },
  { value: 5138, label: "Pardasuka", kab: 214, prop: 18 },
  { value: 5139, label: "Pringsewu", kab: 214, prop: 18 },
  { value: 5140, label: "Sukoharjo", kab: 214, prop: 18 },
  { value: 6317, label: "Air Naningan", kab: 215, prop: 18 },
  { value: 6318, label: "Bandar Negeri Semuong", kab: 215, prop: 18 },
  { value: 6319, label: "Bulok", kab: 215, prop: 18 },
  { value: 6320, label: "Cukuh Balak", kab: 215, prop: 18 },
  { value: 6321, label: "Gisting", kab: 215, prop: 18 },
  { value: 6322, label: "Gunung Alip", kab: 215, prop: 18 },
  { value: 6323, label: "Kelumbayan", kab: 215, prop: 18 },
  { value: 6324, label: "Kelumbayan Barat", kab: 215, prop: 18 },
  { value: 6325, label: "Kota Agung (Kota Agung Pusat)", kab: 215, prop: 18 },
  { value: 6326, label: "Kota Agung Barat", kab: 215, prop: 18 },
  { value: 6327, label: "Kota Agung Timur", kab: 215, prop: 18 },
  { value: 6328, label: "Limau", kab: 215, prop: 18 },
  { value: 6329, label: "Pematang Sawa", kab: 215, prop: 18 },
  { value: 6330, label: "Pugung", kab: 215, prop: 18 },
  { value: 6331, label: "Pulau Panggung", kab: 215, prop: 18 },
  { value: 6332, label: "Semaka", kab: 215, prop: 18 },
  { value: 6333, label: "Sumberejo", kab: 215, prop: 18 },
  { value: 6334, label: "Talang Padang", kab: 215, prop: 18 },
  { value: 6335, label: "Ulubelu", kab: 215, prop: 18 },
  { value: 6336, label: "Wonosobo", kab: 215, prop: 18 },
  { value: 6798, label: "Banjar Agung", kab: 216, prop: 18 },
  { value: 6799, label: "Banjar Baru", kab: 216, prop: 18 },
  { value: 6800, label: "Banjar Margo", kab: 216, prop: 18 },
  { value: 6801, label: "Dente Teladas", kab: 216, prop: 18 },
  { value: 6802, label: "Gedung Aji", kab: 216, prop: 18 },
  { value: 6803, label: "Gedung Aji Baru", kab: 216, prop: 18 },
  { value: 6804, label: "Gedung Meneng", kab: 216, prop: 18 },
  { value: 6805, label: "Menggala", kab: 216, prop: 18 },
  { value: 6806, label: "Menggala Timur", kab: 216, prop: 18 },
  { value: 6807, label: "Meraksa Aji", kab: 216, prop: 18 },
  { value: 6808, label: "Penawar Aji", kab: 216, prop: 18 },
  { value: 6809, label: "Penawar Tama", kab: 216, prop: 18 },
  { value: 6810, label: "Rawa Pitu", kab: 216, prop: 18 },
  { value: 6811, label: "Rawajitu Selatan", kab: 216, prop: 18 },
  { value: 6812, label: "Rawajitu Timur", kab: 216, prop: 18 },
  { value: 6813, label: "Gunung Agung", kab: 217, prop: 18 },
  { value: 6814, label: "Gunung Terang", kab: 217, prop: 18 },
  { value: 6815, label: "Lambu Kibang", kab: 217, prop: 18 },
  { value: 6816, label: "Pagar Dewa", kab: 217, prop: 18 },
  { value: 6817, label: "Tulang Bawang Tengah", kab: 217, prop: 18 },
  { value: 6818, label: "Tulang Bawang Udik", kab: 217, prop: 18 },
  { value: 6819, label: "Tumijajar", kab: 217, prop: 18 },
  { value: 6820, label: "Way Kenanga", kab: 217, prop: 18 },
  { value: 6871, label: "Bahuga", kab: 218, prop: 18 },
  { value: 6872, label: "Banjit", kab: 218, prop: 18 },
  { value: 6873, label: "Baradatu", kab: 218, prop: 18 },
  { value: 6874, label: "Blambangan Umpu", kab: 218, prop: 18 },
  { value: 6875, label: "Buay Bahuga", kab: 218, prop: 18 },
  { value: 6876, label: "Bumi Agung", kab: 218, prop: 18 },
  { value: 6877, label: "Gunung Labuhan", kab: 218, prop: 18 },
  { value: 6878, label: "Kasui", kab: 218, prop: 18 },
  { value: 6879, label: "Negara Batin", kab: 218, prop: 18 },
  { value: 6880, label: "Negeri Agung", kab: 218, prop: 18 },
  { value: 6881, label: "Negeri Besar", kab: 218, prop: 18 },
  { value: 6882, label: "Pakuan Ratu", kab: 218, prop: 18 },
  { value: 6883, label: "Rebang Tangkas", kab: 218, prop: 18 },
  { value: 6884, label: "Way Tuba", kab: 218, prop: 18 },
  { value: 209, label: "Baguala", kab: 219, prop: 19 },
  { value: 210, label: "Leitimur Selatan", kab: 219, prop: 19 },
  { value: 211, label: "Nusaniwe (Nusanive)", kab: 219, prop: 19 },
  { value: 212, label: "Sirimau", kab: 219, prop: 19 },
  { value: 213, label: "Teluk Ambon", kab: 219, prop: 19 },
  { value: 1336, label: "Airbuaya", kab: 220, prop: 19 },
  { value: 1337, label: "Batabual", kab: 220, prop: 19 },
  { value: 1338, label: "Fena Leisela", kab: 220, prop: 19 },
  { value: 1339, label: "Lilialy", kab: 220, prop: 19 },
  { value: 1340, label: "Lolong Guba", kab: 220, prop: 19 },
  { value: 1341, label: "Namlea", kab: 220, prop: 19 },
  { value: 1342, label: "Teluk Kaiely", kab: 220, prop: 19 },
  { value: 1343, label: "Waeapo", kab: 220, prop: 19 },
  { value: 1344, label: "Waelata", kab: 220, prop: 19 },
  { value: 1345, label: "Waplau", kab: 220, prop: 19 },
  { value: 1346, label: "Ambalau", kab: 221, prop: 19 },
  { value: 1347, label: "Fena Fafan", kab: 221, prop: 19 },
  { value: 1348, label: "Kepala Madan", kab: 221, prop: 19 },
  { value: 1349, label: "Leksula", kab: 221, prop: 19 },
  { value: 1350, label: "Namrole", kab: 221, prop: 19 },
  { value: 1351, label: "Waesama", kab: 221, prop: 19 },
  { value: 2578, label: "Aru Selatan", kab: 222, prop: 19 },
  { value: 2579, label: "Aru Selatan Timur", kab: 222, prop: 19 },
  { value: 2580, label: "Aru Selatan Utara", kab: 222, prop: 19 },
  { value: 2581, label: "Aru Tengah", kab: 222, prop: 19 },
  { value: 2582, label: "Aru Tengah Selatan", kab: 222, prop: 19 },
  { value: 2583, label: "Aru Tengah Timur", kab: 222, prop: 19 },
  { value: 2584, label: "Aru Utara", kab: 222, prop: 19 },
  { value: 2585, label: "Aru Utara Timur Batuley", kab: 222, prop: 19 },
  { value: 2586, label: "Pulau-Pulau Aru", kab: 222, prop: 19 },
  { value: 2587, label: "Sir-Sir", kab: 222, prop: 19 },
  { value: 3654, label: "Damer", kab: 223, prop: 19 },
  { value: 3655, label: "Dawelor Dawera", kab: 223, prop: 19 },
  { value: 3656, label: "Kepulauan Romang", kab: 223, prop: 19 },
  { value: 3657, label: "Kisar Utara", kab: 223, prop: 19 },
  { value: 3658, label: "Mdona Hyera/Hiera", kab: 223, prop: 19 },
  { value: 3659, label: "Moa Lakor", kab: 223, prop: 19 },
  { value: 3660, label: "Pulau Lakor", kab: 223, prop: 19 },
  { value: 3661, label: "Pulau Letti (Leti Moa Lakor)", kab: 223, prop: 19 },
  { value: 3662, label: "Pulau Masela", kab: 223, prop: 19 },
  { value: 3663, label: "Pulau Pulau Babar", kab: 223, prop: 19 },
  { value: 3664, label: "Pulau Pulau Terselatan", kab: 223, prop: 19 },
  { value: 3665, label: "Pulau Wetang", kab: 223, prop: 19 },
  { value: 3666, label: "Pulau-Pulau Babar Timur", kab: 223, prop: 19 },
  { value: 3667, label: "Wetar", kab: 223, prop: 19 },
  { value: 3668, label: "Wetar Barat", kab: 223, prop: 19 },
  { value: 3669, label: "Wetar Timur", kab: 223, prop: 19 },
  { value: 3670, label: "Wetar Utara", kab: 223, prop: 19 },
  { value: 3671, label: "Amahai", kab: 224, prop: 19 },
  { value: 3672, label: "Banda", kab: 224, prop: 19 },
  { value: 3673, label: "Leihitu", kab: 224, prop: 19 },
  { value: 3674, label: "Leihitu Barat", kab: 224, prop: 19 },
  { value: 3675, label: "Masohi Kota", kab: 224, prop: 19 },
  { value: 3676, label: "Nusalaut", kab: 224, prop: 19 },
  { value: 3677, label: "Pulau Haruku", kab: 224, prop: 19 },
  { value: 3678, label: "Salahutu", kab: 224, prop: 19 },
  { value: 3679, label: "Saparua", kab: 224, prop: 19 },
  { value: 3680, label: "Saparua Timur", kab: 224, prop: 19 },
  { value: 3681, label: "Seram Utara", kab: 224, prop: 19 },
  { value: 3682, label: "Seram Utara Barat", kab: 224, prop: 19 },
  { value: 3683, label: "Seram Utara Timur Kobi", kab: 224, prop: 19 },
  { value: 3684, label: "Seram Utara Timur Seti", kab: 224, prop: 19 },
  { value: 3685, label: "Tehoru", kab: 224, prop: 19 },
  { value: 3686, label: "Teluk Elpaputih", kab: 224, prop: 19 },
  { value: 3687, label: "Telutih", kab: 224, prop: 19 },
  { value: 3688, label: "Teon Nila Serua", kab: 224, prop: 19 },
  { value: 3689, label: "Hoat Sorbay", kab: 225, prop: 19 },
  { value: 3690, label: "Kei Besar", kab: 225, prop: 19 },
  { value: 3691, label: "Kei Besar Selatan", kab: 225, prop: 19 },
  { value: 3692, label: "Kei Besar Selatan Barat", kab: 225, prop: 19 },
  { value: 3693, label: "Kei Besar Utara Barat", kab: 225, prop: 19 },
  { value: 3694, label: "Kei Besar Utara Timur", kab: 225, prop: 19 },
  { value: 3695, label: "Kei Kecil", kab: 225, prop: 19 },
  { value: 3696, label: "Kei Kecil Barat", kab: 225, prop: 19 },
  { value: 3697, label: "Kei Kecil Timur", kab: 225, prop: 19 },
  { value: 3698, label: "Kei Kecil Timur Selatan", kab: 225, prop: 19 },
  { value: 3699, label: "Manyeuw", kab: 225, prop: 19 },
  { value: 3700, label: "Kormomolin", kab: 226, prop: 19 },
  { value: 3701, label: "Molu Maru", kab: 226, prop: 19 },
  { value: 3702, label: "Nirunmas", kab: 226, prop: 19 },
  { value: 3703, label: "Selaru", kab: 226, prop: 19 },
  { value: 3704, label: "Tanimbar Selatan", kab: 226, prop: 19 },
  { value: 3705, label: "Tanimbar Utara", kab: 226, prop: 19 },
  { value: 3706, label: "Wermakatian (Wer Maktian)", kab: 226, prop: 19 },
  { value: 3707, label: "Wertamrian", kab: 226, prop: 19 },
  { value: 3708, label: "Wuarlabobar", kab: 226, prop: 19 },
  { value: 3709, label: "Yaru", kab: 226, prop: 19 },
  { value: 5514, label: "Amalatu", kab: 227, prop: 19 },
  { value: 5515, label: "Elpaputih", kab: 227, prop: 19 },
  { value: 5516, label: "Huamual", kab: 227, prop: 19 },
  { value: 5517, label: "Huamual Belakang (Waisala)", kab: 227, prop: 19 },
  { value: 5518, label: "Inamosol", kab: 227, prop: 19 },
  { value: 5519, label: "Kairatu", kab: 227, prop: 19 },
  { value: 5520, label: "Kairatu Barat", kab: 227, prop: 19 },
  { value: 5521, label: "Kepulauan Manipa", kab: 227, prop: 19 },
  { value: 5522, label: "Seram Barat", kab: 227, prop: 19 },
  { value: 5523, label: "Taniwel", kab: 227, prop: 19 },
  { value: 5524, label: "Taniwel Timur", kab: 227, prop: 19 },
  { value: 5525, label: "Bula", kab: 228, prop: 19 },
  { value: 5526, label: "Bula Barat", kab: 228, prop: 19 },
  { value: 5527, label: "Gorom Timur", kab: 228, prop: 19 },
  { value: 5528, label: "Kian Darat", kab: 228, prop: 19 },
  { value: 5529, label: "Kilmury", kab: 228, prop: 19 },
  { value: 5530, label: "Pulau Gorong (Gorom)", kab: 228, prop: 19 },
  { value: 5531, label: "Pulau Panjang", kab: 228, prop: 19 },
  { value: 5532, label: "Seram Timur", kab: 228, prop: 19 },
  { value: 5533, label: "Siritaun Wida Timur", kab: 228, prop: 19 },
  { value: 5534, label: "Siwalalat", kab: 228, prop: 19 },
  { value: 5535, label: "Teluk Waru", kab: 228, prop: 19 },
  { value: 5536, label: "Teor", kab: 228, prop: 19 },
  { value: 5537, label: "Tutuk Tolu", kab: 228, prop: 19 },
  { value: 5538, label: "Wakate", kab: 228, prop: 19 },
  { value: 5539, label: "Werinama", kab: 228, prop: 19 },
  { value: 6773, label: "Kur Selatan", kab: 229, prop: 19 },
  { value: 6774, label: "Pulau Dullah Selatan", kab: 229, prop: 19 },
  { value: 6775, label: "Pulau Dullah Utara", kab: 229, prop: 19 },
  { value: 6776, label: "Pulau Tayando Tam", kab: 229, prop: 19 },
  { value: 6777, label: "Pulau-Pulau Kur", kab: 229, prop: 19 },
  { value: 1901, label: "Ibu", kab: 230, prop: 20 },
  { value: 1902, label: "Ibu Selatan", kab: 230, prop: 20 },
  { value: 1903, label: "Ibu Utara", kab: 230, prop: 20 },
  { value: 1904, label: "Jailolo", kab: 230, prop: 20 },
  { value: 1905, label: "Jailolo Selatan", kab: 230, prop: 20 },
  { value: 1906, label: "Loloda", kab: 230, prop: 20 },
  { value: 1907, label: "Sahu", kab: 230, prop: 20 },
  { value: 1908, label: "Sahu Timur", kab: 230, prop: 20 },
  { value: 1909, label: "Bacan", kab: 231, prop: 20 },
  { value: 1910, label: "Bacan Barat", kab: 231, prop: 20 },
  { value: 1911, label: "Bacan Barat Utara", kab: 231, prop: 20 },
  { value: 1912, label: "Bacan Selatan", kab: 231, prop: 20 },
  { value: 1913, label: "Bacan Timur", kab: 231, prop: 20 },
  { value: 1914, label: "Bacan Timur Selatan", kab: 231, prop: 20 },
  { value: 1915, label: "Bacan Timur Tengah", kab: 231, prop: 20 },
  { value: 1916, label: "Gane Barat", kab: 231, prop: 20 },
  { value: 1917, label: "Gane Barat Selatan", kab: 231, prop: 20 },
  { value: 1918, label: "Gane Barat Utara", kab: 231, prop: 20 },
  { value: 1919, label: "Gane Timur", kab: 231, prop: 20 },
  { value: 1920, label: "Gane Timur Selatan", kab: 231, prop: 20 },
  { value: 1921, label: "Gane Timur Tengah", kab: 231, prop: 20 },
  { value: 1922, label: "Kasiruta Barat", kab: 231, prop: 20 },
  { value: 1923, label: "Kasiruta Timur", kab: 231, prop: 20 },
  { value: 1924, label: "Kayoa", kab: 231, prop: 20 },
  { value: 1925, label: "Kayoa Barat", kab: 231, prop: 20 },
  { value: 1926, label: "Kayoa Selatan", kab: 231, prop: 20 },
  { value: 1927, label: "Kayoa Utara", kab: 231, prop: 20 },
  { value: 1928, label: "Kepulauan Botanglomang", kab: 231, prop: 20 },
  { value: 1929, label: "Kepulauan Joronga", kab: 231, prop: 20 },
  { value: 1930, label: "Makian (Pulau Makian)", kab: 231, prop: 20 },
  { value: 1931, label: "Makian Barat (Pulau Makian)", kab: 231, prop: 20 },
  { value: 1932, label: "Mandioli Selatan", kab: 231, prop: 20 },
  { value: 1933, label: "Mandioli Utara", kab: 231, prop: 20 },
  { value: 1934, label: "Obi", kab: 231, prop: 20 },
  { value: 1935, label: "Obi Barat", kab: 231, prop: 20 },
  { value: 1936, label: "Obi Selatan", kab: 231, prop: 20 },
  { value: 1937, label: "Obi Timur", kab: 231, prop: 20 },
  { value: 1938, label: "Obi Utara", kab: 231, prop: 20 },
  { value: 1939, label: "Patani", kab: 232, prop: 20 },
  { value: 1940, label: "Patani Barat", kab: 232, prop: 20 },
  { value: 1941, label: "Patani Utara", kab: 232, prop: 20 },
  { value: 1942, label: "Pulau Gebe", kab: 232, prop: 20 },
  { value: 1943, label: "Weda", kab: 232, prop: 20 },
  { value: 1944, label: "Weda Selatan", kab: 232, prop: 20 },
  { value: 1945, label: "Weda Tengah", kab: 232, prop: 20 },
  { value: 1946, label: "Weda Utara", kab: 232, prop: 20 },
  { value: 1947, label: "Kota Maba", kab: 233, prop: 20 },
  { value: 1948, label: "Maba", kab: 233, prop: 20 },
  { value: 1949, label: "Maba Selatan", kab: 233, prop: 20 },
  { value: 1950, label: "Maba Tengah", kab: 233, prop: 20 },
  { value: 1951, label: "Maba Utara", kab: 233, prop: 20 },
  { value: 1952, label: "Wasile", kab: 233, prop: 20 },
  { value: 1953, label: "Wasile Selatan", kab: 233, prop: 20 },
  { value: 1954, label: "Wasile Tengah", kab: 233, prop: 20 },
  { value: 1955, label: "Wasile Timur", kab: 233, prop: 20 },
  { value: 1956, label: "Wasile Utara", kab: 233, prop: 20 },
  { value: 1957, label: "Galela", kab: 234, prop: 20 },
  { value: 1958, label: "Galela Barat", kab: 234, prop: 20 },
  { value: 1959, label: "Galela Selatan", kab: 234, prop: 20 },
  { value: 1960, label: "Galela Utara", kab: 234, prop: 20 },
  { value: 1961, label: "Kao", kab: 234, prop: 20 },
  { value: 1962, label: "Kao Barat", kab: 234, prop: 20 },
  { value: 1963, label: "Kao Teluk", kab: 234, prop: 20 },
  { value: 1964, label: "Kao Utara", kab: 234, prop: 20 },
  { value: 1965, label: "Loloda Kepulauan", kab: 234, prop: 20 },
  { value: 1966, label: "Loloda Utara", kab: 234, prop: 20 },
  { value: 1967, label: "Malifut", kab: 234, prop: 20 },
  { value: 1968, label: "Tobelo", kab: 234, prop: 20 },
  { value: 1969, label: "Tobelo Barat", kab: 234, prop: 20 },
  { value: 1970, label: "Tobelo Selatan", kab: 234, prop: 20 },
  { value: 1971, label: "Tobelo Tengah", kab: 234, prop: 20 },
  { value: 1972, label: "Tobelo Timur", kab: 234, prop: 20 },
  { value: 1973, label: "Tobelo Utara", kab: 234, prop: 20 },
  { value: 2634, label: "Lede", kab: 235, prop: 20 },
  { value: 2635, label: "Mangoli Barat", kab: 235, prop: 20 },
  { value: 2636, label: "Mangoli Selatan", kab: 235, prop: 20 },
  { value: 2637, label: "Mangoli Tengah", kab: 235, prop: 20 },
  { value: 2638, label: "Mangoli Timur", kab: 235, prop: 20 },
  { value: 2639, label: "Mangoli Utara", kab: 235, prop: 20 },
  { value: 2640, label: "Mangoli Utara Timur", kab: 235, prop: 20 },
  { value: 2641, label: "Sanana", kab: 235, prop: 20 },
  { value: 2642, label: "Sanana Utara", kab: 235, prop: 20 },
  { value: 2643, label: "Sulabesi Barat", kab: 235, prop: 20 },
  { value: 2644, label: "Sulabesi Selatan", kab: 235, prop: 20 },
  { value: 2645, label: "Sulabesi Tengah", kab: 235, prop: 20 },
  { value: 2646, label: "Sulabesi Timur", kab: 235, prop: 20 },
  { value: 2647, label: "Taliabu Barat", kab: 235, prop: 20 },
  { value: 2648, label: "Taliabu Barat Laut", kab: 235, prop: 20 },
  { value: 2649, label: "Taliabu Selatan", kab: 235, prop: 20 },
  { value: 2650, label: "Taliabu Timur", kab: 235, prop: 20 },
  { value: 2651, label: "Taliabu Timur Selatan", kab: 235, prop: 20 },
  { value: 2652, label: "Taliabu Utara", kab: 235, prop: 20 },
  { value: 5178, label: "Morotai Jaya", kab: 236, prop: 20 },
  { value: 5179, label: "Morotai Selatan", kab: 236, prop: 20 },
  { value: 5180, label: "Morotai Selatan Barat", kab: 236, prop: 20 },
  { value: 5181, label: "Morotai Timur", kab: 236, prop: 20 },
  { value: 5182, label: "Morotai Utara", kab: 236, prop: 20 },
  { value: 6581, label: "Moti (Pulau Moti)", kab: 237, prop: 20 },
  { value: 6582, label: "Pulau Batang Dua", kab: 237, prop: 20 },
  { value: 6583, label: "Pulau Hiri", kab: 237, prop: 20 },
  { value: 6584, label: "Pulau Ternate", kab: 237, prop: 20 },
  { value: 6585, label: "Ternate Selatan (Kota)", kab: 237, prop: 20 },
  { value: 6586, label: "Ternate Tengah (Kota)", kab: 237, prop: 20 },
  { value: 6587, label: "Ternate Utara (Kota)", kab: 237, prop: 20 },
  { value: 6588, label: "Oba", kab: 238, prop: 20 },
  { value: 6589, label: "Oba Selatan", kab: 238, prop: 20 },
  { value: 6590, label: "Oba Tengah", kab: 238, prop: 20 },
  { value: 6591, label: "Oba Utara", kab: 238, prop: 20 },
  { value: 6592, label: "Tidore (Pulau Tidore)", kab: 238, prop: 20 },
  { value: 6593, label: "Tidore Selatan", kab: 238, prop: 20 },
  { value: 6594, label: "Tidore Timur (Pulau Tidore)", kab: 238, prop: 20 },
  { value: 6595, label: "Tidore Utara", kab: 238, prop: 20 },
  { value: 1, label: "Arongan Lambalek", kab: 239, prop: 21 },
  { value: 2, label: "Bubon", kab: 239, prop: 21 },
  { value: 3, label: "Johan Pahlawan", kab: 239, prop: 21 },
  { value: 4, label: "Kaway XVI", kab: 239, prop: 21 },
  { value: 5, label: "Meureubo", kab: 239, prop: 21 },
  { value: 6, label: "Pante Ceureumen (Pantai Ceuremen)", kab: 239, prop: 21 },
  { value: 7, label: "Panton Reu", kab: 239, prop: 21 },
  { value: 8, label: "Samatiga", kab: 239, prop: 21 },
  { value: 9, label: "Sungai Mas", kab: 239, prop: 21 },
  { value: 10, label: "Woyla", kab: 239, prop: 21 },
  { value: 11, label: "Woyla Barat", kab: 239, prop: 21 },
  { value: 12, label: "Woyla Timur", kab: 239, prop: 21 },
  { value: 13, label: "Babah Rot", kab: 240, prop: 21 },
  { value: 14, label: "Blang Pidie", kab: 240, prop: 21 },
  { value: 15, label: "Jeumpa", kab: 240, prop: 21 },
  { value: 16, label: "Kuala Batee", kab: 240, prop: 21 },
  { value: 17, label: "Lembah Sabil", kab: 240, prop: 21 },
  { value: 18, label: "Manggeng", kab: 240, prop: 21 },
  { value: 19, label: "Setia", kab: 240, prop: 21 },
  { value: 20, label: "Susoh", kab: 240, prop: 21 },
  { value: 21, label: "Tangan-Tangan", kab: 240, prop: 21 },
  { value: 22, label: "Baitussalam", kab: 241, prop: 21 },
  { value: 23, label: "Blank Bintang", kab: 241, prop: 21 },
  { value: 24, label: "Darul Imarah", kab: 241, prop: 21 },
  { value: 25, label: "Darul Kamal", kab: 241, prop: 21 },
  { value: 26, label: "Darussalam", kab: 241, prop: 21 },
  { value: 27, label: "Indrapuri", kab: 241, prop: 21 },
  { value: 28, label: "Ingin Jaya", kab: 241, prop: 21 },
  { value: 29, label: "Kota Cot Glie (Kuta Cot Glie)", kab: 241, prop: 21 },
  { value: 30, label: "Kota Jantho", kab: 241, prop: 21 },
  { value: 31, label: "Kota Malaka (Kuta Malaka)", kab: 241, prop: 21 },
  { value: 32, label: "Krueng Barona Jaya", kab: 241, prop: 21 },
  { value: 33, label: "Kuta Baro", kab: 241, prop: 21 },
  { value: 34, label: "Lembah Seulawah", kab: 241, prop: 21 },
  { value: 35, label: "Leupung", kab: 241, prop: 21 },
  { value: 36, label: "Lhoknga (Lhonga)", kab: 241, prop: 21 },
  { value: 37, label: "Lhoong", kab: 241, prop: 21 },
  { value: 38, label: "Mantasiek (Montasik)", kab: 241, prop: 21 },
  { value: 39, label: "Mesjid Raya", kab: 241, prop: 21 },
  { value: 40, label: "Peukan Bada", kab: 241, prop: 21 },
  { value: 41, label: "Pulo Aceh", kab: 241, prop: 21 },
  { value: 42, label: "Seulimeum", kab: 241, prop: 21 },
  { value: 43, label: "Simpang Tiga", kab: 241, prop: 21 },
  { value: 44, label: "Suka Makmur", kab: 241, prop: 21 },
  { value: 45, label: "Darul Hikmah", kab: 242, prop: 21 },
  { value: 46, label: "Indra Jaya", kab: 242, prop: 21 },
  { value: 47, label: "Jaya", kab: 242, prop: 21 },
  { value: 48, label: "Keude Panga", kab: 242, prop: 21 },
  { value: 49, label: "Krueng Sabee", kab: 242, prop: 21 },
  { value: 50, label: "Pasie Raya", kab: 242, prop: 21 },
  { value: 51, label: "Sampoiniet", kab: 242, prop: 21 },
  { value: 52, label: "Setia Bakti", kab: 242, prop: 21 },
  { value: 53, label: "Teunom", kab: 242, prop: 21 },
  { value: 54, label: "Bakongan", kab: 243, prop: 21 },
  { value: 55, label: "Bakongan Timur", kab: 243, prop: 21 },
  { value: 56, label: "Kluet Selatan", kab: 243, prop: 21 },
  { value: 57, label: "Kluet Tengah", kab: 243, prop: 21 },
  { value: 58, label: "Kluet Timur", kab: 243, prop: 21 },
  { value: 59, label: "Kluet Utara", kab: 243, prop: 21 },
  { value: 60, label: "Kota Bahagia", kab: 243, prop: 21 },
  { value: 61, label: "Labuhan Haji", kab: 243, prop: 21 },
  { value: 62, label: "Labuhan Haji Barat", kab: 243, prop: 21 },
  { value: 63, label: "Labuhan Haji Timur", kab: 243, prop: 21 },
  { value: 64, label: "Meukek", kab: 243, prop: 21 },
  { value: 65, label: "Pasie Raja", kab: 243, prop: 21 },
  { value: 66, label: "Sama Dua", kab: 243, prop: 21 },
  { value: 67, label: "Sawang", kab: 243, prop: 21 },
  { value: 68, label: "Tapak Tuan", kab: 243, prop: 21 },
  { value: 69, label: "Trumon", kab: 243, prop: 21 },
  { value: 70, label: "Trumon Tengah", kab: 243, prop: 21 },
  { value: 71, label: "Trumon Timur", kab: 243, prop: 21 },
  { value: 72, label: "Danau Paris", kab: 244, prop: 21 },
  { value: 73, label: "Gunung Meriah (Mariah)", kab: 244, prop: 21 },
  { value: 74, label: "Kota Baharu", kab: 244, prop: 21 },
  { value: 75, label: "Kuala Baru", kab: 244, prop: 21 },
  { value: 76, label: "Pulau Banyak", kab: 244, prop: 21 },
  { value: 77, label: "Pulau Banyak Barat", kab: 244, prop: 21 },
  { value: 78, label: "Simpang Kanan", kab: 244, prop: 21 },
  { value: 79, label: "Singkil", kab: 244, prop: 21 },
  { value: 80, label: "Singkil Utara", kab: 244, prop: 21 },
  { value: 81, label: "Singkohor", kab: 244, prop: 21 },
  { value: 82, label: "Suro Makmur", kab: 244, prop: 21 },
  { value: 83, label: "Banda Mulia", kab: 245, prop: 21 },
  { value: 84, label: "Bandar Pusaka", kab: 245, prop: 21 },
  { value: 85, label: "Bendahara", kab: 245, prop: 21 },
  { value: 86, label: "Karang Baru", kab: 245, prop: 21 },
  { value: 87, label: "Kejuruan Muda", kab: 245, prop: 21 },
  { value: 88, label: "Kota Kuala Simpang", kab: 245, prop: 21 },
  { value: 89, label: "Manyak Payed", kab: 245, prop: 21 },
  { value: 90, label: "Rantau", kab: 245, prop: 21 },
  { value: 91, label: "Sekerak", kab: 245, prop: 21 },
  { value: 92, label: "Seruway", kab: 245, prop: 21 },
  { value: 93, label: "Tamiang Hulu", kab: 245, prop: 21 },
  { value: 94, label: "Tenggulun", kab: 245, prop: 21 },
  { value: 95, label: "Atu Lintang", kab: 246, prop: 21 },
  { value: 96, label: "Bebesen", kab: 246, prop: 21 },
  { value: 97, label: "Bies", kab: 246, prop: 21 },
  { value: 98, label: "Bintang", kab: 246, prop: 21 },
  { value: 99, label: "Celala", kab: 246, prop: 21 },
  { value: 100, label: "Jagong Jeget", kab: 246, prop: 21 },
  { value: 101, label: "Kebayakan", kab: 246, prop: 21 },
  { value: 102, label: "Ketol", kab: 246, prop: 21 },
  { value: 103, label: "Kute Panang", kab: 246, prop: 21 },
  { value: 104, label: "Linge", kab: 246, prop: 21 },
  { value: 105, label: "Lut Tawar", kab: 246, prop: 21 },
  { value: 106, label: "Pegasing", kab: 246, prop: 21 },
  { value: 107, label: "Rusip Antara", kab: 246, prop: 21 },
  { value: 108, label: "Silih Nara", kab: 246, prop: 21 },
  { value: 109, label: "Babul Makmur", kab: 247, prop: 21 },
  { value: 110, label: "Babul Rahmah", kab: 247, prop: 21 },
  { value: 111, label: "Babussalam", kab: 247, prop: 21 },
  { value: 112, label: "Badar", kab: 247, prop: 21 },
  { value: 113, label: "Bambel", kab: 247, prop: 21 },
  { value: 114, label: "Bukit Tusam", kab: 247, prop: 21 },
  { value: 115, label: "Darul Hasanah", kab: 247, prop: 21 },
  { value: 116, label: "Deleng Pokhisen", kab: 247, prop: 21 },
  { value: 117, label: "Ketambe", kab: 247, prop: 21 },
  { value: 118, label: "Lawe Alas", kab: 247, prop: 21 },
  { value: 119, label: "Lawe Bulan", kab: 247, prop: 21 },
  { value: 120, label: "Lawe Sigala-Gala", kab: 247, prop: 21 },
  { value: 121, label: "Lawe Sumur", kab: 247, prop: 21 },
  { value: 122, label: "Leuser", kab: 247, prop: 21 },
  { value: 123, label: "Semadam", kab: 247, prop: 21 },
  { value: 124, label: "Tanah Alas", kab: 247, prop: 21 },
  { value: 125, label: "Banda Alam", kab: 248, prop: 21 },
  { value: 126, label: "Birem Bayeun", kab: 248, prop: 21 },
  { value: 127, label: "Darul Aman", kab: 248, prop: 21 },
  { value: 128, label: "Darul Falah", kab: 248, prop: 21 },
  { value: 129, label: "Darul Iksan (Ihsan)", kab: 248, prop: 21 },
  { value: 130, label: "Idi Rayeuk", kab: 248, prop: 21 },
  { value: 131, label: "Idi Timur", kab: 248, prop: 21 },
  { value: 132, label: "Idi Tunong", kab: 248, prop: 21 },
  { value: 133, label: "Indra Makmur", kab: 248, prop: 21 },
  { value: 134, label: "Julok", kab: 248, prop: 21 },
  { value: 135, label: "Madat", kab: 248, prop: 21 },
  { value: 136, label: "Nurussalam", kab: 248, prop: 21 },
  { value: 137, label: "Pante Bidari (Beudari)", kab: 248, prop: 21 },
  { value: 138, label: "Peudawa", kab: 248, prop: 21 },
  { value: 139, label: "Peunaron", kab: 248, prop: 21 },
  { value: 140, label: "Peureulak", kab: 248, prop: 21 },
  { value: 141, label: "Peureulak Barat", kab: 248, prop: 21 },
  { value: 142, label: "Peureulak Timur", kab: 248, prop: 21 },
  { value: 143, label: "Rantau Selamat", kab: 248, prop: 21 },
  { value: 144, label: "Ranto Peureulak", kab: 248, prop: 21 },
  { value: 145, label: "Serba Jadi", kab: 248, prop: 21 },
  { value: 146, label: "Simpang Jernih", kab: 248, prop: 21 },
  { value: 147, label: "Simpang Ulim", kab: 248, prop: 21 },
  { value: 148, label: "Sungai Raya", kab: 248, prop: 21 },
  { value: 149, label: "Baktiya", kab: 249, prop: 21 },
  { value: 150, label: "Baktiya Barat", kab: 249, prop: 21 },
  { value: 151, label: "Banda Baro", kab: 249, prop: 21 },
  { value: 152, label: "Cot Girek", kab: 249, prop: 21 },
  { value: 153, label: "Dewantara", kab: 249, prop: 21 },
  { value: 154, label: "Geuredong Pase", kab: 249, prop: 21 },
  { value: 155, label: "Kuta Makmur", kab: 249, prop: 21 },
  { value: 156, label: "Langkahan", kab: 249, prop: 21 },
  { value: 157, label: "Lapang", kab: 249, prop: 21 },
  { value: 158, label: "Lhoksukon", kab: 249, prop: 21 },
  { value: 159, label: "Matangkuli", kab: 249, prop: 21 },
  { value: 160, label: "Meurah Mulia", kab: 249, prop: 21 },
  { value: 161, label: "Muara Batu", kab: 249, prop: 21 },
  { value: 162, label: "Nibong", kab: 249, prop: 21 },
  { value: 163, label: "Nisam", kab: 249, prop: 21 },
  { value: 164, label: "Nisam Antara", kab: 249, prop: 21 },
  { value: 165, label: "Paya Bakong", kab: 249, prop: 21 },
  { value: 166, label: "Pirak Timur", kab: 249, prop: 21 },
  { value: 167, label: "Samudera", kab: 249, prop: 21 },
  { value: 168, label: "Sawang", kab: 249, prop: 21 },
  { value: 169, label: "Seunuddon (Seunudon)", kab: 249, prop: 21 },
  { value: 170, label: "Simpang Kramat (Keramat)", kab: 249, prop: 21 },
  { value: 171, label: "Syamtalira Aron", kab: 249, prop: 21 },
  { value: 172, label: "Syamtalira Bayu", kab: 249, prop: 21 },
  { value: 173, label: "Tanah Jambo Aye", kab: 249, prop: 21 },
  { value: 174, label: "Tanah Luas", kab: 249, prop: 21 },
  { value: 175, label: "Tanah Pasir", kab: 249, prop: 21 },
  { value: 278, label: "Baiturrahman", kab: 250, prop: 21 },
  { value: 279, label: "Banda Raya", kab: 250, prop: 21 },
  { value: 280, label: "Jaya Baru", kab: 250, prop: 21 },
  { value: 281, label: "Kuta Alam", kab: 250, prop: 21 },
  { value: 282, label: "Kuta Raja", kab: 250, prop: 21 },
  { value: 283, label: "Lueng Bata", kab: 250, prop: 21 },
  { value: 284, label: "Meuraxa", kab: 250, prop: 21 },
  { value: 285, label: "Syiah Kuala", kab: 250, prop: 21 },
  { value: 286, label: "Ulee Kareng", kab: 250, prop: 21 },
  { value: 797, label: "Bandar", kab: 251, prop: 21 },
  { value: 798, label: "Bener Kelipah", kab: 251, prop: 21 },
  { value: 799, label: "Bukit", kab: 251, prop: 21 },
  { value: 800, label: "Gajah Putih", kab: 251, prop: 21 },
  { value: 801, label: "Mesidah", kab: 251, prop: 21 },
  { value: 802, label: "Permata", kab: 251, prop: 21 },
  { value: 803, label: "Pintu Rime Gayo", kab: 251, prop: 21 },
  { value: 804, label: "Syiah Utama", kab: 251, prop: 21 },
  { value: 805, label: "Timang Gajah", kab: 251, prop: 21 },
  { value: 806, label: "Wih Pesam", kab: 251, prop: 21 },
  { value: 949, label: "Ganda Pura", kab: 252, prop: 21 },
  { value: 950, label: "Jangka", kab: 252, prop: 21 },
  { value: 951, label: "Jeumpa", kab: 252, prop: 21 },
  { value: 952, label: "Jeunieb", kab: 252, prop: 21 },
  { value: 953, label: "Juli", kab: 252, prop: 21 },
  { value: 954, label: "Kota Juang", kab: 252, prop: 21 },
  { value: 955, label: "Kuala", kab: 252, prop: 21 },
  { value: 956, label: "Kuta Blang", kab: 252, prop: 21 },
  { value: 957, label: "Makmur", kab: 252, prop: 21 },
  { value: 958, label: "Pandrah", kab: 252, prop: 21 },
  { value: 959, label: "Peudada", kab: 252, prop: 21 },
  { value: 960, label: "Peulimbang (Plimbang)", kab: 252, prop: 21 },
  { value: 961, label: "Peusangan", kab: 252, prop: 21 },
  { value: 962, label: "Peusangan Selatan", kab: 252, prop: 21 },
  { value: 963, label: "Peusangan Siblah Krueng", kab: 252, prop: 21 },
  { value: 964, label: "Samalanga", kab: 252, prop: 21 },
  { value: 965, label: "Simpang Mamplam", kab: 252, prop: 21 },
  { value: 1753, label: "Blang Jerango", kab: 253, prop: 21 },
  { value: 1754, label: "Blang Kejeren", kab: 253, prop: 21 },
  { value: 1755, label: "Blang Pegayon", kab: 253, prop: 21 },
  { value: 1756, label: "Dabun Gelang (Debun Gelang)", kab: 253, prop: 21 },
  { value: 1757, label: "Kuta Panjang", kab: 253, prop: 21 },
  { value: 1758, label: "Pantan Cuaca", kab: 253, prop: 21 },
  { value: 1759, label: "Pining (Pinding)", kab: 253, prop: 21 },
  { value: 1760, label: "Putri Betung", kab: 253, prop: 21 },
  { value: 1761, label: "Rikit Gaib", kab: 253, prop: 21 },
  { value: 1762, label: "Terangun (Terangon)", kab: 253, prop: 21 },
  { value: 1763, label: "Teripe/Tripe Jaya", kab: 253, prop: 21 },
  { value: 3283, label: "Langsa Barat", kab: 254, prop: 21 },
  { value: 3284, label: "Langsa Baro", kab: 254, prop: 21 },
  { value: 3285, label: "Langsa Kota", kab: 254, prop: 21 },
  { value: 3286, label: "Langsa Lama", kab: 254, prop: 21 },
  { value: 3287, label: "Langsa Timur", kab: 254, prop: 21 },
  { value: 3347, label: "Banda Sakti", kab: 255, prop: 21 },
  { value: 3348, label: "Blang Mangat", kab: 255, prop: 21 },
  { value: 3349, label: "Muara Dua", kab: 255, prop: 21 },
  { value: 3350, label: "Muara Satu", kab: 255, prop: 21 },
  { value: 4250, label: "Beutong", kab: 256, prop: 21 },
  { value: 4251, label: "Beutong Ateuh Banggalang", kab: 256, prop: 21 },
  { value: 4252, label: "Darul Makmur", kab: 256, prop: 21 },
  { value: 4253, label: "Kuala", kab: 256, prop: 21 },
  { value: 4254, label: "Kuala Pesisir", kab: 256, prop: 21 },
  { value: 4255, label: "Seunagan", kab: 256, prop: 21 },
  { value: 4256, label: "Seunagan Timur", kab: 256, prop: 21 },
  { value: 4257, label: "Suka Makmue", kab: 256, prop: 21 },
  { value: 4258, label: "Tadu Raya", kab: 256, prop: 21 },
  { value: 4259, label: "Tripa Makmur", kab: 256, prop: 21 },
  { value: 4999, label: "Batee", kab: 257, prop: 21 },
  { value: 5000, label: "Delima", kab: 257, prop: 21 },
  { value: 5001, label: "Geumpang", kab: 257, prop: 21 },
  { value: 5002, label: "Glumpang Baro", kab: 257, prop: 21 },
  { value: 5003, label: "Glumpang Tiga (Geulumpang Tiga)", kab: 257, prop: 21 },
  { value: 5004, label: "Grong Grong", kab: 257, prop: 21 },
  { value: 5005, label: "Indrajaya", kab: 257, prop: 21 },
  { value: 5006, label: "Kembang Tanjong (Tanjung)", kab: 257, prop: 21 },
  { value: 5007, label: "Keumala", kab: 257, prop: 21 },
  { value: 5008, label: "Kota Sigli", kab: 257, prop: 21 },
  { value: 5009, label: "Mane", kab: 257, prop: 21 },
  { value: 5010, label: "Mila", kab: 257, prop: 21 },
  { value: 5011, label: "Muara Tiga", kab: 257, prop: 21 },
  { value: 5012, label: "Mutiara", kab: 257, prop: 21 },
  { value: 5013, label: "Mutiara Timur", kab: 257, prop: 21 },
  { value: 5014, label: "Padang Tiji", kab: 257, prop: 21 },
  { value: 5015, label: "Peukan Baro", kab: 257, prop: 21 },
  { value: 5016, label: "Pidie", kab: 257, prop: 21 },
  { value: 5017, label: "Sakti", kab: 257, prop: 21 },
  { value: 5018, label: "Simpang Tiga", kab: 257, prop: 21 },
  { value: 5019, label: "Tangse", kab: 257, prop: 21 },
  { value: 5020, label: "Tiro/Truseb", kab: 257, prop: 21 },
  { value: 5021, label: "Titeue", kab: 257, prop: 21 },
  { value: 5022, label: "Bandar Baru", kab: 258, prop: 21 },
  { value: 5023, label: "Bandar Dua", kab: 258, prop: 21 },
  { value: 5024, label: "Jangka Buya", kab: 258, prop: 21 },
  { value: 5025, label: "Meurah Dua", kab: 258, prop: 21 },
  { value: 5026, label: "Meureudu", kab: 258, prop: 21 },
  { value: 5027, label: "Panteraja", kab: 258, prop: 21 },
  { value: 5028, label: "Trienggadeng", kab: 258, prop: 21 },
  { value: 5029, label: "Ulim", kab: 258, prop: 21 },
  { value: 5344, label: "Sukajaya", kab: 259, prop: 21 },
  { value: 5345, label: "Sukakarya", kab: 259, prop: 21 },
  { value: 5724, label: "Alapan (Alafan)", kab: 260, prop: 21 },
  { value: 5725, label: "Salang", kab: 260, prop: 21 },
  { value: 5726, label: "Simeuleu Barat", kab: 260, prop: 21 },
  { value: 5727, label: "Simeuleu Tengah", kab: 260, prop: 21 },
  { value: 5728, label: "Simeuleu Timur", kab: 260, prop: 21 },
  { value: 5729, label: "Simeulue Cut", kab: 260, prop: 21 },
  { value: 5730, label: "Teluk Dalam", kab: 260, prop: 21 },
  { value: 5731, label: "Teupah Barat", kab: 260, prop: 21 },
  { value: 5732, label: "Teupah Selatan", kab: 260, prop: 21 },
  { value: 5733, label: "Teupah Tengah", kab: 260, prop: 21 },
  { value: 5913, label: "Longkib", kab: 261, prop: 21 },
  { value: 5914, label: "Penanggalan", kab: 261, prop: 21 },
  { value: 5915, label: "Rundeng", kab: 261, prop: 21 },
  { value: 5916, label: "Simpang Kiri", kab: 261, prop: 21 },
  { value: 5917, label: "Sultan Daulat", kab: 261, prop: 21 },
  { value: 911, label: "Ambalawi", kab: 262, prop: 22 },
  { value: 912, label: "Belo", kab: 262, prop: 22 },
  { value: 913, label: "Bolo", kab: 262, prop: 22 },
  { value: 914, label: "Donggo", kab: 262, prop: 22 },
  { value: 915, label: "Lambitu", kab: 262, prop: 22 },
  { value: 916, label: "Lambu", kab: 262, prop: 22 },
  { value: 917, label: "Langgudu", kab: 262, prop: 22 },
  { value: 918, label: "Madapangga", kab: 262, prop: 22 },
  { value: 919, label: "Monta", kab: 262, prop: 22 },
  { value: 920, label: "Palibelo", kab: 262, prop: 22 },
  { value: 921, label: "Parado", kab: 262, prop: 22 },
  { value: 922, label: "Sanggar", kab: 262, prop: 22 },
  { value: 923, label: "Sape", kab: 262, prop: 22 },
  { value: 924, label: "Soromandi", kab: 262, prop: 22 },
  { value: 925, label: "Tambora", kab: 262, prop: 22 },
  { value: 926, label: "Wawo", kab: 262, prop: 22 },
  { value: 927, label: "Wera", kab: 262, prop: 22 },
  { value: 928, label: "Woha", kab: 262, prop: 22 },
  { value: 929, label: "Asakota", kab: 262, prop: 22 },
  { value: 930, label: "Mpunda", kab: 262, prop: 22 },
  { value: 931, label: "Raba", kab: 262, prop: 22 },
  { value: 932, label: "Rasanae Barat", kab: 262, prop: 22 },
  { value: 933, label: "Rasanae Timur", kab: 262, prop: 22 },
  { value: 1609, label: "Dompu", kab: 263, prop: 22 },
  { value: 1610, label: "Huu", kab: 263, prop: 22 },
  { value: 1611, label: "Kempo", kab: 263, prop: 22 },
  { value: 1612, label: "Kilo", kab: 263, prop: 22 },
  { value: 1613, label: "Menggelewa (Manggelewa)", kab: 263, prop: 22 },
  { value: 1614, label: "Pajo", kab: 263, prop: 22 },
  { value: 1615, label: "Pekat", kab: 263, prop: 22 },
  { value: 1616, label: "Woja", kab: 263, prop: 22 },
  { value: 3372, label: "Batu Layar", kab: 264, prop: 22 },
  { value: 3373, label: "Gerung", kab: 264, prop: 22 },
  { value: 3374, label: "Gunungsari", kab: 264, prop: 22 },
  { value: 3375, label: "Kediri", kab: 264, prop: 22 },
  { value: 3376, label: "Kuripan", kab: 264, prop: 22 },
  { value: 3377, label: "Labuapi", kab: 264, prop: 22 },
  { value: 3378, label: "Lembar", kab: 264, prop: 22 },
  { value: 3379, label: "Lingsar", kab: 264, prop: 22 },
  { value: 3380, label: "Narmada", kab: 264, prop: 22 },
  { value: 3381, label: "Sekotong", kab: 264, prop: 22 },
  { value: 3382, label: "Batukliang", kab: 265, prop: 22 },
  { value: 3383, label: "Batukliang Utara", kab: 265, prop: 22 },
  { value: 3384, label: "Janapria", kab: 265, prop: 22 },
  { value: 3385, label: "Jonggat", kab: 265, prop: 22 },
  { value: 3386, label: "Kopang", kab: 265, prop: 22 },
  { value: 3387, label: "Praya", kab: 265, prop: 22 },
  { value: 3388, label: "Praya Barat", kab: 265, prop: 22 },
  { value: 3389, label: "Praya Barat Daya", kab: 265, prop: 22 },
  { value: 3390, label: "Praya Tengah", kab: 265, prop: 22 },
  { value: 3391, label: "Praya Timur", kab: 265, prop: 22 },
  { value: 3392, label: "Pringgarata", kab: 265, prop: 22 },
  { value: 3393, label: "Pujut", kab: 265, prop: 22 },
  { value: 3394, label: "Aikmel", kab: 266, prop: 22 },
  { value: 3395, label: "Jerowaru", kab: 266, prop: 22 },
  { value: 3396, label: "Keruak", kab: 266, prop: 22 },
  { value: 3397, label: "Labuhan Haji", kab: 266, prop: 22 },
  { value: 3398, label: "Masbagik", kab: 266, prop: 22 },
  { value: 3399, label: "Montong Gading", kab: 266, prop: 22 },
  { value: 3400, label: "Pringgabaya", kab: 266, prop: 22 },
  { value: 3401, label: "Pringgasela", kab: 266, prop: 22 },
  { value: 3402, label: "Sakra", kab: 266, prop: 22 },
  { value: 3403, label: "Sakra Barat", kab: 266, prop: 22 },
  { value: 3404, label: "Sakra Timur", kab: 266, prop: 22 },
  { value: 3405, label: "Sambalia (Sambelia)", kab: 266, prop: 22 },
  { value: 3406, label: "Selong", kab: 266, prop: 22 },
  { value: 3407, label: "Sembalun", kab: 266, prop: 22 },
  { value: 3408, label: "Sikur", kab: 266, prop: 22 },
  { value: 3409, label: "Suela (Suwela)", kab: 266, prop: 22 },
  { value: 3410, label: "Sukamulia", kab: 266, prop: 22 },
  { value: 3411, label: "Suralaga", kab: 266, prop: 22 },
  { value: 3412, label: "Terara", kab: 266, prop: 22 },
  { value: 3413, label: "Wanasaba", kab: 266, prop: 22 },
  { value: 3414, label: "Bayan", kab: 267, prop: 22 },
  { value: 3415, label: "Gangga", kab: 267, prop: 22 },
  { value: 3416, label: "Kayangan", kab: 267, prop: 22 },
  { value: 3417, label: "Pemenang", kab: 267, prop: 22 },
  { value: 3418, label: "Tanjung", kab: 267, prop: 22 },
  { value: 3876, label: "Ampenan", kab: 268, prop: 22 },
  { value: 3877, label: "Cakranegara", kab: 268, prop: 22 },
  { value: 3878, label: "Mataram", kab: 268, prop: 22 },
  { value: 3879, label: "Sandubaya (Sandujaya)", kab: 268, prop: 22 },
  { value: 3880, label: "Sekarbela", kab: 268, prop: 22 },
  { value: 3881, label: "Selaparang (Selaprang)", kab: 268, prop: 22 },
  { value: 6033, label: "Alas", kab: 269, prop: 22 },
  { value: 6034, label: "Alas Barat", kab: 269, prop: 22 },
  { value: 6035, label: "Batulanteh", kab: 269, prop: 22 },
  { value: 6036, label: "Buer", kab: 269, prop: 22 },
  { value: 6037, label: "Empang", kab: 269, prop: 22 },
  { value: 6038, label: "Labangka", kab: 269, prop: 22 },
  { value: 6039, label: "Labuhan Badas", kab: 269, prop: 22 },
  { value: 6040, label: "Lantung", kab: 269, prop: 22 },
  { value: 6041, label: "Lape (Lape Lopok)", kab: 269, prop: 22 },
  { value: 6042, label: "Lenangguar", kab: 269, prop: 22 },
  { value: 6043, label: "Lopok", kab: 269, prop: 22 },
  { value: 6044, label: "Lunyuk", kab: 269, prop: 22 },
  { value: 6045, label: "Maronge", kab: 269, prop: 22 },
  { value: 6046, label: "Moyo Hilir", kab: 269, prop: 22 },
  { value: 6047, label: "Moyo Hulu", kab: 269, prop: 22 },
  { value: 6048, label: "Moyo Utara", kab: 269, prop: 22 },
  { value: 6049, label: "Orong Telu", kab: 269, prop: 22 },
  { value: 6050, label: "Plampang", kab: 269, prop: 22 },
  { value: 6051, label: "Rhee", kab: 269, prop: 22 },
  { value: 6052, label: "Ropang", kab: 269, prop: 22 },
  { value: 6053, label: "Sumbawa", kab: 269, prop: 22 },
  { value: 6054, label: "Tarano", kab: 269, prop: 22 },
  { value: 6055, label: "Unter Iwes (Unterwiris)", kab: 269, prop: 22 },
  { value: 6056, label: "Utan", kab: 269, prop: 22 },
  { value: 6057, label: "Brang Ene", kab: 270, prop: 22 },
  { value: 6058, label: "Brang Rea", kab: 270, prop: 22 },
  { value: 6059, label: "Jereweh", kab: 270, prop: 22 },
  { value: 6060, label: "Maluk", kab: 270, prop: 22 },
  { value: 6061, label: "Poto Tano", kab: 270, prop: 22 },
  { value: 6062, label: "Sateluk (Seteluk)", kab: 270, prop: 22 },
  { value: 6063, label: "Sekongkang", kab: 270, prop: 22 },
  { value: 6064, label: "Taliwang", kab: 270, prop: 22 },
  { value: 192, label: "Alor Barat Daya", kab: 271, prop: 23 },
  { value: 193, label: "Alor Barat Laut", kab: 271, prop: 23 },
  { value: 194, label: "Alor Selatan", kab: 271, prop: 23 },
  { value: 195, label: "Alor Tengah Utara", kab: 271, prop: 23 },
  { value: 196, label: "Alor Timur", kab: 271, prop: 23 },
  { value: 197, label: "Alor Timur Laut", kab: 271, prop: 23 },
  { value: 198, label: "Kabola", kab: 271, prop: 23 },
  { value: 199, label: "Lembur", kab: 271, prop: 23 },
  { value: 200, label: "Mataru", kab: 271, prop: 23 },
  { value: 201, label: "Pantar", kab: 271, prop: 23 },
  { value: 202, label: "Pantar Barat", kab: 271, prop: 23 },
  { value: 203, label: "Pantar Barat Laut", kab: 271, prop: 23 },
  { value: 204, label: "Pantar Tengah", kab: 271, prop: 23 },
  { value: 205, label: "Pantar Timur", kab: 271, prop: 23 },
  { value: 206, label: "Pulau Pura", kab: 271, prop: 23 },
  { value: 207, label: "Pureman", kab: 271, prop: 23 },
  { value: 208, label: "Teluk Mutiara", kab: 271, prop: 23 },
  { value: 773, label: "Atambua Barat", kab: 272, prop: 23 },
  { value: 774, label: "Atambua Kota", kab: 272, prop: 23 },
  { value: 775, label: "Atambua Selatan", kab: 272, prop: 23 },
  { value: 776, label: "Botin Leo Bele", kab: 272, prop: 23 },
  { value: 777, label: "Io Kufeu", kab: 272, prop: 23 },
  { value: 778, label: "Kakuluk Mesak", kab: 272, prop: 23 },
  { value: 779, label: "Kobalima", kab: 272, prop: 23 },
  { value: 780, label: "Kobalima Timur", kab: 272, prop: 23 },
  { value: 781, label: "Laen Manen", kab: 272, prop: 23 },
  { value: 782, label: "Lamaknen", kab: 272, prop: 23 },
  { value: 783, label: "Lamaknen Selatan", kab: 272, prop: 23 },
  { value: 784, label: "Lasiolat", kab: 272, prop: 23 },
  { value: 785, label: "Malaka Barat", kab: 272, prop: 23 },
  { value: 786, label: "Malaka Tengah", kab: 272, prop: 23 },
  { value: 787, label: "Malaka Timur", kab: 272, prop: 23 },
  { value: 788, label: "Nanaet Duabesi", kab: 272, prop: 23 },
  { value: 789, label: "Raihat", kab: 272, prop: 23 },
  { value: 790, label: "Raimanuk", kab: 272, prop: 23 },
  { value: 791, label: "Rinhat", kab: 272, prop: 23 },
  { value: 792, label: "Sasitamean", kab: 272, prop: 23 },
  { value: 793, label: "Tasifeto Barat", kab: 272, prop: 23 },
  { value: 794, label: "Tasifeto Timur", kab: 272, prop: 23 },
  { value: 795, label: "Weliman", kab: 272, prop: 23 },
  { value: 796, label: "Wewiku", kab: 272, prop: 23 },
  { value: 1650, label: "Detukeli", kab: 273, prop: 23 },
  { value: 1651, label: "Detusoko", kab: 273, prop: 23 },
  { value: 1652, label: "Ende", kab: 273, prop: 23 },
  { value: 1653, label: "Ende Selatan", kab: 273, prop: 23 },
  { value: 1654, label: "Ende Tengah", kab: 273, prop: 23 },
  { value: 1655, label: "Ende Timur", kab: 273, prop: 23 },
  { value: 1656, label: "Ende Utara", kab: 273, prop: 23 },
  { value: 1657, label: "Kelimutu", kab: 273, prop: 23 },
  { value: 1658, label: "Kotabaru", kab: 273, prop: 23 },
  { value: 1659, label: "Lepembusu Kelisoke", kab: 273, prop: 23 },
  { value: 1660, label: "Lio Timur", kab: 273, prop: 23 },
  { value: 1661, label: "Maukaro", kab: 273, prop: 23 },
  { value: 1662, label: "Maurole", kab: 273, prop: 23 },
  { value: 1663, label: "Nangapanda", kab: 273, prop: 23 },
  { value: 1664, label: "Ndona", kab: 273, prop: 23 },
  { value: 1665, label: "Ndona Timur", kab: 273, prop: 23 },
  { value: 1666, label: "Ndori", kab: 273, prop: 23 },
  { value: 1667, label: "Pulau Ende", kab: 273, prop: 23 },
  { value: 1668, label: "Wewaria", kab: 273, prop: 23 },
  { value: 1669, label: "Wolojita", kab: 273, prop: 23 },
  { value: 1670, label: "Wolowaru", kab: 273, prop: 23 },
  { value: 1692, label: "Adonara", kab: 274, prop: 23 },
  { value: 1693, label: "Adonara Barat", kab: 274, prop: 23 },
  { value: 1694, label: "Adonara Tengah", kab: 274, prop: 23 },
  { value: 1695, label: "Adonara Timur", kab: 274, prop: 23 },
  { value: 1696, label: "Demon Pagong", kab: 274, prop: 23 },
  { value: 1697, label: "Ile Boleng", kab: 274, prop: 23 },
  { value: 1698, label: "Ile Bura", kab: 274, prop: 23 },
  { value: 1699, label: "Ile Mandiri", kab: 274, prop: 23 },
  { value: 1700, label: "Kelubagolit (Klubagolit)", kab: 274, prop: 23 },
  { value: 1701, label: "Larantuka", kab: 274, prop: 23 },
  { value: 1702, label: "Lewolema", kab: 274, prop: 23 },
  { value: 1703, label: "Solor Barat", kab: 274, prop: 23 },
  { value: 1704, label: "Solor Selatan", kab: 274, prop: 23 },
  { value: 1705, label: "Solor Timur", kab: 274, prop: 23 },
  { value: 1706, label: "Tanjung Bunga", kab: 274, prop: 23 },
  { value: 1707, label: "Titehena", kab: 274, prop: 23 },
  { value: 1708, label: "Witihama (Watihama)", kab: 274, prop: 23 },
  { value: 1709, label: "Wotan Ulumado", kab: 274, prop: 23 },
  { value: 1710, label: "Wulanggitang", kab: 274, prop: 23 },
  { value: 2974, label: "Amabi Oefeto", kab: 275, prop: 23 },
  { value: 2975, label: "Amabi Oefeto Timur", kab: 275, prop: 23 },
  { value: 2976, label: "Amarasi", kab: 275, prop: 23 },
  { value: 2977, label: "Amarasi Barat", kab: 275, prop: 23 },
  { value: 2978, label: "Amarasi Selatan", kab: 275, prop: 23 },
  { value: 2979, label: "Amarasi Timur", kab: 275, prop: 23 },
  { value: 2980, label: "Amfoang Barat Daya", kab: 275, prop: 23 },
  { value: 2981, label: "Amfoang Barat Laut", kab: 275, prop: 23 },
  { value: 2982, label: "Amfoang Selatan", kab: 275, prop: 23 },
  { value: 2983, label: "Amfoang Tengah", kab: 275, prop: 23 },
  { value: 2984, label: "Amfoang Timur", kab: 275, prop: 23 },
  { value: 2985, label: "Amfoang Utara", kab: 275, prop: 23 },
  { value: 2986, label: "Fatuleu", kab: 275, prop: 23 },
  { value: 2987, label: "Fatuleu Barat", kab: 275, prop: 23 },
  { value: 2988, label: "Fatuleu Tengah", kab: 275, prop: 23 },
  { value: 2989, label: "Kupang Barat", kab: 275, prop: 23 },
  { value: 2990, label: "Kupang Tengah", kab: 275, prop: 23 },
  { value: 2991, label: "Kupang Timur", kab: 275, prop: 23 },
  { value: 2992, label: "Nekamese", kab: 275, prop: 23 },
  { value: 2993, label: "Semau", kab: 275, prop: 23 },
  { value: 2994, label: "Semau Selatan", kab: 275, prop: 23 },
  { value: 2995, label: "Sulamu", kab: 275, prop: 23 },
  { value: 2996, label: "Taebenu", kab: 275, prop: 23 },
  { value: 2997, label: "Takari", kab: 275, prop: 23 },
  { value: 2998, label: "Alak", kab: 275, prop: 23 },
  { value: 2999, label: "Kelapa Lima", kab: 275, prop: 23 },
  { value: 3000, label: "Kota Lama", kab: 275, prop: 23 },
  { value: 3001, label: "Kota Raja", kab: 275, prop: 23 },
  { value: 3002, label: "Maulafa", kab: 275, prop: 23 },
  { value: 3003, label: "Oebobo", kab: 275, prop: 23 },
  { value: 3338, label: "Atadei", kab: 276, prop: 23 },
  { value: 3339, label: "Buyasuri (Buyasari)", kab: 276, prop: 23 },
  { value: 3340, label: "Ile Ape", kab: 276, prop: 23 },
  { value: 3341, label: "Ile Ape Timur", kab: 276, prop: 23 },
  { value: 3342, label: "Lebatukan", kab: 276, prop: 23 },
  { value: 3343, label: "Nagawutung", kab: 276, prop: 23 },
  { value: 3344, label: "Nubatukan", kab: 276, prop: 23 },
  { value: 3345, label: "Omesuri", kab: 276, prop: 23 },
  { value: 3346, label: "Wulandoni (Wulandioni)", kab: 276, prop: 23 },
  { value: 3802, label: "Cibal", kab: 277, prop: 23 },
  { value: 3803, label: "Cibal Barat", kab: 277, prop: 23 },
  { value: 3804, label: "Langke Rembong", kab: 277, prop: 23 },
  { value: 3805, label: "Lelak", kab: 277, prop: 23 },
  { value: 3806, label: "Rahong Utara", kab: 277, prop: 23 },
  { value: 3807, label: "Reok", kab: 277, prop: 23 },
  { value: 3808, label: "Reok Barat", kab: 277, prop: 23 },
  { value: 3809, label: "Ruteng", kab: 277, prop: 23 },
  { value: 3810, label: "Satar Mese", kab: 277, prop: 23 },
  { value: 3811, label: "Satar Mese Barat", kab: 277, prop: 23 },
  { value: 3812, label: "Wae Rii", kab: 277, prop: 23 },
  { value: 3813, label: "Boleng", kab: 278, prop: 23 },
  { value: 3814, label: "Komodo", kab: 278, prop: 23 },
  { value: 3815, label: "Kuwus", kab: 278, prop: 23 },
  { value: 3816, label: "Lembor", kab: 278, prop: 23 },
  { value: 3817, label: "Lembor Selatan", kab: 278, prop: 23 },
  { value: 3818, label: "Macang Pacar", kab: 278, prop: 23 },
  { value: 3819, label: "Mbeliling", kab: 278, prop: 23 },
  { value: 3820, label: "Ndoso", kab: 278, prop: 23 },
  { value: 3821, label: "Sano Nggoang", kab: 278, prop: 23 },
  { value: 3822, label: "Welak", kab: 278, prop: 23 },
  { value: 3823, label: "Borong", kab: 279, prop: 23 },
  { value: 3824, label: "Elar", kab: 279, prop: 23 },
  { value: 3825, label: "Elar Selatan", kab: 279, prop: 23 },
  { value: 3826, label: "Kota Komba", kab: 279, prop: 23 },
  { value: 3827, label: "Lamba Leda", kab: 279, prop: 23 },
  { value: 3828, label: "Poco Ranaka", kab: 279, prop: 23 },
  { value: 3829, label: "Poco Ranaka Timur", kab: 279, prop: 23 },
  { value: 3830, label: "Rana Mese", kab: 279, prop: 23 },
  { value: 3831, label: "Sambi Rampas", kab: 279, prop: 23 },
  { value: 4260, label: "Aesesa", kab: 280, prop: 23 },
  { value: 4261, label: "Aesesa Selatan", kab: 280, prop: 23 },
  { value: 4262, label: "Boawae", kab: 280, prop: 23 },
  { value: 4263, label: "Keo Tengah", kab: 280, prop: 23 },
  { value: 4264, label: "Mauponggo", kab: 280, prop: 23 },
  { value: 4265, label: "Nangaroro", kab: 280, prop: 23 },
  { value: 4266, label: "Wolowae", kab: 280, prop: 23 },
  { value: 4311, label: "Aimere", kab: 281, prop: 23 },
  { value: 4312, label: "Bajawa", kab: 281, prop: 23 },
  { value: 4313, label: "Bajawa Utara", kab: 281, prop: 23 },
  { value: 4314, label: "Golewa", kab: 281, prop: 23 },
  { value: 4315, label: "Golewa Barat", kab: 281, prop: 23 },
  { value: 4316, label: "Golewa Selatan", kab: 281, prop: 23 },
  { value: 4317, label: "Inerie", kab: 281, prop: 23 },
  { value: 4318, label: "Jerebuu", kab: 281, prop: 23 },
  { value: 4319, label: "Riung", kab: 281, prop: 23 },
  { value: 4320, label: "Riung Barat", kab: 281, prop: 23 },
  { value: 4321, label: "Soa", kab: 281, prop: 23 },
  { value: 4322, label: "Wolomeze (Riung Selatan)", kab: 281, prop: 23 },
  { value: 5334, label: "Landu Leko", kab: 282, prop: 23 },
  { value: 5335, label: "Lobalain", kab: 282, prop: 23 },
  { value: 5336, label: "Ndao Nuse", kab: 282, prop: 23 },
  { value: 5337, label: "Pantai Baru", kab: 282, prop: 23 },
  { value: 5338, label: "Rote Barat", kab: 282, prop: 23 },
  { value: 5339, label: "Rote Barat Daya", kab: 282, prop: 23 },
  { value: 5340, label: "Rote Barat Laut", kab: 282, prop: 23 },
  { value: 5341, label: "Rote Selatan", kab: 282, prop: 23 },
  { value: 5342, label: "Rote Tengah", kab: 282, prop: 23 },
  { value: 5343, label: "Rote Timur", kab: 282, prop: 23 },
  { value: 5346, label: "Hawu Mehara", kab: 283, prop: 23 },
  { value: 5347, label: "Raijua", kab: 283, prop: 23 },
  { value: 5348, label: "Sabu Barat", kab: 283, prop: 23 },
  { value: 5349, label: "Sabu Liae", kab: 283, prop: 23 },
  { value: 5350, label: "Sabu Tengah", kab: 283, prop: 23 },
  { value: 5351, label: "Sabu Timur", kab: 283, prop: 23 },
  { value: 5672, label: "Alok", kab: 284, prop: 23 },
  { value: 5673, label: "Alok Barat", kab: 284, prop: 23 },
  { value: 5674, label: "Alok Timur", kab: 284, prop: 23 },
  { value: 5675, label: "Bola", kab: 284, prop: 23 },
  { value: 5676, label: "Doreng", kab: 284, prop: 23 },
  { value: 5677, label: "Hewokloang", kab: 284, prop: 23 },
  { value: 5678, label: "Kangae", kab: 284, prop: 23 },
  { value: 5679, label: "Kewapante", kab: 284, prop: 23 },
  { value: 5680, label: "Koting", kab: 284, prop: 23 },
  { value: 5681, label: "Lela", kab: 284, prop: 23 },
  { value: 5682, label: "Magepanda", kab: 284, prop: 23 },
  { value: 5683, label: "Mapitara", kab: 284, prop: 23 },
  { value: 5684, label: "Mego", kab: 284, prop: 23 },
  { value: 5685, label: "Nelle (Maumerei)", kab: 284, prop: 23 },
  { value: 5686, label: "Nita", kab: 284, prop: 23 },
  { value: 5687, label: "Paga", kab: 284, prop: 23 },
  { value: 5688, label: "Palue", kab: 284, prop: 23 },
  { value: 5689, label: "Talibura", kab: 284, prop: 23 },
  { value: 5690, label: "Tana Wawo", kab: 284, prop: 23 },
  { value: 5691, label: "Waiblama", kab: 284, prop: 23 },
  { value: 5692, label: "Waigete", kab: 284, prop: 23 },
  { value: 5989, label: "Kota Waikabubak", kab: 285, prop: 23 },
  { value: 5990, label: "Lamboya", kab: 285, prop: 23 },
  { value: 5991, label: "Lamboya Barat", kab: 285, prop: 23 },
  { value: 5992, label: "Loli", kab: 285, prop: 23 },
  { value: 5993, label: "Tana Righu", kab: 285, prop: 23 },
  { value: 5994, label: "Wanokaka", kab: 285, prop: 23 },
  { value: 5995, label: "Kodi", kab: 286, prop: 23 },
  { value: 5996, label: "Kodi Balaghar", kab: 286, prop: 23 },
  { value: 5997, label: "Kodi Bangedo", kab: 286, prop: 23 },
  { value: 5998, label: "Kodi Utara", kab: 286, prop: 23 },
  { value: 5999, label: "Kota Tambolaka", kab: 286, prop: 23 },
  { value: 6000, label: "Loura (Laura)", kab: 286, prop: 23 },
  { value: 6001, label: "Wewewa Barat", kab: 286, prop: 23 },
  { value: 6002, label: "Wewewa Selatan", kab: 286, prop: 23 },
  { value: 6003, label: "Wewewa Tengah (Wewera Tengah)", kab: 286, prop: 23 },
  { value: 6004, label: "Wewewa Timur", kab: 286, prop: 23 },
  { value: 6005, label: "Wewewa Utara", kab: 286, prop: 23 },
  { value: 6006, label: "Katikutana", kab: 287, prop: 23 },
  { value: 6007, label: "Katikutana Selatan", kab: 287, prop: 23 },
  { value: 6008, label: "Mamboro", kab: 287, prop: 23 },
  { value: 6009, label: "Umbu Ratu Nggay", kab: 287, prop: 23 },
  { value: 6010, label: "Umbu Ratu Nggay Barat", kab: 287, prop: 23 },
  { value: 6011, label: "Haharu", kab: 288, prop: 23 },
  { value: 6012, label: "Kahaunguweti (Kahaungu Eti)", kab: 288, prop: 23 },
  { value: 6013, label: "Kambata Mapambuhang", kab: 288, prop: 23 },
  { value: 6014, label: "Kambera", kab: 288, prop: 23 },
  { value: 6015, label: "Kanatang", kab: 288, prop: 23 },
  { value: 6016, label: "Karera", kab: 288, prop: 23 },
  { value: 6017, label: "Katala Hamu Lingu", kab: 288, prop: 23 },
  { value: 6018, label: "Kota Waingapu", kab: 288, prop: 23 },
  { value: 6019, label: "Lewa", kab: 288, prop: 23 },
  { value: 6020, label: "Lewa Tidahu", kab: 288, prop: 23 },
  { value: 6021, label: "Mahu", kab: 288, prop: 23 },
  { value: 6022, label: "Matawai Lappau (La Pawu)", kab: 288, prop: 23 },
  { value: 6023, label: "Ngadu Ngala", kab: 288, prop: 23 },
  { value: 6024, label: "Nggaha Oriangu", kab: 288, prop: 23 },
  { value: 6025, label: "Paberiwai", kab: 288, prop: 23 },
  { value: 6026, label: "Pahunga Lodu", kab: 288, prop: 23 },
  { value: 6027, label: "Pandawai", kab: 288, prop: 23 },
  { value: 6028, label: "Pinupahar (Pirapahar)", kab: 288, prop: 23 },
  { value: 6029, label: "Rindi", kab: 288, prop: 23 },
  { value: 6030, label: "Tabundung", kab: 288, prop: 23 },
  { value: 6031, label: "Umalulu", kab: 288, prop: 23 },
  { value: 6032, label: "Wula Waijelu", kab: 288, prop: 23 },
  { value: 6596, label: "Amanatun Selatan", kab: 289, prop: 23 },
  { value: 6597, label: "Amanatun Utara", kab: 289, prop: 23 },
  { value: 6598, label: "Amanuban Barat", kab: 289, prop: 23 },
  { value: 6599, label: "Amanuban Selatan", kab: 289, prop: 23 },
  { value: 6600, label: "Amanuban Tengah", kab: 289, prop: 23 },
  { value: 6601, label: "Amanuban Timur", kab: 289, prop: 23 },
  { value: 6602, label: "Batu Putih", kab: 289, prop: 23 },
  { value: 6603, label: "Boking", kab: 289, prop: 23 },
  { value: 6604, label: "Fatukopa", kab: 289, prop: 23 },
  { value: 6605, label: "Fatumnasi", kab: 289, prop: 23 },
  { value: 6606, label: "Fautmolo", kab: 289, prop: 23 },
  { value: 6607, label: "Kie (Kie)", kab: 289, prop: 23 },
  { value: 6608, label: "Kok Baun", kab: 289, prop: 23 },
  { value: 6609, label: "Kolbano", kab: 289, prop: 23 },
  { value: 6610, label: "Kot Olin", kab: 289, prop: 23 },
  { value: 6611, label: "Kota Soe", kab: 289, prop: 23 },
  { value: 6612, label: "Kualin", kab: 289, prop: 23 },
  { value: 6613, label: "Kuanfatu", kab: 289, prop: 23 },
  { value: 6614, label: "Kuatnana", kab: 289, prop: 23 },
  { value: 6615, label: "Mollo Barat", kab: 289, prop: 23 },
  { value: 6616, label: "Mollo Selatan", kab: 289, prop: 23 },
  { value: 6617, label: "Mollo Tengah", kab: 289, prop: 23 },
  { value: 6618, label: "Mollo Utara", kab: 289, prop: 23 },
  { value: 6619, label: "Noebana", kab: 289, prop: 23 },
  { value: 6620, label: "Noebeba", kab: 289, prop: 23 },
  { value: 6621, label: "Nunbena", kab: 289, prop: 23 },
  { value: 6622, label: "Nunkolo", kab: 289, prop: 23 },
  { value: 6623, label: "Oenino", kab: 289, prop: 23 },
  { value: 6624, label: "Polen", kab: 289, prop: 23 },
  { value: 6625, label: "Santian", kab: 289, prop: 23 },
  { value: 6626, label: "Tobu", kab: 289, prop: 23 },
  { value: 6627, label: "Toianas", kab: 289, prop: 23 },
  { value: 6628, label: "Biboki Anleu", kab: 290, prop: 23 },
  { value: 6629, label: "Biboki Feotleu", kab: 290, prop: 23 },
  { value: 6630, label: "Biboki Moenleu", kab: 290, prop: 23 },
  { value: 6631, label: "Biboki Selatan", kab: 290, prop: 23 },
  { value: 6632, label: "Biboki Tan Pah", kab: 290, prop: 23 },
  { value: 6633, label: "Biboki Utara", kab: 290, prop: 23 },
  { value: 6634, label: "Bikomi Nilulat", kab: 290, prop: 23 },
  { value: 6635, label: "Bikomi Selatan", kab: 290, prop: 23 },
  { value: 6636, label: "Bikomi Tengah", kab: 290, prop: 23 },
  { value: 6637, label: "Bikomi Utara", kab: 290, prop: 23 },
  { value: 6638, label: "Insana", kab: 290, prop: 23 },
  { value: 6639, label: "Insana Barat", kab: 290, prop: 23 },
  { value: 6640, label: "Insana Fafinesu", kab: 290, prop: 23 },
  { value: 6641, label: "Insana Tengah", kab: 290, prop: 23 },
  { value: 6642, label: "Insana Utara", kab: 290, prop: 23 },
  { value: 6643, label: "Kota Kefamenanu", kab: 290, prop: 23 },
  { value: 6644, label: "Miomaffo Barat", kab: 290, prop: 23 },
  { value: 6645, label: "Miomaffo Tengah", kab: 290, prop: 23 },
  { value: 6646, label: "Miomaffo Timur", kab: 290, prop: 23 },
  { value: 6647, label: "Musi", kab: 290, prop: 23 },
  { value: 6648, label: "Mutis", kab: 290, prop: 23 },
  { value: 6649, label: "Naibenu", kab: 290, prop: 23 },
  { value: 6650, label: "Noemuti", kab: 290, prop: 23 },
  { value: 6651, label: "Noemuti Timur", kab: 290, prop: 23 },
  { value: 239, label: "Agats", kab: 291, prop: 24 },
  { value: 240, label: "Akat", kab: 291, prop: 24 },
  { value: 241, label: "Atsy / Atsj", kab: 291, prop: 24 },
  { value: 242, label: "Ayip", kab: 291, prop: 24 },
  { value: 243, label: "Betcbamu", kab: 291, prop: 24 },
  { value: 244, label: "Der Koumur", kab: 291, prop: 24 },
  { value: 245, label: "Fayit", kab: 291, prop: 24 },
  { value: 246, label: "Jetsy", kab: 291, prop: 24 },
  { value: 247, label: "Joerat", kab: 291, prop: 24 },
  { value: 248, label: "Kolf Braza", kab: 291, prop: 24 },
  { value: 249, label: "Kopay", kab: 291, prop: 24 },
  { value: 250, label: "Pantai Kasuari", kab: 291, prop: 24 },
  { value: 251, label: "Pulau Tiga", kab: 291, prop: 24 },
  { value: 252, label: "Safan", kab: 291, prop: 24 },
  { value: 253, label: "Sawa Erma", kab: 291, prop: 24 },
  { value: 254, label: "Sirets", kab: 291, prop: 24 },
  { value: 255, label: "Suator", kab: 291, prop: 24 },
  { value: 256, label: "Suru-suru", kab: 291, prop: 24 },
  { value: 257, label: "Unir Sirau", kab: 291, prop: 24 },
  { value: 892, label: "Aimando Padaido", kab: 292, prop: 24 },
  { value: 893, label: "Andey (Andei)", kab: 292, prop: 24 },
  { value: 894, label: "Biak Barat", kab: 292, prop: 24 },
  { value: 895, label: "Biak Kota", kab: 292, prop: 24 },
  { value: 896, label: "Biak Timur", kab: 292, prop: 24 },
  { value: 897, label: "Biak Utara", kab: 292, prop: 24 },
  { value: 898, label: "Bondifuar", kab: 292, prop: 24 },
  { value: 899, label: "Bruyadori", kab: 292, prop: 24 },
  { value: 900, label: "Numfor Barat", kab: 292, prop: 24 },
  { value: 901, label: "Numfor Timur", kab: 292, prop: 24 },
  { value: 902, label: "Oridek", kab: 292, prop: 24 },
  { value: 903, label: "Orkeri", kab: 292, prop: 24 },
  { value: 904, label: "Padaido", kab: 292, prop: 24 },
  { value: 905, label: "Poiru", kab: 292, prop: 24 },
  { value: 906, label: "Samofa", kab: 292, prop: 24 },
  { value: 907, label: "Swandiwe", kab: 292, prop: 24 },
  { value: 908, label: "Warsa", kab: 292, prop: 24 },
  { value: 909, label: "Yawosi", kab: 292, prop: 24 },
  { value: 910, label: "Yendidori", kab: 292, prop: 24 },
  { value: 1220, label: "Ambatkwi (Ambatkui)", kab: 293, prop: 24 },
  { value: 1221, label: "Arimop", kab: 293, prop: 24 },
  { value: 1222, label: "Bomakia", kab: 293, prop: 24 },
  { value: 1223, label: "Firiwage", kab: 293, prop: 24 },
  { value: 1224, label: "Fofi", kab: 293, prop: 24 },
  { value: 1225, label: "Iniyandit", kab: 293, prop: 24 },
  { value: 1226, label: "Jair", kab: 293, prop: 24 },
  { value: 1227, label: "Kawagit", kab: 293, prop: 24 },
  { value: 1228, label: "Ki", kab: 293, prop: 24 },
  { value: 1229, label: "Kombay", kab: 293, prop: 24 },
  { value: 1230, label: "Kombut", kab: 293, prop: 24 },
  { value: 1231, label: "Kouh", kab: 293, prop: 24 },
  { value: 1232, label: "Mandobo", kab: 293, prop: 24 },
  { value: 1233, label: "Manggelum", kab: 293, prop: 24 },
  { value: 1234, label: "Mindiptana", kab: 293, prop: 24 },
  { value: 1235, label: "Ninati", kab: 293, prop: 24 },
  { value: 1236, label: "Sesnuk", kab: 293, prop: 24 },
  { value: 1237, label: "Subur", kab: 293, prop: 24 },
  { value: 1238, label: "Waropko", kab: 293, prop: 24 },
  { value: 1239, label: "Yaniruma", kab: 293, prop: 24 },
  { value: 1532, label: "Bowobado", kab: 294, prop: 24 },
  { value: 1533, label: "Kapiraya", kab: 294, prop: 24 },
  { value: 1534, label: "Tigi", kab: 294, prop: 24 },
  { value: 1535, label: "Tigi Barat", kab: 294, prop: 24 },
  { value: 1536, label: "Tigi Timur", kab: 294, prop: 24 },
  { value: 1599, label: "Dogiyai", kab: 295, prop: 24 },
  { value: 1600, label: "Kamu", kab: 295, prop: 24 },
  { value: 1601, label: "Kamu Selatan", kab: 295, prop: 24 },
  { value: 1602, label: "Kamu Timur", kab: 295, prop: 24 },
  { value: 1603, label: "Kamu Utara (Ikrar/Ikrat)", kab: 295, prop: 24 },
  { value: 1604, label: "Mapia", kab: 295, prop: 24 },
  { value: 1605, label: "Mapia Barat", kab: 295, prop: 24 },
  { value: 1606, label: "Mapia Tengah", kab: 295, prop: 24 },
  { value: 1607, label: "Piyaiye (Sukikai)", kab: 295, prop: 24 },
  { value: 1608, label: "Sukikai Selatan", kab: 295, prop: 24 },
  { value: 2081, label: "Agisiga", kab: 296, prop: 24 },
  { value: 2082, label: "Biandoga", kab: 296, prop: 24 },
  { value: 2083, label: "Hitadipa", kab: 296, prop: 24 },
  { value: 2084, label: "Homeo (Homeyo)", kab: 296, prop: 24 },
  { value: 2085, label: "Sugapa", kab: 296, prop: 24 },
  { value: 2086, label: "Wandai", kab: 296, prop: 24 },
  { value: 2137, label: "Airu", kab: 297, prop: 24 },
  { value: 2138, label: "Demta", kab: 297, prop: 24 },
  { value: 2139, label: "Depapre", kab: 297, prop: 24 },
  { value: 2140, label: "Ebungfau (Ebungfa)", kab: 297, prop: 24 },
  { value: 2141, label: "Gresi Selatan", kab: 297, prop: 24 },
  { value: 2142, label: "Kaureh", kab: 297, prop: 24 },
  { value: 2143, label: "Kemtuk", kab: 297, prop: 24 },
  { value: 2144, label: "Kemtuk Gresi", kab: 297, prop: 24 },
  { value: 2145, label: "Nambluong", kab: 297, prop: 24 },
  { value: 2146, label: "Nimbokrang", kab: 297, prop: 24 },
  { value: 2147, label: "Nimboran", kab: 297, prop: 24 },
  { value: 2148, label: "Ravenirara", kab: 297, prop: 24 },
  { value: 2149, label: "Sentani", kab: 297, prop: 24 },
  { value: 2150, label: "Sentani Barat", kab: 297, prop: 24 },
  { value: 2151, label: "Sentani Timur", kab: 297, prop: 24 },
  { value: 2152, label: "Unurum Guay", kab: 297, prop: 24 },
  { value: 2153, label: "Waibu", kab: 297, prop: 24 },
  { value: 2154, label: "Yapsi", kab: 297, prop: 24 },
  { value: 2155, label: "Yokari", kab: 297, prop: 24 },
  { value: 2156, label: "Abepura", kab: 297, prop: 24 },
  { value: 2157, label: "Heram", kab: 297, prop: 24 },
  { value: 2158, label: "Jayapura Selatan", kab: 297, prop: 24 },
  { value: 2159, label: "Jayapura Utara", kab: 297, prop: 24 },
  { value: 2160, label: "Muara Tami", kab: 297, prop: 24 },
  { value: 2161, label: "Asologaima (Asalogaima)", kab: 298, prop: 24 },
  { value: 2162, label: "Asolokobal", kab: 298, prop: 24 },
  { value: 2163, label: "Asotipo", kab: 298, prop: 24 },
  { value: 2164, label: "Bolakme", kab: 298, prop: 24 },
  { value: 2165, label: "Bpiri", kab: 298, prop: 24 },
  { value: 2166, label: "Bugi", kab: 298, prop: 24 },
  { value: 2167, label: "Hubikiak", kab: 298, prop: 24 },
  { value: 2168, label: "Hubikosi (Hobikosi)", kab: 298, prop: 24 },
  { value: 2169, label: "Ibele", kab: 298, prop: 24 },
  { value: 2170, label: "Itlay Hisage", kab: 298, prop: 24 },
  { value: 2171, label: "Koragi", kab: 298, prop: 24 },
  { value: 2172, label: "Kurulu", kab: 298, prop: 24 },
  { value: 2173, label: "Libarek", kab: 298, prop: 24 },
  { value: 2174, label: "Maima", kab: 298, prop: 24 },
  { value: 2175, label: "Molagalome", kab: 298, prop: 24 },
  { value: 2176, label: "Muliama", kab: 298, prop: 24 },
  { value: 2177, label: "Musatfak", kab: 298, prop: 24 },
  { value: 2178, label: "Napua", kab: 298, prop: 24 },
  { value: 2179, label: "Pelebaga", kab: 298, prop: 24 },
  { value: 2180, label: "Piramid", kab: 298, prop: 24 },
  { value: 2181, label: "Pisugi", kab: 298, prop: 24 },
  { value: 2182, label: "Popugoba", kab: 298, prop: 24 },
  { value: 2183, label: "Siepkosi", kab: 298, prop: 24 },
  { value: 2184, label: "Silo Karno Doga", kab: 298, prop: 24 },
  { value: 2185, label: "Taelarek", kab: 298, prop: 24 },
  { value: 2186, label: "Tagime", kab: 298, prop: 24 },
  { value: 2187, label: "Tagineri", kab: 298, prop: 24 },
  { value: 2188, label: "Trikora", kab: 298, prop: 24 },
  { value: 2189, label: "Usilimo", kab: 298, prop: 24 },
  { value: 2190, label: "Wadangku", kab: 298, prop: 24 },
  { value: 2191, label: "Walaik", kab: 298, prop: 24 },
  { value: 2192, label: "Walelagama", kab: 298, prop: 24 },
  { value: 2193, label: "Wame", kab: 298, prop: 24 },
  { value: 2194, label: "Wamena", kab: 298, prop: 24 },
  { value: 2195, label: "Welesi", kab: 298, prop: 24 },
  { value: 2196, label: "Wesaput", kab: 298, prop: 24 },
  { value: 2197, label: "Wita Waya", kab: 298, prop: 24 },
  { value: 2198, label: "Wollo (Wolo)", kab: 298, prop: 24 },
  { value: 2199, label: "Wouma", kab: 298, prop: 24 },
  { value: 2200, label: "Yalengga", kab: 298, prop: 24 },
  { value: 2526, label: "Arso", kab: 299, prop: 24 },
  { value: 2527, label: "Arso Timur", kab: 299, prop: 24 },
  { value: 2528, label: "Senggi", kab: 299, prop: 24 },
  { value: 2529, label: "Skamto (Skanto)", kab: 299, prop: 24 },
  { value: 2530, label: "Towe", kab: 299, prop: 24 },
  { value: 2531, label: "Waris", kab: 299, prop: 24 },
  { value: 2532, label: "Web", kab: 299, prop: 24 },
  { value: 2672, label: "Angkaisera", kab: 300, prop: 24 },
  { value: 2673, label: "Kepulauan Ambai", kab: 300, prop: 24 },
  { value: 2674, label: "Kosiwo", kab: 300, prop: 24 },
  { value: 2675, label: "Poom", kab: 300, prop: 24 },
  { value: 2676, label: "Pulau Kurudu", kab: 300, prop: 24 },
  { value: 2677, label: "Pulau Yerui", kab: 300, prop: 24 },
  { value: 2678, label: "Raimbawi", kab: 300, prop: 24 },
  { value: 2679, label: "Teluk Ampimoi", kab: 300, prop: 24 },
  { value: 2680, label: "Windesi", kab: 300, prop: 24 },
  { value: 2681, label: "Wonawa", kab: 300, prop: 24 },
  { value: 2682, label: "Yapen Barat", kab: 300, prop: 24 },
  { value: 2683, label: "Yapen Selatan", kab: 300, prop: 24 },
  { value: 2684, label: "Yapen Timur", kab: 300, prop: 24 },
  { value: 2685, label: "Yapen Utara", kab: 300, prop: 24 },
  { value: 3288, label: "Balingga", kab: 301, prop: 24 },
  { value: 3289, label: "Dimba", kab: 301, prop: 24 },
  { value: 3290, label: "Gamelia", kab: 301, prop: 24 },
  { value: 3291, label: "Kuyawage", kab: 301, prop: 24 },
  { value: 3292, label: "Makki (Maki)", kab: 301, prop: 24 },
  { value: 3293, label: "Malagaineri (Malagineri)", kab: 301, prop: 24 },
  { value: 3294, label: "Pirime", kab: 301, prop: 24 },
  { value: 3295, label: "Poga", kab: 301, prop: 24 },
  { value: 3296, label: "Tiom", kab: 301, prop: 24 },
  { value: 3297, label: "Tiomneri", kab: 301, prop: 24 },
  { value: 3727, label: "Benuki", kab: 302, prop: 24 },
  { value: 3728, label: "Mamberamo Hilir/Ilir", kab: 302, prop: 24 },
  { value: 3729, label: "Mamberamo Hulu/Ulu", kab: 302, prop: 24 },
  { value: 3730, label: "Mamberamo Tengah", kab: 302, prop: 24 },
  { value: 3731, label: "Mamberamo Tengah Timur", kab: 302, prop: 24 },
  { value: 3732, label: "Rofaer (Rufaer)", kab: 302, prop: 24 },
  { value: 3733, label: "Sawai", kab: 302, prop: 24 },
  { value: 3734, label: "Waropen Atas", kab: 302, prop: 24 },
  { value: 3735, label: "Eragayam", kab: 303, prop: 24 },
  { value: 3736, label: "Ilugwa", kab: 303, prop: 24 },
  { value: 3737, label: "Kelila", kab: 303, prop: 24 },
  { value: 3738, label: "Kobakma", kab: 303, prop: 24 },
  { value: 3739, label: "Megabilis (Megambilis)", kab: 303, prop: 24 },
  { value: 3847, label: "Assue", kab: 304, prop: 24 },
  { value: 3848, label: "Bamgi", kab: 304, prop: 24 },
  { value: 3849, label: "Citakmitak", kab: 304, prop: 24 },
  { value: 3850, label: "Edera", kab: 304, prop: 24 },
  { value: 3851, label: "Haju", kab: 304, prop: 24 },
  { value: 3852, label: "Kaibar", kab: 304, prop: 24 },
  { value: 3853, label: "Minyamur", kab: 304, prop: 24 },
  { value: 3854, label: "Nambioman Bapai (Mambioman)", kab: 304, prop: 24 },
  { value: 3855, label: "Obaa", kab: 304, prop: 24 },
  { value: 3856, label: "Passue", kab: 304, prop: 24 },
  { value: 3857, label: "Passue Bawah", kab: 304, prop: 24 },
  { value: 3858, label: "Syahcame", kab: 304, prop: 24 },
  { value: 3859, label: "Ti Zain", kab: 304, prop: 24 },
  { value: 3860, label: "Venaha", kab: 304, prop: 24 },
  { value: 3861, label: "Yakomi", kab: 304, prop: 24 },
  { value: 3962, label: "Animha", kab: 305, prop: 24 },
  { value: 3963, label: "Eligobel", kab: 305, prop: 24 },
  { value: 3964, label: "Ilyawab", kab: 305, prop: 24 },
  { value: 3965, label: "Jagebob", kab: 305, prop: 24 },
  { value: 3966, label: "Kaptel", kab: 305, prop: 24 },
  { value: 3967, label: "Kimaam", kab: 305, prop: 24 },
  { value: 3968, label: "Kurik", kab: 305, prop: 24 },
  { value: 3969, label: "Malind", kab: 305, prop: 24 },
  { value: 3970, label: "Merauke", kab: 305, prop: 24 },
  { value: 3971, label: "Muting", kab: 305, prop: 24 },
  { value: 3972, label: "Naukenjerai", kab: 305, prop: 24 },
  { value: 3973, label: "Ngguti (Nggunti)", kab: 305, prop: 24 },
  { value: 3974, label: "Okaba", kab: 305, prop: 24 },
  { value: 3975, label: "Semangga", kab: 305, prop: 24 },
  { value: 3976, label: "Sota", kab: 305, prop: 24 },
  { value: 3977, label: "Tabonji", kab: 305, prop: 24 },
  { value: 3978, label: "Tanah Miring", kab: 305, prop: 24 },
  { value: 3979, label: "Tubang", kab: 305, prop: 24 },
  { value: 3980, label: "Ulilin", kab: 305, prop: 24 },
  { value: 3981, label: "Waan", kab: 305, prop: 24 },
  { value: 3994, label: "Agimuga", kab: 306, prop: 24 },
  { value: 3995, label: "Jila", kab: 306, prop: 24 },
  { value: 3996, label: "Jita", kab: 306, prop: 24 },
  { value: 3997, label: "Kuala Kencana", kab: 306, prop: 24 },
  { value: 3998, label: "Mimika Barat (Mibar)", kab: 306, prop: 24 },
  { value: 3999, label: "Mimika Barat Jauh", kab: 306, prop: 24 },
  { value: 4000, label: "Mimika Barat Tengah", kab: 306, prop: 24 },
  { value: 4001, label: "Mimika Baru", kab: 306, prop: 24 },
  { value: 4002, label: "Mimika Timur", kab: 306, prop: 24 },
  { value: 4003, label: "Mimika Timur Jauh", kab: 306, prop: 24 },
  { value: 4004, label: "Mimika Timur Tengah", kab: 306, prop: 24 },
  { value: 4005, label: "Tembagapura", kab: 306, prop: 24 },
  { value: 4235, label: "Dipa", kab: 307, prop: 24 },
  { value: 4236, label: "Makimi", kab: 307, prop: 24 },
  { value: 4237, label: "Menou", kab: 307, prop: 24 },
  { value: 4238, label: "Moora", kab: 307, prop: 24 },
  { value: 4239, label: "Nabire", kab: 307, prop: 24 },
  { value: 4240, label: "Nabire Barat", kab: 307, prop: 24 },
  { value: 4241, label: "Napan", kab: 307, prop: 24 },
  { value: 4242, label: "Siriwo", kab: 307, prop: 24 },
  { value: 4243, label: "Teluk Kimi", kab: 307, prop: 24 },
  { value: 4244, label: "Teluk Umar", kab: 307, prop: 24 },
  { value: 4245, label: "Uwapa", kab: 307, prop: 24 },
  { value: 4246, label: "Wanggar", kab: 307, prop: 24 },
  { value: 4247, label: "Wapoga", kab: 307, prop: 24 },
  { value: 4248, label: "Yaro (Yaro Kabisay)", kab: 307, prop: 24 },
  { value: 4249, label: "Yaur", kab: 307, prop: 24 },
  { value: 4279, label: "Alama", kab: 308, prop: 24 },
  { value: 4280, label: "Dal", kab: 308, prop: 24 },
  { value: 4281, label: "Embetpen", kab: 308, prop: 24 },
  { value: 4282, label: "Gearek", kab: 308, prop: 24 },
  { value: 4283, label: "Geselma (Geselema)", kab: 308, prop: 24 },
  { value: 4284, label: "Inikgal", kab: 308, prop: 24 },
  { value: 4285, label: "Iniye", kab: 308, prop: 24 },
  { value: 4286, label: "Kegayem", kab: 308, prop: 24 },
  { value: 4287, label: "Kenyam", kab: 308, prop: 24 },
  { value: 4288, label: "Kilmid", kab: 308, prop: 24 },
  { value: 4289, label: "Kora", kab: 308, prop: 24 },
  { value: 4290, label: "Koroptak", kab: 308, prop: 24 },
  { value: 4291, label: "Krepkuri", kab: 308, prop: 24 },
  { value: 4292, label: "Mam", kab: 308, prop: 24 },
  { value: 4293, label: "Mapenduma", kab: 308, prop: 24 },
  { value: 4294, label: "Mbua (Mbuga)", kab: 308, prop: 24 },
  { value: 4295, label: "Mbua Tengah", kab: 308, prop: 24 },
  { value: 4296, label: "Mbulmu Yalma", kab: 308, prop: 24 },
  { value: 4297, label: "Mebarok", kab: 308, prop: 24 },
  { value: 4298, label: "Moba", kab: 308, prop: 24 },
  { value: 4299, label: "Mugi", kab: 308, prop: 24 },
  { value: 4300, label: "Nenggeagin", kab: 308, prop: 24 },
  { value: 4301, label: "Nirkuri", kab: 308, prop: 24 },
  { value: 4302, label: "Paro", kab: 308, prop: 24 },
  { value: 4303, label: "Pasir Putih", kab: 308, prop: 24 },
  { value: 4304, label: "Pija", kab: 308, prop: 24 },
  { value: 4305, label: "Wosak", kab: 308, prop: 24 },
  { value: 4306, label: "Wusi", kab: 308, prop: 24 },
  { value: 4307, label: "Wutpaga", kab: 308, prop: 24 },
  { value: 4308, label: "Yal", kab: 308, prop: 24 },
  { value: 4309, label: "Yenggelo", kab: 308, prop: 24 },
  { value: 4310, label: "Yigi", kab: 308, prop: 24 },
  { value: 4720, label: "Aradide", kab: 309, prop: 24 },
  { value: 4721, label: "Bibida", kab: 309, prop: 24 },
  { value: 4722, label: "Bogobaida", kab: 309, prop: 24 },
  { value: 4723, label: "Dumadama", kab: 309, prop: 24 },
  { value: 4724, label: "Ekadide", kab: 309, prop: 24 },
  { value: 4725, label: "Kebo", kab: 309, prop: 24 },
  { value: 4726, label: "Paniai Barat", kab: 309, prop: 24 },
  { value: 4727, label: "Paniai Timur", kab: 309, prop: 24 },
  { value: 4728, label: "Siriwo", kab: 309, prop: 24 },
  { value: 4729, label: "Yatamo", kab: 309, prop: 24 },
  { value: 4857, label: "Aboy", kab: 310, prop: 24 },
  { value: 4858, label: "Alemsom", kab: 310, prop: 24 },
  { value: 4859, label: "Awinbon", kab: 310, prop: 24 },
  { value: 4860, label: "Batani", kab: 310, prop: 24 },
  { value: 4861, label: "Batom", kab: 310, prop: 24 },
  { value: 4862, label: "Bime", kab: 310, prop: 24 },
  { value: 4863, label: "Borme", kab: 310, prop: 24 },
  { value: 4864, label: "Eipumek", kab: 310, prop: 24 },
  { value: 4865, label: "Iwur (Okiwur)", kab: 310, prop: 24 },
  { value: 4866, label: "Jetfa", kab: 310, prop: 24 },
  { value: 4867, label: "Kalomdol", kab: 310, prop: 24 },
  { value: 4868, label: "Kawor", kab: 310, prop: 24 },
  { value: 4869, label: "Kiwirok", kab: 310, prop: 24 },
  { value: 4870, label: "Kiwirok Timur", kab: 310, prop: 24 },
  { value: 4871, label: "Mofinop", kab: 310, prop: 24 },
  { value: 4872, label: "Murkim", kab: 310, prop: 24 },
  { value: 4873, label: "Nongme", kab: 310, prop: 24 },
  { value: 4874, label: "Ok Aom", kab: 310, prop: 24 },
  { value: 4875, label: "Okbab", kab: 310, prop: 24 },
  { value: 4876, label: "Okbape", kab: 310, prop: 24 },
  { value: 4877, label: "Okbemtau", kab: 310, prop: 24 },
  { value: 4878, label: "Okbibab", kab: 310, prop: 24 },
  { value: 4879, label: "Okhika", kab: 310, prop: 24 },
  { value: 4880, label: "Oklip", kab: 310, prop: 24 },
  { value: 4881, label: "Oksamol", kab: 310, prop: 24 },
  { value: 4882, label: "Oksebang", kab: 310, prop: 24 },
  { value: 4883, label: "Oksibil", kab: 310, prop: 24 },
  { value: 4884, label: "Oksop", kab: 310, prop: 24 },
  { value: 4885, label: "Pamek", kab: 310, prop: 24 },
  { value: 4886, label: "Pepera", kab: 310, prop: 24 },
  { value: 4887, label: "Serambakon", kab: 310, prop: 24 },
  { value: 4888, label: "Tarup", kab: 310, prop: 24 },
  { value: 4889, label: "Teiraplu", kab: 310, prop: 24 },
  { value: 4890, label: "Weime", kab: 310, prop: 24 },
  { value: 5183, label: "Agadugume", kab: 311, prop: 24 },
  { value: 5184, label: "Beoga", kab: 311, prop: 24 },
  { value: 5185, label: "Doufu", kab: 311, prop: 24 },
  { value: 5186, label: "Gome", kab: 311, prop: 24 },
  { value: 5187, label: "Ilaga", kab: 311, prop: 24 },
  { value: 5188, label: "Pogoma", kab: 311, prop: 24 },
  { value: 5189, label: "Sinak", kab: 311, prop: 24 },
  { value: 5190, label: "Wangbe", kab: 311, prop: 24 },
  { value: 5191, label: "Fawi", kab: 312, prop: 24 },
  { value: 5192, label: "Ilu", kab: 312, prop: 24 },
  { value: 5193, label: "Jigonikme", kab: 312, prop: 24 },
  { value: 5194, label: "Mewoluk (Mewulok)", kab: 312, prop: 24 },
  { value: 5195, label: "Mulia", kab: 312, prop: 24 },
  { value: 5196, label: "Tingginambut", kab: 312, prop: 24 },
  { value: 5197, label: "Torere", kab: 312, prop: 24 },
  { value: 5198, label: "Yamo", kab: 312, prop: 24 },
  { value: 5423, label: "Apawer Hulu", kab: 313, prop: 24 },
  { value: 5424, label: "Bonggo", kab: 313, prop: 24 },
  { value: 5425, label: "Bonggo Timur", kab: 313, prop: 24 },
  { value: 5426, label: "Pantai Barat", kab: 313, prop: 24 },
  { value: 5427, label: "Pantai Timur", kab: 313, prop: 24 },
  { value: 5428, label: "Pantai Timur Barat", kab: 313, prop: 24 },
  { value: 5429, label: "Sarmi", kab: 313, prop: 24 },
  { value: 5430, label: "Sarmi Selatan", kab: 313, prop: 24 },
  { value: 5431, label: "Sarmi Timur", kab: 313, prop: 24 },
  { value: 5432, label: "Tor Atas", kab: 313, prop: 24 },
  { value: 6126, label: "Kepulauan Aruri", kab: 314, prop: 24 },
  { value: 6127, label: "Supiori Barat", kab: 314, prop: 24 },
  { value: 6128, label: "Supiori Selatan", kab: 314, prop: 24 },
  { value: 6129, label: "Supiori Timur", kab: 314, prop: 24 },
  { value: 6130, label: "Supiori Utara", kab: 314, prop: 24 },
  { value: 6687, label: "Airgaram", kab: 315, prop: 24 },
  { value: 6688, label: "Anawi", kab: 315, prop: 24 },
  { value: 6689, label: "Aweku", kab: 315, prop: 24 },
  { value: 6690, label: "Bewani", kab: 315, prop: 24 },
  { value: 6691, label: "Biuk", kab: 315, prop: 24 },
  { value: 6692, label: "Bogonuk", kab: 315, prop: 24 },
  { value: 6693, label: "Bokondini", kab: 315, prop: 24 },
  { value: 6694, label: "Bokoneri", kab: 315, prop: 24 },
  { value: 6695, label: "Danime", kab: 315, prop: 24 },
  { value: 6696, label: "Dow", kab: 315, prop: 24 },
  { value: 6697, label: "Dundu (Ndundu)", kab: 315, prop: 24 },
  { value: 6698, label: "Egiam", kab: 315, prop: 24 },
  { value: 6699, label: "Geya", kab: 315, prop: 24 },
  { value: 6700, label: "Gika", kab: 315, prop: 24 },
  {
    value: 6701,
    label: "Gilubandu (Gilumbandu/Gilimbandu)",
    kab: 315,
    prop: 24,
  },
  { value: 6702, label: "Goyage", kab: 315, prop: 24 },
  { value: 6703, label: "Gundagi (Gudage)", kab: 315, prop: 24 },
  { value: 6704, label: "Kai", kab: 315, prop: 24 },
  { value: 6705, label: "Kamboneri", kab: 315, prop: 24 },
  { value: 6706, label: "Kanggime (Kanggima )", kab: 315, prop: 24 },
  { value: 6707, label: "Karubaga", kab: 315, prop: 24 },
  { value: 6708, label: "Kembu", kab: 315, prop: 24 },
  { value: 6709, label: "Kondaga (Konda)", kab: 315, prop: 24 },
  { value: 6710, label: "Kuari", kab: 315, prop: 24 },
  { value: 6711, label: "Kubu", kab: 315, prop: 24 },
  { value: 6712, label: "Li Anogomma", kab: 315, prop: 24 },
  { value: 6713, label: "Nabunage", kab: 315, prop: 24 },
  { value: 6714, label: "Nelawi", kab: 315, prop: 24 },
  { value: 6715, label: "Numba", kab: 315, prop: 24 },
  { value: 6716, label: "Nunggawi (Munggawi)", kab: 315, prop: 24 },
  { value: 6717, label: "Panaga", kab: 315, prop: 24 },
  { value: 6718, label: "Poganeri", kab: 315, prop: 24 },
  { value: 6719, label: "Tagime", kab: 315, prop: 24 },
  { value: 6720, label: "Tagineri", kab: 315, prop: 24 },
  { value: 6721, label: "Telenggeme", kab: 315, prop: 24 },
  { value: 6722, label: "Timori", kab: 315, prop: 24 },
  { value: 6723, label: "Umagi", kab: 315, prop: 24 },
  { value: 6724, label: "Wakuwo", kab: 315, prop: 24 },
  { value: 6725, label: "Wari (Taiyeve)", kab: 315, prop: 24 },
  { value: 6726, label: "Wenam", kab: 315, prop: 24 },
  { value: 6727, label: "Wina", kab: 315, prop: 24 },
  { value: 6728, label: "Wonoki (Woniki)", kab: 315, prop: 24 },
  { value: 6729, label: "Wugi", kab: 315, prop: 24 },
  { value: 6730, label: "Wunin (Wumin)", kab: 315, prop: 24 },
  { value: 6731, label: "Yuko", kab: 315, prop: 24 },
  { value: 6732, label: "Yuneri", kab: 315, prop: 24 },
  { value: 6862, label: "Demba Masirei", kab: 316, prop: 24 },
  { value: 6863, label: "Inggerus", kab: 316, prop: 24 },
  { value: 6864, label: "Kirihi", kab: 316, prop: 24 },
  { value: 6865, label: "Masirei", kab: 316, prop: 24 },
  { value: 6866, label: "Oudate Waropen", kab: 316, prop: 24 },
  { value: 6867, label: "Risei Sayati", kab: 316, prop: 24 },
  { value: 6868, label: "Ureifasei", kab: 316, prop: 24 },
  { value: 6869, label: "Wapoga Inggerus", kab: 316, prop: 24 },
  { value: 6870, label: "Waropen Bawah", kab: 316, prop: 24 },
  { value: 6925, label: "Amuma", kab: 317, prop: 24 },
  { value: 6926, label: "Anggruk", kab: 317, prop: 24 },
  { value: 6927, label: "Bomela", kab: 317, prop: 24 },
  { value: 6928, label: "Dekai", kab: 317, prop: 24 },
  { value: 6929, label: "Dirwemna (Diruwena)", kab: 317, prop: 24 },
  { value: 6930, label: "Duram", kab: 317, prop: 24 },
  { value: 6931, label: "Endomen", kab: 317, prop: 24 },
  { value: 6932, label: "Hereapini (Hereanini)", kab: 317, prop: 24 },
  { value: 6933, label: "Hilipuk", kab: 317, prop: 24 },
  { value: 6934, label: "Hogio (Hugio)", kab: 317, prop: 24 },
  { value: 6935, label: "Holuon", kab: 317, prop: 24 },
  { value: 6936, label: "Kabianggama (Kabianggema)", kab: 317, prop: 24 },
  { value: 6937, label: "Kayo", kab: 317, prop: 24 },
  { value: 6938, label: "Kona", kab: 317, prop: 24 },
  { value: 6939, label: "Koropun (Korupun)", kab: 317, prop: 24 },
  { value: 6940, label: "Kosarek", kab: 317, prop: 24 },
  { value: 6941, label: "Kurima", kab: 317, prop: 24 },
  { value: 6942, label: "Kwelemdua (Kwelamdua)", kab: 317, prop: 24 },
  { value: 6943, label: "Kwikma", kab: 317, prop: 24 },
  { value: 6944, label: "Langda", kab: 317, prop: 24 },
  { value: 6945, label: "Lolat", kab: 317, prop: 24 },
  { value: 6946, label: "Mugi", kab: 317, prop: 24 },
  { value: 6947, label: "Musaik", kab: 317, prop: 24 },
  { value: 6948, label: "Nalca", kab: 317, prop: 24 },
  { value: 6949, label: "Ninia", kab: 317, prop: 24 },
  { value: 6950, label: "Nipsan", kab: 317, prop: 24 },
  { value: 6951, label: "Obio", kab: 317, prop: 24 },
  { value: 6952, label: "Panggema", kab: 317, prop: 24 },
  { value: 6953, label: "Pasema", kab: 317, prop: 24 },
  { value: 6954, label: "Pronggoli (Proggoli)", kab: 317, prop: 24 },
  { value: 6955, label: "Puldama", kab: 317, prop: 24 },
  { value: 6956, label: "Samenage", kab: 317, prop: 24 },
  { value: 6957, label: "Sela", kab: 317, prop: 24 },
  { value: 6958, label: "Seredela (Seredala)", kab: 317, prop: 24 },
  { value: 6959, label: "Silimo", kab: 317, prop: 24 },
  { value: 6960, label: "Soba", kab: 317, prop: 24 },
  { value: 6961, label: "Sobaham", kab: 317, prop: 24 },
  { value: 6962, label: "Soloikma", kab: 317, prop: 24 },
  { value: 6963, label: "Sumo", kab: 317, prop: 24 },
  { value: 6964, label: "Suntamon", kab: 317, prop: 24 },
  { value: 6965, label: "Suru Suru", kab: 317, prop: 24 },
  { value: 6966, label: "Talambo", kab: 317, prop: 24 },
  { value: 6967, label: "Tangma", kab: 317, prop: 24 },
  { value: 6968, label: "Ubahak", kab: 317, prop: 24 },
  { value: 6969, label: "Ubalihi", kab: 317, prop: 24 },
  { value: 6970, label: "Ukha", kab: 317, prop: 24 },
  { value: 6971, label: "Walma", kab: 317, prop: 24 },
  { value: 6972, label: "Werima", kab: 317, prop: 24 },
  { value: 6973, label: "Wusuma", kab: 317, prop: 24 },
  { value: 6974, label: "Yahuliambut", kab: 317, prop: 24 },
  { value: 6975, label: "Yogosem", kab: 317, prop: 24 },
  { value: 6976, label: "Abenaho", kab: 318, prop: 24 },
  { value: 6977, label: "Apalapsili", kab: 318, prop: 24 },
  { value: 6978, label: "Benawa", kab: 318, prop: 24 },
  { value: 6979, label: "Elelim", kab: 318, prop: 24 },
  { value: 6980, label: "Welarek", kab: 318, prop: 24 },
  { value: 1683, label: "Bombarai (Bomberay)", kab: 319, prop: 25 },
  { value: 1684, label: "Fakfak", kab: 319, prop: 25 },
  { value: 1685, label: "Fakfak Barat", kab: 319, prop: 25 },
  { value: 1686, label: "Fakfak Tengah", kab: 319, prop: 25 },
  { value: 1687, label: "Fakfak Timur", kab: 319, prop: 25 },
  { value: 1688, label: "Karas", kab: 319, prop: 25 },
  { value: 1689, label: "Kokas", kab: 319, prop: 25 },
  { value: 1690, label: "Kramongmongga (Kramamongga)", kab: 319, prop: 25 },
  { value: 1691, label: "Teluk Patipi", kab: 319, prop: 25 },
  { value: 2285, label: "Buruway", kab: 320, prop: 25 },
  { value: 2286, label: "Kaimana", kab: 320, prop: 25 },
  { value: 2287, label: "Kambraw (Kamberau)", kab: 320, prop: 25 },
  { value: 2288, label: "Teluk Arguni Atas", kab: 320, prop: 25 },
  { value: 2289, label: "Teluk Arguni Bawah (Yerusi)", kab: 320, prop: 25 },
  { value: 2290, label: "Teluk Etna", kab: 320, prop: 25 },
  { value: 2291, label: "Yamor", kab: 320, prop: 25 },
  { value: 3832, label: "Manokwari Barat", kab: 321, prop: 25 },
  { value: 3833, label: "Manokwari Selatan", kab: 321, prop: 25 },
  { value: 3834, label: "Manokwari Timur", kab: 321, prop: 25 },
  { value: 3835, label: "Manokwari Utara", kab: 321, prop: 25 },
  { value: 3836, label: "Masni", kab: 321, prop: 25 },
  { value: 3837, label: "Prafi", kab: 321, prop: 25 },
  { value: 3838, label: "Sidey", kab: 321, prop: 25 },
  { value: 3839, label: "Tanah Rubuh", kab: 321, prop: 25 },
  { value: 3840, label: "Warmare", kab: 321, prop: 25 },
  { value: 3841, label: "Dataran Isim", kab: 322, prop: 25 },
  { value: 3842, label: "Momi Waren", kab: 322, prop: 25 },
  { value: 3843, label: "Neney (Nenei)", kab: 322, prop: 25 },
  { value: 3844, label: "Oransbari", kab: 322, prop: 25 },
  { value: 3845, label: "Ransiki", kab: 322, prop: 25 },
  { value: 3846, label: "Tahota (Tohota)", kab: 322, prop: 25 },
  { value: 3882, label: "Aifat", kab: 323, prop: 25 },
  { value: 3883, label: "Aifat Selatan", kab: 323, prop: 25 },
  { value: 3884, label: "Aifat Timur", kab: 323, prop: 25 },
  { value: 3885, label: "Aifat Timur Jauh", kab: 323, prop: 25 },
  { value: 3886, label: "Aifat Timur Selatan", kab: 323, prop: 25 },
  { value: 3887, label: "Aifat Timur Tengah", kab: 323, prop: 25 },
  { value: 3888, label: "Aifat Utara", kab: 323, prop: 25 },
  { value: 3889, label: "Aitinyo", kab: 323, prop: 25 },
  { value: 3890, label: "Aitinyo Barat", kab: 323, prop: 25 },
  { value: 3891, label: "Aitinyo Raya", kab: 323, prop: 25 },
  { value: 3892, label: "Aitinyo Tengah", kab: 323, prop: 25 },
  { value: 3893, label: "Aitinyo Utara", kab: 323, prop: 25 },
  { value: 3894, label: "Ayamaru", kab: 323, prop: 25 },
  { value: 3895, label: "Ayamaru Barat", kab: 323, prop: 25 },
  { value: 3896, label: "Ayamaru Jaya", kab: 323, prop: 25 },
  { value: 3897, label: "Ayamaru Selatan", kab: 323, prop: 25 },
  { value: 3898, label: "Ayamaru Selatan Jaya", kab: 323, prop: 25 },
  { value: 3899, label: "Ayamaru Tengah", kab: 323, prop: 25 },
  { value: 3900, label: "Ayamaru Timur", kab: 323, prop: 25 },
  { value: 3901, label: "Ayamaru Timur Selatan", kab: 323, prop: 25 },
  { value: 3902, label: "Ayamaru Utara", kab: 323, prop: 25 },
  { value: 3903, label: "Ayamaru Utara Timur", kab: 323, prop: 25 },
  { value: 3904, label: "Mare", kab: 323, prop: 25 },
  { value: 3905, label: "Mare Selatan", kab: 323, prop: 25 },
  { value: 4847, label: "Anggi", kab: 324, prop: 25 },
  { value: 4848, label: "Anggi Gida", kab: 324, prop: 25 },
  { value: 4849, label: "Catubouw", kab: 324, prop: 25 },
  { value: 4850, label: "Didohu", kab: 324, prop: 25 },
  { value: 4851, label: "Hingk", kab: 324, prop: 25 },
  { value: 4852, label: "Membey", kab: 324, prop: 25 },
  { value: 4853, label: "Menyambouw (Minyambouw)", kab: 324, prop: 25 },
  { value: 4854, label: "Sururey", kab: 324, prop: 25 },
  { value: 4855, label: "Taige", kab: 324, prop: 25 },
  { value: 4856, label: "Testega", kab: 324, prop: 25 },
  { value: 5250, label: "Ayau", kab: 325, prop: 25 },
  { value: 5251, label: "Batanta Selatan", kab: 325, prop: 25 },
  { value: 5252, label: "Batanta Utara", kab: 325, prop: 25 },
  { value: 5253, label: "Kepulauan Ayau", kab: 325, prop: 25 },
  { value: 5254, label: "Kepulauan Sembilan", kab: 325, prop: 25 },
  { value: 5255, label: "Kofiau", kab: 325, prop: 25 },
  { value: 5256, label: "Kota Waisai", kab: 325, prop: 25 },
  { value: 5257, label: "Meos Mansar", kab: 325, prop: 25 },
  { value: 5258, label: "Misool (Misool Utara)", kab: 325, prop: 25 },
  { value: 5259, label: "Misool Barat", kab: 325, prop: 25 },
  { value: 5260, label: "Misool Selatan", kab: 325, prop: 25 },
  { value: 5261, label: "Misool Timur", kab: 325, prop: 25 },
  { value: 5262, label: "Salawati Barat", kab: 325, prop: 25 },
  { value: 5263, label: "Salawati Tengah", kab: 325, prop: 25 },
  { value: 5264, label: "Salawati Utara (Samate)", kab: 325, prop: 25 },
  { value: 5265, label: "Supnin", kab: 325, prop: 25 },
  { value: 5266, label: "Teluk Mayalibit", kab: 325, prop: 25 },
  { value: 5267, label: "Tiplol Mayalibit", kab: 325, prop: 25 },
  { value: 5268, label: "Waigeo Barat", kab: 325, prop: 25 },
  { value: 5269, label: "Waigeo Barat Kepulauan", kab: 325, prop: 25 },
  { value: 5270, label: "Waigeo Selatan", kab: 325, prop: 25 },
  { value: 5271, label: "Waigeo Timur", kab: 325, prop: 25 },
  { value: 5272, label: "Waigeo Utara", kab: 325, prop: 25 },
  { value: 5273, label: "Warwabomi", kab: 325, prop: 25 },
  { value: 5827, label: "Aimas", kab: 326, prop: 25 },
  { value: 5828, label: "Beraur", kab: 326, prop: 25 },
  { value: 5829, label: "Klabot", kab: 326, prop: 25 },
  { value: 5830, label: "Klamono", kab: 326, prop: 25 },
  { value: 5831, label: "Klaso", kab: 326, prop: 25 },
  { value: 5832, label: "Klawak", kab: 326, prop: 25 },
  { value: 5833, label: "Klayili", kab: 326, prop: 25 },
  { value: 5834, label: "Makbon", kab: 326, prop: 25 },
  { value: 5835, label: "Mariat", kab: 326, prop: 25 },
  { value: 5836, label: "Maudus", kab: 326, prop: 25 },
  { value: 5837, label: "Mayamuk", kab: 326, prop: 25 },
  { value: 5838, label: "Moisegen", kab: 326, prop: 25 },
  { value: 5839, label: "Salawati", kab: 326, prop: 25 },
  { value: 5840, label: "Salawati Selatan", kab: 326, prop: 25 },
  { value: 5841, label: "Sayosa", kab: 326, prop: 25 },
  { value: 5842, label: "Seget", kab: 326, prop: 25 },
  { value: 5843, label: "Segun", kab: 326, prop: 25 },
  { value: 5844, label: "Sorong", kab: 326, prop: 25 },
  { value: 5845, label: "Sorong Barat", kab: 326, prop: 25 },
  { value: 5846, label: "Sorong Kepulauan", kab: 326, prop: 25 },
  { value: 5847, label: "Sorong Manoi", kab: 326, prop: 25 },
  { value: 5848, label: "Sorong Timur", kab: 326, prop: 25 },
  { value: 5849, label: "Sorong Utara", kab: 326, prop: 25 },
  { value: 5850, label: "Fokour", kab: 327, prop: 25 },
  { value: 5851, label: "Inanwatan (Inawatan)", kab: 327, prop: 25 },
  { value: 5852, label: "Kais (Matemani Kais)", kab: 327, prop: 25 },
  { value: 5853, label: "Kokoda", kab: 327, prop: 25 },
  { value: 5854, label: "Kokoda Utara", kab: 327, prop: 25 },
  { value: 5855, label: "Konda", kab: 327, prop: 25 },
  { value: 5856, label: "Matemani", kab: 327, prop: 25 },
  { value: 5857, label: "Moswaren", kab: 327, prop: 25 },
  { value: 5858, label: "Saifi", kab: 327, prop: 25 },
  { value: 5859, label: "Sawiat", kab: 327, prop: 25 },
  { value: 5860, label: "Seremuk", kab: 327, prop: 25 },
  { value: 5861, label: "Teminabuan", kab: 327, prop: 25 },
  { value: 5862, label: "Wayer", kab: 327, prop: 25 },
  { value: 6198, label: "Abun", kab: 328, prop: 25 },
  { value: 6199, label: "Amberbaken", kab: 328, prop: 25 },
  { value: 6200, label: "Fef (Peef)", kab: 328, prop: 25 },
  { value: 6201, label: "Kebar", kab: 328, prop: 25 },
  { value: 6202, label: "Kwoor", kab: 328, prop: 25 },
  { value: 6203, label: "Miyah (Meyah)", kab: 328, prop: 25 },
  { value: 6204, label: "Moraid", kab: 328, prop: 25 },
  { value: 6205, label: "Mubrani", kab: 328, prop: 25 },
  { value: 6206, label: "Sausapor", kab: 328, prop: 25 },
  { value: 6207, label: "Senopi", kab: 328, prop: 25 },
  { value: 6208, label: "Syujak", kab: 328, prop: 25 },
  { value: 6209, label: "Yembun", kab: 328, prop: 25 },
  { value: 6524, label: "Aranday", kab: 329, prop: 25 },
  { value: 6525, label: "Aroba", kab: 329, prop: 25 },
  { value: 6526, label: "Babo", kab: 329, prop: 25 },
  { value: 6527, label: "Bintuni", kab: 329, prop: 25 },
  { value: 6528, label: "Biscoop", kab: 329, prop: 25 },
  { value: 6529, label: "Dataran Beimes", kab: 329, prop: 25 },
  { value: 6530, label: "Fafurwar (Irorutu)", kab: 329, prop: 25 },
  { value: 6531, label: "Kaitaro", kab: 329, prop: 25 },
  { value: 6532, label: "Kamundan", kab: 329, prop: 25 },
  { value: 6533, label: "Kuri", kab: 329, prop: 25 },
  { value: 6534, label: "Manimeri", kab: 329, prop: 25 },
  { value: 6535, label: "Masyeta", kab: 329, prop: 25 },
  { value: 6536, label: "Mayado", kab: 329, prop: 25 },
  { value: 6537, label: "Merdey", kab: 329, prop: 25 },
  { value: 6538, label: "Moskona Barat", kab: 329, prop: 25 },
  { value: 6539, label: "Moskona Selatan", kab: 329, prop: 25 },
  { value: 6540, label: "Moskona Timur", kab: 329, prop: 25 },
  { value: 6541, label: "Moskona Utara", kab: 329, prop: 25 },
  { value: 6542, label: "Sumuri (Simuri)", kab: 329, prop: 25 },
  { value: 6543, label: "Tembuni", kab: 329, prop: 25 },
  { value: 6544, label: "Tomu", kab: 329, prop: 25 },
  { value: 6545, label: "Tuhiba", kab: 329, prop: 25 },
  { value: 6546, label: "Wamesa (Idoor)", kab: 329, prop: 25 },
  { value: 6547, label: "Weriagar", kab: 329, prop: 25 },
  { value: 6548, label: "Kuri Wamesa", kab: 330, prop: 25 },
  { value: 6549, label: "Naikere (Wasior Barat)", kab: 330, prop: 25 },
  { value: 6550, label: "Nikiwar", kab: 330, prop: 25 },
  { value: 6551, label: "Rasiei", kab: 330, prop: 25 },
  { value: 6552, label: "Roon", kab: 330, prop: 25 },
  { value: 6553, label: "Roswar", kab: 330, prop: 25 },
  { value: 6554, label: "Rumberpon", kab: 330, prop: 25 },
  { value: 6555, label: "Soug Jaya", kab: 330, prop: 25 },
  { value: 6556, label: "Teluk Duairi (Wasior Utara)", kab: 330, prop: 25 },
  { value: 6557, label: "Wamesa", kab: 330, prop: 25 },
  { value: 6558, label: "Wasior", kab: 330, prop: 25 },
  { value: 6559, label: "Windesi", kab: 330, prop: 25 },
  { value: 6560, label: "Wondiboy (Wasior Selatan)", kab: 330, prop: 25 },
  { value: 807, label: "Bantan", kab: 331, prop: 26 },
  { value: 808, label: "Bengkalis", kab: 331, prop: 26 },
  { value: 809, label: "Bukit Batu", kab: 331, prop: 26 },
  { value: 810, label: "Mandau", kab: 331, prop: 26 },
  { value: 811, label: "Pinggir", kab: 331, prop: 26 },
  { value: 812, label: "Rupat", kab: 331, prop: 26 },
  { value: 813, label: "Rupat Utara", kab: 331, prop: 26 },
  { value: 814, label: "Siak Kecil", kab: 331, prop: 26 },
  { value: 1633, label: "Bukit Kapur", kab: 332, prop: 26 },
  { value: 1634, label: "Dumai Barat", kab: 332, prop: 26 },
  { value: 1635, label: "Dumai Kota", kab: 332, prop: 26 },
  { value: 1636, label: "Dumai Selatan", kab: 332, prop: 26 },
  { value: 1637, label: "Dumai Timur", kab: 332, prop: 26 },
  { value: 1638, label: "Medang Kampai", kab: 332, prop: 26 },
  { value: 1639, label: "Sungai Sembilan", kab: 332, prop: 26 },
  { value: 2016, label: "Batang Tuaka", kab: 333, prop: 26 },
  { value: 2017, label: "Concong", kab: 333, prop: 26 },
  { value: 2018, label: "Enok", kab: 333, prop: 26 },
  { value: 2019, label: "Gaung", kab: 333, prop: 26 },
  { value: 2020, label: "Gaung Anak Serka", kab: 333, prop: 26 },
  { value: 2021, label: "Kateman", kab: 333, prop: 26 },
  { value: 2022, label: "Kempas", kab: 333, prop: 26 },
  { value: 2023, label: "Kemuning", kab: 333, prop: 26 },
  { value: 2024, label: "Keritang", kab: 333, prop: 26 },
  { value: 2025, label: "Kuala Indragiri", kab: 333, prop: 26 },
  { value: 2026, label: "Mandah", kab: 333, prop: 26 },
  { value: 2027, label: "Pelangiran", kab: 333, prop: 26 },
  { value: 2028, label: "Pulau Burung", kab: 333, prop: 26 },
  { value: 2029, label: "Reteh", kab: 333, prop: 26 },
  { value: 2030, label: "Sungai Batang", kab: 333, prop: 26 },
  { value: 2031, label: "Tanah Merah", kab: 333, prop: 26 },
  { value: 2032, label: "Teluk Belengkong", kab: 333, prop: 26 },
  { value: 2033, label: "Tembilahan", kab: 333, prop: 26 },
  { value: 2034, label: "Tembilahan Hulu", kab: 333, prop: 26 },
  { value: 2035, label: "Tempuling", kab: 333, prop: 26 },
  { value: 2036, label: "Batang Cenaku", kab: 334, prop: 26 },
  { value: 2037, label: "Batang Gansal", kab: 334, prop: 26 },
  { value: 2038, label: "Batang Peranap", kab: 334, prop: 26 },
  { value: 2039, label: "Kelayang", kab: 334, prop: 26 },
  { value: 2040, label: "Kuala Cenaku", kab: 334, prop: 26 },
  { value: 2041, label: "Lirik", kab: 334, prop: 26 },
  { value: 2042, label: "Lubuk Batu Jaya", kab: 334, prop: 26 },
  { value: 2043, label: "Pasir Penyu", kab: 334, prop: 26 },
  { value: 2044, label: "Peranap", kab: 334, prop: 26 },
  { value: 2045, label: "Rakit Kulim", kab: 334, prop: 26 },
  { value: 2046, label: "Rengat", kab: 334, prop: 26 },
  { value: 2047, label: "Rengat Barat", kab: 334, prop: 26 },
  { value: 2048, label: "Seberida", kab: 334, prop: 26 },
  { value: 2049, label: "Sungai Lala", kab: 334, prop: 26 },
  { value: 2292, label: "Bangkinang", kab: 335, prop: 26 },
  { value: 2293, label: "Bangkinang Seberang", kab: 335, prop: 26 },
  { value: 2294, label: "Gunung Sahilan", kab: 335, prop: 26 },
  { value: 2295, label: "Kampar", kab: 335, prop: 26 },
  { value: 2296, label: "Kampar Kiri", kab: 335, prop: 26 },
  { value: 2297, label: "Kampar Kiri Hilir", kab: 335, prop: 26 },
  { value: 2298, label: "Kampar Kiri Hulu", kab: 335, prop: 26 },
  { value: 2299, label: "Kampar Kiri Tengah", kab: 335, prop: 26 },
  { value: 2300, label: "Kampar Timur", kab: 335, prop: 26 },
  { value: 2301, label: "Kampar Utara", kab: 335, prop: 26 },
  { value: 2302, label: "Koto Kampar Hulu", kab: 335, prop: 26 },
  { value: 2303, label: "Kuok (Bangkinang Barat)", kab: 335, prop: 26 },
  { value: 2304, label: "Perhentian Raja", kab: 335, prop: 26 },
  { value: 2305, label: "Rumbio Jaya", kab: 335, prop: 26 },
  { value: 2306, label: "Salo", kab: 335, prop: 26 },
  { value: 2307, label: "Siak Hulu", kab: 335, prop: 26 },
  { value: 2308, label: "Tambang", kab: 335, prop: 26 },
  { value: 2309, label: "Tapung", kab: 335, prop: 26 },
  { value: 2310, label: "Tapung Hilir", kab: 335, prop: 26 },
  { value: 2311, label: "Tapung Hulu", kab: 335, prop: 26 },
  { value: 2312, label: "XIII Koto Kampar", kab: 335, prop: 26 },
  { value: 2598, label: "Merbau", kab: 336, prop: 26 },
  { value: 2599, label: "Pulaumerbau", kab: 336, prop: 26 },
  { value: 2600, label: "Rangsang", kab: 336, prop: 26 },
  { value: 2601, label: "Rangsang Barat", kab: 336, prop: 26 },
  { value: 2602, label: "Rangsang Pesisir", kab: 336, prop: 26 },
  { value: 2603, label: "Tasik Putri Puyu", kab: 336, prop: 26 },
  { value: 2604, label: "Tebing Tinggi", kab: 336, prop: 26 },
  { value: 2605, label: "Tebing Tinggi Barat", kab: 336, prop: 26 },
  { value: 2606, label: "Tebing Tinggi Timur", kab: 336, prop: 26 },
  { value: 2897, label: "Benai", kab: 337, prop: 26 },
  { value: 2898, label: "Cerenti", kab: 337, prop: 26 },
  { value: 2899, label: "Gunung Toar", kab: 337, prop: 26 },
  { value: 2900, label: "Hulu Kuantan", kab: 337, prop: 26 },
  { value: 2901, label: "Inuman", kab: 337, prop: 26 },
  { value: 2902, label: "Kuantan Hilir", kab: 337, prop: 26 },
  { value: 2903, label: "Kuantan Hilir Seberang", kab: 337, prop: 26 },
  { value: 2904, label: "Kuantan Mudik", kab: 337, prop: 26 },
  { value: 2905, label: "Kuantan Tengah", kab: 337, prop: 26 },
  { value: 2906, label: "Logas Tanah Darat", kab: 337, prop: 26 },
  { value: 2907, label: "Pangean", kab: 337, prop: 26 },
  { value: 2908, label: "Pucuk Rantau", kab: 337, prop: 26 },
  { value: 2909, label: "Sentajo Raya", kab: 337, prop: 26 },
  { value: 2910, label: "Singingi", kab: 337, prop: 26 },
  { value: 2911, label: "Singingi Hilir", kab: 337, prop: 26 },
  { value: 4914, label: "Bukit Raya", kab: 338, prop: 26 },
  { value: 4915, label: "Lima Puluh", kab: 338, prop: 26 },
  { value: 4916, label: "Marpoyan Damai", kab: 338, prop: 26 },
  { value: 4917, label: "Payung Sekaki", kab: 338, prop: 26 },
  { value: 4918, label: "Pekanbaru Kota", kab: 338, prop: 26 },
  { value: 4919, label: "Rumbai", kab: 338, prop: 26 },
  { value: 4920, label: "Rumbai Pesisir", kab: 338, prop: 26 },
  { value: 4921, label: "Sail", kab: 338, prop: 26 },
  { value: 4922, label: "Senapelan", kab: 338, prop: 26 },
  { value: 4923, label: "Sukajadi", kab: 338, prop: 26 },
  { value: 4924, label: "Tampan", kab: 338, prop: 26 },
  { value: 4925, label: "Tenayan Raya", kab: 338, prop: 26 },
  { value: 4926, label: "Bandar Petalangan", kab: 339, prop: 26 },
  { value: 4927, label: "Bandar Sei Kijang", kab: 339, prop: 26 },
  { value: 4928, label: "Bunut", kab: 339, prop: 26 },
  { value: 4929, label: "Kerumutan", kab: 339, prop: 26 },
  { value: 4930, label: "Kuala Kampar", kab: 339, prop: 26 },
  { value: 4931, label: "Langgam", kab: 339, prop: 26 },
  { value: 4932, label: "Pangkalan Kerinci", kab: 339, prop: 26 },
  { value: 4933, label: "Pangkalan Kuras", kab: 339, prop: 26 },
  { value: 4934, label: "Pangkalan Lesung", kab: 339, prop: 26 },
  { value: 4935, label: "Pelalawan", kab: 339, prop: 26 },
  { value: 4936, label: "Teluk Meranti", kab: 339, prop: 26 },
  { value: 4937, label: "Ukui", kab: 339, prop: 26 },
  { value: 5303, label: "Bagan Sinembah", kab: 340, prop: 26 },
  { value: 5304, label: "Bangko", kab: 340, prop: 26 },
  { value: 5305, label: "Bangko Pusaka (Pusako)", kab: 340, prop: 26 },
  { value: 5306, label: "Batu Hampar", kab: 340, prop: 26 },
  { value: 5307, label: "Kubu", kab: 340, prop: 26 },
  { value: 5308, label: "Kubu Babussalam", kab: 340, prop: 26 },
  { value: 5309, label: "Pasir Limau Kapas", kab: 340, prop: 26 },
  { value: 5310, label: "Pekaitan", kab: 340, prop: 26 },
  { value: 5311, label: "Pujud", kab: 340, prop: 26 },
  { value: 5312, label: "Rantau Kopar", kab: 340, prop: 26 },
  { value: 5313, label: "Rimba Melintang", kab: 340, prop: 26 },
  { value: 5314, label: "Simpang Kanan", kab: 340, prop: 26 },
  { value: 5315, label: "Sinaboi (Senaboi)", kab: 340, prop: 26 },
  { value: 5316, label: "Tanah Putih", kab: 340, prop: 26 },
  { value: 5317, label: "Tanah Putih Tanjung Melawan", kab: 340, prop: 26 },
  { value: 5318, label: "Bangun Purba", kab: 341, prop: 26 },
  { value: 5319, label: "Bonai Darussalam", kab: 341, prop: 26 },
  { value: 5320, label: "Kabun", kab: 341, prop: 26 },
  { value: 5321, label: "Kepenuhan", kab: 341, prop: 26 },
  { value: 5322, label: "Kepenuhan Hulu", kab: 341, prop: 26 },
  { value: 5323, label: "Kunto Darussalam", kab: 341, prop: 26 },
  { value: 5324, label: "Pagaran Tapah Darussalam", kab: 341, prop: 26 },
  { value: 5325, label: "Pendalian IV Koto", kab: 341, prop: 26 },
  { value: 5326, label: "Rambah", kab: 341, prop: 26 },
  { value: 5327, label: "Rambah Hilir", kab: 341, prop: 26 },
  { value: 5328, label: "Rambah Samo", kab: 341, prop: 26 },
  { value: 5329, label: "Rokan IV Koto", kab: 341, prop: 26 },
  { value: 5330, label: "Tambusai", kab: 341, prop: 26 },
  { value: 5331, label: "Tambusai Utara", kab: 341, prop: 26 },
  { value: 5332, label: "Tandun", kab: 341, prop: 26 },
  { value: 5333, label: "Ujung Batu", kab: 341, prop: 26 },
  { value: 5602, label: "Bunga Raya", kab: 342, prop: 26 },
  { value: 5603, label: "Dayun", kab: 342, prop: 26 },
  { value: 5604, label: "Kandis", kab: 342, prop: 26 },
  { value: 5605, label: "Kerinci Kanan", kab: 342, prop: 26 },
  { value: 5606, label: "Koto Gasib", kab: 342, prop: 26 },
  { value: 5607, label: "Lubuk Dalam", kab: 342, prop: 26 },
  { value: 5608, label: "Mempura", kab: 342, prop: 26 },
  { value: 5609, label: "Minas", kab: 342, prop: 26 },
  { value: 5610, label: "Pusako", kab: 342, prop: 26 },
  { value: 5611, label: "Sabak Auh", kab: 342, prop: 26 },
  { value: 5612, label: "Siak", kab: 342, prop: 26 },
  { value: 5613, label: "Sungai Apit", kab: 342, prop: 26 },
  { value: 5614, label: "Sungai Mandau", kab: 342, prop: 26 },
  { value: 5615, label: "Tualang", kab: 342, prop: 26 },
  { value: 3579, label: "Banggae", kab: 343, prop: 27 },
  { value: 3580, label: "Banggae Timur", kab: 343, prop: 27 },
  { value: 3581, label: "Malunda", kab: 343, prop: 27 },
  { value: 3582, label: "Pamboang", kab: 343, prop: 27 },
  { value: 3583, label: "Sendana", kab: 343, prop: 27 },
  { value: 3584, label: "Tammeredo Sendana", kab: 343, prop: 27 },
  { value: 3585, label: "Tubo (Tubo Sendana)", kab: 343, prop: 27 },
  { value: 3586, label: "Ulumunda", kab: 343, prop: 27 },
  { value: 3710, label: "Aralle (Arrale)", kab: 344, prop: 27 },
  { value: 3711, label: "Balla", kab: 344, prop: 27 },
  { value: 3712, label: "Bambang", kab: 344, prop: 27 },
  { value: 3713, label: "Buntumalangka", kab: 344, prop: 27 },
  { value: 3714, label: "Mamasa", kab: 344, prop: 27 },
  { value: 3715, label: "Mambi", kab: 344, prop: 27 },
  { value: 3716, label: "Mehalaan", kab: 344, prop: 27 },
  { value: 3717, label: "Messawa", kab: 344, prop: 27 },
  { value: 3718, label: "Nosu", kab: 344, prop: 27 },
  { value: 3719, label: "Pana", kab: 344, prop: 27 },
  { value: 3720, label: "Rantebulahan Timur", kab: 344, prop: 27 },
  { value: 3721, label: "Sesena Padang", kab: 344, prop: 27 },
  { value: 3722, label: "Sumarorong", kab: 344, prop: 27 },
  { value: 3723, label: "Tabang", kab: 344, prop: 27 },
  { value: 3724, label: "Tabulahan", kab: 344, prop: 27 },
  { value: 3725, label: "Tanduk Kalua", kab: 344, prop: 27 },
  { value: 3726, label: "Tawalian", kab: 344, prop: 27 },
  { value: 3740, label: "Bonehau", kab: 345, prop: 27 },
  { value: 3741, label: "Budong-Budong", kab: 345, prop: 27 },
  { value: 3742, label: "Kalukku", kab: 345, prop: 27 },
  { value: 3743, label: "Kalumpang", kab: 345, prop: 27 },
  { value: 3744, label: "Karossa", kab: 345, prop: 27 },
  { value: 3745, label: "Kep. Bala Balakang", kab: 345, prop: 27 },
  { value: 3746, label: "Mamuju", kab: 345, prop: 27 },
  { value: 3747, label: "Pangale", kab: 345, prop: 27 },
  { value: 3748, label: "Papalang", kab: 345, prop: 27 },
  { value: 3749, label: "Sampaga", kab: 345, prop: 27 },
  { value: 3750, label: "Simboro dan Kepulauan", kab: 345, prop: 27 },
  { value: 3751, label: "Tapalang", kab: 345, prop: 27 },
  { value: 3752, label: "Tapalang Barat", kab: 345, prop: 27 },
  { value: 3753, label: "Tobadak", kab: 345, prop: 27 },
  { value: 3754, label: "Tommo", kab: 345, prop: 27 },
  { value: 3755, label: "Topoyo", kab: 345, prop: 27 },
  { value: 3756, label: "Bambaira", kab: 346, prop: 27 },
  { value: 3757, label: "Bambalamotu", kab: 346, prop: 27 },
  { value: 3758, label: "Baras", kab: 346, prop: 27 },
  { value: 3759, label: "Bulu Taba", kab: 346, prop: 27 },
  { value: 3760, label: "Dapurang", kab: 346, prop: 27 },
  { value: 3761, label: "Duripoku", kab: 346, prop: 27 },
  { value: 3762, label: "Lariang", kab: 346, prop: 27 },
  { value: 3763, label: "Pasangkayu", kab: 346, prop: 27 },
  { value: 3764, label: "Pedongga", kab: 346, prop: 27 },
  { value: 3765, label: "Sarjo", kab: 346, prop: 27 },
  { value: 3766, label: "Sarudu", kab: 346, prop: 27 },
  { value: 3767, label: "Tikke Raya", kab: 346, prop: 27 },
  { value: 5055, label: "Alu (Allu)", kab: 347, prop: 27 },
  { value: 5056, label: "Anreapi", kab: 347, prop: 27 },
  { value: 5057, label: "Balanipa", kab: 347, prop: 27 },
  { value: 5058, label: "Binuang", kab: 347, prop: 27 },
  { value: 5059, label: "Bulo", kab: 347, prop: 27 },
  { value: 5060, label: "Campalagian", kab: 347, prop: 27 },
  { value: 5061, label: "Limboro", kab: 347, prop: 27 },
  { value: 5062, label: "Luyo", kab: 347, prop: 27 },
  { value: 5063, label: "Mapilli", kab: 347, prop: 27 },
  { value: 5064, label: "Matakali", kab: 347, prop: 27 },
  { value: 5065, label: "Matangnga", kab: 347, prop: 27 },
  { value: 5066, label: "Polewali", kab: 347, prop: 27 },
  { value: 5067, label: "Tapango", kab: 347, prop: 27 },
  { value: 5068, label: "Tinambung", kab: 347, prop: 27 },
  { value: 5069, label: "Tubbi Taramanu (Tutar/Tutallu)", kab: 347, prop: 27 },
  { value: 5070, label: "Wonomulyo", kab: 347, prop: 27 },
  { value: 529, label: "Bantaeng", kab: 348, prop: 28 },
  { value: 530, label: "Bissappu", kab: 348, prop: 28 },
  { value: 531, label: "Eremerasa", kab: 348, prop: 28 },
  { value: 532, label: "Gantarang Keke (Gantareng Keke)", kab: 348, prop: 28 },
  { value: 533, label: "Pajukukang", kab: 348, prop: 28 },
  { value: 534, label: "Sinoa", kab: 348, prop: 28 },
  { value: 535, label: "Tompobulu", kab: 348, prop: 28 },
  { value: 536, label: "Uluere", kab: 348, prop: 28 },
  { value: 666, label: "Balusu", kab: 349, prop: 28 },
  { value: 667, label: "Barru", kab: 349, prop: 28 },
  { value: 668, label: "Mallusetasi", kab: 349, prop: 28 },
  { value: 669, label: "Pujananting", kab: 349, prop: 28 },
  { value: 670, label: "Soppeng Riaja", kab: 349, prop: 28 },
  { value: 671, label: "Tanete Riaja", kab: 349, prop: 28 },
  { value: 672, label: "Tanete Rilau", kab: 349, prop: 28 },
  { value: 1172, label: "Ajangale", kab: 350, prop: 28 },
  { value: 1173, label: "Amali", kab: 350, prop: 28 },
  { value: 1174, label: "Awangpone", kab: 350, prop: 28 },
  { value: 1175, label: "Barebbo", kab: 350, prop: 28 },
  { value: 1176, label: "Bengo", kab: 350, prop: 28 },
  { value: 1177, label: "Bontocani", kab: 350, prop: 28 },
  { value: 1178, label: "Cenrana", kab: 350, prop: 28 },
  { value: 1179, label: "Cina", kab: 350, prop: 28 },
  { value: 1180, label: "Dua Boccoe", kab: 350, prop: 28 },
  { value: 1181, label: "Kahu", kab: 350, prop: 28 },
  { value: 1182, label: "Kajuara", kab: 350, prop: 28 },
  { value: 1183, label: "Lamuru", kab: 350, prop: 28 },
  { value: 1184, label: "Lappariaja", kab: 350, prop: 28 },
  { value: 1185, label: "Libureng", kab: 350, prop: 28 },
  { value: 1186, label: "Mare", kab: 350, prop: 28 },
  { value: 1187, label: "Palakka", kab: 350, prop: 28 },
  { value: 1188, label: "Patimpeng", kab: 350, prop: 28 },
  { value: 1189, label: "Ponre", kab: 350, prop: 28 },
  { value: 1190, label: "Salomekko", kab: 350, prop: 28 },
  { value: 1191, label: "Sibulue", kab: 350, prop: 28 },
  { value: 1192, label: "Tanete Riattang", kab: 350, prop: 28 },
  { value: 1193, label: "Tanete Riattang Barat", kab: 350, prop: 28 },
  { value: 1194, label: "Tanete Riattang Timur", kab: 350, prop: 28 },
  { value: 1195, label: "Tellu Limpoe", kab: 350, prop: 28 },
  { value: 1196, label: "Tellu Siattinge", kab: 350, prop: 28 },
  { value: 1197, label: "Tonra", kab: 350, prop: 28 },
  { value: 1198, label: "Ulaweng", kab: 350, prop: 28 },
  { value: 1288, label: "Bonto Bahari", kab: 351, prop: 28 },
  { value: 1289, label: "Bontotiro", kab: 351, prop: 28 },
  { value: 1290, label: "Bulukumba (Bulukumpa)", kab: 351, prop: 28 },
  { value: 1291, label: "Gantorang/Gantarang (Gangking)", kab: 351, prop: 28 },
  { value: 1292, label: "Hero Lange-Lange (Herlang)", kab: 351, prop: 28 },
  { value: 1293, label: "Kajang", kab: 351, prop: 28 },
  { value: 1294, label: "Kindang", kab: 351, prop: 28 },
  { value: 1295, label: "Rilau Ale", kab: 351, prop: 28 },
  { value: 1296, label: "Ujung Bulu", kab: 351, prop: 28 },
  { value: 1297, label: "Ujung Loe", kab: 351, prop: 28 },
  { value: 1671, label: "Alla", kab: 352, prop: 28 },
  { value: 1672, label: "Anggeraja", kab: 352, prop: 28 },
  { value: 1673, label: "Baraka", kab: 352, prop: 28 },
  { value: 1674, label: "Baroko", kab: 352, prop: 28 },
  { value: 1675, label: "Bungin", kab: 352, prop: 28 },
  { value: 1676, label: "Buntu Batu", kab: 352, prop: 28 },
  { value: 1677, label: "Cendana", kab: 352, prop: 28 },
  { value: 1678, label: "Curio", kab: 352, prop: 28 },
  { value: 1679, label: "Enrekang", kab: 352, prop: 28 },
  { value: 1680, label: "Maiwa", kab: 352, prop: 28 },
  { value: 1681, label: "Malua", kab: 352, prop: 28 },
  { value: 1682, label: "Masalle", kab: 352, prop: 28 },
  { value: 1810, label: "Bajeng", kab: 353, prop: 28 },
  { value: 1811, label: "Bajeng Barat", kab: 353, prop: 28 },
  { value: 1812, label: "Barombong", kab: 353, prop: 28 },
  { value: 1813, label: "Biringbulu", kab: 353, prop: 28 },
  { value: 1814, label: "Bontolempangang", kab: 353, prop: 28 },
  { value: 1815, label: "Bontomarannu", kab: 353, prop: 28 },
  { value: 1816, label: "Bontonompo", kab: 353, prop: 28 },
  { value: 1817, label: "Bontonompo Selatan", kab: 353, prop: 28 },
  { value: 1818, label: "Bungaya", kab: 353, prop: 28 },
  { value: 1819, label: "Manuju", kab: 353, prop: 28 },
  { value: 1820, label: "Pallangga", kab: 353, prop: 28 },
  { value: 1821, label: "Parangloe", kab: 353, prop: 28 },
  { value: 1822, label: "Parigi", kab: 353, prop: 28 },
  { value: 1823, label: "Pattallassang", kab: 353, prop: 28 },
  { value: 1824, label: "Somba Opu (Upu)", kab: 353, prop: 28 },
  { value: 1825, label: "Tinggimoncong", kab: 353, prop: 28 },
  { value: 1826, label: "Tombolo Pao", kab: 353, prop: 28 },
  { value: 1827, label: "Tompobulu", kab: 353, prop: 28 },
  { value: 2237, label: "Arungkeke", kab: 354, prop: 28 },
  { value: 2238, label: "Bangkala", kab: 354, prop: 28 },
  { value: 2239, label: "Bangkala Barat", kab: 354, prop: 28 },
  { value: 2240, label: "Batang", kab: 354, prop: 28 },
  { value: 2241, label: "Binamu", kab: 354, prop: 28 },
  { value: 2242, label: "Bontoramba", kab: 354, prop: 28 },
  { value: 2243, label: "Kelara", kab: 354, prop: 28 },
  { value: 2244, label: "Rumbia", kab: 354, prop: 28 },
  { value: 2245, label: "Tamalatea", kab: 354, prop: 28 },
  { value: 2246, label: "Tarowang", kab: 354, prop: 28 },
  { value: 2247, label: "Turatea", kab: 354, prop: 28 },
  { value: 3448, label: "Bajo", kab: 355, prop: 28 },
  { value: 3449, label: "Bajo Barat", kab: 355, prop: 28 },
  { value: 3450, label: "Basse Sangtempe Utara", kab: 355, prop: 28 },
  { value: 3451, label: "Bassesang Tempe (Bastem)", kab: 355, prop: 28 },
  { value: 3452, label: "Belopa", kab: 355, prop: 28 },
  { value: 3453, label: "Belopa Utara", kab: 355, prop: 28 },
  { value: 3454, label: "Bua", kab: 355, prop: 28 },
  { value: 3455, label: "Bua Ponrang (Bupon)", kab: 355, prop: 28 },
  { value: 3456, label: "Kamanre", kab: 355, prop: 28 },
  { value: 3457, label: "Lamasi", kab: 355, prop: 28 },
  { value: 3458, label: "Lamasi Timur", kab: 355, prop: 28 },
  { value: 3459, label: "Larompong", kab: 355, prop: 28 },
  { value: 3460, label: "Larompong Selatan", kab: 355, prop: 28 },
  { value: 3461, label: "Latimojong", kab: 355, prop: 28 },
  { value: 3462, label: "Ponrang", kab: 355, prop: 28 },
  { value: 3463, label: "Ponrang Selatan", kab: 355, prop: 28 },
  { value: 3464, label: "Suli", kab: 355, prop: 28 },
  { value: 3465, label: "Suli Barat", kab: 355, prop: 28 },
  { value: 3466, label: "Walenrang", kab: 355, prop: 28 },
  { value: 3467, label: "Walenrang Barat", kab: 355, prop: 28 },
  { value: 3468, label: "Walenrang Timur", kab: 355, prop: 28 },
  { value: 3469, label: "Walenrang Utara", kab: 355, prop: 28 },
  { value: 3470, label: "Angkona", kab: 356, prop: 28 },
  { value: 3471, label: "Burau", kab: 356, prop: 28 },
  { value: 3472, label: "Kalaena", kab: 356, prop: 28 },
  { value: 3473, label: "Malili", kab: 356, prop: 28 },
  { value: 3474, label: "Mangkutana", kab: 356, prop: 28 },
  { value: 3475, label: "Nuha", kab: 356, prop: 28 },
  { value: 3476, label: "Tomoni", kab: 356, prop: 28 },
  { value: 3477, label: "Tomoni Timur", kab: 356, prop: 28 },
  { value: 3478, label: "Towuti", kab: 356, prop: 28 },
  { value: 3479, label: "Wasuponda", kab: 356, prop: 28 },
  { value: 3480, label: "Wotu", kab: 356, prop: 28 },
  { value: 3481, label: "Baebunta", kab: 357, prop: 28 },
  { value: 3482, label: "Bone-Bone", kab: 357, prop: 28 },
  { value: 3483, label: "Limbong", kab: 357, prop: 28 },
  { value: 3484, label: "Malangke", kab: 357, prop: 28 },
  { value: 3485, label: "Malangke Barat", kab: 357, prop: 28 },
  { value: 3486, label: "Mappedeceng", kab: 357, prop: 28 },
  { value: 3487, label: "Masamba", kab: 357, prop: 28 },
  { value: 3488, label: "Rampi", kab: 357, prop: 28 },
  { value: 3489, label: "Sabbang", kab: 357, prop: 28 },
  { value: 3490, label: "Seko", kab: 357, prop: 28 },
  { value: 3491, label: "Sukamaju", kab: 357, prop: 28 },
  { value: 3492, label: "Tana Lili", kab: 357, prop: 28 },
  { value: 3587, label: "Biring Kanaya", kab: 358, prop: 28 },
  { value: 3588, label: "Bontoala", kab: 358, prop: 28 },
  { value: 3589, label: "Makassar", kab: 358, prop: 28 },
  { value: 3590, label: "Mamajang", kab: 358, prop: 28 },
  { value: 3591, label: "Manggala", kab: 358, prop: 28 },
  { value: 3592, label: "Mariso", kab: 358, prop: 28 },
  { value: 3593, label: "Panakkukang", kab: 358, prop: 28 },
  { value: 3594, label: "Rappocini", kab: 358, prop: 28 },
  { value: 3595, label: "Tallo", kab: 358, prop: 28 },
  { value: 3596, label: "Tamalanrea", kab: 358, prop: 28 },
  { value: 3597, label: "Tamalate", kab: 358, prop: 28 },
  { value: 3598, label: "Ujung Pandang", kab: 358, prop: 28 },
  { value: 3599, label: "Ujung Tanah", kab: 358, prop: 28 },
  { value: 3600, label: "Wajo", kab: 358, prop: 28 },
  { value: 3862, label: "Bantimurung", kab: 359, prop: 28 },
  { value: 3863, label: "Bontoa (Maros Utara)", kab: 359, prop: 28 },
  { value: 3864, label: "Camba", kab: 359, prop: 28 },
  { value: 3865, label: "Cenrana", kab: 359, prop: 28 },
  { value: 3866, label: "Lau", kab: 359, prop: 28 },
  { value: 3867, label: "Mallawa", kab: 359, prop: 28 },
  { value: 3868, label: "Mandai", kab: 359, prop: 28 },
  { value: 3869, label: "Maros Baru", kab: 359, prop: 28 },
  { value: 3870, label: "Marusu", kab: 359, prop: 28 },
  { value: 3871, label: "Moncongloe", kab: 359, prop: 28 },
  { value: 3872, label: "Simbang", kab: 359, prop: 28 },
  { value: 3873, label: "Tanralili", kab: 359, prop: 28 },
  { value: 3874, label: "Tompu Bulu", kab: 359, prop: 28 },
  { value: 3875, label: "Turikale", kab: 359, prop: 28 },
  { value: 4625, label: "Bara", kab: 360, prop: 28 },
  { value: 4626, label: "Mungkajang", kab: 360, prop: 28 },
  { value: 4627, label: "Sendana", kab: 360, prop: 28 },
  { value: 4628, label: "Telluwanua", kab: 360, prop: 28 },
  { value: 4629, label: "Wara", kab: 360, prop: 28 },
  { value: 4630, label: "Wara Barat", kab: 360, prop: 28 },
  { value: 4631, label: "Wara Selatan", kab: 360, prop: 28 },
  { value: 4632, label: "Wara Timur", kab: 360, prop: 28 },
  { value: 4633, label: "Wara Utara", kab: 360, prop: 28 },
  { value: 4700, label: "Balocci", kab: 361, prop: 28 },
  { value: 4701, label: "Bungoro", kab: 361, prop: 28 },
  { value: 4702, label: "Labakkang", kab: 361, prop: 28 },
  {
    value: 4703,
    label: "Liukang Kalmas (Kalukuang Masalima)",
    kab: 361,
    prop: 28,
  },
  { value: 4704, label: "Liukang Tangaya", kab: 361, prop: 28 },
  { value: 4705, label: "Liukang Tupabbiring", kab: 361, prop: 28 },
  { value: 4706, label: "Liukang Tupabbiring Utara", kab: 361, prop: 28 },
  { value: 4707, label: "Mandalle", kab: 361, prop: 28 },
  { value: 4708, label: "Marang (Ma Rang)", kab: 361, prop: 28 },
  { value: 4709, label: "Minasa Tene", kab: 361, prop: 28 },
  { value: 4710, label: "Pangkajene", kab: 361, prop: 28 },
  { value: 4711, label: "Segeri", kab: 361, prop: 28 },
  { value: 4712, label: "Tondong Tallasa", kab: 361, prop: 28 },
  { value: 4730, label: "Bacukiki", kab: 362, prop: 28 },
  { value: 4731, label: "Bacukiki Barat", kab: 362, prop: 28 },
  { value: 4732, label: "Soreang", kab: 362, prop: 28 },
  { value: 4733, label: "Ujung", kab: 362, prop: 28 },
  { value: 5030, label: "Batulappa", kab: 363, prop: 28 },
  { value: 5031, label: "Cempa", kab: 363, prop: 28 },
  { value: 5032, label: "Duampanua", kab: 363, prop: 28 },
  { value: 5033, label: "Lanrisang", kab: 363, prop: 28 },
  { value: 5034, label: "Lembang", kab: 363, prop: 28 },
  { value: 5035, label: "Mattiro Bulu", kab: 363, prop: 28 },
  { value: 5036, label: "Mattiro Sompe", kab: 363, prop: 28 },
  { value: 5037, label: "Paleteang", kab: 363, prop: 28 },
  { value: 5038, label: "Patampanua", kab: 363, prop: 28 },
  { value: 5039, label: "Suppa", kab: 363, prop: 28 },
  { value: 5040, label: "Tiroang", kab: 363, prop: 28 },
  { value: 5041, label: "Watang Sawitto", kab: 363, prop: 28 },
  { value: 5454, label: "Benteng", kab: 364, prop: 28 },
  { value: 5455, label: "Bontoharu", kab: 364, prop: 28 },
  { value: 5456, label: "Bontomanai", kab: 364, prop: 28 },
  { value: 5457, label: "Bontomatene", kab: 364, prop: 28 },
  { value: 5458, label: "Bontosikuyu", kab: 364, prop: 28 },
  { value: 5459, label: "Buki", kab: 364, prop: 28 },
  { value: 5460, label: "Pasilambena", kab: 364, prop: 28 },
  { value: 5461, label: "Pasimarannu", kab: 364, prop: 28 },
  { value: 5462, label: "Pasimassunggu", kab: 364, prop: 28 },
  { value: 5463, label: "Pasimasunggu Timur", kab: 364, prop: 28 },
  { value: 5464, label: "Takabonerate", kab: 364, prop: 28 },
  { value: 5620, label: "Baranti", kab: 365, prop: 28 },
  { value: 5621, label: "Dua Pitue", kab: 365, prop: 28 },
  { value: 5622, label: "Kulo", kab: 365, prop: 28 },
  { value: 5623, label: "Maritengngae", kab: 365, prop: 28 },
  { value: 5624, label: "Panca Lautan (Lautang)", kab: 365, prop: 28 },
  { value: 5625, label: "Panca Rijang", kab: 365, prop: 28 },
  { value: 5626, label: "Pitu Raise/Riase", kab: 365, prop: 28 },
  { value: 5627, label: "Pitu Riawa", kab: 365, prop: 28 },
  { value: 5628, label: "Tellu Limpoe", kab: 365, prop: 28 },
  { value: 5629, label: "Watang Pulu", kab: 365, prop: 28 },
  {
    value: 5630,
    label: "Wattang Sidenreng (Watang Sidenreng)",
    kab: 365,
    prop: 28,
  },
  { value: 5739, label: "Bulupoddo", kab: 366, prop: 28 },
  { value: 5740, label: "Pulau Sembilan", kab: 366, prop: 28 },
  { value: 5741, label: "Sinjai Barat", kab: 366, prop: 28 },
  { value: 5742, label: "Sinjai Borong", kab: 366, prop: 28 },
  { value: 5743, label: "Sinjai Selatan", kab: 366, prop: 28 },
  { value: 5744, label: "Sinjai Tengah", kab: 366, prop: 28 },
  { value: 5745, label: "Sinjai Timur", kab: 366, prop: 28 },
  { value: 5746, label: "Sinjai Utara", kab: 366, prop: 28 },
  { value: 5747, label: "Tellu Limpoe", kab: 366, prop: 28 },
  { value: 5819, label: "Citta", kab: 367, prop: 28 },
  { value: 5820, label: "Donri-Donri", kab: 367, prop: 28 },
  { value: 5821, label: "Ganra", kab: 367, prop: 28 },
  { value: 5822, label: "Lalabata", kab: 367, prop: 28 },
  { value: 5823, label: "Lili Rilau", kab: 367, prop: 28 },
  { value: 5824, label: "Liliraja (Lili Riaja)", kab: 367, prop: 28 },
  { value: 5825, label: "Mario Riawa", kab: 367, prop: 28 },
  { value: 5826, label: "Mario Riwawo", kab: 367, prop: 28 },
  { value: 6189, label: "Galesong", kab: 368, prop: 28 },
  { value: 6190, label: "Galesong Selatan", kab: 368, prop: 28 },
  { value: 6191, label: "Galesong Utara", kab: 368, prop: 28 },
  { value: 6192, label: "Mangara Bombang", kab: 368, prop: 28 },
  { value: 6193, label: "Mappakasunggu", kab: 368, prop: 28 },
  { value: 6194, label: "Patallassang", kab: 368, prop: 28 },
  {
    value: 6195,
    label: "Polombangkeng Selatan (Polobangkeng)",
    kab: 368,
    prop: 28,
  },
  {
    value: 6196,
    label: "Polombangkeng Utara (Polobangkeng)",
    kab: 368,
    prop: 28,
  },
  { value: 6197, label: "Sanrobone", kab: 368, prop: 28 },
  { value: 6214, label: "Bittuang", kab: 369, prop: 28 },
  { value: 6215, label: "Bonggakaradeng", kab: 369, prop: 28 },
  { value: 6216, label: "Gandang Batu Sillanan", kab: 369, prop: 28 },
  { value: 6217, label: "Kurra", kab: 369, prop: 28 },
  { value: 6218, label: "Makale", kab: 369, prop: 28 },
  { value: 6219, label: "Makale Selatan", kab: 369, prop: 28 },
  { value: 6220, label: "Makale Utara", kab: 369, prop: 28 },
  { value: 6221, label: "Malimbong Balepe", kab: 369, prop: 28 },
  { value: 6222, label: "Mappak", kab: 369, prop: 28 },
  { value: 6223, label: "Masanda", kab: 369, prop: 28 },
  { value: 6224, label: "Mengkendek", kab: 369, prop: 28 },
  { value: 6225, label: "Rano", kab: 369, prop: 28 },
  { value: 6226, label: "Rantetayo", kab: 369, prop: 28 },
  { value: 6227, label: "Rembon", kab: 369, prop: 28 },
  { value: 6228, label: "Saluputti", kab: 369, prop: 28 },
  { value: 6229, label: "Sangalla (Sanggala)", kab: 369, prop: 28 },
  { value: 6230, label: "Sangalla Selatan", kab: 369, prop: 28 },
  { value: 6231, label: "Sangalla Utara", kab: 369, prop: 28 },
  { value: 6232, label: "Simbuang", kab: 369, prop: 28 },
  { value: 6738, label: "Awan Rante Karua", kab: 370, prop: 28 },
  { value: 6739, label: "Balusu", kab: 370, prop: 28 },
  { value: 6740, label: "Bangkelekila", kab: 370, prop: 28 },
  { value: 6741, label: "Baruppu", kab: 370, prop: 28 },
  { value: 6742, label: "Buntao", kab: 370, prop: 28 },
  { value: 6743, label: "Buntu Pepasan", kab: 370, prop: 28 },
  { value: 6744, label: "Dende Piongan Napo", kab: 370, prop: 28 },
  { value: 6745, label: "Kapalla Pitu (Kapala Pitu)", kab: 370, prop: 28 },
  { value: 6746, label: "Kesu", kab: 370, prop: 28 },
  { value: 6747, label: "Nanggala", kab: 370, prop: 28 },
  { value: 6748, label: "Rantebua", kab: 370, prop: 28 },
  { value: 6749, label: "Rantepao", kab: 370, prop: 28 },
  { value: 6750, label: "Rindingallo", kab: 370, prop: 28 },
  { value: 6751, label: "Sadan", kab: 370, prop: 28 },
  { value: 6752, label: "Sanggalangi", kab: 370, prop: 28 },
  { value: 6753, label: "Sesean", kab: 370, prop: 28 },
  { value: 6754, label: "Sesean Suloara", kab: 370, prop: 28 },
  { value: 6755, label: "Sopai", kab: 370, prop: 28 },
  { value: 6756, label: "Tallunglipu", kab: 370, prop: 28 },
  { value: 6757, label: "Tikala", kab: 370, prop: 28 },
  { value: 6758, label: "Tondon", kab: 370, prop: 28 },
  { value: 6840, label: "Belawa", kab: 371, prop: 28 },
  { value: 6841, label: "Bola", kab: 371, prop: 28 },
  { value: 6842, label: "Gilireng", kab: 371, prop: 28 },
  { value: 6843, label: "Keera", kab: 371, prop: 28 },
  { value: 6844, label: "Majauleng", kab: 371, prop: 28 },
  { value: 6845, label: "Maniang Pajo", kab: 371, prop: 28 },
  { value: 6846, label: "Pammana", kab: 371, prop: 28 },
  { value: 6847, label: "Penrang", kab: 371, prop: 28 },
  { value: 6848, label: "Pitumpanua", kab: 371, prop: 28 },
  { value: 6849, label: "Sabbang Paru", kab: 371, prop: 28 },
  { value: 6850, label: "Sajoanging", kab: 371, prop: 28 },
  { value: 6851, label: "Takkalalla", kab: 371, prop: 28 },
  { value: 6852, label: "Tana Sitolo", kab: 371, prop: 28 },
  { value: 6853, label: "Tempe", kab: 371, prop: 28 },
  { value: 384, label: "Balantak", kab: 372, prop: 29 },
  { value: 385, label: "Balantak Selatan", kab: 372, prop: 29 },
  { value: 386, label: "Balantak Utara", kab: 372, prop: 29 },
  { value: 387, label: "Batui", kab: 372, prop: 29 },
  { value: 388, label: "Batui Selatan", kab: 372, prop: 29 },
  { value: 389, label: "Bualemo (Boalemo)", kab: 372, prop: 29 },
  { value: 390, label: "Bunta", kab: 372, prop: 29 },
  { value: 391, label: "Kintom", kab: 372, prop: 29 },
  { value: 392, label: "Lamala", kab: 372, prop: 29 },
  { value: 393, label: "Lobu", kab: 372, prop: 29 },
  { value: 394, label: "Luwuk", kab: 372, prop: 29 },
  { value: 395, label: "Luwuk Selatan", kab: 372, prop: 29 },
  { value: 396, label: "Luwuk Timur", kab: 372, prop: 29 },
  { value: 397, label: "Luwuk Utara", kab: 372, prop: 29 },
  { value: 398, label: "Mantoh", kab: 372, prop: 29 },
  { value: 399, label: "Masama", kab: 372, prop: 29 },
  { value: 400, label: "Moilong", kab: 372, prop: 29 },
  { value: 401, label: "Nambo", kab: 372, prop: 29 },
  { value: 402, label: "Nuhon", kab: 372, prop: 29 },
  { value: 403, label: "Pagimana", kab: 372, prop: 29 },
  { value: 404, label: "Simpang Raya", kab: 372, prop: 29 },
  { value: 405, label: "Toili", kab: 372, prop: 29 },
  { value: 406, label: "Toili Barat", kab: 372, prop: 29 },
  { value: 407, label: "Banggai", kab: 373, prop: 29 },
  { value: 408, label: "Banggai Selatan", kab: 373, prop: 29 },
  { value: 409, label: "Banggai Tengah", kab: 373, prop: 29 },
  { value: 410, label: "Banggai Utara", kab: 373, prop: 29 },
  { value: 411, label: "Bangkurung", kab: 373, prop: 29 },
  { value: 412, label: "Bokan Kepulauan", kab: 373, prop: 29 },
  { value: 413, label: "Buko", kab: 373, prop: 29 },
  { value: 414, label: "Buko Selatan", kab: 373, prop: 29 },
  { value: 415, label: "Bulagi", kab: 373, prop: 29 },
  { value: 416, label: "Bulagi Selatan", kab: 373, prop: 29 },
  { value: 417, label: "Bulagi Utara", kab: 373, prop: 29 },
  { value: 418, label: "Labobo (Lobangkurung)", kab: 373, prop: 29 },
  { value: 419, label: "Liang", kab: 373, prop: 29 },
  { value: 420, label: "Peling Tengah", kab: 373, prop: 29 },
  { value: 421, label: "Tinangkung", kab: 373, prop: 29 },
  { value: 422, label: "Tinangkung Selatan", kab: 373, prop: 29 },
  { value: 423, label: "Tinangkung Utara", kab: 373, prop: 29 },
  { value: 424, label: "Totikum (Totikung)", kab: 373, prop: 29 },
  { value: 425, label: "Totikum Selatan", kab: 373, prop: 29 },
  { value: 1325, label: "Biau", kab: 374, prop: 29 },
  { value: 1326, label: "Bokat", kab: 374, prop: 29 },
  { value: 1327, label: "Bukal", kab: 374, prop: 29 },
  { value: 1328, label: "Bunobogu", kab: 374, prop: 29 },
  { value: 1329, label: "Gadung", kab: 374, prop: 29 },
  { value: 1330, label: "Karamat", kab: 374, prop: 29 },
  { value: 1331, label: "Lakea (Lipunoto)", kab: 374, prop: 29 },
  { value: 1332, label: "Momunu", kab: 374, prop: 29 },
  { value: 1333, label: "Paleleh", kab: 374, prop: 29 },
  { value: 1334, label: "Paleleh Barat", kab: 374, prop: 29 },
  { value: 1335, label: "Tiloan", kab: 374, prop: 29 },
  { value: 1617, label: "Balaesang", kab: 375, prop: 29 },
  { value: 1618, label: "Balaesang Tanjung", kab: 375, prop: 29 },
  { value: 1619, label: "Banawa", kab: 375, prop: 29 },
  { value: 1620, label: "Banawa Selatan", kab: 375, prop: 29 },
  { value: 1621, label: "Banawa Tengah", kab: 375, prop: 29 },
  { value: 1622, label: "Damsol (Dampelas Sojol)", kab: 375, prop: 29 },
  { value: 1623, label: "Labuan", kab: 375, prop: 29 },
  { value: 1624, label: "Pinembani", kab: 375, prop: 29 },
  { value: 1625, label: "Rio Pakava (Riopakawa)", kab: 375, prop: 29 },
  { value: 1626, label: "Sindue", kab: 375, prop: 29 },
  { value: 1627, label: "Sindue Tobata", kab: 375, prop: 29 },
  { value: 1628, label: "Sindue Tombusabora", kab: 375, prop: 29 },
  { value: 1629, label: "Sirenja", kab: 375, prop: 29 },
  { value: 1630, label: "Sojol", kab: 375, prop: 29 },
  { value: 1631, label: "Sojol Utara", kab: 375, prop: 29 },
  { value: 1632, label: "Tanantovea", kab: 375, prop: 29 },
  { value: 4090, label: "Bahodopi", kab: 376, prop: 29 },
  { value: 4091, label: "Bumi Raya", kab: 376, prop: 29 },
  { value: 4092, label: "Bungku Barat", kab: 376, prop: 29 },
  { value: 4093, label: "Bungku Pesisir", kab: 376, prop: 29 },
  { value: 4094, label: "Bungku Selatan", kab: 376, prop: 29 },
  { value: 4095, label: "Bungku Tengah", kab: 376, prop: 29 },
  { value: 4096, label: "Bungku Timur", kab: 376, prop: 29 },
  { value: 4097, label: "Bungku Utara", kab: 376, prop: 29 },
  { value: 4098, label: "Lembo", kab: 376, prop: 29 },
  { value: 4099, label: "Lembo Raya", kab: 376, prop: 29 },
  { value: 4100, label: "Mamosalato", kab: 376, prop: 29 },
  { value: 4101, label: "Menui Kepulauan", kab: 376, prop: 29 },
  { value: 4102, label: "Mori Atas", kab: 376, prop: 29 },
  { value: 4103, label: "Mori Utara", kab: 376, prop: 29 },
  { value: 4104, label: "Petasia", kab: 376, prop: 29 },
  { value: 4105, label: "Petasia Barat", kab: 376, prop: 29 },
  { value: 4106, label: "Petasia Timur", kab: 376, prop: 29 },
  { value: 4107, label: "Soyo Jaya", kab: 376, prop: 29 },
  { value: 4108, label: "Wita Ponda", kab: 376, prop: 29 },
  { value: 4634, label: "Mantikulore", kab: 377, prop: 29 },
  { value: 4635, label: "Palu Barat", kab: 377, prop: 29 },
  { value: 4636, label: "Palu Selatan", kab: 377, prop: 29 },
  { value: 4637, label: "Palu Timur", kab: 377, prop: 29 },
  { value: 4638, label: "Palu Utara", kab: 377, prop: 29 },
  { value: 4639, label: "Tatanga", kab: 377, prop: 29 },
  { value: 4640, label: "Tawaeli", kab: 377, prop: 29 },
  { value: 4641, label: "Ulujadi", kab: 377, prop: 29 },
  { value: 4738, label: "Ampibabo", kab: 378, prop: 29 },
  { value: 4739, label: "Balinggi", kab: 378, prop: 29 },
  { value: 4740, label: "Bolano", kab: 378, prop: 29 },
  { value: 4741, label: "Bolano Lambunu/Lambulu", kab: 378, prop: 29 },
  { value: 4742, label: "Kasimbar", kab: 378, prop: 29 },
  { value: 4743, label: "Mepanga", kab: 378, prop: 29 },
  { value: 4744, label: "Moutong", kab: 378, prop: 29 },
  { value: 4745, label: "Ongka Malino", kab: 378, prop: 29 },
  { value: 4746, label: "Palasa", kab: 378, prop: 29 },
  { value: 4747, label: "Parigi", kab: 378, prop: 29 },
  { value: 4748, label: "Parigi Barat", kab: 378, prop: 29 },
  { value: 4749, label: "Parigi Selatan", kab: 378, prop: 29 },
  { value: 4750, label: "Parigi Tengah", kab: 378, prop: 29 },
  { value: 4751, label: "Parigi Utara", kab: 378, prop: 29 },
  { value: 4752, label: "Sausu", kab: 378, prop: 29 },
  { value: 4753, label: "Siniu", kab: 378, prop: 29 },
  { value: 4754, label: "Taopa", kab: 378, prop: 29 },
  { value: 4755, label: "Tinombo", kab: 378, prop: 29 },
  { value: 4756, label: "Tinombo Selatan", kab: 378, prop: 29 },
  { value: 4757, label: "Tomini", kab: 378, prop: 29 },
  { value: 4758, label: "Toribulu", kab: 378, prop: 29 },
  { value: 4759, label: "Torue", kab: 378, prop: 29 },
  { value: 5107, label: "Lage", kab: 379, prop: 29 },
  { value: 5108, label: "Lore Barat", kab: 379, prop: 29 },
  { value: 5109, label: "Lore Piore", kab: 379, prop: 29 },
  { value: 5110, label: "Lore Selatan", kab: 379, prop: 29 },
  { value: 5111, label: "Lore Tengah", kab: 379, prop: 29 },
  { value: 5112, label: "Lore Timur", kab: 379, prop: 29 },
  { value: 5113, label: "Lore Utara", kab: 379, prop: 29 },
  { value: 5114, label: "Pamona Barat", kab: 379, prop: 29 },
  { value: 5115, label: "Pamona Puselemba", kab: 379, prop: 29 },
  { value: 5116, label: "Pamona Selatan", kab: 379, prop: 29 },
  { value: 5117, label: "Pamona Tenggara", kab: 379, prop: 29 },
  { value: 5118, label: "Pamona Timur", kab: 379, prop: 29 },
  { value: 5119, label: "Pamona Utara", kab: 379, prop: 29 },
  { value: 5120, label: "Poso Kota", kab: 379, prop: 29 },
  { value: 5121, label: "Poso Kota Selatan", kab: 379, prop: 29 },
  { value: 5122, label: "Poso Kota Utara", kab: 379, prop: 29 },
  { value: 5123, label: "Poso Pesisir", kab: 379, prop: 29 },
  { value: 5124, label: "Poso Pesisir Selatan", kab: 379, prop: 29 },
  { value: 5125, label: "Poso Pesisir Utara", kab: 379, prop: 29 },
  { value: 5649, label: "Dolo", kab: 380, prop: 29 },
  { value: 5650, label: "Dolo Barat", kab: 380, prop: 29 },
  { value: 5651, label: "Dolo Selatan", kab: 380, prop: 29 },
  { value: 5652, label: "Gumbasa", kab: 380, prop: 29 },
  { value: 5653, label: "Kinovaru", kab: 380, prop: 29 },
  { value: 5654, label: "Kulawi", kab: 380, prop: 29 },
  { value: 5655, label: "Kulawi Selatan", kab: 380, prop: 29 },
  { value: 5656, label: "Lindu", kab: 380, prop: 29 },
  { value: 5657, label: "Marawola", kab: 380, prop: 29 },
  { value: 5658, label: "Marawola Barat", kab: 380, prop: 29 },
  { value: 5659, label: "Nokilalaki", kab: 380, prop: 29 },
  { value: 5660, label: "Palolo", kab: 380, prop: 29 },
  { value: 5661, label: "Pipikoro", kab: 380, prop: 29 },
  { value: 5662, label: "Sigi Biromaru", kab: 380, prop: 29 },
  { value: 5663, label: "Tanambulava", kab: 380, prop: 29 },
  { value: 6668, label: "Ampana Kota", kab: 381, prop: 29 },
  { value: 6669, label: "Ampana Tete", kab: 381, prop: 29 },
  { value: 6670, label: "Togean", kab: 381, prop: 29 },
  { value: 6671, label: "Tojo", kab: 381, prop: 29 },
  { value: 6672, label: "Tojo Barat", kab: 381, prop: 29 },
  { value: 6673, label: "Ulu Bongka", kab: 381, prop: 29 },
  { value: 6674, label: "Una - Una", kab: 381, prop: 29 },
  { value: 6675, label: "Walea Besar", kab: 381, prop: 29 },
  { value: 6676, label: "Walea Kepulauan", kab: 381, prop: 29 },
  { value: 6677, label: "Baolan", kab: 382, prop: 29 },
  { value: 6678, label: "Basidondo", kab: 382, prop: 29 },
  { value: 6679, label: "Dako Pamean", kab: 382, prop: 29 },
  { value: 6680, label: "Dampal Selatan", kab: 382, prop: 29 },
  { value: 6681, label: "Dampal Utara", kab: 382, prop: 29 },
  { value: 6682, label: "Dondo", kab: 382, prop: 29 },
  { value: 6683, label: "Galang", kab: 382, prop: 29 },
  { value: 6684, label: "Lampasio", kab: 382, prop: 29 },
  { value: 6685, label: "Ogo Deide", kab: 382, prop: 29 },
  { value: 6686, label: "Tolitoli Utara", kab: 382, prop: 29 },
  { value: 718, label: "Batupoaro", kab: 383, prop: 30 },
  { value: 719, label: "Betoambari", kab: 383, prop: 30 },
  { value: 720, label: "Bungi", kab: 383, prop: 30 },
  { value: 721, label: "Kokalukuna", kab: 383, prop: 30 },
  { value: 722, label: "Lea-Lea", kab: 383, prop: 30 },
  { value: 723, label: "Murhum", kab: 383, prop: 30 },
  { value: 724, label: "Sora Walio (Sorowalio)", kab: 383, prop: 30 },
  { value: 725, label: "Wolio", kab: 383, prop: 30 },
  { value: 1127, label: "Kabaena", kab: 384, prop: 30 },
  { value: 1128, label: "Kabaena Barat", kab: 384, prop: 30 },
  { value: 1129, label: "Kabaena Selatan", kab: 384, prop: 30 },
  { value: 1130, label: "Kabaena Tengah", kab: 384, prop: 30 },
  { value: 1131, label: "Kabaena Timur", kab: 384, prop: 30 },
  { value: 1132, label: "Kabaena Utara", kab: 384, prop: 30 },
  { value: 1133, label: "Kepulauan Masaloka Raya", kab: 384, prop: 30 },
  { value: 1134, label: "Lentarai Jaya S. (Lantari Jaya)", kab: 384, prop: 30 },
  { value: 1135, label: "Mata Oleo", kab: 384, prop: 30 },
  { value: 1136, label: "Mata Usu", kab: 384, prop: 30 },
  { value: 1137, label: "Poleang", kab: 384, prop: 30 },
  { value: 1138, label: "Poleang Barat", kab: 384, prop: 30 },
  { value: 1139, label: "Poleang Selatan", kab: 384, prop: 30 },
  { value: 1140, label: "Poleang Tengah", kab: 384, prop: 30 },
  { value: 1141, label: "Poleang Tenggara", kab: 384, prop: 30 },
  { value: 1142, label: "Poleang Timur", kab: 384, prop: 30 },
  { value: 1143, label: "Poleang Utara", kab: 384, prop: 30 },
  { value: 1144, label: "Rarowatu", kab: 384, prop: 30 },
  { value: 1145, label: "Rarowatu Utara", kab: 384, prop: 30 },
  { value: 1146, label: "Rumbia", kab: 384, prop: 30 },
  { value: 1147, label: "Rumbia Tengah", kab: 384, prop: 30 },
  { value: 1148, label: "Tontonunu (Tontonuwu)", kab: 384, prop: 30 },
  { value: 1352, label: "Batauga", kab: 385, prop: 30 },
  { value: 1353, label: "Batu Atas", kab: 385, prop: 30 },
  { value: 1354, label: "Gu", kab: 385, prop: 30 },
  { value: 1355, label: "Kadatua", kab: 385, prop: 30 },
  { value: 1356, label: "Kapontori", kab: 385, prop: 30 },
  { value: 1357, label: "Lakudo", kab: 385, prop: 30 },
  { value: 1358, label: "Lapandewa", kab: 385, prop: 30 },
  { value: 1359, label: "Lasalimu", kab: 385, prop: 30 },
  { value: 1360, label: "Lasalimu Selatan", kab: 385, prop: 30 },
  { value: 1361, label: "Mawasangka", kab: 385, prop: 30 },
  { value: 1362, label: "Mawasangka Tengah", kab: 385, prop: 30 },
  { value: 1363, label: "Mawasangka Timur", kab: 385, prop: 30 },
  { value: 1364, label: "Pasar Wajo", kab: 385, prop: 30 },
  { value: 1365, label: "Sampolawa", kab: 385, prop: 30 },
  { value: 1366, label: "Sangia Mambulu", kab: 385, prop: 30 },
  { value: 1367, label: "Siompu", kab: 385, prop: 30 },
  { value: 1368, label: "Siompu Barat", kab: 385, prop: 30 },
  { value: 1369, label: "Siontapia (Siontapina)", kab: 385, prop: 30 },
  { value: 1370, label: "Talaga Raya (Telaga Raya)", kab: 385, prop: 30 },
  { value: 1371, label: "Wabula", kab: 385, prop: 30 },
  { value: 1372, label: "Wolowa", kab: 385, prop: 30 },
  { value: 1373, label: "Bonegunu", kab: 386, prop: 30 },
  { value: 1374, label: "Kambowa", kab: 386, prop: 30 },
  { value: 1375, label: "Kulisusu (Kalingsusu/Kalisusu)", kab: 386, prop: 30 },
  { value: 1376, label: "Kulisusu Barat", kab: 386, prop: 30 },
  { value: 1377, label: "Kulisusu Utara", kab: 386, prop: 30 },
  { value: 1378, label: "Wakorumba Utara", kab: 386, prop: 30 },
  { value: 2553, label: "Abeli", kab: 387, prop: 30 },
  { value: 2554, label: "Baruga", kab: 387, prop: 30 },
  { value: 2555, label: "Kadia", kab: 387, prop: 30 },
  { value: 2556, label: "Kambu", kab: 387, prop: 30 },
  { value: 2557, label: "Kendari", kab: 387, prop: 30 },
  { value: 2558, label: "Kendari Barat", kab: 387, prop: 30 },
  { value: 2559, label: "Mandonga", kab: 387, prop: 30 },
  { value: 2560, label: "Poasia", kab: 387, prop: 30 },
  { value: 2561, label: "Puuwatu", kab: 387, prop: 30 },
  { value: 2562, label: "Wua-Wua", kab: 387, prop: 30 },
  { value: 2752, label: "Baula", kab: 388, prop: 30 },
  { value: 2753, label: "Kolaka", kab: 388, prop: 30 },
  { value: 2754, label: "Ladongi", kab: 388, prop: 30 },
  { value: 2755, label: "Lalolae", kab: 388, prop: 30 },
  { value: 2756, label: "Lambandia (Lambadia)", kab: 388, prop: 30 },
  { value: 2757, label: "Latambaga", kab: 388, prop: 30 },
  { value: 2758, label: "Loea", kab: 388, prop: 30 },
  { value: 2759, label: "Mowewe", kab: 388, prop: 30 },
  { value: 2760, label: "Poli Polia", kab: 388, prop: 30 },
  { value: 2761, label: "Polinggona", kab: 388, prop: 30 },
  { value: 2762, label: "Pomalaa", kab: 388, prop: 30 },
  { value: 2763, label: "Samaturu", kab: 388, prop: 30 },
  { value: 2764, label: "Tanggetada", kab: 388, prop: 30 },
  { value: 2765, label: "Tinondo", kab: 388, prop: 30 },
  { value: 2766, label: "Tirawuta", kab: 388, prop: 30 },
  { value: 2767, label: "Toari", kab: 388, prop: 30 },
  { value: 2768, label: "Uluiwoi", kab: 388, prop: 30 },
  { value: 2769, label: "Watumbangga (Watubanggo)", kab: 388, prop: 30 },
  { value: 2770, label: "Wolo", kab: 388, prop: 30 },
  { value: 2771, label: "Wundulako", kab: 388, prop: 30 },
  { value: 2772, label: "Batu Putih", kab: 389, prop: 30 },
  { value: 2773, label: "Katoi", kab: 389, prop: 30 },
  { value: 2774, label: "Kodeoha", kab: 389, prop: 30 },
  { value: 2775, label: "Lasusua", kab: 389, prop: 30 },
  { value: 2776, label: "Lombai (Lambai)", kab: 389, prop: 30 },
  { value: 2777, label: "Ngapa", kab: 389, prop: 30 },
  { value: 2778, label: "Pakue", kab: 389, prop: 30 },
  { value: 2779, label: "Pakue Tengah", kab: 389, prop: 30 },
  { value: 2780, label: "Pakue Utara", kab: 389, prop: 30 },
  { value: 2781, label: "Porehu", kab: 389, prop: 30 },
  { value: 2782, label: "Ranteangin", kab: 389, prop: 30 },
  { value: 2783, label: "Tiwu", kab: 389, prop: 30 },
  { value: 2784, label: "Tolala", kab: 389, prop: 30 },
  { value: 2785, label: "Watunohu", kab: 389, prop: 30 },
  { value: 2786, label: "Wawo", kab: 389, prop: 30 },
  { value: 2787, label: "Abuki", kab: 390, prop: 30 },
  { value: 2788, label: "Amonggedo", kab: 390, prop: 30 },
  { value: 2789, label: "Anggaberi", kab: 390, prop: 30 },
  { value: 2790, label: "Asinua", kab: 390, prop: 30 },
  { value: 2791, label: "Besulutu", kab: 390, prop: 30 },
  { value: 2792, label: "Bondoala", kab: 390, prop: 30 },
  { value: 2793, label: "Kapoiala (Kapoyala)", kab: 390, prop: 30 },
  { value: 2794, label: "Konawe", kab: 390, prop: 30 },
  { value: 2795, label: "Lalonggasumeeto", kab: 390, prop: 30 },
  { value: 2796, label: "Lambuya", kab: 390, prop: 30 },
  { value: 2797, label: "Latoma", kab: 390, prop: 30 },
  { value: 2798, label: "Meluhu", kab: 390, prop: 30 },
  { value: 2799, label: "Onembute", kab: 390, prop: 30 },
  { value: 2800, label: "Pondidaha", kab: 390, prop: 30 },
  { value: 2801, label: "Puriala", kab: 390, prop: 30 },
  { value: 2802, label: "Routa", kab: 390, prop: 30 },
  { value: 2803, label: "Sampara", kab: 390, prop: 30 },
  { value: 2804, label: "Soropia", kab: 390, prop: 30 },
  { value: 2805, label: "Tongauna", kab: 390, prop: 30 },
  { value: 2806, label: "Uepai (Uwepai)", kab: 390, prop: 30 },
  { value: 2807, label: "Unaaha", kab: 390, prop: 30 },
  { value: 2808, label: "Wawonii Barat", kab: 390, prop: 30 },
  { value: 2809, label: "Wawonii Selatan", kab: 390, prop: 30 },
  { value: 2810, label: "Wawonii Tengah", kab: 390, prop: 30 },
  { value: 2811, label: "Wawonii Tenggara", kab: 390, prop: 30 },
  { value: 2812, label: "Wawonii Timur", kab: 390, prop: 30 },
  { value: 2813, label: "Wawonii Timur Laut", kab: 390, prop: 30 },
  { value: 2814, label: "Wawonii Utara", kab: 390, prop: 30 },
  { value: 2815, label: "Wawotobi", kab: 390, prop: 30 },
  { value: 2816, label: "Wonggeduku", kab: 390, prop: 30 },
  { value: 2817, label: "Andoolo", kab: 391, prop: 30 },
  { value: 2818, label: "Angata", kab: 391, prop: 30 },
  { value: 2819, label: "Baito", kab: 391, prop: 30 },
  { value: 2820, label: "Basala", kab: 391, prop: 30 },
  { value: 2821, label: "Benua", kab: 391, prop: 30 },
  { value: 2822, label: "Buke", kab: 391, prop: 30 },
  { value: 2823, label: "Kolono", kab: 391, prop: 30 },
  { value: 2824, label: "Konda", kab: 391, prop: 30 },
  { value: 2825, label: "Laeya", kab: 391, prop: 30 },
  { value: 2826, label: "Lainea", kab: 391, prop: 30 },
  { value: 2827, label: "Lalembuu / Lalumbuu", kab: 391, prop: 30 },
  { value: 2828, label: "Landono", kab: 391, prop: 30 },
  { value: 2829, label: "Laonti", kab: 391, prop: 30 },
  { value: 2830, label: "Moramo", kab: 391, prop: 30 },
  { value: 2831, label: "Moramo Utara", kab: 391, prop: 30 },
  { value: 2832, label: "Mowila", kab: 391, prop: 30 },
  { value: 2833, label: "Palangga", kab: 391, prop: 30 },
  { value: 2834, label: "Palangga Selatan", kab: 391, prop: 30 },
  { value: 2835, label: "Ranomeeto", kab: 391, prop: 30 },
  { value: 2836, label: "Ranomeeto Barat", kab: 391, prop: 30 },
  { value: 2837, label: "Tinanggea", kab: 391, prop: 30 },
  { value: 2838, label: "Wolasi", kab: 391, prop: 30 },
  { value: 2839, label: "Andowia", kab: 392, prop: 30 },
  { value: 2840, label: "Asera", kab: 392, prop: 30 },
  { value: 2841, label: "Langgikima", kab: 392, prop: 30 },
  { value: 2842, label: "Lasolo", kab: 392, prop: 30 },
  { value: 2843, label: "Lembo", kab: 392, prop: 30 },
  { value: 2844, label: "Molawe", kab: 392, prop: 30 },
  { value: 2845, label: "Motui", kab: 392, prop: 30 },
  { value: 2846, label: "Oheo", kab: 392, prop: 30 },
  { value: 2847, label: "Sawa", kab: 392, prop: 30 },
  { value: 2848, label: "Wiwirano", kab: 392, prop: 30 },
  { value: 4157, label: "Barangka", kab: 393, prop: 30 },
  { value: 4158, label: "Batalaiwaru (Batalaiworu)", kab: 393, prop: 30 },
  { value: 4159, label: "Batukara", kab: 393, prop: 30 },
  { value: 4160, label: "Bone (Bone Tondo)", kab: 393, prop: 30 },
  { value: 4161, label: "Duruka", kab: 393, prop: 30 },
  { value: 4162, label: "Kabangka", kab: 393, prop: 30 },
  { value: 4163, label: "Kabawo", kab: 393, prop: 30 },
  { value: 4164, label: "Katobu", kab: 393, prop: 30 },
  { value: 4165, label: "Kontu Kowuna", kab: 393, prop: 30 },
  { value: 4166, label: "Kontunaga", kab: 393, prop: 30 },
  { value: 4167, label: "Kusambi", kab: 393, prop: 30 },
  { value: 4168, label: "Lasalepa", kab: 393, prop: 30 },
  { value: 4169, label: "Lawa", kab: 393, prop: 30 },
  { value: 4170, label: "Lohia", kab: 393, prop: 30 },
  { value: 4171, label: "Maginti", kab: 393, prop: 30 },
  { value: 4172, label: "Maligano", kab: 393, prop: 30 },
  { value: 4173, label: "Marobo", kab: 393, prop: 30 },
  { value: 4174, label: "Napabalano", kab: 393, prop: 30 },
  { value: 4175, label: "Napano Kusambi", kab: 393, prop: 30 },
  { value: 4176, label: "Parigi", kab: 393, prop: 30 },
  { value: 4177, label: "Pasi Kolaga", kab: 393, prop: 30 },
  { value: 4178, label: "Pasir Putih", kab: 393, prop: 30 },
  {
    value: 4179,
    label: "Sawerigadi (Sawerigading/Sewergadi)",
    kab: 393,
    prop: 30,
  },
  { value: 4180, label: "Tiworo Kepulauan", kab: 393, prop: 30 },
  { value: 4181, label: "Tiworo Selatan", kab: 393, prop: 30 },
  { value: 4182, label: "Tiworo Tengah", kab: 393, prop: 30 },
  { value: 4183, label: "Tiworo Utara", kab: 393, prop: 30 },
  { value: 4184, label: "Tongkuno", kab: 393, prop: 30 },
  { value: 4185, label: "Tongkuno Selatan", kab: 393, prop: 30 },
  { value: 4186, label: "Towea", kab: 393, prop: 30 },
  { value: 4187, label: "Wa Daga", kab: 393, prop: 30 },
  { value: 4188, label: "Wakorumba Selatan", kab: 393, prop: 30 },
  { value: 4189, label: "Watopute", kab: 393, prop: 30 },
  { value: 6854, label: "Binongko", kab: 394, prop: 30 },
  { value: 6855, label: "Kaledupa", kab: 394, prop: 30 },
  { value: 6856, label: "Kaledupa Selatan", kab: 394, prop: 30 },
  { value: 6857, label: "Togo Binongko", kab: 394, prop: 30 },
  { value: 6858, label: "Tomia", kab: 394, prop: 30 },
  { value: 6859, label: "Tomia Timur", kab: 394, prop: 30 },
  { value: 6860, label: "Wangi-Wangi", kab: 394, prop: 30 },
  { value: 6861, label: "Wangi-Wangi Selatan", kab: 394, prop: 30 },
  { value: 966, label: "Aertembaga (Bitung Timur)", kab: 395, prop: 31 },
  { value: 967, label: "Girian", kab: 395, prop: 31 },
  { value: 968, label: "Lembeh Selatan (Bitung Selatan)", kab: 395, prop: 31 },
  { value: 969, label: "Lembeh Utara", kab: 395, prop: 31 },
  { value: 970, label: "Madidir (Bitung Tengah)", kab: 395, prop: 31 },
  { value: 971, label: "Maesa", kab: 395, prop: 31 },
  { value: 972, label: "Matuari (Bitung Barat)", kab: 395, prop: 31 },
  { value: 973, label: "Ranowulu (Bitung Utara)", kab: 395, prop: 31 },
  { value: 1096, label: "Bilalang", kab: 396, prop: 31 },
  { value: 1097, label: "Bolaang", kab: 396, prop: 31 },
  { value: 1098, label: "Bolaang Timur", kab: 396, prop: 31 },
  { value: 1099, label: "Dumoga", kab: 396, prop: 31 },
  { value: 1100, label: "Dumoga Barat", kab: 396, prop: 31 },
  { value: 1101, label: "Dumoga Tengah", kab: 396, prop: 31 },
  { value: 1102, label: "Dumoga Tenggara", kab: 396, prop: 31 },
  { value: 1103, label: "Dumoga Timur", kab: 396, prop: 31 },
  { value: 1104, label: "Dumoga Utara", kab: 396, prop: 31 },
  { value: 1105, label: "Lolak", kab: 396, prop: 31 },
  { value: 1106, label: "Lolayan", kab: 396, prop: 31 },
  { value: 1107, label: "Passi Barat", kab: 396, prop: 31 },
  { value: 1108, label: "Passi Timur", kab: 396, prop: 31 },
  { value: 1109, label: "Poigar", kab: 396, prop: 31 },
  { value: 1110, label: "Sangtombolang", kab: 396, prop: 31 },
  { value: 1111, label: "Bolaang Uki", kab: 397, prop: 31 },
  { value: 1112, label: "Pinolosian", kab: 397, prop: 31 },
  { value: 1113, label: "Pinolosian Tengah", kab: 397, prop: 31 },
  { value: 1114, label: "Pinolosian Timur", kab: 397, prop: 31 },
  { value: 1115, label: "Posigadan", kab: 397, prop: 31 },
  { value: 1116, label: "Kotabunan", kab: 398, prop: 31 },
  { value: 1117, label: "Modayag", kab: 398, prop: 31 },
  { value: 1118, label: "Modayag Barat", kab: 398, prop: 31 },
  { value: 1119, label: "Nuangan", kab: 398, prop: 31 },
  { value: 1120, label: "Tutuyan", kab: 398, prop: 31 },
  { value: 1121, label: "Bintauna", kab: 399, prop: 31 },
  { value: 1122, label: "Bolang Itang Barat", kab: 399, prop: 31 },
  { value: 1123, label: "Bolang Itang Timur", kab: 399, prop: 31 },
  { value: 1124, label: "Kaidipang", kab: 399, prop: 31 },
  { value: 1125, label: "Pinogaluman", kab: 399, prop: 31 },
  { value: 1126, label: "Sangkub", kab: 399, prop: 31 },
  { value: 2607, label: "Kendahe", kab: 400, prop: 31 },
  { value: 2608, label: "Kepulauan Marore", kab: 400, prop: 31 },
  { value: 2609, label: "Manganitu", kab: 400, prop: 31 },
  { value: 2610, label: "Manganitu Selatan", kab: 400, prop: 31 },
  { value: 2611, label: "Nusa Tabukan", kab: 400, prop: 31 },
  { value: 2612, label: "Tabukan Selatan", kab: 400, prop: 31 },
  { value: 2613, label: "Tabukan Selatan Tengah", kab: 400, prop: 31 },
  { value: 2614, label: "Tabukan Selatan Tenggara", kab: 400, prop: 31 },
  { value: 2615, label: "Tabukan Tengah", kab: 400, prop: 31 },
  { value: 2616, label: "Tabukan Utara", kab: 400, prop: 31 },
  { value: 2617, label: "Tahuna", kab: 400, prop: 31 },
  { value: 2618, label: "Tahuna Barat", kab: 400, prop: 31 },
  { value: 2619, label: "Tahuna Timur", kab: 400, prop: 31 },
  { value: 2620, label: "Tamako", kab: 400, prop: 31 },
  { value: 2621, label: "Tatoareng", kab: 400, prop: 31 },
  { value: 2624, label: "Biaro", kab: 401, prop: 31 },
  { value: 2625, label: "Siau Barat", kab: 401, prop: 31 },
  { value: 2626, label: "Siau Barat Selatan", kab: 401, prop: 31 },
  { value: 2627, label: "Siau Barat Utara", kab: 401, prop: 31 },
  { value: 2628, label: "Siau Tengah", kab: 401, prop: 31 },
  { value: 2629, label: "Siau Timur", kab: 401, prop: 31 },
  { value: 2630, label: "Siau Timur Selatan", kab: 401, prop: 31 },
  { value: 2631, label: "Tagulandang", kab: 401, prop: 31 },
  { value: 2632, label: "Tagulandang Selatan", kab: 401, prop: 31 },
  { value: 2633, label: "Tagulandang Utara", kab: 401, prop: 31 },
  { value: 2653, label: "Beo", kab: 402, prop: 31 },
  { value: 2654, label: "Beo Selatan", kab: 402, prop: 31 },
  { value: 2655, label: "Beo Utara", kab: 402, prop: 31 },
  { value: 2656, label: "Damao (Damau)", kab: 402, prop: 31 },
  { value: 2657, label: "Essang", kab: 402, prop: 31 },
  { value: 2658, label: "Essang Selatan", kab: 402, prop: 31 },
  { value: 2659, label: "Gemeh", kab: 402, prop: 31 },
  { value: 2660, label: "Kabaruan", kab: 402, prop: 31 },
  { value: 2661, label: "Kalongan", kab: 402, prop: 31 },
  { value: 2662, label: "Lirung", kab: 402, prop: 31 },
  { value: 2663, label: "Melonguane", kab: 402, prop: 31 },
  { value: 2664, label: "Melonguane Timur", kab: 402, prop: 31 },
  { value: 2665, label: "Miangas", kab: 402, prop: 31 },
  { value: 2666, label: "Moronge", kab: 402, prop: 31 },
  { value: 2667, label: "Nanusa", kab: 402, prop: 31 },
  { value: 2668, label: "Pulutan", kab: 402, prop: 31 },
  { value: 2669, label: "Rainis", kab: 402, prop: 31 },
  { value: 2670, label: "Salibabu", kab: 402, prop: 31 },
  { value: 2671, label: "Tampan Amma", kab: 402, prop: 31 },
  { value: 2870, label: "Kotamobagu Barat", kab: 403, prop: 31 },
  { value: 2871, label: "Kotamobagu Selatan", kab: 403, prop: 31 },
  { value: 2872, label: "Kotamobagu Timur", kab: 403, prop: 31 },
  { value: 2873, label: "Kotamobagu Utara", kab: 403, prop: 31 },
  { value: 3768, label: "Bunaken", kab: 404, prop: 31 },
  { value: 3769, label: "Bunaken Kepulauan", kab: 404, prop: 31 },
  { value: 3770, label: "Malalayang", kab: 404, prop: 31 },
  { value: 3771, label: "Mapanget", kab: 404, prop: 31 },
  { value: 3772, label: "Paal Dua", kab: 404, prop: 31 },
  { value: 3773, label: "Sario", kab: 404, prop: 31 },
  { value: 3774, label: "Singkil", kab: 404, prop: 31 },
  { value: 3775, label: "Tikala", kab: 404, prop: 31 },
  { value: 3776, label: "Tuminiting", kab: 404, prop: 31 },
  { value: 3777, label: "Wanea", kab: 404, prop: 31 },
  { value: 3778, label: "Wenang", kab: 404, prop: 31 },
  { value: 4006, label: "Eris", kab: 405, prop: 31 },
  { value: 4007, label: "Kakas", kab: 405, prop: 31 },
  { value: 4008, label: "Kakas Barat", kab: 405, prop: 31 },
  { value: 4009, label: "Kawangkoan", kab: 405, prop: 31 },
  { value: 4010, label: "Kawangkoan Barat", kab: 405, prop: 31 },
  { value: 4011, label: "Kawangkoan Utara", kab: 405, prop: 31 },
  { value: 4012, label: "Kombi", kab: 405, prop: 31 },
  { value: 4013, label: "Langowan Barat", kab: 405, prop: 31 },
  { value: 4014, label: "Langowan Selatan", kab: 405, prop: 31 },
  { value: 4015, label: "Langowan Timur", kab: 405, prop: 31 },
  { value: 4016, label: "Langowan Utara", kab: 405, prop: 31 },
  { value: 4017, label: "Lembean Timur", kab: 405, prop: 31 },
  { value: 4018, label: "Mandolang", kab: 405, prop: 31 },
  { value: 4019, label: "Pineleng", kab: 405, prop: 31 },
  { value: 4020, label: "Remboken", kab: 405, prop: 31 },
  { value: 4021, label: "Sonder", kab: 405, prop: 31 },
  { value: 4022, label: "Tombariri", kab: 405, prop: 31 },
  { value: 4023, label: "Tombariri Timur", kab: 405, prop: 31 },
  { value: 4024, label: "Tombulu", kab: 405, prop: 31 },
  { value: 4025, label: "Tompaso", kab: 405, prop: 31 },
  { value: 4026, label: "Tompaso Barat", kab: 405, prop: 31 },
  { value: 4027, label: "Tondano Barat", kab: 405, prop: 31 },
  { value: 4028, label: "Tondano Selatan", kab: 405, prop: 31 },
  { value: 4029, label: "Tondano Timur", kab: 405, prop: 31 },
  { value: 4030, label: "Tondano Utara", kab: 405, prop: 31 },
  { value: 4031, label: "Amurang", kab: 406, prop: 31 },
  { value: 4032, label: "Amurang Barat", kab: 406, prop: 31 },
  { value: 4033, label: "Amurang Timur", kab: 406, prop: 31 },
  { value: 4034, label: "Kumelembuai", kab: 406, prop: 31 },
  { value: 4035, label: "Maesaan", kab: 406, prop: 31 },
  { value: 4036, label: "Modoinding", kab: 406, prop: 31 },
  { value: 4037, label: "Motoling", kab: 406, prop: 31 },
  { value: 4038, label: "Motoling Barat", kab: 406, prop: 31 },
  { value: 4039, label: "Motoling Timur", kab: 406, prop: 31 },
  { value: 4040, label: "Ranoyapo", kab: 406, prop: 31 },
  { value: 4041, label: "Sinonsayang", kab: 406, prop: 31 },
  { value: 4042, label: "Suluun Tareran", kab: 406, prop: 31 },
  { value: 4043, label: "Tareran", kab: 406, prop: 31 },
  { value: 4044, label: "Tatapaan", kab: 406, prop: 31 },
  { value: 4045, label: "Tenga", kab: 406, prop: 31 },
  { value: 4046, label: "Tompaso Baru", kab: 406, prop: 31 },
  { value: 4047, label: "Tumpaan", kab: 406, prop: 31 },
  { value: 4048, label: "Belang", kab: 407, prop: 31 },
  { value: 4049, label: "Pasan", kab: 407, prop: 31 },
  { value: 4050, label: "Pusomaen", kab: 407, prop: 31 },
  { value: 4051, label: "Ratahan", kab: 407, prop: 31 },
  { value: 4052, label: "Ratahan Timur", kab: 407, prop: 31 },
  { value: 4053, label: "Ratatotok", kab: 407, prop: 31 },
  { value: 4054, label: "Silian Raya", kab: 407, prop: 31 },
  { value: 4055, label: "Tombatu", kab: 407, prop: 31 },
  { value: 4056, label: "Tombatu Timur", kab: 407, prop: 31 },
  { value: 4057, label: "Tombatu Utara", kab: 407, prop: 31 },
  { value: 4058, label: "Touluaan", kab: 407, prop: 31 },
  { value: 4059, label: "Touluaan Selatan", kab: 407, prop: 31 },
  { value: 4060, label: "Airmadidi", kab: 408, prop: 31 },
  { value: 4061, label: "Dimembe", kab: 408, prop: 31 },
  { value: 4062, label: "Kalawat", kab: 408, prop: 31 },
  { value: 4063, label: "Kauditan", kab: 408, prop: 31 },
  { value: 4064, label: "Kema", kab: 408, prop: 31 },
  { value: 4065, label: "Likupang Barat", kab: 408, prop: 31 },
  { value: 4066, label: "Likupang Selatan", kab: 408, prop: 31 },
  { value: 4067, label: "Likupang Timur", kab: 408, prop: 31 },
  { value: 4068, label: "Talawaan", kab: 408, prop: 31 },
  { value: 4069, label: "Wori", kab: 408, prop: 31 },
  { value: 6733, label: "Tomohon Barat", kab: 409, prop: 31 },
  { value: 6734, label: "Tomohon Selatan", kab: 409, prop: 31 },
  { value: 6735, label: "Tomohon Tengah", kab: 409, prop: 31 },
  { value: 6736, label: "Tomohon Timur", kab: 409, prop: 31 },
  { value: 6737, label: "Tomohon Utara", kab: 409, prop: 31 },
  { value: 176, label: "Ampek Nagari (IV Nagari )", kab: 410, prop: 32 },
  { value: 177, label: "Banuhampu", kab: 410, prop: 32 },
  { value: 178, label: "Baso", kab: 410, prop: 32 },
  { value: 179, label: "Candung", kab: 410, prop: 32 },
  { value: 180, label: "IV Angkat Candung (Ampek Angkek)", kab: 410, prop: 32 },
  { value: 181, label: "IV Koto (Ampek Koto)", kab: 410, prop: 32 },
  { value: 182, label: "Kamang Magek", kab: 410, prop: 32 },
  { value: 183, label: "Lubuk Basung", kab: 410, prop: 32 },
  { value: 184, label: "Malakak", kab: 410, prop: 32 },
  { value: 185, label: "Matur", kab: 410, prop: 32 },
  { value: 186, label: "Palembayan", kab: 410, prop: 32 },
  { value: 187, label: "Palupuh", kab: 410, prop: 32 },
  { value: 188, label: "Sungai Pua (Puar)", kab: 410, prop: 32 },
  { value: 189, label: "Tanjung Mutiara", kab: 410, prop: 32 },
  { value: 190, label: "Tanjung Raya", kab: 410, prop: 32 },
  { value: 191, label: "Tilatang Kamang", kab: 410, prop: 32 },
  { value: 1276, label: "Aur Birugo Tigo Baleh", kab: 411, prop: 32 },
  { value: 1277, label: "Guguk Panjang (Guguak Panjang)", kab: 411, prop: 32 },
  { value: 1278, label: "Mandiangin Koto Selayan", kab: 411, prop: 32 },
  { value: 1588, label: "Asam Jujuhan", kab: 412, prop: 32 },
  { value: 1589, label: "Koto Baru", kab: 412, prop: 32 },
  { value: 1590, label: "Koto Besar", kab: 412, prop: 32 },
  { value: 1591, label: "Koto Salak", kab: 412, prop: 32 },
  { value: 1592, label: "Padang Laweh", kab: 412, prop: 32 },
  { value: 1593, label: "Pulau Punjung", kab: 412, prop: 32 },
  { value: 1594, label: "Sembilan Koto (IX Koto)", kab: 412, prop: 32 },
  { value: 1595, label: "Sitiung", kab: 412, prop: 32 },
  { value: 1596, label: "Sungai Rumbai", kab: 412, prop: 32 },
  { value: 1597, label: "Timpeh", kab: 412, prop: 32 },
  { value: 1598, label: "Tiumang", kab: 412, prop: 32 },
  { value: 2588, label: "Pagai Selatan", kab: 413, prop: 32 },
  { value: 2589, label: "Pagai Utara", kab: 413, prop: 32 },
  { value: 2590, label: "Siberut Barat", kab: 413, prop: 32 },
  { value: 2591, label: "Siberut Barat Daya", kab: 413, prop: 32 },
  { value: 2592, label: "Siberut Selatan", kab: 413, prop: 32 },
  { value: 2593, label: "Siberut Tengah", kab: 413, prop: 32 },
  { value: 2594, label: "Siberut Utara", kab: 413, prop: 32 },
  { value: 2595, label: "Sikakap", kab: 413, prop: 32 },
  { value: 2596, label: "Sipora Selatan", kab: 413, prop: 32 },
  { value: 2597, label: "Sipora Utara", kab: 413, prop: 32 },
  { value: 3351, label: "Akabiluru", kab: 414, prop: 32 },
  { value: 3352, label: "Bukik Barisan", kab: 414, prop: 32 },
  { value: 3353, label: "Guguak (Gugu)", kab: 414, prop: 32 },
  { value: 3354, label: "Gunuang Omeh (Gunung Mas)", kab: 414, prop: 32 },
  { value: 3355, label: "Harau", kab: 414, prop: 32 },
  { value: 3356, label: "Kapur IX/Sembilan", kab: 414, prop: 32 },
  { value: 3357, label: "Lareh Sago Halaban", kab: 414, prop: 32 },
  { value: 3358, label: "Luak (Luhak)", kab: 414, prop: 32 },
  { value: 3359, label: "Mungka", kab: 414, prop: 32 },
  { value: 3360, label: "Pangkalan Koto Baru", kab: 414, prop: 32 },
  { value: 3361, label: "Payakumbuh", kab: 414, prop: 32 },
  { value: 3362, label: "Situjuah Limo/Lima Nagari", kab: 414, prop: 32 },
  { value: 3363, label: "Suliki", kab: 414, prop: 32 },
  { value: 4534, label: "Bungus Teluk Kabung", kab: 415, prop: 32 },
  { value: 4535, label: "Koto Tangah", kab: 415, prop: 32 },
  { value: 4536, label: "Kuranji", kab: 415, prop: 32 },
  { value: 4537, label: "Lubuk Begalung", kab: 415, prop: 32 },
  { value: 4538, label: "Lubuk Kilangan", kab: 415, prop: 32 },
  { value: 4539, label: "Nanggalo", kab: 415, prop: 32 },
  { value: 4540, label: "Padang Barat", kab: 415, prop: 32 },
  { value: 4541, label: "Padang Selatan", kab: 415, prop: 32 },
  { value: 4542, label: "Padang Timur", kab: 415, prop: 32 },
  { value: 4543, label: "Padang Utara", kab: 415, prop: 32 },
  { value: 4544, label: "Pauh", kab: 415, prop: 32 },
  { value: 4566, label: "Padang Panjang Barat", kab: 416, prop: 32 },
  { value: 4567, label: "Padang Panjang Timur", kab: 416, prop: 32 },
  { value: 4568, label: "2 X 11 Enam Lingkung", kab: 417, prop: 32 },
  { value: 4569, label: "2 X 11 Kayu Tanam", kab: 417, prop: 32 },
  { value: 4570, label: "Batang Anai", kab: 417, prop: 32 },
  { value: 4571, label: "Batang Gasan", kab: 417, prop: 32 },
  { value: 4572, label: "Enam Lingkung", kab: 417, prop: 32 },
  { value: 4573, label: "IV Koto Aur Malintang", kab: 417, prop: 32 },
  { value: 4574, label: "Lubuk Alung", kab: 417, prop: 32 },
  { value: 4575, label: "Nan Sabaris", kab: 417, prop: 32 },
  { value: 4576, label: "Padang Sago", kab: 417, prop: 32 },
  { value: 4577, label: "Patamuan", kab: 417, prop: 32 },
  { value: 4578, label: "Sintuk/Sintuak Toboh Gadang", kab: 417, prop: 32 },
  { value: 4579, label: "Sungai Geringging/Garingging", kab: 417, prop: 32 },
  { value: 4580, label: "Sungai Limau", kab: 417, prop: 32 },
  { value: 4581, label: "Ulakan Tapakih/Tapakis", kab: 417, prop: 32 },
  { value: 4582, label: "V Koto Kampung Dalam", kab: 417, prop: 32 },
  { value: 4583, label: "V Koto Timur", kab: 417, prop: 32 },
  { value: 4584, label: "VII Koto Sungai Sarik", kab: 417, prop: 32 },
  { value: 4734, label: "Pariaman Selatan", kab: 418, prop: 32 },
  { value: 4735, label: "Pariaman Tengah", kab: 418, prop: 32 },
  { value: 4736, label: "Pariaman Timur", kab: 418, prop: 32 },
  { value: 4737, label: "Pariaman Utara", kab: 418, prop: 32 },
  { value: 4760, label: "Bonjol", kab: 419, prop: 32 },
  { value: 4761, label: "Duo Koto (II Koto)", kab: 419, prop: 32 },
  { value: 4762, label: "Lubuk Sikaping", kab: 419, prop: 32 },
  { value: 4763, label: "Mapat Tunggul", kab: 419, prop: 32 },
  { value: 4764, label: "Mapat Tunggul Selatan", kab: 419, prop: 32 },
  { value: 4765, label: "Padang Gelugur", kab: 419, prop: 32 },
  { value: 4766, label: "Panti", kab: 419, prop: 32 },
  { value: 4767, label: "Rao", kab: 419, prop: 32 },
  { value: 4768, label: "Rao Selatan", kab: 419, prop: 32 },
  { value: 4769, label: "Rao Utara", kab: 419, prop: 32 },
  { value: 4770, label: "Simpang Alahan Mati", kab: 419, prop: 32 },
  { value: 4771, label: "Tigo Nagari (III Nagari)", kab: 419, prop: 32 },
  { value: 4772, label: "Gunung Tuleh", kab: 420, prop: 32 },
  { value: 4773, label: "Kinali", kab: 420, prop: 32 },
  { value: 4774, label: "Koto Balingka", kab: 420, prop: 32 },
  { value: 4775, label: "Lembah Melintang", kab: 420, prop: 32 },
  { value: 4776, label: "Luhak Nan Duo", kab: 420, prop: 32 },
  { value: 4777, label: "Pasaman", kab: 420, prop: 32 },
  { value: 4778, label: "Ranah Batahan", kab: 420, prop: 32 },
  { value: 4779, label: "Sasak Ranah Pasisir/Pesisie", kab: 420, prop: 32 },
  { value: 4780, label: "Sei Beremas", kab: 420, prop: 32 },
  { value: 4781, label: "Sungai Aur", kab: 420, prop: 32 },
  { value: 4782, label: "Talamau", kab: 420, prop: 32 },
  { value: 4842, label: "Lamposi Tigo Nagari", kab: 421, prop: 32 },
  { value: 4843, label: "Payakumbuh Barat", kab: 421, prop: 32 },
  { value: 4844, label: "Payakumbuh Selatan", kab: 421, prop: 32 },
  { value: 4845, label: "Payakumbuh Timur", kab: 421, prop: 32 },
  { value: 4846, label: "Payakumbuh Utara", kab: 421, prop: 32 },
  { value: 4984, label: "Airpura", kab: 422, prop: 32 },
  { value: 4985, label: "Basa Ampek Balai Tapan", kab: 422, prop: 32 },
  { value: 4986, label: "Batang Kapas", kab: 422, prop: 32 },
  { value: 4987, label: "Bayang", kab: 422, prop: 32 },
  { value: 4988, label: "IV Jurai", kab: 422, prop: 32 },
  { value: 4989, label: "IV Nagari Bayang Utara", kab: 422, prop: 32 },
  { value: 4990, label: "Koto XI Tarusan", kab: 422, prop: 32 },
  { value: 4991, label: "Lengayang", kab: 422, prop: 32 },
  { value: 4992, label: "Linggo Sari Baganti", kab: 422, prop: 32 },
  { value: 4993, label: "Lunang", kab: 422, prop: 32 },
  { value: 4994, label: "Pancung Soal", kab: 422, prop: 32 },
  { value: 4995, label: "Ranah Ampek Hulu Tapan", kab: 422, prop: 32 },
  { value: 4996, label: "Ranah Pesisir", kab: 422, prop: 32 },
  { value: 4997, label: "Silaut", kab: 422, prop: 32 },
  { value: 4998, label: "Sutera", kab: 422, prop: 32 },
  { value: 5443, label: "Barangin", kab: 423, prop: 32 },
  { value: 5444, label: "Lembah Segar", kab: 423, prop: 32 },
  { value: 5445, label: "Silungkang", kab: 423, prop: 32 },
  { value: 5446, label: "Talawi", kab: 423, prop: 32 },
  { value: 5664, label: "IV Nagari", kab: 424, prop: 32 },
  { value: 5665, label: "Kamang Baru", kab: 424, prop: 32 },
  { value: 5666, label: "Koto VII", kab: 424, prop: 32 },
  { value: 5667, label: "Kupitan", kab: 424, prop: 32 },
  { value: 5668, label: "Lubuak Tarok", kab: 424, prop: 32 },
  { value: 5669, label: "Sijunjung", kab: 424, prop: 32 },
  { value: 5670, label: "Sumpur Kudus", kab: 424, prop: 32 },
  { value: 5671, label: "Tanjung Gadang", kab: 424, prop: 32 },
  { value: 5796, label: "Bukit Sundi", kab: 425, prop: 32 },
  { value: 5797, label: "Danau Kembar", kab: 425, prop: 32 },
  { value: 5798, label: "Gunung Talang", kab: 425, prop: 32 },
  { value: 5799, label: "Hiliran Gumanti", kab: 425, prop: 32 },
  { value: 5800, label: "IX Koto Sei Lasi", kab: 425, prop: 32 },
  { value: 5801, label: "Junjung Sirih", kab: 425, prop: 32 },
  { value: 5802, label: "Kubung", kab: 425, prop: 32 },
  { value: 5803, label: "Lembah Gumanti", kab: 425, prop: 32 },
  { value: 5804, label: "Lembang Jaya", kab: 425, prop: 32 },
  { value: 5805, label: "Pantai Cermin", kab: 425, prop: 32 },
  { value: 5806, label: "Payung Sekaki", kab: 425, prop: 32 },
  { value: 5807, label: "Tigo Lurah", kab: 425, prop: 32 },
  { value: 5808, label: "X Koto Diatas", kab: 425, prop: 32 },
  { value: 5809, label: "X Koto Singkarak", kab: 425, prop: 32 },
  { value: 5810, label: "Lubuk Sikarah", kab: 425, prop: 32 },
  { value: 5811, label: "Tanjung Harapan", kab: 425, prop: 32 },
  { value: 5812, label: "Koto Parik Gadang Diateh", kab: 426, prop: 32 },
  { value: 5813, label: "Pauh Duo", kab: 426, prop: 32 },
  { value: 5814, label: "Sangir", kab: 426, prop: 32 },
  { value: 5815, label: "Sangir Balai Janggo", kab: 426, prop: 32 },
  { value: 5816, label: "Sangir Batang Hari", kab: 426, prop: 32 },
  { value: 5817, label: "Sangir Jujuan", kab: 426, prop: 32 },
  { value: 5818, label: "Sungai Pagu", kab: 426, prop: 32 },
  { value: 6243, label: "Batipuh", kab: 427, prop: 32 },
  { value: 6244, label: "Batipuh Selatan", kab: 427, prop: 32 },
  { value: 6245, label: "Lima Kaum", kab: 427, prop: 32 },
  { value: 6246, label: "Lintau Buo", kab: 427, prop: 32 },
  { value: 6247, label: "Lintau Buo Utara", kab: 427, prop: 32 },
  { value: 6248, label: "Padang Ganting", kab: 427, prop: 32 },
  { value: 6249, label: "Pariangan", kab: 427, prop: 32 },
  { value: 6250, label: "Rambatan", kab: 427, prop: 32 },
  { value: 6251, label: "Salimpaung", kab: 427, prop: 32 },
  { value: 6252, label: "Sepuluh Koto (X Koto)", kab: 427, prop: 32 },
  { value: 6253, label: "Sungai Tarab", kab: 427, prop: 32 },
  { value: 6254, label: "Sungayang", kab: 427, prop: 32 },
  { value: 6255, label: "Tanjung Baru", kab: 427, prop: 32 },
  { value: 6256, label: "Tanjung Emas", kab: 427, prop: 32 },
  { value: 554, label: "Air Kumbang", kab: 428, prop: 33 },
  { value: 555, label: "Air Salek", kab: 428, prop: 33 },
  { value: 556, label: "Banyuasin I", kab: 428, prop: 33 },
  { value: 557, label: "Banyuasin II", kab: 428, prop: 33 },
  { value: 558, label: "Banyuasin III", kab: 428, prop: 33 },
  { value: 559, label: "Betung", kab: 428, prop: 33 },
  { value: 560, label: "Makarti Jaya", kab: 428, prop: 33 },
  { value: 561, label: "Muara Padang", kab: 428, prop: 33 },
  { value: 562, label: "Muara Sugihan", kab: 428, prop: 33 },
  { value: 563, label: "Muara Telang", kab: 428, prop: 33 },
  { value: 564, label: "Pulau Rimau", kab: 428, prop: 33 },
  { value: 565, label: "Rambutan", kab: 428, prop: 33 },
  { value: 566, label: "Rantau Bayur", kab: 428, prop: 33 },
  { value: 567, label: "Sembawa", kab: 428, prop: 33 },
  { value: 568, label: "Suak Tapeh", kab: 428, prop: 33 },
  { value: 569, label: "Sumber Marga Telang", kab: 428, prop: 33 },
  { value: 570, label: "Talang Kelapa", kab: 428, prop: 33 },
  { value: 571, label: "Tanjung Lago", kab: 428, prop: 33 },
  { value: 572, label: "Tungkal Ilir", kab: 428, prop: 33 },
  { value: 1640, label: "Lintang Kanan", kab: 429, prop: 33 },
  { value: 1641, label: "Muara Pinang", kab: 429, prop: 33 },
  { value: 1642, label: "Pasemah Air Keruh", kab: 429, prop: 33 },
  { value: 1643, label: "Pendopo", kab: 429, prop: 33 },
  { value: 1644, label: "Pendopo Barat", kab: 429, prop: 33 },
  { value: 1645, label: "Saling", kab: 429, prop: 33 },
  { value: 1646, label: "Sikap Dalam", kab: 429, prop: 33 },
  { value: 1647, label: "Talang Padang", kab: 429, prop: 33 },
  { value: 1648, label: "Tebing Tinggi", kab: 429, prop: 33 },
  { value: 1649, label: "Ulu Musi", kab: 429, prop: 33 },
  { value: 3083, label: "Gumay Talang", kab: 430, prop: 33 },
  { value: 3084, label: "Gumay Ulu", kab: 430, prop: 33 },
  { value: 3085, label: "Jarai", kab: 430, prop: 33 },
  { value: 3086, label: "Kikim Barat", kab: 430, prop: 33 },
  { value: 3087, label: "Kikim Selatan", kab: 430, prop: 33 },
  { value: 3088, label: "Kikim Tengah", kab: 430, prop: 33 },
  { value: 3089, label: "Kikim Timur", kab: 430, prop: 33 },
  { value: 3090, label: "Kota Agung", kab: 430, prop: 33 },
  { value: 3091, label: "Lahat", kab: 430, prop: 33 },
  { value: 3092, label: "Merapi Barat", kab: 430, prop: 33 },
  { value: 3093, label: "Merapi Selatan", kab: 430, prop: 33 },
  { value: 3094, label: "Merapi Timur", kab: 430, prop: 33 },
  { value: 3095, label: "Muarapayang", kab: 430, prop: 33 },
  { value: 3096, label: "Mulak Ulu", kab: 430, prop: 33 },
  { value: 3097, label: "Pagar Gunung", kab: 430, prop: 33 },
  { value: 3098, label: "Pajar Bulan", kab: 430, prop: 33 },
  { value: 3099, label: "Pseksu", kab: 430, prop: 33 },
  { value: 3100, label: "Pulau Pinang", kab: 430, prop: 33 },
  { value: 3101, label: "Sukamerindu", kab: 430, prop: 33 },
  { value: 3102, label: "Tanjung Sakti Pumi", kab: 430, prop: 33 },
  { value: 3103, label: "Tanjung Sakti Pumu", kab: 430, prop: 33 },
  { value: 3104, label: "Tanjung Tebat", kab: 430, prop: 33 },
  { value: 3419, label: "Lubuk Linggau Barat Dua (II)", kab: 431, prop: 33 },
  { value: 3420, label: "Lubuk Linggau Barat Satu (I)", kab: 431, prop: 33 },
  { value: 3421, label: "Lubuk Linggau Selatan Dua (II)", kab: 431, prop: 33 },
  { value: 3422, label: "Lubuk Linggau Selatan Satu (I)", kab: 431, prop: 33 },
  { value: 3423, label: "Lubuk Linggau Timur Dua (II)", kab: 431, prop: 33 },
  { value: 3424, label: "Lubuk Linggau Timur Satu (I)", kab: 431, prop: 33 },
  { value: 3425, label: "Lubuk Linggau Utara Dua (II)", kab: 431, prop: 33 },
  { value: 3426, label: "Lubuk Linggau Utara Satu (I)", kab: 431, prop: 33 },
  { value: 4109, label: "Abab", kab: 432, prop: 33 },
  { value: 4110, label: "Benakat", kab: 432, prop: 33 },
  { value: 4111, label: "Gelumbang", kab: 432, prop: 33 },
  { value: 4112, label: "Gunung Megang", kab: 432, prop: 33 },
  { value: 4113, label: "Kelekar", kab: 432, prop: 33 },
  { value: 4114, label: "Lawang Kidul", kab: 432, prop: 33 },
  { value: 4115, label: "Lembak", kab: 432, prop: 33 },
  { value: 4116, label: "Lubai", kab: 432, prop: 33 },
  { value: 4117, label: "Muara Belida", kab: 432, prop: 33 },
  { value: 4118, label: "Muara Enim", kab: 432, prop: 33 },
  { value: 4119, label: "Penukal (Penukal Abab)", kab: 432, prop: 33 },
  { value: 4120, label: "Penukal Utara", kab: 432, prop: 33 },
  { value: 4121, label: "Rambang", kab: 432, prop: 33 },
  { value: 4122, label: "Rambang Dangku", kab: 432, prop: 33 },
  { value: 4123, label: "Semendo Darat Laut", kab: 432, prop: 33 },
  { value: 4124, label: "Semendo Darat Tengah", kab: 432, prop: 33 },
  { value: 4125, label: "Semendo Darat Ulu", kab: 432, prop: 33 },
  { value: 4126, label: "Sungai Rotan", kab: 432, prop: 33 },
  { value: 4127, label: "Talang Ubi", kab: 432, prop: 33 },
  { value: 4128, label: "Tanah Abang", kab: 432, prop: 33 },
  { value: 4129, label: "Tanjung Agung", kab: 432, prop: 33 },
  { value: 4130, label: "Ujan Mas", kab: 432, prop: 33 },
  { value: 4200, label: "Babat Supat", kab: 433, prop: 33 },
  { value: 4201, label: "Babat Toman", kab: 433, prop: 33 },
  { value: 4202, label: "Batanghari Leko", kab: 433, prop: 33 },
  { value: 4203, label: "Bayung Lencir", kab: 433, prop: 33 },
  { value: 4204, label: "Keluang", kab: 433, prop: 33 },
  { value: 4205, label: "Lais", kab: 433, prop: 33 },
  { value: 4206, label: "Lalan (Sungai Lalan)", kab: 433, prop: 33 },
  { value: 4207, label: "Lawang Wetan", kab: 433, prop: 33 },
  { value: 4208, label: "Plakat Tinggi (Pelakat Tinggi)", kab: 433, prop: 33 },
  { value: 4209, label: "Sanga Desa", kab: 433, prop: 33 },
  { value: 4210, label: "Sekayu", kab: 433, prop: 33 },
  { value: 4211, label: "Sungai Keruh", kab: 433, prop: 33 },
  { value: 4212, label: "Sungai Lilin", kab: 433, prop: 33 },
  { value: 4213, label: "Tungkal Jaya", kab: 433, prop: 33 },
  {
    value: 4214,
    label: "Batukuning Lakitan Ulu (BTS)/Cecar",
    kab: 434,
    prop: 33,
  },
  { value: 4215, label: "Jaya Loka", kab: 434, prop: 33 },
  { value: 4216, label: "Karang Dapo", kab: 434, prop: 33 },
  { value: 4217, label: "Karang Jaya", kab: 434, prop: 33 },
  { value: 4218, label: "Megang Sakti", kab: 434, prop: 33 },
  { value: 4219, label: "Muara Beliti", kab: 434, prop: 33 },
  { value: 4220, label: "Muara Kelingi", kab: 434, prop: 33 },
  { value: 4221, label: "Muara Lakitan", kab: 434, prop: 33 },
  { value: 4222, label: "Nibung", kab: 434, prop: 33 },
  { value: 4223, label: "Purwodadi", kab: 434, prop: 33 },
  { value: 4224, label: "Rawas Ilir", kab: 434, prop: 33 },
  { value: 4225, label: "Rawas Ulu", kab: 434, prop: 33 },
  { value: 4226, label: "Rupit", kab: 434, prop: 33 },
  { value: 4227, label: "Selangit", kab: 434, prop: 33 },
  { value: 4228, label: "STL Ulu Terawas", kab: 434, prop: 33 },
  { value: 4229, label: "Sukakarya", kab: 434, prop: 33 },
  { value: 4230, label: "Sumber Harta", kab: 434, prop: 33 },
  { value: 4231, label: "Tiang Pumpung Kepungut", kab: 434, prop: 33 },
  { value: 4232, label: "Tuah Negeri", kab: 434, prop: 33 },
  { value: 4233, label: "Tugumulyo", kab: 434, prop: 33 },
  { value: 4234, label: "Ulu Rawas", kab: 434, prop: 33 },
  { value: 4437, label: "Indralaya", kab: 435, prop: 33 },
  { value: 4438, label: "Indralaya Selatan", kab: 435, prop: 33 },
  { value: 4439, label: "Indralaya Utara", kab: 435, prop: 33 },
  { value: 4440, label: "Kandis", kab: 435, prop: 33 },
  { value: 4441, label: "Lubuk Keliat", kab: 435, prop: 33 },
  { value: 4442, label: "Muara Kuang", kab: 435, prop: 33 },
  { value: 4443, label: "Payaraman", kab: 435, prop: 33 },
  { value: 4444, label: "Pemulutan", kab: 435, prop: 33 },
  { value: 4445, label: "Pemulutan Barat", kab: 435, prop: 33 },
  { value: 4446, label: "Pemulutan Selatan", kab: 435, prop: 33 },
  { value: 4447, label: "Rambang Kuang", kab: 435, prop: 33 },
  { value: 4448, label: "Rantau Alai", kab: 435, prop: 33 },
  { value: 4449, label: "Rantau Panjang", kab: 435, prop: 33 },
  { value: 4450, label: "Sungai Pinang", kab: 435, prop: 33 },
  { value: 4451, label: "Tanjung Batu", kab: 435, prop: 33 },
  { value: 4452, label: "Tanjung Raja", kab: 435, prop: 33 },
  { value: 4453, label: "Air Sugihan", kab: 436, prop: 33 },
  { value: 4454, label: "Cengal", kab: 436, prop: 33 },
  { value: 4455, label: "Jejawi", kab: 436, prop: 33 },
  { value: 4456, label: "Kayu Agung", kab: 436, prop: 33 },
  { value: 4457, label: "Lempuing", kab: 436, prop: 33 },
  { value: 4458, label: "Lempuing Jaya", kab: 436, prop: 33 },
  { value: 4459, label: "Mesuji", kab: 436, prop: 33 },
  { value: 4460, label: "Mesuji Makmur", kab: 436, prop: 33 },
  { value: 4461, label: "Mesuji Raya", kab: 436, prop: 33 },
  { value: 4462, label: "Pampangan", kab: 436, prop: 33 },
  { value: 4463, label: "Pangkalan Lampam", kab: 436, prop: 33 },
  { value: 4464, label: "Pedamaran", kab: 436, prop: 33 },
  { value: 4465, label: "Pedamaran Timur", kab: 436, prop: 33 },
  { value: 4466, label: "Sirah Pulau Padang", kab: 436, prop: 33 },
  { value: 4467, label: "Sungai Menang", kab: 436, prop: 33 },
  { value: 4468, label: "Tanjung Lubuk", kab: 436, prop: 33 },
  { value: 4469, label: "Teluk Gelam", kab: 436, prop: 33 },
  { value: 4470, label: "Tulung Selapan", kab: 436, prop: 33 },
  { value: 4471, label: "Baturaja Barat", kab: 437, prop: 33 },
  { value: 4472, label: "Baturaja Timur", kab: 437, prop: 33 },
  { value: 4473, label: "Lengkiti", kab: 437, prop: 33 },
  { value: 4474, label: "Lubuk Batang", kab: 437, prop: 33 },
  { value: 4475, label: "Lubuk Raja", kab: 437, prop: 33 },
  { value: 4476, label: "Muara Jaya", kab: 437, prop: 33 },
  { value: 4477, label: "Pengandonan", kab: 437, prop: 33 },
  { value: 4478, label: "Peninjauan", kab: 437, prop: 33 },
  { value: 4479, label: "Semidang Aji", kab: 437, prop: 33 },
  { value: 4480, label: "Sinar Peninjauan", kab: 437, prop: 33 },
  { value: 4481, label: "Sosoh Buay Rayap", kab: 437, prop: 33 },
  { value: 4482, label: "Ulu Ogan", kab: 437, prop: 33 },
  { value: 4483, label: "Banding Agung", kab: 438, prop: 33 },
  { value: 4484, label: "Buana Pemaca", kab: 438, prop: 33 },
  { value: 4485, label: "Buay Pemaca", kab: 438, prop: 33 },
  { value: 4486, label: "Buay Pematang Ribu Ranau Tengah", kab: 438, prop: 33 },
  { value: 4487, label: "Buay Rawan", kab: 438, prop: 33 },
  { value: 4488, label: "Buay Runjung", kab: 438, prop: 33 },
  { value: 4489, label: "Buay Sandang Aji", kab: 438, prop: 33 },
  { value: 4490, label: "Kisam Ilir", kab: 438, prop: 33 },
  { value: 4491, label: "Kisam Tinggi", kab: 438, prop: 33 },
  { value: 4492, label: "Mekakau Ilir", kab: 438, prop: 33 },
  { value: 4493, label: "Muaradua", kab: 438, prop: 33 },
  { value: 4494, label: "Muaradua Kisam", kab: 438, prop: 33 },
  { value: 4495, label: "Pulau Beringin", kab: 438, prop: 33 },
  { value: 4496, label: "Runjung Agung", kab: 438, prop: 33 },
  { value: 4497, label: "Simpang", kab: 438, prop: 33 },
  { value: 4498, label: "Sindang Danau", kab: 438, prop: 33 },
  { value: 4499, label: "Sungai Are", kab: 438, prop: 33 },
  { value: 4500, label: "Tiga Dihaji", kab: 438, prop: 33 },
  { value: 4501, label: "Warkuk Ranau Selatan", kab: 438, prop: 33 },
  { value: 4502, label: "Belitang", kab: 439, prop: 33 },
  { value: 4503, label: "Belitang II", kab: 439, prop: 33 },
  { value: 4504, label: "Belitang III", kab: 439, prop: 33 },
  { value: 4505, label: "Belitang Jaya", kab: 439, prop: 33 },
  { value: 4506, label: "Belitang Madang Raya", kab: 439, prop: 33 },
  { value: 4507, label: "Belitang Mulya", kab: 439, prop: 33 },
  { value: 4508, label: "Buay Madang", kab: 439, prop: 33 },
  { value: 4509, label: "Buay Madang Timur", kab: 439, prop: 33 },
  { value: 4510, label: "Buay Pemuka Bangsa Raja", kab: 439, prop: 33 },
  { value: 4511, label: "Buay Pemuka Beliung / Peliung", kab: 439, prop: 33 },
  { value: 4512, label: "Bunga Mayang", kab: 439, prop: 33 },
  { value: 4513, label: "Cempaka", kab: 439, prop: 33 },
  { value: 4514, label: "Jayapura", kab: 439, prop: 33 },
  { value: 4515, label: "Madang Suku I", kab: 439, prop: 33 },
  { value: 4516, label: "Madang Suku II", kab: 439, prop: 33 },
  { value: 4517, label: "Madang Suku III", kab: 439, prop: 33 },
  { value: 4518, label: "Martapura", kab: 439, prop: 33 },
  { value: 4519, label: "Semendawai Barat", kab: 439, prop: 33 },
  { value: 4520, label: "Semendawai Suku III", kab: 439, prop: 33 },
  { value: 4521, label: "Semendawai Timur", kab: 439, prop: 33 },
  { value: 4591, label: "Dempo Selatan", kab: 440, prop: 33 },
  { value: 4592, label: "Dempo Tengah", kab: 440, prop: 33 },
  { value: 4593, label: "Dempo Utara", kab: 440, prop: 33 },
  { value: 4594, label: "Pagar Alam Selatan", kab: 440, prop: 33 },
  { value: 4595, label: "Pagar Alam Utara", kab: 440, prop: 33 },
  { value: 4609, label: "Alang-Alang Lebar", kab: 441, prop: 33 },
  { value: 4610, label: "Bukit Kecil", kab: 441, prop: 33 },
  { value: 4611, label: "Gandus", kab: 441, prop: 33 },
  { value: 4612, label: "Ilir Barat I", kab: 441, prop: 33 },
  { value: 4613, label: "Ilir Barat II", kab: 441, prop: 33 },
  { value: 4614, label: "Ilir Timur I", kab: 441, prop: 33 },
  { value: 4615, label: "Ilir Timur II", kab: 441, prop: 33 },
  { value: 4616, label: "Kalidoni", kab: 441, prop: 33 },
  { value: 4617, label: "Kemuning", kab: 441, prop: 33 },
  { value: 4618, label: "Kertapati", kab: 441, prop: 33 },
  { value: 4619, label: "Plaju", kab: 441, prop: 33 },
  { value: 4620, label: "Sako", kab: 441, prop: 33 },
  { value: 4621, label: "Seberang Ulu I", kab: 441, prop: 33 },
  { value: 4622, label: "Seberang Ulu II", kab: 441, prop: 33 },
  { value: 4623, label: "Sematang Borang", kab: 441, prop: 33 },
  { value: 4624, label: "Sukarami", kab: 441, prop: 33 },
  { value: 5126, label: "Cambai", kab: 442, prop: 33 },
  { value: 5127, label: "Prabumulih Barat", kab: 442, prop: 33 },
  { value: 5128, label: "Prabumulih Selatan", kab: 442, prop: 33 },
  { value: 5129, label: "Prabumulih Timur", kab: 442, prop: 33 },
  { value: 5130, label: "Prabumulih Utara", kab: 442, prop: 33 },
  { value: 5131, label: "Rambang Kapak Tengah", kab: 442, prop: 33 },
  { value: 214, label: "Aek Kuasan", kab: 443, prop: 34 },
  { value: 215, label: "Aek Ledong", kab: 443, prop: 34 },
  { value: 216, label: "Aek Songsongan", kab: 443, prop: 34 },
  { value: 217, label: "Air Batu", kab: 443, prop: 34 },
  { value: 218, label: "Air Joman", kab: 443, prop: 34 },
  { value: 219, label: "Bandar Pasir Mandoge", kab: 443, prop: 34 },
  { value: 220, label: "Bandar Pulau", kab: 443, prop: 34 },
  { value: 221, label: "Buntu Pane", kab: 443, prop: 34 },
  { value: 222, label: "Kisaran Barat Kota", kab: 443, prop: 34 },
  { value: 223, label: "Kisaran Timur Kota", kab: 443, prop: 34 },
  { value: 224, label: "Meranti", kab: 443, prop: 34 },
  { value: 225, label: "Pulau Rakyat", kab: 443, prop: 34 },
  { value: 226, label: "Pulo Bandring", kab: 443, prop: 34 },
  { value: 227, label: "Rahuning", kab: 443, prop: 34 },
  { value: 228, label: "Rawang Panca Arga", kab: 443, prop: 34 },
  { value: 229, label: "Sei Dadap", kab: 443, prop: 34 },
  { value: 230, label: "Sei Kepayang", kab: 443, prop: 34 },
  { value: 231, label: "Sei Kepayang Barat", kab: 443, prop: 34 },
  { value: 232, label: "Sei Kepayang Timur", kab: 443, prop: 34 },
  { value: 233, label: "Setia Janji", kab: 443, prop: 34 },
  { value: 234, label: "Silau Laut", kab: 443, prop: 34 },
  { value: 235, label: "Simpang Empat", kab: 443, prop: 34 },
  { value: 236, label: "Tanjung Balai", kab: 443, prop: 34 },
  { value: 237, label: "Teluk Dalam", kab: 443, prop: 34 },
  { value: 238, label: "Tinggi Raja", kab: 443, prop: 34 },
  { value: 711, label: "Air Putih", kab: 444, prop: 34 },
  { value: 712, label: "Limapuluh", kab: 444, prop: 34 },
  { value: 713, label: "Medang Deras", kab: 444, prop: 34 },
  { value: 714, label: "Sei Balai", kab: 444, prop: 34 },
  { value: 715, label: "Sei Suka", kab: 444, prop: 34 },
  { value: 716, label: "Talawi", kab: 444, prop: 34 },
  { value: 717, label: "Tanjung Tiram", kab: 444, prop: 34 },
  { value: 934, label: "Binjai Barat", kab: 445, prop: 34 },
  { value: 935, label: "Binjai Kota", kab: 445, prop: 34 },
  { value: 936, label: "Binjai Selatan", kab: 445, prop: 34 },
  { value: 937, label: "Binjai Timur", kab: 445, prop: 34 },
  { value: 938, label: "Binjai Utara", kab: 445, prop: 34 },
  { value: 1517, label: "Berampu (Brampu)", kab: 446, prop: 34 },
  { value: 1518, label: "Gunung Sitember", kab: 446, prop: 34 },
  { value: 1519, label: "Lae Parira", kab: 446, prop: 34 },
  { value: 1520, label: "Parbuluan", kab: 446, prop: 34 },
  { value: 1521, label: "Pegagan Hilir", kab: 446, prop: 34 },
  { value: 1522, label: "Sidikalang", kab: 446, prop: 34 },
  { value: 1523, label: "Siempat Nempu", kab: 446, prop: 34 },
  { value: 1524, label: "Siempat Nempu Hilir", kab: 446, prop: 34 },
  { value: 1525, label: "Siempat Nempu Hulu", kab: 446, prop: 34 },
  { value: 1526, label: "Silahi Sabungan", kab: 446, prop: 34 },
  { value: 1527, label: "Silima Pungga-Pungga", kab: 446, prop: 34 },
  { value: 1528, label: "Sitinjo", kab: 446, prop: 34 },
  { value: 1529, label: "Sumbul", kab: 446, prop: 34 },
  { value: 1530, label: "Tanah Pinem", kab: 446, prop: 34 },
  { value: 1531, label: "Tiga Lingga", kab: 446, prop: 34 },
  { value: 1537, label: "Bangun Purba", kab: 447, prop: 34 },
  { value: 1538, label: "Batang Kuis", kab: 447, prop: 34 },
  { value: 1539, label: "Beringin", kab: 447, prop: 34 },
  { value: 1540, label: "Biru-Biru", kab: 447, prop: 34 },
  { value: 1541, label: "Deli Tua", kab: 447, prop: 34 },
  { value: 1542, label: "Galang", kab: 447, prop: 34 },
  { value: 1543, label: "Gunung Meriah", kab: 447, prop: 34 },
  { value: 1544, label: "Hamparan Perak", kab: 447, prop: 34 },
  { value: 1545, label: "Kutalimbaru", kab: 447, prop: 34 },
  { value: 1546, label: "Labuhan Deli", kab: 447, prop: 34 },
  { value: 1547, label: "Lubuk Pakam", kab: 447, prop: 34 },
  { value: 1548, label: "Namo Rambe", kab: 447, prop: 34 },
  { value: 1549, label: "Pagar Merbau", kab: 447, prop: 34 },
  { value: 1550, label: "Pancur Batu", kab: 447, prop: 34 },
  { value: 1551, label: "Pantai Labu", kab: 447, prop: 34 },
  { value: 1552, label: "Patumbak", kab: 447, prop: 34 },
  { value: 1553, label: "Percut Sei Tuan", kab: 447, prop: 34 },
  { value: 1554, label: "Sibolangit", kab: 447, prop: 34 },
  { value: 1555, label: "Sinembah Tanjung Muda Hilir", kab: 447, prop: 34 },
  { value: 1556, label: "Sinembah Tanjung Muda Hulu", kab: 447, prop: 34 },
  { value: 1557, label: "Sunggal", kab: 447, prop: 34 },
  { value: 1558, label: "Tanjung Morawa", kab: 447, prop: 34 },
  { value: 1895, label: "Gunungsitoli", kab: 448, prop: 34 },
  { value: 1896, label: "Gunungsitoli Alooa", kab: 448, prop: 34 },
  { value: 1897, label: "Gunungsitoli Barat", kab: 448, prop: 34 },
  { value: 1898, label: "Gunungsitoli Idanoi", kab: 448, prop: 34 },
  { value: 1899, label: "Gunungsitoli Selatan", kab: 448, prop: 34 },
  { value: 1900, label: "Gunungsitoli Utara", kab: 448, prop: 34 },
  { value: 2006, label: "Bakti Raja", kab: 449, prop: 34 },
  { value: 2007, label: "Dolok Sanggul", kab: 449, prop: 34 },
  { value: 2008, label: "Lintong Nihuta", kab: 449, prop: 34 },
  { value: 2009, label: "Onan Ganjang", kab: 449, prop: 34 },
  { value: 2010, label: "Pakkat", kab: 449, prop: 34 },
  { value: 2011, label: "Paranginan", kab: 449, prop: 34 },
  { value: 2012, label: "Parlilitan", kab: 449, prop: 34 },
  { value: 2013, label: "Pollung", kab: 449, prop: 34 },
  { value: 2014, label: "Sijama Polang", kab: 449, prop: 34 },
  { value: 2015, label: "Tara Bintang", kab: 449, prop: 34 },
  { value: 2420, label: "Barus Jahe", kab: 450, prop: 34 },
  { value: 2421, label: "Brastagi (Berastagi)", kab: 450, prop: 34 },
  { value: 2422, label: "Dolat Rayat", kab: 450, prop: 34 },
  { value: 2423, label: "Juhar", kab: 450, prop: 34 },
  { value: 2424, label: "Kabanjahe", kab: 450, prop: 34 },
  { value: 2425, label: "Kuta Buluh", kab: 450, prop: 34 },
  { value: 2426, label: "Laubaleng", kab: 450, prop: 34 },
  { value: 2427, label: "Mardinding", kab: 450, prop: 34 },
  { value: 2428, label: "Merdeka", kab: 450, prop: 34 },
  { value: 2429, label: "Merek", kab: 450, prop: 34 },
  { value: 2430, label: "Munte", kab: 450, prop: 34 },
  { value: 2431, label: "Nama Teran", kab: 450, prop: 34 },
  { value: 2432, label: "Payung", kab: 450, prop: 34 },
  { value: 2433, label: "Simpang Empat", kab: 450, prop: 34 },
  { value: 2434, label: "Tiga Binanga", kab: 450, prop: 34 },
  { value: 2435, label: "Tiga Panah", kab: 450, prop: 34 },
  { value: 2436, label: "Tiganderket", kab: 450, prop: 34 },
  { value: 3061, label: "Bilah Barat", kab: 451, prop: 34 },
  { value: 3062, label: "Bilah Hilir", kab: 451, prop: 34 },
  { value: 3063, label: "Bilah Hulu", kab: 451, prop: 34 },
  { value: 3064, label: "Panai Hilir", kab: 451, prop: 34 },
  { value: 3065, label: "Panai Hulu", kab: 451, prop: 34 },
  { value: 3066, label: "Panai Tengah", kab: 451, prop: 34 },
  { value: 3067, label: "Pangkatan", kab: 451, prop: 34 },
  { value: 3068, label: "Rantau Selatan", kab: 451, prop: 34 },
  { value: 3069, label: "Rantau Utara", kab: 451, prop: 34 },
  { value: 3070, label: "Kampung Rakyat", kab: 452, prop: 34 },
  { value: 3071, label: "Kota Pinang", kab: 452, prop: 34 },
  { value: 3072, label: "Sei/Sungai Kanan", kab: 452, prop: 34 },
  { value: 3073, label: "Silangkitang", kab: 452, prop: 34 },
  { value: 3074, label: "Torgamba", kab: 452, prop: 34 },
  { value: 3075, label: "Aek Kuo", kab: 453, prop: 34 },
  { value: 3076, label: "Aek Natas", kab: 453, prop: 34 },
  { value: 3077, label: "Kuala Ledong (Kualuh Leidong)", kab: 453, prop: 34 },
  { value: 3078, label: "Kualuh Hilir", kab: 453, prop: 34 },
  { value: 3079, label: "Kualuh Hulu", kab: 453, prop: 34 },
  { value: 3080, label: "Kualuh Selatan", kab: 453, prop: 34 },
  { value: 3081, label: "Marbau", kab: 453, prop: 34 },
  { value: 3082, label: "Na IX-X", kab: 453, prop: 34 },
  { value: 3260, label: "Babalan", kab: 454, prop: 34 },
  { value: 3261, label: "Bahorok", kab: 454, prop: 34 },
  { value: 3262, label: "Batang Serangan", kab: 454, prop: 34 },
  { value: 3263, label: "Besitang", kab: 454, prop: 34 },
  { value: 3264, label: "Binjai", kab: 454, prop: 34 },
  { value: 3265, label: "Brandan Barat", kab: 454, prop: 34 },
  { value: 3266, label: "Gebang", kab: 454, prop: 34 },
  { value: 3267, label: "Hinai", kab: 454, prop: 34 },
  { value: 3268, label: "Kuala", kab: 454, prop: 34 },
  { value: 3269, label: "Kutambaru", kab: 454, prop: 34 },
  { value: 3270, label: "Padang Tualang", kab: 454, prop: 34 },
  { value: 3271, label: "Pangkalan Susu", kab: 454, prop: 34 },
  { value: 3272, label: "Pematang Jaya", kab: 454, prop: 34 },
  { value: 3273, label: "Salapian", kab: 454, prop: 34 },
  { value: 3274, label: "Sawit Seberang", kab: 454, prop: 34 },
  { value: 3275, label: "Secanggang", kab: 454, prop: 34 },
  { value: 3276, label: "Sei Binge (Bingai)", kab: 454, prop: 34 },
  { value: 3277, label: "Sei Lepan", kab: 454, prop: 34 },
  { value: 3278, label: "Selesai", kab: 454, prop: 34 },
  { value: 3279, label: "Sirapit (Serapit)", kab: 454, prop: 34 },
  { value: 3280, label: "Stabat", kab: 454, prop: 34 },
  { value: 3281, label: "Tanjungpura", kab: 454, prop: 34 },
  { value: 3282, label: "Wampu", kab: 454, prop: 34 },
  { value: 3779, label: "Batahan", kab: 455, prop: 34 },
  { value: 3780, label: "Batang Natal", kab: 455, prop: 34 },
  { value: 3781, label: "Bukit Malintang", kab: 455, prop: 34 },
  { value: 3782, label: "Huta Bargot", kab: 455, prop: 34 },
  { value: 3783, label: "Kotanopan", kab: 455, prop: 34 },
  { value: 3784, label: "Langga Bayu (Lingga Bayu)", kab: 455, prop: 34 },
  { value: 3785, label: "Lembah Sorik Merapi", kab: 455, prop: 34 },
  { value: 3786, label: "Muara Batang Gadis", kab: 455, prop: 34 },
  { value: 3787, label: "Muara Sipongi", kab: 455, prop: 34 },
  { value: 3788, label: "Naga Juang", kab: 455, prop: 34 },
  { value: 3789, label: "Natal", kab: 455, prop: 34 },
  { value: 3790, label: "Pakantan", kab: 455, prop: 34 },
  { value: 3791, label: "Panyabungan Barat", kab: 455, prop: 34 },
  { value: 3792, label: "Panyabungan Kota", kab: 455, prop: 34 },
  { value: 3793, label: "Panyabungan Selatan", kab: 455, prop: 34 },
  { value: 3794, label: "Panyabungan Timur", kab: 455, prop: 34 },
  { value: 3795, label: "Panyabungan Utara", kab: 455, prop: 34 },
  { value: 3796, label: "Puncak Sorik Marapi/Merapi", kab: 455, prop: 34 },
  { value: 3797, label: "Ranto Baek/Baik", kab: 455, prop: 34 },
  { value: 3798, label: "Siabu", kab: 455, prop: 34 },
  { value: 3799, label: "Sinunukan", kab: 455, prop: 34 },
  { value: 3800, label: "Tambangan", kab: 455, prop: 34 },
  { value: 3801, label: "Ulu Pungkut", kab: 455, prop: 34 },
  { value: 3906, label: "Medan Amplas", kab: 456, prop: 34 },
  { value: 3907, label: "Medan Area", kab: 456, prop: 34 },
  { value: 3908, label: "Medan Barat", kab: 456, prop: 34 },
  { value: 3909, label: "Medan Baru", kab: 456, prop: 34 },
  { value: 3910, label: "Medan Belawan Kota", kab: 456, prop: 34 },
  { value: 3911, label: "Medan Deli", kab: 456, prop: 34 },
  { value: 3912, label: "Medan Denai", kab: 456, prop: 34 },
  { value: 3913, label: "Medan Helvetia", kab: 456, prop: 34 },
  { value: 3914, label: "Medan Johor", kab: 456, prop: 34 },
  { value: 3915, label: "Medan Kota", kab: 456, prop: 34 },
  { value: 3916, label: "Medan Labuhan", kab: 456, prop: 34 },
  { value: 3917, label: "Medan Maimun", kab: 456, prop: 34 },
  { value: 3918, label: "Medan Marelan", kab: 456, prop: 34 },
  { value: 3919, label: "Medan Perjuangan", kab: 456, prop: 34 },
  { value: 3920, label: "Medan Petisah", kab: 456, prop: 34 },
  { value: 3921, label: "Medan Polonia", kab: 456, prop: 34 },
  { value: 3922, label: "Medan Selayang", kab: 456, prop: 34 },
  { value: 3923, label: "Medan Sunggal", kab: 456, prop: 34 },
  { value: 3924, label: "Medan Tembung", kab: 456, prop: 34 },
  { value: 3925, label: "Medan Timur", kab: 456, prop: 34 },
  { value: 3926, label: "Medan Tuntungan", kab: 456, prop: 34 },
  { value: 4362, label: "Bawolato", kab: 457, prop: 34 },
  { value: 4363, label: "Botomuzoi", kab: 457, prop: 34 },
  { value: 4364, label: "Gido", kab: 457, prop: 34 },
  { value: 4365, label: "Hili Serangkai (Hilisaranggu)", kab: 457, prop: 34 },
  { value: 4366, label: "Hiliduho", kab: 457, prop: 34 },
  { value: 4367, label: "Idano Gawo", kab: 457, prop: 34 },
  { value: 4368, label: "Mau", kab: 457, prop: 34 },
  { value: 4369, label: "Sogae Adu (Sogaeadu)", kab: 457, prop: 34 },
  { value: 4370, label: "Somolo-Molo (Samolo)", kab: 457, prop: 34 },
  { value: 4371, label: "Ulugawo", kab: 457, prop: 34 },
  { value: 4372, label: "Lahomi (Gahori)", kab: 458, prop: 34 },
  { value: 4373, label: "Lolofitu Moi", kab: 458, prop: 34 },
  { value: 4374, label: "Mandrehe", kab: 458, prop: 34 },
  { value: 4375, label: "Mandrehe Barat", kab: 458, prop: 34 },
  { value: 4376, label: "Mandrehe Utara", kab: 458, prop: 34 },
  { value: 4377, label: "Moroo", kab: 458, prop: 34 },
  { value: 4378, label: "Sirombu", kab: 458, prop: 34 },
  { value: 4379, label: "Ulu Moroo (Ulu Narwo)", kab: 458, prop: 34 },
  { value: 4380, label: "Amandraya", kab: 459, prop: 34 },
  { value: 4381, label: "Aramo", kab: 459, prop: 34 },
  { value: 4382, label: "Boronadu", kab: 459, prop: 34 },
  { value: 4383, label: "Fanayama", kab: 459, prop: 34 },
  { value: 4384, label: "Gomo", kab: 459, prop: 34 },
  { value: 4385, label: "Hibala", kab: 459, prop: 34 },
  { value: 4386, label: "Hilimegai", kab: 459, prop: 34 },
  { value: 4387, label: "Hilisalawaahe (Hilisalawaahe)", kab: 459, prop: 34 },
  { value: 4388, label: "Huruna", kab: 459, prop: 34 },
  { value: 4389, label: "Lahusa", kab: 459, prop: 34 },
  { value: 4390, label: "Lolomatua", kab: 459, prop: 34 },
  { value: 4391, label: "Lolowau", kab: 459, prop: 34 },
  { value: 4392, label: "Maniamolo", kab: 459, prop: 34 },
  { value: 4393, label: "Mazino", kab: 459, prop: 34 },
  { value: 4394, label: "Mazo", kab: 459, prop: 34 },
  { value: 4395, label: "Oou (Oou)", kab: 459, prop: 34 },
  { value: 4396, label: "Onohazumba", kab: 459, prop: 34 },
  { value: 4397, label: "Pulau-Pulau Batu", kab: 459, prop: 34 },
  { value: 4398, label: "Pulau-Pulau Batu Barat", kab: 459, prop: 34 },
  { value: 4399, label: "Pulau-Pulau Batu Timur", kab: 459, prop: 34 },
  { value: 4400, label: "Pulau-Pulau Batu Utara", kab: 459, prop: 34 },
  { value: 4401, label: "Siduaori", kab: 459, prop: 34 },
  { value: 4402, label: "Simuk", kab: 459, prop: 34 },
  { value: 4403, label: "Somambawa", kab: 459, prop: 34 },
  { value: 4404, label: "Susua", kab: 459, prop: 34 },
  { value: 4405, label: "Tanah Masa", kab: 459, prop: 34 },
  { value: 4406, label: "Teluk Dalam", kab: 459, prop: 34 },
  { value: 4407, label: "Toma", kab: 459, prop: 34 },
  { value: 4408, label: "Ulunoyo", kab: 459, prop: 34 },
  { value: 4409, label: "Ulususua", kab: 459, prop: 34 },
  { value: 4410, label: "Umbunasi", kab: 459, prop: 34 },
  { value: 4411, label: "Afulu", kab: 460, prop: 34 },
  { value: 4412, label: "Alasa", kab: 460, prop: 34 },
  { value: 4413, label: "Alasa Talumuzoi", kab: 460, prop: 34 },
  { value: 4414, label: "Lahewa", kab: 460, prop: 34 },
  { value: 4415, label: "Lahewa Timur", kab: 460, prop: 34 },
  { value: 4416, label: "Lotu", kab: 460, prop: 34 },
  { value: 4417, label: "Namohalu Esiwa", kab: 460, prop: 34 },
  { value: 4418, label: "Sawo", kab: 460, prop: 34 },
  { value: 4419, label: "Sitolu Ori", kab: 460, prop: 34 },
  { value: 4420, label: "Tugala Oyo", kab: 460, prop: 34 },
  { value: 4421, label: "Tuhemberua", kab: 460, prop: 34 },
  { value: 4545, label: "Aek Nabara Barumun", kab: 461, prop: 34 },
  { value: 4546, label: "Barumun", kab: 461, prop: 34 },
  { value: 4547, label: "Barumun Selatan", kab: 461, prop: 34 },
  { value: 4548, label: "Barumun Tengah", kab: 461, prop: 34 },
  { value: 4549, label: "Batang Lubu Sutam", kab: 461, prop: 34 },
  { value: 4550, label: "Huristak", kab: 461, prop: 34 },
  { value: 4551, label: "Huta Raja Tinggi", kab: 461, prop: 34 },
  { value: 4552, label: "Lubuk Barumun", kab: 461, prop: 34 },
  { value: 4553, label: "Sihapas Barumun", kab: 461, prop: 34 },
  { value: 4554, label: "Sosa", kab: 461, prop: 34 },
  { value: 4555, label: "Sosopan", kab: 461, prop: 34 },
  { value: 4556, label: "Ulu Barumun", kab: 461, prop: 34 },
  { value: 4557, label: "Batang Onang", kab: 462, prop: 34 },
  { value: 4558, label: "Dolok", kab: 462, prop: 34 },
  { value: 4559, label: "Dolok Sigompulon", kab: 462, prop: 34 },
  { value: 4560, label: "Halongonan", kab: 462, prop: 34 },
  { value: 4561, label: "Hulu Sihapas", kab: 462, prop: 34 },
  { value: 4562, label: "Padang Bolak", kab: 462, prop: 34 },
  { value: 4563, label: "Padang Bolak Julu", kab: 462, prop: 34 },
  { value: 4564, label: "Portibi", kab: 462, prop: 34 },
  { value: 4565, label: "Simangambat", kab: 462, prop: 34 },
  { value: 4585, label: "Padang Sidempuan Angkola Julu", kab: 463, prop: 34 },
  { value: 4586, label: "Padang Sidempuan Batunadua", kab: 463, prop: 34 },
  { value: 4587, label: "Padang Sidempuan Hutaimbaru", kab: 463, prop: 34 },
  { value: 4588, label: "Padang Sidempuan Selatan", kab: 463, prop: 34 },
  { value: 4589, label: "Padang Sidempuan Tenggara", kab: 463, prop: 34 },
  {
    value: 4590,
    label: "Padang Sidempuan Utara (Padangsidimpuan)",
    kab: 463,
    prop: 34,
  },
  { value: 4596, label: "Kerajaan", kab: 464, prop: 34 },
  { value: 4597, label: "Pagindar", kab: 464, prop: 34 },
  { value: 4598, label: "Pergetteng Getteng Sengkut", kab: 464, prop: 34 },
  { value: 4599, label: "Salak", kab: 464, prop: 34 },
  { value: 4600, label: "Siempat Rube", kab: 464, prop: 34 },
  { value: 4601, label: "Sitellu Tali Urang Jehe", kab: 464, prop: 34 },
  { value: 4602, label: "Sitellu Tali Urang Julu", kab: 464, prop: 34 },
  { value: 4603, label: "Tinada", kab: 464, prop: 34 },
  { value: 4952, label: "Siantar Barat", kab: 465, prop: 34 },
  { value: 4953, label: "Siantar Marihat", kab: 465, prop: 34 },
  { value: 4954, label: "Siantar Marimbun", kab: 465, prop: 34 },
  { value: 4955, label: "Siantar Martoba", kab: 465, prop: 34 },
  { value: 4956, label: "Siantar Selatan", kab: 465, prop: 34 },
  { value: 4957, label: "Siantar Sitalasari", kab: 465, prop: 34 },
  { value: 4958, label: "Siantar Timur", kab: 465, prop: 34 },
  { value: 4959, label: "Siantar Utara", kab: 465, prop: 34 },
  { value: 5385, label: "Harian", kab: 466, prop: 34 },
  { value: 5386, label: "Nainggolan", kab: 466, prop: 34 },
  { value: 5387, label: "Onan Runggu", kab: 466, prop: 34 },
  { value: 5388, label: "Palipi", kab: 466, prop: 34 },
  { value: 5389, label: "Pangururan", kab: 466, prop: 34 },
  { value: 5390, label: "Ronggur Nihuta", kab: 466, prop: 34 },
  { value: 5391, label: "Sianjur Mula-Mula", kab: 466, prop: 34 },
  { value: 5392, label: "Simanindo", kab: 466, prop: 34 },
  { value: 5393, label: "Sitio-Tio", kab: 466, prop: 34 },
  { value: 5575, label: "Bandar Khalifah", kab: 467, prop: 34 },
  { value: 5576, label: "Bintang Bayu", kab: 467, prop: 34 },
  { value: 5577, label: "Dolok Masihul", kab: 467, prop: 34 },
  { value: 5578, label: "Dolok Merawan", kab: 467, prop: 34 },
  { value: 5579, label: "Kotarih", kab: 467, prop: 34 },
  { value: 5580, label: "Pantai Cermin", kab: 467, prop: 34 },
  { value: 5581, label: "Pegajahan", kab: 467, prop: 34 },
  { value: 5582, label: "Perbaungan", kab: 467, prop: 34 },
  { value: 5583, label: "Sei Bamban", kab: 467, prop: 34 },
  { value: 5584, label: "Sei Rampah", kab: 467, prop: 34 },
  { value: 5585, label: "Serba Jadi", kab: 467, prop: 34 },
  { value: 5586, label: "Silinda", kab: 467, prop: 34 },
  { value: 5587, label: "Sipispis", kab: 467, prop: 34 },
  { value: 5588, label: "Tanjung Beringin", kab: 467, prop: 34 },
  { value: 5589, label: "Tebing Syahbandar", kab: 467, prop: 34 },
  { value: 5590, label: "Tebing Tinggi", kab: 467, prop: 34 },
  { value: 5591, label: "Teluk Mengkudu", kab: 467, prop: 34 },
  { value: 5616, label: "Sibolga Kota", kab: 468, prop: 34 },
  { value: 5617, label: "Sibolga Sambas", kab: 468, prop: 34 },
  { value: 5618, label: "Sibolga Selatan", kab: 468, prop: 34 },
  { value: 5619, label: "Sibolga Utara", kab: 468, prop: 34 },
  { value: 5693, label: "Bandar", kab: 469, prop: 34 },
  { value: 5694, label: "Bandar Huluan", kab: 469, prop: 34 },
  { value: 5695, label: "Bandar Masilam", kab: 469, prop: 34 },
  { value: 5696, label: "Bosar Maligas", kab: 469, prop: 34 },
  { value: 5697, label: "Dolok Batu Nanggar", kab: 469, prop: 34 },
  { value: 5698, label: "Dolok Panribuan", kab: 469, prop: 34 },
  { value: 5699, label: "Dolok Pardamean", kab: 469, prop: 34 },
  { value: 5700, label: "Dolok Silau", kab: 469, prop: 34 },
  { value: 5701, label: "Girsang Sipangan Bolon", kab: 469, prop: 34 },
  { value: 5702, label: "Gunung Malela", kab: 469, prop: 34 },
  { value: 5703, label: "Gunung Maligas", kab: 469, prop: 34 },
  { value: 5704, label: "Haranggaol Horison", kab: 469, prop: 34 },
  { value: 5705, label: "Hatonduhan", kab: 469, prop: 34 },
  { value: 5706, label: "Huta Bayu Raja", kab: 469, prop: 34 },
  { value: 5707, label: "Jawa Maraja Bah Jambi", kab: 469, prop: 34 },
  { value: 5708, label: "Jorlang Hataran", kab: 469, prop: 34 },
  { value: 5709, label: "Panei", kab: 469, prop: 34 },
  { value: 5710, label: "Panombeian Panei", kab: 469, prop: 34 },
  { value: 5711, label: "Pematang Bandar", kab: 469, prop: 34 },
  { value: 5712, label: "Pematang Sidamanik", kab: 469, prop: 34 },
  { value: 5713, label: "Pematang Silima Huta", kab: 469, prop: 34 },
  { value: 5714, label: "Purba", kab: 469, prop: 34 },
  { value: 5715, label: "Raya", kab: 469, prop: 34 },
  { value: 5716, label: "Raya Kahean", kab: 469, prop: 34 },
  { value: 5717, label: "Siantar", kab: 469, prop: 34 },
  { value: 5718, label: "Sidamanik", kab: 469, prop: 34 },
  { value: 5719, label: "Silimakuta", kab: 469, prop: 34 },
  { value: 5720, label: "Silou Kahean", kab: 469, prop: 34 },
  { value: 5721, label: "Tanah Jawa", kab: 469, prop: 34 },
  { value: 5722, label: "Tapian Dolok", kab: 469, prop: 34 },
  { value: 5723, label: "Ujung Padang", kab: 469, prop: 34 },
  { value: 6337, label: "Datuk Bandar", kab: 470, prop: 34 },
  { value: 6338, label: "Datuk Bandar Timur", kab: 470, prop: 34 },
  { value: 6339, label: "Sei Tualang Raso", kab: 470, prop: 34 },
  { value: 6340, label: "Tanjung Balai Selatan", kab: 470, prop: 34 },
  { value: 6341, label: "Tanjung Balai Utara", kab: 470, prop: 34 },
  { value: 6342, label: "Teluk Nibung", kab: 470, prop: 34 },
  { value: 6371, label: "Aek Bilah", kab: 471, prop: 34 },
  {
    value: 6372,
    label: "Angkola Barat (Padang Sidempuan)",
    kab: 471,
    prop: 34,
  },
  { value: 6373, label: "Angkola Sangkunur", kab: 471, prop: 34 },
  { value: 6374, label: "Angkola Selatan (Siais)", kab: 471, prop: 34 },
  {
    value: 6375,
    label: "Angkola Timur (Padang Sidempuan)",
    kab: 471,
    prop: 34,
  },
  { value: 6376, label: "Arse", kab: 471, prop: 34 },
  { value: 6377, label: "Batang Angkola", kab: 471, prop: 34 },
  { value: 6378, label: "Batang Toru", kab: 471, prop: 34 },
  { value: 6379, label: "Marancar", kab: 471, prop: 34 },
  { value: 6380, label: "Muara Batang Toru", kab: 471, prop: 34 },
  { value: 6381, label: "Saipar Dolok Hole", kab: 471, prop: 34 },
  { value: 6382, label: "Sayur Matinggi", kab: 471, prop: 34 },
  { value: 6383, label: "Sipirok", kab: 471, prop: 34 },
  { value: 6384, label: "Tano Tombangan Angkola", kab: 471, prop: 34 },
  { value: 6385, label: "Andam Dewi", kab: 472, prop: 34 },
  { value: 6386, label: "Badiri", kab: 472, prop: 34 },
  { value: 6387, label: "Barus", kab: 472, prop: 34 },
  { value: 6388, label: "Barus Utara", kab: 472, prop: 34 },
  { value: 6389, label: "Kolang", kab: 472, prop: 34 },
  { value: 6390, label: "Lumut", kab: 472, prop: 34 },
  { value: 6391, label: "Manduamas", kab: 472, prop: 34 },
  { value: 6392, label: "Pandan", kab: 472, prop: 34 },
  { value: 6393, label: "Pasaribu Tobing", kab: 472, prop: 34 },
  { value: 6394, label: "Pinangsori", kab: 472, prop: 34 },
  { value: 6395, label: "Sarudik", kab: 472, prop: 34 },
  { value: 6396, label: "Sibabangun", kab: 472, prop: 34 },
  { value: 6397, label: "Sirandorung", kab: 472, prop: 34 },
  { value: 6398, label: "Sitahuis", kab: 472, prop: 34 },
  { value: 6399, label: "Sorkam", kab: 472, prop: 34 },
  { value: 6400, label: "Sorkam Barat", kab: 472, prop: 34 },
  { value: 6401, label: "Sosor Gadong", kab: 472, prop: 34 },
  { value: 6402, label: "Suka Bangun", kab: 472, prop: 34 },
  { value: 6403, label: "Tapian Nauli", kab: 472, prop: 34 },
  { value: 6404, label: "Tukka", kab: 472, prop: 34 },
  { value: 6405, label: "Adian Koting", kab: 473, prop: 34 },
  { value: 6406, label: "Garoga", kab: 473, prop: 34 },
  { value: 6407, label: "Muara", kab: 473, prop: 34 },
  { value: 6408, label: "Pagaran", kab: 473, prop: 34 },
  { value: 6409, label: "Pahae Jae", kab: 473, prop: 34 },
  { value: 6410, label: "Pahae Julu", kab: 473, prop: 34 },
  { value: 6411, label: "Pangaribuan", kab: 473, prop: 34 },
  { value: 6412, label: "Parmonangan", kab: 473, prop: 34 },
  { value: 6413, label: "Purbatua", kab: 473, prop: 34 },
  { value: 6414, label: "Siatas Barita", kab: 473, prop: 34 },
  { value: 6415, label: "Siborong-Borong", kab: 473, prop: 34 },
  { value: 6416, label: "Simangumban", kab: 473, prop: 34 },
  { value: 6417, label: "Sipahutar", kab: 473, prop: 34 },
  { value: 6418, label: "Sipoholon", kab: 473, prop: 34 },
  { value: 6419, label: "Tarutung", kab: 473, prop: 34 },
  { value: 6485, label: "Bajenis", kab: 474, prop: 34 },
  { value: 6486, label: "Padang Hilir", kab: 474, prop: 34 },
  { value: 6487, label: "Padang Hulu", kab: 474, prop: 34 },
  { value: 6488, label: "Rambutan", kab: 474, prop: 34 },
  { value: 6489, label: "Tebing Tinggi Kota", kab: 474, prop: 34 },
  { value: 6652, label: "Ajibata", kab: 475, prop: 34 },
  { value: 6653, label: "Balige", kab: 475, prop: 34 },
  { value: 6654, label: "Bonatua Lunasi", kab: 475, prop: 34 },
  { value: 6655, label: "Bor-Bor", kab: 475, prop: 34 },
  { value: 6656, label: "Habinsaran", kab: 475, prop: 34 },
  { value: 6657, label: "Laguboti", kab: 475, prop: 34 },
  { value: 6658, label: "Lumban Julu", kab: 475, prop: 34 },
  { value: 6659, label: "Nassau", kab: 475, prop: 34 },
  { value: 6660, label: "Parmaksian", kab: 475, prop: 34 },
  { value: 6661, label: "Pintu Pohan Meranti", kab: 475, prop: 34 },
  { value: 6662, label: "Porsea", kab: 475, prop: 34 },
  { value: 6663, label: "Siantar Narumonda", kab: 475, prop: 34 },
  { value: 6664, label: "Sigumpar", kab: 475, prop: 34 },
  { value: 6665, label: "Silaen", kab: 475, prop: 34 },
  { value: 6666, label: "Tampahan", kab: 475, prop: 34 },
  { value: 6667, label: "Uluan", kab: 475, prop: 34 },
];
