import React from 'react'
import { Modal } from 'react-bootstrap'

const ModalAction = (props) => {
  const { color } = props
  const {
    title, content, size
  } = props.modalOption

  return (
    <Modal
      {...props}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {title && (
        <Modal.Header style={{ background: '#52567a' }}>
          <Modal.Title id="contained-modal-title-vcenter" style={{ color: 'white' }} className={`text-${color}`}>
            <strong>{title}</strong>
          </Modal.Title>
          <span className='btn' onClick={props.onHide}>&times;</span>
        </Modal.Header>
      )}

      <Modal.Body>
        {content}
      </Modal.Body>
    </Modal>
  )
}

export default ModalAction
