import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import CardBasic from '../../../components/Cards/Basic';
import DatePicker from '../../../components/DatePicker';
import {
  formatCurrency,
  formatDateOnly,
  formatMonthYear,
} from '../../../constants/utility';
import PageHeading from '../../../components/PageHeading';
import PrintingHeader from '../../../components/PrintingHeader';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';

import * as actions from '../../../redux/actions';
import { fetchBonusBulanan } from '../../../redux/actions/report';

const BonusBulanan = (props) => {
  const { name, title, parent } = props.data;
  const { bonusBulanan, formResult } = useSelector((state) => state.report);
  const { public_id } = useSelector((state) => state.auth.data);
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();
  let date = new Date();
  const [filterDate, setFilterDate] = useState({
    start: new Date(date.getFullYear(), date.getMonth(), 1),
    end: new Date(),
  });

  const getReport = () => {
    const st = new Date(filterDate.start);
    const et = new Date(filterDate.end);
    const params = {
      id: public_id,
      filter: {
        tanggal: {
          op: 'between',
          value: {
            from: moment(new Date(st.getFullYear(), st.getMonth(), 1)).format(
              'YYYY-MM-DD'
            ),
            to: moment(new Date(et.getFullYear(), et.getMonth() + 1, 0)).format(
              'YYYY-MM-DD'
            ),
          },
        },
      },
    };
    console.log('xna', params);
    dispatch(fetchBonusBulanan(params));
  };

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    dispatch(
      actions.setMenuActive({ menuActive: parent || name, subMenuActive: name })
    );
  }, [dispatch, formResult, name, parent]);

  const columns = [
    {
      dataField: '_',
      text: 'No',
      headerClasses: 'py-2 px-2 text-center',
      classes: 'p-2 text-center',
      headerStyle: (colum, colIndex) => {
        return { width: '40px' };
      },
      formatter: (cel, row, rowIndex) => rowIndex + 1,
      footer: '',
    },
    {
      dataField: 'bulan',
      text: 'Bulan',
      headerStyle: (colum, colIndex) => {
        return { width: '90px' };
      },
      headerClasses: (column, colIndex) => {
        return 'py-2 px-2 text-left';
      },
      classes: (column, colIndex) => {
        return 'p-2';
      },
      formatter: (val) =>
        val ? formatMonthYear(val, false, false, true) : '-',
      footer: 'Total',
    },
    {
      dataField: 'bonus_bruto',
      text: 'Bonus Bruto',
      headerClasses: 'py-2 px-2 text-center',
      headerStyle: { width: '160px' },
      classes: 'p-2 text-center',
      formatter: (val) => formatCurrency(val),
      footerClasses: 'text-center',
      footer: (row) => formatCurrency(row.reduce((a, b) => a + b, 0)),
    },
    {
      dataField: 'pph',
      text: 'Potongan PPH',
      headerClasses: 'py-2 px-2 text-center',
      headerStyle: { width: '160px' },
      classes: 'p-2 text-center',
      formatter: (val) => formatCurrency(val),
      footerClasses: 'text-center',
      footer: (row) => formatCurrency(row.reduce((a, b) => a + b, 0)),
    },
    {
      dataField: 'bonus_dibayar',
      text: 'Bonus Bersih',
      headerClasses: 'py-2 px-2 text-center',
      headerStyle: { width: '160px' },
      classes: 'p-2 text-center',
      formatter: (val) => formatCurrency(val),
      footerClasses: 'text-center',
      footer: (row) => formatCurrency(row.reduce((a, b) => a + b, 0)),
    },
  ];

  return (
    <div>
      <PageHeading title={title} />
      <CardBasic nopadding>
        <div className="py-3 px-3 d-sm-block d-md-flex justify-content-between">
          <div className="d-flex">
            <div className="m-1">
              <DatePicker
                value={filterDate.start}
                endDate={filterDate.end}
                onChange={setFilterDate}
                placeholder={'Filter tanggal mulai-akhir'}
                range
                monthPicker={true}
              />
            </div>
            <div className="m-1">
              <button
                onClick={() => getReport()}
                className="btn btn-primary-f text-nowrap">
                <i className="fa fa-search"></i> Lihat
              </button>
            </div>
          </div>
        </div>
        <div className="table-fixed custom-scrollbar table-responsive my-table">
          <div className="container-print media-print-p2" ref={printRef}>
            <PrintingHeader
              title={'Laporan Bonus Bulanan'}
              subtitle={
                bonusBulanan?.member
                  ? `${bonusBulanan?.member?.id_member} - ${bonusBulanan?.member?.nama}`
                  : ''
              }
              date={[filterDate.start, filterDate.end]}
              printBy={null}
              printAt={null}
            />
            {bonusBulanan?.member && (
              <>
                <div
                  className="px-2 d-flex mt-5"
                  style={{ fontSize: '0.9rem' }}>
                  <div className="mr-2" style={{ width: '130px' }}>
                    Akumulasi Bonus{' '}
                  </div>
                  <div className="mr-2">:</div>
                  <div className="font-weight-bold">
                    {formatCurrency(bonusBulanan?.akumulasi)}
                  </div>
                </div>
              </>
            )}

            <div className="bt-table mt-4">
              <BootstrapTable
                className="bt-table"
                bootstrap4
                hover
                condensed
                noDataIndication={() => {
                  return 'No data';
                }}
                bordered={false}
                keyField="no_order"
                data={bonusBulanan?.bonus || []}
                columns={columns}
              // pagination={paginationFactory()}
              />
            </div>

            <div
              className="px-2 d-flex align-items-center mb-4"
              style={{ fontSize: '0.85rem' }}>
              <div className="mr-2" style={{ width: '180px' }}>
                Tanggal Bergabung
              </div>
              <div className="mr-2">:</div>
              <div
                className="font-weight-bold d-flex align-items-center gap-2"
                style={{ width: '60%' }}>
                <span style={{ marginTop: '3px' }}>
                  {show
                    ? formatDateOnly(bonusBulanan?.member?.tgl_join)
                    : '************'}
                </span>
                <button
                  className="btn btn-primary-f text-nowrap pt-2 pb-2 ml-2"
                  style={{ fontSize: '10px', fontWeight: 'bold' }}
                  onClick={() => setShow(!show)}>
                  <i
                    className={`fa ${show ? 'fa-caret-down' : 'fa-caret-up'
                      } mr-1`}></i>
                  <span>{show ? 'Hide' : 'Show'}</span>
                </button>
              </div>
            </div>

            {/*<BonusPending data={data} />*/}
            {/*{*/}
            {/*    jenis?.value === 'pending' ? <BonusPending data={data} />*/}
            {/*        : jenis?.value === 'payout' ? <BonusPaid data={data} />*/}
            {/*            : jenis?.value === 'pending-member' ? <BonusPendingPermember data={data} />*/}
            {/*                : jenis?.value === 'payout-member' ? <BonusPaidPermember data={data} />*/}
            {/*                    : "payout"*/}
            {/*}*/}
          </div>
        </div>
      </CardBasic>
    </div>
  );
};

export default BonusBulanan;
